import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'next-i18next';
import { TableWithPagination, TextTooltip } from 'src/ui/components/shared';
import { Button, Chip, Grid, Typography } from '@mui/material';
import { Requisition, RequisitionTableRow } from '@/models';
import { ColumnDef, TableMeta } from '@tanstack/react-table';
import { useProcurementStore, useTableRows, useUserData } from '@/zustand';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { makeStyles } from "@mui/styles";
import { Link, ConvertToRFQButton } from '@/components';
import { sm } from 'src/site-map';
import { RequisitionStatus } from '@/config';
import { useRouter } from 'next/router';


// const useStyles = makeStyles({
//     head: {
//         backgroundColor: '#F3F6F9',
//         '& th:first-child': { borderRadius: '6px 0 0 6px' },
//         '& th:last-child': { borderRadius: '0 6px 6px 0' },
//     },
//     cellHead: {
//         color: '#8B8D98', fontSize: 16, fontWeight: 400, textTransform: 'uppercase'
//     },
//     cellHeadStatus: {
//         paddingLeft: 40
//     },
//     requisitionsColumn: {
//         marginTop: 5, fontSize: 18, fontWeight: 400
//     },
//     cellBody: {
//         fontSize: 15, fontWeight: 400, paddingLeft: 40,
//     },
//     cellDateBody: {
//         fontSize: 15, fontWeight: 400, paddingLeft: 30,
//     },
//     styleColumn: {
//         fontWeight: 400, padding: 0,
//     },
//     rowBody: {
//         height: '80px', borderBottom: '1px solid #E3E3E9'
//     }
// });

const useStyles = makeStyles({
    head: {
        backgroundColor: '#F3F6F9',
        '& th:first-child': { borderRadius: '6px 0 0 6px' },
        '& th:last-child': { borderRadius: '0 6px 6px 0' },
        '& th': { textAlign: 'start' },
    },
    rowBody: {
        height: '80px', borderBottom: '1px solid #E3E3E9'
    },
    cellHead: {},
    styleColumn: {}
});
export const RequisitionsTable = () => {
    const classes = useStyles()
    const { requisitionTableRows, selectedRequisitionTableStatusName } = useProcurementStore()
    const { hasAccessToPortal } = useUserData()

    const getRequisitionDetailURL = (id: number, hasAccessToPortal: boolean) => {
        if (hasAccessToPortal)
            return sm.portals.buyer.procurement.requisitions.requisition(id).url
        else
            return sm.requisitions.requisition(id).url
    }


    const columns = React.useMemo<ColumnDef<RequisitionTableRow>[]>(
        () => [
            {
                accessorKey: 'requisition',
                header: 'requisitions',
                cell: info => <TableRequisitionText title={info.getValue().name} link={getRequisitionDetailURL(info.getValue().id, hasAccessToPortal)} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false,
                sortDescFirst: false,
                enableSorting: true
            },
            {
                accessorKey: 'requisition',
                header: 'project',
                cell: info => <TableDate date={info.getValue().project.name} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false,
                // enableSorting: true
            },
            {
                accessorKey: 'requisition',
                header: 'date_created',
                cell: info => <TableDate date={info.getValue().created_at} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false,
            },
            {
                accessorKey: 'requisition',
                header: 'owner',
                cell: info => <TableDate date={info.getValue().owner_name} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false,
                // enableSorting: true
            },
            {
                accessorKey: 'numberOfProducts',
                header: 'products',
                cell: info => <TableText title={info.getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'requisition',
                header: 'status',
                cell: info => <TableChip status={info.getValue().status} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'requisition',
                header: 'actions',
                cell: info => <TableButton requisition={info.getValue()} productNumber={info.getValue().products} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleColumn
                    }
                },
                enableColumnFilter: false
            },
        ],
        []
    )

    return (
        <>
            {
                requisitionTableRows && <TableWithPagination
                    disablePagination
                    columns={columns} data={requisitionTableRows}
                    key={selectedRequisitionTableStatusName + requisitionTableRows.length}
                    tableMeta={{
                        getRowStyle: (index: number) => {
                            return classes.rowBody
                        },
                        headStyle: classes.head
                    }}
                />
            }
        </>
    )
}

const TableText = ({ title }) => {
    return (
        <Typography variant='subtitle2' fontWeight={400}>{title}</Typography>
    )
}

const TableRequisitionText = ({ title, link }) => {
    return (
        <>
            <Link underline='hover' href={link}>
                <Typography
                    variant='body2'
                    sx={{ fontWeight: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {title}
                </Typography>
            </Link>

            {/* <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{title?.project?.name} </span> */}
        </>
    )
}

const TableButton = ({ requisition, productNumber }) => {
    const { t } = useTranslation()
    const { push } = useRouter()
    const setCurrentRequisition = useProcurementStore(state => state.setCurrentRequisition)
    const handleAddProducts = () => {
        push(sm.catalogue.index.url)
        setCurrentRequisition(requisition)
    }
    const convertToRFQHandler = () => push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(requisition.id).url, query: { sellers: 'select' } })
    return (
        <>
            {requisition.status !== RequisitionStatus.CONVERTED_TO_RFQ && productNumber != 0 && requisition.can_edit && <ConvertToRFQButton redirectHandler={convertToRFQHandler} />}

            {productNumber == 0 && requisition.can_edit && <Button onClick={handleAddProducts}><Typography variant='subtitle2' fontWeight={700}>{t('buttons.add_products')} </Typography></Button>}

            {requisition.status === RequisitionStatus.CONVERTED_TO_RFQ && <Button onClick={() => push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(requisition?.bundle_rfq_id).url)}>
                <Typography variant='subtitle2' fontWeight={700}>{t('buttons.view_rfq')} </Typography>
            </Button>}
        </>
    )
}

const TableChip = ({ status }) => {
    const { t } = useTranslation()
    return (
        <Chip label={t('chip.' + status)} variant="outlined" sx={{ width: "144px", height: '28px', color: 'text.disabled', '& .MuiChip-label': { fontWeight: 500, fontSize: '12px' } }} />
    )
}


const TableDate = ({ date }) => {
    const mainCharacter = '-';
    const replaceWith = '/';
    const updatedDate = date.split(mainCharacter).join(replaceWith);

    return (
        <Typography variant='subtitle2' fontWeight={400}>
            {updatedDate}
        </Typography>
    )
}