import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '@/hooks';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { NewSearchIcon } from '@/icons';

type ExpandedSearchIconProps = {
  onChangeSearchText?: ((newValue: string) => void) | undefined;
};

export const ExpandedSearchIcon: React.FC<ExpandedSearchIconProps> = ({ onChangeSearchText }) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [value, setValue] = useState<string>('');
  useDebounce(() => onChangeSearchText?.(value), 700, [value]);
  const handleClear = () => {
    setValue('');
    onChangeSearchText?.('');
    searchInputRef.current?.focus?.();
  };

  const handleSearchButtonClick = () => {
    setIsExpanded(prev => {
      const newExpandValue = !prev;
      if (newExpandValue) {
        searchInputRef.current?.focus?.();
      } else {
        if (value) {
          handleClear();
        }
      }
      return newExpandValue;
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        inputRef={searchInputRef}
        placeholder='Active Search'
        onChange={e => setValue(e.target.value)}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {value && (
                <IconButton onClick={handleClear} size='small' edge='end'>
                  <ClearIcon fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          ),
          sx: {
            borderRadius: '4px 0px 0px 4px',
            backgroundColor: '#EAEEF6',

            border: 'none',
            outline: 'none',
          },
        }}
        sx={{
          width: isExpanded ? '200px' : 0,
          transition: 'width 300ms',
          height: '100%',
          textOverflow: 'ellipsis !important',
          '& input': {
            padding: '10.5px 14px',
            color: '#00385E !important',
            outline: 'none !important',
            border: 'none !important',
          },
          '& fieldset': {
            borderColor: 'transparent !important',
          },
        }}
      />
      <Button
        onClick={handleSearchButtonClick}
        sx={{
          minWidth: 'fit-content',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          borderRadius: isExpanded ? '0px 4px 4px 0px' : '4px',
          backgroundColor: isExpanded ? '#13628C' : '#EAEEF6',
          '&:active': { backgroundColor: isExpanded ? '#13628C' : '#EAEEF6' },
          '&:hover': { backgroundColor: isExpanded ? '#13628C' : '#EAEEF6' },
        }}
      >
        <NewSearchIcon fill={isExpanded ? '#fff' : '#89B1C6'} style={{ width: '20px' }} />
      </Button>
    </div>
  );
};
