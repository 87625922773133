import { useProfile, useProfilePhoneNumberFlow } from '@/zustand';
import { SendVerificationOrLost } from '../shared-popups/send-verification-or-lost';
import { VerificationCodeFormByPhone } from '../shared-popups/verification-code-form-byPhone';
import { ChangePhoneForm } from '../shared-popups/change-phone-form';
import { Success } from '../shared-popups/success';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useRequestVerificationOldPhoneNumber,useVerificationOldPhoneNumber,
useRequestChangePhoneByPhone,useVerificationNewPhoneNumber,useVerificationOldPhoneNumberAgain,
useVerificationNewPhoneNumberAgain} from '@/services';
import { ProfileChangePhoneByEmailPopupContent } from '../change-phone-byEmail'

type Props = { };

export const ProfileChangePhonePopupContent: React.FC<Props> = ({}) => {
    const { t } = useTranslation();
    const changePhoneByPhonePopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByPhonePopupSteps);
    const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
    const toggleChangePhonePopup = useProfilePhoneNumberFlow(state => state.toggleChangePhonePopup);
    const user_info = useProfile(state => state.user_info);
    const temporaryPhone = useProfilePhoneNumberFlow(state => state.temporaryPhone);
    const [enabled, setEnabled] = useState(false);
    const [isEmailSteps, setIsEmailSteps] = useState(false);

    const isTimer = useProfilePhoneNumberFlow(state => state.isTimer);
    const timer = useProfilePhoneNumberFlow(state => state.timer);
    const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
    const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const getVerifyOldPhoneNumber = useRequestVerificationOldPhoneNumber(enabled); 
    const handleClickVerification = () => { getVerifyOldPhoneNumber.refetch() };

    const mutationRequestChangePhoneViaPhone = useVerificationOldPhoneNumber(); 
    const handleSubmitCode = (values, actions) => { mutationRequestChangePhoneViaPhone.mutate(values) } 
   
    const mutationChangePhone = useRequestChangePhoneByPhone(); 
    const handleSubmitNewPhone = (values, actions) => { mutationChangePhone.mutate(values)   } 

    const mutationVerificationNewPhoneNumber = useVerificationNewPhoneNumber(); 
    const handleSubmitCodeNew = (values, actions) => { mutationVerificationNewPhoneNumber.mutate(values) } 
   
    const ReRequestVerificationOldPhone = useVerificationOldPhoneNumberAgain(enabled); 
    const handleClickCodeAgainOld = () => { ReRequestVerificationOldPhone.refetch() };

    const { mutate } = useVerificationNewPhoneNumberAgain();
    const handleClickCodeAgainNew = () => { mutate() }


    const change_Phone = [
        <SendVerificationOrLost 
            isDisabledButton={getVerifyOldPhoneNumber.isFetching}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verify_phone'))}
            userInfo={user_info?.mobile_number.number} 
            verificationButtonTranslate={t('buttons.send_verification')} 
            lostButtonTranslate={t('buttons.lost_phone')}  
            handleClickVerification={handleClickVerification}
            handleClickLost={() => { setIsEmailSteps(true)} }
            timerButton={isTimer}
            timerValue={timer}
        />,
        <VerificationCodeFormByPhone 
            isDisabledButton={mutationRequestChangePhoneViaPhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_phone' ))}
            userInfo={user_info?.mobile_number.number} 
            handleClickNext={handleSubmitCode} 
            handleClickBack={() => {setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps-1)}}
            handleClickAgain={handleClickCodeAgainOld}
        />,
        <ChangePhoneForm
            isDisabledButton={mutationChangePhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_phone'))}
            handleClickNext={handleSubmitNewPhone} 
            handleClickBack={() => setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps-1)}
        />,
        <VerificationCodeFormByPhone
            isDisabledButton={mutationVerificationNewPhoneNumber.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_phone' ))}
            userInfo={temporaryPhone} 
            handleClickNext={handleSubmitCodeNew} 
            handleClickBack={() => setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps-1)}
            handleClickAgain={handleClickCodeAgainNew}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.change_phone_success' ))}
            userInfo={user_info?.mobile_number.number} togglePopup={() => toggleChangePhonePopup(false)}
        />
    ]

    return (
        <>
            { !isEmailSteps ? change_Phone[changePhoneByPhonePopupSteps] : <ProfileChangePhoneByEmailPopupContent/> }
        </>
    );
};