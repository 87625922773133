import { useRef, useState, useLayoutEffect } from 'react';
import { useViewportScroll, useTransform, MotionValue } from 'framer-motion';
import { getOffset } from '@/utils';

type Result = [React.RefObject<HTMLDivElement>, MotionValue<number>] & {
  ref: React.RefObject<HTMLDivElement>;
  result: MotionValue<number>;
};

export const useItemScrollInViewPort = (): Result => {
  const { scrollY } = useViewportScroll();
  const [top, setTop] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const result = useTransform(scrollY, [top - screenHeight, top], [0, 1]);

  useLayoutEffect(() => {
    const onResize = () => {
      if (!ref.current) return;
      setTop(getOffset(ref.current).top);
      setScreenHeight(window.innerHeight);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  let res: any = [ref, result] as const;
  res.ref = ref;
  res.result = result;
  return res;
};
