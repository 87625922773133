import { ThemeProvider } from '@mui/material';
import React from 'react';

import { useApp } from '@/zustand';
import { createTheme } from './create-theme';

export const MuiThemeProvider: React.FC = ({ children }) => {
  const [direction] = useApp(state => [state.direction]);

  const theme = React.useMemo(
    () =>
      createTheme({
        direction: direction,
      }),
    [direction]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
