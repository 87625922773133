import { Grid, Typography, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import { AutoComplete, LabelWithButton, StyledTextField, user } from '@/components';
import validationSchema from './validationSchema'
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
import { Formik } from 'formik';
import { useUserData } from 'src/logic/zustand/user';
import { toast } from 'react-toastify';
import { NotifySuccess, useUpdateProfile } from '@/services';
import { UserSettingsWithPopupsSection } from '../user-settings-with-popups';
import { getAccountTranslationKey } from '@/hooks';

type Props = { userData: user, isSmallScreen: boolean };

export const EditUserSettingsSection: React.FC<Props> = ({
  userData,
  predefinedData,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  const onSuccess = () => {
    NotifySuccess(t('notifications.update_profile'));
    router.push(sm.account.index.url)
  }

  const { mutate } = useUpdateProfile({ onSuccess });


  const handleSubmit = (values, actions) => { mutate({ values: values, actions: actions }); }

  const initialValues = {
    first_name: userData.first_name,
    last_name: userData.last_name,
    title: userData.title,
    job_title: userData.job_title,
  }
  const router = useRouter();
  const cancel = () => { router.push(sm.account.index.url) }
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <UserSettingsForm cancel={cancel} isSmallScreen={isSmallScreen} userData={userData} predefinedData={predefinedData} disabledButton={!(formikProps.isValid && formikProps.dirty)} />
          </form>
        )}
      </Formik>
    </>
  );
};

type UserSettingsFormProps = { cancel: Function; userData: user, isSmallScreen: boolean, disabledButton: boolean };

export const UserSettingsForm: React.FC<UserSettingsFormProps> = ({ cancel, userData, isSmallScreen, predefinedData, disabledButton }) => {
  const { t } = useTranslation();
  const [showEmail, setShowEmail] = useState(false)
  return (
    <Grid container>
      <Grid item container xs={6}>
        <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>{t(getAccountTranslationKey('sections_titles.user_settings'))}</Typography>
      </Grid>
      <Grid item container xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='outlined' sx={{ maxWidth: isSmallScreen ? '40px' : '174px', minWidth: isSmallScreen ? '40px' : '145px', mr: 2 }} onClick={() => cancel()}>
          {isSmallScreen ? '' : t('buttons.cancel')}
        </Button>
        <Button disabled={disabledButton} variant='contained' sx={{ maxWidth: isSmallScreen ? '40px' : '174px', minWidth: isSmallScreen ? '40px' : '145px' }} type="submit">
          <CheckIcon sx={{ fontSize: '20px', mr: isSmallScreen ? 0 : 1 }} />
          {isSmallScreen ? '' : t('buttons.save')}
        </Button>
      </Grid>
      <Grid item xs={12} >
        <hr />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <StyledTextField showLabel required translateKey='user.first_name' name={'first_name'} />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <StyledTextField showLabel required translateKey='user.last_name' name={'last_name'} />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <AutoComplete
          required
          translateKey='user.title'
          name='title'
          defaultValue={{ name: userData.title, id: userData.title }}
          items={predefinedData.titles}
        />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <StyledTextField showLabel required translateKey='user.job_title' name={'job_title'} />
      </Grid>
      <Grid item xs={12} mt={3} />
      <UserSettingsWithPopupsSection userData={userData} />
    </Grid>
  )
}