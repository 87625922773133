import { getInputsTranslationKey } from "@/hooks"
import { Grid, TextField, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from 'next-i18next';
import { useField } from "formik";
import { at } from 'lodash';

interface MultilineTextFieldFormProps {
    translateKey: string,
    name: string,
    showLabel?: boolean,
    required?: boolean,
    disabled?: boolean,
    autofocus?: boolean,
    commentNote?: string | null
    border?: string
}

export const MultilineTextFieldForm: FC<MultilineTextFieldFormProps> = (props) => {
    const { t } = useTranslation()
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <Typography variant='subtitle2' >
                {t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
            </Typography>
        }
    }
    return (
        <Grid item container md={12}>
            <Grid item md={12}>
                <Typography variant="subtitle2" component='label' fontWeight={400} sx={{ color: 'text.third' }}>
                    {props.showLabel && t(getInputsTranslationKey('labels.' + props.translateKey))} {props.required ? ' *' : ''}
                </Typography>
            </Grid>
            <Grid item md={12}>
                <TextField
                    autoFocus={props.autofocus}
                    // onChange={val => handleValueChange(val.target.value)}
                    minRows={5}
                    maxRows={5}
                    disabled={props.disabled}
                    multiline
                    variant="outlined"
                    fullWidth
                    // helperText={_renderHelperText()}
                    {...field}
                    helperText={`${field.value?.length ?? 0}/500`}
                    placeholder={t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    // placeholder={placeholder}
                    inputProps={{ maxLength: 500, }}
                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                    style={{ overflow: 'auto', height: 150 }}
                    InputProps={{
                        sx: {
                            minHeight: 120, fontSize: '14px', color: '#3A3E50', fontWeight: 400,
                            '& ::placeholder': {
                                fontSize: '12px'
                            },
                            border: props.border
                        }
                    }}
                />
                {props.commentNote && <Typography variant='subtitle2' fontWeight={400} sx={{ color: 'text.third', mt: -1, mb: 2 }}> {props.commentNote} </Typography>}
            </Grid>
        </Grid>
    )
}