import React, { useEffect } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const QueryProvider: React.FC<{}> = props => {
  const [queryClient] = React.useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect:true
      },
    },
  }))

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={props.pageProps.dehydratedState}>
        {props.children}
      </Hydrate>
    </QueryClientProvider>
  );
};
