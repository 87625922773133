import { Comment, ProcurementFormModel, ProcurementModelDetails, Product, ProductTableRow } from "@/models";
import { buildCatalogTable, buildProcurementFormDetails, NotifySuccess, useGetSellerCatalogDetails, usePublishSellerCatalog, useSaveCatalougDetails } from "@/services";
import { t } from "i18next";
import produce from "immer";
import CatalogSavingServices from "src/logic/services/products-saving/catalog";
import { StateCreator } from "zustand";

export interface CatalogListDetailsSlice {
    catalogListSliceProperties: {
        catalogList: ProcurementFormModel | null
        loadingDetails: boolean
        newComments: Comment[]
        productsTableRows: ProductTableRow[]
        typingTimer: NodeJS.Timeout | null
    },
    catalogListSliceActions: {
        fetchDetails: (id: string, handlerAfterFetch?: (catalog: any) => void) => void
        setIsLoading: (isLoading: boolean) => void
        setTimer: (timer: NodeJS.Timeout | null) => void
        setDetails: (formDetails: ProcurementModelDetails) => void
        closeNewCommentsPopup: () => void
        setProductsTableRows: (products: Product[]) => void
        setProducts: (products: ProductTableRow[]) => void
        saveFormProducts: (form: ProcurementModelDetails, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => void
        publish: () => void
        updateProductPriceFromList: (product: Product, price: string) => void
        removeProductFromList: (product: Product) => void
        productsUpdate: (newProducts: ProductTableRow[]) => void
    }
}

export const createCatalogListDetailsSlice: StateCreator<CatalogListDetailsSlice> = (set, get, api) => ({
    catalogListSliceProperties: {
        catalogList: null,
        loadingDetails: false,
        newComments: [],
        productsTableRows: [],
        typingTimer: null,
    },
    catalogListSliceActions: {
        setDetails: (formDetails: ProcurementModelDetails) => {
            const details = { ...formDetails, products: getCatalogProductsArray(formDetails.catalog_draft_products) }
            set(produce(draftState => {
                draftState.catalogListSliceProperties.catalogList = buildProcurementFormDetails(details, 'canUpdateCatalog', false, true, false)
            }))
            get().catalogListSliceActions.setProductsTableRows(details.products)
        },
        setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.catalogListSliceProperties.loadingDetails = isLoading })),
        setTimer: (timer: NodeJS.Timeout | null) => set(produce(draftState => { draftState.catalogListSliceProperties.typingTimer = timer })),
        setProducts: (products: ProductTableRow[]) => set(produce(draftState => { draftState.catalogListSliceProperties.productsTableRows = products })),
        fetchDetails: (id: string, handlerAfterFetch?: (catalog: any) => void) => {
            get().catalogListSliceActions.setIsLoading(true)
            useGetSellerCatalogDetails(id).then((data: any) => {
                get().catalogListSliceActions.setDetails(data.data)
                handlerAfterFetch?.({ ...data.data, products: getCatalogProductsArray(data.data.catalog_draft_products) })
                get().catalogListSliceActions.setIsLoading(false)
            })
        },
        closeNewCommentsPopup: () => set(produce(draftState => { draftState.catalogListSliceProperties.newComments = null })),
        setProductsTableRows: (products: Product[]) => {
            const ProductsArray = buildCatalogTable(products ?? [])
            set(produce(draftState => { draftState.catalogListSliceProperties.productsTableRows = ProductsArray }))
        },
        saveFormProducts: (form: ProcurementModelDetails, products: Product[], handleSavingSucess: (data: ProcurementModelDetails) => void, onError?: () => void) => {
            useSaveCatalougDetails(form, products).then(data => {
                const formDetails = data.data as ProcurementModelDetails
                handleSavingSucess({ ...formDetails, products: getCatalogProductsArray(formDetails.catalog_draft_products) })
            }).catch(() => {
                onError?.()
            })
        },
        publish: () => {
            const id = get().catalogListSliceProperties.catalogList?.model.id
            usePublishSellerCatalog(id).then((data: any) => {
                get().catalogListSliceActions.setDetails(data.data)
                NotifySuccess(t('notifications.draft_list_published', { name: data.data.name }))
            })
        },
        updateProductPriceFromList: (product: Product, price: string) => {
            const products = get().catalogListSliceProperties.productsTableRows
            const actions = get().catalogListSliceActions
            const newProducts = products.map(element => element.id === product.id ? { ...element, price: price } : element)
            actions.setProducts(newProducts)
            // actions.productsUpdate(newProducts)

            const data = get().catalogListSliceProperties
            clearTimeout(data.typingTimer);
            const newTimer = setTimeout(() => {
                actions.setTimer(null)
                const handleSavingSucess = (form: ProcurementModelDetails) => {
                    NotifySuccess(t('notifications.changes_saved'))
                }
                // actions.saveFormProducts(data.catalogList?.model, newProducts, handleSavingSucess)
                CatalogSavingServices.changePrice({
                    catalog_id: get().catalogListSliceProperties.catalogList?.model.id,
                    product_id: product.id,
                    price
                }).then(handleSavingSucess)
            }, 300);
            actions.setTimer(newTimer)
        },
        removeProductFromList: (product: Product) => {
            const products = get().catalogListSliceProperties.productsTableRows
            const actions = get().catalogListSliceActions
            const newProducts = products.filter(element => element.id != product.id)
            actions.setProducts(newProducts)

            CatalogSavingServices.removeProductFromCatalog({
                catalog_id: get().catalogListSliceProperties.catalogList?.model.id,
                product_id: product.id
            })

            // actions.productsUpdate(newProducts)
        },
        productsUpdate: (newProducts: ProductTableRow[]) => {
            const data = get().catalogListSliceProperties
            const actions = get().catalogListSliceActions
            clearTimeout(data.typingTimer);
            const newTimer = setTimeout(() => {
                actions.setTimer(null)
                const handleSavingSucess = (form: ProcurementModelDetails) => {
                    actions.setDetails(form)
                    NotifySuccess(t('notifications.changes_saved'))
                }
                actions.saveFormProducts(data.catalogList?.model, newProducts, handleSavingSucess)
            }, 2000);
            actions.setTimer(newTimer)
        }
    },
})


export const getCatalogProductsArray = (products: Product[]) => {
    return products.map(product => { return { ...product.product, ...product, name: product.product.name } })
}