const initialValues = (productId, quantity, selectedSeller) => {
    return {
        purchase_order_id: null,
        product_id: productId,
        quantity: quantity,
        price: null,
        seller_id: (typeof selectedSeller === 'number' || typeof selectedSeller === 'string') ? selectedSeller : (selectedSeller?.seller?.id ?? 0),
        projectId: '',
        projectName: '',
    }
}

export default initialValues