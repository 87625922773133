import {
  AlternativeProductRequisitionButton,
  Carousel,
  DescPopup,
  Loading,
  SimilarProductCatalogDraftListButton,
  SimilarProductQuoteButton,
  SimilarProductRequisitionButton,
  TextTooltip,
} from '@/components';
import { ProductManagerEnv, ProductManagerEnvItem } from '@/config';
import { Company, Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import ProductQuickView from '../product-quick-view';

interface ProductAlternativesType {
  environment: ProductManagerEnvItem | null;
  sellerDetails: Company | undefined;
  products: Product[];
  mainProduct: Product;
  canEditRequisition?: boolean;
}

export const ProductAlternatives: React.VFC<ProductAlternativesType> = ({
  seller,
  environment,
  sellerDetails,
  mainProduct,
  products,
  canEditRequisition,
}) => {
  const { t } = useTranslation();
  const { push, query } = useRouter();
  const isAlternatives =
    environment?.type === ProductManagerEnv.requisition || environment?.type === ProductManagerEnv.edit_bundle_rfq;

  return (
    <>
      {products.length != 0 && (
        <>
          <Grid
            item
            container
            md={12}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 3,
              mt: 3,
              // px: 7,
            }}
          >
            <Typography variant='overline' sx={{ fontWeight: 600 }}>
              {!isAlternatives ? t('texts.view_similar_products') : t('texts.view_alternatives')}
            </Typography>
            <Button
              color={
                // environment?.type == ProductManagerEnv.purchase_order
                //   ? 'primary'
                //   : mainProduct.inCart
                //   ? 'secondary'
                //   : 'primary'
                isAlternatives ? 'secondary' : 'primary'
              }
              sx={{ fontWeight: '700 !important' }}
              onClick={() => {
                if (isAlternatives) {
                  push(
                    sm.catalogue.products.alternatives(
                      // mainProduct.category.parent.parent.id,
                      // mainProduct.category.parent.id,
                      // mainProduct.category.id,
                      mainProduct.id
                    ).url
                  );
                } else {
                  push(sm.catalogue.products.similar(mainProduct.id).url);
                }
              }}
            >
              {t('buttons.view_all')}
            </Button>
          </Grid>
          <Grid item md={12}>
            <Carousel>
              {products?.map(product => (
                <AlternativeProductsCard
                  seller={seller}
                  key={product.id}
                  environment={environment?.type}
                  alternativeProduct={product}
                  mainProduct={mainProduct}
                  canEditRequisition={canEditRequisition}
                />
              ))}
            </Carousel>
          </Grid>
        </>
      )}
    </>
  );
};

const AlternativeProductsCard = ({ alternativeProduct, mainProduct, canEditRequisition, environment, seller }) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const detailsPopup = usePopup();

  const { data } = useProcurementStore(state => state.productFormManagerProperties);
  const loadingProductsIds = data.loadingProductsIds;
  const productId = alternativeProduct.id;
  const productIsLoading = React.useMemo(
    () => !!loadingProductsIds.find(id => id === productId),
    [productId, loadingProductsIds]
  );

  return (
    <>
      <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView
          product={alternativeProduct}
          disableNavigation={
            environment == ProductManagerEnv.requisition || environment == ProductManagerEnv.edit_bundle_rfq
          }
        />
      </DescPopup>
      <Box
        p={1}
        sx={{
          width: '303px',
          bgcolor: 'secondary.contrastText',
          mr: 1,
          borderRadius: '8px',
          position: 'relative',
        }}
      >
        {productIsLoading && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              zIndex: 3,
              backgroundColor: 'rgba(255, 255, 255, .5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
          >
            <Loading fullHeight={false} />
          </div>
        )}
        <Grid container height={'94px'} gap={1}>
          <Box height={'94px'} width='96px'>
            <img src={alternativeProduct.image_url} height={'100%'} width={'100%'} style={{ objectFit: 'contain' }} />
          </Box>
          <Box width='151px' height={'94px'}>
            <Box height={'20px'}>
              <img src={alternativeProduct?.brand.image_url} height={'100%'} style={{ objectFit: 'contain' }} />
            </Box>
            <TextTooltip title={alternativeProduct.name} textRef={textRef}>
              <Typography
                ref={textRef}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant='h6'
                fontWeight='700'
                className='underline-on-hover cursor-pointer'
                my={1}
                onClick={detailsPopup.handleOpen}
              >
                {alternativeProduct.name}
              </Typography>
            </TextTooltip>
          </Box>
        </Grid>
        {(environment === ProductManagerEnv.purchase_order ||
          environment === ProductManagerEnv.edit_po_from_scratch) && (
          <SimilarProductRequisitionButton product={alternativeProduct} seller={seller} />
        )}
        {environment === ProductManagerEnv.quote && <SimilarProductQuoteButton product={alternativeProduct} />}
        {(environment == ProductManagerEnv.requisition || environment == ProductManagerEnv.edit_bundle_rfq) && (
          <Grid md={12} p={1}>
            <AlternativeProductRequisitionButton
              product={alternativeProduct}
              mainProductIsInCart={mainProduct.inCart}
            />
          </Grid>
        )}
        {environment == ProductManagerEnv.catalog && (
          <SimilarProductCatalogDraftListButton product={alternativeProduct} />
        )}
      </Box>
    </>
  );
};
