import { CompanyDataForm, Loading, UserDataForm } from "@/components";
import { Grid, Typography } from "@mui/material";
import { ResponsiveContainer } from "@/styles";
import { useTranslation } from 'next-i18next';
import React, { useState, useEffect } from "react";
import { Formik, useFormikContext } from 'formik';
import { initialValues } from './initialValues'
import { validationSchema } from './validationSchema'
import { useRegisterData } from '@/zustand'
import { apiURLs } from '@/config';
import axios from 'axios'
import { useRouter } from 'next/router';
import { getRegisterTranslationKey } from '@/hooks'
import { sm } from "src/site-map";
import useLoadingState from "src/logic/hooks/use-loading-state";

interface RegisterProps {
    isBuyer?: boolean,
    emailInvitation?:string;
    companyIdInvitation?:number;
    companyNameInvitation?:string;
    companyCountryInvitation?:string;
    companyCityInvitation?:string;
    companyIndustryInvitation?:string;
    companyAddressInvitation?:string;
    companyFaxInvitation?:number;       
    companyTrnInvitation?:number;
    companyWorkNumberInvitation?:number;
    invitationId?:string;
}

const AutoValidateOnStepChange = ({ step }: { step: number }) => {
    const { validateForm } = useFormikContext();

    React.useEffect(() => {
        validateForm();
    }, [step])

    return null;
}

export const RegisterForm: React.FC<RegisterProps> = (props: RegisterProps) => {

    const { t } = useTranslation()
    const [step, setStep] = useState(0)
    const router = useRouter();

    const currentValidationSchema = validationSchema[step];

    const getRegisterData = useRegisterData(state => state.getRegisterData);
    const isLoadingRegisterData = useRegisterData(state => state.isLoading);
    const titles = useRegisterData(state => state.titles)
    const countries = useRegisterData(state => state.countries)
    const industries = useRegisterData(state => state.industries)
    const upload = useRegisterData(state => state.upload)
    const toggleUpload = useRegisterData(state => state.toggleUpload)
    const requestAdminRoles = useRegisterData(state => state.requestAdminRoles)
    const toggleRequestAdminRoles = useRegisterData(state => state.toggleRequestAdminRoles)
    const setCountry = useRegisterData(state => state.setCountry)
    const setCompany = useRegisterData(state => state.setCompany)
    const getCities = useRegisterData(state => state.getCities)
    const companies = useRegisterData(state => state.companies)
    const company = useRegisterData(state => state.company)
    const searchCompanies = useRegisterData(state => state.searchCompanies)
    const getSelectedCompanyIndustry = useRegisterData(state => state.getSelectedCompanyIndustry)
    const getSelectedCompanyCountry = useRegisterData(state => state.getSelectedCompanyCountry)
    const getSelectedCompanyCity = useRegisterData(state => state.getSelectedCompanyCity)

    useEffect(() => {
        getRegisterData();
    }, []);

    const loadingState = useLoadingState();
    const submitForm = (values, actions) => {
        var formData = new FormData()
        Object.entries(values).forEach(entry => {
            const [key, value] = entry;
            if (value) formData.append(key, value)
        });
        loadingState.startLoading();
        axios.post(apiURLs.register, formData).then((response) => {
            router.push({pathname: sm.register.thankYou.url,query: {email: values.email }})
        }).catch((exception) => {
            loadingState.finishLoading();
            if (exception.response?.status == 422) {
                const fieldsWithError = Object.keys(exception.response.data.errors)
                const fieldsInStepOne = Object.keys(validationSchema[0].fields)
                if (fieldsWithError.some(r => fieldsInStepOne.indexOf(r) >= 0))
                    setStep(0)
                actions.setErrors(exception.response.data.errors)
                loadingState.finishLoading();
            }
            else
                router.push(sm.register.somethingWentWrong.url)
        });
    }

    const handleSubmit = (values, actions) => {
        if (step == 1)
            submitForm(values, actions)
        else
            setStep(step + 1)
        actions.setTouched({});
        actions.setSubmitting(false);
    }

    if(isLoadingRegisterData) {
        return <Loading fullHeight />
    }
    return (
        <>
            <Grid item xs={12} sx={{ pt: 4 }} component={ResponsiveContainer}>
                <Typography variant="h1" sx={{ fontWeight: 'bolder' }}> {props.isBuyer ? t(getRegisterTranslationKey('headers.register_as_buyer')) : t(getRegisterTranslationKey('headers.register_as_seller'))} </Typography>
            </Grid>
            <Formik initialValues={initialValues(props.isBuyer,props.emailInvitation,props.companyIdInvitation,  props.invitationId)} 
                validationSchema={currentValidationSchema} onSubmit={handleSubmit} 
                isolatedFields={true} validateOnBlur={true} validateOnChange={true} 
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <AutoValidateOnStepChange step={step} />
                        <Grid item xs={12} sx={{ py: 2, pb: 4 }} component={ResponsiveContainer}>
                            {
                                step == 0 ?
                                    <UserDataForm titles={titles} disabledButton={!(formikProps.isValid && formikProps.dirty)} 
                                        email_invitation={props.emailInvitation}
                                    />
                                    :
                                    <CompanyDataForm
                                        countries={countries}
                                        industries={industries}
                                        onBack={(() => setStep(0))}
                                        isBuyer={props.isBuyer}
                                        upload={upload}
                                        toggleUpload={toggleUpload}
                                        requestAdminRoles={requestAdminRoles}
                                        toggleRequestAdminRoles={toggleRequestAdminRoles}
                                        setCountry={setCountry}
                                        setCompany={setCompany}
                                        getCities={getCities}
                                        companies={companies}
                                        company={company}
                                        getSelectedCompanyIndustry={getSelectedCompanyIndustry}
                                        getSelectedCompanyCountry={getSelectedCompanyCountry}
                                        getSelectedCompanyCity={getSelectedCompanyCity}
                                        searchCompanies={searchCompanies}
                                        disabledButton={!(formikProps.isValid && formikProps.dirty)}
                                        isLoading={loadingState.isLoading}
                                        setFieldValue={formikProps.setFieldValue}
                                        FieldsValues={formikProps.values}
                                        company_Id_Invitation={props.companyIdInvitation}
                                        company_Name_Invitation={props.companyNameInvitation}
                                        company_Country_Invitation={props.companyCountryInvitation}
                                        company_City_Invitation={props.companyCityInvitation}
                                        company_Industry_Invitation={props.companyIndustryInvitation}
                                        company_Address_Invitation={props.companyAddressInvitation}
                                        company_Fax_Invitation={props.companyFaxInvitation}      
                                        company_Trn_Invitation={props.companyTrnInvitation}
                                        company_WorkNumber_Invitation={props.companyWorkNumberInvitation}
                                    />
                            }
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}
