import { ProcurementButtons, ProcurementForms, ProcurementPopupStatus, procurementStatus } from "@/config";
import { ProcurementFormModel } from "@/models";
import { NotifyError, ProcurementFormPermission } from "@/services";
import { useProcurementStore, useUserData } from "@/zustand";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import { FC } from "react";
import { sm } from "src/site-map";
import { EditAndRegenerateButton, EditResponseButton, FormMoreOptions, LinkedFormsIcon, SinglePODocument } from "src/ui/components/dumb";
import { StyledLoadingButton } from "src/ui/components/shared";
import { RelatedFormsList } from "../../../list-linked-forms";

interface PurchaseOrderHeaderActionsType {
    purchaseOrder: ProcurementFormModel | null
    isIndividualForm: boolean
    isAllTermsValid?: boolean
    onSendForApprovalRequested?: Function
    onSendToSellerRequested?: Function
}
export const PurchaseOrderHeaderActions: FC<PurchaseOrderHeaderActionsType> = ({ purchaseOrder, isIndividualForm, isAllTermsValid, onSendForApprovalRequested, onSendToSellerRequested }) => {
    const { push } = useRouter()
    const { t } = useTranslation();
    const { customLoadingButton, productsTableRows } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceProperties)
    const { fetchDetails, setPopupStatus, setCheckIfTermsValid, deleteSinglePO, handleDuplicateSinglePO, declineRequestExtension, requestExtensionForExpiredQuote } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions)
    const { canCreatePO } = useUserData()
    let truthyValues = productsTableRows?.filter(item => item.unitPrice);

    const handleSendForApproval = () => {
        onSendForApprovalRequested?.();
        if (isIndividualForm && !isAllTermsValid) {
            setCheckIfTermsValid(true)
        } else if (truthyValues && truthyValues.length !== productsTableRows.length) {
            NotifyError(t('notifications.missing_field', { fieldName: 'price' }))
        } else {
            setPopupStatus(ProcurementPopupStatus.SEND_FOR_APPROVAL)
        }
    }
    const handleApprove = () => setPopupStatus(ProcurementPopupStatus.APPROVE)
    const handleReject = () => setPopupStatus(ProcurementPopupStatus.REJECT)
    const handleCancel = () => setPopupStatus(ProcurementPopupStatus.CANCEL_APPROVAL)
    let canEditResponse = purchaseOrder?.actions[ProcurementFormPermission.EDIT_RESPONSE]
    const redirectToSourceForm = () => {
        if (purchaseOrder?.model?.comparison_table) {
            push(sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderId(purchaseOrder?.model?.bundle?.id).url)
        }
        else push({
            pathname: sm.portals.buyer.procurement.quotes.quoteId(purchaseOrder?.model?.quote?.id).url,
            query: { type: ProcurementForms.PO, id: purchaseOrder?.model?.id, name: purchaseOrder?.model?.name }
        })
    }

    const handleSendToSeller = () => {
        onSendToSellerRequested?.();
        if (isIndividualForm && !isAllTermsValid) {
            setCheckIfTermsValid(true)
        } else if (truthyValues && truthyValues.length !== productsTableRows.length) {
            NotifyError(t('notifications.missing_field', { fieldName: 'price' }))
        } else {
            setPopupStatus(ProcurementPopupStatus.SENT_TO_SELLER)
        }
    }
    const handleRecallRequest = () => setPopupStatus(ProcurementPopupStatus.RECALL)
    // const handleAddProducts = () => {
    //     fetchDetails(purchaseOrder?.model.id, (po) => {
    //         setDetails(po, productManagerEnv.purchase_order)
    //         push({ pathname: sm.products.index.url, query: { 'seller-id': purchaseOrder?.model.seller_details?.id } })
    //     })
    // }

    const handleCancelAction = () => setPopupStatus(ProcurementPopupStatus.CANCEL)

    const handleDeleteSinglePO = () => deleteSinglePO(purchaseOrder?.model?.id, () => push(sm.portals.buyer.procurement.purchaseOrders.index.url))

    const handleDuplicate = (project: any) => {
        handleDuplicateSinglePO(purchaseOrder?.model?.id, project, (id: any) => push(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(id).url))
    }

    const handleDeclineRequestExtension = () => {
        declineRequestExtension(purchaseOrder?.model?.id)
    }

    const handleRequestExtensionForExpiredQuote = () => {
        requestExtensionForExpiredQuote(purchaseOrder?.model?.quote?.id)
    }

    const handleEditFromScratch = () => {
        push(sm.portals.buyer.procurement.purchaseOrders.edit(purchaseOrder?.model?.id).url)
    }

    const editButtonLabel = purchaseOrder?.model.status === procurementStatus.DECLINED ? "Edit And Resend" : "Edit"
    return (
        <>
            {purchaseOrder?.actions[ProcurementFormPermission.REQUEST_EXTENSION_FOR_EXPIRED_QUOTE_BUYER] && <StyledLoadingButton customWidth variant="contained" translateKey="request_extension" loading={customLoadingButton == ProcurementButtons.REQUEST_EXTENSION} onClick={handleRequestExtensionForExpiredQuote} />}
            {purchaseOrder?.actions[ProcurementFormPermission.DECLINE_REQUEST_EXTENSION] && <StyledLoadingButton customWidth variant="outlined" translateKey="decline_request" loading={customLoadingButton == ProcurementButtons.DECLINE_REQUEST_EXTENSION} color={'error'} onClick={handleDeclineRequestExtension} />}
            {purchaseOrder?.actions[ProcurementFormPermission.EDIT] && !isIndividualForm && <EditAndRegenerateButton editAndRegenerateHandler={redirectToSourceForm} hidePopup />}
            {purchaseOrder?.actions[ProcurementFormPermission.EditAsMainAction] && !isIndividualForm && <EditAndRegenerateButton editAndRegenerateHandler={redirectToSourceForm} hidePopup mainAction={true} customName={editButtonLabel} />}
            {/* {purchaseOrder?.actions[ProcurementFormPermission.EditAndSend] && !isIndividualForm && <EditAndRegenerateButton editAndRegenerateHandler={redirectToSourceForm} hidePopup mainAction={true} customName={'Edit & Resend'} />} */}

            {purchaseOrder?.actions[ProcurementFormPermission.EDIT_FROM_SCRATCH] && isIndividualForm && <EditAndRegenerateButton editAndRegenerateHandler={handleEditFromScratch} hidePopup mainAction={purchaseOrder?.model.status === procurementStatus.DECLINED} customName={editButtonLabel} />}

            {/* {purchaseOrder?.actions[ProcurementFormPermission.ADD_PRODUCTS] && isIndividualForm && <Button variant="containedInfo" color='primary' onClick={handleAddProducts}>
                {t('buttons.add_products')}
            </Button>} */}
            {purchaseOrder?.actions[ProcurementFormPermission.SEND_TO_SELLER] && purchaseOrder?.model?.products?.length != 0 && <Button variant="contained" sx={{ px: 4, mr: 1 }} onClick={handleSendToSeller}> {t("buttons.send_to_seller")} </Button>}

            {purchaseOrder?.actions[ProcurementFormPermission.SEND_FOR_APPROVAL] && <Button variant='contained' onClick={handleSendForApproval}>{t('buttons.send_for_approval')}</Button>}

            {purchaseOrder?.actions[ProcurementFormPermission.CANCEL_APPROVAL] && <Button variant='outlined' color='error' onClick={handleCancel}>
                {t('buttons.cancel_approval_request')}
            </Button>}
            {purchaseOrder?.actions[ProcurementFormPermission.REJECT] && !canEditResponse && <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={handleReject}> {t("buttons.reject")} </Button>}
            {purchaseOrder?.actions[ProcurementFormPermission.APPROVE] && !canEditResponse && <Button variant="contained" color="success" sx={{ mr: 1 }} onClick={handleApprove}> {t("buttons.approve")} </Button>}
            {canEditResponse && <EditResponseButton>
                {purchaseOrder?.actions[ProcurementFormPermission.APPROVE] && <Button startIcon={<CheckCircleOutline />} sx={{ color: "success.dark" }} onClick={handleApprove}>
                    {t('buttons.approve')}
                </Button>}

                {purchaseOrder?.actions[ProcurementFormPermission.REJECT] && <Button startIcon={<HighlightOff />} color="error" onClick={handleReject}>
                    {t("buttons.reject")}
                </Button>}
            </EditResponseButton>}


            {purchaseOrder?.model?.linkedforms?.length != 0 && <LinkedFormsIcon><RelatedFormsList forms={purchaseOrder?.model?.linkedforms} /></LinkedFormsIcon>}


            <FormMoreOptions
                shareURL={purchaseOrder?.model.share_url}
                deleteAction={purchaseOrder?.actions[ProcurementFormPermission.DELETE]}
                pdfDocument={<SinglePODocument po={purchaseOrder} companyDetails={purchaseOrder?.model?.seller_details} productsTableRows={productsTableRows} />}
                disableDownload={!purchaseOrder?.model?.products?.length}
                handleDeleteAction={handleDeleteSinglePO}
                formType={ProcurementForms.PO}
                formName={purchaseOrder?.model?.name}
                duplicateAction={canCreatePO}
                handleDuplicateAction={(data) => handleDuplicate(data)}
                disableDuplicate={!purchaseOrder?.model?.products?.length}
                currentProject={purchaseOrder?.model?.project?.id}
                recallAction={purchaseOrder?.actions[ProcurementFormPermission.RECALL]}
                handleRecallAction={handleRecallRequest}
                cancelAction={purchaseOrder?.actions[ProcurementFormPermission.CANCEL_FORM]}
                handleCancelAction={handleCancelAction}
            />
        </>
    )
}