import { ProcurementButtons, ProcurementForms } from '@/config';
import { ComparisonTablesData, GeneralObject } from '@/models';
import { useUserData } from '@/zustand';
import { Close } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Button, Checkbox, Chip, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CTFormPermission } from 'src/logic/services/procurement/comparison-table';
import { sm } from 'src/site-map';
import { EditProject } from 'src/ui/components/dumb';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import {
  StyledBreadcrumbs,
  StyledLoadingButton
} from 'src/ui/components/shared';
import GeneralTerms from 'src/ui/components/smart/general-terms/GeneralTerms';
import SellersList from './sellers-list';

type CTHeaderProps = {
  comparisonTableData: ComparisonTablesData | null;
  generatePOFromCT: () => void;
  EditCT: () => void;
  isBundlePO: boolean;
  purchaseOrderId: string;
  // popupDoneGenerated: boolean
  // setPopupDoneGenerated: (isOpen: boolean) => void
  isShowSpecificQuotes: boolean;
  updateIsShowSpecificQuotes: (value: boolean) => void;
  changeProjectName?: (project: GeneralObject) => void;
  customLoadingButton: ProcurementButtons | null;
  relatedType?: ProcurementForms | undefined;
  relatedId?: number | undefined;
  relatedName?: string | undefined;
};

export const CTHeader: React.FC<CTHeaderProps> = ({
  comparisonTableData,
  customLoadingButton,
  generatePOFromCT,
  EditCT,
  isBundlePO,
  purchaseOrderId,
  relatedType,
  relatedId,
  relatedName,
  isShowSpecificQuotes,
  updateIsShowSpecificQuotes,
  changeProjectName,
}) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const userInfo = useUserData(state => state.userInfo);
  const canEdit = userInfo?.id === comparisonTableData?.owner_id;

  // const redirectToPOTable = () => {
  //     push({ pathname: sm.portals.buyer.procurement.purchaseOrders.index.url })
  //     handleClosePopup()
  // }
  // const handleView = () => {
  //     isBundlePO ? push({ pathname: sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(purchaseOrderId).url }) : push({ pathname: sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(purchaseOrderId).url })
  //     handleClosePopup()
  // }
  // const handleClosePopup = () => setPopupDoneGenerated(false)
  const handleCancelEdit = () => {
    relatedType == ProcurementForms.BUNDLE_PO
      ? push({ pathname: sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(relatedId).url })
      : push({ pathname: sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(relatedId).url });
  };
  // const handleViewPO = item => {
  //   item.form_type == LinkType.BUNDLE_PO
  //     ? push({ pathname: sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(item.id).url })
  //     : push({ pathname: sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(item.id).url });
  // };

  return (
    <Grid container item md={12}>
      <Grid item md={12} my={1} justifyContent='space-between'>
        <StyledBreadcrumbs
          path={sm.portals.buyer.procurement.comparisonTables.index.url}
          showBackButton
          alias={sm.portals.buyer.procurement.comparisonTables.ctId('*').url}
          dynammicAlias={[comparisonTableData?.name]}
        />
      </Grid>

      {/* <PageDetailsHeader> */}
      <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px', mt: 1 }}>
        <Grid container md={12} px={2} py={1}>
          <Grid item container md={6} alignItems='center' gap={1}>
            <Typography variant='h5' fontWeight={700}>
              {relatedName ? relatedName : comparisonTableData?.name}
            </Typography>
            {relatedName && (
              <Chip label={t('chip.EDITING')} variant='outlined' sx={{ minWidth: '144px', height: '28px', ml: 1 }} />
            )}
          </Grid>
          <Grid item container gap={1} md={6} alignItems='center' justifyContent={'end'}>
            {relatedType ? (
              <>
                <StyledLoadingButton
                  type={'submit'}
                  customWidth
                  variant='contained'
                  translateKey='save_changes'
                  loading={customLoadingButton == ProcurementButtons.GENERATE_PO_FROM_CT}
                  color={'success'}
                  onClick={relatedType ? EditCT : generatePOFromCT}
                />
                <Button type='button' color='error' onClick={handleCancelEdit} startIcon={<Close />} sx={{ pt: 1 }}>
                  {t('buttons.cancel')}
                </Button>
              </>
            ) : null}
            {
              !relatedType && comparisonTableData?.actions[CTFormPermission.GENERATE_PO] && (
                <StyledLoadingButton
                  customWidth
                  variant='contained'
                  onClick={relatedType ? EditCT : generatePOFromCT}
                  translateKey={
                    comparisonTableData?.actions[CTFormPermission.VIEW_PO]
                      ? 'generate_new_purchase_order'
                      : 'generate_po'
                  }
                  loading={customLoadingButton == ProcurementButtons.GENERATE_PO_FROM_CT}
                />
              )
              // <Button variant='contained' onClick={relatedType ? EditCT : generatePOFromCT} sx={{ px: 3 }}>{t('buttons.generate_po')}</Button>
            }
            {/* {!relatedType && comparisonTableData?.actions[CTFormPermission.VIEW_PO] && (
              <ButtonWithDropdownMenu
                translateKeySingleItem='view_po'
                dropDownItems={comparisonTableData?.purchase_orders}
                onClickItem={handleViewPO}
                translateKey='view_pos'
              />
            )} */}
            {/* <ExitForm exitFormHandler={() => push(sm.portals.buyer.procurement.comparisonTables.index.url)} /> */}
            {/* {!relatedType && <IconButton color='primary'><MoreVert /></IconButton>} */}
          </Grid>
          <Grid item md={12}>
            {' '}
            <Divider sx={{ opacity: 0.7, my: 1 }} />{' '}
          </Grid>
          <Grid container md={12} alignItems='center' my={1}>
            <Grid item container md={3} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant='body2' fontWeight={400} color='text.third'>
                  {t('texts.created_on')} {comparisonTableData?.created_at}
                </Typography>
                <Typography variant='body2' fontWeight={400} color='text.third'>
                  {t('texts.by')} {comparisonTableData?.owner_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' fontWeight={400} color='text.third'>
                  {t('texts.products')}: {comparisonTableData?.numberOfProducts}
                </Typography>
                <Typography variant='body2' fontWeight={400} color='text.third'>
                  {t('texts.services')}: {comparisonTableData?.numberOfServices}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container md={9} justifyContent={'flex-end'}>
              <Grid item container md={4}>
                <Grid item md={2}>
                  <Box
                    width='100%'
                    height='100%'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgb(137, 177, 198, 0.5)',
                      borderRadius: '4px',
                    }}
                  >
                    <BadgeIcon sx={{ color: 'white' }} />
                  </Box>
                </Grid>
                <Grid item md={9} pl={1}>
                  <Grid item container md={12} gap={3} alignItems={'center'}>
                    <Grid item md={6}>
                      <Typography
                        variant='body2'
                        fontWeight={400}
                        color='text.third'
                        sx={{ textTransform: 'capitalize' }}
                      >
                        Internal Project
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container md={12}>
                    <Grid item md={11} style={{ display: 'flex' }}>
                      <Link
                        href={sm.portals.buyer.projects.projectId(comparisonTableData?.project?.id?.toString()).url}
                      >
                        <Typography
                          sx={{ cursor: 'pointer', mt: 0.7 }}
                          className='underline-on-hover'
                          variant='h6'
                          lineHeight={1.2}
                          fontWeight={700}
                          color='text.disabled'
                        >
                          {comparisonTableData?.project?.name}
                        </Typography>
                      </Link>
                      {canEdit && (
                        <EditProject
                          sx={{ ml: -1 }}
                          formModel={comparisonTableData}
                          updateProject={changeProjectName}
                          projectId={comparisonTableData?.project?.id}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container md={3}>
                <Grid item md={3}>
                  <Box
                    width='100%'
                    height='100%'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgb(137, 177, 198, 0.5)',
                      borderRadius: '4px',
                    }}
                  >
                    <BadgeIcon sx={{ color: 'white' }} />
                  </Box>
                </Grid>
                <Grid item pl={1}>
                  <Typography variant='body2' fontWeight={400} color='text.third' sx={{ textTransform: 'capitalize' }}>
                    {t('texts.rfq_sent_to')}
                  </Typography>
                  <Typography variant='h6' fontWeight={700} color='text.disabled'>
                    <Grid item container justifyContent={'space-between'}>
                      <Grid item>
                        {comparisonTableData?.competitors} {t('texts.sellers')}
                      </Grid>
                      <Grid item pt={0.2}>
                        <SellersList sellers={comparisonTableData?.request_for_quotes} />
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container md={2}>
                <Grid item md={3}>
                  <Box
                    width='100%'
                    height='100%'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgb(137, 177, 198, 0.5)',
                      borderRadius: '4px',
                    }}
                  >
                    <BadgeIcon sx={{ color: 'white' }} />
                  </Box>
                </Grid>
                <Grid item pl={1}>
                  <Typography variant='body2' fontWeight={400} color='text.third' sx={{ textTransform: 'capitalize' }}>
                    {t('texts.received')}
                  </Typography>
                  <Typography variant='h6' fontWeight={700} color='text.disabled'>
                    {comparisonTableData?.received_quotes_count}{' '}
                    {comparisonTableData?.received_quotes_count == 1 ? 'Quote' : t('texts.quotes')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item container md={2}>
                            <Box width='40px' height='40px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'rgb(137, 177, 198, 0.5)', borderRadius: '4px' }}>
                                <BadgeIcon sx={{ color: 'white' }} />
                            </Box>
                            <Grid item pl={1}>
                                <Typography variant="subtitle2" fontWeight={400} color="text.third">{t('table_cells.rfq') + " " + t('texts.valid_until')}</Typography>
                                <Typography variant="h6" fontWeight={700} color="text.disabled">{comparisonTableData?.validity_date}</Typography>
                            </Grid>
                        </Grid> */}
          </Grid>
        </Grid>
      </Box>
      {/* </PageDetailsHeader> */}

      <Grid container md={12} justifyContent='flex-end' alignItems='center' my={1}>
        <GeneralTerms
          mode='view-only'
          warranty_term={comparisonTableData?.termsBundle?.warranty_term}
          payment_term={comparisonTableData?.termsBundle?.payment_term}
          delivery_term={comparisonTableData?.termsBundle?.delivery_term}
        />
      </Grid>

      <Grid container md={12} alignItems='center' justifyContent='space-between' pt={3}>
        <Grid item>
          <Grid container alignItems='center'>
            <Typography variant='overline' fontWeight={700}>
              {t('texts.products')} / {t('texts.services')}
            </Typography>
            <PrimaryBadge>{comparisonTableData?.numberOfProducts + comparisonTableData?.numberOfServices }</PrimaryBadge>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems='center'>
            <Checkbox
              size='small'
              sx={{ color: 'primary.main', mb: 0.3 }}
              checked={isShowSpecificQuotes}
              onChange={e => updateIsShowSpecificQuotes(e.target.checked)}
            />
            <Typography variant='body2' fontWeight={700} ml={-1} color='primary.main'>
              {t('buttons.show')}
            </Typography>
            <Typography variant='body2' fontWeight={400} pl={0.5} color='info.main'>
              {t('texts.specific_quotes')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* <DescPopup open={popupDoneGenerated} handleClose={handleClosePopup}>
                <Grid container md={12} gap={2} width={300} p={2}>
                    <Grid item md={12} textAlign='center'>
                        <TaskAltIcon sx={{ color: 'success.dark', fontSize: '40' }} />
                        <Grid item md={12} textAlign='center'>
                            <Typography variant='overline' color='success.dark' fontWeight={700} my={1}>
                                {isBundlePO ? t('popups.titles.bundle_PO_Generated') : t('popups.titles.PO_Generated')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent={"center"} textAlign="center" md={12}>
                        <Typography variant="body2" color="text.disabled">
                            {isBundlePO ? t('popups.subTitles.bundle_PO_Generated') : t('popups.subTitles.PO_Generated')}
                        </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <Button variant="outlined" onClick={redirectToPOTable} sx={{ color: 'text.disabled', fontWeight: 500 }} fullWidth>{t('buttons.view_my_purchase_orders')}</Button>
                    </Grid>
                    <Grid item md={6}>
                        <Button variant="contained" fullWidth onClick={handleView}>{isBundlePO ? t('buttons.view_bundle_po') : t('buttons.view_po')}</Button>
                    </Grid>
                </Grid>
            </DescPopup> */}
    </Grid>
  );
};
