import { useTranslation } from 'next-i18next';
import { company, user, RoundedImageWithText } from '@/components';
import { statusesTableData } from '@/config';
import { useUpdateProfileImage } from '@/services';
import { useMediaQuery } from '@mui/material';

type Props = { userData: user; companyData: company };

export const UserInfoSection: React.FC<Props> = ({
  userData,
  companyData,
}) => {

  const { t } = useTranslation();
  const userTypeConfig = statusesTableData[userData.account_status];
  const { mutate } = useUpdateProfileImage();
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));

  return (
    <RoundedImageWithText
      title={userData.first_name + ' ' + userData.last_name}
      subtitle={userData.company_name}
      chipContent={t("account_status." + userTypeConfig.text)}
      chipColor={userTypeConfig.color}
      chipTextColor={userTypeConfig.textColor}
      profilePictureUrl={userData.profile_picture_url}
      changePicture={(data) => mutate(data)}
      isSmallScreen={isSmallScreen}
      editImage={true}
    />
  );
};
