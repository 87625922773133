import { Grid } from "@mui/material"
import { ReactNode, useEffect } from "react";
import { useProcurementStore } from "@/zustand";
import { PopupStatus } from "@/config";
import { ProcurementActionsPopupContent } from '@/components'
import { Formik } from "formik";
import initialValues from './initialValues'
import { commentsValidationSchema, commentsWithReasonValidationSchema } from './validationSchema'

type BundleRFQPopupContentProps = {
    icon: ReactNode
    popupTitle: string
    handlePopupAction: (values: any) => void
    buttonTitle: string
    status: string
    popupSubTitle?: string
    commentNote?: string | null
    titleColor?: string
    checkLabel?: string
    isButtonLoading?: boolean
};


export const BundleRFQPopupContent: React.VFC<BundleRFQPopupContentProps> = ({ icon, popupTitle, popupSubTitle, handlePopupAction, buttonTitle, status, commentNote, titleColor, checkLabel, isButtonLoading }) => {
    const getReasonsForPopup = useProcurementStore(state => state.bundleRFQDetailsSliceActions.getReasonsForPopup)
    const openedPopupStatus = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.openedPopupStatus)
    const reasonsForPopup = useProcurementStore(state => state.bundleRFQDetailsSliceProperties.reasonsForPopup)
    let reasonsStatus = openedPopupStatus == PopupStatus.REJECT || openedPopupStatus == PopupStatus.REJECT_ALL || openedPopupStatus == PopupStatus.CANCEL_BUNDLE || openedPopupStatus == PopupStatus.CANCEL_RFQ
    const handleSubmit = (values, actions) => handlePopupAction(values)

    useEffect(() => {
        if (reasonsStatus) getReasonsForPopup()
    }, [openedPopupStatus]);

    return (
        <Grid container gap={2} width={300}>
            <Formik initialValues={initialValues} validationSchema={reasonsStatus ? commentsWithReasonValidationSchema : commentsValidationSchema} onSubmit={handleSubmit}>
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <ProcurementActionsPopupContent
                            icon={icon}
                            popupTitle={popupTitle}
                            titleColor={titleColor}
                            buttonTitle={buttonTitle}
                            status={status}
                            popupSubTitle={popupSubTitle}
                            reasonsForPopup={reasonsStatus ? reasonsForPopup : undefined}
                            cancelStatus={openedPopupStatus == PopupStatus.CANCEL_BUNDLE || openedPopupStatus == PopupStatus.CANCEL_RFQ}
                            checkLabel={checkLabel}
                            isButtonLoading={isButtonLoading}
                        />
                    </form>
                )}
            </Formik>
        </Grid>
    )
}