import { styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  '@keyframes sk-stretchdelay': {
    '0%, 40%, 100%': {
      transform: 'scaleY(0.4)',
    },
    '20%': {
      transform: 'scaleY(1.0)',
    },
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1em',
  '& > div': {
    backgroundColor: theme.palette.secondary.main,
    height: '3em',
    width: '0.4em',
    margin: '0px 0.05em',
    display: 'inline-block',

    animation: 'sk-stretchdelay 1.2s infinite ease-in-out',
  },
  '& > div:nth-child(2)': {
    animationDelay: '-1.1s',
  },
  '& > div:nth-child(3)': {
    animationDelay: '-1s',
  },
  '& > div:nth-child(4)': {
    animationDelay: '-0.9s',
  },
  '& > div:nth-child(5)': {
    animationDelay: '-0.8s',
  },
}));

export const LoadingRect: React.VFC = ({}) => {
  return (
    <Root>
      <div />
      <div />
      <div />
      <div />
      <div />
    </Root>
  );
};
