export enum Permissions {
    EDIT_COMPANY = "EDIT_COMPANY",
    EDIT_USERS = "EDIT_USERS",
    portal_access_permission = "portal_access_permission",
    create_rfq = "create_rfq",
    create_po = "create_po",
    update_catalog = "update_catalog",
    generate_quote = "generate_quote",
    GENERATE_PROFORMA_INVOICE_PERMISSION = "GENERATE_PROFORMA_INVOICE_PERMISSION",
    GENERATE_TAX_INVOICE_PERMISSION = "GENERATE_TAX_INVOICE_PERMISSION"
}