import {
  AppBar,
  Box,
  Grid,
  ListItemButton as _ListItemButton,
  Toolbar,
} from '@mui/material';
import { Link, Logo, NotificationMenu } from '@/components';
import React from 'react';
import { sm } from 'src/site-map';
import { SearchBar } from '../../dumb/header/SearchBar';
import SearchIcon from '@mui/icons-material/Search';
import { MobileDrawer } from '../drawer-mobile'
import { AuthenticateStatus } from '@/config';

const notifications = [
  {
    title: 'review_mode',
    date: 'Apr 10:50',
    from: 'requisition',
  },
  {
    title: 'cameron_accepted',
    date: '6 Apr 10:50',
    from: 'users',
  },
];

type Props = {
  authenticatedStatus?: number;
  userType?: number
};

export const MobileNavbar: React.FC<Props> = (props) => {
  const [searching, setSearching] = React.useState(false);

  return (
    <Box sx={{ height: 56, width: '100%' }}>
      <Grid container>
        <AppBar
          color="inherit"
          sx={{
            position: "static",
            justifyContent: 'center',
            display: 'flex',
            height: 56,
          }}
        >
          {searching ? (
            <SearchBar mobile={true} backFun={() => setSearching(false)} />
          ) : (
            <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid container>
                <Grid item xs={2} container alignItems="center">
                  <MobileDrawer userType={props.userType} />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Link href={sm.home.url} underline='none' sx={{ display: 'flex', alignItems: 'center'}} >
                    <Logo />
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <SearchIcon color='primary' onClick={() => setSearching(true)} />
                  {props.authenticatedStatus == AuthenticateStatus.AUTHENTICATED && <NotificationMenu notifications={notifications} />}
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </AppBar>
        <Toolbar />
      </Grid>
    </Box>
  );
};
