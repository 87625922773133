import { SvgIconProps, SvgIcon } from '@mui/material';

export const CatalogueIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='33'
    height='33'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.73584 4.2002H27.1108L30.4 11.6434V29.4002H1.59998V11.6526L4.73584 4.2002ZM6.06412 6.2002L4.10568 10.8545H27.8648L25.808 6.2002H6.06412ZM28.4 12.8545H21.2757V20.7213L15.8037 18.0827L10.4 20.7332V12.8545H3.59998V27.4002H28.4V12.8545ZM12.4 12.8545V17.5246L15.7962 15.8588L19.2757 17.5365V12.8545H12.4Z'
      fill={props.color}
    />
    <path
      d='M22.5687 10.2212V9.03418H23.5355C23.779 9.03418 23.9939 8.98942 24.1801 8.8999C24.3663 8.81038 24.5113 8.68506 24.6151 8.52393C24.719 8.36279 24.7709 8.1766 24.7709 7.96533C24.7709 7.77197 24.7225 7.59831 24.6259 7.44434C24.5292 7.29036 24.3895 7.16862 24.2069 7.0791C24.0243 6.98958 23.8077 6.94482 23.557 6.94482C23.1631 6.94482 22.8516 7.0415 22.6224 7.23486C22.3933 7.42464 22.2644 7.6932 22.2357 8.04053H20.7372C20.7551 7.56429 20.884 7.14714 21.1239 6.78906C21.3638 6.43099 21.6968 6.15169 22.1229 5.95117C22.549 5.75065 23.0539 5.65039 23.6376 5.65039C24.0673 5.65039 24.4468 5.70589 24.7763 5.81689C25.1093 5.92432 25.3886 6.07471 25.6141 6.26807C25.8433 6.46143 26.0152 6.68343 26.1298 6.93408C26.2479 7.18473 26.307 7.4515 26.307 7.73438C26.307 8.0459 26.2444 8.32161 26.119 8.56152C25.9973 8.80143 25.829 9.00016 25.6141 9.15771C25.4029 9.31527 25.1576 9.42806 24.8783 9.49609V9.59814C25.2364 9.65186 25.5443 9.76107 25.8021 9.92578C26.0635 10.0905 26.2641 10.3035 26.4037 10.5649C26.5433 10.8263 26.6132 11.1307 26.6132 11.478C26.6132 11.8182 26.538 12.1297 26.3876 12.4126C26.2372 12.6955 26.0259 12.9408 25.7538 13.1484C25.4817 13.3561 25.1612 13.5155 24.7924 13.6265C24.4236 13.741 24.0207 13.7983 23.5839 13.7983C23.147 13.7983 22.7478 13.741 22.3861 13.6265C22.0245 13.5119 21.7112 13.349 21.4462 13.1377C21.1812 12.9229 20.9735 12.6668 20.8231 12.3696C20.6727 12.0724 20.5886 11.7412 20.5707 11.376H22.1229C22.1408 11.5944 22.2107 11.7842 22.3324 11.9453C22.4542 12.1064 22.6207 12.2318 22.8319 12.3213C23.0432 12.4072 23.2867 12.4502 23.5624 12.4502C23.8381 12.4502 24.0798 12.4036 24.2875 12.3105C24.4988 12.2174 24.6635 12.0868 24.7816 11.9185C24.8998 11.7502 24.9589 11.555 24.9589 11.333C24.9589 10.9893 24.8318 10.7189 24.5775 10.522C24.3269 10.3215 23.9849 10.2212 23.5516 10.2212H22.5687Z'
      fill={props.color}
    />
  </SvgIcon>
);
