import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { useProfile, useUserData } from '@/zustand'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next';
import { NotifyError, NotifySuccess } from '../Notify-toast'


export const fetchProfileInfo = async () => {
  return request({
    url: apiRoutes.profileInfo,
    method: "GET"
  })
}


export const useProfileInfo = () => {
  const setProfileData = useProfile(state => state.setProfileData);
  return useQuery(['profile'], () => fetchProfileInfo(), { onSuccess: (data) => { setProfileData(data.data) } })
}


const updateProfile = async (formData) => {
  return request({
    url: apiRoutes.updateProfile,
    method: "PUT",
    data: formData
  })
}
export const useUpdateProfile = ({ onSuccess }) => {
  const { t } = useTranslation();
  const updateProfileError = (data, variables) => {
    {
      Object.entries(data.data.errors).map(([k, v]) => (
        variables.actions.setFieldError(k, v[0])
      ))
    }
    NotifyError(t('notifications.server_error'));
  }
  return useMutation((formData) => updateProfile(formData.values), { onSuccess: onSuccess, onError: (data, variables) => updateProfileError(data, variables) })
}

const modifyAccessControl = async (data) => {
  return request({
    url: apiRoutes.modifyMyAccessControl,
    method: "PUT",
    data: data
  })
}
export const useAccessControl = () => {
  const setPermisisons = useProfile(state => state.setPermisisons);
  const setRoles = useProfile(state => state.setRoles);
  const setPermissionInUserDataStore = useUserData(state => state.setPermissionInUserDataStore);

  const { t } = useTranslation();
  const onAccessControlModified = (data) => {
    if (data.access_control_type == "PERMISSION") {
      setPermisisons(data.status, data.id)
      setPermissionInUserDataStore(data.status, data.id)
    }
    if (data.access_control_type == "ROLE") setRoles(data.status, data.id)
    return modifyAccessControl(data)
  }
  const onAccessControlError = (data, variables) => {
    if (variables.access_control_type == "PERMISSION") {
      setPermisisons(!variables.status, variables.id)
      setPermissionInUserDataStore(!variables.status, variables.id)
    }
    if (variables.access_control_type == "ROLE") setRoles(!variables.status, variables.id)
    NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => onAccessControlModified(data), { onError: onAccessControlError })
}


type uploadRequestType = { file: File, upload_type: string }
const uploadDocuments = (data: uploadRequestType) => {
  return request({
    url: apiRoutes.uploadDocument,
    method: "POST",
    data: data
  })
}
export const useUploadDocument = () => {
  const setCompanySettings = useProfile(state => state.setCompanySettings);
  const setCompanyInfo = useUserData(state => state.setCompanyInfo);
  const { t } = useTranslation();
  const onSuccess = (data) => {
    setCompanySettings(data.data)
    setCompanyInfo(data.data)
    NotifySuccess(t("notifications.upload_file"))
  }
  return useMutation((data: uploadRequestType) => uploadDocuments(data), { onSuccess: onSuccess, onError: () => NotifyError(t('notifications.server_error')) })
}


const deleteDocument = (document) => {
  return request({
    url: apiRoutes.deleteDocument + '/' + document.id,
    method: "DELETE",
  })
}
export const useDeleteDocument = () => {
  const { t } = useTranslation();
  const setCompanySettings = useProfile(state => state.setCompanySettings);
  const setCompanyInfo = useUserData(state => state.setCompanyInfo);
  const onSuccess = (data, variables) => {
    setCompanySettings(data.data)
    setCompanyInfo(data.data)
    NotifySuccess(t("notifications.delete_file"))
  }
  return useMutation((data) => deleteDocument(data.document), { onSuccess: onSuccess, onError: () => NotifyError(t('notifications.server_error')) })
}


const deleteAccount = async (form) => {
  return request({
    url: apiRoutes.user,
    method: "DELETE",
    data: form
  })
}
export const useDeleteAccount = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation((form) => deleteAccount(form), { onSuccess: onSuccess, onError: () => NotifyError(t('notifications.server_error')) })
}


type uploadRequestImageType = { data: File }
const updateProfileImage = (data: uploadRequestImageType) => {
  var formData = new FormData()
  formData.append('image', data)
  return request({
    url: apiRoutes.updateProfileImage,
    method: "POST",
    data: formData,
    headers:{'Content-Type':'multi-part/form-data'}
  })
}
export const useUpdateProfileImage = () => {
  const { t } = useTranslation();
  const setUserInfo = useProfile(state => state.setUserInfo);
  const setUserData = useUserData(state => state.setUserInfo);

  const onSuccess = (data) => { setUserInfo(data);setUserData(data); NotifySuccess(t('notifications.upload_file_image')) }
  return useMutation((data: uploadRequestImageType) => updateProfileImage(data), { onSuccess: (data) => onSuccess(data.data), onError: () => NotifyError(t('notifications.server_error')) })
}