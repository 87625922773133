import type { FC } from 'react';
import { Typography, TextField } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { getInputsTranslationKey } from '@/hooks'

interface FakeStyledTextFieldProps {
    text: string,
    translateKey: string,
    required?: boolean
    hideLabel?: boolean
}

const inputStyle = {
    width: '100%',
};
const textInputStyle = {
    bgcolor: 'white',
    height: '40px',
    color: '#000000',
    '& ::placeholder': {
        fontSize: '14px',
        color: '#000000',
        fontWeight: '400',
    },
};

export const FakeStyledTextField: FC<FakeStyledTextFieldProps> = (props: FakeStyledTextFieldProps) => {
    const { t } = useTranslation()
    return (
        <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block' }}
        >
            {!props.hideLabel && <> {t(getInputsTranslationKey('labels.' + props.translateKey))} {props.required ? ' *' : ''}</>}
            <TextField
                InputProps={{ sx: textInputStyle }}
                sx={inputStyle}
                value={props.text}
                disabled
            />
        </Typography>
    );
};

