import {
  CheckboxWithLabel,
  CustomWarrantyText,
  DescPopup,
  MultilineTextField,
  RemarkButton,
  StyledCheckbox,
  StyledTextField,
  TableWithPagination,
  TextFieldCounterForm,
} from '@/components';
import { ProductCategoryKind, ProductCategoryKindLabel, ProductTableRow } from '@/models';
import { useSellerProcurementStore } from '@/zustand';
import { Info, TextSnippetOutlined } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, Grid, ThemeOptions, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ColumnDef } from '@tanstack/react-table';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DescriptionIndicator } from '../../../requisition/requisition-alternatives-table/components';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';

const useStyles = makeStyles((theme: ThemeOptions) => ({
  cellHead: {
    color: '#8B8D98',
    fontSize: 16,
    fontWeight: 400,
    paddingLeft: 16,
    textTransform: 'uppercase',
    width: '400px',
    paddingBlock: 4,
  },
  cellHeadPaddingLeft: {
    paddingLeft: 40,
  },
  cellHeadpaddingRight: {
    paddingRight: 15,
  },
  columnMarginTop: {
    marginTop: 5,
    fontSize: 18,
  },
  columnNoPadding: {
    fontWeight: 400,
    padding: 0,
  },
  columnPaddingLeft: {
    paddingLeft: 40,
  },
  columnPaddingRight: {
    paddingRight: 10,
  },
  head: {
    backgroundColor: theme?.palette?.secondary.contrastText,
    '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 0 },
    '& th:last-child': { borderRadius: '0 6px 6px 0' },
  },
  rowBody: { borderRadius: '6px 6px 6px 6px', '& .MuiTableCell-root': { paddingBlock: 4 } },
  rowBodyWhite: { backgroundColor: theme?.palette?.secondary.contrastText },
  rowBodyRedBorder: {
    backgroundColor: theme?.palette?.secondary.contrastText,
    borderBottom: '1px solid #ff0000',
    '& .MuiTableCell-root': {
      borderBottom: '1px solid red',
      borderTop: '1px solid red',
    },
    '& .MuiTableCell-root:first-child': {
      borderLeft: '1px solid red',
    },
    '& .MuiTableCell-root:last-child': {
      borderRight: '1px solid red',
    },
  },
}));

type RFQProductsTableProps = {
  key: string;
  rowsData: ProductTableRow;
};
const EMPTY_ARR = [] as any[];

export const RFQProductsTable: React.FC<RFQProductsTableProps> = ({ key, rowsData }) => {
  const classes = useStyles();
  const { values, errors } = useFormikContext();
  const formikSlice = getIn(values, name) || EMPTY_ARR;
  const [tableRows, setTableRows] = useState(formikSlice);
  const { changeAvailableQuantity, changeUnitPrice, toggleSelectAllProducts } = useSellerProcurementStore(
    state => state.requestForQuoteSliceActions
  );
  const { isAllProductsSelected, requestForQuote } = useSellerProcurementStore(
    state => state.requestForQuoteSliceProperties
  );
  useEffect(() => {
    setTableRows(formikSlice);
  }, [formikSlice]);
  const columns = React.useMemo<ColumnDef<ProductTableRow>[]>(
    () => [
      {
        accessorKey: 'productDetails',
        id: 'productDetails',
        header: 'details',
        cell: ({ row: { index }, getValue }) => <ProductRFQDetails index={index} content={getValue()} />,
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHead,
          headerRender: (
            <StyledCheckbox
              value={isAllProductsSelected}
              onChange={value => toggleSelectAllProducts(value)}
              size='medium'
            />
          ),
          tableRow: {
            cellClass: classes.columnMarginTop,
          },
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
      // {
      //   accessorKey: 'brandImage',
      //   id: 'brandImage',
      //   header: 'brand',
      //   cell: info => (
      //     <Box width='88px'>
      //       {' '}
      //       <CardMedia
      //         component='img'
      //         sx={{ objectFit: 'contain', height: '25px' }}
      //         image={info.getValue()}
      //         alt={'image'}
      //       />
      //     </Box>
      //   ),
      //   meta: {
      //     filterType: 'search',
      //     columnIcon: UnfoldMoreIcon,
      //     headerCellClass: classes.cellHeadPaddingLeft,
      //   },
      //   enableColumnFilter: false,
      // },
      {
        accessorKey: 'warrantyTerm',
        id: 'warrantyTerm',
        header: 'warranty',
        cell: ({ row: { index, original }, getValue }) => (
          <CustomWarrantyText
            isWarranyTermCustom={
              requestForQuote?.rfqModel?.terms_bundle.warranty_term.id !=
              requestForQuote?.rfqModel?.products[index]?.warranty_term.id
            }
            warrantyName={getValue()}
          />
        ),
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHeadPaddingLeft,
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
      {
        accessorKey: 'availableQuantity',
        id: 'availableQuantity',
        header: 'available',
        cell: ({ row: { index, original } }) => {
          const { setFieldValue, values, errors } = useFormikContext();
          const isSelected = values[index].productDetails.selected;
          if (!isSelected) {
            return <p style={{ marginLeft: '8px', color: 'lightgray' }}>N/A</p>;
          }

          return (
            <TextFieldCounterForm
              onChange={value => changeAvailableQuantity(original, value)}
              name={`${name}[${index}].availableQuantity`}
              disabled={!values[index].productDetails.selected}
            />
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHeadpaddingRight,
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
      {
        accessorKey: 'requestedQuantity',
        id: 'requestedQuantity',
        header: 'requested',
        cell: info => <Quantity quantity={info.getValue()} />,
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          headerCellClass: classes.cellHeadpaddingRight,
          tableRow: {
            cellClass: classes.columnPaddingLeft,
          },
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
      {
        accessorKey: 'unitPrice',
        id: 'unitPrice',
        header: 'unit_price',
        cell: ({ row: { index, original } }) => {
          const { setFieldValue, values, errors, getFieldMeta } = useFormikContext();
          const isError = !!getFieldMeta(`${name}[${index}].unitPrice`).error;
          return (
            <Box width='88px' style={{ position: 'relative' }}>
              <StyledTextField
                type='number'
                disableErrorShow
                onChange={e => changeUnitPrice(original, e.target.value)}
                name={`${name}[${index}].unitPrice`}
                translateKey='unit_price'
                outlined
                disabled={!values[index].productDetails.selected}
              />
              {isError && (
                <Typography
                  variant='subtitle2'
                  color='red'
                  style={{
                    maxWidth: '100%',
                    lineHeight: 1,
                    position: 'absolute',
                    bottom: 0,
                    transform: 'translateY(calc(100% + 3px))',
                  }}
                >
                  This is Required
                </Typography>
              )}
            </Box>
          );
        },
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          tableRow: {
            cellClass: classes.columnPaddingRight,
          },
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
      {
        accessorKey: 'remark',
        id: 'remark',
        header: 'remarks',
        cell: ({ row: { index, original } }) => <RemarksButton rowIndex={index} row={original} />,
        meta: {
          filterType: 'search',
          columnIcon: UnfoldMoreIcon,
          tableRow: {
            cellClass: classes.columnPaddingRight,
          },
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
    ],
    [isAllProductsSelected]
  );
  const columnsWithErrors = React.useMemo<ColumnDef<ProductTableRow>[]>(
    () => [
      {
        accessorKey: 'productDetails',
        header: 'empty',
        cell: ({ row: { index, original } }) => {
          const { setFieldValue, values, errors } = useFormikContext();
          return <>{errors[index] && <Info color='error' />}</>;
        },
        meta: {
          filterType: 'search',
          columnIcon: null,
          tableRow: {
            cellClass: classes.columnPaddingRight,
          },
        },
        enableColumnFilter: false,
        enableSorting: false 
      },
    ],
    []
  );
  return (
    <>
      <TableWithPagination
        style={{ overflow: 'visible' }}
        disablePagination
        columns={errors.length != 0 ? [...columns, ...columnsWithErrors] : columns}
        data={tableRows}
        key={tableRows}
        tableMeta={{
          getRowStyle: index => {
            return !values[index].productDetails.selected
              ? classes.rowBody
              : errors[index]
              ? classes.rowBodyRedBorder
              : classes.rowBodyWhite;
          },
          headStyle: classes.head,
        }}
      />
    </>
    // <StyledTable key={'id'} rows={tableRows} columns={columns} whiteHeader />
  );
};

const ProductRFQDetails = ({ content, index }) => {
  const { t } = useTranslation();
  const { setFieldValue, values, errors } = useFormikContext();
  const [productDetailsPopup, setProductDetailsPopup] = useState(false);
  const openProductDetailsPopup = () => setProductDetailsPopup(true);
  const closeProductDetailsPopup = () => setProductDetailsPopup(false);
  const { toggleSelectProductFromRFQ } = useSellerProcurementStore(state => state.requestForQuoteSliceActions);
  const onChange = () => {
    toggleSelectProductFromRFQ(values[index]);
    // if (!values[index].productDetails.selected) setFieldValue(`${name}[${index}].availableQuantity`, values[index].requestedQuantity)
    // else setFieldValue(`${name}[${index}].availableQuantity`, 0)
  };

  return (
    <Grid container alignItems={'center'} pl={0}>
      <Grid item md={1}>
        <CheckboxWithLabel
          name={`${name}[${index}].productDetails.selected`}
          onChange={onChange}
          error={errors[index] ? true : false}
        />
      </Grid>
      <Grid item md={1.5} pl={2}>
        <Typography color='#3A3E50' fontWeight={400} fontSize={10} textTransform={'uppercase'}>
          {ProductCategoryKindLabel[content.category_kind]}
        </Typography>
      </Grid>
      <Grid item md={2} px={2}>
        <Box justifyContent={'center'} display={'flex'} alignItems={'center'}>
          <img style={{ objectFit: 'contain', width: '35px', height: '35px' }} src={content.image_url} alt={''} />
        </Box>
      </Grid>
      <Grid item md={7.5}>
        <div style={{ display: 'flex' }}>
          <Typography
            variant='body2'
            onClick={openProductDetailsPopup}
            color='text.disabled'
            fontWeight={600}
            sx={{ cursor: 'pointer' }}
            className='underline-on-hover'
            noWrap
          >
            {content.name}
          </Typography>
          {content.is_private_product && <PrivacyButton disableText />}
        </div>
        <Grid item md={12} container alignItems={'center'} gap='.5rem'>
          {/* <Typography variant='subtitle2' fontWeight={400} color={'text.third'} mr={1}>{t('texts.part_number')}</Typography> */}
          <DescriptionIndicator category_kind={content.category_kind} description={content.description} />
          {content.category_kind === ProductCategoryKind.PRODUCT ? (
            <Typography variant='subtitle2' fontWeight={500} color={'#8B8D98'}>
              {content.partNumber}
            </Typography>
          ) : (
            <BuyerRequirementsIndicator additional_requirements={values?.[index]?.additional_requirements} />
          )}
        </Grid>
      </Grid>
      <DescPopup big handleClose={closeProductDetailsPopup} open={productDetailsPopup} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView product={content} />
      </DescPopup>
    </Grid>
  );
};
const RemarksButton = (props: { rowIndex: number; row: ProductTableRow }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const { addRemark } = useSellerProcurementStore(state => state.requestForQuoteSliceActions);
  const [showAddRemarkPopup, setShowAddRemarkPopup] = useState(false);
  const openAddRemarkPopup = () => setShowAddRemarkPopup(true);
  const closeAddRemarkPopup = () => setShowAddRemarkPopup(false);

  const updateRemarkOnProduct = (remark: string) => {
    values[props.rowIndex].productDetails.selected && setFieldValue(`${name}[${props.rowIndex}].remark`, remark);
    addRemark(props.row, remark);
    closeAddRemarkPopup();
  };
  const removeRemark = () => updateRemarkOnProduct('');
  const editRemark = (remark: string) => updateRemarkOnProduct(remark);
  return (
    <>
      {values[props.rowIndex].remark ? (
        <RemarkButton
          remark={values[props.rowIndex].remark}
          contentDetails={values[props.rowIndex]}
          deleteRemarkHandler={removeRemark}
          handleRemarkChange={editRemark}
        />
      ) : (
        <>
          <Button onClick={openAddRemarkPopup} disabled={!values[props.rowIndex].productDetails.selected}>
            {t('buttons.add_remark')}
          </Button>
          <DescPopup open={showAddRemarkPopup} handleClose={closeAddRemarkPopup}>
            <RemarkPopupContent updateRemarkOnProduct={updateRemarkOnProduct} />
          </DescPopup>
        </>
      )}
    </>
  );
};
export const RemarkPopupContent = ({ updateRemarkOnProduct }) => {
  const { t } = useTranslation();
  const [remark, setRemark] = useState('');

  return (
    <Grid container gap={2} width={300}>
      <Grid item md={12} textAlign='center'>
        <TextSnippetOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
        <Grid item md={12} textAlign='center'>
          <Typography variant='overline' color='primary.main' fontWeight='700' my={1}>
            {t('buttons.add_remark')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container gap={1}>
        <Grid item container gap={1} md={12}>
          <Typography variant='subtitle2' sx={{ color: 'text.third' }}>
            {t('buttons.add_remark')}
          </Typography>
          <MultilineTextField
            value={remark}
            placeholder={t('input_fields.type_your_remark')}
            handleValueChange={val => setRemark(val)}
          />
          <Button variant='contained' fullWidth onClick={() => updateRemarkOnProduct(remark)}>
            {t('buttons.add_remark')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
const Quantity = ({ quantity }) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems='center'>
      <Typography variant='subtitle2' color='text.third' style={{ marginInlineEnd: '3px' }}>
        {t('texts.qty')}:
      </Typography>
      {quantity}
    </Grid>
  );
};
