import { DescriptionOutlined } from '@mui/icons-material';
import { Divider, Grid, Box, Typography } from '@mui/material';
import {
  //   TextDataView,
  ButtonWithStartIcon,
  TextDataViewEnd,
  Link
} from '@/components';
import { useTranslation } from 'next-i18next';
import { sm } from 'src/site-map';

export const ProductDetails = props => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{
      bgcolor: 'secondary.contrastText', borderRadius: 2,
      borderLeft: props.productData.inCart
        ? props.productData.inCartType == 'alternative'
          ? '8px solid #FB7F0D'
          : '8px solid #13628C'
        : null,
    }}>
      <Grid item md={3} height='250px'>
        <Box
          height='100%'
          pt={1}
          p={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

          }}
        >
          <img
            src={props.productData.image_url}
            height={'185px'}
            width={'100%'}
            style={{ objectFit: 'contain' }}
          />
          <ButtonWithStartIcon
            variant='outlined'
            icon={<DescriptionOutlined color='primary' />}
            translateKey='data_sheet'
          />
        </Box>
      </Grid>
      <Grid item md={9} pl={8}>
        <Box height='100%' p={3}>
          {props.showTitle && (
            <Grid container md={12}>
              <Box mr={3}>
                <img
                  src={props.productData?.brand?.image_url}
                  height={'20px'}
                  width={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </Box>
              <Link underline='hover' href={sm.catalogue.products.productId(props.productData?.id).url}>
                <Typography variant='overline' sx={{ fontWeight: 600 }}>
                  {props.productData.name}
                </Typography>
              </Link>
            </Grid>
          )}
          <Typography
            variant='subtitle2'
            sx={{ color: 'text.disabled', fontWeight: 400 }}
          >
            {props.productData.description}
          </Typography>
          <Divider sx={{ borderColor: 'info.contrastText', my: 3 }} />
          <Grid container sx={{ justifyContent: 'space-between' }} columnSpacing={2}>
            <Grid item md={6}>
              <TextDataViewEnd
                textVariant='body1'
                title={t('texts.part_number')}
                value={props.productData.part_number}
              />
            </Grid>
            <Grid item md={6}>
              <TextDataViewEnd
                textVariant='body1'
                title={t('texts.brand')}
                value={
                  props.productData.brand ? props.productData.brand.name : ''
                }
              />
            </Grid>
            {props.productData.productAttributes
              ? props.productData.productAttributes.map(el => (
                <Grid item md={6} key={`${el.name}-${el.value}`}>
                  <TextDataViewEnd
                    textVariant='body1'
                    title={el.name}
                    value={el.value}
                  />
                </Grid>
              ))
              : ''}
          </Grid>
        </Box>
      </Grid>
    </Grid >
  );
};
