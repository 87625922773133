import { HowItWorksCard } from '@/components';
import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography } from '@mui/material';
import { CameraEnhance, Layers, Palette } from '@mui/icons-material';
import React from 'react';
import { RGB, LAYER, CM } from 'public/assets/icons';
import { useTranslation } from 'next-i18next';
import { getHomeTranslationKey } from '@/hooks';

type Props = {};
const problems = [
  {
    icon: LAYER,
    primaryText: 'cost',
    secondaryText: 'cost',
  },
  {
    icon: RGB,
    primaryText: 'digitization',
    secondaryText: 'digitization',
  },
  {
    icon: CM,
    primaryText: 'flexibility',
    secondaryText: 'flexibility',
  },
];
export const WhatProblemHomeSection: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ position: 'relative' }}>
      <Grid
        item
        component={ResponsiveContainer}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            background: 'linear-gradient(to bottom, #F6F8FC, #FCFFFF)',
          },
        }}
      >
        <Typography variant='h2' textAlign='center' fontWeight='700'>
              {t(getHomeTranslationKey('titles.what_problem'))}  
        </Typography>
        <Box display='flex' justifyContent='center' mt={2}>
          <Typography
            color='text.third'
            textAlign='center'
            maxWidth={560}
            variant='body2'
          >
              {t(getHomeTranslationKey('sub_titles.what_problem'))}    
          </Typography>
        </Box>
        <Grid mb={-10} container spacing={2} pt={8} justifyContent='center'>
          {problems.map((el, i) => (
            <Grid item xl={4} lg={6} xs={12} key={i}>
              <HowItWorksCard
                primaryText={t(getHomeTranslationKey(`titles.${el.primaryText}`))}
                secondaryText={t(getHomeTranslationKey(`sub_titles.${el.secondaryText}`))}
                icon={el.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
