import create from 'zustand';

export const useDrawerStore = create((set, get) => ({
    drawerSubMenuContent: null,
    drawerSubMenuNestedContent: null,
    drawerSubMenuNestedContentPreviousTitle: "",
    drawerSubMenuParent: null,
    isDrawerOpen: false,
    isMobileDrawerOpen: false,
    isDrawerSubMenuOpen: false,
    isLoadingDrawerSubMenuContent: false,
    drawerSubMenuIndexesTree: [],
    drawerSubMenuQueryTitlesTree: [],
    isFooter: false,

    openDrawer: () => set({ isDrawerOpen: true, isMobileDrawerOpen: true }),
    toggleIsLoadingDrawerSubMenuContent: () => set({ isLoadingDrawerSubMenuContent: !get().isLoadingDrawerSubMenuContent }),
    closeDrawer: () => set({ isMobileDrawerOpen: false, isDrawerOpen: false, isDrawerSubMenuOpen: false, isFooter: false }),
    openDrawerSubMenu: (isFooterInfo?: boolean) => set({ isDrawerOpen: false, isDrawerSubMenuOpen: true, isFooter: isFooterInfo }),
    closeDrawerSubMenu: () => set({ isDrawerOpen: true, isDrawerSubMenuOpen: false, isFooter: false }),
    closeMobileDrawer: () => set({ isMobileDrawerOpen: false }),
    setDrawerSubMenuContent: (data) => { set({ isLoadingDrawerSubMenuContent: false, drawerSubMenuIndexesTree: [], drawerSubMenuQueryTitlesTree: [], drawerSubMenuContent: data, drawerSubMenuNestedContent: data, drawerSubMenuNestedContentPreviousTitle: "" }) },
    setDrawerSubMenuParent: (data: any) => set({ drawerSubMenuParent: data }),
    addItemToSubMenuTitlesTree: (title: string, id: string) => {
        const newDrawerSubMenuQueryTitlesTree = changeDrawerSubMenuItemQuery(get().drawerSubMenuParent.subMenuPathQuery, get().drawerSubMenuQueryTitlesTree, title, id)
        set({
            drawerSubMenuParent: {
                ...get().drawerSubMenuParent,
                subMenuPathQuery: newDrawerSubMenuQueryTitlesTree.newSubMenuPathQuery
            },
            drawerSubMenuTitlesTree: newDrawerSubMenuQueryTitlesTree.newDrawerSubMenuTitlesTree
        })

    },
    addItemToSubMenuTree: (index: number) => {
        let newDrawerSubMenuIndexesTree = get().drawerSubMenuIndexesTree
        newDrawerSubMenuIndexesTree.push(index)
        const updatedContent = getUpdatedContent(get().drawerSubMenuContent, newDrawerSubMenuIndexesTree)
        set({
            drawerSubMenuIndexesTree: newDrawerSubMenuIndexesTree,
            drawerSubMenuNestedContent: updatedContent.nestedContent,
            drawerSubMenuNestedContentPreviousTitle: updatedContent.previousTitle
        })
    },
    removeItemFromSubMenuTree: () => {
        let newDrawerSubMenuIndexesTree = get().drawerSubMenuIndexesTree
        const newDrawerSubMenuQueryTitlesTree = get().drawerSubMenuQueryTitlesTree
        newDrawerSubMenuQueryTitlesTree.length === Object.keys(get().drawerSubMenuParent.subMenuPathQuery).length && newDrawerSubMenuQueryTitlesTree.pop()
        newDrawerSubMenuQueryTitlesTree.pop()
        newDrawerSubMenuIndexesTree.pop()
        const updatedContent = getUpdatedContent(get().drawerSubMenuContent, newDrawerSubMenuIndexesTree)
        set({
            drawerSubMenuIndexesTree: newDrawerSubMenuIndexesTree,
            drawerSubMenuQueryTitlesTree: newDrawerSubMenuQueryTitlesTree,
            drawerSubMenuNestedContent: updatedContent.nestedContent,
            drawerSubMenuNestedContentPreviousTitle: updatedContent.previousTitle
        })
    },
}));

const getUpdatedContent = (subMenuArray: [], indexesArray: []) => {
    let nestedContent = subMenuArray
    let previousTitle = "";
    if (indexesArray.length != 0) {
        for (let i = 0; i < indexesArray.length; i++) {
            previousTitle = nestedContent[indexesArray[i]].title;
            nestedContent = nestedContent[indexesArray[i]].subs;
        }
        return { nestedContent: nestedContent, previousTitle: previousTitle }
    }
    else return { nestedContent: nestedContent, previousTitle: previousTitle }
}

const changeDrawerSubMenuItemQuery = (subMenuPathQuery: any, drawerSubMenuTitlesTree: any, newQuery: string, id: string) => {
    const newSubMenuPathQuery = subMenuPathQuery
    const newDrawerSubMenuTitlesTree = drawerSubMenuTitlesTree
    const length = Object.keys(subMenuPathQuery).length;
    let i = 0;
    for (const key in newSubMenuPathQuery) {
        if (i == length - 1 && length == newDrawerSubMenuTitlesTree.length) {
            newSubMenuPathQuery[key] = id
            newDrawerSubMenuTitlesTree[length - 1] = newQuery
            break;
        }
        else if (i == newDrawerSubMenuTitlesTree.length) {
            newSubMenuPathQuery[key] = id
            newDrawerSubMenuTitlesTree.push(newQuery);
            break;
        }
        else i++;
    }
    return { newSubMenuPathQuery: newSubMenuPathQuery, newDrawerSubMenuTitlesTree: newDrawerSubMenuTitlesTree };
}