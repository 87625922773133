import { productManagerEnv } from '@/config';
import { useProcurementStore, useSellerProcurementStore } from '@/zustand';
import Description from '@mui/icons-material/Description';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { RadioButtonGroup } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import DebouncedSearchInput from 'src/ui/components/shared/debounced-search-input';

export type BuyerCompany = {
  id: number;
  name: string;
  phone_number: string;
  email: string;
  contact_full_name: string;
  is_private: boolean;
};

type IProps = {
  onPopupSwitch: () => void;
  onPopupClose: () => void;
  buyersCompanies: BuyerCompany[];
};

const CreateQuoteWithProduct = ({ onPopupSwitch, onPopupClose, buyersCompanies }: IProps) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const product_id = data.candidateProductToAdd?.id;

  const [searchText, setsearchText] = React.useState<string>('');
  const companiesToRender = React.useMemo(() => {
    if (!searchText) return buyersCompanies;
    const searchTerm = searchText.toLocaleLowerCase();
    return buyersCompanies.filter(item => item.name.toLocaleLowerCase().includes(searchTerm));
  }, [searchText, buyersCompanies]);

  const [selectedBuyerId, setSelectedBuyerId] = React.useState<number | ''>('');

  const createLoading = useLoadingState();
  const { create: createQuote } = useSellerProcurementStore(state => state.sellerQuotesTableSliceProperties.actions);
  const handleCreateQuote = () => {
    const product = data.candidateProductToAdd;
    if (!product || !product_id || !selectedBuyerId) return;

    createLoading.startLoading();
    createQuote({ buyerId: selectedBuyerId, projectId: '' }, (id, quote) => {
      actions.setDetails({ ...quote, products: [] }, productManagerEnv.quote);
      onPopupClose();
      actions.addProduct(product);
    });
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, width: '100%' }}>
        <Description color='primary' />
        <Typography color='primary' variant='overline' fontWeight='700' mt={1} textAlign={'center'}>
          Add To New Quote
        </Typography>
        <Typography color='#3A3E50' fontWeight={400} fontSize={14} mt={1} textAlign={'center'}>
          Please Select a Buyer
        </Typography>
      </Stack>
      <Box width={'100%'} mb={2}>
        <DebouncedSearchInput onChange={setsearchText} translateKey={'search_buyers'} />
      </Box>
      <Stack my={1} maxHeight='200px'>
        <Stack pl={1}>
          <RadioButtonGroup
            data={companiesToRender}
            handleChange={(id: number) => setSelectedBuyerId(id)}
            value={selectedBuyerId}
            getSubTitle={() => ''}
            getLabelSuffix={(buyer: any) => {
              return buyer.is_private ? <PrivacyButton disableText zIndex={1300} /> : null;
            }}
          />
        </Stack>
      </Stack>
      <LoadingButton
        loading={createLoading.isLoading}
        sx={{ mt: 2 }}
        type='button'
        fullWidth
        variant='contained'
        disabled={!selectedBuyerId}
        onClick={handleCreateQuote}
      >
        Create New Quote
      </LoadingButton>
      <Button
        type='button'
        color='primary'
        variant='text'
        fullWidth
        sx={{ mt: 1, fontWeight: 500 }}
        onClick={onPopupSwitch}
        disabled={createLoading.isLoading}
      >
        Or Add To Existing Quote
      </Button>
    </Box>
  );
};

export default CreateQuoteWithProduct;
