import { Product, Requisition } from "@/models";
import { fetchAlternativeProductDetails } from "@/services";
import produce from "immer";
import { StateCreator } from "zustand";

export interface AlternativeProductDetailsSlice {
    alternativeDetailsMainAlternativeProduct: Product | null,
    alternativeDetailsMainProduct: Product | null,
    alternativeDetailsMainProductId: string,
    alternativeDetailsAlternatives: Product[],
    loadAlternativeProductDetails: (productId: number, mainProductId: number) => void;
    copyOfFormAlternativeProductDetailsSlice: Requisition | null;
}

export const createAlternativeProductDetailsSlice: StateCreator<AlternativeProductDetailsSlice> = (set, get, api) => ({
    copyOfFormAlternativeProductDetailsSlice: null,
    alternativeDetailsMainAlternativeProduct: null,
    alternativeDetailsMainProduct: null,
    alternativeDetailsMainProductId: "",
    alternativeDetailsAlternatives: [],
    loadAlternativeProductDetails: (productId: number, mainProductId: number) => {
        fetchAlternativeProductDetails(productId).then(data => {
            set(produce(draftState => {
                // draftState.alternativeDetailsMainProduct = get().buildInitialProductState(data.data.main_product)
                draftState.alternativeDetailsMainProductId = mainProductId
                draftState.alternativeDetailsMainAlternativeProduct = get().buildInitialProductAlternativeState(data.data.product, mainProductId)
                draftState.alternativeDetailsAlternatives = data.data.alternatives.map((alternativeProduct: Product) => {
                    return get().buildInitialProductAlternativeState(alternativeProduct, mainProductId)
                })
                draftState.copyOfFormAlternativeProductDetailsSlice = null //!important - we need to reset it to null so that it rebuilds if there is any current requisition
            }))
        })
        api.subscribe(
            state => {
                const productSliceData = get().productFormManagerProperties.data
                const formDetails = state.productFormManagerProperties.data.formDetails
                const productSliceActions = get().productFormManagerProperties.actions
                if (JSON.stringify(formDetails) !== JSON.stringify(get().copyOfFormAlternativeProductDetailsSlice))
                    set(produce(draftState => {
                        // draftState.alternativeDetailsMainProduct
                        //     = productSliceData.environment ? productSliceActions.transformProduct(draftState.alternativeDetailsMainProduct, formDetails, true)
                        //         : state.buildInitialProductState(draftState.alternativeDetailsMainProduct)
                        draftState.alternativeDetailsMainAlternativeProduct
                            = productSliceData.environment ? productSliceActions.transformProduct(draftState.alternativeDetailsMainAlternativeProduct, formDetails, true)
                                : get().buildInitialProductAlternativeState(draftState.alternativeDetailsMainAlternativeProduct, draftState.alternativeDetailsMainProductId)
                        draftState.alternativeDetailsAlternatives = draftState.alternativeDetailsAlternatives?.map((element: Product) =>
                            productSliceData.environment ? productSliceActions.transformProduct(element, formDetails, true)
                                : get().buildInitialProductAlternativeState(element, get().alternativeDetailsMainProductId))
                        draftState.copyOfFormAlternativeProductDetailsSlice = formDetails
                    }))

            },
        )
    },
})

//1- Fill Main Product Requisition Details
//2- Fill Alternative Products Req Details
//3- Fill Alternative Produt Req Details

// const fillRequisitionProductDetails=({requisition,product})=>{

// }