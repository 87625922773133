import { Loading } from '@/components';
import { useSellerProcurementStore } from '@/zustand';
import { Description } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { sm } from 'src/site-map';
import { createQuoteSchema } from './validation-schema';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { LoadingButton } from '@mui/lab';
import { SearchTextField, RadioButtonGroup } from '@/components';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

interface props {
  closePopup: () => void;
  initialProjectId?: number;
}
export const CreateQuotePopup: FC<props> = ({ closePopup, initialProjectId }) => {
  const { data, actions } = useSellerProcurementStore(state => state.sellerQuotesTableSliceProperties);

  useEffect(() => {
    actions.fetchCreateData();
  }, []);

  return (
    <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, width: '100%' }}>
      <Description color='primary' />
      <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
        {t('texts.create_new_quote')}
      </Typography>
      {data.createData ? (
        <QuoteCreateForm closePopup={closePopup} initialProjectId={initialProjectId} />
      ) : (
        <div style={{ height: '5rem' }}>
          <Loading fullHeight={false} />
        </div>
      )}
    </Stack>
  );
};

const QuoteCreateForm: FC<props> = ({ closePopup, initialProjectId }) => {
  const { push } = useRouter();
  const { data, actions } = useSellerProcurementStore(state => state.sellerQuotesTableSliceProperties);
  const initialValues = {
    projectId: initialProjectId ?? '',
    buyerId: '',
  };

  const createQuoteLoading = useLoadingState();
  const handleSubmitForm = (values: any) => {
    createQuoteLoading.startLoading();
    actions.create(
      values,
      (id: string) => {
        push(sm.portals.seller.procurement.quotes.quoteId(id).url);
        createQuoteLoading.finishLoading();
        closePopup();
      },
      createQuoteLoading.finishLoading
    );
  };

  const [searchText, setSearchText] = React.useState<string>('');

  return (
    <Stack gap={2} textAlign={'start'} width='100%'>
      <Formik initialValues={initialValues} validationSchema={createQuoteSchema} onSubmit={handleSubmitForm}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <Box width={'100%'} mt={2}>
              <SearchTextField
                translateKey='search_buyers'
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </Box>
            <Stack my={1} maxHeight='200px'>
              <Stack pl={1}>
                <RadioButtonGroup
                  data={
                    data.createData?.companies?.filter(company =>
                      company.name.toLowerCase().includes(searchText.toLowerCase())
                    ) ?? []
                  }
                  handleChange={(id: string) => formikProps.setFieldValue('buyerId', id)}
                  value={formikProps.values.buyerId}
                  getSubTitle={() => ''}
                  getLabelSuffix={(buyer: any) => {
                    return buyer.is_private ? <PrivacyButton disableText zIndex={1300} /> : null;
                  }}
                />
              </Stack>
            </Stack>
            <LoadingButton
              loading={createQuoteLoading.isLoading}
              disabled={!formikProps.values.buyerId}
              fullWidth
              variant='contained'
              type='submit'
              sx={{ mt: 2 }}
            >
              {t('texts.create_new_quote')}
            </LoadingButton>
          </form>
        )}
      </Formik>
    </Stack>
  );
};
