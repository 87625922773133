import { apiRoutes } from "@/config";
import { request } from "@/helpers";
import { useMutation } from "@tanstack/react-query";
const resendVerificationEmail = async (email:string) => {
  return request({
    url: apiRoutes.resendVerificationEmail,
    method: "POST",
    data: {
      'email': email
    }
  })
}


export const useResendVerificationEmail = (onSuccess, onError) => {
  return useMutation((email) => resendVerificationEmail(email), { onSuccess: onSuccess, onError: onError })
}