import React from 'react';
import { StyledTable } from '@/components';
import { Link, StatusChip } from '@/components';
import { sm } from 'src/site-map';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { makeStyles } from '@mui/styles';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { RFQTableListOfActions } from '@/config';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ColumnDef } from '@tanstack/react-table';
import { RFQTableRowModel } from '@/models';

type QuotesTablePageForSellerProps = {
    key: string
    // rowsData: RequestForQuotesTableData | undefined
}

const useStyles = makeStyles({
    cellHead: {},
    styleColumn: {}
});

export const QuotesTablePageForSeller: React.FC<QuotesTablePageForSellerProps> = ({ key, rowsData }) => {

    const classes = useStyles()

    const columnObjects = [
        {
            accessorKey: 'titleData',
            header: 'quote',
            cell: info => <TableRFQText content={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        },
        {
            accessorKey: 'buyer',
            header: 'buyer',
            cell: info => <TableText title={info.getValue().name} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'project',
            header: 'project',
            cell: info => <TableText title={info.getValue().name} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'created_at',
            header: 'date',
            cell: info => <TableDate date={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        },
        {
            accessorKey: 'owner_name',
            header: 'owner',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        },
        {
            accessorKey: 'number_of_products',
            header: 'products',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'number_of_competitors',
            header: 'competitors',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'value',
            header: 'value',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'status',
            header: 'status',
            cell: info => <TableChip status={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        }
    ]


    // if (rowsData.shouldShowReadColumn)
    columnObjects.push({
        accessorKey: 'read',
        header: 'read_all',
        cell: info => <TableIsRead isRead={info.getValue()} />,
        meta: {
            filterType: 'search',
            columnIcon: UnfoldMoreIcon,
            headerCellClass: classes.cellHead,
            tableRow: {
                cellClass: classes.styleColumn
            },
        },
        enableColumnFilter: false,
    })

    // if (rowsData.shouldShowActions)
    columnObjects.push({
        accessorKey: 'list_actions',
        header: 'actions',
        cell: info => <TableButton listOfActions={info.getValue()} bundleId={info.row.original.bundle_id} rfqId={info.row.original.id} />,
        meta: {
            filterType: 'search',
            columnIcon: UnfoldMoreIcon,
            headerCellClass: classes.cellHead,
            tableRow: {
                cellClass: classes.styleColumn
            }
        },
        enableColumnFilter: false,
    })

    const columns = React.useMemo<ColumnDef<RFQTableRowModel>[]>(
        () => columnObjects,
        []
    )

    return (
        <>
            {/* <StyledTable key={key} rows={rowsData.rows} columns={columns} /> */}
        </>
    )
}

const TableText = ({ title }) => {
    return (
        <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
            <Typography variant='subtitle2' fontWeight={400}>{title}</Typography>
        </Grid>
    )
}

const TableChip = ({ status }) => {
    return (<StatusChip status={status} />)
}

const TableRFQText = ({ content }) => {
    return (
        <Grid container width='100%'>
            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
                {content.has_new_changes && <CircleIcon sx={{ color: 'success.dark', fontSize: '14px' }} />}
                <Link underline='hover' href={sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(content.bundle_id).url}>
                    <Typography variant='body2' sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {content.name}
                    </Typography>
                </Link>
            </Grid>
            <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{content?.bundle_name} </span>
        </Grid>)
}

const TableButton = ({ listOfActions, bundleId, rfqId }) => {
    const { t } = useTranslation()

    return (
        <>
            {listOfActions.map((action: RFQTableListOfActions) =>
                <Grid container gap={1}>
                    <Button><Typography variant="subtitle2" fontWeight={700}>
                        {t('buttons.' + action)}</Typography>
                    </Button>
                </Grid>
            )}
        </>
    )
}


const TableIsRead = ({ isRead }) => {
    return (<DoneAllIcon sx={{ color: isRead ? 'success.dark' : 'text.third', fontSize: '16px' }} />)
}

const TableDate = ({ date }) => {
    const mainCharacter = '-';
    const replaceWith = '/';
    const updatedDate = date.split(mainCharacter).join(replaceWith);

    return (
        <Typography variant='subtitle2' fontWeight={400}>  {updatedDate}  </Typography>
    )
}
