import React, { useState } from 'react';
import { TextField, Typography, Menu, MenuItem, IconButton, InputAdornment, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TableFilterItem } from '@/models';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'next-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type FilterByCheckboxMenuTypeProps = {
    label: string;
    type: string;
    kind: string;
    filterItems: TableFilterItem[];
    onFilterChanged: (type: string, kind: string, value: any) => void
    isStatus?: boolean
};

export const FilterByCheckboxMenuType: React.FC<FilterByCheckboxMenuTypeProps> = ({ label, type, kind, filterItems, onFilterChanged, isStatus }) => {
    const [selectedOptions, setSelectedOptions] = useState(filterItems.filter(item => item.checked) ?? []);

    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const [currentFilter, setCurrentFilter] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setCurrentFilter(type);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentFilter(null);
    };

    const handleOptionClick = (option) => {
        const selectedIndex = selectedOptions.findIndex((selectedOption) => selectedOption.id == option.id);
        const updatedSelectedOptions = [...selectedOptions];
        if (selectedIndex === -1) {
            updatedSelectedOptions.push(option);
            onFilterChanged(type, kind, option.id)
        } else {
            updatedSelectedOptions.splice(selectedIndex, 1);
            onFilterChanged(type, kind, option.id)
        }
        setSelectedOptions(updatedSelectedOptions);
    };

    const handleClearSelection = () => {
        handleCloseMenu()
        selectedOptions.forEach(item => {
            onFilterChanged(type, kind, item.id);
        });
        setSelectedOptions([]);
    };

    return (
        <>
            <TextField variant="standard" sx={{ width: '100%' }} InputLabelProps={{ style: { fontSize: '12px' } }}
                label={label} onClick={handleOpenMenu}
                value={selectedOptions.length !== 0 ? `${selectedOptions.length} selected` : ''}
                InputProps={{
                    style: { cursor: 'pointer' },
                    endAdornment: (
                        <InputAdornment position="end">
                            {selectedOptions.length > 0 && (
                                <IconButton sx={{ zIndex: currentFilter === type ? 10000 : 'auto' }} onClick={(e) => { e.stopPropagation(); handleClearSelection(); }} style={{ cursor: 'pointer' }}>
                                    <ClearIcon sx={{ fontSize: '20px' }} />
                                </IconButton>
                            )}
                            <IconButton>
                                <ExpandMoreIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />


            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} PaperProps={{
                sx: {
                    width: '18%', maxHeight: 300, overflowY: 'auto', scrollbarWidth: 'thin', zIndex: 9999,
                    '&::-webkit-scrollbar': { width: 3, height: 2 },
                    '&::-webkit-scrollbar-track': { boxShadow: `white`, margin: 2 },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: '#13628C', outline: `1px solid slategrey`, borderRadius: 3, width: 10, justifyContent: 'center' },
                }
            }}>
                {filterItems && filterItems.length != 0 && filterItems?.map((option) => (
                    <MenuItem key={option.name} onClick={() => handleOptionClick(option)} sx={{ whiteSpace: 'pre-line', padding: 0.15 }}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selectedOptions.some((selectedOption) => selectedOption.id == option.id)}
                        />
                        <Typography fontWeight={400} variant="body2" color="primary.main" style={{ lineHeight: 1.1 }}> {isStatus ? t('chip.' + option.name) : option.name}  </Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
