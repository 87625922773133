import { apiRoutes } from "@/config"
import { request } from "@/helpers"

export const useFetchRequestForQuoteDetails = (rfqId: number) => {
    return request({ url: apiRoutes.requestForQuote + rfqId, method: "GET" })
}


export const useSendForApprovalRFQBuyer = (rfqId: number, optionalComment: string) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.sendForApproval, method: "POST", data: { comment: optionalComment } })
}

export const useCancelSendForApprovalsRFQBuyer = (rfqId: number) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.cancelApproval, method: "PUT" })
}