import { Grid, Typography, Button } from '@mui/material';
import {  PasswordRestrictions, StyledTextField, user } from '@/components';
import { validationSchema } from './validationSchema'
import { initialValues } from './initialValues'
import { Formik, useField } from 'formik';
import { useTranslation } from 'next-i18next';
import { getAccountTranslationKey } from '@/hooks';
import type { FC } from 'react';

interface ChangePasswordByEmailProps {
    isDisabledButton: boolean,
    paragraphTranslate: string,
    handleClickNext: (values:any,actions: any) => void,
    handleClickBack: () => void,
}

export const ChangePasswordByEmail : FC<ChangePasswordByEmailProps> = (props: ChangePasswordByEmailProps) => {
    const { t } = useTranslation();

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.handleClickNext}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                        {props.paragraphTranslate}
                    </Typography>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField showLabel required type="password" translateKey='user.new_password' name='new_password' />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField showLabel required type="password" translateKey='user.password_confirm' name='new_password_confirmation' />
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <PasswordRestrictions />
                    </Grid>
                    <Button
                        disabled={props.isDisabledButton}
                        variant='contained'
                        color='secondary'
                        type='submit'
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                       {t('buttons.change_password')}
                    </Button>
                    <Button
                        variant='outlined'
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={props.handleClickBack}
                    >
                        {t('buttons.back')}
                    </Button>
                </form>
            )}
        </Formik>
    )
}