import React from 'react'
import { makeStyles } from "@mui/styles";
import { TableWithPagination } from 'src/ui/components/shared';
import { ThemeOptions } from '@mui/material';


type StyledTableProps = {
    key: string
    rows: any
    columns: any
    style?: React.CSSProperties
}


export const StyledTable: React.FC<StyledTableProps> = ({ key, rows, columns, style = {} }) => {
    const useStyles = makeStyles((theme: ThemeOptions) => ({
        head: {
            backgroundColor: theme?.palette?.action?.lightBackground,
            '& th:first-child': { borderRadius: '6px 0 0 6px' },
            '& th:last-child': { borderRadius: '0 6px 6px 0' },
            '& th': { textAlign: 'start' }
        },
        rowBody: {
            height: '80px', borderBottom: '1px solid #E3E3E9', '&:hover': { backgroundColor: theme?.palette?.warning?.dark }
        },
    }));

    const classes = useStyles()

    return (
        <>
            {
                rows &&
                <TableWithPagination
                    style={style}
                    disablePagination
                    columns={columns}
                    data={rows}
                    key={key}
                    tableMeta={{
                        getRowStyle: () => {
                            return classes.rowBody
                        },
                        headStyle: classes.head
                    }}
                />
            }
        </>
    )
}

