import { Grid, Typography, Stack } from '@mui/material';
import { ResponsiveContainer } from '@/styles';
import { motion } from 'framer-motion';
import { StyledBreadcrumbs } from 'src/ui/components/shared';

export const HeroTextSection = ({
  children,
  isSmallScreen,
  ShowBreadcrumbs,
  title,
  subTitle,
  secondSubTitle,
  withBlueBackgroung,
  breadCrumbsAlias
}) => {


  return (
    <>
      {isSmallScreen && ShowBreadcrumbs && (
        <Grid
          item
          component={ResponsiveContainer}
          sx={{
            position: 'relative',
            width: '100%%',
          }}
        >
          <StyledBreadcrumbs  alias={breadCrumbsAlias}/>
        </Grid>
      )}
      <Grid
        item
        component={ResponsiveContainer}
        lg={7}
        order={[1, 1, 1, 0]}
        sx={{
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '130%',
            height: 'calc(100% - 50px)',
            zIndex: -1,
            bgcolor:
              !isSmallScreen || withBlueBackgroung
                ? 'background.primary.main'
                : 'white',
          },
        }}
      >
        {!isSmallScreen && ShowBreadcrumbs && <StyledBreadcrumbs  alias={breadCrumbsAlias}/>}
        <Typography
          component={motion.div}
          variant='h1'
          fontWeight='700'
          mt={isSmallScreen ? 2 : '10%'}
        >
            {title}
        </Typography>
        <Typography
          component={motion.div}
          color='text.third'
          variant='body2'
          fontWeight='400'
          mt={2}
          width={isSmallScreen ? '100%' : '80%'}
        >
            {subTitle}
        </Typography>
        {secondSubTitle && (
        <Typography
          component={motion.div}
          color='text.third'
          fontWeight='400'
          variant='body2'
        >
            {secondSubTitle}
        </Typography>
        )}
        <Stack
          direction={isSmallScreen ? 'column' : 'row'}
          spacing={2}
          sx={{ mt: 3 }}
        >
          {children}
        </Stack>
      </Grid>
    </>
  );
};
