import { productManagerEnv } from '@/config';
import { Product, Seller } from '@/models';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import React, { FC } from 'react';
import { AddProductToEnvironmentButton, PriceInputForEnvironmentProduct, ProductQuantity } from '.';
import LinkSellerAction from './link-seller';
import { useProcurementStore } from '@/zustand';

export const PurchaseOrderProductActions: FC<{
  product: Product;
  isPageAlternative?: boolean;
  seller?: Seller;
}> = props => {
  const { formDetails } = useProcurementStore(state => state.productFormManagerProperties.data);
  const envSeller = formDetails?.seller_details;
  const targetSeller = React.useMemo(() => {
    if (props.seller) return props.seller;
    if (envSeller) return { seller: { id: envSeller.id, name: envSeller.name } };
    return undefined;
  }, [envSeller, props.seller]);

  const isProductLinkedToSeller = React.useMemo(() => {
    if (targetSeller) {
      return props.product?.sellers?.find(sellerItem => sellerItem.seller.id == targetSeller?.seller.id) !== undefined;
    }
    return true;
  }, [props.product, targetSeller]);

  if (!isProductLinkedToSeller) {
    return <LinkSellerAction product={props.product} seller={targetSeller} />;
  }
  return (
    <>
      <Grid item md={12} container alignItems={'center'}>
        <ProductQuantity product={props.product} isPageAlternative={props.isPageAlternative} />
      </Grid>
      <Grid item md={12}>
        <PriceInputForEnvironmentProduct product={props.product} isPageAlternative={props.isPageAlternative} />
      </Grid>
      <Grid item md={12}>
        <AddProductToEnvironmentButton
          product={props.product}
          titleForAddedProduct='added_to_po'
          environment={productManagerEnv.purchase_order}
          titleForAddingProduct={t('buttons.add_to_PO')}
          isPageAlternative={props.isPageAlternative}
        />
      </Grid>
    </>
  );
};
