import { Grid } from "@mui/material"
import { ReactNode, useEffect } from "react";
import { QuotePopupStatus } from "@/config";
import { ProcurementActionsPopupContent, DescPopup } from '@/components'
import { Formik } from "formik";
import { useTranslation } from 'next-i18next';
import initialValues from './initialValues'
import { commentsValidationSchema, commentsWithReasonValidationSchema } from './validationSchema'
import { useSellerProcurementStore } from "src/logic/zustand/procurement/seller";

type QuotePopupContentProps = {
    QuotePopupOpened: boolean
    handleCloseQuotePopup: () => void
    icon: ReactNode
    popupTitle: string
    handlePopupAction: (values: any) => void
    buttonTitle: string
    status: string
    popupSubTitle?: string
    isButtonLoading?: boolean
};


export const QuoteActionPopup: React.VFC<QuotePopupContentProps> = ({ QuotePopupOpened, handleCloseQuotePopup, icon, popupTitle, popupSubTitle, handlePopupAction, buttonTitle, status,isButtonLoading }) => {
    const { t } = useTranslation();
    const getReasonsForPopup = useSellerProcurementStore(state => state.quoteDetailsSliceActions.getReasonsForPopup)
    // const getCancelReasonsForPopup = useProcurementStore(state => state.bundleRFQDetailsSliceActions.getCancelReasonsForPopup)
    const openedPopupStatus = useSellerProcurementStore(state => state.quoteDetailsSliceProperties.openedPopupStatus)
    const reasonsForPopup = useSellerProcurementStore(state => state.quoteDetailsSliceProperties.reasonsForPopup)
    let reasonsStatus = openedPopupStatus == QuotePopupStatus.REJECT || openedPopupStatus == QuotePopupStatus.CANCEL_QUOTE
    const handleSubmit = (values, actions) => handlePopupAction(values)

    useEffect(() => {
        if (reasonsStatus) getReasonsForPopup()
    }, [openedPopupStatus]);

    return (
        <DescPopup handleClose={handleCloseQuotePopup} open={QuotePopupOpened}>
            <Grid container gap={2} width={300}>
                <Formik initialValues={initialValues} validationSchema={reasonsStatus ? commentsWithReasonValidationSchema : commentsValidationSchema} onSubmit={handleSubmit}>
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <ProcurementActionsPopupContent
                                icon={icon}
                                popupTitle={popupTitle}
                                buttonTitle={buttonTitle}
                                status={status}
                                popupSubTitle={popupSubTitle}
                                reasonsForPopup={reasonsStatus ? reasonsForPopup : undefined}
                                cancelStatus={openedPopupStatus == QuotePopupStatus.CANCEL_QUOTE}
                                checkLabel={t('texts.hide_reason_from_buyer')}
                                isButtonLoading={isButtonLoading}
                            />
                        </form>
                    )}
                </Formik>
            </Grid>
        </DescPopup>
    )
}