import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { formatNumber } from 'src/logic/utils/numbers';

type TotalSectionProps = {
  title: string;
  position: string;
  totalValue: string;
  subtotalValue?: string;
  vatValue?: string;
  fullWidth?: boolean;
  grayBackground?: boolean;
};

const getFormatedNumber = (value: string | number | null | undefined) => {
  if(value === null || value === undefined || isNaN(value)) return "";
  if(typeof value === "number") return formatNumber(value);
  return formatNumber(parseFloat(value) ?? ''); 
}

export const TotalSection: React.FC<TotalSectionProps> = props => {
  const { t } = useTranslation();

  const { totalValue, subtotalValue, vatValue } = React.useMemo(() => ({
    totalValue: getFormatedNumber(props.totalValue),
    subtotalValue: getFormatedNumber(props.subtotalValue),
    vatValue: getFormatedNumber(props.vatValue),
  }), [props])

  return (
    <Grid container md={12} xs={12} justifyContent={props.position}>
      <Grid
        container
        item
        md={props.fullWidth ? 12 : 4}
        xs={12}
        sx={{
          bgcolor: props.grayBackground
            ? 'background.primary.main'
            : 'secondary.contrastText',
          borderRadius: '8px',
        }}
        gap={1}
        p={3}
      >
        <Typography variant='body1' fontWeight={700} color='text.disabled'>
          {props.title}
        </Typography>
        <Grid item md={12}>
          <TotalDetails
            title={t('texts.sub_total')}
            value={subtotalValue}
          />
        </Grid>
        <Grid item md={12}>
          <TotalDetails title={t('texts.vat')} value={vatValue} />
        </Grid>
        <Grid
          container
          md={12}
          sx={{ display: 'flex', flexDirection: 'row' }}
          justifyContent='space-between'
        >
          <Typography variant='body2' fontWeight={700} color='text.disabled'>
            {t('texts.total')}{' '}
          </Typography>
          <Typography variant='body2' fontWeight={700} color='text.disabled'>
            {t('texts.aed')} {totalValue}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

type TotalDetailsProps = {
  title: string;
  value: string;
};

export const TotalDetails: React.FC<TotalDetailsProps> = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent='space-between'>
      <Typography variant='body2' fontWeight={400} color='text.third'>
        {title}
      </Typography>
      <Typography variant='body2' fontWeight={400} color='text.third'>
        {t('texts.aed')} {value}
      </Typography>
    </Grid>
  );
};
