import create from 'zustand';

export const useProfile = create((set, get) => ({
  user_info: null,
  company_settings: null,
  presentable_roles: null,
  presentable_permissions: null,
  predefined_data: null,

  setProfileData: data => {
    set({
      user_info: data.user_info,
      company_settings: data.company_settings,
      presentable_roles: data.presentable_roles,
      presentable_permissions: data.presentable_permissions,
      predefined_data: data.predefined_data,
    });
  },
  setRoles: (value: string, id: string) => {
    let roles = get().presentable_roles?.map(role => {
      if (role.data.id == id) return { ...role, is_selected: value };
      return role;
    });
    set({ presentable_roles: roles });
  },
  setPermisisons: (value: string, id: string) => {
    let permissions = get().presentable_permissions?.map(permission => {
      if (permission.data.id == id) return { ...permission, is_selected: value };
      return permission;
    });
    set({ presentable_permissions: permissions });
  },
  setCompanySettings: company_settings => {
    set({ company_settings: company_settings });
  },
  setCompanyDocuments: documents => {
    set({
      company_settings: {
        ...get().company_settings,
        company_documents: documents,
      },
    });
  },
  
  setUserInfo: user_info => {
    set({ user_info: user_info });
  },

}));
