import React from 'react';
import { Approver } from '@/models';
// import { UserIcon } from '@/icons';
import { Typography } from '@mui/material';
import { ApproversList } from 'src/ui/components/dumb';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

type IProps = {
  approvers?: Approver[];
};

const ListOfApprovers = ({ approvers }: IProps) => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const showListOfApprovers = Boolean(anchorElement);
  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => {
    handleMouseEnter();
    setAnchorElement(event.currentTarget);
  };
  const handleHoverOut = () => {
    handleMouseLeave();
    setAnchorElement(null);
  };

  if (!Array.isArray(approvers)) return null;
  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          color: '#8B8D98',
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseEnter={event => handleHoverIn(event)}
        onMouseLeave={handleHoverOut}
      >
        <div
          style={{
            backgroundColor: '#EAEEF6',
            aspectRatio: '1',
            width: '40px',
            height: '40px',
            marginInlineEnd: '.75rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
          }}
        >
          {/* <UserIcon color='primary' /> */}
          <AccountCircleOutlinedIcon color='primary' />
        </div>
        <div>
          <Typography variant='subtitle2'>{t('texts.approvers')}</Typography>
          <Typography fontWeight={700} variant='subtitle2' color='text.disabled' sx={{ whiteSpace: 'nowrap' }}>
            {approvers.length} Approver{approvers.length > 1 && 's'}
          </Typography>
        </div>
      </div>
      <ApproversList
        isHovered={isHovered && showListOfApprovers}
        Anchor={anchorElement}
        listOfApprovers={approvers}
        handleClose={() => setAnchorElement(null)}
      />
    </>
  );
};

export default ListOfApprovers;
