import {procurementStatus, RequisitionStatus} from "@/config";
import {
    ColumnFormat, ColumnSort,
    ColumnType,
    Requisition,
    RequisitionTableRow,
    RequisitionTableStatus, TableColumnMapper,
    TableGroup,
    TableMapper,
    TableRow,
    TableRowAction, TableType, TabMapper
} from "@/models";
import produce from "immer";
import { StateCreator } from "zustand";
import {buildTableGroupModel} from "../../../services/tables";
import {useUserData} from "@/zustand";

// export interface RequisitionsTableSlice {
//     requisitionsTableStatuses?: RequisitionTableStatus[] | null,
//     selectedRequisitionTableStatusName: string | null,
//     searchValue?: string | null,
//     allRequisitionTableRows: RequisitionTableRow[] | null,
//     requisitionTableRows: RequisitionTableRow[] | null,
//     requisitionsTableSliceActions: {
//         loadRequisitionsTableSlice: () => void,
//         changeTab: (name: string) => void,
//         changeSearchValue: (name: string) => void
//     },
//     copyOfRequisitionsRequisitionsTableSlice: Requisition[] | null
// }

export interface RequisitionsTableSlice {
    requisitionsTableSliceProperties: {
        data: {
            isLoading: boolean,
            tableGroup: TableGroup | null,
            copyOfRequisitionsTableSlice: Requisition[] | null
        },
        actions: {
            loadData: () => void,
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void
        },
    }
}

export const createRequisitionsTableSlice: StateCreator<RequisitionsTableSlice> = (set, get, api) => ({
    requisitionsTableSliceProperties: {
        data: {
            isLoading: true,
            tableGroup: null,
            copyOfRequisitionsTableSlice: null
        },
        actions: {
            loadData: () => {
                set(produce(draftState => {
                    draftState.requisitionsTableSliceProperties.data.isLoading = true
                }))

                if (get().requisitions) {
                    const requisitions = get().requisitions

                    const userId = useUserData.getState().userInfo?.id

                    let tableGroup = buildTableGroupModel(
                        requisitions, tableMapper(), columnsMapper(), userId, false,
                    )
                    tableGroup = addTableActions(tableGroup, requisitions)
                    set(produce(draftState => {
                        draftState.requisitionsTableSliceProperties.data.isLoading = false
                        draftState.requisitionsTableSliceProperties.data.copyOfRequisitionsTableSlice = requisitions
                        draftState.requisitionsTableSliceProperties.data.tableGroup = tableGroup
                    }))

                    api.subscribe(
                        state => {
                            if (JSON.stringify(state.requisitions) !== JSON.stringify(get().requisitionsTableSliceProperties.data.copyOfRequisitionsTableSlice)) {
                                const requisitions = state.requisitions
                                let tableGroup = buildTableGroupModel(
                                    requisitions, tableMapper(), columnsMapper(), userId, false,
                                )
                                tableGroup = addTableActions(tableGroup, requisitions)
                                set(produce(draftState => {
                                    draftState.requisitionsTableSliceProperties.data.copyOfRequisitionsTableSlice = requisitions
                                    draftState.requisitionsTableSliceProperties.data.tableGroup = tableGroup
                                    draftState.requisitionsTableSliceProperties.data.isLoading = false
                                }))
                            }
                        }
                    )
                }
            },
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => {

            }
        }
    }
})

export const addTableActions = (tableGroup: TableGroup, requisitions) => {
    tableGroup.tables.map(table => {
        return table.tabs.map(tab => {
            if (tab.hasActions) {
                return tab.rows.map(row => {
                    let relatedRequisition = requisitions.find(element => element.id == row.id)
                    if(relatedRequisition.status == RequisitionStatus.DRAFT && relatedRequisition.products?.length != 0 && relatedRequisition.can_edit)
                        return row.setActions([TableRowAction.CONVERT_TO_RFQ])
                    if(relatedRequisition.status == RequisitionStatus.DRAFT && relatedRequisition.products?.length == 0 && relatedRequisition.can_edit)
                        return row.setActions([TableRowAction.ADD_PRODUCTS])
                    if(relatedRequisition.status == RequisitionStatus.CONVERTED_TO_RFQ)
                        return row.setActions([TableRowAction.VIEW_RFQ])
                    return row
                })
            }
            return tab
        })
    })
    return tableGroup
}

export const tableMapper = () => {
    return [
        new TableMapper(TableType.MINE, 'My Requisitions', [
            new TabMapper('all', [procurementStatus.ALL], true),
            new TabMapper('DRAFT', [procurementStatus.DRAFT], true),
            new TabMapper('CONVERTED_TO_RFQ', [procurementStatus.CONVERTED_TO_RFQ], true),
        ]),
        new TableMapper(TableType.OTHER, 'Company Requisitions', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('DRAFT', [procurementStatus.DRAFT]),
            new TabMapper('CONVERTED_TO_RFQ', [procurementStatus.CONVERTED_TO_RFQ]),
        ]),
    ];
}

export const columnsMapper = () => {
    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        new TableColumnMapper('name', 'requisition', ColumnType.STRING, ColumnFormat.URL),
        new TableColumnMapper('project.name', 'project', ColumnType.STRING),
        new TableColumnMapper('owner_name', 'owner', ColumnType.STRING),
        dateColumn,
        new TableColumnMapper('numberOfProducts', 'products', ColumnType.NUMBER, null, null, false,
            (requisition) =>  requisition?.products?.length ?? 0),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}