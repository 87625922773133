import { sm } from 'src/site-map';

export const BreadcrumbsConfig = {
    [sm.home.url]: () => { return [sm.home] },

    [sm.contactUs.url]: () => { return [sm.home, sm.contactUs] },
    [sm.becomeASeller.url]: () => { return [sm.home, sm.becomeASeller] },
    [sm.careers.index.url]: () => { return [sm.home, sm.careers.index] },
    [sm.careers.id('*').url]: (id: string[]) => { return [sm.home, sm.careers.index, sm.careers.id(id[0])] },
    [sm.searchResults.index.url]: () => { return [sm.home, sm.searchResults.index] },
    [sm.searchResults.id('*').url]: (searchVal: string[]) => {
        return [sm.home, sm.searchResults.index, sm.searchResults.id(searchVal[0])]
    },
    [sm.requisitions.index.url]: () => { return [sm.home, sm.requisitions.index] },
    [sm.requisitions.draft.url]: () => { return [sm.home, sm.requisitions.draft] },
    [sm.requisitions.requisition('*').url]: (id: string[]) => { return [sm.requisitions.index, sm.requisitions.requisition(id[0])] },

    [sm.products.index.url]: () => { return [sm.home, sm.products.index] },
    [sm.products.id('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0])] },
    [sm.products.classId('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1])] },
    [sm.products.typeId('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2])] },
    [sm.products.productId('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2]), sm.products.productId(id[0], id[1], id[2], id[3])] },
    [sm.products.alternatives('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2]), sm.products.productId(id[0], id[1], id[2], id[3]), sm.products.alternatives(id[0], id[1], id[2], id[3])] },
    [sm.products.similar('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2]), sm.products.productId(id[0], id[1], id[2], id[3]), sm.products.similar(id[0], id[1], id[2], id[3])] },
    [sm.products.alternatives('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2]), sm.products.productId(id[0], id[1], id[2], id[3]), sm.products.alternatives(id[0], id[1], id[2], id[3])] },
    [sm.products.alternativeId('*').url]: (id: string[]) => { return [sm.products.index, sm.products.id(id[0]), sm.products.classId(id[0], id[1]), sm.products.typeId(id[0], id[1], id[2]), sm.products.productId(id[0], id[1], id[2], id[3]), sm.products.alternatives(id[0], id[1], id[2], id[3]), sm.products.alternativeId(id[0], id[1], id[2], id[3], id[4])] },

    [sm.catalogue.index.url]: () => { return [sm.catalogue.index] },
    [sm.catalogue.searchResults.url]: () => { return [sm.catalogue.index, sm.catalogue.searchResults] },
    [sm.catalogue.products.index.url]: () => { return [sm.catalogue.index, sm.catalogue.products.index] },
    [sm.catalogue.products.groupId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.groupId(id[0])] },
    [sm.catalogue.products.categoryId('*', '*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.groupId(id[0]), sm.catalogue.products.categoryId(id[0], id[1])] },
    [sm.catalogue.products.classId('*', '*', '*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.groupId(id[0]), sm.catalogue.products.categoryId(id[0], id[1]), sm.catalogue.products.classId(id[0], id[1], id[2])] },
    [sm.catalogue.products.typeId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.searchResults] },
    [sm.catalogue.products.productId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.productId(id[0])] },
    [sm.catalogue.products.editProduct('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.editProduct(id[0])] },
    [sm.catalogue.products.similar('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.products.index, sm.catalogue.products.productId(id[0])] },
    [sm.catalogue.services.index.url]: () => { return [sm.catalogue.index, sm.catalogue.services.index] },
    [sm.catalogue.services.groupId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.services.index, sm.catalogue.services.groupId(id[0])] },
    [sm.catalogue.services.categoryId('*', '*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.services.index, sm.catalogue.services.groupId(id[0]), sm.catalogue.services.categoryId(id[0], id[1])] },
    [sm.catalogue.services.classId('*', '*', '*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.services.index, sm.catalogue.services.groupId(id[0]), sm.catalogue.services.categoryId(id[0], id[1]), sm.catalogue.services.classId(id[0], id[1], id[2])] },
    [sm.catalogue.brands.index.url]: () => { return [sm.catalogue.index, sm.catalogue.brands.index] },
    [sm.catalogue.brands.brandId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.brands.index, sm.catalogue.brands.brandId(id[0])] },
    [sm.catalogue.sellers.index.url]: () => { return [sm.catalogue.index, sm.catalogue.sellers.index] },
    [sm.catalogue.sellers.sellerId('*').url]: (id: string[]) => { return [sm.catalogue.index, sm.catalogue.sellers.index, sm.catalogue.sellers.sellerId(id[0])] },
    [sm.catalogue.products.add.url]: () => { return [sm.catalogue.index, sm.catalogue.products.add] },

    [sm.login.url]: () => { return [sm.home, sm.login] },

    [sm.register.index.url]: () => { return [sm.home, sm.register.index] },
    [sm.register.thankYou.url]: () => { return [sm.home, sm.register.index, sm.register.thankYou] },
    [sm.register.somethingWentWrong.url]: () => { return [sm.home, sm.register.index, sm.register.somethingWentWrong] },
    [sm.register.verificationExpired.url]: () => { return [sm.home, sm.register.index, sm.register.verificationExpired] },
    [sm.register.verificationSuccess.url]: () => { return [sm.home, sm.register.index, sm.register.verificationSuccess] },
    [sm.register.alreadyConfirmed.url]: () => { return [sm.home, sm.register.index, sm.register.alreadyConfirmed] },
    [sm.register.alreadyRegistered.url]: () => { return [sm.home, sm.register.index, sm.register.alreadyRegistered] },
    [sm.register.invitationExpired.url]: () => { return [sm.home, sm.register.index, sm.register.invitationExpired] },
    [sm.register.asBuyer.index.url]: () => { return [sm.home, sm.register.index, sm.register.asBuyer.index] },
    [sm.register.asSeller.index.url]: () => { return [sm.home, sm.register.index, sm.register.asSeller.index] },

    [sm.account.index.url]: () => { return [sm.account.index] },
    [sm.account.editProfile.url]: () => { return [sm.account.index, sm.account.editProfile] },

    [sm.portals.index.url]: () => { return [sm.home, sm.portals.index] },
    [sm.portals.buyer.index.url]: () => { return [sm.home, sm.portals.index, sm.portals.buyer.index] },
    [sm.portals.buyer.dashboard.url]: () => { return [sm.home, sm.portals.buyer.dashboard] },
    [sm.portals.buyer.documentsCenter.url]: () => { return [sm.home, sm.portals.buyer.documentsCenter] },
    [sm.portals.buyer.sellers.index.url]: () => { return [sm.home, sm.portals.buyer.sellers.index] },
    [sm.portals.buyer.sellers.allSellers.index.url]: () => { return [sm.home, sm.portals.buyer.sellers.allSellers.index] },
    [sm.portals.buyer.sellers.sellerId('*').url]: (id: string[]) => { return [sm.portals.buyer.sellers.index, sm.portals.buyer.sellers.sellerId(id[0])] },
    [sm.portals.buyer.users.url]: () => { return [sm.home, sm.portals.buyer.users] },
    [sm.portals.buyer.usersAccount.url]: () => { return [sm.home, sm.portals.buyer.usersAccount] },
    [sm.portals.buyer.procurement.requisitions.index.url]: () => { return [sm.portals.buyer.procurement.index, sm.portals.buyer.procurement.requisitions.index] },
    [sm.portals.buyer.procurement.requisitions.requisition('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.requisitions.index, , sm.portals.buyer.procurement.requisitions.requisition(id[0])] },
    [sm.portals.buyer.procurement.requestForQuotes.index.url]: () => { return [sm.portals.buyer.procurement.index, sm.portals.buyer.procurement.requestForQuotes.index] },
    [sm.portals.buyer.procurement.requestForQuotes.RequestForQuote('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.requestForQuotes.index, sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(id[0])] },
    [sm.portals.buyer.procurement.bundleRFQs.bundleRFQ('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.requestForQuotes.index, , sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(id[0])] },
    [sm.portals.buyer.procurement.bundleRFQs.edit('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.requestForQuotes.index, , sm.portals.buyer.procurement.bundleRFQs.edit(id[0])] },
    [sm.portals.buyer.procurement.quotes.index.url]: () => { return [sm.portals.buyer.procurement.index, sm.portals.buyer.procurement.quotes.index] },
    [sm.portals.buyer.procurement.quotes.quoteId('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.quotes.index, sm.portals.buyer.procurement.quotes.quoteId(id[0])] },
    [sm.portals.buyer.procurement.comparisonTables.index.url]: () => { return [sm.portals.buyer.procurement.index, sm.portals.buyer.procurement.comparisonTables.index] },
    [sm.portals.buyer.procurement.comparisonTables.ctId('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.comparisonTables.index, , sm.portals.buyer.procurement.comparisonTables.ctId(id[0])] },
    [sm.portals.buyer.procurement.purchaseOrders.index.url]: () => { return [sm.portals.buyer.procurement.index, sm.portals.buyer.procurement.purchaseOrders.index] },
    [sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.purchaseOrders.index, , sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(id[0])] },
    [sm.portals.buyer.procurement.purchaseOrders.edit('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.purchaseOrders.index, , sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(id[0])] },
    [sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.purchaseOrders.index, , sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(id[0])] },
    [sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderId('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.purchaseOrders.index, , sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(id[0])] },
    [sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderIdCT('*').url]: (id: string[]) => { return [sm.portals.buyer.procurement.purchaseOrders.index, , sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(id[0])] },

    [sm.portals.seller.index.url]: () => { return [sm.home, sm.portals.index, sm.portals.seller.index] },
    [sm.portals.seller.dashboard.url]: () => { return [sm.home, sm.portals.seller.dashboard] },
    [sm.portals.seller.documentsCenter.url]: () => { return [sm.home, sm.portals.seller.documentsCenter] },
    [sm.portals.seller.buyers.index.url]: () => { return [sm.home, sm.portals.seller.buyers.index] },
    [sm.portals.seller.buyers.allBuyers.index.url]: () => { return [sm.home, sm.portals.seller.buyers.allBuyers.index] },
    [sm.portals.seller.buyers.buyerId('*').url]: (id: string[]) => { return [sm.portals.seller.buyers.index, sm.portals.seller.buyers.buyerId(id[0])] },
    [sm.portals.seller.users.url]: () => { return [sm.home, sm.portals.seller.users] },
    [sm.portals.seller.usersAccount.url]: () => { return [sm.home, sm.portals.seller.usersAccount] },
    [sm.portals.seller.products.index.url]: () => { return [sm.home, sm.portals.seller.products.index] },
    [sm.portals.seller.products.sellerProductList('*').url]: (id: string[]) => { return [sm.portals.seller.products.index, sm.portals.seller.products.sellerProductList(id[0])] },

    [sm.portals.seller.procurement.quotes.index.url]: () => { return [sm.portals.seller.procurement.index, sm.portals.seller.procurement.quotes.index] },
    [sm.portals.seller.procurement.quotes.quoteId('*').url]: (id: string[]) => { return [sm.portals.seller.procurement.quotes.index, , sm.portals.seller.procurement.quotes.quoteId(id[0])] },
    [sm.portals.seller.procurement.quotes.edit('*').url]: (id: string[]) => { return [sm.portals.seller.procurement.quotes.index, , sm.portals.seller.procurement.quotes.edit(id[0])] },

    [sm.portals.seller.procurement.requestForQuotes.index.url]: () => { return [sm.portals.seller.procurement.index, sm.portals.seller.procurement.requestForQuotes.index] },
    [sm.portals.seller.procurement.requestForQuotes.requestForQuote('*').url]: (id: string[]) => { return [sm.portals.seller.procurement.requestForQuotes.index, , sm.portals.seller.procurement.requestForQuotes.requestForQuote(id[0])] },

    [sm.portals.seller.procurement.purchaseOrders.index.url]: () => { return [sm.portals.seller.procurement.index, sm.portals.seller.procurement.purchaseOrders.index] },
    [sm.portals.seller.procurement.purchaseOrders.purchaseOrderId('*').url]: (id: string[]) => { return [sm.portals.seller.procurement.purchaseOrders.index, , sm.portals.seller.procurement.purchaseOrders.purchaseOrderId(id[0])] },

    [sm.portals.seller.projects.projectId('*').url]: (id: string[]) => { return [sm.portals.seller.projects.index, sm.portals.seller.projects.projectId(id[0])] },
    [sm.portals.buyer.projects.projectId('*').url]: (id: string[]) => { return [sm.portals.buyer.projects.index, sm.portals.buyer.projects.projectId(id[0])] },

    [sm.portals.external.seller.procurement.requestForQuotes.requestForQuote('*').url]: (id: string[]) => { return [sm.home, , sm.portals.external.seller.procurement.requestForQuotes.requestForQuote(id[0])] },
    [sm.portals.external.seller.procurement.quotes.quoteId('*').url]: (id: string[]) => { return [sm.home, , sm.portals.external.seller.procurement.quotes.quoteId(id[0])] },
    [sm.portals.external.seller.procurement.purchaseOrders.purchaseOrderId('*').url]: (id: string[]) => { return [sm.home, , sm.portals.external.seller.procurement.purchaseOrders.purchaseOrderId(id[0])] },
    [sm.portals.external.buyer.procurement.quotes.quoteId('*').url]: (id: string[]) => { return [sm.home, , sm.portals.external.buyer.procurement.quotes.quoteId(id[0])] },

}
