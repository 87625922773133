import { Carousel, DescPopup, StyledClickableBorderedText } from '@/components';
import { ConvertToRFQProduct, Product, SellerData } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { useLinkOrCreateSellersSlice } from 'src/logic/zustand/catalouge/link-create-sellers/LinkOrCreateSellersSlice';
import { LinkOrCreateSellersPopup } from 'src/ui/components/smart/product/product-details-header/link-or-create-sellers-popup/link-sellers-popup';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';

type RequisitionProductWithSellersCardProps = {
  productDetails: ConvertToRFQProduct;
  isAlternative?: boolean;
  selectSellersForProduct: (product: Product, seller: SellerData) => void;
  editMode?: boolean;
  handleLinkSellersInEditMode?: (productId: number, newSellers: any) => void;
};

export const RequisitionProductWithSellersCard: React.VFC<RequisitionProductWithSellersCardProps> = ({
  productDetails,
  isAlternative,
  selectSellersForProduct,
  editMode,
  handleLinkSellersInEditMode,
}) => {
  const { t } = useTranslation();
  const detailsPopup = usePopup();
  const numberOfSelectedSellers = React.useMemo(() => {
    return productDetails?.sellers?.filter(seller => seller.is_selected).length ?? 0;
  }, [productDetails]);
  const { getUnlinkedSellers, existingSellersInReq, fetchSellersQueryStatus, linkSellersInReq } = useProcurementStore();
  const linkSellersPopup = usePopup();
  const { getAllLocations } = useLinkOrCreateSellersSlice(state => state.actions);

  const productId = productDetails?.id;
  useEffect(() => {
    if (linkSellersPopup.isOpen) {
      getUnlinkedSellers(productId);
      getAllLocations();
    }
  }, [productId, linkSellersPopup.isOpen]);

  return (
    <>
      <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView product={productDetails} />
      </DescPopup>
      <Grid
        container
        px={2}
        py={isAlternative ? 1.25 : 3}
        sx={{
          backgroundColor: 'white',
          borderRadius: '8px',
          // borderLeft: productDetails.inCart ? productDetails.inCartType == 'alternative' ? '8px solid #FB7F0D' : '8px solid #13628C' : null
          borderLeft: isAlternative ? '8px solid #FB7F0D' : '8px solid #13628C',
        }}
      >
        <Grid item container lg={5}>
          <Grid item container md={12} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box height='43px' width='43px' pr={1}>
              <img
                height='100%'
                width='100%'
                style={{ objectFit: 'contain' }}
                src={productDetails.image_url}
                alt={''}
              />
            </Box>
            <Box>
              <Grid item container md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant='body2'
                  fontWeight='700'
                  color='text.disabled'
                  className={'underline-on-hover cursor-pointer'}
                  onClick={detailsPopup.handleOpen}
                  sx={{
                    width: '350px',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {productDetails.name}
                </Typography>
                <Typography variant='subtitle2' color='text.third'>
                  {productDetails.sellers.length}
                  {'  '}
                  {t('texts.sellers')}
                  <span style={{ paddingLeft: 15 }}>
                    {numberOfSelectedSellers}
                    {'  '}
                    {t('texts.selected')}
                  </span>
                  <span
                    style={{ paddingLeft: 15, color: '#13628C', fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => {
                      linkSellersPopup.handleOpen();
                    }}
                  >
                    Link Seller
                  </span>
                </Typography>
              </Grid>
            </Box>
          </Grid>
          {/* <Grid item container md={12} mt={1}>
            {!isAlternative && (
              <Grid container item md={12}>
                <Box width={120} height={20} pr={1}>
                  {' '}
                  <TextFieldCounter
                    value={productDetails.quantity}
                    changeQuantity={(quantity: number) => {
                      onQuantityChange(productDetails, quantity);
                    }}
                  />
                </Box>
                <StyledSelect
                  placeholder={'custom_warranty'}
                  items={warrenty_terms}
                  handleChange={onWarrentTermsSelect}
                  value={productDetails?.warranty_term?.id ?? 0}
                />
              </Grid>
            )}
          </Grid> */}
        </Grid>

        <Grid item container lg={7} onClick={event => event.stopPropagation()} alignItems='center'>
          <Carousel>
            {productDetails.sellers.map(sellerData => (
              <Box px={1}>
                <StyledClickableBorderedText
                  handleTextClick={() => selectSellersForProduct(productDetails, sellerData)}
                  isSelected={sellerData.is_selected}
                  textProperties={sellerData.data}
                />
              </Box>
            ))}
          </Carousel>
        </Grid>

        <LinkOrCreateSellersPopup
          service={'requisition'}
          open={linkSellersPopup.isOpen}
          LoadingFetchStatus={fetchSellersQueryStatus}
          productId={productId}
          onSuccess={sellers =>
            editMode ? handleLinkSellersInEditMode?.(productId, sellers) : linkSellersInReq(productId, sellers)
          }
          existingSellers={existingSellersInReq}
          closePopup={linkSellersPopup.handleClose}
        />
      </Grid>
    </>
  );
};
