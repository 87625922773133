import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { TextTooltip } from "@/components";
import { useRef } from "react";

export const TableChipCell = ({ el, cell, data }) => {
    const { t } = useTranslation();
    const textRef = useRef();
    const config = el ? data[el] : data['none']
    return (
        <TextTooltip title={t(cell.column.id + "." + config.text)} textRef={textRef}>
            <Chip
                sx={{ mb: 0.5, mr: 0.5, height: 32, maxWidth: 120, minWidth: 50, backgroundColor: config.color ? config.color : 'primary.light' }}
                label={<Typography ref={textRef} variant='subtitle2' color={config.textColor ? config.textColor : 'primary'} fontWeight={500} style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {t(cell.column.id + "." + config.text)}
                </Typography>}
            />
        </TextTooltip>
    )
}