import { ExpandableListItemWithIcon, Loading, SearchTextField } from '@/components';
import { useDrawerStore } from '@/zustand';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import * as React from 'react';
import { sm } from 'src/site-map';
import { SideDrawerSubMenuItem } from './drawer-submenu-item';

export const DrawerSubMenuContent = () => {
  const { t } = useTranslation();
  const [searchValue, setsearchValue] = React.useState('');
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  const {
    drawerSubMenuNestedContentPreviousTitle,
    isLoadingDrawerSubMenuContent,
    drawerSubMenuNestedContent,
    removeItemFromSubMenuTree,
    closeDrawerSubMenu,
    drawerSubMenuParent,
    isDrawerSubMenuOpen,
    drawerSubMenuIndexesTree
  } = useDrawerStore()
  const currentSearchPlaceholder = drawerSubMenuParent?.subMenuPathQuery ? Object.keys(drawerSubMenuParent?.subMenuPathQuery)[drawerSubMenuIndexesTree?.length] : ""

  const { push } = useRouter()
  const goToProducts = () => push(sm.products.index.url)

  const groupedObjects = drawerSubMenuNestedContent?.reduce((groups, obj) => {
    const section = obj.section || 'default';
    if (!groups[section]) {
      groups[section] = [];
    }
    groups[section].push(obj);
    return groups;
  }, {});

  return (
    <Box height="100%">
      {isSmallScreen ? <Button variant="text" onClick={closeDrawerSubMenu} fullWidth sx={{ justifyContent: 'start', my: 1, textTransform: "uppercase", fontWeight: '600 !important', }}>
        <ArrowBackIosIcon color="primary" sx={{ fontSize: 11, mr: 1 }} />{t("pages_names." + drawerSubMenuParent?.title)}
      </Button>
        : <Typography color="primary.main" variant="overline" fontWeight="700" px={2} py={2} pb={3} textTransform="uppercase">{t("pages_names." + drawerSubMenuParent?.title)}</Typography>}
      {!isLoadingDrawerSubMenuContent ?
        <>
          {drawerSubMenuParent?.subMenuContent.canSearch && <Stack px={1} >
            <Box height={32} pb={0.5} >
              <SearchTextField onChange={(e) => setsearchValue(e.target.value)} value={searchValue} startAdornment translateKey={"search_by_" + currentSearchPlaceholder} /></Box>
            <Button variant="contained" fullWidth sx={{ mt: 0.5 }} onClick={goToProducts}>{t("buttons.view_catalogue")}</Button>
            <Divider sx={{ borderColor: 'info.contrastText', pt: 1.5, mb: 0.5 }} />
          </Stack>}
          {drawerSubMenuNestedContentPreviousTitle && <Stack height={32}>
            <ExpandableListItemWithIcon
              title={drawerSubMenuNestedContentPreviousTitle}
              handleClickListItem={() => removeItemFromSubMenuTree()}
              isItemExpanded={true}
              isItemContainsSubItem={false}
              icon={<ArrowBackIosIcon color="primary" sx={{ fontSize: 10 }} />}
              isMainButton={true}
            />
          </Stack>}
          <Stack height="72%" ml={isSmallScreen ? 1.5 : 0}>
            <Stack>
              {Object.keys(groupedObjects).map((section, index) => (
                <div key={section}>
                  {section !== 'default' && <Typography variant='subtitle2' color='warning.active' px={2} mt={2}>{t('pages_sections.' + section)}</Typography>}
                  {groupedObjects[section]?.filter((contentItem: any) =>
                    contentItem.title.toLowerCase().includes(searchValue.toLowerCase()) ? true : false
                  ).map((contentItem, index) => (
                    <SideDrawerSubMenuItem key={contentItem.id} index={index} drawerItemDetails={contentItem} isDrawerOpen={isDrawerSubMenuOpen} />
                  ))}
                  {section !== 'default' && index != Object.keys(groupedObjects).length - 1 && <Divider color='info.contrastText' sx={{ mx: 2 }} />}
                </div>
              ))
              }
              {/* {groupedObjects?.filter((contentItem: any) =>
                  contentItem.title.toLowerCase().includes(searchValue.toLowerCase()) ? true : false
                ).map((contentItem: any, index: number) => (
                ))} */}
            </Stack>
          </Stack>
        </>
        : <Loading fullHeight={true} />}
    </Box>
  )
}