import { ProductManagerEnv } from '@/config';

import { Product } from '@/models';
import PurchaseOrderSavingServices from '../purchase-orders';
import RequsitionSavingServices from '../requisitions';
import EditBundleRfqSavingServices from '../edit_bundle_rfq';
import EditPoFromScratchSavingServices from '../edit_po_from_scratch';
import EditQuoteFromScratchSavingServices from '../edit_quote_from_scratch';
import QuoteSavingServices from '../quotes';

type EnvChangeRequirementsServiceMapperParams = {
  env: ProductManagerEnv;
  formId: number;
  product: Product;
  newRequirements?: string | null;
};

const EnvChangeRequirementsServiceMapper = ({
  env,
  formId,
  product,
  newRequirements,
}: EnvChangeRequirementsServiceMapperParams) => {
  if (env === ProductManagerEnv.requisition) {
    return () =>
      RequsitionSavingServices.changeAdditionalRequirements({
        requisition_id: formId,
        product_id: product.id,
        additional_requirements: newRequirements,
      });
  } else if (env === ProductManagerEnv.purchase_order) {
    return () =>
      PurchaseOrderSavingServices.changeAdditionalRequirements({
        purchase_order_id: formId,
        product_id: product.id,
        additional_requirements: newRequirements,
      });
  } else if (env === ProductManagerEnv.quote) {
    return () =>
      QuoteSavingServices.changeAdditionalRequirements({
        quote_id: formId,
        product_id: product.id,
        additional_requirements: newRequirements,
      });
  } else if (env === ProductManagerEnv.edit_bundle_rfq) {
    return () => EditBundleRfqSavingServices.changeAdditionalRequirements();
  } else if (env === ProductManagerEnv.edit_quote_from_scratch) {
    return () => EditQuoteFromScratchSavingServices.changeAdditionalRequirements();
  } else if (env === ProductManagerEnv.edit_po_from_scratch) {
    return () => EditPoFromScratchSavingServices.changeAdditionalRequirements();
  }
  return null;
};

export default EnvChangeRequirementsServiceMapper;
