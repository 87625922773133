import { Grid, Switch, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface props {
  label: string;
  value: boolean;
}
export const CheckedTextDataView: React.FC<props> = ({ label, value }) => {
  return (
    <Grid container my={2} sx={{ justifyContent: 'space-between' }}>
      <Grid item xs={10}>
        <Typography variant='h6' color={value ? 'text.primary' : 'info.light'} fontWeight={700}>
          {label}
        </Typography>
      </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {value ? <CheckIcon /> : <CloseIcon sx={{ color: 'info.light' }} />}
        </Grid>
    </Grid>
  );
};
