import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { ColumnType, LinkType, ReadStatus } from '@/models';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { StatusChip } from '../../procurement';
import { Link } from '@/components';
import CircleIcon from '@mui/icons-material/Circle';
import { useUserData } from '@/zustand';
import { sm } from 'src/site-map';

export type ProcurementTableDataProps = {
  data: any;
  onRoute?: (type: string, id: string, queryId?: string) => void;
};

const borderRadius = '8px';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: 'transparent',
  '.MuiTableCell-root:first-child': {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
  },
  '.MuiTableCell-root:last-child': {
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  },
}));

export const ProcurementTableData: React.FC<ProcurementTableDataProps> = ({ data, onRoute }) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortedData, setSortedData] = useState([]);
  const toggleSortDirection = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleColumnClick = (columnTitle: string) => {
    if (columnTitle === sortColumn) {
      toggleSortDirection();
    } else {
      setSortColumn(columnTitle);
      setSortDirection('asc');
    }
  };
  useEffect(() => {
    let newSortedData = sortDataByColumn(data, sortColumn, sortDirection);
    setSortedData(newSortedData);
  }, [data, sortColumn, sortDirection]);
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ borderCollapse: 'separate' }}>
        <TableHead style={{ backgroundColor: '#F6F8FC' }}>
          <TableRow>
            {data?.tableData?.rows[0].columns?.map(col => (
              <TableCell
                key={col.title}
                onClick={() =>
                  col.title !== 'status' &&
                  col.title !== 'valid until' &&
                  col.title !== 'P&S' &&
                  col.title !== 'sellers' &&
                  col.title !== 'buyers' &&
                  col.title !== 'quote.s' &&
                  col.title !== 'po.s' &&
                  col.title !== 'phone number' &&
                  col.title !== 'categories' &&
                  col.title !== 'brands' &&
                  col.title !== 'contact' &&
                  col.title &&
                  handleColumnClick(col.name)
                }
                sx={{ cursor: 'pointer' }}
              >
                <Grid container alignItems={'center'} wrap='nowrap'>
                  <Grid item>
                    <Typography
                      variant='subtitle2'
                      color='text.third'
                      fontWeight={600}
                      sx={{ fontSize: 11, textTransform: 'uppercase' }}
                    >
                      {' '}
                      {col.title}{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {' '}
                    {col.type !== ColumnType.READ_TICK &&
                      col.type !== ColumnType.STATUS &&
                      col.type !== ColumnType.Expiry_mark &&
                      col.title !== 'valid until' &&
                      col.title !== 'P&S' &&
                      col.title !== 'sellers' &&
                      col.title !== 'buyers' &&
                      col.title !== 'status' &&
                      col.title !== 'quote.s' &&
                      col.title !== 'po.s' &&
                      col.title !== 'phone number' &&
                      col.title !== 'categories' &&
                      col.title !== 'brands' &&
                      col.title !== 'contact' &&
                      col.title && <UnfoldMoreIcon sx={{ fontSize: '10px' }} />}
                  </Grid>
                </Grid>
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ bgcolor: 'white' }}>
          {sortedData?.map((row, index) => (
            <React.Fragment key={`${row?.id}-${index}`}>
              <Row row={row} onRoute={onRoute} />
              <TableRow sx={{ height: '0.5rem', backgroundColor: 'transparent' }}></TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function Row({ row, onRoute }) {
  const [open, setOpen] = useState(false);
  const { isSeller } = useUserData();

  const getMainRowUrl = () => {
    // If it's the main row + it's a part of a bundle => that means it's a result of a filteration operation
    if ([LinkType.RFQ_OF_BUNDLE, LinkType.PO_OF_BUNDLE].includes(row.form_type)) {
      return onRoute(row.form_type, row.parent_id, row.id);
    }
    return onRoute(row.form_type, row.id, row.parent_id);
  };

  return (
    <>
      <StyledTableRow
        onClick={() => setOpen(!open)}
        sx={{
          '& > *': { borderBottom: '1px solid #e0e0e0' },
          backgroundColor: 'transparent',
          cursor: 'pointer',
          // '&:hover': { backgroundColor: 'lightgray', cursor: 'pointer' }
        }}
      >
        {row?.columns?.map(col => (
          <TableCell component='th' scope='row' key={col.id} sx={{ fontSize: 12 }}>
            {col.type == ColumnType.STATUS && <StatusChip status={col.description} />}
            {col.type == ColumnType.READ_TICK && (
              <Grid container>
                {col.description == ReadStatus.PARTIALLY_READ ? (
                  <Grid container>
                    <Box sx={{ minWidth: '40px' }}>
                      <Grid container>
                        <Grid item md={6}>
                          <DoneIcon sx={{ color: 'success.dark', fontSize: '18px' }} />
                        </Grid>
                        <Grid item md={6}>
                          <DoneIcon sx={{ color: 'info.light', fontSize: '18px', ml: -1.7 }} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  <DoneAllIcon
                    sx={{
                      color:
                        col.description == ReadStatus.PENDING
                          ? 'info.light'
                          : col.description == ReadStatus.READ
                          ? 'success.dark'
                          : 'transparent',
                      fontSize: '18px',
                    }}
                  />
                )}
              </Grid>
            )}
            {col.type == ColumnType.LINK && (
              <Grid container alignItems={'center'}>
                {col._hasNewChanges ? <CircleIcon sx={{ color: 'success.dark', fontSize: '12px', mr: 1 }} /> : <></>}
                <Link
                  underline='hover'
                  href={getMainRowUrl()}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  {' '}
                  {col.description}{' '}
                </Link>
              </Grid>
            )}
            {col.type == ColumnType.LINK_DOUBLE && (
              <Link
                underline='hover'
                href={{
                  pathname: isSeller() ? sm.portals.seller.usersAccount.url : sm.portals.buyer.usersAccount.url,
                  query: { userId: row.ownerId },
                }}
                onClick={event => event.stopPropagation()}
              >
                <Typography variant='subtitle2' fontWeight={400}>
                  {' '}
                  {col.description}{' '}
                </Typography>
              </Link>
            )}
            {col.type != ColumnType.READ_TICK &&
              col.type != ColumnType.STATUS &&
              col.type != ColumnType.LINK &&
              col.type != ColumnType.LINK_DOUBLE &&
              col.description}
          </TableCell>
        ))}
        <TableCell>
          {row.children && (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </StyledTableRow>
      {open && (
        <>
          {row.children?.map(childRow => (
            <TableRow key={childRow.id} sx={{ bgcolor: 'primary.light' }}>
              {childRow.columns.map(col => (
                <TableCell key={col.id} sx={{ fontSize: 12 }}>
                  {col.type == ColumnType.STATUS && <StatusChip status={col.description} withOutBorder={true} />}
                  {col.type == ColumnType.READ_TICK && (
                    <Grid container>
                      {col.description == ReadStatus.PARTIALLY_READ ? (
                        <>
                          <DoneIcon sx={{ color: 'success.dark', fontSize: '18px' }} />
                          <DoneIcon sx={{ color: 'info.light', fontSize: '18px', ml: -1.4 }} />
                        </>
                      ) : (
                        <DoneAllIcon
                          sx={{
                            color:
                              col.description == ReadStatus.PENDING
                                ? 'info.light'
                                : col.description == ReadStatus.READ
                                ? 'success.dark'
                                : 'transparent',
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </Grid>
                  )}
                  {col.type == ColumnType.LINK && (
                    <Grid container alignItems={'center'}>
                      {col._hasNewChanges ? (
                        <CircleIcon sx={{ color: 'success.dark', fontSize: '12px', mr: 1 }} />
                      ) : (
                        <></>
                      )}
                      <Link
                        underline='hover'
                        href={onRoute(childRow.form_type, row.id, childRow.id)}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        {' '}
                        {col.description}{' '}
                      </Link>
                    </Grid>
                  )}
                  {col.type != ColumnType.READ_TICK &&
                    col.type != ColumnType.STATUS &&
                    col.type != ColumnType.LINK &&
                    col.type != ColumnType.LINK_DOUBLE &&
                    col.description}
                </TableCell>
              ))}
              <TableCell sx={{ bgcolor: 'primary.light' }}> </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
}

export function sortDataByColumn(data, sortColumn, sortDirection) {
  const sortedData = Array.from(data?.tableData?.rows || []);

  sortedData.sort((a, b) => {

    const columnA = (a?.columns || []).find(col => col.name === sortColumn);
    const columnB = (b?.columns || []).find(col => col.name === sortColumn);

    let valueA = columnA ? extractValue(columnA.description, columnA.title) : '';
    let valueB = columnB ? extractValue(columnB.description, columnB.title) : '';

    const dateA = parseDate(valueA);
    const dateB = parseDate(valueB);

    if (!isNaN(dateA) && !isNaN(dateB)) {
      return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
    }

    const numA = parseFloat(valueA);
    const numB = parseFloat(valueB);

    if (!isNaN(numA) && !isNaN(numB)) {
      return sortDirection === 'asc' ? numA - numB : numB - numA;
    }

    valueA = valueA?.toString() || '';
    valueB = valueB?.toString() || '';
    if (sortDirection === 'asc') {
      return valueA.localeCompare(valueB);
    } else {
      return valueB.localeCompare(valueA);
    }
  });

  return sortedData;
}

function extractValue(description, title) {
  if (typeof description === 'object' && description !== null) {
    if (description.props) {
      const firstKey = Object.keys(description.props)[0];

      if (title === 'project') {
        return description.props.name;
      } else if (title === 'company') {
        return description.props.children?.[0]?.props?.children?.props?.children || '';
      }
      return description.props[firstKey];
    }
  } else {
    return description || '';
  }
}

function parseDate(dateString) {
  if (typeof dateString !== 'string') return NaN;
  const parts = dateString.split('-').map(Number);
  if (parts.length === 3) {
    const [day, month, year] = parts;
    const date = new Date(year, month - 1, day);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }
  return NaN;
}
