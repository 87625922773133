import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'next-i18next';
 
export type CardWithAvatarProps = {
    children?: React.ReactNode;
};

export const CardWithAvatar: React.VFC<CardWithAvatarProps> = ({
    children,
}) => {
  const { t } = useTranslation()
  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia
        component='img'
        sx={{ width: 151 }}
        image='/static/images/cards/live-from-space.jpg'
        alt='Live from space album cover'
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h5'>
             {t('texts.live_from_space')}
          </Typography>
          <Typography
            variant='subtitle1'
            color='text.secondary'
            component='div'
          >
            Mac Miller
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};
