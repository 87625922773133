import React from 'react';
import { GeneralObject } from '@/models';
import { Button, Grid, Typography } from '@mui/material';
import { gridItemStyles } from '../styles';
import { useFormikContext } from 'formik';
import { GeneralTermsFormType } from './types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import usePopup from 'src/logic/hooks/use-popup';
import { StyledPopper } from 'src/ui/components/shared';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type SelectTermsItemProps = {
  label: string;
  name: keyof GeneralTermsFormType;
  options: GeneralObject[];
  style?: React.CSSProperties;
  handleSelectGeneralTerms: (values: any) => void;
  shouldShowErrorIndicator?: boolean;
};

const SelectTermsItem = ({
  label,
  name,
  options,
  style = {},
  handleSelectGeneralTerms,
  shouldShowErrorIndicator,
}: SelectTermsItemProps) => {
  const formik = useFormikContext<GeneralTermsFormType>();
  const value = formik.values[name];
  const selectPopup = usePopup();
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const selectedItem = React.useMemo(() => {
    return options?.find(opt => opt.id == value);
  }, [options, value]);

  const onSelect = (optionId: number | string) => {
    formik.setFieldValue(name, optionId);
    selectPopup.handleClose();

    // Handle Submit if all other values are filled
    const { values } = formik;
    const allOtherValuesAreFilled = Object.keys(values)
      .filter(key => key !== name) // Remove curreny key (To make sure the values are updated and there is no delay in updating the state)
      .every(key => values[key] !== null && values[key] !== undefined); // All other values are filled

    if (allOtherValuesAreFilled) {
      // Ready to submit
      const valuesToSubmit = {
        ...values,
        [name]: optionId,
      };
      handleSelectGeneralTerms(valuesToSubmit);
    }
  };

  return (
    <Grid item md={3.25} style={{ ...gridItemStyles, ...style }}>
      <Typography color='#13628C' fontWeight={400} fontSize={12} ref={anchorRef}>
        {shouldShowErrorIndicator && !value && (
          <ErrorOutlineIcon
            color='error'
            sx={{ fontSize: '18px', transform: 'translateY(25%)', marginInlineEnd: '.4rem' }}
          />
        )}
        <span style={{ color: '#8B8D98' }}>{label}: </span>
        <span className='cursor-pointer underline-on-hover' onClick={selectPopup.handleToggle}>
          {selectedItem?.name || 'Select Terms'}{' '}
          <KeyboardArrowDownIcon
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              transform: 'translateY(30%)',
            }}
          />
        </span>
      </Typography>
      <StyledPopper open={selectPopup.isOpen} anchorEl={anchorRef.current} handleClose={selectPopup.handleClose}>
        <div
          style={{
            maxHeight: '15rem',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            paddingInlineEnd: '.25rem',
            paddingBlock: '.25rem',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 8px 32px 0px #EBEBEB',
          }}
          className='styled-scrollbar'
        >
          {options.map(opt => (
            <Button
              fullWidth
              key={opt.id}
              type='button'
              onClick={() => {
                onSelect(opt.id);
              }}
              sx={{
                borderBottom: '1px solid #EAEEF6',
                backgroundColor: opt.id == selectedItem?.id ? '#EAEEF6' : '#fff',
              }}
            >
              {opt.name}
            </Button>
          ))}
        </div>
      </StyledPopper>
    </Grid>
  );
};

export default SelectTermsItem;
