import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { SE } from 'public/assets/icons';

import { getInputsTranslationKey, useDebounce } from '@/hooks';

type SearchTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  translateKey: string;
  onChange: (newValue: string) => void;
};

const DebouncedSearchInput = (props: SearchTextFieldProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');
  useDebounce(() => props.onChange(value), 700, [value]);
  const handleClear = () => {
    setValue('');
    props.onChange('');
  };
  return (
    <TextField
      placeholder={t(
        getInputsTranslationKey('labels.search.' + props.translateKey)
      )}
      onChange={e => setValue(e.target.value)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            sx={{
              width: '19px',
              height: '19px',
              marginLeft: -1,
              marginRight: 0.5,
            }}
          >
            <Image src={SE} objectFit='contain' alt='Fancy' />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position='end' onClick={handleClear}>
            <span style={{ cursor: 'pointer' }}>✕</span>
          </InputAdornment>
        ),
        sx: {
          bgcolor: 'secondary.contrastText',
          width: '100%',
          height: '100%',
          borderColor: 'divider',
          borderRadius: '6px',
        },
      }}
      sx={{
        flex: 1,
        height: '100%',
        width: '100%',
        textOverflow: 'ellipsis !important',
      }}
    />
  );
};

export default DebouncedSearchInput;
