import { sm } from "src/site-map";
import { AuthenticateStatus } from "../authenticated-status";
import { Permissions } from "../Permissions";
import { Roles } from "../Roles";
import { UserType } from "../user-type";

export interface RouteProtectionRule {
    authenticationStatus: AuthenticateStatus,
    userType: UserType,
    roles: Roles[],
    permissions: Permissions[],
    redirectUrl: string,
}
interface Rule {
    [index: string]: RouteProtectionRule
}

export const RouteProtectionRules: Rule = {
    [sm.home.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.careers.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.careers.id('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.becomeASeller.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.contactUs.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.searchResults.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.searchResults.id('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.requisitions.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.requisitions.draft.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.requisitions.requisition('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.products.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.id('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.classId('*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.typeId('*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.productId('*', '*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.alternatives('*', '*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.similar('*', '*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.products.alternativeId('*', '*', '*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.catalogue.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.searchResults.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.brands.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.brands.brandId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.sellers.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.sellers.sellerId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.groupId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.categoryId('*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.classId('*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.typeId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.productId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.similar('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.alternatives('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.services.index.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.services.groupId('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.services.categoryId('*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.services.classId('*', '*', '*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.add.url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.catalogue.products.editProduct('*').url]: { authenticationStatus: AuthenticateStatus.UNDEFINED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },

    [sm.login.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },

    [sm.register.index.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.thankYou.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.somethingWentWrong.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.verificationExpired.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.verificationSuccess.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.alreadyConfirmed.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.alreadyRegistered.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.invitationExpired.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.asBuyer.index.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },
    [sm.register.asSeller.index.url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.portals.index.url },

    [sm.account.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.login.url },
    [sm.account.editProfile.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.login.url },

    [sm.portals.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: sm.login.url },

    [sm.portals.buyer.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.dashboard.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [], redirectUrl: sm.login.url },
    [sm.portals.buyer.documentsCenter.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.sellers.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.sellers.allSellers.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.sellers.sellerId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },

    [sm.portals.buyer.users.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.usersAccount.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.requisitions.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.requisitions.requisition('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.projects.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.projects.projectId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.requestForQuotes.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.requestForQuotes.RequestForQuote('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.bundleRFQs.bundleRFQ('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.bundleRFQs.edit('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.quotes.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.quotes.quoteId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.comparisonTables.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.comparisonTables.ctId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.edit('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderIdCT('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.BUYER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },

    [sm.portals.seller.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.dashboard.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [], redirectUrl: sm.login.url },
    [sm.portals.seller.documentsCenter.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.buyers.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.buyers.allBuyers.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.buyers.buyerId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.users.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.products.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.products.sellerProductList('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.usersAccount.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    // [sm.portals.seller.requisitions.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.projects.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.projects.projectId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.products.sellerProductList('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.catalogue.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },

    [sm.portals.seller.procurement.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.requestForQuotes.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.requestForQuotes.requestForQuote('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.quotes.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.quotes.quoteId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.quotes.edit('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.purchaseOrders.index.url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },
    [sm.portals.seller.procurement.purchaseOrders.purchaseOrderId('*').url]: { authenticationStatus: AuthenticateStatus.AUTHENTICATED, userType: UserType.SELLER, roles: [], permissions: [Permissions.portal_access_permission], redirectUrl: sm.login.url },

    [sm.portals.external.seller.procurement.requestForQuotes.requestForQuote('*').url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.portals.external.seller.procurement.quotes.quoteId('*').url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.portals.external.seller.procurement.purchaseOrders.purchaseOrderId('*').url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
    [sm.portals.external.buyer.procurement.quotes.quoteId('*').url]: { authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED, userType: UserType.UNDEFIENED, roles: [], permissions: [], redirectUrl: '' },
};