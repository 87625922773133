import { Grid, Button, Card, CardMedia, Typography } from '@mui/material';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DeleteIcon } from 'src/ui/icons/delete';
import { Requisition, Product } from '@/models';
import { ConfirmPopup } from '@/components';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'next-i18next';

type Props = {
  index: number;
  product: Product;
  requisition: Requisition;
  selectedProductIndex: number;
  onClickCard: Function;
  removeProduct: Function;
  isPopupProducts: boolean;
  tabId: number;
};

export const ProductTab: React.FC<Props> = ({
  index,
  product,
  selectedProductIndex,
  onClickCard,
  removeProduct,
  requisition,
  tabId,
  isPopupProducts,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = e => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleDelete = (product, id, tabId) => {
    removeProduct(product, id, tabId);
  };
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: '100%',
        p: 1,
        bgcolor: index == selectedProductIndex ? 'secondary.main' : 'white',
        height: '48px',
        cursor: 'pointer',
        flexDirection: 'row',
      }}
      onClick={() => onClickCard(index)}
    >
      <Grid container>
        <Grid container item xs={11}>
          <CardMedia
            component='img'
            image={product.imageURL}
            sx={{
              height: '31px',
              width: '31px',
              objectFit: 'contain',
              backgroundColor: 'white',
            }}
          />
          <Grid container width='87%'>
            <Typography
              
              fontWeight='500'
              sx={{
                maxWidth: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                alignSelf: 'center',
                mx: 1,
                color: index == selectedProductIndex ? 'white' : 'text.primary',
              }}
            >
              {product.name}
            </Typography>
            <Typography
              variant='body2'
              fontWeight='500'
              sx={{
                width: '2%',
                alignSelf: 'center',
                color: index == selectedProductIndex ? 'white' : 'text.primary',
              }}
            >
              {!isPopupProducts
                ? `(${requisition.products.length})`
                : `(${requisition.products[index].altProducts.length})`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {isPopupProducts ? (
            <>
              <Button
                onClick={e => handleClickOpen(e)}
                sx={{
                  maxWidth: '20px',
                  maxHeight: '30px',
                  minWidth: '20px',
                  minHeight: '30px',
                }}
              >
                <DeleteIcon
                  sx={{
                    color:
                      index == selectedProductIndex ? 'white' : 'text.third',
                    fontSize: '23px !important',
                  }}
                />
              </Button>
              <ConfirmPopup
                buttonTitleKey={t('buttons.confirm_delete')}
                titleKey={t('texts.confirm_delete',{ ProductName: product.name })}
                handleClose={handleClose}
                handleConfirm={() =>
                  handleDelete(product, requisition.id, tabId)
                }
                open={open}
              />
            </>
          ) : (
            <MoreVertIcon sx={{ color: 'white', fontSize: '23px !important' }} />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
