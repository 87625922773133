import { Typography } from "@mui/material";
import { FC } from "react";
import { CustomWarrantyPopper } from "../poppers";


export const CustomWarrantyText: FC<{ warrantyName: string, isWarranyTermCustom: boolean }> = ({ warrantyName, isWarranyTermCustom }) => {
    return (
        <>
            <Typography variant='subtitle2' fontWeight='500' color='primary.main'>
                {warrantyName}
            </Typography>
            {isWarranyTermCustom && <CustomWarrantyPopper />}
        </>
    )
}