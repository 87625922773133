import { FC } from "react"
import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { ProcurementButtons, ProcurementPopupStatus } from "@/config"
import { MarkChatReadOutlined } from "@mui/icons-material"
import { DescPopup, ProcurementActionsPopupFormContent, SendToOtherCompany } from "src/ui/components/shared"
import { GeneralObject, ProcurementFormModel } from "@/models";
import { RequestForQuoteRedirectPopup } from "../request-for-quote-success-popup";
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


interface RequestForQuotePopupsProps {
    openedPopupStatus: ProcurementPopupStatus
    handleClosePopup: () => void
    reasonsForPopup?: GeneralObject[]
    requestForQuoteDetails: ProcurementFormModel
}
export const RequestForQuotePopups: FC<RequestForQuotePopupsProps> = ({ openedPopupStatus, handleClosePopup, reasonsForPopup, requestForQuoteDetails }) => {
    const { t } = useTranslation();
    const { sendForApproval, getReasonsForPopup, reject, approve, cancel, cancelSendForApproval, sendRFQToSeller } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceActions)
    const { successPopupStatus, customLoadingButton } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceProperties)

    const handleGetReasonsForPopup = () => getReasonsForPopup()
    const handleSendForApprovalAction = (values) => sendForApproval(values.comment)
    const handleRejectPopup = (values) => reject(values.reasonId, values.comment)
    const handleApprovePopup = (values) => approve(values.comment)
    const handleCancel = (values) => cancel(values.comment, values.reasonId)
    const handleCancelApproval = (values) => cancelSendForApproval(values.comment)
    const handleSendForSellerAction = (values: any) => sendRFQToSeller(values.validity_date, values.comment)

    return (
        <>
            {/* Send for approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleSendForApprovalAction}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined />}
                    buttonTitle={t('popups.titles.send_for_approval')}
                    status="primary"
                    popupTitle={t('popups.titles.send_for_approval')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_SINGLE_RFQ_FOR_APPROVAL}
                />
            </DescPopup>

            {/* <RequestForQuoteRedirectPopup
                openPopup={successPopupStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.sent_for_approval')}
                subTitle={t('popups.subTitles.rfq_sent_for_approval')}
                colorTitle='success.main'
            /> */}

            {/* Approve popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.APPROVE} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleApprovePopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='success' />}
                    popupTitle={t('popups.titles.approve_rfq', { RFQName: requestForQuoteDetails?.model.name })}
                    buttonTitle={t('buttons.approve_rfq')}
                    status="success"
                    isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_SINGLE_RFQ}
                />
            </DescPopup>
            {/* Reject popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.REJECT} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRejectPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    popupTitle={t('popups.titles.reject_rfq', { RFQName: requestForQuoteDetails?.model.name })}
                    buttonTitle={t('buttons.reject_rfq')}
                    popupSubTitle={t('popups.subTitles.reject_rfq')}
                    status="error"
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_SINGLE_RFQ}
                />
            </DescPopup>

            {/* cancel approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelApproval}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.cancel_approval_request')}
                    status="error"
                    popupTitle={t('buttons.cancel_approval_request')}
                    popupSubTitle={t('popups.subTitles.cancel_approval_rfq')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_SINGLE_RFQ_APPROVAL}
                />
            </DescPopup>


            {/* cancel popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancel}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    popupTitle={t('popups.titles.cancel_rfq')}
                    buttonTitle={t('popups.titles.cancel_rfq')}
                    popupSubTitle={t('popups.subTitles.reason_cancel_rfq')}
                    status="error"
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_SINGLE_RFQ}
                />
            </DescPopup>


            {/* Send to seller popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SENT_TO_SELLER} handleClose={handleClosePopup}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary' />}
                    popupTitle={t('buttons.send_rfq_to_seller')}
                    validTitle={t('table_cells.rfq') + " " + t('texts.valid_until')}
                    buttonTitle={t('buttons.send_rfq_to_seller')}
                    handlePopupAction={handleSendForSellerAction}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_SINGLE_RFQ_TO_SELLER}
                />
            </DescPopup>

            {/* <RequestForQuoteRedirectPopup
                openPopup={successPopupStatus == ProcurementPopupStatus.SENT_TO_SELLER}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.sent_to_seller')}
                subTitle={t('popups.subTitles.sent_to_seller')}
                colorTitle='success.main'
            /> */}
        </>
    )
}