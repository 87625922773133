import { ProcurementButtons, ProcurementPopupStatus } from "@/config";
import { GeneralObject, LinkType, Quote, QuoteDetails, QuoteDetailsBuyer, QuoteProductTableRow } from "@/models";
import { buildBuyerQuoteDetails, buildQuoteProductsTable, NotifySuccess, NotifySuccessWithCancelIcon, updatePurchaseOrderFromQuote as updatePurchaseOrderFromQuoteService, useChangeProject, useDeclineQuote, useEditExpiryDate, useFetchDeclineQuoteReasons, useGeneratePurchaseOrder, useGetBuyerPurchaseOrderDetails, useGetBuyerQuoteDetails, useRequestExtension } from "@/services";
import produce from "immer";
import { useUserData } from "src/logic/zustand/user";
import { StateCreator } from "zustand";
import { getVATTotalValues } from "./createProcurementSlice";
// import { toast } from "react-toastify";
import { t } from "i18next";
import { getExternalBuyerQuoteDetails } from "src/logic/services/procurement/external-quote";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";

type ResponseHandlers<T = any> = {
    onSuccess?: (data: T) => void;
    onError?: () => void;
  };

export interface BuyerQuoteDetailsSlice {
    quoteBuyerDetailsSliceProperties: {
        quote: QuoteDetailsBuyer | null
        fetchQuoteDetailsQueryStatus: QueryHandlerModel,
        // loadingQuoteDetails: boolean
        productsTableRows: QuoteProductTableRow[]
        reasonsForPopup: GeneralObject[]
        openedPopupStatus: ProcurementPopupStatus | null
        newComments: Comment[]
        numberOfSelectedProducts: number
        isAllProductsSelected: boolean
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        customLoadingButton: ProcurementButtons | null
    }
    quoteBuyerDetailsSliceActions: {
        fetchQuoteDetails: (quoteId: number, generatingPurchaseOrder: boolean, poIdToEdit?: number) => void
        fetchExternalQuoteDetails: (quoteId: number, generatingPurchaseOrder: boolean, poIdToEdit?: number) => void
        // setIsLoading: (isLoading: boolean) => void
        setQuoteDetails: (quote: Quote, generatingPurchaseOrder: boolean, unsetComments?: boolean) => void
        setProductsTableRows: (quote: QuoteDetails | null) => void
        setRawProductsTableRows: (newProductsTableRow: QuoteProductTableRow) => void
        initializeProductsTableRowsFromProducts: (purchaseOrderProducts: any, onUpdated?: () => void) => void
        closeNewCommentsPopup: () => void
        toggleSelectProductFromQuote: (product: QuoteProductTableRow) => void
        changeRequestedQuantity: (product: QuoteProductTableRow, quantity: number) => void
        generatePurchaseOrder: (handleOpenPOGeneratedPopup: () => void, handleErrorMessage: () => void) => void
        updatePurchaseOrderFromQuote: (poId: string, onSuccess: (data: any) => void, handleErrorMessage: () => void) => void
        setSummaryDetails: (products: QuoteProductTableRow[]) => void
        toggleSelectAllProducts: (value: boolean) => void
        setIsAllProductsSeleced: (products: QuoteProductTableRow[], numberOfSelectedProducts: number) => void
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        decline: (reasonId: string, comment?: string) => void
        getReasonsForPopup: () => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        requestExtensionForQuoteExpiredByBuyer: (responseHandlers?: ResponseHandlers<Quote>) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        changeProject: (project: GeneralObject) => void
    }
}

export const createBuyerQuoteDetailsSlice: StateCreator<BuyerQuoteDetailsSlice> = (set, get, api) => ({
    quoteBuyerDetailsSliceProperties: {
        quote: null,
        fetchQuoteDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingQuoteDetails: false,
        productsTableRows: [],
        newComments: [],
        numberOfSelectedProducts: 0,
        reasonsForPopup: [],
        openedPopupStatus: null,
        isAllProductsSelected: false,
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        customLoadingButton: null
    },
    quoteBuyerDetailsSliceActions: {
        fetchQuoteDetails: (quoteId: number, generatingPurchaseOrder: boolean, poIdToEdit?: number) => {
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useGetBuyerQuoteDetails(quoteId).then(data => {
                get().quoteBuyerDetailsSliceActions.setQuoteDetails(data.data, generatingPurchaseOrder)

                if (poIdToEdit) {
                    useGetBuyerPurchaseOrderDetails(poIdToEdit).then(poData => {
                        const purchaseOrderProducts = poData.data.products;
                        get().quoteBuyerDetailsSliceActions.initializeProductsTableRowsFromProducts(purchaseOrderProducts)
                        set(produce(draftState => {
                            draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                        }))
                    }).catch((response) => {
                        set(produce(draftState => {
                            draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                        }))
                    })
                } else {
                    set(produce(draftState => {
                        draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                    }))
                }
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        fetchExternalQuoteDetails: (quoteId: number, generatingPurchaseOrder: boolean, poIdToEdit?: number) => {
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            getExternalBuyerQuoteDetails(quoteId).then(data => {
                get().quoteBuyerDetailsSliceActions.setQuoteDetails(data.data, generatingPurchaseOrder)

                if (poIdToEdit) {
                    useGetBuyerPurchaseOrderDetails(poIdToEdit).then(poData => {
                        const purchaseOrderProducts = poData.data.products;
                        get().quoteBuyerDetailsSliceActions.initializeProductsTableRowsFromProducts(purchaseOrderProducts)
                        set(produce(draftState => {
                            draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                        }))
                    }).catch((response) => {
                        set(produce(draftState => {
                            draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                        }))
                    })
                } else {
                    set(produce(draftState => {
                        draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                    }))
                }
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.quoteBuyerDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.loadingQuoteDetails = isLoading })),
        setQuoteDetails: (quote: Quote, generatingPurchaseOrder: boolean, unsetComments: boolean = false) => {
            const userId = useUserData.getState().userInfo?.id
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.customLoadingButton = null
                draftState.quoteBuyerDetailsSliceProperties.isAllProductsSelected = false
                draftState.quoteBuyerDetailsSliceProperties.quote = buildBuyerQuoteDetails({ ...quote, company_details: quote.seller_details }, userId, generatingPurchaseOrder)
                if (!unsetComments) {
                    draftState.quoteBuyerDetailsSliceProperties.newComments = quote.new_comments?.length == 0 ? null : quote.new_comments
                }
            }))
            get().quoteBuyerDetailsSliceActions.setProductsTableRows(get().quoteBuyerDetailsSliceProperties.quote)
            get().setTimeline(quote.timeline);
        },
        setProductsTableRows: (quote: QuoteDetails | null) => {
            const ProductsArray = buildQuoteProductsTable(quote)
            set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.productsTableRows = ProductsArray }))
            get().quoteBuyerDetailsSliceActions.setSummaryDetails(ProductsArray)
            get().quoteBuyerDetailsSliceActions.setIsAllProductsSeleced(ProductsArray, get().quoteBuyerDetailsSliceProperties.numberOfSelectedProducts)
        },
        setRawProductsTableRows: (newProductsTableRows: QuoteProductTableRow) => {
            set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.productsTableRows = newProductsTableRows }))
        },
        initializeProductsTableRowsFromProducts: (purchaseOrderProducts: any, onUpdated?: () => void) => {
            const productsTableRows = get().quoteBuyerDetailsSliceProperties.productsTableRows
            const newProductsTableRows = productsTableRows.map(quoteProduct => {
                const targetProductInPo = purchaseOrderProducts.find(poProduct => poProduct.quote_product_id === quoteProduct.id)
                if (targetProductInPo) {
                    return {
                        ...quoteProduct,
                        productDetails: {
                            ...quoteProduct.productDetails,
                            selected: true
                        },
                        requestedQuantity: targetProductInPo.requested_quantity
                    }
                }
                return quoteProduct;
            });
            const numberOfSelectedProducts = newProductsTableRows.filter(el => el.productDetails.selected).length

            get().quoteBuyerDetailsSliceActions.setRawProductsTableRows(newProductsTableRows);
            get().quoteBuyerDetailsSliceActions.setSummaryDetails(newProductsTableRows);
            get().quoteBuyerDetailsSliceActions.setIsAllProductsSeleced(newProductsTableRows, numberOfSelectedProducts);

            onUpdated?.();
        },
        closeNewCommentsPopup: () => set(produce(draftState => { 
            const quoteModel = draftState.quoteBuyerDetailsSliceProperties.quote?.quoteModel;
            if (quoteModel) {
                quoteModel.new_comments = null;
            }
            draftState.quoteBuyerDetailsSliceProperties.newComments = null })),
        toggleSelectProductFromQuote: (product: QuoteProductTableRow) => {
            let productRows = get().quoteBuyerDetailsSliceProperties.productsTableRows
            let changedProductsRows = productRows.map((productRow: QuoteProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftproductRow => {
                    draftproductRow.requestedQuantity = productRow.productDetails.selected ? 0 : productRow.availableQuantity
                    draftproductRow.productDetails.selected = !productRow.productDetails.selected
                })
                else return productRow
            })
            let numberOfSelectedProducts = changedProductsRows.filter(productsTableRow => productsTableRow.productDetails.selected).length
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.productsTableRows = changedProductsRows
                draftState.quoteBuyerDetailsSliceProperties.numberOfSelectedProducts = numberOfSelectedProducts
            }))
            get().quoteBuyerDetailsSliceActions.setSummaryDetails(changedProductsRows)
            get().quoteBuyerDetailsSliceActions.setIsAllProductsSeleced(changedProductsRows, numberOfSelectedProducts)
        },
        toggleSelectAllProducts: (value: boolean) => {
            let productRows = get().quoteBuyerDetailsSliceProperties.productsTableRows
            let changedProductsRows = productRows.map((productRow: QuoteProductTableRow) => {
                return produce(productRow, draftproductRow => {
                    if (value === true && !productRow.productDetails.selected) {
                        draftproductRow.requestedQuantity = productRow.availableQuantity;
                    }
                    if (value === false && productRow.productDetails.selected) {
                        draftproductRow.requestedQuantity = 0;
                    }

                    // draftproductRow.requestedQuantity = productRow.productDetails.selected ? 0 : productRow.availableQuantity
                    draftproductRow.productDetails.selected = value
                })
            })
            let numberOfSelectedProducts = changedProductsRows.filter(productsTableRow => productsTableRow.productDetails.selected).length
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.productsTableRows = changedProductsRows
                draftState.quoteBuyerDetailsSliceProperties.numberOfSelectedProducts = numberOfSelectedProducts
            }))
            get().quoteBuyerDetailsSliceActions.setSummaryDetails(changedProductsRows)
            get().quoteBuyerDetailsSliceActions.setIsAllProductsSeleced(changedProductsRows, numberOfSelectedProducts)
        },
        changeRequestedQuantity: (product: QuoteProductTableRow, quantity: number) => {
            let productRows = get().quoteBuyerDetailsSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: QuoteProductTableRow) => {
                // if (productRow.id == product.id && productRow.availableQuantity >= quantity) return produce(productRow, draftproductRow => {
                if (productRow.id == product.id) return produce(productRow, draftproductRow => {
                    draftproductRow.requestedQuantity = quantity
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().quoteBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
        },
        generatePurchaseOrder: (onSuccess: (data: any) => void, handleErrorMessage: () => void) => {
            let state = get().quoteBuyerDetailsSliceProperties
            const selectedProducts = state.productsTableRows.filter(product => product.productDetails.selected)
            if (selectedProducts.length != 0) {
                get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.GENERATE_PO_BY_BUYER)
                useGeneratePurchaseOrder(state.quote?.quoteModel?.id, selectedProducts).then((data) => {
                    let url = new URL(window.location.href);
                    url.search = '';  
                    window.history.replaceState(null, '', url.toString());
                    onSuccess?.(data.data);
                    NotifySuccess(t("popups.titles.purchase_order_generated"))
                    get().quoteBuyerDetailsSliceActions.setQuoteDetails({ ...data.data.quote, redirectData: data.data.purchase_order }, false)
                }).catch(() => get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
            } else handleErrorMessage()
        },
        updatePurchaseOrderFromQuote: (poId: string, onSuccess: (data: any) => void, handleErrorMessage: () => void) => {
            const state = get().quoteBuyerDetailsSliceProperties
            const selectedProducts = state.productsTableRows.filter(product => product.productDetails.selected)
            if (selectedProducts.length === 0) {
                handleErrorMessage();
                return;
            }
            get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_PO_UPDATES)
            updatePurchaseOrderFromQuoteService(state.quote?.quoteModel?.id, selectedProducts, poId).then((data) => {
                onSuccess(data.data)
                get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null)
                NotifySuccess(t("popups.titles.purchase_order_updated"))
            }).catch(() => get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        setSummaryDetails: (products: QuoteProductTableRow[]) => {
            const summary = getVATTotalValues(products, 'requestedQuantity')
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.quoteBuyerDetailsSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.quoteBuyerDetailsSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        setIsAllProductsSeleced: (products: QuoteProductTableRow[], numberOfSelectedProducts: number) => {
            set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.isAllProductsSelected = products.length == numberOfSelectedProducts }))
        },
        getReasonsForPopup: () => {
            if (get().quoteBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.DECLINE)
                useFetchDeclineQuoteReasons().then(data => { set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.quoteBuyerDetailsSliceProperties.openedPopupStatus = status
            if (!status) {
                draftState.quoteBuyerDetailsSliceProperties.openedPopupStatus = null
            }
        })),
        decline: (reasonId: string, comment?: string) => {
            const rfqProps = get().quoteBuyerDetailsSliceProperties
            if (rfqProps.openedPopupStatus == ProcurementPopupStatus.DECLINE)
                get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_QUOTE_BY_BUYER)
            useDeclineQuote(rfqProps.quote?.quoteModel?.id, reasonId, comment).then((data) => {
                get().quoteBuyerDetailsSliceActions.setPopupStatus(null)
                get().quoteBuyerDetailsSliceActions.setQuoteDetails(data.data, false)
                NotifySuccessWithCancelIcon(t('notifications.form_declined', { formName: get().quoteBuyerDetailsSliceProperties.quote?.quoteModel?.name }))
            }).catch(() => get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.quoteBuyerDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.quoteBuyerDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        requestExtensionForQuoteExpiredByBuyer: (responseHandlers?: ResponseHandlers<Quote>) => {
            get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(get().quoteBuyerDetailsSliceProperties.quote?.quoteModel?.id, 'Quote').then((data) => {
                set(produce(draftState => { draftState.quoteBuyerDetailsSliceProperties.customLoadingButton = null }))
                // let quoteModel = get().quoteBuyerDetailsSliceProperties.quote?.quoteModel
                // let updatedQuote = { ...quoteModel, has_pending_extension_request: true, extension_request_status: procurementStatus.PENDING }
                // get().quoteBuyerDetailsSliceActions.setQuoteDetails(updatedQuote, false)
                get().quoteBuyerDetailsSliceActions.setQuoteDetails(data.data, false)
                NotifySuccess(t('notifications.extension_requested'))
                responseHandlers?.onSuccess?.(data.data);
            }).catch(() => get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const quoteId = get().quoteBuyerDetailsSliceProperties.quote?.quoteModel?.id
            get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(quoteId, LinkType.QUOTE_BUYER, validityDate).then((data) => {
                get().quoteBuyerDetailsSliceActions.setQuoteDetails(data.data, false)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().quoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().quoteBuyerDetailsSliceProperties.quote?.quoteModel, true, false)
            get().quoteBuyerDetailsSliceActions.setQuoteDetails(modelAfterUpdateProject)
        }
    }
})