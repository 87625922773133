import { Button, Grid, Typography } from "@mui/material";
import { ResponsiveContainer } from "@/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from 'next-i18next';
import { getRegisterTranslationKey } from '@/hooks'
import { sm } from 'src/site-map';

export const AlreadyConfirmed: React.FC<{}> = ({}) => {
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const { t } = useTranslation()

    return (
        <Grid container component={ResponsiveContainer}>
            <Grid item xs={12} sx={{ pt: [4,  16] }}>
                <Typography variant="h2" sx={{fontWeight: 'bolder'}}> {t(getRegisterTranslationKey('headers.already_confirmed'))} </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography variant="body2" color="text.third"> {t(getRegisterTranslationKey('paragraphs.already_confirmed'))} </Typography>
            </Grid>
            <Grid item xs={12} sx={{ py: 4 }}>
                <Button 
                    variant='contained'
                    href={sm.home.url}
                    sx={{
                        textAlign: 'bold',
                        width: isMobile? '100%' : 'initial',
                    }}
                > {t('buttons.go_to_site')} </Button>
            </Grid>
        </Grid>
    )
}