import { GetServerSidePropsContext } from 'next';

import { AuthenticateStatus, httpConfig } from '@/config';
import axios, { AxiosError } from 'axios';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useUserData } from 'src/logic/zustand/user';
import { NotifyError } from '@/services';

export type ErrorType = AxiosError;

export const client = (() => {
  return axios.create({
    baseURL: httpConfig.baseURL,
    withCredentials: true,
  });
})();

export const getServerSideClient = (context: GetServerSidePropsContext) => {
  const origin = context.req.headers.referer?.split('/')[2];
  return axios.create({
    baseURL: httpConfig.baseURL,
    withCredentials: true,
    headers: {
      Cookie: context.req.headers.cookie || '',
      // origin: context.req.headers.origin ?? 'localhost:3000',
      origin: origin,
    },
  });
};

export const request = async function (options) {
  const onSuccess = function (response) {
    const { data } = response;
    return data;
  };
  const onError = function (error) {
    if(options.disableErrorMessage === true) {
      return Promise.reject(error.response);
    }
    if(typeof options.disableErrorMessage === 'function') {
      if(options.disableErrorMessage(error)) return Promise.reject(error.response);
    }
    if(error.response?.status === undefined) {
      NotifyError('Please check your internet connection');
    }
    else if (
      error.response?.status === 401 &&
      useUserData.getState().isAuthenticated ===
        AuthenticateStatus.AUTHENTICATED
    ) {
      useUserData.getState().logUserOut();
      NotifyError(t('notifications.user_not_authorized'));
    } else if (error.response?.status === 401) {
      useUserData.getState().logUserOut();
    } else {
      //TODO: Handle this case
      if(!options.url?.includes("/count")) {
        // Added url for testing purposes 
        NotifyError(`${t('notifications.server_error')} with URL: ${options.url}, Status Code: ${error?.response?.status}`);
      }
    }
    return Promise.reject(error.response);
  };
  return client(options).then(onSuccess).catch(onError);
};
 