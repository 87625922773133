import { DescPopup } from '@/components';
import { Product, ProductCategoryKind, ProductCategoryKindLabel } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
// import HistoryIcon from '@mui/icons-material/History';

type RequisitionProductCardProps = {
  productDetails: Product;
  ControlButtons?: any;
  withDetailsPopup?: boolean;
  canEditRequisition?: boolean;
};

const categoryKindChipProps = {
  sx: {
    backgroundColor: 'rgba(255, 255, 255, .85)',
    color: 'text.disabled',
    borderRadius: '16px',
    border: '0.5px solid #3A3E50',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 1,
    padding: '4px 12px',
  },
  fontSize: 10,
  fontWeight: 500,
};

const SpecItem = ({ label, value }: { label: string | React.ReactNode; value: string | React.ReactNode }) => {
  return (
    <Box>
      <Typography fontSize={11} fontWeight={400} color='#C5C6CB' lineHeight={1}>
        {label}
      </Typography>
      <Typography fontSize={15} fontWeight={400} color='#3A3E50' lineHeight={1.25}>
        {value}
      </Typography>
    </Box>
  );
};

const sliceDescription = (description: string) => {
  if (!description) return '';
  const MAX_LENGTH = 100;
  let sliced = description?.slice(0, MAX_LENGTH);
  if (description.length > MAX_LENGTH) {
    return `${sliced}...`;
  }
  return sliced;
};

export const RequisitionProductCard: React.VFC<RequisitionProductCardProps> = ({
  productDetails,
  ControlButtons,
  withDetailsPopup = false,
  canEditRequisition = false,
}) => {
  const detailsPopup = usePopup();
  const changeProductRequirements = useProcurementStore(state => state.changeProductRequirementsWithPersistence);
  const onChangeRequirements = (newRequirements: string) => changeProductRequirements(newRequirements, productDetails);
  const [showingRequirements, setShowingRequirements] = React.useState<boolean>(true);
  const toggleVisibilityText = () => setShowingRequirements(prev => !prev);

  return (
    <>
      {withDetailsPopup && (
        <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
          <ProductQuickView product={productDetails} />
        </DescPopup>
      )}
      <Grid
        container
        py={2}
        px={3}
        sx={{
          backgroundColor: 'white',
          borderRadius: '8px',
          borderLeft: productDetails.inCart
            ? productDetails.inCartType == 'alternative'
              ? '8px solid #FB7F0D'
              : '8px solid #13628C'
            : null,
        }}
      >
        <Grid item xs={1.5} display={'flex'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
          <img
            style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100px' }}
            src={productDetails.image_url}
            alt={'product name'}
          />
          <Typography {...categoryKindChipProps}>{ProductCategoryKindLabel[productDetails.category_kind]}</Typography>
        </Grid>

        <Grid item xs={8.5} px={2}>
          <Typography
            fontSize={20}
            fontWeight='700'
            color='text.primary'
            mb={1}
            lineHeight={1.3}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            className={withDetailsPopup ? 'underline-on-hover cursor-pointer' : ''}
            onClick={() => {
              if (withDetailsPopup) detailsPopup.handleOpen();
            }}
          >
            {productDetails.name}{' '}
            {productDetails.is_private_product && (
              <PrivacyButton
                disableText
                content='This seller profile is private because it was created and/or linked by your company.'
              />
            )}
          </Typography>

          {productDetails.category_kind === ProductCategoryKind.PRODUCT ? (
            <Grid container md={12} alignItems={'center'}>
              {productDetails.brand && (
                <Grid item md={3}>
                  <Box height='50px' width='50px'>
                    <img
                      height='100%'
                      width='100%'
                      style={{ objectFit: 'contain' }}
                      src={productDetails.brand.image_url}
                      alt={productDetails.brand.name}
                    />
                  </Box>
                </Grid>
              )}
              {productDetails?.productAttributes?.slice(0, 7)?.map(item => (
                <Grid key={item.name} item md={3} pt={1}>
                  <SpecItem label={item.name} value={item.value || 'N/A'} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {productDetails.description ? (
                <Grid container md={12}>
                  <Grid item md={11} justifyContent={'space-between'}>
                    <Typography fontSize={15} fontWeight={400} color='#3A3E50' lineHeight={1.25}>
                      {showingRequirements ? sliceDescription(productDetails.description) : productDetails.description}
                    </Typography>
                  </Grid>
                  <Grid item md={1} sx={{ textAlign: 'end' }}>
                    <Typography
                      variant='subtitle2'
                      fontWeight={500}
                      sx={{ cursor: 'pointer', color: '#13628C', pr: 1 }}
                      onClick={toggleVisibilityText}
                    >
                      {showingRequirements ? 'Show' : 'Hide'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <SpecItem label='Description' value='N/A' />
              )}
              <Grid item md={12} mt={1}>
                {canEditRequisition ? (
                  <>
                    <Typography variant='subtitle2' component='label' fontWeight={400} sx={{ color: 'text.third' }}>
                      Buyer Requirements
                    </Typography>
                    <TextField
                      rows={showingRequirements ? 3 : 1}
                      multiline
                      variant='outlined'
                      fullWidth
                      placeholder={'Buyer Requirements'}
                      sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                      style={{ overflow: 'auto' }}
                      InputProps={{
                        sx: {
                          minHeight: showingRequirements ? 80 : undefined,
                          padding: '10px',
                          fontSize: '14px',
                          color: '#3A3E50',
                          fontWeight: 400,
                          '& ::placeholder': {
                            fontSize: '12px',
                          },
                          border: !productDetails.additional_requirements ? '1px solid red' : 'none',
                        },
                        endAdornment: (
                          <Typography
                            variant='subtitle2'
                            fontWeight={500}
                            sx={{ cursor: 'pointer', color: '#13628C' }}
                            onClick={toggleVisibilityText}
                          >
                            {showingRequirements ? 'Hide' : 'Show'}
                          </Typography>
                        ),
                      }}
                      value={productDetails.additional_requirements}
                      onChange={e => onChangeRequirements(e.target.value)}
                    />
                  </>
                ) : (
                  <SpecItem label={'Buyer Requirements'} value={productDetails.additional_requirements || 'N/A'} />
                )}
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          item
          container
          xs={2}
          alignItems='center'
          justifyContent={'center'}
          onClick={event => event.stopPropagation()}
        >
          {ControlButtons && <ControlButtons key={productDetails.id} product={productDetails} />}
        </Grid>
      </Grid>
    </>
  );
};
