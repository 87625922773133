import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { SideDrawerItem } from './drawer-item';
import { Drawer_Footer_CONFIG } from '../drawer-config';
import { UserType } from '@/config';
import { useDrawerStore } from '@/zustand';
import { Grid, IconButton, ListItem, Typography } from '@mui/material';
import Image from 'next/image';
import { FB, INS, Linkedin } from 'public/assets/icons';
import { ListStack } from './drawer-styled-components';
import { getFooterTranslationKey } from '@/hooks';
import { FacebookIcon, FacebookSmallIcon, InstagramIcon } from '@/icons';

interface DrawerFooterProps {
  userType: UserType
}
export const DrawerFooter: React.FC<DrawerFooterProps> = (props) => {
  const { isDrawerOpen, isDrawerSubMenuOpen, openDrawer, closeDrawer } = useDrawerStore()
  const footerConfigs = Drawer_Footer_CONFIG[props.userType]
  return (
    <ListStack open={isDrawerOpen} isDrawerSubMenuOpen={isDrawerSubMenuOpen}>
      <SideDrawerItem key={footerConfigs.title} drawerItemDetails={footerConfigs} isDrawerOpen={isDrawerOpen} isFooter />
    </ListStack>
  )
}

export const DrawerFooterWebsiteInfo: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Grid container width='80%' mb={1}>
      <Grid item md={12} container gap={1}>
        <IconButton color='primary' sx={{ ml: 1 }} onClick={() => window.open('https://www.facebook.com/eprocurement.ae/')}><FacebookSmallIcon /></IconButton>
        <IconButton color='primary' sx={{ ml: 1 }} onClick={() => window.open('https://instagram.com/eprocurementofficial?utm_medium=copy_link')}><InstagramIcon /></IconButton>
        <Image
          onClick={() => window.open('https://www.linkedin.com/company/eprocurement-ae')}
          src={Linkedin}
          objectFit='contain'
          alt='Fancy'
          style={{ cursor: 'pointer' }}
        />
      </Grid>
      <Grid item md={12} my={2}>
        <Typography variant='subtitle2' color='text.disabled'>
          {new Date().getFullYear()}  {t(getFooterTranslationKey('titles.rights_reserved'))}
        </Typography>
      </Grid>
    </Grid>
  )
}