import React from 'react';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
import { LinkType } from '@/models';
import { getPathWithoutQueryParams } from 'src/logic/utils/url';

type FormTypeNavigatorMapperType = {
  [k in LinkType]: (id: string) => { url: string };
};
export const FormTypeNavigatorMapper: FormTypeNavigatorMapperType = {
  [LinkType.BUNDLE_RFQ]: sm.portals.buyer.procurement.bundleRFQs.bundleRFQ,
  [LinkType.RFQ_SINGLE]:
    sm.portals.buyer.procurement.requestForQuotes.RequestForQuote,
  [LinkType.RFQ_OF_BUNDLE]: sm.portals.buyer.procurement.bundleRFQs.bundleRFQ,
  [LinkType.RFQ_SELLER]:
    sm.portals.seller.procurement.requestForQuotes.requestForQuote,
  [LinkType.QUOTE_SELLER]: sm.portals.seller.procurement.quotes.quoteId,
  [LinkType.QUOTE_BUYER]: sm.portals.buyer.procurement.quotes.quoteId,
  [LinkType.REQUISITION]: sm.portals.buyer.procurement.requisitions.requisition,
  [LinkType.PROJECT_SELLER]: sm.portals.seller.projects.projectId,
  [LinkType.PROJECT_BUYER]: sm.portals.buyer.projects.projectId,
  [LinkType.BUYER_CLIENT_COMPANY]: sm.portals.seller.buyers.buyerId,
  [LinkType.SELLER_CLIENT_COMPANY]: sm.portals.buyer.sellers.sellerId,
  [LinkType.CATALOG_DRAFT]: sm.portals.seller.products.sellerProductList,
  [LinkType.COMPARISON_TABLE]:
    sm.portals.buyer.procurement.comparisonTables.ctId,
  [LinkType.PO_SINGLE]:
    sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId,
  [LinkType.BUNDLE_PO]:
    sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId,
  [LinkType.PO_OF_BUNDLE]:
    sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId,
  [LinkType.PO_SELLER]:
    sm.portals.seller.procurement.purchaseOrders.purchaseOrderId,
};

export const getUrlBasedOnFormType = (formType: LinkType, id: string): string =>
  FormTypeNavigatorMapper[formType](id).url;

export const useFormTypeNavigator = (
  formType: LinkType,
  id: string | number
) => {
  const router = useRouter();
  return React.useCallback(() => {
    const fromLink = getPathWithoutQueryParams(router.asPath);
    const toLink = getUrlBasedOnFormType(formType, id.toString());

    if (fromLink === toLink) {
      router.reload();
    } else {
      router.push(toLink);
    }
  }, [formType, id]);
};
