import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { useProfile, useProfileEmailFlow } from '@/zustand'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next';
import { NotifyError, NotifySuccess } from '../Notify-toast'


/* change email */

export const requestVerificationOldEmail = async () => {
  return request({
    url: apiRoutes.requestVerificationOldEmail,
    method: "GET"
  })
}
export const useRequestVerificationOldEmail = (enabled) => {
  const { t } = useTranslation();
  const changeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.changeEmailByEmailPopupSteps);
  const setChangeEmailByEmailSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps);
  const setIsTimer = useProfileEmailFlow(state => state.setIsTimer);
  const setTimer = useProfileEmailFlow(state => state.setTimer);
  const onSuccess = (data) => {
    setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps+1),NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to }))
  }
  const showError = (data) => {
    setIsTimer(true);setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['requestVerificationOldEmail',enabled], () => requestVerificationOldEmail(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

const verificationOldEmail = async (data) => {
  return request({
    url: apiRoutes.verificationOldEmail,
    method: "POST",
    data: data
  })
}
export const useVerificationOldEmail = () => {
  const { t } = useTranslation();
  const changeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.changeEmailByPhonePopupSteps);
  const setChangeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
  const changeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.changeEmailByEmailPopupSteps);
  const setChangeEmailViaEmailToken = useProfileEmailFlow(state => state.setChangeEmailViaEmailToken);
  const setChangeEmailByEmailSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps);

  const onSuccess = (data) => { 
    setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps+1),setChangeEmailByPhonePopupSteps(changeEmailByPhonePopupSteps+1), setChangeEmailViaEmailToken(data.data.token)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((values) => verificationOldEmail(values), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

const requestChangeEmail = async (data) => {
  return request({
    url: apiRoutes.requestChangeEmail,
    method: "POST",
    data: data
  })
}
export const useRequestChangeEmail = () => {
  const { t } = useTranslation();
  const changeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.changeEmailByEmailPopupSteps);
  const changeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.changeEmailByPhonePopupSteps);
  const setChangeEmailByEmailSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps);
  const setChangeEmailByPhoneSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
  const setChangeEmailViaEmailToken = useProfileEmailFlow(state => state.setChangeEmailViaEmailToken);
  const setTemporaryEmail = useProfileEmailFlow(state => state.setTemporaryEmail);
  const changeEmailViaEmailToken = useProfileEmailFlow(state => state.changeEmailViaEmailToken);

  const onSuccess = (data) => {
    setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps+1),setChangeEmailByPhoneSteps(changeEmailByPhonePopupSteps+1), setChangeEmailViaEmailToken(data.data.token),NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to })),setTemporaryEmail(data.data.sent_to)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => requestChangeEmail(({ ...data, token: changeEmailViaEmailToken })), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

const verificationNewEmail = async (data) => {
  return request({
    url: apiRoutes.verificationNewEmail,
    method: "POST",
    data: data
  })
}
export const useVerificationNewEmail = () => {
  const { t } = useTranslation();
  const changeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.changeEmailByEmailPopupSteps);
  const changeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.changeEmailByPhonePopupSteps);
  const setChangeEmailByPhoneSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
  const setChangeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps);
  const setUserInfo = useProfile(state => state.setUserInfo);
  const changeEmailViaEmailToken = useProfileEmailFlow(state => state.changeEmailViaEmailToken);

  const onSuccess = (data) => { 
    setChangeEmailByEmailPopupSteps(changeEmailByEmailPopupSteps+1),setChangeEmailByPhoneSteps(changeEmailByPhonePopupSteps+1),setUserInfo(data.data)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => verificationNewEmail(({...data, token:changeEmailViaEmailToken})), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

export const verificationOldEmailAgain = async () => {
  return request({
    url: apiRoutes.reRequestVerificationOldEmailViaEmail,
    method: "GET"
  })
}
export const useVerificationOldEmailAgain= (enabled) => {
  const { t } = useTranslation();

  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['reRequestVerificationOldEmail',enabled], () => verificationOldEmailAgain(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

export const verificationNewEmailAgain = async (data) => {
  return request({
    url: apiRoutes.reRequestVerificationNewEmail,
    method: "POST",
    data: data
  })
}
export const useVerificationNewEmailAgain = (temporaryEmail) => {
  const { t } = useTranslation();
  const changeEmailViaEmailToken = useProfileEmailFlow(state => state.changeEmailViaEmailToken);
  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation(() => verificationNewEmailAgain({email:temporaryEmail,token:changeEmailViaEmailToken}), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}


export const requestVerifyOldEmailVaiPhone = async () => {
  return request({
    url: apiRoutes.requestVerificationOldEmailViaPhone,
    method: "GET"
  })
}
export const useRequestVerificationOldEmailVaiPhone = (enabled) => {
  const { t } = useTranslation();
  const changeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.changeEmailByPhonePopupSteps);
  const setChangeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
  const setIsTimer = useProfileEmailFlow(state => state.setIsTimer);
  const setTimer = useProfileEmailFlow(state => state.setTimer);
  
  const onSuccess = (data) => {
    setChangeEmailByPhonePopupSteps(changeEmailByPhonePopupSteps+1),NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to }))
  }
  const showError = (data) => {
    setIsTimer(true);setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['requestVerificationOldEmailVaiPhone',enabled], () => requestVerifyOldEmailVaiPhone(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

export const verificationEmailByPhoneAgain = async () => {
  return request({
    url: apiRoutes.reRequestVerificationOldEmailViaPhone,
    method: "GET"
  })
}
export const useVerificationEmailByPhoneAgain = (enabled) => {
  const { t } = useTranslation();
  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code_phone"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['reRequestVerificationOldEmailVaiPhone',enabled], () => verificationEmailByPhoneAgain(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}