import { StateCreator } from "zustand";
import produce from "immer";
import { Project } from "@/models";
import { useGetAllProjects } from "@/services";
import { apiRoutes } from "@/config";

export interface ProjectSlice {
    projects: Project[] | null,
    setProjects: (projects: Project[]) => void
    addProject: (project: Project) => void
    fetchProjects: (onSuccess?: () => void) => void
}

export const createProjectSlice: StateCreator<ProjectSlice> = (set, get, api) => ({
    projects: null,
    setProjects: (projects: Project[]) => {
        set(produce(draftState => {
            draftState.projects = projects
        }))
    },
    addProject: (project: Project) => {
        if(!get().projects?.find(proj => proj.id === project.id)) {
            set(produce(draftState => {
                draftState.projects = draftState.projects ? produce(draftState.projects, draftProject => {
                    draftProject.push(project)
                }) : [project]
            }))
        }
    },
    fetchProjects: (onSuccess?: () => void) => {
        useGetAllProjects(apiRoutes.indexProjectsBuyer).then(data => {
            set(produce(draftState => { draftState.allProjects = data.data.data.data }))
            onSuccess?.();
        })
    }
})