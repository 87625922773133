import {
  HeroImageSection,
  HeroTextSection,
  Link,
} from '@/components';
import { useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Box, Grid, useMediaQuery, Typography, Button } from '@mui/material';
import { ArrowForward, NavigateNext } from '@mui/icons-material';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import { getBecomeSellerTranslationKey } from '@/hooks';

const smallBreakPoints = {
  lg: 7,
  xs: 12,
} as const;
const smallBreakPointsImg = {
  lg: 5,
  xs: 12,
} as const;
type Props = {};

export const HeroBecomeASellerSection: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });

  return (
    <Grid container>
      <HeroTextSection
        isSmallScreen={smallScreens}
        ShowBreadcrumbs={true}
        breadCrumbsAlias={sm.becomeASeller.url}
        title={t(getBecomeSellerTranslationKey('titles.intro'))}
        subTitle={t(getBecomeSellerTranslationKey('sub_titles.intro'))}
        secondSubTitle={t('texts.register_now')}
        withBlueBackgroung={false}
      >
        <Button
          component={Link}
          href={{ pathname: sm.register.asSeller.index.url }}
          {...third}
          variant='contained'
          endIcon={<ArrowForward />}
          sx={{
            width: smallScreens ? '100%' : '176px',
            color: 'secondary.contrastText',
          }}
        >
          {t('buttons.become_a_seller')}
        </Button>
      </HeroTextSection>
      <HeroImageSection imageSrc='/assets/images/become-a-seller/hand-shake.png' />
    </Grid>
  );
};
