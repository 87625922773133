import { request } from '@/helpers';

export const getExternalQuoteDetails = (quoteId: string) => {
  return request({ url: `/external-quote/show/${quoteId}`, method: 'GET' });
};

export const getExternalBuyerQuoteDetails = (quoteId: string) => {
  return request({ url: `/buyer/external-quote/show/${quoteId}`, method: 'GET' });
};

export const useSendExternalQuoteToBuyer = (quoteId: number, date: string, optionalComment: string | null) => {
  return request({
    url: `/external-quote/${quoteId}/send-to-buyer`,
    method: 'POST',
    data: { validity_date: date, comment: optionalComment ?? null },
  });
};
