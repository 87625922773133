import { TextField } from "@mui/material"
import { FC } from "react"

interface MultilineTextFieldProps {
    value: string | null,
    placeholder: string,
    handleValueChange: (value: string) => void
}


export const MultilineTextField: FC<MultilineTextFieldProps> = ({ value, placeholder, handleValueChange }) => {
    return (
        <TextField
            onChange={val => handleValueChange(val.target.value)}
            value={value ?? ''}
            minRows={5}
            maxRows={5}
            multiline
            variant="outlined"
            fullWidth
            helperText={`${value?.length ?? 0}/500`}
            placeholder={placeholder}
            inputProps={{ maxLength: 500, }}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
            style={{ overflow: 'auto', height: 150 }}
            InputProps={{
                sx: {
                    minHeight: 120, fontSize: '14px', color: '#8B8D98', fontWeight: 400,
                    '& ::placeholder': {
                        fontSize: '14px'
                    },
                }
            }}
        />
    )
}