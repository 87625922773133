import { BundleProcurementDetails } from '@/models';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FC } from "react"
import { CommentsNumber } from 'src/ui/components/dumb';
import { TextViewWithValue } from 'src/ui/components/shared';

interface BundlePurchaseOrderMainDetailsType {
    bundleDetails: BundleProcurementDetails | null
}
export const BundlePurchaseOrderMainDetails: FC<BundlePurchaseOrderMainDetailsType> = ({ bundleDetails }) => {
    const { t } = useTranslation();
    const bundlePurchaseOrderModel = bundleDetails?.model
    return (
        <>
            <Grid item md={12}>
                <Typography variant="h2" fontWeight={600}>{bundlePurchaseOrderModel?.name}</Typography>
            </Grid>
            <Grid item md={12} container>
                <Grid item md={7} container gap={2}>
                    <TextViewWithValue title={t('texts.created_on')} value={bundlePurchaseOrderModel?.created_at} />
                    <TextViewWithValue title={t('texts.project')} value={bundlePurchaseOrderModel?.project.name} />
                    <TextViewWithValue title={t('texts.req')} value={bundlePurchaseOrderModel?.requisition.name} />
                    <TextViewWithValue title={t('table_cells.owner')} value={bundlePurchaseOrderModel?.owner.full_name} />
                    <TextViewWithValue title={t('table_cells.products')} value={bundleDetails?.numberOfProducts} />
                </Grid>
                <Grid item container md={5} justifyContent='flex-end'>
                    <CommentsNumber title={t('texts.quote')} commentType={bundlePurchaseOrderModel?.commentable_type ?? ''} commentId={bundlePurchaseOrderModel?.id ?? ''} formDetails={bundlePurchaseOrderModel} />
                </Grid>
            </Grid>
        </>
    )
}