import { ArrowBack, Close } from '@mui/icons-material';
import { Dialog, Grid, IconButton, SxProps } from '@mui/material';
import React from 'react';

export type DescPopupProps = {
  children?: React.ReactNode;
  handleClose: () => void;
  open: boolean;
  medium?: boolean;
  big?: boolean;
  hasBackButton?: boolean;
  backButtonAction?: () => void;
  paperSx?: SxProps;
};

export const DescPopup: React.VFC<DescPopupProps> = ({
  children,
  handleClose,
  open,
  medium,
  big,
  hasBackButton,
  backButtonAction,
  paperSx = {},
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      PaperProps={{
        sx: { maxWidth: medium ? '607px' : big ? '878px' : '370px', pb: 1, ...paperSx },
      }}
      disableScrollLock
    >
      <Grid container>
        <Grid item md={6} container justifyContent={'flex-start'} p={2} pt={1}>
          {hasBackButton && (
            <IconButton edge='start' color='inherit' onClick={backButtonAction}>
              <ArrowBack sx={{ color: 'text.secondary', height: 18, width: 18 }} />
            </IconButton>
          )}
        </Grid>
        <Grid item md={6} container justifyContent={'flex-end'} p={1}>
          <IconButton edge='start' color='inherit' onClick={handleClose}>
            <Close sx={{ color: 'text.secondary', height: 18, width: 18 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item md={12} container justifyContent={'center'} sx={{ mt: -5, p: 1 }} width='100%'>
        {children}
      </Grid>
    </Dialog>
  );
};
