import React from 'react';
import { EditableGeneralTermsContentProps, EditableGeneralTermsProps } from '../types';
import { Grid, Typography } from '@mui/material';
import { containerStyles } from '../styles';
import * as yup from 'yup';
import { Form, Formik, useFormikContext } from 'formik';
import SelectTermsItem from './SelectTermsItem';
import { GeneralTermsFormType } from './types';

const validationSchema = yup.object({
  payment_term_id: yup.string().required('required').nullable(),
  warranty_term_id: yup.string().required('required').nullable(),
  delivery_term_id: yup.string().required('required').nullable(),
});

const EditableGeneralTerms = (props: EditableGeneralTermsProps) => {
  const initialValues = {
    payment_term_id: props.payment_term?.id ?? null,
    warranty_term_id: props.warranty_term?.id ?? null,
    delivery_term_id: props.delivery_term?.id ?? null,
  };

  const handleSubmit = () => {};

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form style={{ width: '100%' }}>
        <EditableGeneralTermsContent {...props} />
      </Form>
    </Formik>
  );
};
export default EditableGeneralTerms;

export function EditableGeneralTermsContent(props: EditableGeneralTermsContentProps) {
  const { forceValidateTerms } = props;
  const { isValid, validateForm } = useFormikContext<GeneralTermsFormType>();

  React.useEffect(() => {
    if (forceValidateTerms) {
      validateForm();
    }
  }, [forceValidateTerms]);
  const shouldShowErrorIndicator = !isValid && forceValidateTerms;

  return (
    <Grid
      container
      style={shouldShowErrorIndicator ? { ...containerStyles, border: '1px solid red' } : containerStyles}
    >
      <Grid item md={2.25} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontWeight={700} fontSize={14}>
          General Terms
        </Typography>
      </Grid>
      <SelectTermsItem
        label='Payment'
        name='payment_term_id'
        options={props.terms.payment_terms ?? []}
        handleSelectGeneralTerms={props.handleSelectGeneralTerms}
        shouldShowErrorIndicator={shouldShowErrorIndicator}
      />
      <SelectTermsItem
        label='Warranty'
        name='warranty_term_id'
        style={{ borderInline: '1px solid #EAEEF6' }}
        options={props.terms.warranty_terms ?? []}
        handleSelectGeneralTerms={props.handleSelectGeneralTerms}
        shouldShowErrorIndicator={shouldShowErrorIndicator}
      />
      <SelectTermsItem
        name='delivery_term_id'
        label='Delivery'
        options={props.terms.delivery_terms ?? []}
        handleSelectGeneralTerms={props.handleSelectGeneralTerms}
        shouldShowErrorIndicator={shouldShowErrorIndicator}
      />
    </Grid>
  );
}
