
import { useTranslation } from 'next-i18next';
import AddIcon from '@mui/icons-material/Add';
import { createColumnHelper, ColumnMeta, RowData } from '@tanstack/react-table'
import { getUsersTranslationKey } from '@/hooks';
import { AllowedTo, NotAllowedTo } from 'react-abac';
import { Permissions, permissionsTableData, Roles, rolesTableData, statusesTableData, typesTableData } from '@/config';
import { useUsersStore, useUserData } from '@/zustand';
import { useUsersInfo } from '@/services';
import { arrayFilter, sortArray, ChipCells, InviteUserPopupContent, Link, Loading, PopupWithTitle, TableWithPagination, UserTableMenu, TableChipCell, TextTooltip } from '@/components';
import { Button, Grid, Typography } from '@mui/material';
import { sm } from 'src/site-map';
import React, { useRef } from 'react';
import {makeStyles} from "@mui/styles";

type Props = {};
declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
      filterType: string
    }
  }

const useStyles = makeStyles((theme) => {
    return ({
        oddRowBody: {
            backgroundColor: theme.palette.background.primary.main
        },
        evenRowBody: {
            backgroundColor: 'inherit'
        },
    })
})

export const UsersMainComponent: React.FC<Props> = () => {
    const { t } = useTranslation();
    const { isFetching } = useUsersInfo()
    const users_info = useUsersStore(state => state.users_info);
    const invitePopupVisible = useUsersStore(state => state.invitePopupVisible);
    const toggleInvitePopup = useUsersStore(state => state.toggleInvitePopup);
    const classes = useStyles()

    const columnHelper = createColumnHelper<Person>()

    const TableText = ({ title }) => {
        const textRef = useRef();
        return (
          <TextTooltip title={title} textRef={textRef}>
            <Typography
              ref={textRef}
              variant='subtitle2'
              sx={{ fontWeight: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {title}
            </Typography>
          </TextTooltip>
        )
      }
      
    
    const columns = React.useMemo<ColumnDef<Person>[]>(
        () => [
            {
                accessorKey: 'full_name',
                id: 'full_name',
                header: 'full_name',
                cell: info => <TableLink info={info} />,
                meta: {
                    filterType: 'search'
                }
            },
            {
                accessorKey: 'email',
                id: 'email',
                header: 'email',
                cell: info => <TableText title={info.getValue()}/>,
                meta: {
                    filterType: 'search'
                }
            },
            {
                accessorKey: 'mobile_number',
                id: 'mobile_number',
                header: 'mobile',
                cell: info => <TableText title={info.getValue().number}/>,
                meta: {
                    filterType: 'search'
                }
            },
            {
                accessorKey: 'roles',
                id: 'roles',
                header: 'roles',
                cell: info => <ChipCells cell={info} data={rolesTableData} />,
                filterFn: arrayFilter,
                sortingFn: sortArray,
                sortDescFirst: false,
                meta: {
                    filterType: 'select'
                }
            },
            {
                accessorKey: 'permissions',
                id: 'permissions',
                header: 'permissions',
                cell: info => <ChipCells cell={info} data={permissionsTableData} />,
                filterFn: arrayFilter,
                sortingFn: sortArray,
                sortDescFirst: false,
                meta: {
                    filterType: 'select'
                }
                
            },
            {
                accessorKey: 'user_role',
                id: 'user_role',
                header: 'type',
                cell: info => <TableChipCell cell={info} data={typesTableData} el={info.getValue()}/>,
                sortDescFirst: false,
                meta: {
                    filterType: 'select'
                }
            },
        ],
        []
    )
    const adminColumns = React.useMemo<ColumnDef<Person>[]>(
        () => [
            {
                accessorKey: 'account_status',
                id: 'account_status',
                header: 'status',
                cell: info => <TableChipCell cell={info} data={statusesTableData} el={info.getValue()}/>,
                sortDescFirst: false,
                meta: {
                    filterType: 'select'
                }
            },
        ],
        []
    )
    return (
        <>
            <Grid container item md={12} pt={4}>
                <Grid item md={6}>
                    <Typography variant='h1' fontWeight={700}>{t(getUsersTranslationKey('title'))}</Typography>
                </Grid>
                <AllowedTo perform={Permissions.EDIT_USERS}>
                    <Grid item container md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' onClick={() => toggleInvitePopup(true)}>
                            <AddIcon sx={{ fontSize: '20px', mr: 1 }} />
                            {t('buttons.invite_users')}
                        </Button>
                        <PopupWithTitle title={t(getUsersTranslationKey('popup_titles.invite_users'))} handleClose={() => toggleInvitePopup(false)} open={invitePopupVisible}>
                            <InviteUserPopupContent />
                        </PopupWithTitle>
                    </Grid>
                </AllowedTo>
            </Grid>
            {!isFetching ? <Grid md={12} pt={1} pb={4}>
                <AllowedTo perform={Permissions.EDIT_USERS}>
                    <TableWithPagination
                        columns={[...columns, ...adminColumns]}
                        data={users_info}
                        Menu={UserTableMenu}
                        tableMeta={{
                            getRowStyle: (index: number) => {
                                if(index % 2 == 0)
                                    return classes.evenRowBody
                                return classes.oddRowBody
                            },
                        }}
                    />
                </AllowedTo>
                <NotAllowedTo perform={Permissions.EDIT_USERS}>
                    <TableWithPagination
                        columns={columns}
                        data={users_info}
                        tableMeta={{
                            getRowStyle: (index: number) => {
                                if(index % 2 == 0)
                                    return classes.evenRowBody
                                return classes.oddRowBody
                            },
                        }}
                    />
                </NotAllowedTo>
            </Grid>
                : <Loading fullHeight={true} />}
        </>
    );
};

const TableLink = (props) => {
    const setUserInfo = useUsersStore(state => state.setUserInfo);
    const currentUserId = useUserData(state => state.userInfo.id);

    const textStyle = { width: 120, fontSize: '12px !important', fontWeight: 400, overflow: 'hidden', textOverflow: 'ellipsis', color: 'text.primary' }
    return (
        <>
            {(props.info.row.original.account_status && props.info.row.original.account_status != 'INVITED') || !props.info.row.original.account_status ? <Link
                underline='hover'
                sx={textStyle}
                href={props.info.row.original.id != currentUserId ?
                    { pathname: './users/company-user-profile', query: { userId: props.info.row.original.id } }
                    : sm.account.index.url}
                onClick={() => setUserInfo(null)}>
                {props.info.getValue()}
            </Link>
                : <Typography sx={textStyle}>{props.info.getValue()}</Typography>
            }
        </>
    )
}