import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { getInputsTranslationKey } from '@/hooks';

interface props {
  title: string;
  value: string;
  textVariant: any;
}
export const TextDataView: React.FC<props> = props => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Grid container my={0.5}>
      <Grid item xs={6}>
        <Typography fontWeight={400} variant={props.textVariant} color={'text.third'} width='80%'>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          variant={props.textVariant}
          color={'text.disabled'}
          sx={{
            fontWeight: 500,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...isSmallScreen ? {
              whiteSpace: 'initial',
              display: '-webkit-box',
              float: 'right',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            } : { pr: 1 }
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
