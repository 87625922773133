import type { FC } from 'react';
import { Box, Pagination, Switch } from "@mui/material";
import { useField } from 'formik'

interface StyledPaginationProps {
  count?: number,
  page?: number,
  onChange?: Function
}

export const StyledPagination: FC<StyledPaginationProps> = (props: StyledPaginationProps) => {
  return (
    <Pagination
      count={props.count}
      page={props.page}
      onChange={props.onChange}
      shape='rounded'
      boundaryCount={2}
      sx={{
        '& > .MuiPagination-ul': {
          bgcolor: 'primary.light',
          p: 0.5,
          borderRadius: 1,
          '& > li > button': {
            fontWeight: 400,
            color: 'text.third',
            borderRadius: '4px',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'white',
            },
            '&.Mui-selected': {
              bgcolor: 'primary.main',
              color: 'white',
            },
          },
        },
      }}
    />
  );
};
