import React from 'react';
import { useTranslation } from 'next-i18next';
import { Checkbox, Typography } from '@mui/material';
import { FormControlLabel } from '@mui/material';

export type CheckBoxActionProps = {
    onChange: (value: boolean) => void
    value: boolean
};

export const CheckBoxAction: React.FC<CheckBoxActionProps> = ({ value, onChange }) => {
    const { t } = useTranslation();

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    color="primary"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
            }
            label={<Typography variant='subtitle2' >{t('texts.requires_action')}</Typography>}
        />
    )
}
