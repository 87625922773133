import { StyledTextField } from "@/components";
import { Product } from "@/models";
import { useProcurementStore } from "@/zustand";
import { Button, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import React from "react";
import validationSchema from './validationSchema';

export const SimilarProductPopup: React.VFC<{ product: Product, sellerId: string, closePopup: () => void }> = (props) => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const handleAddProduct = (values) => {
        actions.addProduct({ ...data.candidateProductToAdd, price: values.price })
        props.closePopup()
    }
    const seller = props.product.sellers?.find(seller => seller.seller.id == props.sellerId)

    const initialValues = { price: seller?.price }
    return (
        <Stack sx={{ alignItems: 'center', textAlign: 'center', m: 1 }}>
            <Stack>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddProduct}
                    isolatedFields={true} validateOnBlur={true} validateOnChange={true}
                >
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <Stack style={{ gap: 10 }}>
                                <Typography variant="body2" color="text.disabled">{t('texts.confirm_details')}</Typography>
                                <StyledTextField name="price" translateKey="unit_price" type='number' />
                                <Button variant="contained" type='submit'>
                                    {t("buttons.confirm")}
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Formik>
            </Stack>
        </Stack>
    )
}