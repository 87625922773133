import { ProcurementButtons, ProcurementForms, ProcurementPopupStatus } from '@/config';
import { ProductTableRow, ProcurementFormModel, QuoteProductTableRow } from '@/models';
import { ProcurementFormPermission } from '@/services';
import { useProcurementStore, useUserData } from '@/zustand';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { sm } from 'src/site-map';
import {
  EditAndRegenerateButton,
  EditResponseButton,
  FormMoreOptions,
  LinkedFormsIcon,
  MoreOptions,
  SinglePODocument,
} from 'src/ui/components/dumb';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useRouter } from 'next/router';
import { StyledLoadingButton } from 'src/ui/components/shared';
import { RelatedFormsList } from '../../../list-linked-forms';

export interface PurchaseOrderActionsType {
  purchaseOrder: ProcurementFormModel | null | undefined;
  isLastPO?: boolean;
  bundlePOId?: boolean;
  productsTableRows: QuoteProductTableRow[];
}
export const PurchaseOrderActions: FC<PurchaseOrderActionsType> = ({
  purchaseOrder,
  isLastPO,
  bundlePOId,
  productsTableRows,
}) => {
  const { t } = useTranslation();
  const { customLoadingButton } = useProcurementStore(state => state.bundlePurchaseOrderBuyerDetailsSliceProperties);
  const {
    setPopupStatus,
    deleteIndividualPO,
    deleteBundlePO,
    handleDuplicateIndividualPO,
    declineRequestExtension,
    requestExtensionForExpiredQuote,
  } = useProcurementStore(state => state.bundlePurchaseOrderBuyerDetailsSliceActions);
  const { push } = useRouter();
  const { canCreatePO } = useUserData();

  const handleSendForApproval = () => setPopupStatus(ProcurementPopupStatus.SEND_FOR_APPROVAL);
  const handleApprove = () => setPopupStatus(ProcurementPopupStatus.APPROVE);
  const handleReject = () => setPopupStatus(ProcurementPopupStatus.REJECT);
  let canEditResponse = purchaseOrder?.actions[ProcurementFormPermission.EDIT_RESPONSE];
  const rejectButton = () => (
    <Button startIcon={<ClearIcon />} color='error' onClick={handleReject}>
      {' '}
      {t('buttons.reject')}{' '}
    </Button>
  );

  const approveButton = () => (
    <Button startIcon={<CheckIcon />} sx={{ color: 'success.dark' }} onClick={handleApprove}>
      {' '}
      {t('buttons.approve')}
    </Button>
  );
  const handleSendPOToSeller = () => setPopupStatus(ProcurementPopupStatus.SENT_TO_SELLER);
  const handleRecallPO = () => setPopupStatus(ProcurementPopupStatus.RECALL);
  const handleDeleteIndividualPO = () => deleteIndividualPO(purchaseOrder?.model?.id, purchaseOrder?.model?.name);
  const handleDeleteBundlePO = () =>
    deleteBundlePO(bundlePOId, () => push(sm.portals.buyer.procurement.purchaseOrders.index.url));
  const handleCancelAction = () => setPopupStatus(ProcurementPopupStatus.CANCEL);

  const handleDuplicate = (project: any) => {
    handleDuplicateIndividualPO(purchaseOrder?.model?.id, project, (id: any) =>
      push(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(id).url)
    );
  };

  const handleDeclineRequestExtension = () => {
    declineRequestExtension(purchaseOrder?.model?.id);
  };

  const handleRequestExtensionForExpiredQuote = () => {
    requestExtensionForExpiredQuote(purchaseOrder?.model?.id, purchaseOrder?.model?.quote?.id);
  };

  return (
    <>
      {purchaseOrder?.actions[ProcurementFormPermission.REQUEST_EXTENSION_FOR_EXPIRED_QUOTE_BUYER] && (
        <StyledLoadingButton
          customWidth
          variant='contained'
          translateKey='request_extension'
          loading={customLoadingButton == ProcurementButtons.REQUEST_EXTENSION}
          onClick={handleRequestExtensionForExpiredQuote}
        />
      )}
      {purchaseOrder?.actions[ProcurementFormPermission.DECLINE_REQUEST_EXTENSION] && (
        <StyledLoadingButton
          customWidth
          variant='outlined'
          translateKey='decline_request'
          loading={customLoadingButton == ProcurementButtons.DECLINE_REQUEST_EXTENSION}
          color={'error'}
          onClick={handleDeclineRequestExtension}
        />
      )}
      {purchaseOrder?.actions[ProcurementFormPermission.REJECT] && !canEditResponse && rejectButton()}
      {purchaseOrder?.actions[ProcurementFormPermission.APPROVE] && !canEditResponse && approveButton()}
      {canEditResponse && (
        <EditResponseButton>
          {purchaseOrder?.actions[ProcurementFormPermission.APPROVE] && approveButton()}

          {purchaseOrder?.actions[ProcurementFormPermission.REJECT] && rejectButton()}
        </EditResponseButton>
      )}
      {purchaseOrder?.actions[ProcurementFormPermission.SEND_TO_SELLER] && (
        <Button variant='contained' sx={{ px: 4, mr: 1 }} onClick={handleSendPOToSeller}>
          {' '}
          {t('buttons.send_PO_to_seller')}{' '}
        </Button>
      )}

      {purchaseOrder?.model?.linkedforms?.length != 0 && (
        <LinkedFormsIcon>
          <RelatedFormsList forms={purchaseOrder?.model?.linkedforms} />
        </LinkedFormsIcon>
      )}

      <FormMoreOptions
        shareURL={purchaseOrder?.model.share_url}
        pdfDocument={
          <SinglePODocument
            po={purchaseOrder}
            companyDetails={purchaseOrder?.model?.buyer_details}
            productsTableRows={productsTableRows}
          />
        }
        recallAction={purchaseOrder?.actions[ProcurementFormPermission.RECALL]}
        handleRecallAction={handleRecallPO}
        deleteAction={purchaseOrder?.actions[ProcurementFormPermission.DELETE]}
        handleDeleteAction={!isLastPO ? handleDeleteIndividualPO : handleDeleteBundlePO}
        duplicateAction={canCreatePO}
        handleDuplicateAction={data => handleDuplicate(data)}
        currentProject={purchaseOrder?.model?.project?.id}
        formType={ProcurementForms.PO}
        formName={purchaseOrder?.model?.name}
        specificSubtitle={
          !isLastPO
            ? t('popups.subTitles.delete_form', { formType: ProcurementForms.PO })
            : t('popups.subTitles.delete_last_po')
        }
        cancelAction={purchaseOrder?.actions[ProcurementFormPermission.CANCEL_FORM]}
        handleCancelAction={handleCancelAction}
      />
    </>
  );
};
