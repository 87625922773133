import { StyledTextField } from "@/components";
import { Grid, Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import { toast } from 'react-toastify';
import { NotifyError, NotifySuccess, useResendVerificationEmail } from "@/services";
import { useLogin } from "@/zustand";

interface VerifyEmailProps { email: String }

export const VerifyEmailForm: React.FC<VerifyEmailProps> = (props: VerifyEmailProps) => {

    const { t } = useTranslation()

    const setVerified = useLogin(state => state.setVerified);

    const onSuccess = () => { NotifySuccess('Verification Email Was Sent'); setVerified(); }
    const onError = () => { NotifyError('Something Went Wrong') }
    const { mutate } = useResendVerificationEmail(onSuccess, onError);

    const handleSubmit = (values, actions) => {
        let email = values['email']
        mutate(email);
    }

    const initialValues = { email: props.email }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid item xs={12}>
                        <StyledTextField translateKey='user.email' name="email" showLabel/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            size='large'
                            color='secondary'
                            fullWidth
                            sx={{ mt: 2 }}
                            type="submit"
                        >
                            {t('buttons.verify_email')}
                        </Button>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}