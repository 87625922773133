import { Box, Grid, Typography, Button } from '@mui/material';
import { useRequisition } from '@/zustand';
import { Requisition } from '@/models';
import { RequisitionProduct, DescPopup } from '@/components';
import { ResponsiveContainer } from '@/styles';
import React from 'react';

type Props = {
  requisition: Requisition;
  selectedProductIndex: number;
};

export const AlternativeProductsList: React.FC<Props> = ({
  requisition,
  selectedProductIndex,
  sliderRef,
}) => {
  const [showCardDetails, setshowCardDetails] = React.useState(null);
  const openCardDetails = id => setshowCardDetails(id);
  const handleCloseCardDetails = () => setshowCardDetails(null);
  const removeProduct = useRequisition(state => state.removeProduct);

  return (
    <>
      {/* {!isSmallScreen ? ( */}
      <Box
        sx={{
          width: '230px',
          transform: 'rotateX(180deg)',
        }}
      >
        {requisition?.products?.map((products, index) => {
          if (index === selectedProductIndex)
            return (
              <RequisitionProduct
                key={products.mainProduct.partNumber}
                product={products.mainProduct}
                mainProduct={true}
                requisition={requisition}
                tabId={products.tabId}
                removeProduct={removeProduct}
                isWithDetails={true}
                openCardDetails={openCardDetails}
              />
            );
        })}
      </Box>
      <Grid
        container
        ref={sliderRef}
        wrap='nowrap'
        sx={{
          height: '100%',
          marginTop: -0.4,
          width: '1400px',
          position: 'relative',
          overflowX: 'scroll',
          scrollbarWidth: 'thin',
          display: '-webkit-box',
          scrollBehavior: 'smooth',
          WebkitBoxOrient: 'horizontal',
          transform: 'rotateX(180deg)',
          alignItems: 'end',
          '&::-webkit-scrollbar': {
            width: 3,
            height: 3,
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: `white`,
          },
          '&::-webkit-scrollbar-thumb': {
            scrollBehavior: 'smooth',
            backgroundColor: 'primary.main',
            outline: `1px solid slategrey`,
            borderRadius: 3,
          },
        }}
      >
        {requisition?.products[selectedProductIndex]?.altProducts.map(
          altProduct => {
            return (
              <RequisitionProduct
                key={altProduct.partNumber}
                product={altProduct}
                mainProduct={false}
                requisition={requisition}
                tabId={requisition.products[selectedProductIndex].tabId}
                removeProduct={removeProduct}
                isWithDetails={true}
                openCardDetails={openCardDetails}
              />
            );
          }
        )}
      </Grid>
      {/* ) : (
        <Grid
          container
          ref={sliderRef}
          // wrap='nowrap'
          sx={{
            height: '100%',
            // marginTop: -0.4,
            // width: '1400px',
            position: 'relative',
          }}
        >
          {requisition?.products[selectedProductIndex]?.altProducts.map(
            altProduct => {
              return (
                  <RequisitionProduct
                    key={altProduct.partNumber}
                    product={altProduct}
                    mainProduct={false}
                    requisition={requisition}
                    tabId={requisition.products[selectedProductIndex].tabId}
                    removeProduct={removeProduct}
                    isWithDetails={false}
                    openCardDetails={openCardDetails}
                  />
              );
            }
          )}
        </Grid>
      )} */}
    </>
  );
};
