import axios from 'axios';
import create from 'zustand';
import { apiURLs } from '@/config';
import { Filters_Res, Product } from '@/models';

type Filters = {
  data: Product[];
  filter_data: Filters_Res;
};

export const useCategories = create((set, get) => ({
  categories: [] as Filters[],
  getCategoriesURL: apiURLs.categoriesURL as string,
  waitCategories: '' as string,
  getCategories: async data => {
    set({ waitCategories: 'loading' });
    const response = await axios.get(get().getCategoriesURL, { params: data });
    set({
      categories: response.data.data,
      waitCategories: 'success',
    });
  },
}));
