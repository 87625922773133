import { AuthenticateStatus, ProcurementButtons, ProcurementForms, QuotePopupStatus, UserPortalPermission, procurementStatus } from "@/config";
import { GeneralObject, LinkType, Product, Quote, QuoteDetails, QuoteProduct, QuoteProductTableRow } from "@/models";
import { buildQuoteDetails, convertStatusToApproverStatus, NotifyMessage, NotifySuccess, NotifySuccessWithDeleteIcon, NotifySuccessWithCancelIcon, useApproveQuote, useCancelQuoteToApprovers, useCancelQuoteToBuyer, useChangeProject, useDeclineRequestExtension, useDeleteQuote, useDuplicateQuote, useEditExpiryDate, useFetchQuoteCancelReasons, useFetchQuoteRejectReasons, useGetAllTerms, useGetQuoteDetails, useRejectQuote, useRequestExtension, useSaveQuoteProducts, useSelectTermsInQuote, useSendQuoteForApproval, useSendQuoteToBuyer } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { useUserData } from "src/logic/zustand/user";
import { StateCreator } from "zustand";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";
import { getExternalQuoteDetails, useSendExternalQuoteToBuyer } from "src/logic/services/procurement/external-quote";
import { ResponseHandlers } from "src/logic/models/queries";

export interface QuoteDetailsSlice {
    quoteDetailsSliceProperties: {
        terms: any
        quote: QuoteDetails | null
        fetchQuoteDetailsQueryStatus: QueryHandlerModel,
        // loadingQuoteDetails: boolean
        productsTableRows: QuoteProductTableRow[]
        products: Product[]
        showAfterSendForApprovalPopup: boolean
        sendForApprovalPopup: boolean
        showAfterApprovedPopup: boolean
        showAfterRejectedPopup: boolean
        showAfterSendToBuyerPopup: boolean
        openedPopupStatus: QuotePopupStatus | null
        popupComment: string | null
        popupReasonId: string
        reasonsForPopup: GeneralObject[]
        userPermission: UserPortalPermission | ""
        canApproveQuote: boolean
        approverId: number
        newComments: Comment[]
        // isBundleRFQDeleted: boolean
        customLoadingButton: ProcurementButtons | null
        typingTimer: NodeJS.Timeout | null
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        isTermsValid: boolean
        numberOfProductsWithNoPrice: number
        payment_term: GeneralObject
        warranty_term: GeneralObject
        delivery_term: GeneralObject
    }
    quoteDetailsSliceActions: {
        setTimer: (timer: NodeJS.Timeout | null) => void
        fetchQuoteDetails: (quoteId: number, responseHandlers?: ResponseHandlers) => void
        fetchExternalQuoteDetails: (quoteId: number) => void
        // setIsLoading: (isLoading: boolean) => void
        setQuoteDetails: (quote: Quote) => void
        changeProject: (project: GeneralObject) => void
        setProductsTableRows: (quote: QuoteDetails | null) => void
        sendForApproval: (quoteId: number, optionalComment: string) => void
        setShowAfterSendForApprovalPopup: (isShow: boolean) => void
        setSendForApprovalPopup: (isShow: boolean) => void
        setShowAfterSendToBuyerPopup: (isShow: boolean) => void
        setPopupStatus: (status: QuotePopupStatus | null) => void
        setPopupReasonId: (reasonId: string) => void
        setPopupComment: (comment: string) => void
        getReasonsForPopup: () => void
        resetQuoteDetails: (data: Quote) => void
        approveQuote: (quoteId: number, comment?: string) => void
        rejectQuote: (quoteId: number, reasonId: string, comment?: string) => void
        cancelSendForApprovals: (quoteId: number) => void
        deleteQuote: (quoteId: number, redirectToQuotes: () => void) => void
        cancelForBuyer: (quoteId: number, handleCancelDone: () => void, reasonId: string, hideReason: boolean, comment?: string) => void
        closeNewCommentsPopup: () => void
        sendQuoteToBuyer: (quoteId: number, date: string, comment?: string) => void
        // setBundleRFQDeleted: (isDeleted: boolean) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        saveFormProducts: (form: Quote, products: Product[], handleSavingSucess: (data: Quote) => void, onError?: () => void) => void
        productsUpdate: (products: Product[]) => void
        setSummaryDetails: (products: Product[]) => void
        fetchTerms: () => void
        selectGeneralTerms: (terms: { payment_term_id: string | null, warranty_term_id: string | null, delivery_term_id: string | null }) => void
        handleDuplicateQuote: (quoteId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => void
        requestExtensionForRFQExpiredFromQuoteBySeller: (rfqId: number | undefined) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        declineRequestExtension: (quoteId: number | undefined) => void
    }
}

export const createQuoteDetailsSlice: StateCreator<QuoteDetailsSlice> = (set, get, api) => ({
    quoteDetailsSliceProperties: {
        quote: null,
        fetchQuoteDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingQuoteDetails: false,
        productsTableRows: [],
        showAfterSendForApprovalPopup: false,
        sendForApprovalPopup: false,
        showAfterApprovedPopup: false,
        showAfterRejectedPopup: false,
        openedPopupStatus: null,
        popupComment: null,
        popupReasonId: '0',
        reasonsForPopup: [],
        cancelReasonsForPopup: [],
        products: [],
        userPermission: '',
        canApproveQuote: false,
        approverId: 0,
        newComments: [],
        showAfterSendToBuyerPopup: false,
        // isBundleRFQDeleted: false,
        customLoadingButton: null,
        typingTimer: null,
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        terms: null,
        isTermsValid: false,
        numberOfProductsWithNoPrice: 0,
        payment_term: { id: null, name: '' },
        warranty_term: { id: null, name: '' },
        delivery_term: { id: null, name: '' },
    },
    quoteDetailsSliceActions: {
        setTimer: (timer: NodeJS.Timeout | null) => set(produce(draftState => { draftState.quoteDetailsSliceProperties.typingTimer = timer })),
        fetchQuoteDetails: (quoteId: number, responseHandlers?: ResponseHandlers) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            get().quoteDetailsSliceActions.fetchTerms()
            useGetQuoteDetails(quoteId).then(data => {
                const userId = useUserData.getState().userInfo?.id
                const canApproveQuote = useUserData.getState().canApproveQuote
                let isOwner = data.data.owner_id == userId
                let userPermission = isOwner ? UserPortalPermission.owner : UserPortalPermission.approver
                let approver = data.data.approvers?.filter(approver => approver.approver_id == userId)[0]
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.userPermission = userPermission
                    draftState.quoteDetailsSliceProperties.canApproveQuote = canApproveQuote
                    draftState.quoteDetailsSliceProperties.approverId = approver?.approver_id ?? 0
                    draftState.quoteDetailsSliceProperties.isTermsValid = false
                    draftState.quoteDetailsSliceProperties.customLoadingButton = null
                }))
                get().quoteDetailsSliceActions.setQuoteDetails(data.data)
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
                responseHandlers?.onSuccess?.(data.data);
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
                responseHandlers?.onError?.(response);
            })
        },
        fetchExternalQuoteDetails: (quoteId: number) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            getExternalQuoteDetails(quoteId).then(data => {
                const userId = useUserData.getState().userInfo?.id
                let isOwner = true
                let userPermission = isOwner ? UserPortalPermission.owner : UserPortalPermission.approver
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.userPermission = userPermission
                    draftState.quoteDetailsSliceProperties.canApproveQuote = false
                    draftState.quoteDetailsSliceProperties.approverId = 0
                    draftState.quoteDetailsSliceProperties.isTermsValid = false
                    draftState.quoteDetailsSliceProperties.customLoadingButton = null
                }))
                get().quoteDetailsSliceActions.setQuoteDetails(data.data)
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.fetchQuoteDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        fetchDetails: (formId: string, handlerAfterFetch: (quote: Quote) => void) => {
            useGetQuoteDetails(formId).then(data => {
                handlerAfterFetch({ ...data.data, products: getQuoteProductsArray(data.data.quote_products, get().quoteDetailsSliceProperties.warranty_term) })
            })
        },
        // setBundleRFQDeleted: (isDeleted: boolean) => set(produce(draftState => { draftState.quoteDetailsSliceProperties.isBundleRFQDeleted = isDeleted })),
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.quoteDetailsSliceProperties.loadingQuoteDetails = isLoading })),
        setQuoteDetails: (quote: Quote) => {
            let userPermission = get().quoteDetailsSliceProperties.userPermission
            let approverStatus = ''
            let approver = quote.approvers?.filter(approver => approver.approver_id == get().quoteDetailsSliceProperties.approverId)[0]
            if (approver) approverStatus = convertStatusToApproverStatus(approver?.status)
            let quoteWithStatus = { ...quote, status: userPermission == UserPortalPermission.owner || !approverStatus ? quote.status : approverStatus }
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.quote = buildQuoteDetails(quoteWithStatus, userPermission, get().quoteDetailsSliceProperties.canApproveQuote)
                draftState.quoteDetailsSliceProperties.newComments = quoteWithStatus.new_comments?.length == 0 ? null : quoteWithStatus.new_comments
                draftState.quoteDetailsSliceProperties.customLoadingButton = null
                draftState.quoteDetailsSliceProperties.sendForApprovalPopup = false
                draftState.quoteDetailsSliceProperties.numberOfProductsWithNoPrice = quote?.quote_products.filter(product => product.unit_price === null).length
                draftState.quoteDetailsSliceProperties.products = getQuoteProductsArray(quote?.quote_products, quote.terms_bundle?.warranty_term ?? { id: null, name: '' })
                draftState.quoteDetailsSliceProperties.checkIfTermsValid = false
                draftState.quoteDetailsSliceProperties.payment_term = quote.terms_bundle?.payment_term ?? { id: null, name: '' }
                draftState.quoteDetailsSliceProperties.warranty_term = quote.terms_bundle?.warranty_term ?? { id: null, name: '' }
                draftState.quoteDetailsSliceProperties.delivery_term = quote.terms_bundle?.delivery_term ?? { id: null, name: '' }
            }))
            get().quoteDetailsSliceActions.setProductsTableRows(get().quoteDetailsSliceProperties.quote)
            get().quoteDetailsSliceActions.setSummaryDetails(getQuoteProductsArray(quote?.quote_products, get().quoteDetailsSliceProperties.warranty_term))
            get().setTimeline(quote.timeline)
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().quoteDetailsSliceProperties.quote?.quoteModel)
            get().quoteDetailsSliceActions.setQuoteDetails(modelAfterUpdateProject)
        },
        setProductsTableRows: (quote: QuoteDetails | null) => {
            let ProductsArray: QuoteProductTableRow[] = []
            let warrantyTerm = get().quoteDetailsSliceProperties?.quote?.quoteModel?.terms_bundle?.warranty_term
            quote?.quoteModel?.quote_products?.map((product: QuoteProduct) => ProductsArray.push({
                id: product.id,
                productDetails: {
                    name: product.product.name,
                    partNumber: product.product.part_number,
                    image_url: product.product.category.image_url,
                },
                brandImage: product.product.brand.image_url,
                // warrantyTerm: product.warranty_term.name,
                warrantyTerm: { id: product.warranty_term?.id ?? warrantyTerm?.id ?? 0, name: product.warranty_term?.name ?? warrantyTerm?.name ?? ' ' },
                requestedQuantity: product.requested_quantity,
                availableQuantity: product.available_quantity,
                unitPrice: product.unit_price,
                remark: product.remarks ?? '',
                additional_requirements: product.additional_requirements,
            }))
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.productsTableRows = ProductsArray
            }))
        },
        sendForApproval: (quoteId: number, optionalComment: string) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_QUOTE_FOR_APPROVAL)
            useSendQuoteForApproval(quoteId, optionalComment).then((data) => {
                get().quoteDetailsSliceActions.setQuoteDetails(data.data)
                get().quoteDetailsSliceActions.setShowAfterSendForApprovalPopup(true)
                NotifySuccess(t("popups.titles.sent_for_approval"))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        setShowAfterSendForApprovalPopup: (isShow: boolean) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.showAfterSendForApprovalPopup = isShow
            }))
        },
        setSendForApprovalPopup: (isShow: boolean) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.sendForApprovalPopup = isShow
            }))
        },
        setShowAfterSendToBuyerPopup: (isShow: boolean) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.showAfterSendToBuyerPopup = isShow
                draftState.quoteDetailsSliceProperties.popupComment = null
                draftState.quoteDetailsSliceProperties.popupReasonId = '0'
            }))
        },
        setPopupStatus: (status: QuotePopupStatus | null) => set(produce(draftState => {
            draftState.quoteDetailsSliceProperties.openedPopupStatus = status
            if (!status) {
                draftState.quoteDetailsSliceProperties.popupComment = null
                draftState.quoteDetailsSliceProperties.popupReasonId = '0'
                draftState.quoteDetailsSliceProperties.openedPopupStatus = null
            }
        })),
        setPopupReasonId: (reasonId: string) => set(produce(draftState => { draftState.quoteDetailsSliceProperties.popupReasonId = reasonId })),
        setPopupComment: (comment: string) => set(produce(draftState => { draftState.quoteDetailsSliceProperties.popupComment = comment })),
        getReasonsForPopup: () => {
            if (get().quoteDetailsSliceProperties.openedPopupStatus == QuotePopupStatus.REJECT) useFetchQuoteRejectReasons().then(data => { set(produce(draftState => { draftState.quoteDetailsSliceProperties.reasonsForPopup = data.data })) })
            else if (get().quoteDetailsSliceProperties.openedPopupStatus == QuotePopupStatus.CANCEL_QUOTE) useFetchQuoteCancelReasons().then(data => { set(produce(draftState => { draftState.quoteDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        approveQuote: (quoteId: number, comment?: string) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_QUOTE)
            useApproveQuote(quoteId, comment ?? null).then(data => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                NotifySuccess(t('notifications.form_approved', { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        rejectQuote: (quoteId: number, reasonId: string, comment?: string) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_QUOTE)
            useRejectQuote(quoteId, comment ?? null, reasonId).then(data => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.form_rejected', { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancelForBuyer: (quoteId: number, handleCancelDone: () => void, reasonId: string, hideReason: boolean, comment?: string) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_SEND_QUOTE_TO_BUYER)
            useCancelQuoteToBuyer(quoteId, reasonId, hideReason, comment).then(data => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                handleCancelDone()
                NotifyMessage(t("notifications.form_recalled", { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancelSendForApprovals: (quoteId: number) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_QUOTE_APPROVAL)
            useCancelQuoteToApprovers(quoteId).then(data => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                NotifyMessage(t("popups.titles.approval_canceled"))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        deleteQuote: (quoteId: number, redirectToQuotes: () => void) => {
            useDeleteQuote(quoteId).then(() => {
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }));
                redirectToQuotes()
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.quote = null
                }))
            })
        },
        sendQuoteToBuyer: (quoteId: number, date: string, comment?: string) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_QUOTE_TO_BUYER)

            const isGuest = useUserData.getState().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED
            const serviceToBuyerService = isGuest ? useSendExternalQuoteToBuyer : useSendQuoteToBuyer
            serviceToBuyerService(quoteId, date, comment ?? null).then(data => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                get().quoteDetailsSliceActions.setShowAfterSendToBuyerPopup(true)
                NotifySuccess(t('notifications.form_sent_to_buyer', { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        resetQuoteDetails: (data) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.popupReasonId = 0
                draftState.quoteDetailsSliceProperties.openedPopupStatus = null
                draftState.quoteDetailsSliceProperties.popupComment = null
                // draftState.quoteDetailsSliceProperties.quote = buildQuoteDetails(data, get().quoteDetailsSliceProperties.isOwner)
            }))
            get().quoteDetailsSliceActions.setQuoteDetails(data)
        },
        closeNewCommentsPopup: () => set(produce(draftState => {
            const quoteModel = draftState.quoteDetailsSliceProperties?.quote?.quoteModel;
            if (quoteModel) {
                quoteModel.new_comments = null;
            }
            draftState.quoteDetailsSliceProperties.newComments = null
        })),
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.quoteDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        productsUpdate: (products: Product[]) => {
            const data = get().quoteDetailsSliceProperties
            const actions = get().quoteDetailsSliceActions
            actions.setQuoteDetails({ ...data.quote?.quoteModel, quote_products: products })
            clearTimeout(data.typingTimer);
            const newTimer = setTimeout(() => {
                actions.setTimer(null)
                const handleSavingSucess = (form: Quote) => {
                    actions.setQuoteDetails({ ...form, quote_products: form.products })
                    // set(produce(draftState => { draftState.quoteDetailsSliceProperties.products = form.products }))
                    NotifySuccess(t('notifications.changes_saved'))
                }
                actions.saveFormProducts(data.quote?.quoteModel, products, handleSavingSucess)
            }, 2000);
            actions.setTimer(newTimer)
        },
        saveFormProducts: (form: Quote, products: Product[], handleSavingSucess: (data: Quote) => void, onError?: () => void) => {
            useSaveQuoteProducts(form, products).then(data => {
                const formDetails = data.data as Quote
                const formProducts = getQuoteProductsArray(formDetails?.quote_products, get().quoteDetailsSliceProperties.warranty_term)
                handleSavingSucess({ ...formDetails, products: formProducts })
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.numberOfProductsWithNoPrice = formDetails?.quote_products.filter(product => product.unit_price === null || '').length
                }))
            }).catch(() => {
                onError?.()
            })
        },
        setSummaryDetails: (products: Product[]) => {
            const summary = getVATTotalValues(products, 'quantity')
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.quoteDetailsSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.quoteDetailsSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        fetchTerms: () => {
            useGetAllTerms().then(data => {
                const allTerms = data.data
                set(produce(draftState => {
                    draftState.quoteDetailsSliceProperties.terms = allTerms;
                }))
            })
        },
        selectGeneralTerms: (terms: { payment_term_id: string | null, warranty_term_id: string | null, delivery_term_id: string | null }) => {
            const termsData = get().quoteDetailsSliceProperties.terms
            const PoId = get().quoteDetailsSliceProperties?.quote?.quoteModel?.id

            const termsIds = produce(terms, draftTerms => {
                draftTerms.payment_term_id = terms.payment_term_id
                draftTerms.warranty_term_id = terms.warranty_term_id
                draftTerms.delivery_term_id = terms.delivery_term_id
            })
            let payment_term = termsData?.payment_terms.find(term => term.id == termsIds.payment_term_id)
            let warranty_term = termsData?.warranty_terms.find(term => term.id == termsIds.warranty_term_id)
            let delivery_term = termsData?.delivery_terms.find(term => term.id == termsIds.delivery_term_id)
            let isTermsValid = payment_term && warranty_term && delivery_term ? true : false
            set(produce(draftState => {
                draftState.quoteDetailsSliceProperties.payment_term = payment_term ?? { id: null, name: '' }
                draftState.quoteDetailsSliceProperties.warranty_term = warranty_term ?? { id: null, name: '' }
                draftState.quoteDetailsSliceProperties.delivery_term = delivery_term ?? { id: null, name: '' }
                draftState.quoteDetailsSliceProperties.isTermsValid = isTermsValid
                draftState.quoteDetailsSliceProperties.checkIfTermsValid = false
            }))
            {
                isTermsValid && useSelectTermsInQuote(PoId, payment_term?.id, warranty_term?.id, delivery_term?.id).then(data => {
                    get().quoteDetailsSliceActions.setQuoteDetails(data.data)
                })
            }
        },
        handleDuplicateQuote: (quoteId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => {
            useDuplicateQuote(quoteId, project).then((data) => {
                redirectToDuplicatedForm(data.data.id);
                NotifySuccess(t('notifications.form_duplicated', { formName: get().quoteDetailsSliceProperties.quote?.quoteModel?.name }));
            });
        },
        requestExtensionForRFQExpiredFromQuoteBySeller: (rfqId: number | undefined) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(rfqId, 'RequestForQuote').then((data) => {
                set(produce(draftState => { draftState.quoteDetailsSliceProperties.customLoadingButton = null }))
                let model = get().quoteDetailsSliceProperties.quote?.quoteModel
                let updatedQuote = { ...model, rfq: { ...model?.rfq, form_action_permissions: { ...model?.rfq?.form_action_permissions, can_request_extension: false } } }
                get().quoteDetailsSliceActions.setQuoteDetails(updatedQuote)
                NotifySuccess(t('notifications.extension_requested'))
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const quoteId = get().quoteDetailsSliceProperties.quote?.quoteModel?.id
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(quoteId, LinkType.QUOTE_SELLER, validityDate).then((data) => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineRequestExtension: (quoteId: number | undefined) => {
            get().quoteDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(quoteId, 'Quote').then((data) => {
                get().quoteDetailsSliceActions.resetQuoteDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
            }).catch(() => get().quoteDetailsSliceActions.setCustomLoadingButton(null))
        }
    }
})


export const getQuoteProductsArray = (products: Product[], warrantyTerm: GeneralObject) => {
    // return products.map(product => { return { ...product, ...product.product, quantity: product.available_quantity, price: product.unit_price } })

    return products.map(product => { return { ...product, ...product.product, additional_requirements: product.additional_requirements, quantity: product.available_quantity, price: product.unit_price, warranty_term: product.warranty_term ? product.warranty_term : warrantyTerm, isCustomWarranty: product.warranty_term ? warrantyTerm?.id != product?.warranty_term?.id : false } })
}
export const getVATTotalValues = (products: Product[], quantity: string) => {

    let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product.price) * product.quantity), 0);
    let vat = (10 / 100) * subtotal;

    subtotal = subtotal.toFixed(2);
    vat = vat.toFixed(2);

    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

    return { subtotal: subtotal, vat: vat, total: total };
}
