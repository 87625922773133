import * as React from 'react';
import type { FC } from 'react';
import { Typography, Button } from "@mui/material";
import { useTranslation } from 'next-i18next';

interface SuccessProps {
  paragraphTranslate: string,
  userInfo?: string,
  togglePopup: () => void
}

export const Success: FC<SuccessProps> = (props: SuccessProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant='body2' sx={{ fontWeight: '400' }}>
                {props.paragraphTranslate}
                <Typography variant='body2' sx={{ fontWeight: '900' }}>{props.userInfo}</Typography>
            </Typography>
            <Button
                variant='contained'
                color='secondary'
                fullWidth
                sx={{ mt: 3 }}
                onClick={props.togglePopup}
            >
                {t('buttons.done')}
            </Button>
        </>
    )
}