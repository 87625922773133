import React, { FC, useEffect, useState } from 'react';
import { Tooltip } from "@mui/material";

interface TextTooltipProps {
    title: string | number | undefined
    textRef: any
    children?: any
}
export const TextTooltip: FC<TextTooltipProps> = (props: TextTooltipProps) => {
    const [overflowActive, setOverflowActive] = useState(false);
    function isOverflowActive(e) {
        return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
    }
    useEffect(() => {
        if (isOverflowActive(props.textRef.current)) {
            setOverflowActive(true);
            return;
        }
        setOverflowActive(false);
    }, [isOverflowActive]);
    return (
        <Tooltip title={overflowActive ? props.title : ''}>
            {props.children}
        </Tooltip>
    );
};

