import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { getHomeTranslationKey } from '@/hooks';

type Props = {};
 

export const HowItWorksHomeSection: React.FC<Props> = ({}) => {

  const { t } = useTranslation();

  return (
    <ResponsiveContainer>
      <Typography variant='h2' textAlign='center' fontWeight='bold'>
          {t(getHomeTranslationKey('titles.how_it_works'))}  
      </Typography>
      <Box display='flex' justifyContent='center' mt={2}>
        <Typography
          color='text.third'
          textAlign='center'
          maxWidth={600}
          variant='body2'
        >
          {t(getHomeTranslationKey('sub_titles.how_it_works'))}  
        </Typography>
      </Box>
      <Grid container spacing={12} pt={6} justifyContent='center'>
        
        <HowItWorksHomeCard 
          title={t(getHomeTranslationKey('titles.create_requisitions'))} 
          subTitle={t(getHomeTranslationKey('sub_titles.create_requisitions'))} 
          iconPath={'/assets/images/home/fancy-1.svg'}
        />
        <HowItWorksHomeCard 
          title={t(getHomeTranslationKey('titles.create_RFQ'))} 
          subTitle={t(getHomeTranslationKey('sub_titles.create_RFQ'))} 
          iconPath={'/assets/images/home/fancy-2.svg'}
        />
        <HowItWorksHomeCard 
          title={t(getHomeTranslationKey('titles.purchase_orders'))} 
          subTitle={t(getHomeTranslationKey('sub_titles.purchase_orders'))} 
          iconPath={'/assets/images/home/fancy-3.svg'}
        />

      </Grid>
    </ResponsiveContainer>
  );
};


const HowItWorksHomeCard: React.FC<Props> = ({title , subTitle ,iconPath}) => {

  return (    
    <>
    <Grid item xl={4} lg={6} xs={12} key={title}>
      <Box>
        <Box
          sx={{
            position: 'relative',
            height: '129px',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <Image
            src={`${iconPath}`}
            layout='fill'
            objectFit='contain'
            alt='Fancy'
          />
        </Box>
        <Box alignItems='center' mt={2}>
          <Typography
            textAlign='center'
            variant='body1'
            fontWeight='700'
            sx={{ width: '80%',ml:1 }}
          >
            {title}
          </Typography>
          <Typography
            mt={1}
            textAlign='center'
            color='text.third'
            fontWeight='400'
            variant='body2'
            sx={{ width: '328px' }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Grid>
    </>
  );
};
