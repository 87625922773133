import axios from 'axios';
import create from 'zustand';
import { Product } from '@/models';

export const useProductStore = create((set, get) => ({
  productData: {},
  alternatives: [],
  waitProductData:'Loading',
  
  setProduct: (product) => {
    set({productData: product.product})
    set({alternatives: product.alternatives})
    set({waitProductData: 'success'})
  }
}));
