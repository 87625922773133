import { useEffect } from 'react';
import { useTimeout } from '../use-time-out';

export function useDebounce(
  callback: Function,
  delay: number,
  dependencies: any[]
) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, [clear]);
}
