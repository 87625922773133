import { ConfirmPopup, TextFieldCounter } from "@/components";
import { Product } from "@/models";
import { useProcurementStore } from "@/zustand";
import { Box, Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import React, { useState } from "react";
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
import SellersIndicatorBox from "../requisition-product-card/sellers-indicator-box";

type DraftRequisitionButtonsProps = {
    product: Product,
    mainProductIsInCart?: boolean
    isPageAlternative?: boolean;
};

export const DraftRequisitionButtons: React.VFC<DraftRequisitionButtonsProps> = ({ product }) => {
    const { t } = useTranslation();
    const changeProductQuantity = useProcurementStore(state => state.changeProductQuantityWithPersistence)
    const removeMainProduct = useProcurementStore(state => state.requisitionsDetailsSliceActions.removeMainProduct)

    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    const changeQuantity = (quantity: number) => changeProductQuantity(quantity, product)
    const handleRemoveProduct = () => {
        removeMainProduct(product)
        handleCloseDelPopup()
    }
    return (
        <>
            {product.inCartType == 'main' ? <>
                <Box width="100%">
                    <TextFieldCounter
                        value={product.quantity}
                        changeQuantity={changeQuantity}
                    />
                    <SellersIndicatorBox sellers={product.sellers} />
                    <Button variant="LightError" onClick={handleOpenDelPopup} fullWidth startIcon={<Delete />} sx={{ mt: 1 }}>{t('buttons.remove')}</Button>
                </Box>
            </> : <Button variant="LightError" onClick={handleOpenDelPopup} startIcon={<Delete />}>{t('buttons.remove')}</Button>
            }
            <ConfirmPopup
                titleKey={t("popups.titles.remove_product")}
                subTitleKey={t('popups.subTitles.remove_product', { formName: "your Draft Requisition" })}
                buttonTitleKey={t('buttons.remove')}
                handleClose={handleCloseDelPopup}
                handleConfirm={handleRemoveProduct}
                open={showDelPopup}
            />
        </>
    )
}