import { ConfirmPopup, Link, RequisitionProductWithSellersCard, StyledBreadcrumbs, StyledLoadingButton, StyledSelect, StyledTabs, TextFieldCounter, WarningPopup } from "@/components";
import { ProcurementButtons, productManagerEnv } from "@/config";
import { ProductCategoryKind, TabModel } from "@/models";
import { NotifyError, RFQType } from "@/services";
import { useProcurementStore } from "@/zustand";
import { ChevronRight } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { t } from "i18next";
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from "react";
import usePopup from "src/logic/hooks/use-popup";
// import usePreventReload from "src/logic/hooks/use-prevent-reload";
import { sm } from "src/site-map";
import PrimaryBadge from "src/ui/components/dumb/shared/primary-badge";
import * as yup from 'yup';
import { EditableGeneralTermsContent } from "../../../general-terms/editable/EditableGeneralTerms";
import BuyerRequirementsIndicator from "../../products-table/BuyerRequirementsIndicator";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const validationSchema = yup.object({
    payment_term_id: yup.string().required('required'),
    warranty_term_id: yup.string().required('required'),
    delivery_term_id: yup.string().required('required')
});

export const SelectSellersMainPage = ({ currentRequisition, breadCrumbs, requisitionProps }) => {
    const [changesHasMade, setChangesHasMade] = React.useState(false);

    const payment_term = useProcurementStore(state => state.payment_term)
    const warranty_term = useProcurementStore(state => state.warranty_term)
    const delivery_term = useProcurementStore(state => state.delivery_term)
    const convertToRFQData = useProcurementStore(state => state.convertToRFQData)
    const updateGeneralTerms = useProcurementStore(state => state.updateGeneralTerms)

    const { replace } = useRouter();
    React.useEffect(() => {
        const canConvertToRFQ = convertToRFQData?.requisition?.can_edit;
        if (canConvertToRFQ === false) {
            replace(sm.portals.buyer.procurement.requisitions.requisition(convertToRFQData?.requisition?.id).url);
        }
    }, [convertToRFQData])

    const initialValues = { payment_term_id: payment_term?.id, warranty_term_id: warranty_term?.id, delivery_term_id: delivery_term?.id }
    const handleSaveGeneralTerms = (values) => {
        setChangesHasMade(true);
        updateGeneralTerms(values)
    }
    const handleSubmit = (values) => { }

    return (
        <Grid container gap={2}>
            {convertToRFQData && (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount
                >
                    {formikProps => (
                        <>
                            <FormDetails
                                handleSaveGeneralTerms={handleSaveGeneralTerms}
                                changesHasMade={changesHasMade}
                                setChangesHasMade={setChangesHasMade}
                                currentRequisition={currentRequisition}
                                breadCrumbs={breadCrumbs}
                                requisitionProps={requisitionProps}
                            />
                        </>
                    )}
                </Formik>
            )}
        </Grid>
    );
}

const FormDetails = ({ handleSaveGeneralTerms, currentRequisition, breadCrumbs, requisitionProps, changesHasMade, setChangesHasMade }) => {
    const { push, events, replace } = useRouter()
    const cancelSelectPopup = usePopup();

    // prevent browser from reload or closing the browser in case there are unsaved changes
    // usePreventReload(changesHasMade);

    const [sureToCancel, setSureToCancel] = React.useState(false);

    const onRouteChangeStart = React.useCallback(
        (nextPath: string) => {
            if (nextPath.startsWith('/catalogue') || nextPath.startsWith('/portal/buyer/procurement/bundleRFQs/') || nextPath.startsWith('/portal/buyer/procurement/request-for-quotes')) {
                return;
            }
            if (sureToCancel) {
                return;
            }
            if (changesHasMade) {
                cancelSelectPopup.handleOpen();
                setSureToCancel(true);
                throw 'cancelRouteChange';
            }
        },
        [changesHasMade, sureToCancel]
    );

    React.useEffect(() => {
        events.on('routeChangeStart', onRouteChangeStart);
        return () => events.off('routeChangeStart', onRouteChangeStart);
    }, [onRouteChangeStart]);

    const _selectSellersForProduct = useProcurementStore(state => state.selectSellersForProduct)
    const selectSellersForProduct = (...params) => {
        _selectSellersForProduct(...params);
        setChangesHasMade(true);
    }
    const _updateConvertToRFQProductRequirements = useProcurementStore(state => state.updateConvertToRFQProductRequirements)
    const updateConvertToRFQProductRequirements = (...params) => {
        _updateConvertToRFQProductRequirements(...params);
        setChangesHasMade(true);
    }
    const _updateConvertToRFQProductQuantity = useProcurementStore(state => state.updateConvertToRFQProductQuantity)
    const updateConvertToRFQProductQuantity = (...params) => {
        _updateConvertToRFQProductQuantity(...params);
        setChangesHasMade(true);
    }
    const updateConvertToRFQProductWarrantyTerm = useProcurementStore(state => state.updateConvertToRFQProductWarrantyTerm)
    const handleSelectWarrantyTerms = (productDetails, warrantyId) => {
        setChangesHasMade(true);
        updateConvertToRFQProductWarrantyTerm(productDetails, convertToRFQData?.terms.warranty_terms.find(warranty => warranty.id == warrantyId))
    }

    const convertToRFQProducts = useProcurementStore(state => state.convertToRFQProducts)
    const convertToRFQData = useProcurementStore(state => state.convertToRFQData)
    const selectedMainProductId = useProcurementStore(state => state.selectedMainProductId)
    const selectedAlternativeProducts = useProcurementStore(state => state.selectedAlternativeProducts)
    const selectedMainProduct = useProcurementStore(state => state.selectedMainProduct)
    const setSelectedProduct = useProcurementStore(state => state.setSelectedProduct)
    const generateRFQ = useProcurementStore(state => state.generateRFQ)
    const warranty_term = useProcurementStore(state => state.warranty_term)
    const handleGenerateRFQ = useProcurementStore(state => state.handleGenerateRFQ)
    const clearSelectedSellers = useProcurementStore(state => state.clearSelectedSellers)
    const customLoadingButton = useProcurementStore(state => state.customLoadingButton)
    const getNumberOfProductsWithNoSellerSelected = useProcurementStore(state => state.getNumberOfProductsWithNoSellerSelected)
    const selectedSellerTabs = useProcurementStore(state => state.selectedSellerTabs)
    const { isValid } = useFormikContext()
    const notifyNoSelectedSellersInForm = () => NotifyError(t('notifications.no_selected_sellers'))
    const notifySelectGeneralTerms = () => NotifyError(t('texts.general_terms_required'))
    const [submitRequested, setSubmitRequested] = React.useState(false);
    const handleSubmit = (values) => {
        setSubmitRequested(true);
        if (!isValid) {
            notifySelectGeneralTerms();
            return;
        }
        handleGenerateRFQ(notifyNoSelectedSellersInForm, handleOpenWarningPopup, handleGenerateSuccess)
    }

    const handleGenerateSuccess = (id: number, isBundle: boolean) => {
        isBundle ? replace(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(id).url) : replace(sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(id).url)
    };

    //generate rfq warning popup
    const [isWarningPopupOpened, setIsWarningPopupOpened] = useState(false);
    const handleOpenWarningPopup = () => setIsWarningPopupOpened(true);
    const handleCloseWarningPopup = () => setIsWarningPopupOpened(false);
    const handleGenerateRFQFromPopup = () => {
        setChangesHasMade(false);
        generateRFQ(handleGenerateSuccess)
        handleCloseWarningPopup()
    }

    const selectedProduct = convertToRFQProducts?.find(product => product.main_product.id === selectedMainProductId);

    const handleCancelSelecting = () => {
        requisitionProps.type == RFQType.BUNDLE_RFQ && push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(requisitionProps.id).url)
        requisitionProps.type == RFQType.SINGLE_RFQ && push(sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(requisitionProps.id).url)
        !(requisitionProps.type && requisitionProps.id) && push(sm.portals.buyer.procurement.requisitions.requisition(currentRequisition.id).url)
    }
    const handleClickCancel = () => {
        setSureToCancel(true);
        cancelSelectPopup.handleOpen();
    }

    // clear selected sellers
    const handleClearSelectedSeller = () => clearSelectedSellers(selectedMainProduct?.requisition_product_id)
    const numberOfProducts: any = getNumberOfProductsWithNoSellerSelected();
    const productProps = numberOfProducts == 1 ? "product" : "products";
    const returnName = () => {
        return (
            <Grid container alignItems={'center'}>
                <> <Link underline='hover' href={sm.portals.buyer.procurement.requisitions.requisition(currentRequisition?.id).url}>{currentRequisition?.name}</Link></>
                <> <ChevronRight sx={{ mx: 0.2, fontSize: '20px !important', color: 'text.third' }} /> select sellers</>
            </Grid>
        )
    }

    let BreadcrumbsName = returnName();

    const { getProductsArrayFromRequisition } = useProcurementStore(state => state.requisitionSliceActions)
    const { setDetails } = useProcurementStore(state => state.productFormManagerProperties.actions)
    const setEnvironmentForAddingProducts = () => {
        setDetails({ ...currentRequisition, products: getProductsArrayFromRequisition(currentRequisition) }, productManagerEnv.requisition)
    }
    const handleAddProducts = () => {
        setEnvironmentForAddingProducts();
        push(sm.catalogue.index.url)
    }
    const addAlternativesForSelectedProduct = () => {
        setEnvironmentForAddingProducts();
        push(
            sm.catalogue.products.alternatives(
                // selectedMainProduct?.category.parent.parent.id,
                // selectedMainProduct?.category.parent.id,
                // selectedMainProduct?.category.id,
                selectedMainProduct?.id
            ).url
        );
    }

    const addMainProductsWarningPopup = usePopup();
    const addAlternativeProductsWarningPopup = usePopup();

    return (
        <>
            <ConfirmPopup
                icon={<ErrorOutlineIcon color='error' fontSize='large' />}
                titleKey={t('popups.titles.cancel_select_sellers')}
                subTitleKey={t('popups.subTitles.cancel_select_sellers_rfq')}
                buttonTitleKey={"Yes, I'm sure"}
                handleClose={() => { cancelSelectPopup.handleClose(); setSureToCancel(false); }}
                handleConfirm={handleCancelSelecting}
                open={cancelSelectPopup.isOpen}
            />
            <ConfirmPopup
                icon={<ErrorOutlineIcon color='error' fontSize='large' />}
                titleKey={t('popups.titles.warning')}
                subTitleKey={t('popups.subTitles.loose_changes_rfq')}
                buttonTitleKey={"Discard Changes"}
                handleClose={addMainProductsWarningPopup.handleClose}
                handleConfirm={handleAddProducts}
                open={addMainProductsWarningPopup.isOpen}
            />
            <ConfirmPopup
                icon={<ErrorOutlineIcon color='error' fontSize='large' />}
                titleKey={t('popups.titles.warning')}
                subTitleKey={t('popups.subTitles.loose_changes_rfq')}
                buttonTitleKey={"Discard Changes"}
                handleClose={addAlternativeProductsWarningPopup.handleClose}
                handleConfirm={addAlternativesForSelectedProduct}
                open={addAlternativeProductsWarningPopup.isOpen}
            />
            {convertToRFQProducts && <>
                {/* <PageDetailsHeader> */}

                <Grid item md={12} my={1} pl={1}>
                    <StyledBreadcrumbs path={breadCrumbs} alias={breadCrumbs} dynammicAlias={[BreadcrumbsName]} />
                </Grid>

                <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px', mb: 1 }}>
                    <Grid container md={12} p={1}>
                        {
                            requisitionProps?.name ?
                                <>
                                    <Grid item container md={5} alignItems="center" gap={1} >
                                        <Typography variant="h5" fontWeight={600} pl={1}>{requisitionProps.name + " " + '(' + t('texts.select_sellers') + ')'}</Typography>
                                        <Chip label={t('chip.EDITING')} variant="outlined" sx={{ minWidth: "144px", height: '28px', ml: 1 }} />
                                    </Grid>
                                    <Grid item container md={7} alignItems="center" justifyContent={'end'} py={1}>
                                        <Grid container md={12} alignItems="center" justifyContent={'end'}>
                                            {/* <StyledLoadingButton customWidth variant="contained" onClick={handleSubmit} translateKey="generate_RFQ" loading={customLoadingButton == ProcurementButtons.GENERATE_RFQ} /> */}
                                            {/* <Button type="submit" color='success' variant="contained">  {t("buttons.save_changes")} </Button> */}
                                            <StyledLoadingButton customWidth variant="contained" color='success' onClick={handleSubmit} translateKey="save_changes" loading={customLoadingButton == ProcurementButtons.GENERATE_RFQ} />

                                            <Button color='error' sx={{ ml: 2, backgroundColor: 'inherit', borderColor: 'error.main', '&:hover': { backgroundColor: 'inherit' } }}
                                                startIcon={<ClearIcon sx={{ mr: -1 }} />} onClick={handleClickCancel} >
                                                {t("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item container md={5} alignItems="center" gap={1} >
                                        <Typography variant="h5" fontWeight={500} pl={1}>{currentRequisition?.name}</Typography><Typography variant="h5" fontWeight={600}>({t('texts.select_sellers')})</Typography>
                                    </Grid>
                                    <Grid item container gap={1} md={7} alignItems="center" justifyContent={'end'} >
                                        <Grid container md={12} alignItems="center" justifyContent={'end'}>

                                            <StyledLoadingButton customWidth variant="contained" onClick={handleSubmit} translateKey="generate_RFQ" loading={customLoadingButton == ProcurementButtons.GENERATE_RFQ} />

                                            <Button color='error' sx={{ pt: 1, ml: 1, backgroundColor: 'inherit', borderColor: 'error.main', '&:hover': { backgroundColor: 'inherit' } }}
                                                startIcon={<ClearIcon sx={{ mr: -1 }} />} onClick={handleClickCancel} >
                                                {t("buttons.cancel")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                        }

                        <Grid item md={12}> <Divider sx={{ opacity: 0.7, my: 1 }} /> </Grid>
                        <Grid item container md={12} px={1}>   <Typography color="text.third" variant='body2' >{t('texts.convert_to_RFQ')}</Typography>   </Grid>
                    </Grid>
                </Box>

                <WarningPopup
                    open={isWarningPopupOpened}
                    title={t("popups.titles.generate_rfq_warning", { productsNumber: numberOfProducts, name: `main ${productProps}` })}
                    subTitle={t("popups.subTitles.generate_rfq_warning")}
                    handleClose={handleCloseWarningPopup}
                    confirmButtonTitle={t("buttons.proceed_generate_rfq")}
                    handleConfirm={handleGenerateRFQFromPopup}
                    cancelButtonTitle={t("buttons.back_select_sellers")}
                />

                <Grid item container md={12}>
                    {convertToRFQData && <EditableGeneralTermsContent
                        terms={convertToRFQData?.terms}
                        handleSelectGeneralTerms={handleSaveGeneralTerms}
                        forceValidateTerms={submitRequested}
                    />}
                </Grid>

                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <Typography fontWeight={700} color='text.primary' variant="overline">Products / Services <PrimaryBadge>{selectedSellerTabs?.length}</PrimaryBadge></Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='primary'
                            type='button'
                            onClick={changesHasMade ? addMainProductsWarningPopup.handleOpen : handleAddProducts}
                        >
                            Add Products / Services
                        </Button>
                    </Grid>
                </Grid>

                {convertToRFQProducts && <Grid item md={12} mt={2.5} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "grid" }}>
                        <TabContent tabs={selectedSellerTabs} selectedTab={selectedMainProductId} onChange={(id) => setSelectedProduct(id)} />
                    </Box>
                </Grid>}

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBlock: '1em', width: '100%' }}>
                    <Grid container item md={12}>
                        <Typography fontWeight={400} mt="4px" color="#3A3E50" fontSize={"14px"}>Quantity Requested</Typography>
                        <Box width={120} height={20} ml={1} mr={3}> <TextFieldCounter value={selectedMainProduct?.quantity} changeQuantity={(newQuantity) => updateConvertToRFQProductQuantity(selectedMainProduct, newQuantity)} /></Box>
                        <StyledSelect bgColor="transparent !important" placeholder={'custom_warranty'} items={convertToRFQData?.terms?.warranty_terms ?? []} handleChange={(warrentyId) => handleSelectWarrantyTerms(selectedMainProduct, warrentyId)} value={selectedMainProduct?.warranty_term?.id ?? warranty_term?.id ?? 0} />
                        {selectedMainProduct?.category_kind === ProductCategoryKind.SERVICE && (
                            <Box display={'flex'} alignItems={'center'} ml={3} gap={0.5}>
                                <BuyerRequirementsIndicator additional_requirements={selectedMainProduct.additional_requirements} editable onSave={(newRequirements, popupUtils) => { updateConvertToRFQProductRequirements(selectedMainProduct, newRequirements); popupUtils?.handleClose(); }} />
                                <InfoOutlinedIcon sx={{ color: '#89B1C6', fontSize: '18px' }} />
                            </Box>
                        )}
                    </Grid>
                    <Button
                        type='button'
                        onClick={handleClearSelectedSeller}
                        startIcon={<HighlightOffIcon />}
                        disabled={selectedProduct?.numberOfBundleProductSelectedSellers == 0}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        Clear Selection
                    </Button>
                </div>

                {selectedMainProduct && <><Grid item md={12}>
                    <RequisitionProductWithSellersCard productDetails={selectedMainProduct} selectSellersForProduct={selectSellersForProduct} />
                </Grid>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <div>
                            <Typography variant='overline' color='text.disabled'>
                                {t('buttons.alternatives')} <PrimaryBadge>{selectedAlternativeProducts?.length}</PrimaryBadge>
                            </Typography>
                        </div>
                        <div>
                            <Button
                                type='button'
                                variant='contained'
                                color='secondary'
                                onClick={changesHasMade ? addAlternativeProductsWarningPopup.handleOpen : addAlternativesForSelectedProduct}
                            >
                                {t('buttons.add_alternatives')}
                            </Button>
                        </div>
                    </div>
                    {selectedAlternativeProducts && <>
                        {selectedAlternativeProducts?.map((alternativeProduct, index) => (
                            <Grid item md={12} key={`${alternativeProduct.id}-${index}-${selectedMainProduct?.id}`}>
                                <RequisitionProductWithSellersCard productDetails={alternativeProduct} isAlternative selectSellersForProduct={selectSellersForProduct} />
                            </Grid>
                        ))}
                    </>}
                    {selectedAlternativeProducts?.length === 0 && <Typography fontWeight={400} color="#8B8D98" variant="body1">No Alternatives added for this product</Typography>}
                </>
                }
            </>}
        </>
    )
}

type TabContentProps = {
    tabs: TabModel[] | null
    selectedTab: number
    onChange: (id: number) => void
}
const TabContent: React.FC<TabContentProps> = ({ tabs, selectedTab, onChange }) => {
    const { t } = useTranslation();

    return (
        <StyledTabs tabs={tabs} value={selectedTab} thirdText={" " + t('texts.selected')} secondText={" " + t('texts.sellers')}
            updateTab={(_, newValue) => onChange(newValue)} onChange={() => { }} />
    )
}