import { procurementStatus } from '@/config';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Box, Typography } from '@mui/material';

interface SellerListStatusesProps {
  status: procurementStatus;
}

const mainStatus = [
  procurementStatus.MIXED,
  procurementStatus.EDITING,
  procurementStatus.PO_RECEIVED,
  procurementStatus.RFQ_RECEIVED,
  procurementStatus.DRAFT,
  procurementStatus.PARTIALLY_APPROVED,
  procurementStatus.PARTIALLY_APPROVED_BY_ME,
  procurementStatus.PENDING,
  procurementStatus.QUOTE_GENERATED,
  procurementStatus.PARTIALLY_SENT_TO_SELLERS,
  procurementStatus.READY,
];
const dangerStatus = [
  procurementStatus.REVOKED,
  procurementStatus.DECLINED,
  procurementStatus.REJECTED,
  procurementStatus.REJECTED_BY_ME,
  procurementStatus.CANCELED,
  procurementStatus.RECALLED,
  procurementStatus.PARTIALLY_DECLINED,
];
const warningStatus = [
  procurementStatus.PENDING_APPROVAL,
  procurementStatus.PENDING_MY_APPROVAL,
  procurementStatus.SENT_FOR_APPROVAL,
  procurementStatus.REQUESTED,
  'PENDING_QUOTES',
];
const successStatus = [
  procurementStatus.MULTI_POS_GENERATED,
  procurementStatus.PARTIALLY_QUOTES_RECEIVED,
  procurementStatus.QUOTES_RECEIVED,
  procurementStatus.PO_GENERATED,
  procurementStatus.QUOTE_RECEIVED,
  procurementStatus.PUBLISHED,
  procurementStatus.APPROVED,
  procurementStatus.CONFIRMED,
  procurementStatus.APPROVED_BY_ME,
  procurementStatus.SENT_TO_SELLERS,
  procurementStatus.SENT_TO_SELLER,
  procurementStatus.SENT_TO_BUYER,
  procurementStatus.CONVERTED_TO_RFQ,
  procurementStatus.PARTIALLY_APPROVED,
  procurementStatus.PARTIALLY_SENT_TO_SELLERS,
  procurementStatus.PARTIALLY_CONFIRMED,
];
const statuses = {};
mainStatus.forEach(key => (statuses[key] = 'main'));
dangerStatus.forEach(key => (statuses[key] = 'danger'));
warningStatus.forEach(key => (statuses[key] = 'warning'));
successStatus.forEach(key => (statuses[key] = 'success'));

export const SellerListStatuses: React.VFC<SellerListStatusesProps> = ({ status }) => {
  const { t } = useTranslation();
  const colorStatus =
    statuses[status] == 'danger'
      ? 'rgba(255, 77, 77, 1)'
      : statuses[status] == 'warning'
        ? 'rgba(251, 127, 13, 1)'
        : statuses[status] == 'success'
          ? 'rgba(10, 184, 123, 1)'
          : 'rgba(58, 62, 80, 1)';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: colorStatus,
          marginRight: 1,
        }}
      />
      <Typography
        variant='subtitle2'
        sx={{
          color: colorStatus,
        }}
      >
        {t('chip.' + status)}
      </Typography>
    </Box>
  );
};
