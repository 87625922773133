import create from 'zustand';
import { ReqProducts, Requisition, ReqProduct } from '@/models';

export const useRequisition = create((set, get) => ({
  requisitions: [] as Requisition[],
  draftReqProducts: {
    tabId: 0,
    mainProduct: {},
    altProducts: [],
  },
  created: false as boolean,
  createdMain: false as boolean,
  changingName: false as boolean,
  currentReq: -1 as number,
  clearDraftReq: () => {
    set({
      draftReqProducts: {
        tabId: 0,
        mainProduct: {},
        altProducts: [],
        customTerms: { warranty: -1, changed: false },
      },
    });
  },
  saveReq: (id: number) => {
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].saved = true;
    }
    set({ requisitions: [...requisitions] });
  },
  ChangeGeneralTerms: (id: number, terms) => {
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].generalTerms = { ...terms, changed: true };
    }
    set({ requisitions: [...requisitions] });
  },
  ChangeCustomTerms: (id: number, tabId: number, terms) => {
    let tab = Number(tabId);
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].products = requisitions[reqIndex].products.map(
        pro => {
          if (pro.tabId == tab) {
            return { ...pro, customTerms: { ...terms, changed: true } };
          } else return { ...pro };
        }
      );
    }
    set({ requisitions: [...requisitions] });
  },
  changeReqName: (id: number, name: string) => {
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].reqName = name;
    }
    set({ requisitions: [...requisitions], changingName: true });
  },
  deleteReq: (id: number) => {
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions.splice(reqIndex, 1);
    }
    set({ requisitions: [...requisitions] });
  },
  toggleReq: (
    product: ReqProduct,
    id: number,
    tabId: number,
    toggle: boolean
  ) => {
    let reqId = Number(id);
    let tab = Number(tabId);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].products.map(pro => {
        if (pro.tabId == tab) {
          set({ draftReqProducts: pro });
        }
      });
    } else {
      set({
        draftReqProducts: {
          tabId: 0,
          mainProduct: { ...product, quantity: 0 },
          altProducts: [],
          customTerms: { warranty: -1, changed: false },
        },
      });
    }
    set({ created: toggle });
  },
  toggleReqMainPro: (product: ReqProduct, toggle: boolean, add: string) => {
    if (add == 'add')
      set({
        draftReqProducts: {
          tabId: 0,
          mainProduct: { ...product, quantity: 0 },
          altProducts: [],
          customTerms: { warranty: -1, changed: false },
        },
        createdMain: toggle,
        created: toggle,
      });
    else
      set({
        draftReqProducts: {
          tabId: 0,
          mainProduct: {},
          altProducts: [],
          customTerms: { warranty: -1, changed: false },
        },
        createdMain: toggle,
        created: toggle,
      });
  },
  addProduct: (reqName: string, id: number, tabId: number) => {
    let reqId = Number(id);
    let tab = Number(tabId);
    let requisitions = get().requisitions;
    const d = new Date();
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      requisitions[reqIndex].products.push({
        ...get().draftReqProducts,
        tabId: tab,
      });
    } else if (get().draftReqProducts.mainProduct.name) {
      requisitions.push({
        id: reqId,
        reqName: reqName,
        products: [{ ...get().draftReqProducts, tabId: tab }],
        saved: false,
        creationDate: d.toLocaleDateString('en-US'),
        generalTerms: {
          warranty: -1,
          payment: -1,
          delivery: -1,
          changed: false,
        },
      });
    } else {
      requisitions.push({
        id: reqId,
        reqName: reqName,
        products: [],
        saved: false,
        creationDate: d.toLocaleDateString('en-US'),
        generalTerms: {
          warranty: -1,
          payment: -1,
          delivery: -1,
          changed: false,
        },
      });
    }
    set({
      requisitions: requisitions,
      // draftReqProducts: {
      //   tabId: 0,
      //   mainProduct: {},
      //   altProducts: [],
      //   customTerms: { warranty: -1, changed: false },
      // },
    });
  },
  changeQuantity: (
    reqName: string,
    id: number,
    tabId: number,
    product: ReqProduct,
    productQuantity: number
  ) => {
    let reqId = Number(id);
    let tab = Number(tabId);
    let quantity = productQuantity ? Number(productQuantity) : null;
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex > -1) {
      let req = requisitions.map((requisition: Requisition) => {
        if (requisition.id == reqId) {
          return {
            ...requisition,
            products: requisition.products.map((pro: ReqProducts) => {
              if (
                pro.tabId == tab &&
                pro.mainProduct.partNumber == product.partNumber
              ) {
                pro.mainProduct.quantity = quantity;
                return {
                  ...pro,
                  altProducts: pro.altProducts.map(altP => {
                    altP.quantity = quantity;
                    return altP;
                  }),
                };
              } else if (pro.tabId == tab) {
                return {
                  ...pro,
                  altProducts: pro.altProducts.map(altP => {
                    if (altP.partNumber == product.partNumber) {
                      altP.quantity = quantity;
                      return altP;
                    } else return altP;
                  }),
                };
              } else return pro;
            }),
          };
        } else return requisition;
      });
      set({ requisitions: req });
    }
  },
  createReq: (reqName: string, id: number, tabId: number) => {
    let reqId = Number(id);
    let tab = Number(tabId);
    let requisitions = get().requisitions;
    const d = new Date();
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    if (reqIndex >= 0) {
      let proIndex = requisitions[reqIndex]?.products?.findIndex(
        (product: ReqProduct) => product.tabId == tab
      );
      if (proIndex >= 0) {
        let req = requisitions.map((requisition: Requisition) => {
          if (requisition.id == reqId) {
            return {
              ...requisition,
              products: requisition.products.map(pro => {
                if (pro.tabId == tab) return get().draftReqProducts;
                else return pro;
              }),
            };
          } else return requisition;
        });
        set({
          requisitions: req,
          // draftReqProducts: {
          //   tabId: 0,
          //   mainProduct: {},
          //   altProducts: [],
          //   customTerms: { warranty: -1, changed: false },
          // },
        });
      } else {
        requisitions[reqIndex].products.push(get().draftReqProducts);
        set({
          requisitions: requisitions,
          // draftReqProducts: {
          //   tabId: 0,
          //   mainProduct: {},
          //   altProducts: [],
          //   customTerms: { warranty: -1, changed: false },
          // },
        });
      }
    } else {
      requisitions.push({
        id: id,
        reqName: reqName,
        products: [get().draftReqProducts],
        saved: false,
        creationDate: d.toLocaleDateString('en-US'),
        generalTerms: {
          warranty: -1,
          payment: -1,
          delivery: -1,
          changed: false,
        },
      });
      set({
        requisitions: requisitions,
        currentReq: requisitions.findIndex(el => el.reqName === reqName),
        // draftReqProducts: {
        //   tabId: 0,
        //   mainProduct: {},
        //   altProducts: [],
        //   customTerms: { warranty: -1, changed: false },
        // },
      });
    }
  },
  addAltProduct: (
    product: ReqProduct,
    altProduct: ReqProduct,
    id: number,
    tabId: number
  ) => {
    let tab = Number(tabId);
    let mainQuantity = get().draftReqProducts.mainProduct.quantity;
    set({
      draftReqProducts: {
        tabId: tab,
        mainProduct: { ...product, quantity: mainQuantity ? mainQuantity : 0 },
        altProducts: [
          ...get().draftReqProducts?.altProducts,
          { ...altProduct, quantity: mainQuantity ? mainQuantity : 0 },
        ],
        customTerms: { warranty: -1, changed: false },
      },
    });
  },
  removeAltProduct: (
    product: ReqProduct,
    altProduct: ReqProduct,
    id: number,
    tabId: number
  ) => {
    let tab = Number(tabId);
    let draftRequisition = get().draftReqProducts;
    let mainQuantity = draftRequisition.mainProduct.quantity;
    let productIndex = draftRequisition?.altProducts.findIndex(
      (pro: ReqProduct) => pro.partNumber == altProduct.partNumber
    );
    draftRequisition?.altProducts.splice(productIndex, 1);
    set({
      draftReqProducts: {
        tabId: tab,
        mainProduct: { ...product, quantity: mainQuantity ? mainQuantity : 0 },
        altProducts: draftRequisition?.altProducts,
      },
    });
  },
  removeProduct: (productToRemove: ReqProduct, id: number, tabId: number) => {
    let tab = Number(tabId);
    let reqId = Number(id);
    let requisitions = get().requisitions;
    let reqIndex = requisitions.findIndex(
      (requisition: Requisition) => requisition.id == reqId
    );
    requisitions[reqIndex].products = requisitions[reqIndex].products.map(
      (product: ReqProducts) => {
        if (product.tabId == tab) {
          let productIndex = product.altProducts.findIndex(
            pro => pro.partNumber == productToRemove.partNumber
          );
          productIndex > -1 && product.altProducts.splice(productIndex, 1);
          return product;
        } else return product;
      }
    );
    requisitions[reqIndex].products = requisitions[reqIndex].products.filter(
      (product: ReqProducts) =>
        product.tabId == tab
          ? product.mainProduct.partNumber != productToRemove.partNumber
          : true
    );
    // if (requisitions[reqIndex].products.length == 0)
    //   requisitions.splice(reqIndex, 1);
    set({ requisitions: [...requisitions] });
  },
}));
