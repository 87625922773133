import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { SearchTextField } from '../text-field/search-text-field';
import { Column, Table as ReactTable, } from '@tanstack/react-table'

export const Filter = ({
    column,
    table,
}: {
    column: Column<any, any>
    table: ReactTable<any>
}) => {
    const { t } = useTranslation();
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const options = React.useMemo(() => {
        const options = new Set()
        table.getPreFilteredRowModel().flatRows.forEach(row => {
            if (column.columnDef.meta.filterType == 'select' && row?.getValue(column.id)) {
                if (Array.isArray(firstValue)) row?.getValue(column.id).map(value => !options.has(value) && options.add(value))
                else !options.has(row?.getValue(column.id)) && options.add(row?.getValue(column.id))
            }
        })
        return [...options.values()]
    }, [column.columnDef.meta.filterType == 'select'])


    const columnFilterValue = column.getFilterValue()
    return column.columnDef.meta.filterType == 'select' ? (
        <Select
            displayEmpty
            size='small'
            value={(columnFilterValue ? columnFilterValue : '') as string}
            onChange={e => column.setFilterValue(e.target.value)}
            MenuProps={{ style: { zIndex: 0, maxHeight: 300, maxWidth: 190, position: 'absolute' }, disableScrollLock: true }}
            inputProps={{ sx: { height: '25px',marginTop: '5px' } }}
            sx={{
                minWidth: '100%',
                width: '100%',
                maxWidth: '100%',
                height: '25px',marginTop: '-1px' 
            }}
        // {...field}
        >
            <MenuItem value='' sx={{ fontSize: '12px !important' }}>
                {t('texts.all')}
            </MenuItem>
            {options.map((option, i) => (
                <MenuItem key={i} value={option}>
                    <Typography variant='subtitle2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {t(column.id + '.' + option)}
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    ) : (
        <Box sx={{ height: '25px', width: '100%' }}>
            <SearchTextField
                translateKey='search'
                value={(columnFilterValue ?? '') as string}
                onChange={e => column.setFilterValue(e.target.value)}
                startAdornment={true}
            />
        </Box>
    )
}