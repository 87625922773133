import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';

export const validationSchema = [
  yup.object({
    email: yup
      .string()
      .required('required')
      .email('email')
  }),
  yup.object({
    verificationCode: yup.string().required('required'),
  }),
  yup.object({
    password: yup
      .string()
      .required('required')
      .min(8, 'min8')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'small_big')
      .matches(/^(?=.*[0-9])/, 'letter_digit'),
    password_confirmation: yup
      .string()
      .required('required')
      .oneOf([yup.ref('password')], 'matches_password'),
  }),
];
