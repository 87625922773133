import { procurementStatus } from '@/config';
import { Chip } from '@mui/material';
import { StatusChip } from '../../dumb';
import { useTranslation } from 'react-i18next';

type FormChipsProps = {
  formStatus: procurementStatus | undefined;
  extensionRequestStatus: null | string | undefined;
  isFormExpired: boolean | undefined;
  approvers?: {
    approver_id: number;
    status: string;
    user_name: string;
  }[];
};

export const FormChips: React.FC<FormChipsProps> = ({
  formStatus,
  extensionRequestStatus,
  isFormExpired,
  approvers,
}) => {
  const showFormStatusChip = IfShowStatusChip(formStatus, extensionRequestStatus);
  const { t } = useTranslation();

  return (
    <>
      {showFormStatusChip && (
        <StatusChip status={formStatus} title={t('texts.approvers')} listOfApprovers={approvers} />
      )}
      {showFormStatusChip == false ? (
        extensionRequestStatus ? (
          isFormExpired && extensionRequestStatus === procurementStatus.DECLINED ? (
            <Chip
              label={t('notifications.request_extension_declined')}
              variant={'filled'}
              sx={{
                minWidth: '144px',
                color: 'rgba(255, 77, 77, 1)',
                bgcolor: 'white',
                border: `1px solid rgba(255, 77, 77, 1)`,
              }}
            />
          ) : isFormExpired && extensionRequestStatus === procurementStatus.PENDING ? (
            <Chip
              label={t('notifications.extension_requested')}
              variant={'filled'}
              sx={{
                minWidth: '144px',
                color: 'rgba(251, 127, 13, 1)',
                bgcolor: 'white',
                border: `1px solid rgba(251, 127, 13, 1)`,
              }}
            />
          ) : null
        ) : isFormExpired ? (
          <Chip
            label={t('chip.EXPIRED')}
            variant={'filled'}
            sx={{
              minWidth: '144px',
              color: 'rgba(255, 77, 77, 1)',
              bgcolor: 'white',
              border: `1px solid rgba(255, 77, 77, 1)`,
            }}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

function IfShowStatusChip(
  status: procurementStatus | undefined,
  extensionRequestStatus: string | null | undefined
) {
  const allowedStatuses = [
    procurementStatus.RECALLED,
    procurementStatus.REJECTED_BY_ME,
    procurementStatus.APPROVED_BY_ME,
    procurementStatus.PENDING_MY_APPROVAL,
    procurementStatus.REVOKED
  ];

  let ifShowStatus = allowedStatuses.includes(status);

  if (status === procurementStatus.DECLINED) {
    ifShowStatus =
      (extensionRequestStatus !== procurementStatus.DECLINED || extensionRequestStatus === null) &&
      (extensionRequestStatus !== procurementStatus.PENDING || extensionRequestStatus === null);
  }
  

  return ifShowStatus;
}
