import React from 'react';
import { sm } from 'src/site-map';
import { Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { ColumnDef } from '@tanstack/react-table';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { makeStyles } from "@mui/styles";
import { Link, StatusChip, StyledTable } from '@/components';
import CircleIcon from '@mui/icons-material/Circle';
import { RFQTableRowModel } from "@/models";
import { RFQTableListOfActions } from '@/config';
import { useRouter } from "next/router";


type RFQTablePageForSellerProps = {
    key: string
    rowsData: RFQTableRowModel[] | undefined
}


const useStyles = makeStyles({
    cellHead: {},
    styleColumn: {},
});


export const RFQTablePageForSeller: React.FC<RFQTablePageForSellerProps> = ({ key, rowsData }) => {

    const classes = useStyles()
    const columnObjects = [
        {
            accessorKey: 'name',
            header: 'rfq',
            cell: info => <TableRFQText content={info.row.original} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        },
        {
            accessorKey: 'company_details',
            header: 'buyer',
            cell: info => <TableText title={info.getValue().name} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'project',
            header: 'project',
            cell: info => <TableText title={info.getValue().name} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'created_at',
            header: 'date',
            cell: info => <TableDate date={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        },
        {
            accessorKey: 'number_of_products',
            header: 'products',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'number_of_competitors',
            header: 'competitors',
            cell: info => <TableText title={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'status',
            header: 'status',
            cell: info => <TableChip status={info.getValue()} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false
        },
        {
            accessorKey: 'list_actions',
            header: 'actions',
            cell: info => <TableButton listOfActions={info.getValue()} RRQId={info.row.original.id} quoteId={info.row.original.quote?.id} />,
            meta: {
                filterType: 'search',
                columnIcon: UnfoldMoreIcon,
                headerCellClass: classes.cellHead,
                tableRow: {
                    cellClass: classes.styleColumn
                }
            },
            enableColumnFilter: false,
        }
    ]

    const columns = React.useMemo<ColumnDef<RFQTableRowModel>[]>(
        () => columnObjects,
        []
    )

    return (
        <>
            <StyledTable key={key} rows={rowsData} columns={columns} />
        </>
    )
}

const TableText = ({ title }) => {
    return (
        <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
            <Typography variant='subtitle2' fontWeight={400}>{title}</Typography>
        </Grid>
    )
}

const TableChip = ({ status }) => {
    return (<StatusChip status={status} />)
}

const TableRFQText = ({ content }) => {
    return (
        <Grid container width='100%'>
            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
                {content.has_new_changes && <CircleIcon sx={{ color: 'success.dark', fontSize: '14px' }} />}
                <Link underline='hover' href={sm.portals.seller.procurement.requestForQuotes.requestForQuote(content.id).url}>
                    <Typography variant='body2' sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {content.name}
                    </Typography>
                </Link>
            </Grid>
            <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{content?.bundle_name} </span>
        </Grid>)
}

const TableButton = ({ listOfActions, RRQId, quoteId }) => {
    const { t } = useTranslation()
    const { push } = useRouter()
    const handleClickTableButton = (action: RFQTableListOfActions) => {
        if (action == RFQTableListOfActions.GENERATE_QUOTE) push(sm.portals.seller.procurement.requestForQuotes.requestForQuote(RRQId).url)
        // if (action == RFQTableListOfActions.SEND_FOR_APPROVAL) push(sm.portals.seller.procurement.quotes.quoteId(quoteId).url)
    }

    return (
        <>
            {listOfActions?.map((action: RFQTableListOfActions) =>
                <Grid container gap={1}>
                    <Button><Typography variant="subtitle2" fontWeight={700} onClick={() => handleClickTableButton(action)}>
                        {t('buttons.' + action)}</Typography>
                    </Button>
                </Grid>
            )}
        </>
    )
}

const TableDate = ({ date }) => {
    const mainCharacter = '-';
    const replaceWith = '/';
    const updatedDate = date.split(mainCharacter).join(replaceWith);

    return (
        <Typography variant='subtitle2' fontWeight={400}>  {updatedDate}   </Typography>
    )
}
