import { useUserData } from '@/zustand';
import { Page } from './types';
import React from 'react';
import NotificationItem from 'src/logic/models/notification';

export const useUserId = (): number | undefined => {
  const { userInfo } = useUserData();
  let userId: number | undefined;
  if (userInfo && userInfo['id']) {
    userId = userInfo['id'];
  }

  return userId;
};

export const receiveNewNotification = (
  newItem: NotificationItem,
  pages: undefined | Page[]
) => {
  if (!pages) return [newItem];
  return pages?.map(page => ({
    ...page,
    total_unread_notifications: page.total_unread_notifications + 1,
    data: {
      ...page.data,
      data: [newItem, ...page.data.data],
    },
  }));
};

export const MarkAsReadPagesUpdater = (id: string, pages: undefined | Page[]) =>
  pages?.map(page => ({
    ...page,
    total_unread_notifications: page.total_unread_notifications - 1,
    data: {
      ...page.data,
      data: page.data.data.map(item =>
        item.id !== id ? item : { ...item, read: true }
      ),
    },
  }));

export const MarkAllAsReadPagesUpdater = (pages: undefined | Page[]) =>
  pages?.map(page => ({
    ...page,
    total_unread_notifications: 0,
    data: {
      ...page.data,
      data: page.data.data.map(item => ({ ...item, read: true })),
    },
  }));

export const useNotificationAudioPlayer = () => {
  return React.useRef<HTMLAudioElement | undefined>(
    typeof Audio !== 'undefined'
      ? new Audio('/assets/sounds/notification-sound.mp3')
      : undefined
  );
};
