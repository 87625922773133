import React from 'react';
import { useProcurementStore } from '@/zustand';
import { ProductCategoryKind } from '@/models';

const useNumberOfEnviornmentItems = () => {
  const products = useProcurementStore(state => state.productFormManagerProperties.data.products);

  return React.useMemo(() => {
    const totalNumber = products.length;
    let productsNumber = 0;
    let servicesNumber = 0;
    products.forEach(prod => {
      if (prod.category_kind === ProductCategoryKind.PRODUCT) productsNumber++;
      if (prod.category_kind === ProductCategoryKind.SERVICE) servicesNumber++;
    });

    // const productsNumber = products.filter(prod => prod.category_kind === ProductCategoryKind.PRODUCT).length;
    // const servicesNumber = products.filter(prod => prod.category_kind === ProductCategoryKind.SERVICE).length;

    return {
      totalNumber,
      productsNumber,
      servicesNumber,
    };
  }, [products]);
};

export default useNumberOfEnviornmentItems;
