import { Seller } from '@/models';
import produce from 'immer';
import { SellerCompany, Location } from 'src/logic/models/catalogue/LinkOrCreateSellerProps';
import { getALLExistingSellers, getLocations, getSellerCompanies } from 'src/logic/services/catalogue/private-seller';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import create from 'zustand';

export interface LinkOrCreateSellersSlice {
  properties: {
    fetchQueryStatus: QueryHandlerModel;
    fetchSellersQueryStatus: QueryHandlerModel;
    sellerCompaniesData: SellerCompany[] | null;
    locations: Location[] | null;
    existingSellers: Seller[] | null;
  };

  actions: {
    setFetchQueryStatus: (queryHandler: QueryHandlerModel) => void;
    setFetchSellerQueryStatus: (queryHandler: QueryHandlerModel) => void;
    getCompaniesData: (productId: number, name: string) => void;
    getAllLocations: () => void;
    getAllExistingSellers: (productId: number) => void;
  };
}

export const useLinkOrCreateSellersSlice = create<LinkOrCreateSellersSlice>((set, get) => ({
  properties: {
    fetchQueryStatus: QueryHandler.getInitialStatus(),
    fetchSellersQueryStatus: QueryHandler.getInitialStatus(),
    sellerCompaniesData: null,
    locations: null,
    existingSellers: null,
  },
  actions: {
    setFetchQueryStatus: (queryHandler: QueryHandlerModel) => {
      set(
        produce<LinkOrCreateSellersSlice>(draftState => {
          draftState.properties.fetchQueryStatus = queryHandler;
        })
      );
    },
    setFetchSellerQueryStatus: (queryHandler: QueryHandlerModel) => {
      set(
        produce<LinkOrCreateSellersSlice>(draftState => {
          draftState.properties.fetchSellersQueryStatus = queryHandler;
        })
      );
    },
    getCompaniesData: (productId: number, name: string) => {
      if (!name) return;
      else {
        get().actions.setFetchSellerQueryStatus(QueryHandler.getStartStatus());

        getSellerCompanies(productId, name)
          .then(data => {
            set(
              produce<LinkOrCreateSellersSlice>(draftState => {
                draftState.properties.sellerCompaniesData = data.data;
              })
            );
            get().actions.setFetchSellerQueryStatus(QueryHandler.getSuccessStatus());
          })
          .catch(err => {
            get().actions.setFetchSellerQueryStatus(QueryHandler.getErrorStatus(err));
          });
      }
    },
    getAllLocations: () => {
      get().actions.setFetchQueryStatus(QueryHandler.getStartStatus());
      getLocations()
        .then(data => {
          set(
            produce<LinkOrCreateSellersSlice>(draftState => {
              draftState.properties.locations = data.data.locations;
            })
          );
          get().actions.setFetchQueryStatus(QueryHandler.getSuccessStatus());
        })
        .catch(err => {
          get().actions.setFetchQueryStatus(QueryHandler.getErrorStatus(err));
        });
    },
    getAllExistingSellers: (productId: number) => {
      get().actions.setFetchQueryStatus(QueryHandler.getStartStatus());
      getALLExistingSellers(productId)
        .then(data => {
          set(
            produce<LinkOrCreateSellersSlice>(draftState => {
              draftState.properties.existingSellers = data.data;
            })
          );
          get().actions.setFetchQueryStatus(QueryHandler.getSuccessStatus());
        })
        .catch(err => {
          get().actions.setFetchQueryStatus(QueryHandler.getErrorStatus(err));
        });
    },
  },
}));
