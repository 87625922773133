import { useMutation } from '@tanstack/react-query'
import { apiRoutes } from '@/config'
import { request } from '@/helpers'

const postQuestion = async (formData) => {
    return request({
        url: apiRoutes.contactUs,
        method: "POST",
        data: formData
    })
}

export const useContactUs = ({ onSuccess, onError }) => {
    return useMutation((formData) => postQuestion(formData), { onSuccess: onSuccess, onError: onError })
}
