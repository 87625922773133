import { apiRoutes } from '@/config';
import { request } from '@/helpers';
import { useQuery } from '@tanstack/react-query';

export const useGetEditableCatalogs = () => {
  return useQuery({
    queryKey: ['EDITABLE_CATALOGS'],
    queryFn: async () => {
      const response = await request({
        url: apiRoutes.catalogEditableIndex,
        method: 'GET',
      });
      return response.data;
    },
    retry: 0,
  });
};
