import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment from 'moment';
import React, { useState } from 'react';
import { createTheme, Grid, ThemeProvider, TextField, IconButton, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export type FilterByDateTypeProps = {
    label: string
    type: string
    kind: string
    onFilterChanged: (type: string, kind: string, value: any) => void
};


export const FilterByDateType: React.FC<FilterByDateTypeProps> = ({ label, type, kind, onFilterChanged }) => {
    const theme = createTheme({
        components: {
            MuiPickersDay: {
                styleOverrides: {
                    day: {
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
        },
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarOpen, setCalendarOpen] = useState(false);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);

        if (moment(date).isValid()) {
            const formattedDate = moment(date).format("DD-MM-YYYY");
            onFilterChanged(type, kind, formattedDate);
        }
    };

    const handleClearSelection = () => {
        setCalendarOpen(false)
        setSelectedDate(null);
        onFilterChanged(type, kind, null);
    };

    const handleOpenCalendar = () => setCalendarOpen(true)

    return (
        <Grid item md={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        inputFormat="DD/MM/YYYY"
                        open={calendarOpen}
                        onClose={() => setCalendarOpen(false)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={selectedDate}
                                variant="standard"
                                label={label}
                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                InputProps={{
                                    style: { cursor: 'pointer' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {selectedDate && (
                                                <IconButton onClick={(e) => { e.stopPropagation(); handleClearSelection(); }} style={{ cursor: 'pointer' }}>
                                                    <ClearIcon sx={{ fontSize: '20px' }} />
                                                </IconButton>
                                            )}
                                            <IconButton onClick={handleOpenCalendar} style={{ cursor: 'pointer' }}>
                                                <CalendarMonthIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '100%',
                                    '& label': { color: 'text.secondary' },
                                    '& fieldset': { borderColor: 'text.secondary' },
                                    '&:focus': {
                                        '& label': { color: 'text.secondary' },
                                        '& fieldset': { borderColor: 'text.secondary' },
                                    },
                                }}
                            />
                        )}
                        PopperProps={{ style: { paddingLeft: 80 } }}
                    />
                </ThemeProvider>
            </LocalizationProvider>
        </Grid>
    );
}

