import React, { FC, useRef } from 'react';
import { ClickAwayListener, Paper, Popper } from '@mui/material';

interface StyledPopperProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  children?: any;
  place?: string;
  zIndex?: number;
  id?: string;
}

export const StyledPopper: FC<StyledPopperProps> = (props: StyledPopperProps) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const onClickAwayCallBack = (event: MouseEvent | TouchEvent) => {
    if (event.target.localName === 'body') {
      return;
    }
    props.handleClose();
  };

  // const handleMouseLeave = () => {
  //     if (popupRef.current && !popupRef.current.contains(document.activeElement)) {
  //         props.handleClose();
  //     }
  // };

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      sx={{ zIndex: props.zIndex ?? 1 }}
      placement={props.place ? props.place : 'bottom'}
      id={props.id}
    >
      <Paper ref={popupRef} sx={{ width: '100%', height: '100%', borderRadius: '8px', mt: 1 }}>
        <ClickAwayListener onClickAway={onClickAwayCallBack}>{props.children}</ClickAwayListener>
      </Paper>
    </Popper>
  );
};
