import { apiRoutes } from "@/config"
import { request } from "@/helpers"
import { ChildApprovalChange } from ".."

export const useGetBundleRFQDetails = (bundleRFQId: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRFQId, method: "GET" })
}

export const useGetBundleRFQDetailsForEditing = (bundleRFQId: number, preview_selected_products: null | any) => {
    return request({ url: `/request-for-quote/bundle-rfq/${bundleRFQId}/edit`, method: "POST", data: { preview_selected_products } })
}

export const useSaveBundleRfqChanges = (bundleRFQId: number, data: any) => {
    return request({ url: `/request-for-quote/bundle-rfq/${bundleRFQId}/save`, method: "PUT", data })
}

export const useGetBundleRFQComments = (bundleRFQId: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRFQId + apiRoutes.comments, method: "GET" })
}

export const useRegenerateRFQFromRFQDetails = (bundleRFQID: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRFQID + apiRoutes.edit, method: 'POST' })
}

export const useSendForApproval = (bundleRfqId: number, optionalComment: string) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.sendForApproval, method: "POST", data: { comment: optionalComment } })
}
export const useCancelSentBundleRFQForApprovals = (bundleRfqId: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.cancelSendingBundleRFQToApprovals, method: "PUT" })
}
export const useApproveAllRFQs = (bundleRfqId: number, comment: string | null) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.approve, method: "PUT", data: { comment: comment ?? null } })
}
export const useRejectAllRFQs = (bundleRfqId: number, comment: string | null, reasonId: string | number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.reject, method: "PUT", data: { comment: comment ?? null, reason_id: reasonId } })
}

export const useDeleteBundleRFQ = (bundleRfqId: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId, method: "DELETE" })
}

export const useSendBundleToAllSellers = (bundleRfqId: number, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.sendToAllSellers, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}

export const useCancelBundleRFQ = (bundleRfqId: number, reasonId: string, is_external: boolean, optionalComment?: string | null) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRfqId + apiRoutes.cancel, method: "PUT", data: { reason_id: reasonId, is_external: !is_external, comment: optionalComment ?? null } })
}

export const useFetchRejectReasons = () => {
    return request({ url: apiRoutes.getBundleRFQ + apiRoutes.rejectReasons, method: 'GET' })
}

export const useFetchCancelBundleReasons = () => {
    return request({ url: apiRoutes.getBundleRFQ + apiRoutes.cancelReasons, method: 'GET' })
}

export const useBundleRFQBulkApproval = (bundleRFQId: number, changes: ChildApprovalChange[]) => {
    const approval = changes.map(change => ({
        rfq_id: change.id,
        type: change.type,
        comment: change.comment,
        rejection_reason_id: change.reasonId,
    }))
    return request({ url: apiRoutes.getBundleRFQ + bundleRFQId + apiRoutes.rfqsApproval, method: "PUT", data: { rfq_approvals: approval } })
}