import { Grid, Button, useMediaQuery } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { HeroImageSection, HeroTextSection, Link } from '@/components';
import { useTranslation } from 'react-i18next';
import { getHomeTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';

type Props = {};

export const HeroHomeSection: React.FC<Props> = ({ }) => {
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <Grid container>
      <HeroTextSection
        isSmallScreen={smallScreens}
        ShowBreadcrumbs={false}
        title={t(getHomeTranslationKey('titles.intro'))}
        subTitle={t(getHomeTranslationKey('sub_titles.intro'))}
        secondSubTitle={t(getHomeTranslationKey('second_sub_title.intro'))}
        withBlueBackgroung={true}
      >
        <Button
          variant='contained'
          component={Link}
          href={{ pathname: sm.register.index.url }}
          sx={{
            width: smallScreens ? '100%' : '176px',
            color: 'secondary.contrastText',
          }}
        >
          {t('buttons.register')}
        </Button>
        <Button
          sx={{
            width: smallScreens ? '100%' : '176px',
            borderColor: 'primary.main',
          }}
          variant='outlined'
          endIcon={<PlayCircleOutlineIcon sx={{ height: 24 }} />}
        >
          {t('buttons.watch_video')}
        </Button>
      </HeroTextSection>
      <HeroImageSection imageSrc='/assets/images/home/hand-shake.png' />
    </Grid>
  );
};
