import React from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import {
  AutoComplete,
  StyledTextField,
  FakeStyledTextField,
  SwitchWithLabel,
  UploadBox,
  CheckboxWithLabel,
  Link,
  PhoneInputField,
} from '@/components';
import { useTranslation } from 'next-i18next';
import { company, city, industry, country } from '@/components';
import { httpConfig } from '@/config';
import { getRegisterTranslationKey,getInputsTranslationKey } from '@/hooks'
import { LoadingButton } from '@mui/lab';

const gridItemStyle = {
  pr: 2,
  pb: 2,
};

type AllValues = {
  first_name: string,
  last_name: string,
  title: string,
  job_title: string,
  mobile_number: string,
  direct_number: string,
  email: string,
  password: string,
  password_confirmation: string,
  company_type: string,
  existed_company_id: string,
  company_industry_id: string,
  company_name: string,
  company_city_id: string,
  company_country_id: string,
  company_address: string,
  trn_number: string,
  work_number: string,
  fax_number: string,
  is_company_admin: boolean,
  terms: boolean,
  trade_license: null,
  vat_certificate: null
}


interface companyDataFormProps {
  isBuyer?: boolean;
  onBack: Function;
  upload: boolean;
  toggleUpload: Function;
  requestAdminRoles: boolean;
  toggleRequestAdminRoles: Function;
  setCountry: Function;
  setCompany: Function;
  getCities: Array<city>;
  companies: Array<city>;
  industries: Array<industry>;
  countries: Array<country>;
  company: company;
  getSelectedCompanyIndustry: Function;
  getSelectedCompanyCountry: Function;
  getSelectedCompanyCity: Function;
  searchCompanies: Function;
  disabledButton: boolean;
  setFieldValue: Function;
  FieldsValues: AllValues;
  company_Id_Invitation?:number;
  company_Name_Invitation?:string;
  company_Country_Invitation?:string;
  company_City_Invitation?:string;
  company_Industry_Invitation?:string;
  company_Address_Invitation?:string;
  company_Fax_Invitation?:number;       
  company_Trn_Invitation?:number;
  company_WorkNumber_Invitation?:number;
  isLoading?: boolean
}

export const CompanyDataForm: React.FC<companyDataFormProps> = (
  props: companyDataFormProps
) => {
  const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
  const { t } = useTranslation();
 
  const onCountryChange = (event, value) => {
    props.setCountry(value);
  };
  const onCompanyChange = (event, value) => {
    props.setCompany(value);
  };

  
  return (
    <>
    <Grid container xs={12} sx={{ pt: 2, display: 'flex' }}>
      <Grid item xs={12} sx={gridItemStyle}>
        <Typography variant='h5' sx={{ fontWeight: 'bolder' }}>
          {' '}
          {t(getRegisterTranslationKey('headers.company_data'))}{' '}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={gridItemStyle}>
          { props.company_Id_Invitation === null ? (<AutoComplete
            freeSolo='company_name'
            translateKey='company.name'
            name='existed_company_id'
            required
            items={props.companies}
            onSearch={event => {
              props.searchCompanies(event.target.value, props.isBuyer)
              props.setCompany(null);
              props.setFieldValue('existed_company_id', '')
            }}
            onChange={onCompanyChange}
          />) : 
          (<FakeStyledTextField
                required
                translateKey='company.name'
                text={props.company_Name_Invitation}
          />)
      }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        { props.company_Id_Invitation === null ? props.company != null ? (
            <FakeStyledTextField
              required
              translateKey='company.industry'
              text={props.getSelectedCompanyIndustry()}
            />
          ) : (
          <AutoComplete
            required
            translateKey='company.industry'
            name='company_industry_id'
            items={props.industries}
          />
          ): (<FakeStyledTextField
                required
                translateKey='company.industry'
                text={props.company_Industry_Invitation}
          />)
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        { props.company_Id_Invitation === null ? props.company != null ? (
          <FakeStyledTextField
            required
            translateKey='company.country'
            text={props.getSelectedCompanyCountry()}
            />
          ) : (
          <AutoComplete
            required
            translateKey='company.country'
            name='company_country_id'
            items={props.countries}
            onChange={onCountryChange}
          />
          ) : (<FakeStyledTextField
                required
                translateKey='company.country'
                text={props.company_Country_Invitation}
          />)
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        { props.company_Id_Invitation === null ? props.company != null ? (
          <FakeStyledTextField
            required
            translateKey='company.city'
            text={props.getSelectedCompanyCity()}
          />
          ) : (
            <AutoComplete
              required
              translateKey='company.city'
              name='company_city_id'
              items={props.getCities()}
            />
          ): (<FakeStyledTextField
                required
                translateKey='company.city'
                text={props.company_City_Invitation}
          />)
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
          { props.company_Id_Invitation === null ? props.company != null ? (
            <FakeStyledTextField
              required
              translateKey='company.address'
              text={props.company.company_address}
            />
            ) : (
              <StyledTextField showLabel
                required
                translateKey='company.address'
                name='company_address'
              />
            ) : (<FakeStyledTextField
                  required
                  translateKey='company.address'
                  text={props.company_Address_Invitation}
            />)
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
          { props.company_Id_Invitation === null ? props.company != null ? (
          <FakeStyledTextField
            translateKey='company.trn'
            text={props.company.trn_number}
          />
          ) : (
            <StyledTextField showLabel translateKey='company.trn' name='trn_number' />
          ) : (<FakeStyledTextField
                required
                translateKey='company.trn'
                text={props.company_Trn_Invitation}
            />)
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        { props.company_Id_Invitation === null ? props.company != null ? (
          <FakeStyledTextField
            translateKey='company.fax'
            text={props.company.fax_number}
          />
          ) : (
            <PhoneInputField name='fax_number' label='Fax Number' />
          ) : (<FakeStyledTextField
                required
                translateKey='company.fax'
                text={props.company_Fax_Invitation}
            />) 
        }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        { props.company_Id_Invitation === null ? props.company != null ? (
          <FakeStyledTextField
            translateKey='company.work_phone'
            text={props.company.work_number}
          />
        ) : (
          <PhoneInputField name='work_number' label='Work Phone Number' />
        ): (<FakeStyledTextField
              translateKey='company.work_phone'
              text={props.company_WorkNumber_Invitation}
          />) }
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <SwitchWithLabel
          disabled={typeof props.FieldsValues.existed_company_id != 'number'}
          name='is_company_admin'
          withStyling
          onSwitch={props.toggleRequestAdminRoles}
          checked={props.requestAdminRoles}
        >
          <Typography
            component='label'
            color='text.first'
            fontWeight='600'
            variant='h6'
            sx={{ display: 'inline' }}
          >
            {' '}
            {t(getInputsTranslationKey('placeholders.company.request_admin_role'))}{' '}
          </Typography>
        </SwitchWithLabel>
      </Grid>
      
      {props.company_Id_Invitation > 0 || props.requestAdminRoles && 
        (typeof props.FieldsValues.existed_company_id != 'number') 
        && (
        <>
          <Grid item xs={12} md={12} sx={{ pr: 1, pt: 3 }}>
            <Typography variant='h5' sx={{ fontWeight: 'bolder' }}>
              {' '}
              {t(getRegisterTranslationKey('headers.upload_documents'))}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={gridItemStyle}>
            <Typography
              component='label'
              color='text.first'
              fontWeight='400'
              variant='body2'
              sx={{ display: 'inline' }}
            >
              {' '}
              {t(getRegisterTranslationKey('paragraphs.upload_documents'))}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={gridItemStyle}>
            <UploadBox name='vat_certificate' translateKey='company.vat' />
          </Grid>
          <Grid item xs={12} md={6} sx={gridItemStyle}>
            <UploadBox name='trade_license' translateKey='company.trade' />
          </Grid>
        </>
      )}

      <Grid item xs={12} md={12} sx={{ pr: 1, py: 3}}>
        <CheckboxWithLabel translateKey='company.terms' name='terms'>
          <Typography
            component='label'
            color='text.first'
            fontWeight='400'
            variant='body2'
            sx={{ display: 'inline'  }}
          >
            {t(getRegisterTranslationKey('paragraphs.terms_agree_first'))}
            <Link href={`${httpConfig.domain}/terms_conditions`} target="_blank">{t(getRegisterTranslationKey('paragraphs.terms'))}</Link> and
            <Link href={`${httpConfig.domain}/privacy_policy`} target="_blank">{t(getRegisterTranslationKey('paragraphs.terms_agree_second'))}</Link>
          </Typography>
        </CheckboxWithLabel>
      </Grid>

      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <Button
          variant='outlined'
          onClick={() => props.onBack()}
          sx={{
            float: 'left',
            width: isMobile ? '110px' : '146px',
          }}
          startIcon={<ArrowBack />}
        >
          {' '}
          {t('buttons.back')}{' '}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sx={gridItemStyle}>
        <LoadingButton
          variant='contained'
          type='submit'
          disabled={props.disabledButton}
          loading={props.isLoading}
          sx={{
            float: 'right',
            width: isMobile ? '100%' : '230px',
          }}
          endIcon={<ArrowForward />}
        >
          {' '}
          {t(
            props.isBuyer
              ? 'buttons.register_as_buyer'
              : 'buttons.register_as_seller'
          )}{' '}
        </LoadingButton>
      </Grid>
    </Grid>
    </>
  );
};
