import { ProductTableRow, ProcurementFormModel } from "@/models"
import { Grid, Typography, Button } from "@mui/material"
import { useTranslation } from 'next-i18next';
import { FC } from "react"
import { FormProductsTable, GeneralTermsButton, IndividualProductsTable, TotalSection, UpdateGeneralTerms } from "@/components"
import { useProcurementStore } from "@/zustand";
import { FieldArray, Formik } from "formik";
import * as yup from 'yup'
import { ProcurementFormPermission } from "@/services";
import PrimaryBadge from "src/ui/components/dumb/shared/primary-badge";
import { ProcurementForms, productManagerEnv } from "@/config";
import { sm } from "src/site-map";
import { useRouter } from "next/router";
import GeneralTerms from "src/ui/components/smart/general-terms/GeneralTerms";

interface PurchaseOrderDetailsType {
    purchaseOrder: ProcurementFormModel | null
    productsTableRows: ProductTableRow
    bundle?: boolean
    summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
    isLastPoInBundle?: boolean
    sendActionIsRequested: boolean // Approval or Seller
}

export const SinglePurchaseOrderDetails: FC<PurchaseOrderDetailsType> = ({ purchaseOrder, productsTableRows, bundle, summaryDetails, isLastPoInBundle = false, sendActionIsRequested }) => {
    const { t } = useTranslation();
    const purchaseOrderModel = purchaseOrder?.model

    const { terms, isTermsValid, isIndividualForm } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceProperties)
    const { selectGeneralTerms } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions)
    const handleSelectGeneralTerms = (values: any) => {
        selectGeneralTerms(values)
    }


    const initialValues = productsTableRows
    const validationSchema = yup.array().of(yup.object({
        availableQuantity: yup.number().when("productDetails.selected", {
            is: true,
            then: yup.number().required('required')
        }),
        unitPrice: yup.string().when('productDetails.selected', {
            is: true,
            then: yup.string().matches(/^\d+$/, 'required').required('required'),
        })
    }))
    const handleSubmit = (values, { setErrors, setFieldError }) => {
        console.log(values)
    }

    const { push } = useRouter();
    const { fetchDetails } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions)
    const { setDetails } = useProcurementStore(state => state.productFormManagerProperties.actions)
    const handleAddProducts = () => {
        if(purchaseOrder?.model?.created_from_scratch == true) { // Created from scratch
            fetchDetails(purchaseOrder?.model.id, (po) => {
                setDetails(po, productManagerEnv.purchase_order)
                // push({ pathname: sm.catalogue.index.url, query: { 'seller-id': purchaseOrder?.model.seller_details?.id } })
                push({ pathname: sm.catalogue.sellers.sellerId(`${purchaseOrder?.model.seller_details?.id}`).url })
            })
        }
        else if(purchaseOrder?.model?.comparison_table) { // Created from comparison table
            push({
                pathname: sm.portals.buyer.procurement.comparisonTables.ctId(purchaseOrder?.model?.comparison_table?.id).url,
                query: { type: ProcurementForms.BUNDLE_PO, id: purchaseOrder?.model?.bundle?.id, name: bundle ? purchaseOrder?.model?.bundle?.name : purchaseOrder?.model?.name }
            })
        }
        else { // Created from quote
            push({
                pathname: sm.portals.buyer.procurement.quotes.quoteId(purchaseOrder?.model?.quote?.id).url,
                query: { type: bundle ? ProcurementForms.BUNDLE_PO : ProcurementForms.PO, id: bundle ? purchaseOrder?.model?.bundle?.id : purchaseOrder?.model?.id, name: bundle ? purchaseOrder?.model?.bundle?.name : purchaseOrder?.model?.name }
            })
        }
    }

    return (
        <>
            {!bundle && <Grid container justifyContent={'flex-end'}>
                    {(terms && purchaseOrder?.model?.created_from_scratch && purchaseOrder?.actions[ProcurementFormPermission.UPDATE_PO]) ?
                        <GeneralTerms
                            mode='editable' 
                            terms={terms} 
                            isTermsValid={isTermsValid}
                            warranty_term={purchaseOrder?.model?.terms_bundle?.warranty_term}
                            payment_term={purchaseOrder?.model?.terms_bundle?.payment_term}
                            delivery_term={purchaseOrder?.model?.terms_bundle?.delivery_term}
                            handleSelectGeneralTerms={handleSelectGeneralTerms}
                            forceValidateTerms={sendActionIsRequested}
                        />
                        :
                        <GeneralTerms 
                            mode='view-only'
                            warranty_term={purchaseOrder?.model?.terms_bundle?.warranty_term}
                            payment_term={purchaseOrder?.model?.terms_bundle?.payment_term}
                            delivery_term={purchaseOrder?.model?.terms_bundle?.delivery_term}
                        />
                    }
            </Grid>}
            <Grid container md={12} mt={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                    {purchaseOrder?.numberOfProducts != 0 && <>
                        <Typography variant='overline' fontWeight={700}>Products / Services <PrimaryBadge>{purchaseOrder?.numberOfProducts}</PrimaryBadge></Typography>
                    </>}
                </Grid>
                <Grid item>
                    {purchaseOrder?.actions[ProcurementFormPermission.ADD_PRODUCTS] && <Button variant="contained" color='primary' onClick={handleAddProducts}>
                        Add Products / Services
                    </Button>}
                </Grid>
            </Grid>
            {purchaseOrder?.numberOfProducts != 0 && <>
                {(purchaseOrder?.actions[ProcurementFormPermission.UPDATE_PO]) ?
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnBlur={false} validateOnChange={false} enableReinitialize >
                        {formikProps => (
                            <form onSubmit={formikProps.handleSubmit} style={{ width: '100%' }}>
                                <TableSection productsTableRows={productsTableRows} purchaseOrder={purchaseOrder} isBundle={bundle} isLastPoInBundle={isLastPoInBundle} sendActionIsRequested={sendActionIsRequested} />
                            </form>
                        )}
                    </Formik>
                    :
                    <FormProductsTable key={purchaseOrderModel?.name + productsTableRows?.length} rowsData={productsTableRows} hideAvailable requestedLabel="ordered_qty" showOrderedQuantityIndicator formDetails={purchaseOrder?.model} products={purchaseOrder?.model.products} />
                }
                <Grid item container md={12} mt={3}>
                    {/* <TotalSection title={t('texts.order_summary')} position={'end'} subtotalValue={purchaseOrderModel?.subtotal_value} vatValue={purchaseOrderModel?.vat_value} totalValue={purchaseOrderModel?.total_value} /> */}
                    <TotalSection title={t('texts.order_summary')} position={'end'} subtotalValue={summaryDetails?.SummarySubtotal} vatValue={summaryDetails?.SummaryVat} totalValue={summaryDetails?.SummaryTotal} />

                </Grid>
            </>}
        </>
    )
}


export const TableSection = ({ productsTableRows, purchaseOrder, isBundle, isLastPoInBundle, sendActionIsRequested }) => {

    return (
        <FieldArray name="products" key={productsTableRows}>
            {arrayHelpers => (
                <IndividualProductsTable
                    isLastPoInBundle={isLastPoInBundle}
                    isBundle={isBundle}
                    name="products"
                    handleAdd={arrayHelpers.push}
                    handleRemove={arrayHelpers.remove}
                    purchaseOrder={purchaseOrder}
                    sendActionIsRequested={sendActionIsRequested}
                />
            )}
        </FieldArray>
    )
}