import { AuthenticateStatus, ProcurementButtons, ProcurementPopupStatus } from "@/config";
import { Comment, GeneralObject, LinkType, ProcurementFormModel, ProcurementModelDetails, ProductTableRow } from "@/models";
import { NotifySuccess, NotifySuccessWithCancelIcon, buildProcurementFormDetails, buildPurchaseOrderProductsTable, useChangeProject, useConfirmPurchaseOrder, useCreateEmptyPO, useDeclinePurchaseOrder, useEditExpiryDate, useFetchDeclinePurchaseOrderReasons, useGetSellerPurchaseOrderDetails, useRequestExtension } from "@/services";
import { t } from "i18next";
import produce from "immer";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";
import { StateCreator } from "zustand";
import { getVATTotalValues } from "../../slices";
import { getExternalPurchaseOrderDetails, useConfirmExternalPurchaseOrder, useDeclineExternalPurchaseOrder } from "src/logic/services/procurement/external-purchase-order";
import { useUserData } from "src/logic/zustand/user";


type ResponseHandlers<T = any> = {
    onSuccess?: (data: T) => void;
    onError?: () => void;
  };
export interface SellerPurchaseOrderDetailsSlice {
    purchaseOrderDetailsSliceProperties: {
        purchaseOrder: ProcurementFormModel | null
        fetchPoDetailsQueryStatus: QueryHandlerModel,
        // loadingDetails: boolean
        newComments: Comment[]
        productsTableRows: ProductTableRow[]
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        reasonsForPopup: GeneralObject[]
        openedPopupStatus: ProcurementPopupStatus | null
        customLoadingButton: ProcurementButtons | null
    }
    purchaseOrderDetailsSliceActions: {
        fetchDetails: (poId: number, changeResponse: boolean) => void
        fetchExternalPoDetails: (poId: string) => void
        // setIsLoading: (isLoading: boolean) => void
        setDetails: (po: ProcurementModelDetails, changeResponse: boolean) => void
        closeNewCommentsPopup: () => void
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => void
        setSummaryDetails: (products: ProductTableRow[]) => void
        getReasonsForPopup: () => void
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        decline: (reasonId: string, handleChangesDone: () => void, comment?: string) => void
        confirm: (handleChangesDone: () => void, comment?: string) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        requestExtensionForPOExpiredBySeller: (responseHandlers?: ResponseHandlers<ProcurementModelDetails>) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        changeProject: (project: GeneralObject) => void
    }
}

export const createSellerPurchaseOrderDetailsSlice: StateCreator<SellerPurchaseOrderDetailsSlice> = (set, get, api) => ({
    purchaseOrderDetailsSliceProperties: {
        purchaseOrder: null,
        fetchPoDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingDetails: false,
        newComments: [],
        productsTableRows: [],
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        reasonsForPopup: [],
        openedPopupStatus: null,
        customLoadingButton: null
    },
    purchaseOrderDetailsSliceActions: {
        fetchDetails: (poId: number, changeResponse: boolean) => {
            set(produce(draftState => {
                draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useGetSellerPurchaseOrderDetails(poId).then((data) => {
                get().purchaseOrderDetailsSliceActions.setDetails(data.data, changeResponse)
                set(produce(draftState => {
                    draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        fetchExternalPoDetails: (poId: string) => {
            set(produce(draftState => {
                draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            getExternalPurchaseOrderDetails(poId).then((data) => {
                get().purchaseOrderDetailsSliceActions.setDetails(data.data, false)
                set(produce(draftState => {
                    draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.purchaseOrderDetailsSliceProperties.fetchPoDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        create: (values: { projectId: string, projectName: string, sellerId: string }, handlerAfterCreate: (data: ProcurementModelDetails) => void) => {
            useCreateEmptyPO(values.projectId, values.projectName, values.sellerId).then(data => {
                handlerAfterCreate(data.data)
                // NotifySuccess(t('notifications.purchase_order_created'))
                NotifySuccess(t('notifications.create_form', { formName: data.data.name }))
            })
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.purchaseOrderDetailsSliceProperties.loadingDetails = isLoading })),
        setDetails: (purchaseOrderResponse: ProcurementModelDetails, changeResponse: boolean) => {
            set(produce(draftState => {
                draftState.purchaseOrderDetailsSliceProperties.customLoadingButton = null
                draftState.purchaseOrderDetailsSliceProperties.purchaseOrder = buildProcurementFormDetails(purchaseOrderResponse, 'canGenerateQuote', false, false, changeResponse)
                draftState.purchaseOrderDetailsSliceProperties.newComments = purchaseOrderResponse.new_comments?.length == 0 ? null : purchaseOrderResponse.new_comments
            }))
            get().purchaseOrderDetailsSliceActions.setProductsTableRows(get().purchaseOrderDetailsSliceProperties.purchaseOrder)
            get().setTimeline(purchaseOrderResponse.timeline)
        },
        closeNewCommentsPopup: () => set(produce(draftState => {
            const purchaseOrderModel = draftState.purchaseOrderDetailsSliceProperties?.purchaseOrder?.model;
            if (purchaseOrderModel) {
                purchaseOrderModel.new_comments = null;
            }
            draftState.purchaseOrderDetailsSliceProperties.newComments = null 
        })),
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => {
            const ProductsArray = buildPurchaseOrderProductsTable(purchaseOrder, get().purchaseOrderDetailsSliceProperties.purchaseOrder?.model.terms_bundle.warranty_term)
            set(produce(draftState => { draftState.purchaseOrderDetailsSliceProperties.productsTableRows = ProductsArray }))
            get().purchaseOrderDetailsSliceActions.setSummaryDetails(ProductsArray)
        },
        setSummaryDetails: (products: ProductTableRow[]) => {
            const summary = getVATTotalValues(products, 'requestedQuantity')
            set(produce(draftState => {
                draftState.purchaseOrderDetailsSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.purchaseOrderDetailsSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.purchaseOrderDetailsSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        getReasonsForPopup: () => {
            if (get().purchaseOrderDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.DECLINE)
                useFetchDeclinePurchaseOrderReasons().then(data => { set(produce(draftState => { draftState.purchaseOrderDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.purchaseOrderDetailsSliceProperties.openedPopupStatus = status
            if (!status) {
                draftState.purchaseOrderDetailsSliceProperties.openedPopupStatus = null
            }
        })),
        decline: (reasonId: string, handleChangesDone: () => void, comment?: string) => {
            const isGuest = useUserData.getState().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

            get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_PO_BY_SELLER)
            const sliceProps = get().purchaseOrderDetailsSliceProperties
            if (sliceProps.openedPopupStatus == ProcurementPopupStatus.DECLINE) {
                const declineService = isGuest ? useDeclineExternalPurchaseOrder : useDeclinePurchaseOrder;
                declineService(sliceProps.purchaseOrder?.model?.id, reasonId, comment).then((data) => {
                    get().purchaseOrderDetailsSliceActions.setPopupStatus(null)
                    get().purchaseOrderDetailsSliceActions.setDetails(data.data, false)
                    handleChangesDone()
                    NotifySuccessWithCancelIcon(t('notifications.form_declined', { formName: sliceProps.purchaseOrder?.model?.name }))
                }).catch(() => get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(null))
            }
        },
        confirm: (handleChangesDone: () => void, comment?: string) => {
            const isGuest = useUserData.getState().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

            get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CONFIRM_PO_BY_SELLER)
            const sliceProps = get().purchaseOrderDetailsSliceProperties
            
            const confirmService = isGuest ? useConfirmExternalPurchaseOrder : useConfirmPurchaseOrder;
            confirmService(sliceProps.purchaseOrder?.model?.id, comment).then((data) => {
                get().purchaseOrderDetailsSliceActions.setPopupStatus(null)
                get().purchaseOrderDetailsSliceActions.setDetails(data.data, false)
                handleChangesDone()
                NotifySuccess(t('notifications.form_confirmed', { formName: sliceProps.purchaseOrder?.model?.name }))
            }).catch(() => get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(null))
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.purchaseOrderDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.purchaseOrderDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        requestExtensionForPOExpiredBySeller: (responseHandlers?: ResponseHandlers<ProcurementModelDetails>) => {
            get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(get().purchaseOrderDetailsSliceProperties.purchaseOrder?.model?.id, 'PurchaseOrder').then((data) => {
                set(produce(draftState => { draftState.purchaseOrderDetailsSliceProperties.customLoadingButton = null }))
                // let poModel = get().purchaseOrderDetailsSliceProperties.purchaseOrder?.model
                // let updatedPO = { ...poModel, has_pending_extension_request: true, extension_request_status: procurementStatus.PENDING }
                // get().purchaseOrderDetailsSliceActions.setDetails(updatedPO, false)
                get().purchaseOrderDetailsSliceActions.setDetails(data.data, false)
                NotifySuccess(t('notifications.extension_requested'))
                responseHandlers?.onSuccess?.(data.data);
            }).catch(() => get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(null))
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const poId = get().purchaseOrderDetailsSliceProperties.purchaseOrder?.model?.id
            get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(poId, LinkType.PO_SELLER, validityDate).then((data) => {
                get().purchaseOrderDetailsSliceActions.setDetails(data.data, false)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().purchaseOrderDetailsSliceActions.setCustomLoadingButton(null))
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().purchaseOrderDetailsSliceProperties?.purchaseOrder?.model, true, false)
            get().purchaseOrderDetailsSliceActions.setDetails(modelAfterUpdateProject, false)
        },
    }
})