import { TableMapperType } from "@/models";
import { NotifySuccess, useCreateCatalougList, useDeleteCatalogProduct, useUpdateCatalogProduct } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { StateCreator } from "zustand";
import { createTableSlice } from "../procurement/slices/createTableSlice";

interface CandidateProduct { productId: string, price?: string, isActive?: boolean }
export interface CatalogForSellerTableSlice {
    CatalogTableForSellerProperties: {
        data: {
            // isLoading: boolean
            // tableType: TableMapperType | null
            // tableFilters: TableFilters[]
            // tableData: Table | null
            // requiresMyAction: boolean
            // searchText: string | null
            // tablePaginationResult: PaginationResult | null
            // selectedPageNumber: number
            // selectedFiltersNumber: 0
            // selectedFilters: TableSelectedFilters[] | null
            // isFilteredDataLoading: boolean
            timerId: null | number
            candidateProduct: CandidateProduct
        }
        actions: {
            setCandidateProduct: (product: CandidateProduct) => void
            create: (navigateToPoPage: (id: string) => void) => void
            loadData: (tableType: TableMapperType) => void
            setRequiresMyAction: (checked: boolean) => void
            setSearchText: (text: string | null) => void
            changePageNumber: (pageNumber: number) => void
            onFilterChanged: (type: string, kind: string, value: any) => void
            updateFilters: (type?: string, kind?: string, value?: any) => void
            applyFilters: () => void
            clearFilters: (tableType: TableMapperType) => void
            updatePrice: (productId: string, price: string) => void
            activateProduct: (productId: string, isActive: boolean) => void
            updateProduct: (productId: string, price?: string, activateProduct?: boolean) => void
            deleteProduct: (productId: string) => void
        }
    }
}


export const createCatalogTableForSellerSlice: StateCreator<CatalogForSellerTableSlice> = (set, get, api) => ({
    CatalogTableForSellerProperties: {
        data: {
            timerId: null
            // ...createTableSlice(set, get, api).tableSliceProperties.data
        },
        actions: {
            ...createTableSlice(set, get, api).tableSliceProperties.actions,
            create: (navigateToPoPage: (id: string) => void) => {
                useCreateCatalougList().then((data) => {
                    navigateToPoPage(data.data.id)
                    NotifySuccess(t('notifications.draft_list_created'))
                })
            },
            updatePrice: (productId: string, price: string) => {
                get().CatalogTableForSellerProperties.actions.updateProduct(productId, price)
                // set(produce(draftState => {
                //     draftState.tableSliceProperties.data.tableData.rows.map(row => {
                //         return row.id == productId ?
                //             { ...row, referenceObject: { ...row.referenceObject, price: price } }
                //             : { ...row }
                //     })
                // }))
                // console.log(get().tableSliceProperties.data.tableData.rows.map(row => row.id == productId ? console.log(row.referenceObject.price) : console.log("no")))
                // get().CatalogTableForSellerProperties.actions.updateProduct({ ...product, price: price })
            },
            activateProduct: (productId: string, isActive: boolean) => {
                get().CatalogTableForSellerProperties.actions.updateProduct(productId, undefined, isActive)
                // set(produce(draftState => {
                //     draftState.tableSliceProperties.data.tableData.rows.map(row => {
                //         return row.id == productId ?
                //             { ...row, referenceObject: { ...row.referenceObject, is_active: !row.referenceObject.is_active } }
                //             : { ...row }
                //     })
                // }))
            },
            deleteProduct: (productId: string) => {
                useDeleteCatalogProduct(productId).then(data => {
                    let rows = [...get().tableSliceProperties.data.tableData.rows]
                    rows = rows.filter(row => row.id !== productId);
                    set(produce(draftState => {
                        draftState.tableSliceProperties.data.tableData = { ...get().tableSliceProperties.data.tableData, rows }
                    }))
                    NotifySuccess(t('notifications.changes_saved'))
                })
            },
            setCandidateProduct: (product: CandidateProduct) => { set(produce(draftState => { draftState.CatalogTableForSellerProperties.data.candidateProduct = product })) },
            updateProduct: (productId: string, price?: string, activateProduct?: boolean) => {
                let rows = [...get().tableSliceProperties.data.tableData.rows]
                const targetIndex = rows.findIndex(row => row.id === productId);
                if (targetIndex !== -1) {
                    let columns = rows[targetIndex].columns.map(column => {
                        if (column.title == 'price') {
                            if (!price) price = column.price
                            return { ...column, description: price }
                        }
                        if (column.title == 'published') {
                            activateProduct = activateProduct == undefined ? column.description : !activateProduct
                            return { ...column, description: activateProduct }
                        }
                        return { ...column }
                    })
                    rows[targetIndex] = { ...rows[targetIndex], columns: columns }
                    set(produce(draftState => {
                        draftState.tableSliceProperties.data.tableData = { ...get().tableSliceProperties.data.tableData, rows }
                    }))
                    clearTimeout(get().CatalogTableForSellerProperties.data.timerId);
                    const timerId = setTimeout(() => {
                        useUpdateCatalogProduct(productId, price, activateProduct).then(data => {
                            NotifySuccess(t('notifications.changes_saved'))
                        }).catch(err => {
                            console.log(err)
                        })
                    }, 1000);
                    set(produce(draftState => { draftState.CatalogTableForSellerProperties.data.timerId = timerId }))
                }
            }
        }
    },
    // CatalogTableForSellerActions: {
    //     ...get().tableSliceProperties.actions,
    //     onActionClicked: (row: TableRow, action: TableRowAction) => { },
    //     loadData: () => {
    //         console.log(get().tableSliceProperties)
    //         const userInfo = useUserData.getState().userInfo
    //         set(produce(draftState => { draftState.isLoading = true }))
    //         useIndexSellerCatalog().then(data => {
    //             const products = getCatalogProductsArray(data.data.catalog_items)
    //             const ProductsArray = buildCatalogTable(products ?? [])
    //             set(produce(draftState => {
    //                 draftState.CatalogTableForSellerProperties.isLoading = false
    //                 draftState.CatalogTableForSellerProperties.catalogProducts = ProductsArray
    //             }))
    //         })
    //     // },

    //     updatePrice: (product: Product, price: string) => {
    //         get().CatalogTableForSellerActions.updateProduct({ ...product, price: price })
    //     },
    //     activateProduct: (product: Product, isActive: boolean) => {
    //         get().CatalogTableForSellerActions.updateProduct({ ...product, isActive: isActive }, true)
    //     },
    //     deleteProduct: (product: Product) => {
    //         useDeleteCatalogProduct(product).then(data => {
    //             let products = [...get().CatalogTableForSellerProperties.catalogProducts]
    //             products = products.filter(catalogProduct => catalogProduct.id !== product.id)
    //             console.log('products')
    //             console.log(products)
    //             set(produce(draftState => { draftState.CatalogTableForSellerProperties.catalogProducts = products }))
    //             NotifySuccess(t('notifications.changes_saved'))
    //         })
    //     },
    //     updateProduct: (product: Product, applyUpdates?: boolean) => {
    //         let products = [...get().CatalogTableForSellerProperties.catalogProducts]
    //         const targetIndex = products.findIndex(obj => obj.id === product.id);
    //         if (targetIndex !== -1) {
    //             products[targetIndex] = { ...product };
    //         }
    //         set(produce(draftState => { draftState.CatalogTableForSellerProperties.catalogProducts = products }))
    //         if (applyUpdates) useUpdateCatalogProduct(product).then(data => {
    //             NotifySuccess(t('notifications.changes_saved'))
    //         }).catch(err => {
    //             console.log(err)
    //         })
    //     }
    // }
})
