import { getInputsTranslationKey } from '@/hooks';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import type { ReactElement } from 'react';
import React, { FC } from 'react';

interface TextFieldCounterFormProps {
    name: string,
    translateKey?: string,
    showLabel?: boolean,
    type?: string,
    required?: boolean,
    iconEnd?: ReactElement,
    disabled?: boolean,
    autofocus?: boolean
    onChange?: (e: number) => void
    onBlurAction?: () => void
}

export const TextFieldCounterForm: FC<TextFieldCounterFormProps> = (props: TextFieldCounterFormProps) => {
    const { t } = useTranslation()
    const [field, meta, helpers] = useField(props);
    const { setFieldValue } = useFormikContext()

    const useStyles = makeStyles({
        root: {
            borderColor: 'red'
        },
    });

    const handleChangeValue = (newValue: number, changeFromField?: boolean) => {
        newValue >= 0 && props.onChange ? props.onChange(newValue) : newValue >= 0 && setFieldValue(props.name, newValue)
        props.onBlurAction && !changeFromField && handleBlur()
    }

    const handleBlur = (e?: React.FocusEvent<HTMLInputElement>) => {
        const newValue = Number(e?.target.value);
        if (!isNaN(newValue)) {
            setFieldValue(props.name, newValue);
        }

        if (props.onBlurAction) {
            props.onBlurAction();
        }
    };

    const iconButtonStyle = { width: 20, height: 20, backgroundColor: 'action.disabledBackground', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'  };

    const iconStyle = { color: 'primary.main', fontSize: '20px', '&:hover': {bgcolor:'transparent' } };

    return (
        <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block' }}
        >
            {props.showLabel && t(getInputsTranslationKey('labels.' + props.translateKey))} {props.required ? ' *' : ''}
            <Box width={field.value ? (field.value.toString().length + 10) * 10 : 110}>
                <TextField
                    disabled={props.disabled}
                    type="tel"
                    variant='outlined'
                    onBlur={handleBlur}
                    error={meta.touched && meta.error && true}
                    {...(props.onChange ? { onChange: (e: React.FormEvent<HTMLInputElement>) => handleChangeValue(e.target.value, true), value: field.value } : { ...field })}
                    InputProps={{
                        classes: {
                            root: useStyles.root
                        },
                        inputProps: { style: { textAlign: 'center', fontSize: '14px', fontWeight: '500', color: props.disabled ? 'text.third' : '#3A3E50', borderColor: 'red' } },
                        style: { width: '100%', height: 32, justifyContent: 'center', borderColor: 'red' },
                        startAdornment: (
                            !props.disabled && <IconButton disableRipple disableTouchRipple disableFocusRipple disabled={props.disabled} onClick={() => { handleChangeValue(field.value - 1) }} sx={iconButtonStyle}>
                                <RemoveIcon sx={iconStyle} />
                            </IconButton>
                        ),
                        endAdornment: (
                            !props.disabled && <IconButton disableRipple disableTouchRipple disableFocusRipple disabled={props.disabled} onClick={() => { handleChangeValue(field.value + 1) }} sx={iconButtonStyle}>
                                <AddIcon sx={iconStyle} />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
        </Typography>
    );
};

