import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

interface StyledToggleProps {
  handleChange: () => void;
  options: string[];
  selectedValue: string;
  translateKey: string;
  disabled?: boolean;
}
const buttonStyle = {
  textTransform: 'capitalize',
  width: '164px',
  fontSize: '12px !important',
};
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  '.MuiToggleButton-root': {
    color: '#3A3E50 !important',
    backgroundColor: '#fff !important',
    '&.Mui-disabled': {
      color: '#A9A9A9 !important',
      backgroundColor: '#E0E0E0 !important',
    },
  },
  '.Mui-selected': {
    backgroundColor: '#3A3E50 !important',
    color: '#fff !important',
  },
}));
export const StyledToggleButton: FC<StyledToggleProps> = (props: StyledToggleProps) => {
  const { t } = useTranslation();
  return (
    <StyledToggleButtonGroup
      value={props.selectedValue ?? 'company_catalog'}
      exclusive
      onChange={props.handleChange}
      // sx={{
      //     bgcolor: 'primary.light',
      //     borderRadius: '100px !important',
      //     color: 'white !important',
      //     height: 32,
      //     width: '100%',
      //     '.MuiToggleButton-root': {
      //         borderColor: 'primary.light',
      //         color: 'text.disabled',
      //         borderRadius: '100px !important',
      //         width: '50%'
      //     },
      //     '.Mui-selected': {
      //         borderRadius: '100px !important',
      //         backgroundColor: '#0AB87B !important',
      //         color: 'white !important',
      //     },
      // }}
    >
      {props.options.map(option => (
        <ToggleButton value={option} sx={buttonStyle} disabled={props.disabled && props.selectedValue !== option}>
          {t(props.translateKey + '.' + option)}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};
