import { ProductManagerEnv } from '@/config';

import { Product } from '@/models';
import CatalogSavingServices from '../catalog';
import EditBundleRfqSavingServices from '../edit_bundle_rfq';
import EditPoFromScratchSavingServices from '../edit_po_from_scratch';
import EditQuoteFromScratchSavingServices from '../edit_quote_from_scratch';
import PurchaseOrderSavingServices from '../purchase-orders';
import QuoteSavingServices from '../quotes';
import RequsitionSavingServices from '../requisitions';

type EnvAddServiceMapperParams = {
  env: ProductManagerEnv;
  formId: number;
  product: Product;
  isAlternative?: boolean;
};

const EnvAddServiceMapper = ({ env, formId, product, isAlternative = false }: EnvAddServiceMapperParams) => {
  if (env === ProductManagerEnv.requisition) {
    if (isAlternative) {
      return () =>
        RequsitionSavingServices.addProductToRequisitionAsAlternative({
          requisition_id: formId,
          main_product_id: product.parentId,
          alternative_product_id: product.id,
        });
    } else {
      return () =>
        RequsitionSavingServices.addProductToRequisition({
          requisition_id: formId,
          product_id: product.id,
          quantity: product.quantity ?? 1,
          additional_requirements: product.additional_requirements ?? null,
        });
    }
  } else if (env === ProductManagerEnv.purchase_order) {
    return () =>
      PurchaseOrderSavingServices.addProductToPurchaseOrder({
        purchase_order_id: formId,
        product_id: product.id,
        price: product.price,
        quantity: product.quantity,
        additional_requirements: product.additional_requirements,
      });
  } else if (env === ProductManagerEnv.quote) {
    return () =>
      QuoteSavingServices.addProductToQuote({
        quote_id: formId,
        product_id: product.id,
        price: product.price,
        quantity: product.quantity,
        additional_requirements: product.additional_requirements,
      });
  } else if (env === ProductManagerEnv.catalog) {
    return () =>
      CatalogSavingServices.addProductToCatalog({
        catalog_id: formId,
        product_id: product.id,
        price: product.price,
      });
  } else if (env === ProductManagerEnv.edit_bundle_rfq) {
    return () => EditBundleRfqSavingServices.addProduct();
  } else if (env === ProductManagerEnv.edit_quote_from_scratch) {
    return () => EditQuoteFromScratchSavingServices.addProduct();
  } else if (env === ProductManagerEnv.edit_po_from_scratch) {
    return () => EditPoFromScratchSavingServices.addProduct();
  }
  return null;
};

export default EnvAddServiceMapper;
