import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';
import { phoneRegExp } from 'src/logic/helpers/regex';

export const validationSchema =
  yup.object({ direct_number: yup
    .string()
    .required('This is required')
    .matches(phoneRegExp, 'Invalid Phone Number'),
})
