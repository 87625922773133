import { SellerProcurementData } from "@/models";
import { useGetSellerProjects } from "@/services";
import { useUserData } from "@/zustand";
import produce from "immer";
import { Timeline } from "src/logic/models/procurement/form-timeline";
import { Project } from "src/logic/models/project";
import { StateCreator } from "zustand";

export interface SellerProcurementSlice {
    projects: Project[] | null,
    procurementData: SellerProcurementData | null,
    getSellerProcurementData: () => void,
    addProject: (project: Project) => void,
    fetchProjects: (onSuccess?: () => void) => void;
    timeline: Timeline | null;
    setTimeline: (newTimeline: Timeline | null) => void;
}

export const createSellerProcurementSlice: StateCreator<SellerProcurementSlice> = (set, get, api) => ({
    projects: null,
    procurementData: null,
    timeline: null,
    setTimeline: (newTimeline: Timeline | null) => {
        set(produce(draftState => { draftState.timeline = newTimeline }))
    },
    getSellerProcurementData: () => {

        if (useUserData.getState().isSeller()) useGetSellerProjects().then(data => set(state => state.projects = data.data))
        set(produce(draftState => { draftState.procurementData = {} }))
        // const authenticationStatus = useUserData.getState().authenticationStatus
        //
        // const onSuccess = (procurementData: SellerProcurementData) => {
        //     set(produce(draftState => { draftState.procurementData = procurementData }))
        //     // get().setRequisitions(procurementData.requisitions)
        //     // get().setProjects(procurementData.projects)
        //     // get().subscribeCurrentRequisition()
        // }
        // useGetAllSellerProcurementData(authenticationStatus).then(data => onSuccess(data.data))
    },
    addProject: (project: Project) => {
        if(!get().projects?.find(proj => proj.id === project.id)) {
            set(produce(draftState => {
                draftState.projects = draftState.projects ? produce(draftState.projects, draftProject => {
                    draftProject.push(project)
                }) : [project]
            }))
        }
    },
    fetchProjects: (onSuccess?: () => void) => {
        useGetSellerProjects().then(data => {
            set(state => ({...state, projects: data.data}));
            onSuccess?.();
        })
    }
})