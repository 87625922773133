import { useProfile, useProfilePhoneNumberFlow } from '@/zustand';
import { SendVerificationOrLost } from '../shared-popups/send-verification-or-lost';
import { VerificationCodeFormByPhone } from '../shared-popups/verification-code-form-byPhone';
import { ChangePhoneForm } from '../shared-popups/change-phone-form';
import { VerificationCodeFormByEmail } from '../shared-popups/verification-code-form-byEmail'
import { Success } from '../shared-popups/success';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { sm } from 'src/site-map';
import { useVerificationOldPhoneNumber,useRequestChangePhoneByPhone,
useVerificationNewPhoneNumber,useVerificationNewPhoneNumberAgain,
useRequestVerificationOldPhoneByEmail,useVerificationPhoneNumberByEmailAgain} from '@/services';

type Props = { };

export const ProfileChangePhoneByEmailPopupContent: React.FC<Props> = ({}) => {
    const { t } = useTranslation();
    const changePhoneByEmailPopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByEmailPopupSteps);
    const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
    const toggleChangePhonePopup = useProfilePhoneNumberFlow(state => state.toggleChangePhonePopup);
    const user_info = useProfile(state => state.user_info);
    const [enabled, setEnabled] = useState(false);
    const temporaryPhone = useProfilePhoneNumberFlow(state => state.temporaryPhone);

    const isTimer = useProfilePhoneNumberFlow(state => state.isTimer);
    const timer = useProfilePhoneNumberFlow(state => state.timer);
    const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
    const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const mutationRequestChangePhoneViaPhone = useVerificationOldPhoneNumber(); 
    const handleSubmitCode = (values, actions) => { mutationRequestChangePhoneViaPhone.mutate(values) } 
    
    const getVerifyOldPhoneByEmail = useRequestVerificationOldPhoneByEmail(enabled); 
    const handleClickVerifyOldPhoneByEmail = () => { getVerifyOldPhoneByEmail.refetch()};

    const reSendCodeByEmailAgain = useVerificationPhoneNumberByEmailAgain(enabled); 
    const handleClickCodeAgainByEmail = () => { reSendCodeByEmailAgain.refetch() };

    const mutationChangePhone = useRequestChangePhoneByPhone(); 
    const handleSubmitNewPhone = (values, actions) => { mutationChangePhone.mutate(values)   } 

    const mutationVerificationNewPhoneNumber = useVerificationNewPhoneNumber(); 
    const handleSubmitCodeNew = (values, actions) => { mutationVerificationNewPhoneNumber.mutate(values) } 
   
    const { mutate } = useVerificationNewPhoneNumberAgain();
    const handleClickCodeAgainNew = () => { mutate() }

    const change_Phone = [
        <SendVerificationOrLost 
            isDisabledButton={getVerifyOldPhoneByEmail.isFetching}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verify_phone_via_email'))}
            userInfo={user_info?.email} 
            verificationButtonTranslate={t('buttons.send_verification')} 
            lostButtonTranslate={t('buttons.lost_email')}  
            handleClickVerification={handleClickVerifyOldPhoneByEmail}
            href={sm.contactUs.url}
            timerButton={isTimer}
            timerValue={timer}
        />,
        <VerificationCodeFormByEmail 
            isDisabledButton={mutationRequestChangePhoneViaPhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_email' ))}
            handleClickNext={handleSubmitCode} 
            handleClickBack={() => {setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps-1)} }
            handleClickAgain={handleClickCodeAgainByEmail}
        />,
        <ChangePhoneForm
            isDisabledButton={mutationChangePhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_phone'))}
            handleClickNext={handleSubmitNewPhone} 
            handleClickBack={() => setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps-1) }
        />,
        <VerificationCodeFormByPhone
            isDisabledButton={mutationVerificationNewPhoneNumber.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_phone' ))}
            userInfo={temporaryPhone} 
            handleClickNext={handleSubmitCodeNew} 
            handleClickBack={() => setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps-1) }
            handleClickAgain={handleClickCodeAgainNew}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.change_phone_success' ))}
            userInfo={user_info?.mobile_number.number} togglePopup={() => toggleChangePhonePopup(false)}
        />
    ]

    return (
        <>
            { change_Phone[changePhoneByEmailPopupSteps]  }
        </>
    );
};