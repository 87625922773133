import { getInputsTranslationKey } from '@/hooks';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import { at } from 'lodash';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

interface selectWithPlaceholderProps {
  translateKey: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  items:
    | Array<{
        id: number;
        name: string;
      }>
    | undefined
    | null;
  onChange?: (value: any) => void;
  noShowLabel?: boolean;
  isLoading?: boolean;
  disableTranslation?: boolean;
}

const selectTextInputStyle = {
  color: '#B2B2B2',
};
const inputStyle = {
  width: '100%',
};
const textInputStyle = {
  bgcolor: 'white',
  color: '#3A3E50',
  width: '100%',
};
const textDisabledInputStyle = {
  color: '#3A3E50',
  'input::placeholder': {
    fontWeight: '400',
  },
  opacity: 0.8,
};
export const SelectWithPlaceholder: FC<selectWithPlaceholderProps> = ({
  required,
  isLoading,
  disableTranslation,
  ...props
}: selectWithPlaceholderProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (error && touched) {
      return (
        <Typography variant='subtitle2'>
          {disableTranslation
            ? error
            : t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
        </Typography>
      );
      // return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
    }
  }

  return (
    <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2' sx={inputStyle}>
      {props.noShowLabel ? null : (
        <>
          {t(getInputsTranslationKey('labels.' + props.translateKey))} {required ? ' *' : ''}
        </>
      )}
      <FormControl sx={inputStyle} fullWidth size='small' error={meta.error && meta.touched && true}>
        <Select
          renderValue={value => {
            if (isLoading) {
              return 'Loading...';
            }
            if (!value) {
              return disableTranslation
                ? props.placeholder
                  ? props.placeholder
                  : ''
                : t(getInputsTranslationKey('placeholders.' + props.placeholder));
            }
            return props.items.find(op => op.id === value)?.name;
          }}
          displayEmpty
          disabled={props.disabled}
          // inputProps={{ sx: (field.value == 0 ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle)  }}
          // sx={(!field.value ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle)}
          sx={{
            '.MuiSelect-select': props.disabled
              ? textDisabledInputStyle
              : !field.value
              ? { ...textInputStyle, ...selectTextInputStyle }
              : { ...textInputStyle },
          }}
          style={props.disabled ? { opacity: 0.6 } : {}}
          error={meta.error && meta.touched && true}
          {...field}
          onBlur={event => {
            field.onChange(event);
            field.onBlur(event);
            if (props.onChange) {
              props.onChange(field.value);
            }
          }}
          {...props}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 140,
                overflowY: 'auto',
                maxWidth: '40px'
              },
              className: 'styled-scrollbar',
            },
          }}
        >
          <MenuItem value={0} disabled style={{ display: 'none' }}>
            {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
          </MenuItem>
          <MenuItem value={null} disabled style={{ display: 'none' }}>
            {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
          </MenuItem>
          {props.items.map(function (object, i) {
            return (
              <MenuItem value={object.id} key={object.id}>
                {object.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{_renderHelperText()}</FormHelperText>
      </FormControl>
    </Typography>
  );
};
