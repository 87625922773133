import { Button, CircularProgress, Grid, Typography } from "@mui/material"
import { ReactNode, useState } from "react";
import { MultilineTextFieldForm } from "@/components";
import SelectDate from "../text-field/date-textField";
import { Formik } from "formik";
import { commentsValidationSchema } from './validationSchema'
import initialValues from "./initialValues";
import { useTranslation } from 'next-i18next';
import moment from 'moment';

type SendToOtherCompanyProps = {
    icon: ReactNode
    popupTitle: string
    handlePopupAction: (values: any) => void
    buttonTitle: string
    validTitle: string
    note?: string | null
    isButtonLoading?: boolean
};

export const SendToOtherCompany: React.VFC<SendToOtherCompanyProps> = ({ icon, popupTitle, validTitle, handlePopupAction, buttonTitle, note, isButtonLoading }) => {
    const { t } = useTranslation();

    let defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 7);


    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const handleDateChange = (date) => setSelectedDate(date);
    const formattedDate = moment(selectedDate).format("DD-MM-YYYY");

    const handleSubmit = (values, actions) => {
        values.validity_date = formattedDate
        handlePopupAction(values)
    }

    const handleReset = () => {
        let resetResult = new Date()
        resetResult.setDate(resetResult.getDate() + 7);
        setSelectedDate(resetResult)
    }

    return (
        <Grid container width={350}>
            <Formik initialValues={initialValues} validationSchema={commentsValidationSchema} onSubmit={handleSubmit}>
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Grid item md={12} textAlign='center' my={1} p={1.5}>
                            {icon}
                            <Grid item md={12} textAlign='center' justifyContent={'space-between'} mb={2}>
                                <Typography variant='overline' color='primary.main' fontWeight="700" > {popupTitle} </Typography>
                            </Grid>
                            <Grid item md={12} textAlign='start' py={1}>
                                <Grid item container md={12} justifyContent={'space-between'}>
                                    <Typography variant='body2' fontWeight="400" color='text.third'> {validTitle}* </Typography>
                                    <Typography variant='body2' fontWeight="400" color='primary.main' onClick={handleReset} sx={{ cursor: 'pointer' }}> {t("buttons.reset")} </Typography>
                                </Grid>
                                <SelectDate selectedDate={selectedDate} formattedDate={formattedDate} handleDateChange={handleDateChange} />
                                {note && <Typography mb={2} variant='subtitle2' fontWeight="400" color='text.third' > {note} </Typography>}
                                <MultilineTextFieldForm translateKey='comment' name='comment' showLabel />
                            </Grid>
                            <Button variant="contained" fullWidth type="submit" disabled={isButtonLoading}>{isButtonLoading ? (<CircularProgress size={24} color="inherit" />) : buttonTitle} </Button>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid>
    )
}