import { useTranslation } from 'next-i18next';
import React from 'react';
import { Link, TextTooltip } from '../../shared';

interface SideDrawerSubMenuLinkProps { drawerItemDetails: any }

export const SideDrawerSubMenuLink: React.FC<SideDrawerSubMenuLinkProps> = ({ drawerItemDetails }) => {
    const { t } = useTranslation();
    const textRef = React.useRef();
    return (
        <TextTooltip title={t("pages_names." + drawerItemDetails.title)} textRef={textRef}>
            <Link
                ref={textRef}
                href={drawerItemDetails.link}
                underline='none'
                sx={{
                    marginX: 2,
                    marginY: 1,
                    display: '-webkit-box',
                    whiteSpace: 'pre-line',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflowY: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {t("pages_names." + drawerItemDetails.title)}
            </Link>
        </TextTooltip>
    )
}
