export const initialValues = (isBuyer,emailInvitation,companyIdInvitation,invitationId) => {
    return {
        first_name: '',
        last_name: '',
        title: '',
        job_title: '',
        mobile_number: '',
        direct_number: '',
        email: emailInvitation ? emailInvitation : '',
        password: '',
        password_confirmation: '',
        company_type: isBuyer? "BUYER" : "SELLER",
        existed_company_id: companyIdInvitation ? companyIdInvitation : '',
        company_industry_id: '',
        company_name: '',
        company_city_id: '',
        company_country_id: '',
        company_address: '',
        trn_number: '',
        work_number: '',
        fax_number: '',
        is_company_admin: 1,
        terms: false,
        trade_license: null,
        vat_certificate: null,
        invitation_uuid: invitationId ? invitationId : null,
    }
}
