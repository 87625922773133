import { Grid, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { StyledPopper } from "src/ui/components/shared";
import LinkIcon from '@mui/icons-material/Link';

interface props {
    children: any
}

export const LinkedFormsIcon: FC<props> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClosePopper = () => setAnchorEl(null)
    return (
        <>
            <IconButton color='primary' sx={{ ml: 1, p: 2.2, bgcolor: 'containedInfo' }} onClick={handleOpenPopper} >
                {/* <LinkedIcon sx={{ fontSize: '35px', padding: '1px' }} /> */}
                <LinkIcon sx={{ bgcolor: 'containedInfo' }} />
            </IconButton>


            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
                <Grid item container direction={'column'} p={1} mt={2} gap={1} alignItems='start' sx={{ borderRadius: '8px' }} onClick={handleClosePopper}>
                    {children}
                </Grid>
            </StyledPopper>
        </>
    )
}