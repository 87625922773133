import React from 'react';

export const containerStyles: React.CSSProperties = {
  borderRadius: '8px',
  backgroundColor: '#fff',
  padding: '.75rem 1.5rem',
  width: '100%',
  boxShadow: '0px 8px 32px 0px #EBEBEB',
};

export const iconContainerStyles: React.CSSProperties = {
  borderRadius: '100%',
  width: '2.75rem',
  height: '2.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#13628C',
  color: '#fff',
};
