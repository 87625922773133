import React from 'react';
import { GeneralTermsProps } from './types';
import ViewOnlyGeneralTerms from './view-only/ViewOnlyGeneralTerms';
import EditableGeneralTerms from './editable/EditableGeneralTerms';

const GeneralTerms = (props: GeneralTermsProps) => {
  if (props.mode === 'view-only') {
    return <ViewOnlyGeneralTerms {...props} />;
  }
  if (props.mode === 'editable') {
    return <EditableGeneralTerms {...props} />;
  }
  return null;
};

export default GeneralTerms;
