import { Grid } from '@mui/material';
import { useRequisition } from '@/zustand';
import { Requisition } from '@/models';
import { RequisitionProductMobile } from '@/components';
import React from 'react';

type Props = {
  requisition: Requisition;
  selectedProductIndex: number;
};

export const AlternativesListMobile: React.FC<Props> = ({
  requisition,
  selectedProductIndex,
  sliderRef,
}) => {
  const removeProduct = useRequisition(state => state.removeProduct);

  return (
    <>
      <Grid container ref={sliderRef}>
        {requisition?.products[selectedProductIndex]?.altProducts.map(
          altProduct => {
            return (
              <Grid item md={12} xs={12} py={1}>
                <RequisitionProductMobile
                  key={altProduct.partNumber}
                  product={altProduct}
                  selectedProductIndex={selectedProductIndex}
                  mainProduct={false}
                  requisition={requisition}
                  tabId={requisition.products[selectedProductIndex].tabId}
                  removeProduct={removeProduct}
                  isWithDetails={true}
                />
              </Grid>
            );
          }
        )}
      </Grid>
    </>
  );
};
