import { AddProductToEnvironmentButton, PriceInputForEnvironmentProduct } from '@/components';
import { productManagerEnv } from '@/config';
import { Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import LinkIcon from '@mui/icons-material/Link';
import { Button, Grid } from '@mui/material';
import { t } from 'i18next';
import Link from 'next/link';
import React from 'react';
import { sm } from 'src/site-map';

export const CatalogActions: React.FC<{ product: Product }> = props => {
  const { product, ...rest } = props;
  const { formDetails } = useProcurementStore(state => state.productFormManagerProperties.data);

  return (
    <Grid item md={12} gap={2} container alignItems={'center'} {...rest}>
      {props.product.part_of_seller_catalog ? (
        <>
          <Button variant='outlined_background' color='success' fullWidth startIcon={<LinkIcon />}>
            {t('buttons.published')}
          </Button>
        </>
      ) : (
        <>
          {props.product.part_of_draft_list && props.product.part_of_draft_list.id !== formDetails?.id ? (
            <Link href={sm.portals.seller.products.sellerProductList(`${props.product.part_of_draft_list.id}`).url}>
              <Button variant='outlined_background' color='primary' fullWidth sx={{ lineHeight: 1 }}>
                Added To {props.product.part_of_draft_list.name}
              </Button>
            </Link>
          ) : (
            <>
              <PriceInputForEnvironmentProduct product={props.product} />
              <AddProductToEnvironmentButton
                product={props.product}
                titleForAddedProduct='added_to_list'
                environment={productManagerEnv.purchase_order}
                titleForAddingProduct={t('buttons.add_to_list')}
                isPageAlternative={props.isPageAlternative}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
};
