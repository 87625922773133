import { BundleRFQPDFDocument, BundleRFQPopupContent, DescPopup, EditAndRegenerateButton, FormHeaderDetails, FormMoreOptions, SendToOtherCompany, StatusChip, StyledBreadcrumbs, TranslationWithStyles } from "@/components";
import { PopupStatus, ProcurementButtons, ProcurementForms, procurementStatus } from "@/config";
import { BundleRFQDetails, ProductCategoryKind } from "@/models";
import { useProcurementStore } from "@/zustand";
import { HighlightOff } from "@mui/icons-material";
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { sm } from "src/site-map";
import { ApproveReject, CancelApproval, SendForApproval } from "./bundle-buttons-with-popups";


export const BundleRFQHeaderDetails = (bundleRFQDetails: BundleRFQDetails) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const { changeProject, setPopupStatus, sendBundleToAllSellers, deleteBundleRFQ, handleDuplicateBundleRFQ, cancelBundleRFQ } = useProcurementStore(state => state.bundleRFQDetailsSliceActions)
    const { openedPopupStatus, customLoadingButton } = useProcurementStore(state => state.bundleRFQDetailsSliceProperties)
    const bundleModel = bundleRFQDetails?.bundleRFQModel

    const OpenSendToAllSellersPopup = () => setPopupStatus(PopupStatus.SENT_TO_SELLERS)
    const handleClosePopup = () => setPopupStatus(null)
    const handleSendToAllSellers = (values) => sendBundleToAllSellers(bundleModel?.id, values.validity_date, values.comment)

    //sentDonePopup
    // const handleCloseSentDoneToAllSellersPopup = () => setSentDoneToAllSellersPopup(false);

    // const handleViewBundleRFQFromPopup = () => {
    //     push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(bundleModel?.id).url)
    //     handleCloseSentDoneToAllSellersPopup()
    // }
    // const handleViewMyRFQsFromPopup = () => {
    //     push({ pathname: sm.portals.buyer.procurement.requestForQuotes.index.url })
    //     handleCloseSentDoneToAllSellersPopup()
    // }

    const handleRedirectToSelectSellerPage = () => {
        // (handleCloseEditAndRegeneratePopup: () => void) => {
        // const reqId = bundleModel?.requisition?.id
        // push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(reqId).url, query: { sellers: 'select', type: RFQType.BUNDLE_RFQ, id: bundleModel?.id, name: bundleModel?.name } })
        // push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(reqId).url, query: { type: 'Bundle_RFQ', id: bundleModel?.id, name: bundleModel?.name } })
        // handleCloseEditAndRegeneratePopup()

        push(sm.portals.buyer.procurement.bundleRFQs.edit(bundleModel.id).url)
    }

    // const handleRegenerateRFQ = () => {
    //     // handleRedirectToSelectSellerPage(handleCloseEditAndRegeneratePopup)
    //     handleRedirectToSelectSellerPage()
    // }

    // Cancel BundleRFQ
    const handleOpenCancelBundleRFQPopup = () => setPopupStatus(PopupStatus.CANCEL_BUNDLE)
    const cancelTheBundleRFQ = (values: any) => { cancelBundleRFQ(bundleModel?.id, values.reasonId, values.hideReason, values.comment) }

    // edit
    // const [showEditAndRegeneratePopup, setShowEditAndRegeneratePopup] = useState(false);
    // const handleOpenEditAndRegeneratePopup = () => setShowEditAndRegeneratePopup(true)
    // const handleCloseEditAndRegeneratePopup = () => setShowEditAndRegeneratePopup(false);

    function IfShowStatusChip(status) {
        let ifShowStatus = (status == procurementStatus.RECALLED || status == procurementStatus.REJECTED_BY_ME || status == procurementStatus.APPROVED_BY_ME || status == procurementStatus.PENDING_MY_APPROVAL || status == procurementStatus.DECLINED)
        return ifShowStatus
    }

    const handleDeleteBundleRFQ = () => deleteBundleRFQ(bundleRFQDetails?.bundleRFQModel?.id, () => push(sm.portals.buyer.procurement.requestForQuotes.index.url))

    const handleDuplicate = (project: any) => {
        handleDuplicateBundleRFQ(bundleRFQDetails?.bundleRFQModel?.id, project, (id: any) => push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(id).url))
    }
    const [hasApprovedRFQ, setHasApprovedRFQ] = useState(false);
    const [isAllRFQsApproved, setIsAllRFQsApproved] = useState(false);

    useEffect(() => {
        if (bundleRFQDetails) {
            const allRfqs = bundleRFQDetails?.bundleRFQModel?.request_for_quotes;
            if (allRfqs && allRfqs.length > 0) {
                const allApproved = allRfqs.every(
                    rfq => rfq.status === procurementStatus.APPROVED || rfq.status === procurementStatus.RECALLED
                );
                const someApproved = allRfqs.some(
                    rfq => rfq.status === procurementStatus.APPROVED
                );

                if (allApproved) {
                    setIsAllRFQsApproved(true)
                } else if (!allApproved && someApproved) {
                    setHasApprovedRFQ(true);
                } else {
                    setHasApprovedRFQ(false);
                }
            }
        }
    }, [bundleRFQDetails]);

    const isAllRfqsDraft = React.useMemo(() => {
        return bundleRFQDetails?.requestForQuotes?.reduce((acc, rfq) => acc && rfq?.rfqModel?.status === procurementStatus.DRAFT, true)
    }, [bundleRFQDetails])

    const isBundleDeclined = bundleRFQDetails?.bundleRFQModel?.status === procurementStatus.DECLINED
    const isEditMainButton = isBundleDeclined;
    const mainEditButtonText = isBundleDeclined ? 'Edit & Resend' : 'Edit'

    const [numberOfProducts, numberOfServices] = React.useMemo(() => {
        return bundleRFQDetails?.requestForQuotes?.reduce((acc, curr) => {
            let numberOfProductsInRfq = 0;
            let numberOfServicesInRfq = 0;
            curr.rfqModel?.products.forEach(rfqProduct => {
                if (rfqProduct.product.category_kind === ProductCategoryKind.PRODUCT) numberOfProductsInRfq++;
                if (rfqProduct.product.category_kind === ProductCategoryKind.SERVICE) numberOfServicesInRfq++;
            }); 
            return [acc[0] + numberOfProductsInRfq, acc[1] + numberOfServicesInRfq]
        }, [0, 0])
    }, [bundleRFQDetails])
    return (
        <Grid container item md={12}>
            <Grid item container md={12} mb={2}>
                <StyledBreadcrumbs path={sm.portals.buyer.procurement.requestForQuotes.index.url} showBackButton alias={sm.portals.buyer.procurement.bundleRFQs.bundleRFQ('*').url} dynammicAlias={[bundleModel?.name]} />
            </Grid>

            {/* <PageDetailsHeader> */}
            <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px' }}>
                <Grid container md={12} px={2}>
                    <Grid item container md={5} alignItems="center" gap={1} my={1}>
                        <Typography variant="h5" fontWeight={700} mr={2}>{bundleModel?.name}</Typography>
                        {IfShowStatusChip(bundleModel?.status) && <StatusChip status={bundleModel?.status} title={t('table_cells.rfq')} listOfApprovers={bundleModel?.approvers} />}
                        {bundleModel?.is_expired && <Chip label={t('chip.EXPIRED')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />}
                    </Grid>
                    <Grid item container gap={1} md={7} alignItems="center" justifyContent={'end'} px={2} py={1}>
                        <Grid container md={12} alignItems="center" justifyContent={'end'}>
                            {bundleRFQDetails.canEditAndRegeneratePrimary && <EditAndRegenerateButton hidePopup editAndRegenerateHandler={handleRedirectToSelectSellerPage} subtitle={t('popups.subTitles.edit_regenerate_bundle')}  mainAction={isEditMainButton} customName={mainEditButtonText} />}
                            {/* {bundleRFQDetails.canViewCT && <Button variant='contained' sx={{ mr: 1 }} onClick={handleViewCT}>{t('buttons.view_comparison_table')}</Button>} */}
                            {bundleRFQDetails.canSendToSellers && <Button variant="contained" sx={{ mr: 1 }} color={'primary'} onClick={OpenSendToAllSellersPopup}>{hasApprovedRFQ ? t('buttons.send_approved_rfqs_to_sellers') : (isAllRfqsDraft && bundleRFQDetails?.bundleRFQModel?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_rfqs_to_sellers') : isAllRFQsApproved ? t('buttons.send_all_rfqs_to_sellers') : t('buttons.send_rfqs_to_sellers')}</Button>}
                            <CancelApproval bundleRFQDetails={bundleRFQDetails} />
                            <SendForApproval bundleRFQDetails={bundleRFQDetails} />
                            <ApproveReject bundleRFQDetails={bundleRFQDetails} openedPopupStatus={openedPopupStatus} />

                            <FormMoreOptions
                                shareURL={bundleModel?.share_url}
                                pdfDocument={<BundleRFQPDFDocument bundleRFQ={bundleRFQDetails} number_of_Products={numberOfProducts} number_of_Services={numberOfServices}/>}
                                deleteAction={bundleRFQDetails?.canDelete}
                                handleDeleteAction={handleDeleteBundleRFQ}
                                formType={ProcurementForms.BUNDLE_RFQ}
                                formName={bundleRFQDetails?.bundleRFQModel?.name}
                                editAction={bundleRFQDetails.canEditAndRegenerateSecondary}
                                handleEditAction={handleRedirectToSelectSellerPage}
                                recallAction={bundleRFQDetails.canRecallBundle}
                                handleRecallAction={handleOpenCancelBundleRFQPopup}
                                duplicateAction={bundleRFQDetails.canDuplicate}
                                handleDuplicateAction={(data) => handleDuplicate(data)}
                                currentProject={bundleRFQDetails?.bundleRFQModel?.project?.id}
                            />
                            {/* <ExitForm exitFormHandler={() => push(sm.portals.buyer.procurement.requestForQuotes.index.url)} /> */}
                        </Grid>
                    </Grid>
                    <Grid item container md={12}>
                        <FormHeaderDetails numberOfProducts={numberOfProducts} numberOfServices={numberOfServices} formModel={bundleModel} updateProject={changeProject} bundle projectDetailsUrl={sm.portals.buyer.projects.projectId(bundleModel?.project?.id?.toString()).url} senderProjectId={bundleModel?.sender_project?.id} />
                    </Grid>
                </Grid>
            </Box >
            {/* </PageDetailsHeader> */}

            {/*cancel bundle RFQ - confirm Popup*/}
            {/* <ConfirmPopup
                titleKey={t('popups.titles.cancel_bundle_rfq')}
                subTitleKey={<TranslationWithStyles title={t('popups.subTitles.cancel_bundle_rfq')} />}
                buttonTitleKey={t("buttons.yes_confirm")}
                handleClose={handleCloseConfirmCancelBundleRFQPopup}
                handleConfirm={handleOpenCancelBundleRfqPopup}
                open={confirmCancelBundleRFQPopup}
                icon={<HighlightOff color='error' />}
            /> */}

            {/*cancel bundle RFQ - Popup*/}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.CANCEL_BUNDLE}>
                <BundleRFQPopupContent
                    icon={<HighlightOff color='error' />}
                    popupTitle={t('popups.titles.cancel_bundle_rfq')}
                    buttonTitle={t('popups.titles.cancel_bundle_rfq')}
                    popupSubTitle={<TranslationWithStyles title={t('popups.subTitles.cancel_bundle_rfq')} />}
                    handlePopupAction={cancelTheBundleRFQ}
                    status='error'
                    titleColor='error'
                    checkLabel={t('texts.hide_reason_from_seller')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_BUNDLE_RFQ}
                />
            </DescPopup>

            {/*cancel bundle RFQ - Done Popup*/}
            {/* <ViewBundleOrRFQs
                icon={<HighlightOff color='error' />}
                title={t('popups.titles.bundle_rfq_canceled')}
                openPopup={openedDonePopupStatus == DonePopupStatus.CANCEL_BUNDLE}
                handleClosePopup={handleCloseDoneCancelBundlePopup}
                status={'error'}
            /> */}

            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.SENT_TO_SELLERS}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary' />}
                    popupTitle={hasApprovedRFQ ? t('buttons.send_approved_rfqs_to_sellers') : (isAllRfqsDraft && bundleRFQDetails?.bundleRFQModel?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_rfqs_to_sellers') : isAllRFQsApproved ? t('buttons.send_all_rfqs_to_sellers') : t('buttons.send_rfqs_to_sellers')}
                    validTitle={t('texts.rfqs') + " " + t('texts.valid_until')}
                    note={t('texts.note_rfq_expiry_date')}
                    buttonTitle={hasApprovedRFQ ? t('buttons.send_approved_rfqs_to_sellers') : (isAllRfqsDraft && bundleRFQDetails?.bundleRFQModel?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_rfqs_to_sellers') : isAllRFQsApproved ? t('buttons.send_all_rfqs_to_sellers') : t('buttons.send_rfqs_to_sellers')}
                    handlePopupAction={handleSendToAllSellers}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_BUNDLE_RFQ_TO_SELLER}
                />
            </DescPopup>

            {/* <DescPopup handleClose={handleCloseSentDoneToAllSellersPopup} open={sentDoneToAllSellersPopup}>
                <Grid container item md={12} mx={1} gap={1}>
                    <Grid item container justifyContent={"center"} md={12}>
                        <CheckCircleOutline sx={{ color: "success.dark" }} />
                    </Grid>
                    <Grid item container justifyContent={"center"} md={12}>
                        <Typography variant="overline" fontWeight="700" color="success.dark">{t('popups.titles.sent_to_sellers')}</Typography>
                    </Grid>
                    <Grid item container md={12} mt={2}>
                        <Grid item md={6} pr={0.5}>
                            <Button fullWidth variant="contained" onClick={handleViewBundleRFQFromPopup}>{t('buttons.back_to_bundle')}</Button>
                        </Grid>
                        <Grid item md={6} pl={0.5}>
                            <Button fullWidth variant="outlined" onClick={handleViewMyRFQsFromPopup}>{t('buttons.my_RFQs')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DescPopup> */}

            {/* <DescPopup
                handleClose={handleCloseEditAndRegeneratePopup}
                open={showEditAndRegeneratePopup}
            >
                <Grid container gap={2} width={320}>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='overline' color='primary.main' fontWeight="700">
                            {t('buttons.edit_regenerate')}
                        </Typography>
                    </Grid>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='body2' color='text.disabled'>
                            {t('popups.subTitles.edit_regenerate_bundle')}
                        </Typography>
                    </Grid>
                    <Grid item container md={12}>
                        <Grid item md={6} pr={0.5}>
                            <Button
                                onClick={handleCloseEditAndRegeneratePopup}
                                variant='contained'
                                fullWidth
                                color='warning'
                            >
                                {t('buttons.cancel')}
                            </Button>
                        </Grid>
                        <Grid item md={6} pl={0.5}>
                            <Button
                                onClick={() => handleRegenerateRFQ(handleCloseEditAndRegeneratePopup)}
                                variant='contained'
                                fullWidth
                            >
                                {t('buttons.yes_confirm')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DescPopup> */}

        </Grid>
    )
}