import { useUserData } from '@/zustand';
import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { sm } from 'src/site-map';

const notificationsData = {
  PENDING_USER_ACTION: {
    color: '#FFAD42',
  },
  PENDING_APPROVAL: {
    color: '#FFAD42',
  },
  UNDER_REVIEW: {
    color: 'success.dark',
  },
};

type Props = {};

export const NotificationsHeader: React.VFC<Props> = () => {
  const { t } = useTranslation();
  const userData = useUserData();
  const companyApprovalStatus = userData?.companyInfo?.company_approval_status;
  const accountStatus = userData?.userInfo?.account_status;

  const shouldDisplayAlert =
    companyApprovalStatus === 'PENDING_APPROVAL' ||
    companyApprovalStatus === 'PENDING_USER_ACTION' ||
    accountStatus === 'PENDING_APPROVAL';

  if (!userData || !shouldDisplayAlert) {
    return null;
  }

  const currentNotification = notificationsData[companyApprovalStatus];
  return (
    <Box>
      <Alert
        icon={false}
        sx={{
          width: '100%',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: currentNotification?.color ?? '#FFAD42',
        }}
      >
        {companyApprovalStatus === 'PENDING_USER_ACTION' && (
          <Typography variant='body2' color='secondary.contrastText' textAlign={'center'}>
            Your documents are not submitted. Please go to{' '}
            <Link href={`${sm.account.index.url}#upload`} scroll={false}>
              Settings
            </Link>{' '}
            to upload the documents
          </Typography>
        )}
        {companyApprovalStatus === 'PENDING_APPROVAL' && (
          <Typography variant='body2' color='secondary.contrastText' textAlign={'center'}>
            Your company is pending approval from eProcurement
          </Typography>
        )}
        {accountStatus == 'PENDING_APPROVAL' && (
          <Typography variant='body2' color='secondary.contrastText' textAlign={'center'}>
            {userData.companyInfo?.has_active_admin_user
              ? t('notifications.pending_approval_with_active_admin')
              : t('notifications.pending_approval_without_active_admin')}
          </Typography>
        )}
      </Alert>
    </Box>
  );
};
