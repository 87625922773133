import { StyledTextField } from "@/components";
import { Grid, Button, Typography } from "@mui/material";
import { Formik } from 'formik';

interface LoginFormHeaderProps {titleTranslate:string; subTitleTranslate:string;}

export const LoginFormHeader: React.FC<LoginFormHeaderProps> = ({titleTranslate,subTitleTranslate}) => {
    return (
        <>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant='h2' sx={{ fontWeight: 'bolder' }}>
            {titleTranslate}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant='body2'> {subTitleTranslate} </Typography>
        </Grid>
        </>
    )
}