import React, { FC } from 'react';
import { useProcurementStore } from '@/zustand';
import { Description } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
// import { ProjectCreateOrSelect } from "src/ui/components/dumb";
import { validationSchema } from './validation-schema';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { LoadingButton } from '@mui/lab';
import { SearchTextField, RadioButtonGroup, Loading } from '@/components';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

interface props {
  initialProjectId?: number;
}

export const CreatePurchaseOrderPopup: FC<props> = ({ initialProjectId }) => {
  const { push } = useRouter();
  const { purchaseOrderRelatedData, loadBuyerPurchaseOrdersRelatedData } = useProcurementStore();
  const initialValues = {
    projectId: initialProjectId ?? '',
    projectName: '',
    sellerId: '',
  };
  const { actions } = useProcurementStore(state => state.buyerPurchaseOrderTableSliceProperties);
  const navigateToPoPage = (poId: string) =>
    push(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(poId).url);
  const createPoLoading = useLoadingState();
  const handleCreatePO = values => {
    createPoLoading.startLoading();
    actions.create(values, navigateToPoPage, createPoLoading.finishLoading);
  };

  const fetchDataLoading = useLoadingState();
  React.useEffect(() => {
    fetchDataLoading.startLoading();
    loadBuyerPurchaseOrdersRelatedData({
      onSuccess: fetchDataLoading.finishLoading,
    });
  }, []);

  const [searchText, setSearchText] = React.useState<string>('');

  return (
    <Stack
      sx={{
        alignItems: 'center',
        textAlign: 'center',
        my: 1,
        width: '100%',
        paddingInline: '1em',
      }}
    >
      <Description color='primary' />
      <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
        {t('popups.titles.create_new_purchase_order')}
      </Typography>
      {fetchDataLoading.isLoading ? (
        <Loading fullHeight />
      ) : (
        <Stack mt={2} width='100%'>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreatePO}>
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <Stack sx={{ textAlign: 'start' }} gap={1}>
                  {/* <SelectWithPlaceholder
                  translateKey='select_seller'
                  name='sellerId'
                  required
                  placeholder='select_seller'
                  items={purchaseOrderRelatedData?.sellers ?? []}
                /> */}
                  <Box width={'100%'}>
                    <SearchTextField
                      translateKey='search_sellers'
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}
                    />
                  </Box>
                  <Stack my={1} maxHeight='200px'>
                    <Stack pl={1}>
                      <RadioButtonGroup
                        data={
                          purchaseOrderRelatedData?.sellers?.filter(seller =>
                            seller.name.toLowerCase().includes(searchText.toLowerCase())
                          ) ?? []
                        }
                        handleChange={(id: string) => formikProps.setFieldValue('sellerId', id)}
                        value={formikProps.values.sellerId}
                        getSubTitle={() => ''}
                        getLabelSuffix={el => (el.is_private ? <PrivacyButton disableText zIndex={1300} /> : null)}
                      />
                    </Stack>
                  </Stack>
                  {/* <ProjectCreateOrSelect projects={purchaseOrderRelatedData?.projects ?? []} showCreateProject={showCreateProject} setshowCreateProject={setshowCreateProject} /> */}
                  <LoadingButton
                    loading={createPoLoading.isLoading}
                    disabled={!formikProps.values.sellerId}
                    sx={{ mt: 2 }}
                    type='submit'
                    fullWidth
                    variant='contained'
                  >
                    {t('buttons.create_new_purchase_order')}
                  </LoadingButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Stack>
      )}
    </Stack>
  );
};
