import React from 'react';

export const containerStyles: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '0.75rem 1rem',
};

export const gridItemStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.25rem',
};

export const containerStylesWithOutPadding: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '8px',
};

export const containerCatalogueStyles: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '8px 8px 0px 0px',
  padding: '0.25rem 1rem',
};
