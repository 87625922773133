import {
    ColumnFormat, ColumnType, Table,
    TableColumnMapper, TableRow, TableRowAction
} from "@/models";
import { NotifySuccess, useIndexRequestForQuotes, useSendReminder, useSendReminderToApprovers } from "@/services";
import produce from "immer";
import { toast } from "react-toastify";
import { StateCreator } from "zustand";
import { buildTableModel } from "../../../services/tables";

export interface RequestForQuotesTableSlice {
    requestForQuotesTableSliceProperties: {
        data: {
            tableGroup: Table | null,
            isLoading: boolean,
        },
        actions: {
            loadData: () => void,
            sendReminder: (id: number, reminderSuccessHandler: () => void) => void
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
        }
    },
}

export const createRequestForQuotesTableSlice: StateCreator<RequestForQuotesTableSlice> = (set, get, api) => ({
    requestForQuotesTableSliceProperties: {
        data: {
            tableGroup: null,
            isLoading: true,
        },
        actions: {
            onActionClicked: (row: TableRow, action: TableRowAction) => {
                if (action == TableRowAction.SEND_REMINDER) {
                    useSendReminderToApprovers(row.id).then(() => NotifySuccess('Reminder Was Sent Successfully'))
                }
            },
            loadData: () => {
                set(produce(draftState => {
                    draftState.requestForQuotesTableSliceProperties.data.isLoading = true
                }))
                useIndexRequestForQuotes().then(data => {
                    const tableModel = buildTableModel({
                      jsonData: data.data.data.data,
                      columnMapper: quotesTableMapper(),
                      rowChildrenAccessorKey: 'request_for_quotes',
                    });
                    set(produce(draftState => {
                        draftState.requestForQuotesTableSliceProperties.data.isLoading = false
                        draftState.requestForQuotesTableSliceProperties.data.tableGroup = tableModel
                    }))
                })

            },
            sendReminder: (id: number, reminderSuccessHandler: () => void) => {
                useSendReminder(id).then(() => reminderSuccessHandler())
            }
        }
    }
})

export const quotesTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', '', ColumnType.STRING)
    nameColumn.childMapper = new TableColumnMapper('name', '', ColumnType.STRING)

    const projectColumn = new TableColumnMapper('project.name', '', ColumnType.STRING)

    const dateColumn = new TableColumnMapper('created_at', '', ColumnType.DATE)

    const ownerColumn = new TableColumnMapper('owner.name', '', ColumnType.STRING)

    const numberOfSellersColumn = new TableColumnMapper('number_of_sellers', '', ColumnType.STRING)
    numberOfSellersColumn.childMapper = new TableColumnMapper('seller_details.name', '', ColumnType.STRING)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', '', ColumnType.STRING)
    numberOfProductsColumn.childMapper = new TableColumnMapper('number_of_products', '', ColumnType.STRING)

    const readStatusColumn = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)
    readStatusColumn.childMapper = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)

    const statusColumn = new TableColumnMapper('status', '', ColumnType.STATUS, ColumnFormat.CHIP)
    statusColumn.childMapper = new TableColumnMapper('status', '', ColumnType.STATUS,)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        ownerColumn,
        numberOfSellersColumn,
        numberOfProductsColumn,
        readStatusColumn,
        statusColumn,
    ];
}