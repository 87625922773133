import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export type PasswordFieldProps = TextFieldProps;

export const PasswordField = React.memo(
  React.forwardRef<HTMLDivElement, PasswordFieldProps>((props, ref) => {
    const [show, setShow] = useState(false);

    const handleMouseDownPassword = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
      },
      []
    );
    const handleClickShowPassword = React.useCallback(() => {
      setShow(v => !v);
    }, []);

    return (
      <TextField
        {...props}
        type={show ? 'text' : 'password'}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        ref={ref}
      />
    );
  })
);

export default PasswordField;
