import { StateCreator } from "zustand";
import produce from "immer";

export interface BadgeSlice {
    procurementBadgeNumber: number,
    requisitionBadgeNumber: number,
    getBadgeNumber: () => void
}

export const createBadgeSlice: StateCreator<BadgeSlice> = (set, get, api) => ({
    procurementBadgeNumber: 0,
    requisitionBadgeNumber: 0,
    getBadgeNumber: () => {
        api.subscribe(
            state => {
                const numberOfDraftRequisitions = state.numberOfDraftRequisitions
                if (numberOfDraftRequisitions !== get().requisitionBadgeNumber)
                    set(produce(draftState => {
                        draftState.procurementBadgeNumber = numberOfDraftRequisitions
                        draftState.requisitionBadgeNumber = numberOfDraftRequisitions
                    }))
            },
        )
    }
})