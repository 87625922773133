import { productManagerEnv } from '@/config';
import { useProcurementStore } from '@/zustand';
import { useRouter } from 'next/router';
import React from 'react';
import { sm } from 'src/site-map';

const useEditUtils = () => {
  const router = useRouter();

  const { setDetails } = useProcurementStore(
    state => state.productFormManagerProperties.actions
  );
  const { bundleRfqModel, products, selectedMainProductId } =
    useProcurementStore(state => state.editBundleRFQDetailsSliceProperties);
  const { clearState } = useProcurementStore(
    state => state.editBundleRFQDetailsSliceActions
  );

  const formName = React.useMemo(() => {
    let formName = bundleRfqModel?.name;
    if (bundleRfqModel?.request_for_quotes.length === 1) {
      formName = bundleRfqModel?.request_for_quotes?.[0].name;
    }
    return formName;
  }, [bundleRfqModel]);

  const handleSetEnvironment = () => {
    setDetails(
      {
        ...bundleRfqModel,
        name: formName,
        products,
      },
      productManagerEnv.edit_bundle_rfq
    );
  };

  const handleAddProducts = () => {
    handleSetEnvironment();
    router.push(sm.catalogue.index.url);
  };

  const selectedMainProduct = React.useMemo(() => {
    return products?.find(prod => prod.id === selectedMainProductId);
  }, [products, selectedMainProductId]);

  const addAlternativesForSelectedProduct = () => {
    handleSetEnvironment();
    router.push(
      sm.catalogue.products.alternatives(
        // selectedMainProduct?.category.parent.parent.id,
        // selectedMainProduct?.category.parent.id,
        // selectedMainProduct?.category.id,
        selectedMainProduct?.id
      ).url
    );
  };

  const navigateToDetailsPage = () => {
    const isSingle = bundleRfqModel?.request_for_quotes?.length === 1;

    if (isSingle) {
      const singleRfq = bundleRfqModel?.request_for_quotes?.[0];
      router.push(
        sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(
          singleRfq?.id
        ).url
      );
    } else {
      router.push(
        sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(bundleRfqModel.id).url
      );
    }
  };

  const handleCancelEditing = () => {
    clearState();
    navigateToDetailsPage();
  };

  return {
    handleCancelEditing,
    navigateToDetailsPage,
    handleAddProducts,
    handleSetEnvironment,
    formName,
    addAlternativesForSelectedProduct,
  };
};

export default useEditUtils;
