import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from 'next/link';
import { sm } from 'src/site-map';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AlreadyExistsLink = ({ text, sellerId }: { text: string; sellerId: string | number | undefined }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', color: '#FF4D4D' }}>
      <ErrorOutlineIcon style={{ marginRight: '4px', fontSize: '14px' }} />
      <span style={{ fontSize: '13px', fontWeight: '400' }}>This {text} already exists. </span>
      <Link href={sm.catalogue.sellers.sellerId(sellerId).url} passHref>
        <span
          className='underline-on-hover'
          style={{
            cursor: 'pointer',
            textTransform: 'capitalize',
            color: '#13628C',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            marginLeft: '4px',
            fontSize: '13px',
          }}
        >
          View
          <OpenInNewIcon fontSize='14px' style={{ marginLeft: '4px', color: 'primary.main', fontWeight: 700 }} />
        </span>
      </Link>
    </span>
  );
};

export default AlreadyExistsLink;
