import create from "zustand";
import { devtools } from "zustand/middleware";
import { createProductsTableSlice, ProductsTableSlice } from "../shared/createProductsTableSlice";
import { createSellerProcurementSlice, SellerProcurementSlice } from "./seller-slices/createProcurementSlice";
import { createSellerPurchaseOrderDetailsSlice, SellerPurchaseOrderDetailsSlice } from "./seller-slices/createPurchaseOrderDetailsSlice";
import {
    createPurchaseOrderTableForSellerSlice,
    PurchaseOrdersForSellerTableSlice
} from "./seller-slices/createPurchaseOrderTableForSellerSlice";
import { createQuoteDetailsSlice, QuoteDetailsSlice } from "./seller-slices/createQuoteDetailsSlice";
import { createEditQuoteFromScratchSlice, EditQuoteFromScratchSlice } from "./seller-slices/createEditQuoteFromScratchSlice";
import { createRequestForQuoteSlice, RequestForQuoteSlice } from "./seller-slices/createRequestForQuoteSlice";
import { createRequestForQuotesForSellerTableSlice, RequestForQuotesForSellerTableSlice } from "./seller-slices/createRFQTableForSellerSlice";
import { createSellerQuotesTableSlice, SellerQuotesTableSlice } from "./seller-slices/createSellerQuotesTableSlice";

type StoreState = SellerProcurementSlice & RequestForQuoteSlice & SellerQuotesTableSlice & RequestForQuotesForSellerTableSlice
    & QuoteDetailsSlice & SellerPurchaseOrderDetailsSlice & PurchaseOrdersForSellerTableSlice & ProductsTableSlice & EditQuoteFromScratchSlice

export const useSellerProcurementStore = create<StoreState>(devtools((...a) => ({
    ...createSellerProcurementSlice(...a),
    ...createSellerQuotesTableSlice(...a),
    ...createRequestForQuotesForSellerTableSlice(...a),
    ...createQuoteDetailsSlice(...a),
    ...createEditQuoteFromScratchSlice(...a),
    ...createRequestForQuoteSlice(...a),
    ...createSellerPurchaseOrderDetailsSlice(...a),
    ...createPurchaseOrderTableForSellerSlice(...a),
    ...createProductsTableSlice(...a)
})))