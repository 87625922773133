import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { ProcurementButtons, ProcurementPopupStatus } from '@/config';
import { ProcurementActionsPopupContent, DescPopup } from '@/components';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import initialValues from './initialValues';
import { commentsWithReasonValidationSchema } from './validationSchema';
import { HighlightOff } from '@mui/icons-material';
import { QuoteDetailsBuyer, RFQDetails } from '@/models';
import { useProcurementStore } from '@/zustand';
import { QuoteFormPermission } from '@/services';

interface props {
  quote: QuoteDetailsBuyer | null;
}

export const QuoteDeclineAction: React.VFC<props> = ({ quote }) => {
  const { t } = useTranslation();
  const { getReasonsForPopup, decline, setPopupStatus } = useProcurementStore(
    state => state.quoteBuyerDetailsSliceActions
  );
  const { customLoadingButton, openedPopupStatus, reasonsForPopup } = useProcurementStore(
    state => state.quoteBuyerDetailsSliceProperties
  );

  const handleSubmit = (values, actions) => decline(values.reasonId, values.comment);
  const handleClosePopup = () => setPopupStatus(null);
  const openPopup = () => setPopupStatus(ProcurementPopupStatus.DECLINE);
  useEffect(() => {
    if (openedPopupStatus === ProcurementPopupStatus.DECLINE) getReasonsForPopup();
  }, [openedPopupStatus]);

  return (
    <>
      {quote?.quoteActions[QuoteFormPermission.DECLINE] && (
        <Button color='error' variant='outlined' onClick={openPopup}>
          {t('buttons.decline_quote')}
        </Button>
      )}
      <DescPopup handleClose={handleClosePopup} open={openedPopupStatus === ProcurementPopupStatus.DECLINE}>
        <Grid container gap={2} width={300}>
          <Formik
            initialValues={initialValues}
            validationSchema={commentsWithReasonValidationSchema}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <ProcurementActionsPopupContent
                  icon={<HighlightOff color='error' />}
                  popupTitle={t('buttons.decline', { formName: quote?.quoteModel?.name })}
                  buttonTitle={t('buttons.decline', { formName: quote?.quoteModel?.name })}
                  status={'error'}
                  popupSubTitle={t('popups.subTitles.decline_quote')}
                  reasonsForPopup={reasonsForPopup}
                  // checkLabel={t('texts.decline_rfq')}
                  isButtonLoading={customLoadingButton == ProcurementButtons.DECLINE_QUOTE_BY_BUYER}
                />
              </form>
            )}
          </Formik>
        </Grid>
      </DescPopup>
    </>
  );
};
