import {
  Grid,
  Button,
  useMediaQuery,
  CardMedia,
  Typography,
  Box,
} from '@mui/material';
import { ResponsiveContainer } from '@/styles';
import { Close } from '@mui/icons-material';
import { CompareOutlined } from '@/icons';

export const BlueBar = ({
  product,
  items,
  cancelEvent,
  createEvent,
  tabId,
  isAlternativesPage,
}) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Box>
      <Grid
        container
        component={ResponsiveContainer}
        alignItems='center'
        sx={{
          bgcolor: 'primary.main',
          height: isSmallScreen ? '140px' : '90px',
          zIndex: 4,
        }}
      >
        <Grid item lg={4} xs={12}>
          {product?.name && (
            <Box
              sx={{
                marginLeft: isSmallScreen ? 0 : 2,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box sx={{ position: 'relative', height: 65, marginRight: 1 }}>
                <CardMedia
                  component='img'
                  image={product.imageURL}
                  sx={{ height: '100%', width: '100%', objectFit: 'contain' }}
                />
              </Box>
              <Box sx={{ maxWidth: 250 }}>
                <Typography
                  fointSize='15px'
                  lineHeight={1.2}
                  color='white'
                  fontWeight='400'
                >
                  {product.name}
                </Typography>
                <Typography
                  color='secondary.main'
                  fontWeight='300'
                  variant='body2'
                  lineHeight={1.2}
                  mt={1}
                >
                  {isAlternativesPage ? items?.length + 1 : items?.length} items
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          lg={8}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
          }}
        >
          {isSmallScreen && (
            <Button
              onClick={() => createEvent()}
              size='small'
              variant='contained'
              color='secondary'
              startIcon={<CompareOutlined sx={{ width: 23, height: 23 }} />}
              sx={{ ml: 1 }}
            >
              {tabId ? 'Update Draft Requisition' : 'Create Draft Requisition'}
            </Button>
          )}
          <Button
            onClick={() => cancelEvent()}
            size='small'
            variant='contained'
            color='primary'
            startIcon={
              <Close sx={{ alignSelf: 'center', width: 23, height: 23 }} />
            }
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              width: isSmallScreen ? 60 : 180,
            }}
          >
            {!isSmallScreen && 'Cancel'}
          </Button>
          {!isSmallScreen && (
            <Button
              onClick={() => createEvent()}
              size='small'
              variant='contained'
              color='secondary'
              startIcon={<CompareOutlined sx={{ width: 23, height: 23 }} />}
              sx={{ ml: 1 }}
            >
              {tabId ? 'Update Draft Requisition' : 'Create Draft Requisition'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
