import * as React from 'react';
import { Typography, Button } from '@mui/material';
import type { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

interface SendVerificationOrLostProps {
    isDisabledButton: boolean,
    paragraphTranslate: string,
    userInfo?: string | number,
    verificationButtonTranslate: string,
    lostButtonTranslate: string,
    handleClickVerification: () => void,
    handleClickLost?: () => void,
    href?: string,
    timerButton?: boolean,
    timerValue?: number
}

export const SendVerificationOrLost: FC<SendVerificationOrLostProps> = (props: SendVerificationOrLostProps) => {

    return (
        <>
            <Typography variant='body2' sx={{ fontWeight: '400' }}>
                {props.paragraphTranslate}
                <Typography variant='body2' sx={{ fontWeight: '900' }}>{props.userInfo}</Typography>
            </Typography>
            <LoadingButton
                loading={props.timerButton}
                loadingIndicator={`Waiting…  ${props.timerValue}`}
                disabled={props.isDisabledButton}
                variant='contained'
                color='secondary'
                fullWidth
                sx={{ mt: 3 }}
                onClick={props.handleClickVerification}
            > {props.verificationButtonTranslate}
            </LoadingButton>

            <Button
                href={props.href}
                variant='outlined'
                fullWidth
                sx={{ mt: 2 }}
                onClick={props.handleClickLost}
            >{props.lostButtonTranslate}</Button>

        </>
    )
}