import { useProfilePasswordFlow } from '@/zustand';
import { Success } from '../shared-popups/success';
import { useTranslation } from 'next-i18next';
import { ChangePasswordFormByCurrent } from '../shared-popups/change-password-byCurrent';
import { SendVerification } from '../shared-popups/request-verification-in-password';
import { useChangePassword,useRequestVerificationCodePHONE, useRequestVerificationCodeEMAIL } from '@/services';
import { getAccountTranslationKey } from '@/hooks';
import { useState } from 'react';
import { ProfilePasswordByEmailPopupContent } from '../change-password-byEmail';
import { ProfilePasswordByPhonePopupContent } from '../change-password-byPhone';

type Props = {};

export const ProfilePasswordPopupContent: React.FC<Props> = () => {
    const PasswordPopupSteps = useProfilePasswordFlow(state => state.PasswordPopupSteps);
    const setChangePasswordSteps = useProfilePasswordFlow(state => state.setChangePasswordSteps);
    const togglePasswordPopup = useProfilePasswordFlow(state => state.togglePasswordPopup);
    const isPasswordByCurrentFinish = useProfilePasswordFlow(state => state.isPasswordByCurrentFinish);
    const [sentTo, setChangeSentTo] = useState(false);

    const { t } = useTranslation();
    const isTimer = useProfilePasswordFlow(state => state.isTimer);
    const timer = useProfilePasswordFlow(state => state.timer);
    const setIsTimer = useProfilePasswordFlow(state => state.setIsTimer);
    const setTimer = useProfilePasswordFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const { mutate } = useChangePassword();
    const handleSubmit = (values, actions) => { mutate({ values: values, actions: actions }) }
    
    const requestVerificationCodeEmail = useRequestVerificationCodeEMAIL();
    const handleSendVerificationEmail = () => { requestVerificationCodeEmail.mutate("EMAIL"),setChangeSentTo('EMAIL')}

    const requestVerificationCodePhone = useRequestVerificationCodePHONE();
    const handleSendVerificationPhone = () => { requestVerificationCodePhone.mutate("PHONE"),setChangeSentTo('PHONE')}

    const changePassword = [
        <ChangePasswordFormByCurrent
            handleClickNext={handleSubmit}
            handleDoNotRemember={()=>{setChangePasswordSteps(2)}}
            handleClickBack={() => togglePasswordPopup(false)}
        />,
        <Success 
            paragraphTranslate={t('notifications.change_password_success')}
            togglePopup={() => togglePasswordPopup(false)}
        />,
        <SendVerification
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.send_verification_password_Two_way'))}
            isDisabledEmailButton={requestVerificationCodeEmail.isLoading}
            isDisabledPhoneButton={requestVerificationCodePhone.isLoading}
            handleClickEmail={handleSendVerificationEmail}
            handleClickPhone={handleSendVerificationPhone}
            timerButton={isTimer}
            timerValue={timer}
        />,
    ]

    return (
        <>
            {   
                !isPasswordByCurrentFinish ? changePassword[PasswordPopupSteps] : sentTo === 'EMAIL' ? <ProfilePasswordByEmailPopupContent/> : <ProfilePasswordByPhonePopupContent/>
            }
        </>
    );
};