import * as React from 'react';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { Grid, TextField, ThemeProvider, createTheme } from '@mui/material';
import moment from 'moment';

type DateProps = {
  selectedDate: Date;
  formattedDate: string;
  handleDateChange: (date: any) => void;
};

export default function SelectDate({ selectedDate, formattedDate, handleDateChange }: DateProps) {
  const today = moment();


  const theme = createTheme({
    components: {
      MuiPickersDay: {
        styleOverrides: {
          day: {
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  });

  return (
    <Grid item md={12} pb={1}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            inputFormat="DD/MM/YYYY"
            renderInput={(props) => (
              <TextField
                value={formattedDate}
                {...props}
                sx={{ width: '100%' }}
              />
            )}
            PopperProps={{
              style: { minWidth: '370px', paddingLeft: 50 }
            }}
            minDate={today}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </Grid>
  );
}
