import { Grid, Typography, Button } from "@mui/material";
import { ResponsiveContainer } from "@/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import { getRegisterTranslationKey } from '@/hooks'

export const VerificationSuccess: React.FC<{}> = ({}) => {
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const { t } = useTranslation()
    return (
        <Grid container component={ResponsiveContainer}>
            <Grid item xs={12} sx={{ pt: [4,  16] }} >
                <Typography variant="h2" sx={{fontWeight: 'bolder'}}> {t(getRegisterTranslationKey('headers.verification_success'))} </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 3 }}>
                <Typography variant='body2' color='text.third'> {t(getRegisterTranslationKey('paragraphs.verification_success'))} </Typography>
            </Grid>
            <Grid item xs={12} my={4}>
                <Button
                    variant='contained'
                    href={sm.login.url}
                    sx={{
                        float: 'left',
                        width:  isMobile? '100%' : '176px'
                    }}
                > {t('buttons.go_to_login')} </Button>
            </Grid>
        </Grid>
    )
}