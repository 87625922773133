import { Grid, Button, Typography } from '@mui/material';
import { useRequisition } from '@/zustand';
import AddIcon from '@mui/icons-material/Add';
import { useRouter } from 'next/router';
import { Requisition } from '@/models';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import React from 'react';
import { ResponsiveContainer } from '@/styles';
import { CustomTermsSelect } from '@/components';
import { useTranslation } from 'next-i18next';
import { getRequisitionTranslationKey } from '@/hooks'
import { sm } from 'src/site-map';

type Props = {
  requisition: Requisition;
  selectedProductIndex: number;
  scroll: Function;
  isSmallScreen: boolean;
};
const smallBreakPoints = {
  lg: 6,
  xs: 12,
  md: 6,
} as const;

export const AddAltProductsSection: React.FC<Props> = ({
  requisition,
  selectedProductIndex,
  scroll,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      component={ResponsiveContainer}
      py={2}
      mt={isSmallScreen ? 1 : 0}
    >
      <Grid
        item
        container
        {...smallBreakPoints}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Grid
          item
          {...(isSmallScreen && {
            xs: 12,
            md: 6,
          })}
        >
          <AddAltProductsButton
            requisition={requisition}
            isSmallScreen={isSmallScreen}
            selectedProductIndex={selectedProductIndex}
          />
        </Grid>
        <Grid
          item
          {...(isSmallScreen && {
            xs: 12,
            md: 6,
          })}
          mt={isSmallScreen ? 1 : 0}
          ml={isSmallScreen ? 0 : 1}
        >
          <CustomTermsSelect
            requisition={requisition}
            reqProduct={requisition?.products[selectedProductIndex]}
            isSmallScreen={isSmallScreen}
          />
        </Grid>
      </Grid>
      <Grid
        item
        {...smallBreakPoints}
        mt={isSmallScreen ? 2 : 0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
        }}
      >
        {!isSmallScreen && (
          <>
            <Typography
              color='text.third'
              fontWeight='400'
              variant='body1'
              mt={1}
            >
              {t(getRequisitionTranslationKey('titles.added_products'))} {' '}
              {requisition?.products[selectedProductIndex]?.mainProduct
                ? requisition?.products[selectedProductIndex]?.altProducts
                    .length + 1
                : 0}
            </Typography>
            <Button
              onClick={() => scroll(-230)}
              // disabled={!embla?.canScrollNext()}
              sx={{
                position: 'sticky',
                backgroundColor: 'primary.light',
                maxWidth: '40px',
                maxHeight: '30px',
                minWidth: '40px',
                minHeight: '30px',
                textAlign: 'center',
                marginX: 2,
              }}
            >
              <ArrowBackIosNew sx={{ color: 'text.third', fontSize: '23px' }} />
            </Button>
            <Button
              onClick={() => scroll(+230)}
              sx={{
                position: 'sticky',
                backgroundColor: 'primary.light',
                maxWidth: '40px',
                maxHeight: '30px',
                minWidth: '40px',
                minHeight: '30px',
                textAlign: 'center',
              }}
            >
              <ArrowForwardIos sx={{ color: 'text.third', fontSize: '23px' }} />
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};
type AddAltProductsButtonProps = {
  isSmallScreen: boolean;
  requisition: Requisition;
  selectedProductIndex: number;
};

export const AddAltProductsButton: React.VFC<AddAltProductsButtonProps> = ({
  requisition,
  isSmallScreen,
  selectedProductIndex,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const toggleReq = useRequisition(state => state.toggleReq);
  return (
    <Button
      onClick={() => {
        toggleReq(
          requisition?.products[selectedProductIndex]?.mainProduct,
          requisition?.id,
          requisition?.products[selectedProductIndex]?.tabId,
          true
        );
        router.push({
          pathname: sm.searchResults.id(`alternative products for ${requisition?.products[
            selectedProductIndex
          ]?.mainProduct.name.replace(/\//g, '|')}`).url,
          query: {
            ...router.query,
            product_id:
              requisition?.products[selectedProductIndex]?.mainProduct
                .partNumber,
            tabId: requisition?.products[selectedProductIndex]?.tabId,
          },
        });
      }}
      disabled={
        requisition?.products[selectedProductIndex]?.mainProduct.partNumber
          ? false
          : true
      }
      endIcon={<AddIcon />}
      variant='contained'
      sx={{
        width: isSmallScreen ? '100%' : '176px',
        height: isSmallScreen ? '50px' : '40px',
        fontSize: '14px',
        fontWeight: '400',
      }}
    >
      {t(getRequisitionTranslationKey('titles.add_alternatives'))}
    </Button>
  );
};
