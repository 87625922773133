import { useProfile, useProfileEmailFlow } from '@/zustand';
import { VerificationCodeFormByPhone } from '../shared-popups/verification-code-form-byPhone';
import { ChangeEmailForm } from '../shared-popups/change-email-form';
import { SendVerificationOrLost } from '../shared-popups/send-verification-or-lost';
import { Success } from '../shared-popups/success';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { useState, useEffect } from 'react';
import { sm } from 'src/site-map';
import { VerificationCodeFormByEmail } from '../shared-popups/verification-code-form-byEmail'
import { useVerificationOldEmail,useRequestChangeEmail,
useVerificationNewEmail,useRequestVerificationOldEmailVaiPhone,
useVerificationNewEmailAgain,useVerificationEmailByPhoneAgain} from '@/services';

type Props = { };


export const ProfileChangeEmailByPhonePopupContent: React.FC<Props> = ({}) => {

    const { t } = useTranslation();
    const changeEmailByPhonePopupSteps = useProfileEmailFlow(state => state.changeEmailByPhonePopupSteps);
    const setChangeEmailByPhoneSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
    const toggleChangeEmailPopup = useProfileEmailFlow(state => state.toggleChangeEmailPopup);
    const user_info = useProfile(state => state.user_info);
    const [enabled, setEnabled] = useState(false);
    const temporaryEmail = useProfileEmailFlow(state => state.temporaryEmail);

    const isTimer = useProfileEmailFlow(state => state.isTimer);
    const timer = useProfileEmailFlow(state => state.timer);
    const setIsTimer = useProfileEmailFlow(state => state.setIsTimer);
    const setTimer = useProfileEmailFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const RequestVerificationOldEmailByPhone = useRequestVerificationOldEmailVaiPhone(enabled); 
    const ClickVerificationOldEmailByPhone = () => { RequestVerificationOldEmailByPhone.refetch() };

    const mutationRequestChangeEmailViaPhone = useVerificationOldEmail(); 
    const handleSubmitCode = (values, actions) => { mutationRequestChangeEmailViaPhone.mutate(values) } 
   
    const mutationChangeEmail = useRequestChangeEmail(); 
    const handleSubmitNewEmail = (values, actions) => { mutationChangeEmail.mutate(values)  } 
   
    const mutationVerificationNewEmail = useVerificationNewEmail(); 
    const handleSubmitCodeNew = (values, actions) => { mutationVerificationNewEmail.mutate(values) } 
   
    const ReRequestVerificationEmailByPhoneAgain = useVerificationEmailByPhoneAgain(enabled); 
    const handleClickCodeAgainOld = () => { ReRequestVerificationEmailByPhoneAgain.refetch() };

    const { mutate } = useVerificationNewEmailAgain(temporaryEmail);
    const handleClickCodeAgainNew = () => { mutate() }

    
    const change_Email = [
        <SendVerificationOrLost 
            isDisabledButton={RequestVerificationOldEmailByPhone.isFetching}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verify_email_via_phone'))}
            userInfo={user_info?.mobile_number.number}  
            verificationButtonTranslate={t('buttons.send_verification')} 
            lostButtonTranslate={t('buttons.lost_phone')}  
            handleClickVerification={ClickVerificationOldEmailByPhone}
            href={sm.contactUs.url}
            timerButton={isTimer}
            timerValue={timer}
        />,
        <VerificationCodeFormByPhone 
            isDisabledButton={mutationRequestChangeEmailViaPhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_phone' ))}
            userInfo={user_info?.mobile_number.number} 
            handleClickNext={handleSubmitCode} 
            handleClickBack={() => {setChangeEmailByPhoneSteps(changeEmailByPhonePopupSteps-1)}}
            handleClickAgain={handleClickCodeAgainOld}
        />,
        <ChangeEmailForm
            isDisabledButton={mutationChangeEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_email'))}
            handleClickNext={handleSubmitNewEmail} 
            handleClickBack={() => setChangeEmailByPhoneSteps(changeEmailByPhonePopupSteps-1)}
        />,
        <VerificationCodeFormByEmail 
            isDisabledButton={mutationVerificationNewEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_email' ))}
            userInfo={temporaryEmail} 
            handleClickNext={handleSubmitCodeNew} 
            handleClickBack={() => setChangeEmailByPhoneSteps(changeEmailByPhonePopupSteps-1)}
            handleClickAgain={handleClickCodeAgainNew}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.change_email_success' ))}
            userInfo={user_info?.email} togglePopup={() => toggleChangeEmailPopup(false)}
        />
    ]

    return (
        <>
            { change_Email[changeEmailByPhonePopupSteps]  }
        </>
    );
};