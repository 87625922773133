export const permissionsTableData = {
    none: { text: 'none', textColor: 'text.disabled', color: 'primary.light' },
    view_catalog: { text: 'view_catalog', textColor: null, color: null },
    update_catalog: { text: 'update_catalog', textColor: null, color: null },
    create_rfq: { text: 'create_rfq', textColor: null, color: null },
    generate_quote: { text: 'generate_quote', textColor: null, color: null },
    view_rfqs_supplier: { text: 'view_rfqs_supplier', textColor: null, color: null },
    create_po: { text: 'create_po', textColor: null, color: null },
    view_po: { text: 'view_po', textColor: null, color: null },
    GENERATE_PROFORMA_INVOICE_PERMISSION: { text: 'GENERATE_PROFORMA_INVOICE_PERMISSION', textColor: null, color: null },
    GENERATE_TAX_INVOICE_PERMISSION: { text: 'GENERATE_TAX_INVOICE_PERMISSION', textColor: null, color: null },
    EDIT_COMPANY: { text: 'EDIT_COMPANY', textColor: null, color: null },
    EDIT_USERS: { text: 'EDIT_USERS', textColor: null, color: null },
    portal_access_permission: { text: 'portal_access_permission', textColor: null, color: null },
}
export const rolesTableData = {
    none: { text: 'none', textColor: 'text.disabled', color: 'primary.light' },
    rfq_approver_role: { text: 'rfq_approver_role', textColor: null, color: null },
    supplier_approver: { text: 'supplier_approver', textColor: null, color: null },
    po_approver_role: { text: 'po_approver_role', textColor: null, color: null },
    INVOICE_APPROVER: { text: 'INVOICE_APPROVER', textColor: null, color: null },
    QUOTE_APPROVER_ROLE: { text: 'QUOTE_APPROVER_ROLE', textColor: null, color: null },
    ADMIN_BUYER: { text: 'ADMIN_BUYER', textColor: null, color: null },
    ADMIN_SELLER: { text: 'ADMIN_BUYER', textColor: null, color: null },
    REGULAR_BUYER: { text: 'REGULAR_BUYER', textColor: null, color: null },
    REGULAR_SELLER: { text: 'REGULAR_SELLER', textColor: null, color: null },
}
export const typesTableData = {
    none: { text: 'none', textColor: 'text.disabled', color: 'primary.light' },
    buyer_admin: { text: 'buyer_admin', textColor: 'secondary.contrastText', color: 'action.selected' },
    buyer_regular: { text:  'buyer_regular', textColor: 'secondary.contrastText', color: 'success.dark' },
    seller_admin: { text: 'seller_admin', textColor: 'secondary.contrastText', color: 'action.selected' },
    seller_regular: { text: 'seller_regular', textColor: 'secondary.contrastText', color: 'success.dark' },
}
export const statusesTableData = {
    none: { text: 'none', textColor: 'text.disabled', color: 'primary.light' },
    ACTIVE: { text: 'ACTIVE', textColor: 'primary', color: 'primary.light' },
    PENDING_APPROVAL: { text: 'PENDING_APPROVAL', textColor: 'white', color: 'secondary.main' },
    INVITED: { text: 'INVITED', textColor: 'secondary.contrastText', color: '#A93CCF' },
    UNAUTHORIZED: { text: 'UNAUTHORIZED', textColor: 'text.disabled', color: 'primary.light' },
}