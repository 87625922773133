import { productManagerEnv } from '@/config';
import { useProcurementStore } from '@/zustand';
import Description from '@mui/icons-material/Description';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { getQuoteProductsArray } from 'src/logic/zustand/procurement/seller/seller-slices/createQuoteDetailsSlice';
import { RadioButtonGroup } from 'src/ui/components/shared';
import DebouncedSearchInput from 'src/ui/components/shared/debounced-search-input';

export type QuoteOptionData = {
  active: boolean;
  id: number;
  name: string;
  sender_project: {
    id: number;
    name: string;
  };
  buyer_details: {
    id: number;
    name: string;
  };
};

type IProps = {
  onPopupSwitch: () => void;
  onPopupClose: () => void;
  quotesOptions: QuoteOptionData[];
};

const AddToExistingQuote = ({ onPopupSwitch, onPopupClose, quotesOptions }: IProps) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const product_id = data.candidateProductToAdd?.id;

  const [searchText, setsearchText] = React.useState<string>('');
  const optionsToRender = React.useMemo(() => {
    if (!searchText) return quotesOptions;
    const searchTerm = searchText.toLocaleLowerCase();
    return quotesOptions.filter(
      item =>
        item.name.toLocaleLowerCase().includes(searchTerm) ||
        item.buyer_details.name.toLocaleLowerCase().includes(searchTerm)
    );
  }, [searchText, quotesOptions]);

  const [selectedQuoteId, setSelectedQuoteId] = React.useState<number | ''>('');

  const selectLoading = useLoadingState();
  const { fetchQuoteDetails } = useProcurementStore(state => state.quoteDetailsSliceActions);
  const handleSelectQuote = () => {
    const product = data.candidateProductToAdd;
    if (!product || !product_id || !selectedQuoteId) return;

    selectLoading.startLoading();
    fetchQuoteDetails(selectedQuoteId, {
      onSuccess: quote => {
        actions.setDetails(
          { ...quote, products: getQuoteProductsArray(quote.quote_products) },
          productManagerEnv.quote
        );
        onPopupClose();
        actions.addProduct(product);
      },
      onError: selectLoading.finishLoading,
    });
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, width: '100%' }}>
        <Description color='primary' />
        <Typography color='primary' variant='overline' fontWeight='700' mt={1} textAlign={'center'}>
          Add To Quote
        </Typography>
        <Typography color='#3A3E50' fontWeight={400} fontSize={14} mt={1} textAlign={'center'}>
          This item can be added to draft P.O.s of applicable sellers only. Please select from the list below.
        </Typography>
      </Stack>
      <Box width={'100%'} mb={2}>
        <DebouncedSearchInput onChange={setsearchText} translateKey={'search_quotes'} />
      </Box>
      <Stack my={1} maxHeight='200px'>
        <Stack pl={1}>
          <RadioButtonGroup
            data={optionsToRender}
            handleChange={(id: number) => setSelectedQuoteId(id)}
            value={selectedQuoteId}
            getSubTitle={(item: QuoteOptionData) => `${item.buyer_details.name}, ${item.sender_project.name}`}
            isItemDisabled={(item: QuoteOptionData) => !item.active}
          />
        </Stack>
      </Stack>
      <LoadingButton
        loading={selectLoading.isLoading}
        sx={{ mt: 2 }}
        type='button'
        fullWidth
        variant='contained'
        disabled={!selectedQuoteId}
        onClick={handleSelectQuote}
      >
        Select Quote
      </LoadingButton>
      <Button
        type='button'
        color='primary'
        variant='text'
        fullWidth
        sx={{ mt: 1, fontWeight: 500 }}
        onClick={onPopupSwitch}
        disabled={selectLoading.isLoading}
      >
        Or Create A New Quote
      </Button>
    </Box>
  );
};

export default AddToExistingQuote;
