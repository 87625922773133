import { Link, Logo } from '@/components';
import { ResponsiveContainer } from '@/styles';
import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import { UserType } from '@/config';
import { MobileDrawer } from '../drawer-mobile';

type Props = {};
export const Header: React.VFC<Props> = () => {
  const { t } = useTranslation();
  const smallScreens = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Box>
      <Grid container component={ResponsiveContainer} sx={{ bgcolor: 'secondary.contrastText', height: '60px', alignItems: 'center' }}>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: "100%"
        }}>
          {
            smallScreens ?
              <MobileDrawer userType={UserType.UNDEFIENED} />
              :
              <Link href={sm.home.url} underline='none' sx={{ alignItems: 'center' }}>
                <Logo />
              </Link>
          }
          <Link href={sm.home.url} underline='none' sx={{ alignItems: 'end' }}>
            <Typography fontWeight={'bolder'} color="text.primary">
              {t('buttons.back_to_site')}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
