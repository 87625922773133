import { SvgIconProps, SvgIcon } from '@mui/material';

export const GoogleIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d="M6.43243 14.0856L5.73626 16.6845L3.19176 16.7383C2.43133 15.3279 2 13.7142 2 11.9993C2 10.3411 2.40328 8.77731 3.11813 7.40039H3.11868L5.38399 7.8157L6.37634 10.0674C6.16864 10.6729 6.05544 11.3229 6.05544 11.9993C6.05552 12.7334 6.18849 13.4367 6.43243 14.0856Z" fill="#FBBB00"/>
    <path d="M21.8268 10.1318C21.9417 10.7368 22.0016 11.3615 22.0016 12C22.0016 12.7159 21.9263 13.4143 21.7829 14.0879C21.2961 16.3802 20.0241 18.3819 18.262 19.7983L18.2615 19.7978L15.4082 19.6522L15.0044 17.1313C16.1736 16.4456 17.0873 15.3725 17.5687 14.0879H12.2214V10.1318H17.6467H21.8268Z" fill="#518EF8"/>
    <path d="M18.2596 19.7985L18.2601 19.799C16.5464 21.1765 14.3694 22.0006 11.9997 22.0006C8.19142 22.0006 4.88043 19.8721 3.19141 16.7397L6.43208 14.0869C7.27657 16.3407 9.45076 17.9452 11.9997 17.9452C13.0953 17.9452 14.1217 17.649 15.0024 17.132L18.2596 19.7985Z" fill="#28B446"/>
    <path d="M18.3814 4.30219L15.1419 6.95439C14.2303 6.38462 13.1528 6.05548 11.9985 6.05548C9.39184 6.05548 7.17699 7.73349 6.3748 10.0681L3.11709 7.40111H3.11655C4.78085 4.19231 8.13359 2 11.9985 2C14.4248 2 16.6496 2.8643 18.3814 4.30219Z" fill="#F14336"/>
  </SvgIcon>
);
