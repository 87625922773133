import { useRouter } from 'next/router';
import React from 'react';

type Props = {
  name: string;
  value: string | number | undefined;
}[];

const useLinkStateToQueryParam = (
  stateParams: Props,
  dependency: any[] = []
) => {
  const router = useRouter();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(router.query);

    stateParams.forEach(({ name, value }) => {
      if (!value) return;
      const valueAsString =
        typeof value === 'string' ? value : value?.toString() ?? '';
      searchParams.set(name, valueAsString);
    });

    router.replace(`${router.pathname}?${searchParams.toString()}`, undefined, {
      shallow: true,
    });
  }, dependency);
};

export default useLinkStateToQueryParam;
