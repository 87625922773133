import ARROW from './arrows.png';
import CM from './cmyk.png';
import LAYER from './layer.png';
import RGB from './rgb.png';
import INS from './2.png';
import FB from './1.png';
import TW from './3.png';
import Linkedin from './linkedin.png';
import MAP from './map.png';
import PH from './phone.png';
import EM from './email.png';
import SE from './search.png';
import SEW from './searchWhite.png';
export { ARROW, CM, LAYER, RGB, INS, FB, TW, MAP, PH, EM, SE, SEW ,Linkedin};
