import { RequisitionPopupStatus, RequisitionProductType } from '@/config';
import { Product, Requisition, RequisitionProducts } from '@/models';
import { useGetAllRequisitions } from '@/services';
import create from 'zustand';

export const useProductsRequisition = create((set: Function, get: Function) => ({
    requisitions: [] as Requisition[],
    currentRequisition: null as Requisition | null,
    requisitionProductDetails: { quantity: 0, product_id: null },
    isRequisitionPopupOpened: false,
    requisitionPopupStatus: RequisitionPopupStatus.loading,
    showRequisitionSummary: false,

    getRequisitions: () => {
        const onSuccess = (requisitions: Requisition[]) => {
            if (requisitions.length > 0) set({ requisitionPopupStatus: RequisitionPopupStatus.selectRequisition, requisitions })
            else set({ requisitionPopupStatus: RequisitionPopupStatus.createRequisition, requisitions })
        }
        const query = useGetAllRequisitions(onSuccess)
    },
    getRequisition: (requisitionId: number) => { return get().requisitions?.find((requisition: Requisition) => requisition.id === requisitionId) },
    setCurrentRequisition: (requisition: Requisition | null) => set({ currentRequisition: requisition }),
    setCreateRequisition: (requisition: Requisition | null) => set({ currentRequisition: requisition, isRequisitionPopupOpened: false }),
    
    getProductDetails: (id: number, productType: RequisitionProductType, mainProductId?: string) => {
        if (productType == RequisitionProductType.main_product) return getRequisitionMainProductDetails(id, get().currentRequisition)
        return getRequisitionAlternativeProductDetails(id, mainProductId, get().currentRequisition)
    },
    selectRequisition: (requisitionId: number) => {
        const currentRequisition = get().requisitions.find((requisition: Requisition) => requisition.id === requisitionId)
        set({ currentRequisition: currentRequisition, isRequisitionPopupOpened: false })
    },

    toggleRequisitionSummaryPopup: () => set({ showRequisitionSummary: get().showRequisitionSummary }),
    setRequisitionPopupStatus: (requisitionPopupStatus: string) => set({ requisitionPopupStatus: requisitionPopupStatus }),
    openRequisitionPopup: (quantity: number, product_id: number) => set({ isRequisitionPopupOpened: true, requisitionPopupStatus: RequisitionPopupStatus.loading, requisitionProductDetails: { quantity: quantity, product_id: product_id } }),
    closeRequisitionPopup: () => set({ isRequisitionPopupOpened: false }),
    changeProductQuantity: (productId: number, quantity: number) => {
        const requisitionProducts = get().currentRequisition.products.map(product => {
            return product.main_product.id == productId ? { ...product, quantity: quantity } : product
        })
        set({ currentRequisition: { ...get().currentRequisition, products: requisitionProducts } })
    },
    getProductQuantity: (productId: number) => {
        return getMainProductDetails(productId, get().currentRequisition)?.quantity;
    }
}))


const getMainProductDetails = (productId: number, currentRequisition: Requisition) => {
    return currentRequisition?.products?.find((requisitionProduct: RequisitionProducts) => requisitionProduct.main_product.id == productId)
}


const getRequisitionMainProductDetails = (productId: number, currentRequisition: Requisition) => {
    const productDetails = getMainProductDetails(productId, currentRequisition)
    if (productDetails) return { quantity: productDetails.quantity, isAddedToRequisition: true }
    else return { quantity: 1, isAddedToRequisition: false };
}
const getRequisitionAlternativeProductDetails = (alternaitveProductId: number, mainProductId: number, currentRequisition: Requisition) => {
    const mainProductDetails = getMainProductDetails(mainProductId, currentRequisition)
    const alternativeProductDetails = mainProductDetails ? mainProductDetails.alternative_products.find((alternativeProduct: Product) => alternativeProduct.id === alternaitveProductId) : null
    if (alternativeProductDetails) return { isAddedToRequisition: true }
    else return { isAddedToRequisition: false };
}