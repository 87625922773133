import { HowItWorksCard } from '@/components';
import { useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Grid, Typography } from '@mui/material';
import { LAYER } from 'public/assets/icons';
import { motion } from 'framer-motion';
import { getBecomeSellerTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';

type Props = {};

export const WhatWeCanOfferOneBecomeASellerSection: React.FC<Props> = ({}) => {

  const { t } = useTranslation();

  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  const offers = [
    {
      primaryText: 'accessing',
      secondText: 'accessing',
    },
    {
      primaryText: 'analytics',
      secondText: 'analytics',
    },
    {
      primaryText: 'digitization',
      secondText: 'digitization',
    },
    {
      primaryText: 'competitive',
      secondText: 'competitive',
    },
  ];
  return (
    <>
      <ResponsiveContainer
        as={motion.div}
        {...first}
        sx={{
          typography: 'h2',
          textAlign: 'center',
          fontWeight: '700',
        }}
      >
          {t(getBecomeSellerTranslationKey('titles.what_offer'))}  
      </ResponsiveContainer>
      {/* <ResponsiveContainer
        as={motion.div}
        {...second}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <Typography
          color='text.third'
          fontWeight='400'
          frontSize='14px'
          textAlign='center'
          maxWidth={560}
        >
          Amet, id integer ultricies lorem. Eu volutpat risus hendrerit egestas
          placerat lectus nisi rhoncus ornare. Quis volutpat tincidunt sed
          interdum viverra. Quis dolor vitae enim, viverra sodales. Ultrices id
          viverra diam diam nunc sem commodo.
        </Typography>
      </ResponsiveContainer> */}
      <Grid
        component={ResponsiveContainer}
        container
        spacing={2}
        pt={6}
        justifyContent='center'
      >
        {offers.map((offer, i) => (
          <Grid item xl={3} lg={6} xs={12} key={i}>
            <motion.div {...third} style={{ height: '100%' }}>
              <HowItWorksCard
                primaryText={t(getBecomeSellerTranslationKey(`titles.${offer.primaryText}`))}  
                secondaryText={t(getBecomeSellerTranslationKey(`sub_titles.${offer.secondText}`))} 
                icon={LAYER}
              />
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
