import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Empty } from '../empty';
import { LoadingRect } from '../loading-rect';
import { useTranslation } from 'next-i18next';

type LoadingDataProps = {
  empty?: boolean;
  refetching?: boolean;
  customError?: ReactElement;
  customEmpty?: ReactElement;
  customStanding?: ReactElement;
  customLoading?: ReactElement;
} & (
  | {
      loading: boolean;
      dataValid?: boolean;
      standing?: boolean;
    }
  | {
      status: 'idle' | 'success' | 'error' | 'loading';
    }
);

export const LoadingData: React.FC<LoadingDataProps> = props => {
  const { t } = useTranslation();

  const {
    customError = <div>{t('texts.error')}</div>,
    customEmpty = <Empty />,
    empty: isEmpty = false,
    customStanding = <div>{t('texts.standing')}</div>,
    customLoading = <LoadingRect />,
    refetching = false,
    children,
  } = props;

  if ('status' in props) {
    switch (props.status) {
      case 'idle':
        return customStanding;

      case 'loading':
        return customLoading;

      case 'error':
        return customError;

      case 'success':
        if (refetching)
          return (
            <Box sx={{ position: 'relative' }}>
              {children}
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  backdropFilter: 'blur(2px)',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  fontSize: '2rem',
                  left: '50%',
                  top: '30%',
                  transform: 'translate(-50%,-50%)',
                }}
              >
                <LoadingRect />
              </Box>
            </Box>
          );

        if (isEmpty) return customEmpty;

        return <>{children}</>;

      default:
        return customStanding;
    }
  } else {
    const { loading, children, dataValid = true } = props;
    if (loading) return customLoading;
    if (dataValid) {
      if (refetching)
        return (
          <Box
            sx={{
              position: 'relative',
            }}
          >
            {children}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                backdropFilter: 'blur(2px)',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                fontSize: '2rem',
                left: '50%',
                top: '30%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <LoadingRect />
            </Box>
          </Box>
        );

      if (isEmpty) return customEmpty;

      return <>{children}</>;
    }
    return customError;
  }
};
