import { useDrawerStore } from '@/zustand';
import { ListItem } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { ExpandableListItemWithIcon } from '../../shared';
import { SideDrawerSubMenuLink } from './drawer-submenu-link';

interface SideDrawerSubMenuItemProps { drawerItemDetails: any, isDrawerOpen: boolean, index: number }

export const SideDrawerSubMenuItem: React.FC<SideDrawerSubMenuItemProps> = ({ drawerItemDetails, isDrawerOpen, index }) => {
  const router = useRouter()
  const { t } = useTranslation();
  const textRef = React.useRef();
  const addItemToSubMenuTree = useDrawerStore(state => state.addItemToSubMenuTree)
  const drawerSubMenuParent = useDrawerStore(state => state.drawerSubMenuParent)
  const addItemToSubMenuTitlesTree = useDrawerStore(state => state.addItemToSubMenuTitlesTree)
  const drawerSubMenuQueryTitlesTree = useDrawerStore(state => state.drawerSubMenuQueryTitlesTree)
  const drawerSubMenuIndexesTree = useDrawerStore(state => state.drawerSubMenuIndexesTree)
  const closeMobileDrawer = useDrawerStore(state => state.closeMobileDrawer)
  const closeDrawer = useDrawerStore(state => state.closeDrawer)
  const isDynamicItem = drawerSubMenuParent?.subMenuContent?.url
  const currentRoute = !isDynamicItem ? router.asPath.split('/')[drawerItemDetails.routeIndex] : Object.values(router.query)[drawerSubMenuIndexesTree?.length]
  const drawerItemRoute = !isDynamicItem ? drawerItemDetails.url?.split('/')[drawerItemDetails.routeIndex] : drawerItemDetails.title
  const currentRouteUrl = !isDynamicItem && currentRoute?.includes('?') ? currentRoute.slice(0, currentRoute.indexOf('?')) : currentRoute

  const handleDrawerItemClick = () => {
    if (drawerItemDetails.subs) {
      addItemToSubMenuTitlesTree(drawerItemDetails.title, drawerItemDetails.id)
      addItemToSubMenuTree(index)
    }
    else if (isDynamicItem) {
      closeMobileDrawer()
      closeDrawer()
      addItemToSubMenuTitlesTree(drawerItemDetails.title, drawerItemDetails.id)
      router.push(drawerSubMenuParent?.subMenuContent.url(...Object.values(drawerSubMenuParent?.subMenuPathQuery)))
    }
    else {
      router.push(drawerItemDetails.url)
      closeDrawer()
      closeMobileDrawer()
    }
  }

  return (
    <ListItem disablePadding>
      {drawerItemDetails.link ? <SideDrawerSubMenuLink drawerItemDetails={drawerItemDetails} />
        : <ExpandableListItemWithIcon
          title={isDynamicItem ? drawerItemDetails.title : t("pages_names." + drawerItemDetails.title)}
          handleClickListItem={handleDrawerItemClick}
          isItemExpanded={isDrawerOpen}
          isItemContainsSubItem={drawerItemDetails.subs}
          disabled={!drawerItemDetails.subs && drawerSubMenuQueryTitlesTree.length == 0 && isDynamicItem}
          selected={currentRouteUrl == drawerItemRoute}
          notificationNumber={drawerItemDetails.notificationNumber && drawerItemDetails.notificationNumber()}
        />
      }
    </ListItem>
  )
}
