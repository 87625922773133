import React, { useCallback, useState } from 'react';
import { useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { PH, EM } from 'public/assets/icons';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Card,
  CardProps,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NotifyError, NotifySuccess, useVacancyQuestionaire } from '@/services';
import { useVacancies } from '@/services';
import * as yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'next-i18next';
import { Loading } from '@/components';
import { getCareerTranslationKey } from '@/hooks';

type Props = {};

export const QuestionCareersSection: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('lg'));
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });

  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  const [placeholder, setPlaceholder] = useState(true);
  const [sent, setSent] = useState(false);
  const { asPath } = useRouter();
  const careerId = asPath.split('/')[2];
  //Use form data controls
  const schema = yup.object().shape({
    full_name: yup.string().required().label('Full name'),
    email: yup.string().email().required().label('E-mail'),
    job_id: careerId
      ? yup.number().optional()
      : yup.number().required().positive().label('Vacancy'),
    phone_number: yup.string().phone().required().label('Phone number'),
    message: yup.string().required().label('Message'),
  });
  type Inputs = {
    full_name: string;
    email: string;
    phone_number: string;
    job_id: number;
    message: string;
  };
  //useform
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isSubmitting, errors, isValid },
  } = useForm<Inputs>({
    resolver: yupResolver(schema) as any,
    mode: 'onChange',
  });
  const { data, status } = useVacancies()
  const onError = () => {
    NotifyError(t('notifications.server_error'))
  }
  const onSuccess = () => {
    NotifySuccess(t('notifications.received'));

    setSent(true);
    setPlaceholder(true);
    setValue('job_id', -1);
    reset({
      full_name: '',
      email: '',
      phone_number: '',
      job_id: -1,
      message: '',
    });
  };
  const { mutate } = useVacancyQuestionaire({ onSuccess, onError });

  const onSubmit = useCallback<SubmitHandler<Inputs>>(
    async data => {
      if (careerId) {
        mutate({ ...data, job_id: careerId, });
        return;
      }
      mutate({ ...data });
    },
    // [contactUs, flatApplicant]
    []
  );
  //input Styles
  const inputStyle = { WebkitBoxShadow: 'none', width: '100%' };
  const textInputStyle = {
    bgcolor: 'white',
    borderRadius: 1,
    mt: 1,
    height: '40px',
  };
  return (
    <Grid mt={4} position='relative' container>
      {' '}
      <Box
        sx={{
          position: 'absolute',
          width: isSmallScreen ? '100%' : '55%',
          height: '100%',
          left: 0,
          zIndex: -1,
          bgcolor: 'background.primary.main',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: isSmallScreen ? '100%' : '50%',
          height: '100%',
          left: 0,
          bottom: 0,
          zIndex: -1,
          backgroundImage: 'url(/assets/images/home/points.svg)',
          opacity: 0.8,
          top: 15,
        }}
      />
      <Grid item xl={6} xs={12} sx={{ py: 8 }} mb={6} mt={4}>
        <ResponsiveContainer>
          <motion.div {...first}>
            <Typography fontWeight='700' variant='h2'>
              {careerId
                ? t(getCareerTranslationKey('titles.vacancy_this_question'))
                : t(getCareerTranslationKey('titles.vacancy_a_question'))
              }
            </Typography>
          </motion.div>
          <Box py={3} />
          <Stack component={motion.div} spacing={2} {...second}>
            <Typography
              component='label'
              color='text.third'
              fontWeight='400'
              variant='subtitle2'
              sx={{ display: 'block' }}
            >
              <div>{t('input_fields.name')}</div>
              <TextField
                InputProps={{ sx: textInputStyle }}
                sx={inputStyle}
                placeholder={t('input_fields.name_field')}
                error={!!errors.full_name}
                helperText={errors.full_name?.message}
                {...register('full_name')}
              />
            </Typography>
            <Typography
              component='label'
              color='text.third'
              fontWeight='400'
              variant='subtitle2'
              sx={{ display: 'block' }}
            >
              <div>{t('input_fields.email')}</div>
              <TextField
                InputProps={{ sx: textInputStyle }}
                sx={inputStyle}
                placeholder={t('input_fields.email_field')}
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email')}
              />
            </Typography>
            <Typography
              component='label'
              color='text.third'
              fontWeight='400'
              variant='subtitle2'
              sx={{ display: 'block' }}
            >
              <div>{t('input_fields.phone')}</div>
              <TextField
                InputProps={{ sx: textInputStyle }}
                sx={inputStyle}
                placeholder={t('input_fields.phone_field')}
                error={!!errors.phone_number}
                helperText={errors.phone_number?.message}
                {...register('phone_number')}
              />
            </Typography>
            {!careerId && (
              <Typography
                component='label'
                color='text.third'
                fontWeight='400'
                variant='subtitle2'
                sx={{ display: 'block' }}
              >
                <div>{t(getCareerTranslationKey('titles.vacancy'))}</div>
                {status === 'loading' ? (
                  <Loading />
                ) : (
                  <>
                    <Select
                      defaultValue={-1}
                      error={!!errors.job_id}
                      size='small'
                      {...register('job_id', {
                        onChange: val => {
                          setPlaceholder(false);
                        },
                      })}
                      MenuProps={{ style: { zIndex: 0, maxHeight: 300, maxWidth: 190 } }}
                      inputProps={{ sx: { height: '25px', marginTop: '5px' } }}
                      value={placeholder ? -1 : getValues('job_id')}
                      sx={{
                        height: '25px', marginTop: '-1px',
                        width: '100%',
                        bgcolor: 'white',
                        borderRadius: 1,
                        height: '40px',
                        textTransform: 'capitalize',
                        color: placeholder ? 'text.third' : '#000000',
                      }}
                    >
                      <MenuItem value={-1} disabled hidden>
                        {t(getCareerTranslationKey('texts.vacancy_select'))}
                      </MenuItem>
                      {data
                        ? data.data.map((vacancy, index) => {
                          return (
                            <MenuItem
                              sx={{ textTransform: 'capitalize' }}
                              key={index}
                              value={vacancy.job_id}
                            >
                              {vacancy.title}
                            </MenuItem>
                          );
                        })
                        : ''}
                    </Select>
                    {errors.job_id ? (
                      <FormHelperText error sx={{ ml: 2 }}>
                        {t(getCareerTranslationKey('texts.vacancy_required'))}
                      </FormHelperText>
                    ) : null}
                  </>
                )}
              </Typography>
            )}
            <Typography
              component='label'
              color='text.third'
              fontWeight='400'
              variant='subtitle2'
              sx={{ display: 'block' }}
            >
              <div>{t('input_fields.message')}</div>
              <TextField
                multiline
                minRows={5}
                maxRows={5}
                fullWidth
                inputProps={{ maxLength: 500, }}
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                style={{ overflow: 'auto', height: 150 }}
                InputProps={{
                  sx: {
                    bgcolor: 'white', minHeight: 120, fontSize: '14px', color: '#8B8D98', fontWeight: 400,
                    '& ::placeholder': {
                      fontSize: '14px'
                    },
                  }
                }}
                placeholder={t('input_fields.message_field')}
                error={!!errors.message}
                helperText={errors.message?.message}
                {...register('message')}
              />
            </Typography>
            <div>
              <LoadingButton
                variant='contained'
                color={isValid || sent ? 'secondary' : 'inherit'}
                onClick={handleSubmit(onSubmit)}
                loading={isSubmitting}
                sx={{
                  color: isValid ? 'white' : 'text.third',
                  fontSize: '14px !important',
                  fontWeight: 500,
                  height: '40px',
                  width: isSmallScreen ? '100%' : '64px',
                }}
                disabled={sent}
                endIcon={sent ? <CheckIcon height='16px' /> : null}
              >
                {sent ? t('buttons.sent') : t('buttons.send')}
              </LoadingButton>
            </div>
          </Stack>
        </ResponsiveContainer>
      </Grid>
      <Grid
        item
        sx={{ bgcolor: 'white', py: 10 }}
        xl={6}
        xs={12}
        {...third}
        component={motion.div}
      >
        <ResponsiveContainer>
          <Typography variant='h5' fontWeight='bold' mb={1} mt={3}>
            {t(getCareerTranslationKey('titles.hr_manager'))}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: isSmallScreen ? 'center' : 'inherit',
            }}
          >
            <ManagerCard
              name='Dianne Russell'
              image='/assets/images/careers/woman.png'
            />
          </Box>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

type ManagerCardProps = {
  sx?: CardProps['sx'];
  name?: string;
  image: string;
};

const ManagerCard: React.VFC<ManagerCardProps> = ({ sx, name, image }) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('lg'));
  return (
    <Card
      component={motion.div}
      variant='outlined'
      sx={{
        ...sx,
        borderColor: isSmallScreen ? 'white' : 'action.disabledBackground',
        display: 'flex',
        borderRadius: 2,
        boxShadow: isSmallScreen ? 2 : 0,
        backgroundColor: isSmallScreen ? 'white' : 'warning.dark',
        minHeight: '160px',
        width: isSmallScreen ? '270px' : '464px',
        alignItems: 'center',
      }}
    >
      <Grid container m={3}>
        <Grid item lg={5} xs={12}>
          <Box
            sx={{
              position: 'relative',
              width: 120,
              height: 120,
              flex: '0 0 auto',
            }}
          >
            <Image src={image} alt='Manager' layout='fill' />
          </Box>
        </Grid>
        <Grid item lg={7} xs={12}>
          <Box
            mt={2}
            sx={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography mb={3} variant='h6' fontWeight='medium'>
              {name}
            </Typography>
            <Typography
              component={Stack}
              alignItems='center'
              spacing={1}
              direction='row'
              onClick={() =>
                (window.location.href = 'mailto:hr@eprocurement.ae')
              }
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
                color: 'text.third',
              }}
              variant='body2'
            >
              <Image src={EM} objectFit='contain' alt='email' />
              <span>hr@eprocurement.ae</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
