import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
    console.error('Uncaught error: ', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: '100%',
            minHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              maxWidth: '500px',
              marginInline: 'auto',
              position: 'relative',
              transform: 'translateY(-50%)',
            }}
          >
            <h1 style={{ margin: 0, color: '#FB7F0D' }}>Oops!</h1>
            <h2 style={{ maxWidth: '90%', lineHeight: 1.25, color: '#00385E' }}>
              Something went wrong, please refresh the page & try again
            </h2>
            <img
              width={'100%'}
              height={'auto'}
              src='/assets/images/oops.png'
              alt=''
              style={{
                transform: 'translate(20%, 70%)',
                position: 'absolute',
                bottom: 0,
                right: 0,
              }}
            />
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
