import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { ConfirmPopup, GeneralTermsButton, RFQProductCard } from "@/components";
import { GeneralObject, ProcurementFormModel, RFQProduct } from "@/models"
import { useRouter } from 'next/router'
import { sm } from "src/site-map";
import { useProcurementStore, useUserData } from "@/zustand";
import React from "react";
import usePopup from "src/logic/hooks/use-popup";
import { RfqProductsTableHeader } from "src/ui/components/dumb/shared/rfq-products-table-header";
import PrimaryBadge from "src/ui/components/dumb/shared/primary-badge";
import GeneralTerms from "src/ui/components/smart/general-terms/GeneralTerms";


type RFQDetailsProps = {
    requestForQuoteDetails: ProcurementFormModel
    changeProject: (project: GeneralObject) => void
}
export const RFQBuyerDetails: React.FC<RFQDetailsProps> = ({ requestForQuoteDetails, changeProject }) => {
    const { t } = useTranslation();
    let requestForQuoteModel = requestForQuoteDetails.model
    const router = useRouter();

    const { deleteProduct } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceActions)
    const { productIdDeleting } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceProperties)


    const [productIdToDelete, setProductIdToDelete] = React.useState<number>();
    const confirmDeleteLastProduct = usePopup();
    const isLastProduct = requestForQuoteDetails?.numberOfProducts === 1;
    const handleDeleteProduct = (productId: number) => {
        deleteProduct(productId, requestForQuoteModel.id, () => router.push(sm.portals.buyer.procurement.requestForQuotes.index.url));
        if(confirmDeleteLastProduct.isOpen) {
            confirmDeleteLastProduct.handleClose();
        }

    }
    const onDeleteProductClick = (productId: number) => {
        setProductIdToDelete(productId);
        if(isLastProduct) {
            confirmDeleteLastProduct.handleOpen();
        } else {
            handleDeleteProduct(productId)
        }
    }

    const userId = useUserData.getState().userInfo?.id
    const isOwner = userId == requestForQuoteModel.owner.id
    const isProductDeletable = requestForQuoteModel.deletable && isOwner
    return (
        <>
            <ConfirmPopup
                titleKey={`Delete Product`}
                subTitleKey={t("popups.subTitles.delete_last_product_in_rfq")}
                buttonTitleKey={t("buttons.delete")}
                handleClose={confirmDeleteLastProduct.handleClose}
                handleConfirm={() => handleDeleteProduct(productIdToDelete)}
                open={confirmDeleteLastProduct.isOpen}
            />
             {/* <Grid container mt={2} justifyContent={'flex-end'}> */}
             <Grid container mt={2} mb={2}>
                <GeneralTerms mode='view-only' warranty_term={requestForQuoteModel?.terms_bundle?.warranty_term} payment_term={requestForQuoteModel?.terms_bundle?.payment_term} delivery_term={requestForQuoteModel?.terms_bundle?.delivery_term} />
            </Grid>
            <Typography mb={1} mt={2} variant='overline' fontWeight={700}>Products / Services <PrimaryBadge>{requestForQuoteDetails.numberOfProducts}</PrimaryBadge></Typography>

            <Grid item container md={12} gap={1.5}>
                <RfqProductsTableHeader />
                {requestForQuoteModel?.products?.map((element: RFQProduct) => <RFQProductCard key={element.id} withDetailsPopup deleteProductLoading={productIdDeleting === element.id} deletable={isProductDeletable} rfqData={element} warrantyGeneralTerm={requestForQuoteModel.terms_bundle.warranty_term} handleDeleteProduct={onDeleteProductClick} />)}
            </Grid>

        </>
    )
}