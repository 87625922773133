import React from 'react';

export const dropdownContainerStyles: React.CSSProperties = {
  // maxWidth: '360px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #E8E8EA',
  borderRadius: '4px',
  padding: '6px 12px',
  width: '100%'
};
