import { procurementStatus, productManagerEnv } from "@/config";
import { ProcurementFormPermission } from "@/services";
import { useProcurementStore, useUserData } from "@/zustand";
import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useRouter } from "next/router";
import { FC } from "react";
import { sm } from "src/site-map";
import { StatusChip } from "src/ui/components/dumb";
import { FormHeaderDetails } from "../../procurement";

interface props { }
export const SellerProductsHeader: FC<props> = ({ }) => {
    const { push } = useRouter()
    const { catalogList } = useProcurementStore(state => state.catalogListSliceProperties)
    const { publish, fetchDetails } = useProcurementStore(state => state.catalogListSliceActions)
    const { setDetails } = useProcurementStore(state => state.productFormManagerProperties.actions)
    const redirectToProductPage = () => {
        fetchDetails(catalogList?.model.id, (catalog) => {
            push(sm.catalogue.index.url)
            setDetails(catalog, productManagerEnv.catalog)
        })
    }
    const handlePublish = () => publish()
    
    const userData = useUserData.getState()
    const canPublish = catalogList?.actions[ProcurementFormPermission.PUBLISH] && catalogList.numberOfProducts != 0;
    const canAddProducts = userData.canUpdateCatalog && catalogList?.model.status == procurementStatus.DRAFT && userData.userInfo?.id == catalogList?.model.owner?.id;

    return (
        <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px' }}>
            <Grid container md={12} px={2}>
                <Grid item container md={5} alignItems="center" gap={1} py={1}>
                    <Typography variant="h6" fontWeight={600} mr={3}>{catalogList?.model.name}</Typography>
                    <StatusChip status={catalogList?.model.status} />
                </Grid>
                <Grid item container gap={1} md={7} alignItems="center" justifyContent={'end'} >
                    {canAddProducts && <Button variant='containedInfo' onClick={redirectToProductPage}>
                        {t('buttons.add_products')}
                    </Button>}
                    {canPublish && <Button variant='contained' onClick={handlePublish}>
                        {t('buttons.publish')}
                    </Button>}
                </Grid>
            </Grid>
            <Grid item container md={12} px={2}>
                <FormHeaderDetails formModel={catalogList?.model} hideComments />
            </Grid>
        </Box >
    )
}