import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useAnimateOnView } from '@/hooks';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { getHomeTranslationKey } from '@/hooks';

type Props = {};

export const LaptopHomeSection: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <Grid container sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: 'calc(100%)',
          right: 0,
          top: smallScreens ? 0 : 50,
          zIndex: 0,
          bgcolor: 'background.primary.main',
          backgroundImage: 'url(/assets/images/home/points.svg)',
        }}
      />
      <Grid
        item
        component={ResponsiveContainer}
        order={[1, 1, 1, 0]}
        lg={7}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 50,
            width: '100%',
            height: smallScreens ? '100%' : 480,
            zIndex: -1,
            bgcolor: 'background.primary.main',
          },
        }}
      >
        <Box>
          <Typography
            {...first}
            component={motion.div}
            fontWeight='700'
            variant='h2'
            mt={smallScreens ? 3 : 0}
          >
            {t(getHomeTranslationKey('titles.laptop_section'))}  
          </Typography>

          <Typography
            {...second}
            component={motion.div}
            color='text.third'
            fontWeight='400'
            width={smallScreens ? '100%' : '75%'}
            variant='body2'
            mt={2}
          >
            {t(getHomeTranslationKey('sub_titles.laptop_section'))}  
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={5}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 0,
        }}
      >
        <Box
          component={motion.div}
          {...first}
          sx={{
            position: 'relative',
            height: smallScreens ? 300 : 550,
            width: smallScreens ? '100%' : 650,
            borderRadius: t =>
              smallScreens ? t.spacing(6, 6, 0, 0) : t.spacing(6, 0, 0, 6),
            overflow: 'hidden',
          }}
        >
          <Image
            src='/assets/images/home/laptop.png'
            layout='fill'
            objectFit='fill'
            alt='Hand shake'
          />
        </Box>
      </Grid>
    </Grid>
  );
};
