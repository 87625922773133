import { Typography } from "@mui/material"

export type StyledClickableTextProps = {
    isSelected: boolean
    handleTextClick: (id: number) => void;
    textProperties: { name: string, id: number };
};
export const StyledClickableText: React.VFC<StyledClickableTextProps> = ({ isSelected, handleTextClick, textProperties }) => {
    return (
        <Typography
            variant="body2"
            sx={{
                display: '-webkit-box', whiteSpace: 'pre-line', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflowY: 'hidden',
                textOverflow: 'ellipsis', overflowX: 'hidden', maxWidth: 250, cursor: isSelected ? 'inherit' : 'pointer',
                textTransform: 'capitalize', fontWeight: 600, textDecoration: isSelected ? 'underline' : null, borderRadius: '4px',
                color: isSelected ? 'primary.main' : 'warning.active', backgroundColor: isSelected ? 'primary.light' : 'white', height: '40px', p: 1, mr: 0.7, lineHeight: 2
            }}
            onClick={() => handleTextClick(textProperties.id)}
        >
            {textProperties.name}
        </Typography>
    )
}