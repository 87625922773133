import { FC } from 'react';
import { Chip, Typography } from '@mui/material';


interface StyledChipProps {
    title: string
    handleClickChip: () => void
    isDark?: boolean
}

export const StyledChip: FC<StyledChipProps> = ({ handleClickChip, title, isDark }) => {
    return (
        <Chip
            key={title}
            onClick={handleClickChip}
            sx={{
                height: 30, margin: 0.3, maxWidth: 120, borderRadius: '4px',
                backgroundColor: isDark ? 'info.contrastText' : 'transparent',
                border: isDark ? 'none' : '1.5px solid #E8E8EA',
            }}
            label={
                <Typography
                    variant='subtitle2'
                    sx={{
                        overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 400, color: isDark ? 'primary.main' : 'text.third',
                    }}
                >
                    {title}
                </Typography>
            }
        />
    );
};
