import { Box, Grid, Typography, MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { StyledBreadcrumbs } from 'src/ui/components/shared';
import { sm } from 'src/site-map';
import { useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRouter } from 'next/router';
import { BrandsIndex } from 'src/logic/models/catalogue/CatalogueIndex';
import BackToCatalogueButton from 'src/ui/components/smart/catalogue/shared/back-to-catalogue-button';
import IndexPageHeader from 'src/ui/components/smart/catalogue/shared/index-page-header';
import { useBrandsIndexSlice } from 'src/logic/zustand/brands';
import useSearchFilterCategoryItems from 'src/logic/hooks/smart/catalogue/use-search-filter-category-items';
import { Brand } from 'src/logic/models/company-details/seller';
import QueryStatusComponent from 'src/logic/services/query-handlers/QueryStatusComponent';
import Link from 'next/link';

type IProps = {
    brands: BrandsIndex;
};

export const BrandsIndexPageContent = ({ brands }: IProps) => {
    const { t } = useTranslation();
    const { onSortBrands } = useBrandsIndexSlice(state => state.brandsActions);
    const { fetchQueryFilterStatus } = useBrandsIndexSlice(state => state.brandsProperties);

    const { setSearchValue, filteredItems } = useSearchFilterCategoryItems<Brand>(brands?.items);

    return (
        <Grid container md={12} gap={1}>
            <Grid item container md={12} justifyContent="space-between" alignItems={'center'}>
                <Grid item>
                    <StyledBreadcrumbs alias={sm.catalogue.brands.index.url} showBackButton />
                </Grid>
                <Grid item pt={1}>
                    <BackToCatalogueButton />
                </Grid>
            </Grid>

            <IndexPageHeader title={t('pages_names.brands')} badgeNumber={brands?.count} search={true} onChange={setSearchValue} />

            <Grid item container md={12} justifyContent="flex-end">
                <SortSelect onSortItemSelected={(id: string) => onSortBrands(id)} sortData={brands.sort} />
            </Grid>

            <QueryStatusComponent queryHandler={fetchQueryFilterStatus}>
                <Grid container sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gridGap: '10px' }}>
                    {filteredItems?.map((brand, index) => (
                        <Grid item key={index}>
                            <Link href={sm.catalogue.brands.brandId(`${brand.id}`).url}>
                                <Box sx={{ height: '150px', width: '100%', borderRadius: '8px', bgcolor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} className='main-border-on-hover'>
                                    <img src={brand.image_url} alt={`Image ${index + 1}`} style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'contain' }} />
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </QueryStatusComponent>
        </Grid >
    );
};


const SortSelect = ({ onSortItemSelected, sortData }) => {
    if (!sortData || sortData.length === 0) {
        return null;
    }
    const { t } = useTranslation();
    const selectRef = useRef(null);

    // const defaultSortValue = sortData?.find((el) => el.is_selected)?.id;

    const handleIconClick = () => {
        if (selectRef.current) {
            const event = new Event('mousedown', { bubbles: true });
            selectRef.current.dispatchEvent(event);
        }
    };

    const defaultValue = sortData.some((sortItem) => sortItem.selected) ? undefined : sortData[0].id;

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
                <Typography variant="body2" color="info.light" fontWeight="400">
                    {' '}
                    {t('texts.sort_by')}
                </Typography>
            </InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={defaultValue}
                onChange={(event) => {
                    onSortItemSelected(event.target.value);
                }}
                sx={{
                    '& .MuiSelect-select': {
                        backgroundColor: 'transparent !important',
                        fontSize: '14px !important',
                        fontWeight: 500,
                        color: '#3A3E50 !important',
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                disableUnderline
                IconComponent={(props) => (
                    <ExpandMoreIcon
                        {...props}
                        onClick={() => {
                            handleIconClick();
                            // onSortItemSelected(sortData?.[0]?.id);
                        }}
                        sx={{ fontSize: '20px !important', color: '#3A3E50 !important', cursor: 'pointer' }}
                    />
                )}
                ref={selectRef}
            >
                {sortData.map((sortItem: any) => (
                    <MenuItem key={sortItem.id} value={sortItem.id} >
                        {sortItem.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
