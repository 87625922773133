import React from 'react';
import Image from 'next/image';
import { Box, BoxProps, useMediaQuery } from '@mui/material';
import { LOGO } from 'public/assets/images';
import clsx from 'clsx';

type Props = {
  children?: never;
  size?: number;
  widthSize?: number
} & BoxProps;

export const Logo: React.FC<Props> = ({
  children,
  className,
  size = 1,
  widthSize = 1,
  ...props
}) => {
  const isSmallScreens = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Box
      position='relative'
      width={150 * size}
      height={30 * size}
      {...props}
      className={clsx(className)}
    >
      <Box
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Image
          src={LOGO}
          objectFit='contain'
          alt='Eprocurement Logo'
          loading='eager'
          width={150 * widthSize}
          height={isSmallScreens ? 32 : 41 * size}
        />
      </Box>
    </Box>
  );
};
