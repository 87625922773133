import { apiRoutes } from "@/config";
import { request } from "@/helpers";
import { BundlePurchaseOrderAPIResponse } from "@/models";
import { buildProcurementBundleFormDetails, ChildApprovalChange } from "..";

export function buildBuyerBundlePurchaseOrderDetails(bundlePO: BundlePurchaseOrderAPIResponse, userId: number) {
    return {
        ...buildProcurementBundleFormDetails(bundlePO, 'canApprovePurchaseOrder', 'purchaseOrders'),
    };
}

export const useGetBuyerBundlePurchaseOrderDetails = (bundlePoId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.show + bundlePoId, method: "GET" })
}
export const useGetBuyerBundlePurchaseOrderDetailsEditPreview = (bundlePoId: number, previewData: any) => {
    return request({ url: `/buyer/purchase_order/bundle/edit/${bundlePoId}`, method: "POST", data: { preview_selected_products: previewData } })
}
export const useGetComparisonTablePreviewForBundlePoEdit = (bundlePoId: number, previewData: any) => {
    return request({ url: `/buyer/purchase_order/bundle/comparison-table/${bundlePoId}`, method: "POST", data: { preview_selected_products: previewData } })
}
export const useUpdateBundlePurchaseOrder = (bundlePoId: number, comparison_table_id: number, preview_selected_products: any) => {
    return request({ url: `/buyer/purchase_order/bundle/update/${bundlePoId}`, method: "POST", data: { comparison_table_id, preview_selected_products } })
}
export const useSendBundlePurchaseOrderForApproval = (bundlePoId: number, comment: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.sendToApprovers + bundlePoId, method: "POST", data: { comment: comment } })
}
export const useApproveBundlePurchaseOrder = (bundlePoId: number, comment: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.approve + '/' + bundlePoId, method: "POST", data: { comment: comment } })
}
export const useRejectBundlePurchaseOrder = (bundlePoId: number, reasonId: string, comment: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.reject + '/' + bundlePoId, method: "POST", data: { reason_id: reasonId, comment: comment } })
}

export const useBulkApproval = (bundlePoId: number, changes: ChildApprovalChange[]) => {
    const approval = changes.map(change => ({
        approvable_id: change.id,
        type: change.type,
        comment: change.comment,
        rejection_reason_id: change.reasonId,
    }))
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.bulkApproval + '/' + bundlePoId, method: "POST", data: { approvals: approval } })
}

export const useCancelBundlePurchaseOrder = (bundlePoId: number, comment: string) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.cancelApproval + '/' + bundlePoId, method: "POST", data: { comment: comment } })
}

export const useSendBundlePoToSeller = (bundlePOId: number | undefined, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + '/' + bundlePOId + apiRoutes.sendToAllSellers, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}

export const useSendPoToSeller = (POId: number | undefined, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId + apiRoutes.sendToSeller, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}

export const useRecallBundlePO = (bundlePoId: number | undefined, comment: string, reasonId: string | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + '/' + bundlePoId + apiRoutes.recall, method: "POST", data: { reason_id: reasonId, comment: comment } })
}

export const useGetRecalledReasons = () => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.recall_reasons, method: "GET" })
}

export const useDeleteBundlePO = (bundlePoId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + '/' + bundlePoId, method: "DELETE" })
}

export const useDeleteIndividualPO = (POId: number) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + '/' + POId, method: "DELETE" })
}