import { ProductManagerPopupStatus, productManagerEnv } from '@/config';
import { ProcurementModelDetails, Product, Seller } from '@/models';
import { useProcurementStore } from '@/zustand';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { RadioButtonGroup, SearchTextField } from 'src/ui/components/shared';
import initialValues from './initialValues';
import { validationSchema } from './validation-schema';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

interface props {
  product: Product;
  selectedSeller?: Seller;
  disableAddToExistingPoButton?: boolean;
}

export const CreatePOWihtProduct: FC<props> = ({ product, selectedSeller, disableAddToExistingPoButton }) => {
  const [searchText, setsearchText] = React.useState<string>('');
  const router = useRouter();
  const { createForm, setProductManagerPopupStatus } = useProcurementStore(
    state => state.productFormManagerProperties.actions
  );
  const { purchaseOrderRelatedData, setPurchaseOrderRelatedData } =
    useProcurementStore();
  const getSellerFiltration = (form: ProcurementModelDetails) => {
    let newpurchaseOrderRelatedData = purchaseOrderRelatedData;
    let newpurchaseOrders = [...purchaseOrderRelatedData?.purchase_orders];
    newpurchaseOrders?.push(form);
    setPurchaseOrderRelatedData({
      ...newpurchaseOrderRelatedData,
      purchase_orders: newpurchaseOrders,
    });
    router.query['seller-id'] = form?.seller_details?.id;
    router.push(router);
  };
  const createPoLoading = useLoadingState();
  const handleCreatePO = values => {
    createPoLoading.startLoading();
    const sellerIndex = product.sellers?.findIndex(
      seller => seller.seller.id == Number(values.seller_id)
    );
    createForm(
      {
        ...values,
        sellerId: values.seller_id,
        product: {
          ...product,
          price: values.price ?? product.sellers[sellerIndex].price,
        },
      },
      'purchaseOrderBuyerDetailsSliceActions',
      productManagerEnv.purchase_order,
      purchaseOrderRelatedData?.purchase_orders,
      getSellerFiltration
    );
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        textAlign: 'center',
        my: 1,
        px: 2,
        width: '100%',
      }}
    >
      <ShoppingCartIcon color='primary' />
      <Typography color='primary' variant='overline' fontWeight='700' mt={1}>
        {t('popups.titles.add_to_new_po')}
      </Typography>
      <Typography color='#3A3E50' variant='subtitle1' fontWeight='400' my={1}>
        {t('popups.subTitles.please_select_a_seller')}
      </Typography>
      {product && (
        <Stack mt={2} width='100%'>
          <Formik
            initialValues={initialValues(
              product.id,
              product.quantity,
              selectedSeller
            )}
            validationSchema={validationSchema}
            onSubmit={handleCreatePO}
          >
            {formikProps => {
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <Stack sx={{ textAlign: 'start' }} gap={1}>
                    {/* {!selectedSeller && (
                      <StyledSelectWithStyledChildren
                        placeholder='select_seller'
                        idObjKey='seller'
                        MenuItemComponent={SellerSelectComponent}
                        items={product.sellers ?? []}
                        name='seller_id'
                        translateKey='select_seller'
                      />
                    )} */}
                    <Box width={'100%'}>
                      <SearchTextField
                        translateKey='search_sellers'
                        value={searchText}
                        onChange={e => setsearchText(e.target.value)}
                      />
                    </Box>
                    <Stack my={1} maxHeight='200px'>
                      <Stack pl={1}>
                        <RadioButtonGroup
                          data={
                            product?.sellers
                              ?.map(sel => sel.seller)
                              ?.filter(seller =>
                                seller?.name
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              ) ?? []
                          }
                          handleChange={(id: string) =>
                            formikProps.setFieldValue('seller_id', id)
                          }
                          value={formikProps.values.seller_id}
                          getSubTitle={(seller: any) => {
                            const price = product?.sellers?.find(
                              el => el.seller.id === seller.id
                            )?.price;
                            if (price) {
                              return `Unit Price: ${price}`;
                            }
                            return `No Listed Price`;
                          }}
                          getLabelSuffix={(seller: any) => {
                            const is_private = product?.sellers?.find(
                              el => el.seller.id === seller.id
                            )?.is_private;
                            return is_private ? <PrivacyButton disableText zIndex={1300} /> : null
                          }}
                        />
                      </Stack>
                    </Stack>
                    {/* <ProjectCreateOrSelect projects={purchaseOrderRelatedData?.projects ?? []} showCreateProject={showCreateProject} setshowCreateProject={setshowCreateProject} /> */}
                    <LoadingButton
                      loading={createPoLoading.isLoading}
                      sx={{ mt: 2 }}
                      type='submit'
                      fullWidth
                      variant='contained'
                      // disabled={!(formikProps.isValid && formikProps.dirty)}
                      disabled={!formikProps.values.seller_id}
                    >
                      {t('buttons.select_seller')}
                    </LoadingButton>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Stack>
      )}
      <Button
        type='button'
        color='primary'
        variant='text'
        fullWidth
        sx={{ mt: 1, fontWeight: 500 }}
        onClick={() => {
          setProductManagerPopupStatus(ProductManagerPopupStatus.SELECT_PO);
        }}
        disabled={disableAddToExistingPoButton}
      >
        {t('buttons.or_add_to_existing_po')}
      </Button>
    </Stack>
  );
};

// const SellerSelectComponent: FC<{ object: Seller }> = ({ object }) => {
//   object = { ...object, id: object.seller.id, name: object.seller.name };
//   return (
//     <Grid container>
//       <Grid item lg={6}>
//         {object.name}
//       </Grid>
//       <Grid container item lg={6} justifyContent='flex-end'>
//         {object.price}
//       </Grid>
//     </Grid>
//   );
// };
