import { Grid, Button, Divider } from '@mui/material';
import { useRequisition } from '@/zustand';
import { Requisition } from '@/models';
import { useState, useRef, useEffect } from 'react';
import {
  RequisitionProduct,
  DescPopup,
  PopupWithTitle,
  ProductChip,
  ProductTab,
} from '@/components';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/system';

type Props = {
  requisition: Requisition;
  selectedProductIndex: number;
  setSelectedProductIndex: Function;
};

export const ProductsList: React.FC<Props> = ({
  requisition,
  selectedProductIndex,
  setSelectedProductIndex,
}) => {
  const productsRef = useRef();
  const [open, setOpen] = useState(false);
  const [productsOverflow, setproductsOverflow] = useState(false);
  const removeProduct = useRequisition(state => state.removeProduct);

  const scrollProducts = scrollOffset => {
    productsRef.current.scrollLeft += scrollOffset;
  };

  function isOverflowActive(event) {
    return event.clientWidth < event.scrollWidth;
  }
  useEffect(() => {
    if (isOverflowActive(productsRef.current)) {
      setproductsOverflow(true);
      return;
    }
    setproductsOverflow(false);
  }, [isOverflowActive]);

  const [showCardDetails, setshowCardDetails] = useState(null);
  const openCardDetails = id => setshowCardDetails(id);
  const handleCloseCardDetails = () => setshowCardDetails(null);
  const handleClickOpen = id => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = product => {
    // deleteReq(product.id);
    setOpen(false);
  };
  return (
    <>
      {/* {!isSmallScreen ? ( */}
      <>
        <Grid
          mt={0}
          ref={productsRef}
          container
          wrap='nowrap'
          sx={{
            overflowX: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'horizontal',
            transform: 'rotateX(180deg)',
            alignItems: 'center',
          }}
        >
          {requisition?.products?.map((reqProducts, index) => {
            return (
              <ProductChip
                key={reqProducts.tabId}
                index={index}
                mainProduct={reqProducts.mainProduct}
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={setSelectedProductIndex}
                removeProduct={removeProduct}
                requisition={requisition}
                tabId={reqProducts.tabId}
              />
            );
          })}
          {productsOverflow && (
            <>
              <Button
                onClick={() => scrollProducts(-200)}
                sx={{
                  position: 'sticky',
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: '#CBD5EA',
                  },
                  display: 'flex',
                  alignSelf: 'flex-start',
                  right: 30,
                  maxWidth: '40px',
                  minWidth: '40px',
                }}
              >
                <ArrowBackIosNew fontSize='inherit' sx={{ color: 'text.third' }} />
              </Button>
              <Button
                onClick={() => scrollProducts(+200)}
                sx={{
                  position: 'sticky',
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: '#CBD5EA',
                  },
                  display: 'flex',
                  alignSelf: 'flex-start',
                  right: 0,
                  maxWidth: '40px',
                  minWidth: '40px',
                }}
              >
                <ArrowForwardIos fontSize='inherit' sx={{ color: 'text.third' }} />
              </Button>
            </>
          )}
        </Grid>
        <Divider
          sx={{ marginTop: -0.2, borderBottomWidth: 2, bgcolor: 'primary.light' }}
        />
      </>
      {/* ) : (
        <Grid mt={3} container ref={productsRef}>
          {requisition?.products?.map((reqProduct, index) => {
            return (
              <>
                {selectedProductIndex == index && (
                  <>
                    <ProductTab
                      key={reqProduct.tabId}
                      index={selectedProductIndex}
                      product={reqProduct.mainProduct}
                      selectedProductIndex={selectedProductIndex}
                      onClickCard={handleClickOpen}
                      removeProduct={removeProduct}
                      requisition={requisition}
                      tabId={reqProduct.tabId}
                      isPopupProducts={false}
                    />
                    <Box mt={1}>
                      <RequisitionProduct
                        key={reqProduct.mainProduct.partNumber}
                        product={reqProduct.mainProduct}
                        mainProduct={true}
                        requisition={requisition}
                        tabId={reqProduct.tabId}
                        removeProduct={removeProduct}
                        isSmallScreen={isSmallScreen}
                        isWithDetails={false}
                        openCardDetails={openCardDetails}
                      />
                    </Box>
                    <DescPopup
                      open={
                        showCardDetails == reqProduct.mainProduct.partNumber
                      }
                      handleClose={handleCloseCardDetails}
                    >
                      <RequisitionProduct
                        key={reqProduct.mainProduct.partNumber}
                        product={reqProduct.mainProduct}
                        mainProduct={true}
                        requisition={requisition}
                        tabId={reqProduct.tabId}
                        removeProduct={removeProduct}
                        isSmallScreen={isSmallScreen}
                        isWithDetails={true}
                        openCardDetails={openCardDetails}
                      />
                    </DescPopup>
                  </>
                )}
              </>
            );
          })}
          <PopupWithTitle
            title={'Product Tabs'}
            handleClose={handleClose}
            open={open}
          >
            {requisition?.products?.map((reqProduct, index) => {
              return (
                <ProductTab
                  key={reqProduct.tabId}
                  index={index}
                  product={reqProduct.mainProduct}
                  selectedProductIndex={selectedProductIndex}
                  onClickCard={index => {
                    setSelectedProductIndex(index);
                    handleClose();
                  }}
                  removeProduct={removeProduct}
                  requisition={requisition}
                  tabId={reqProduct.tabId}
                  isPopupProducts={true}
                />
              );
            })}
          </PopupWithTitle>
        </Grid>
      )} */}
    </>
  );
};
