import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';

export const validationSchema =
  yup.object({
    new_password: yup
      .string()
      .required('required')
      .min(8, 'min8')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'small_big')
      .matches(/^(?=.*[0-9])/, 'letter_digit'),
      new_password_confirmation: yup
      .string()
      .required('required')
      .oneOf([yup.ref('new_password')], 'matches_password'),
  })
