import { useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Grid, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { mock } from '@scandinavia/mock';
import React from 'react';

type Props = {};

export const ListCareersSection: React.FC<Props> = ({}) => {
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });

  const textArr = [
    'NA friendly, motivated and collegial team',
    'An open, low-hierarchy and team-oriented company culture in an international environment',
    'Above average pay',
    'A versatile job with very good development opportunities',
    'A modern workplace with the possibility of working in home office',
    'Our help in bringing work and private life under one roof',
    'Discounts for employees',
  ];
  const textArr1 = [
    'An open, low-hierarchy and team-oriented company culture in an international environment',
    'Above average pay',
    'A versatile job with very good development opportunities',
    'A modern workplace with the possibility of working in home office',
    'Our help in bringing work and private life under one roof',
  ];
  return (
    <ResponsiveContainer>
      <Typography
        component={motion.h4}
        {...first}
        fontWeight='700'
        variant='h2'
      >
        Est eget fusce dignissim et amet.
      </Typography>
      <Grid container mt={2}>
        <Grid item md={6} xs={12}>
          <Typography
            component={motion.ul}
            {...second}
            color='text.third'
            fontWeight='400'
            variant='body2'
          >
            {textArr.map((el, i) => (
              <Box component='li' key={i} sx={{ mb: 1.5, ml: -3 }}>
                {el}
              </Box>
            ))}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            component={motion.ul}
            {...third}
            color='text.third'
            fontWeight='400'
            variant='body2'
          >
            {textArr1.map((el, i) => (
              <Box component='li' key={i} sx={{ mb: 1.5, ml: -3 }}>
                {el}
              </Box>
            ))}
          </Typography>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};
