import { useTranslation } from 'next-i18next';
import { useSellerProcurementStore } from "@/zustand"
import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { QuoteDetails } from "@/models";
import { StyledPopper } from 'src/ui/components/shared';
import { BorderColorOutlined, CheckCircleOutline, ExpandMoreOutlined, HighlightOff } from '@mui/icons-material';
import { QuoteActionPopup } from 'src/ui/components/dumb';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ProcurementButtons, QuotePopupStatus } from '@/config';
import { t } from 'i18next';

type ApproveRejectPopupsProps = {
    quote: QuoteDetails
    openedPopupStatus: QuotePopupStatus | null
}

export const ApproveRejectPopups: FC<ApproveRejectPopupsProps> = ({ quote, openedPopupStatus }) => {
    const quoteModel = quote?.quoteModel
    const { setPopupStatus, approveQuote, rejectQuote } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const { customLoadingButton } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)


    const [anchorShowEditResponse, setAnchorShowEditResponse] = useState<null | HTMLElement>(null);
    const openShowEditResponse = Boolean(anchorShowEditResponse);

    const handleClosePopup = () => setPopupStatus(null)
    const OpenRejectQuotePopup = (values) => setPopupStatus(QuotePopupStatus.REJECT)
    const OpenApproveQuotePopup = (values) => { setPopupStatus(QuotePopupStatus.APPROVE) }
    const handleAcceptQuote = (values) => approveQuote(quoteModel?.id, values.comment)
    const handleRejectQuote = (values) => rejectQuote(quoteModel?.id, values.reasonId, values.comment)

    return (
        <>
            {quote?.canRejectQuote && !quote?.canEditResponse && <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={OpenRejectQuotePopup}> {t("buttons.reject")}</Button>}
            {quote?.canApproveQuote && !quote?.canEditResponse && <Button variant="contained" color="success" sx={{ mr: 1 }} onClick={OpenApproveQuotePopup}> {t("buttons.approve")} </Button>}
            {quote?.canEditResponse && <Button
                onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorShowEditResponse(event.currentTarget)}
                variant="contained"
                color="info"
                startIcon={<BorderColorOutlined sx={{ ml: 1 }} />}
                endIcon={<ExpandMoreOutlined sx={{ mr: 1 }} />}
            >
                <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.edit_response')}</Typography>
            </Button>}
            <StyledPopper open={openShowEditResponse} anchorEl={anchorShowEditResponse} handleClose={() => setAnchorShowEditResponse(null)}>
                <Grid container sx={{ display: 'flex', flexDirection: 'column', minWidth: 185 }}>

                    {quote?.canApproveQuote && <Button startIcon={<CheckCircleOutline />} sx={{ color: "success.dark" }} onClick={OpenApproveQuotePopup}>
                        {t('buttons.approve_quote')}
                    </Button>}

                    {quote?.canRejectQuote && <Button startIcon={<HighlightOff />} color="error" onClick={OpenRejectQuotePopup}>
                        {t("buttons.reject_quote")}
                    </Button>}

                </Grid>
            </StyledPopper>
            <QuoteActionPopup
                handleCloseQuotePopup={handleClosePopup}
                QuotePopupOpened={openedPopupStatus == QuotePopupStatus.APPROVE}
                icon={<CheckCircleOutline sx={{ color: 'success.dark' }} />}
                popupTitle={t('popups.titles.approve_quote', { QuoteName: quoteModel?.name })}
                buttonTitle={t('popups.titles.approve_quote', { QuoteName: quoteModel?.name })}
                handlePopupAction={handleAcceptQuote}
                status='success'
                isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_QUOTE}
            />
            {/* reject popup */}
            <QuoteActionPopup
                handleCloseQuotePopup={handleClosePopup}
                QuotePopupOpened={openedPopupStatus == QuotePopupStatus.REJECT}
                icon={<HighlightOffIcon color='error' />}
                popupTitle={t('popups.titles.reject_quote', { QuoteName: quoteModel?.name })}
                buttonTitle={t('popups.titles.reject_quote', { QuoteName: quoteModel?.name })}
                popupSubTitle={t('popups.subTitles.reject_quote')}
                handlePopupAction={handleRejectQuote}
                status='error'
                isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_QUOTE}
            />
        </>
    )
} 