import { StateCreator } from "zustand";
import produce from "immer";
import { GeneralObject, RFQ, ProcurementFormModel, LinkType } from "@/models";
import { buildProcurementFormDetails, NotifyMessage, NotifySuccess, NotifySuccessWithDeleteIcon, NotifySuccessWithCancelIcon, useApproveRFQ, useCancelRFQ, useCancelSendForApprovalsRFQBuyer, useChangeProject, useDeclineRequestExtension, useDeleteProductFromRFQ, useDeleteRFQ, useDuplicateForm, useEditExpiryDate, useFetchCancelRFQReasons, useFetchRejectReasons, useFetchRequestForQuoteDetails, useRejectRFQ, useSendForApprovalRFQBuyer, useSendRFQToSeller } from "@/services";
import { ProcurementButtons, ProcurementForms, ProcurementPopupStatus, procurementStatus } from "@/config";
import { t } from "i18next";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";

export interface BuyerRequestForQuoteDetailsSlice {
    requestForQuoteBuyerDetailsSliceProperties: {
        requestForQuote: ProcurementFormModel | null
        fetchRfqDetailsQueryStatus: QueryHandlerModel,
        // loadingDetails: boolean
        newComments: Comment[]
        openedPopupStatus: ProcurementPopupStatus | null
        successPopupStatus: ProcurementPopupStatus | null
        reasonsForPopup: GeneralObject[]
        customLoadingButton: ProcurementButtons | null
        productIdDeleting: number | null
        requestExtension: any
    }
    requestForQuoteBuyerDetailsSliceActions: {
        fetchDetails: (POId: number) => void
        // setIsLoading: (isLoading: boolean) => void
        setDetails: (requestForQuote: RFQ) => void
        closeNewCommentsPopup: () => void
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => void
        getReasonsForPopup: () => void
        sendForApproval: (optionalComment: string) => void
        reject: (reasonId: string, comment: string) => void
        approve: (comment: string) => void
        cancel: (comment: string, reasonId: string) => void
        cancelSendForApproval: () => void
        rebuildDetails: (requestForQuote: RFQ, successPopupStatus?: ProcurementPopupStatus) => void
        changeProject: (project: GeneralObject) => void
        sendRFQToSeller: (date: string, optionalComment?: string) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        deleteProduct: (productId: number, rfqId: number | undefined, redirectToRFQs: () => void) => void
        deleteSingleRFQ: (rfqId: number, redirectToRFQs: () => void) => void
        handleDuplicateSingleRFQ: (rfqId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        declineRequestExtension: (rfqId: number | undefined) => void
    }
}

export const createBuyerRequestForQuoteDetailsSlice: StateCreator<BuyerRequestForQuoteDetailsSlice> = (set, get, api) => ({
    requestForQuoteBuyerDetailsSliceProperties: {
        requestForQuote: null,
        fetchRfqDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingDetails: false,
        newComments: [],
        openedPopupStatus: null,
        successPopupStatus: null,
        reasonsForPopup: [],
        customLoadingButton: null,
        productIdDeleting: null,
    },
    requestForQuoteBuyerDetailsSliceActions: {
        fetchDetails: (rfqId: number) => {
            // get().requestForQuoteBuyerDetailsSliceActions.setIsLoading(true)
            set(produce(draftState => {
                draftState.requestForQuoteBuyerDetailsSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useFetchRequestForQuoteDetails(rfqId).then(data => {
                get().requestForQuoteBuyerDetailsSliceActions.setDetails(data.data)
                set(produce(draftState => {
                    draftState.requestForQuoteBuyerDetailsSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
                // get().requestForQuoteBuyerDetailsSliceActions.setIsLoading(false)
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.requestForQuoteBuyerDetailsSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.requestForQuoteBuyerDetailsSliceProperties.loadingDetails = isLoading })),
        setDetails: (requestForQuote: RFQ) => {
            set(produce(draftState => {
                draftState.requestForQuoteBuyerDetailsSliceProperties.requestForQuote = buildProcurementFormDetails(requestForQuote, 'hasRFQApproverRole', false, true)
                draftState.requestForQuoteBuyerDetailsSliceProperties.newComments = requestForQuote.new_comments?.length == 0 ? null : requestForQuote.new_comments
                draftState.requestForQuoteBuyerDetailsSliceProperties.customLoadingButton = null
            }))
            get().setTimeline(requestForQuote.timeline);
        },
        closeNewCommentsPopup: () => set(produce(draftState => { 
            const rfqModel = draftState.requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model;
            if (rfqModel) {
                rfqModel.new_comments = null;
            }
            draftState.requestForQuoteBuyerDetailsSliceProperties.newComments = null 
        })),
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.requestForQuoteBuyerDetailsSliceProperties.openedPopupStatus = status
        })),
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.requestForQuoteBuyerDetailsSliceProperties.successPopupStatus = status
        })),
        getReasonsForPopup: () => {
            if (get().requestForQuoteBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.CANCEL)
                useFetchCancelRFQReasons().then(data => { set(produce(draftState => { draftState.requestForQuoteBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
            if (get().requestForQuoteBuyerDetailsSliceProperties.openedPopupStatus == ProcurementPopupStatus.REJECT)
                useFetchRejectReasons().then(data => { set(produce(draftState => { draftState.requestForQuoteBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        sendRFQToSeller: (date: string, optionalComment?: string) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_SINGLE_RFQ_TO_SELLER)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useSendRFQToSeller(rfqId, date, optionalComment ?? null).then((data) => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SENT_TO_SELLER); NotifySuccess(t("popups.titles.sent_to_seller_ct_created",{formName: get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model.name})) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        sendForApproval: (optionalComment: string) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_SINGLE_RFQ_FOR_APPROVAL)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useSendForApprovalRFQBuyer(rfqId, optionalComment).then(data => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SEND_FOR_APPROVAL); NotifySuccess(t("popups.titles.sent_for_approval")) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        reject: (reasonId: string, comment: string) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_SINGLE_RFQ)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useRejectRFQ(rfqId, comment, reasonId).then(data => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data); NotifySuccessWithCancelIcon(t("popups.titles.rfq_rejected")) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        approve: (comment: string) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_SINGLE_RFQ)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useApproveRFQ(rfqId, comment).then(data => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data); NotifySuccess(t("popups.titles.rfq_approved")) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancel: (comment: string, reasonId: string) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_SINGLE_RFQ)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useCancelRFQ(rfqId, reasonId, false, comment).then(data => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data); NotifyMessage(t("popups.titles.rfq_canceled")) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancelSendForApproval: () => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_SINGLE_RFQ_APPROVAL)
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            useCancelSendForApprovalsRFQBuyer(rfqId).then(data => { get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data); NotifyMessage(t("popups.titles.approval_canceled")) }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model)
            get().requestForQuoteBuyerDetailsSliceActions.setDetails(modelAfterUpdateProject)
        },
        rebuildDetails: (requestForQuote: RFQ, successPopupStatus?: ProcurementPopupStatus) => {
            const actions = get().requestForQuoteBuyerDetailsSliceActions
            actions.setPopupStatus(null)
            if (successPopupStatus) actions.setSuccessPopupStatus(successPopupStatus)
            actions.setDetails(requestForQuote)
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.requestForQuoteBuyerDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.requestForQuoteBuyerDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        deleteProduct: (productId: number, rfqId: number | undefined, redirectToRFQs: () => void) => {
            set(produce(draftState => {
                draftState.requestForQuoteBuyerDetailsSliceProperties.productIdDeleting = productId;
            }))

            useDeleteProductFromRFQ(productId, rfqId).then(() => {

                // Update Products
                const old_products = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model.products;
                const new_products = old_products?.filter(product => product.id !== productId);

                set(produce(draftState => {
                    draftState.requestForQuoteBuyerDetailsSliceProperties.requestForQuote.model.products = new_products;
                    draftState.requestForQuoteBuyerDetailsSliceProperties.requestForQuote.numberOfProducts = new_products?.length;
                    draftState.requestForQuoteBuyerDetailsSliceProperties.productIdDeleting = null;
                }))

                if (new_products?.length === 0) {
                    redirectToRFQs();
                }

            });
        },
        deleteSingleRFQ: (rfqId: number, redirectToRFQs: () => void) => {
            useDeleteRFQ(rfqId).then(() => {
                redirectToRFQs()
                set(produce(draftState => {
                    draftState.requestForQuoteBuyerDetailsSliceProperties.requestForQuote = null
                }))
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: ProcurementForms.RFQ }));
                // get().requisitionSliceActions.refreshRequisition(data.data.requisition)
            })
        },
        handleDuplicateSingleRFQ: (rfqId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => {
            useDuplicateForm(rfqId, project, LinkType.RFQ_SINGLE).then((data) => {
                redirectToDuplicatedForm(data.data.id);
                NotifySuccess(t('notifications.form_duplicated', { formName: ProcurementForms.RFQ }));
            });
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const rfqId = get().requestForQuoteBuyerDetailsSliceProperties.requestForQuote?.model?.id
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(rfqId, LinkType.RFQ_SINGLE, validityDate).then((data) => {
                get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineRequestExtension: (rfqId: number | undefined) => {
            get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(rfqId, 'RequestForQuote').then((data) => {
                get().requestForQuoteBuyerDetailsSliceActions.rebuildDetails(data.data)
                get().requestForQuoteBuyerDetailsSliceActions.closeNewCommentsPopup()
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
            }).catch(() => get().requestForQuoteBuyerDetailsSliceActions.setCustomLoadingButton(null))
        }
    }
})