import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useState } from 'react';
import { sm } from 'src/site-map';
import { AccountRecovery, LoginForm } from '..';

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { t } = useTranslation();
  const [recoverySteps, setRecoverySteps] = useState(0);

  return (
    <>
      {recoverySteps === 0 ? (
        <>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant='h1' sx={{ fontWeight: 'bolder' }}>
              {t('texts.login')}
            </Typography>
          </Grid>
          <LoginForm recoverAccount={() => setRecoverySteps(recoverySteps + 1)} />
          <Typography textAlign={'center'} mt={2} fontSize={13} fontWeight={400}>
            Don't have an account? <Link href={sm.register.index.url}>Register Here</Link>
          </Typography>
        </>
      ) : (
        <AccountRecovery recoverySteps={recoverySteps} setRecoverySteps={setRecoverySteps} />
      )}
    </>
  );
};
