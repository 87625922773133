import { StyledTextField } from '@/components';
import { Grid, Button } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';
import { LoginFormHeader } from '../..';
import { apiURLs } from '@/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getLoginTranslationKey } from '@/hooks';
import { useEffect } from 'react';
import { NotifyError, NotifySuccess } from '@/services';

interface VerificationCodeFormProps {
  disabledButton: boolean;
  onBack: Function;
  validate: Function;
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = (
  props: VerificationCodeFormProps
) => {
  const [field] = useField({ name: 'email' });
  const { t } = useTranslation();
  const resendCode = () => {
    axios
      .post(apiURLs.forgetPassword, { email: field.value })
      .then(response => {
        NotifySuccess(t('notifications.verification_code_sent'));
      })
      .catch(e => NotifyError(t('notifications.server_error')));
  };

  useEffect(() => {
    props.validate()
  }, []);
  return (
    <>
      <LoginFormHeader
        titleTranslate={t(getLoginTranslationKey('headers.recovery'))}
        subTitleTranslate={t(getLoginTranslationKey('paragraphs.verification_code'))}
      />
      <Grid item xs={12}>
        <StyledTextField
          autofocus
          showLabel
          translateKey='user.verificationCode'
          name='verificationCode'
          required
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: -1, ml: -0.5 }}>
        <Button
          variant='text'
          sx={{ fontWeight: 400, fontSize: '12px', color: 'info.main' }}
          onClick={resendCode}
        >
          <u>{t('buttons.get_code_again')}</u>
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          variant='contained'
          size='large'
          color='secondary'
          fullWidth
          type='submit'
          disabled={props.disabledButton}
        >
          {t('buttons.next')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Button
          variant='outlined'
          fullWidth
          onClick={() => props.onBack()}
        >
          {t('buttons.back')}
        </Button>
      </Grid>
    </>
  );
};
