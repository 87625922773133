import { ProductManagerEnv } from '@/config';

import { Product } from '@/models';
import RequsitionSavingServices from '../requisitions';
import PurchaseOrderSavingServices from '../purchase-orders';
import QuoteSavingServices from '../quotes';
import EditBundleRfqSavingServices from '../edit_bundle_rfq';
import EditQuoteFromScratchSavingServices from '../edit_quote_from_scratch';
import EditPoFromScratchSavingServices from '../edit_po_from_scratch';

type EnvChangeQuantityServiceMapperParams = {
  env: ProductManagerEnv;
  formId: number;
  product: Product;
  newQuantity: number;
};

const EnvChangeQuantityServiceMapper = ({
  env,
  formId,
  product,
  newQuantity,
}: EnvChangeQuantityServiceMapperParams) => {
  if (env === ProductManagerEnv.requisition) {
    return () =>
      RequsitionSavingServices.changeQuantity({
        requisition_id: formId,
        product_id: product.id,
        quantity: newQuantity,
      });
  } else if (env === ProductManagerEnv.purchase_order) {
    return () =>
      PurchaseOrderSavingServices.changeQuantity({
        purchase_order_id: formId,
        product_id: product.id,
        quantity: newQuantity,
      });
  } else if (env === ProductManagerEnv.quote) {
    return () =>
      QuoteSavingServices.changeQuantity({
        quote_id: formId,
        product_id: product.id,
        quantity: newQuantity,
      });
  } else if (env === ProductManagerEnv.edit_bundle_rfq) {
    return () => EditBundleRfqSavingServices.changeQuantity();
  } else if (env === ProductManagerEnv.edit_quote_from_scratch) {
    return () => EditQuoteFromScratchSavingServices.changeQuantity();
  } else if (env === ProductManagerEnv.edit_po_from_scratch) {
    return () => EditPoFromScratchSavingServices.changeQuantity();
  }
  return null;
};

export default EnvChangeQuantityServiceMapper;
