import { BasicTabs, Loading, StyledComment, TabsWithChips } from '@/components';
import { getInputsTranslationKey } from "@/hooks";
import { useCommentsStore, useUserData } from "@/zustand";
import { ArrowForwardIos, Description } from "@mui/icons-material";
import { Box, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useRef, useState } from "react";

type CommentsSideDialogContentProps = {
    isCommentsDialogOpened: boolean
    closeCommentsDialog: () => void
    title: string
    commentType: string
    commentId: string
}
export const CommentsSideDialogContent: React.FC<CommentsSideDialogContentProps> = ({ isCommentsDialogOpened, closeCommentsDialog, title, commentType, commentId }) => {
    const { t } = useTranslation();
    const { changeDisplayedComment, changeSubgroupDisplayedComment, createComment, fetchComments } = useCommentsStore(state => state.commentsStoreActions)
    const {
        displayedComments,
        commentsGroupsTabs,
        selectedCommentGroupName,
        numberOfComments,
        commentsSubgroupsTabs,
        selectedCommentSubgroupName,
        isCommentsInSubGroups,
        readOnly,
        loadingComments
    } = useCommentsStore(state => state.commentsStoreProperties)
    const userInfo = useUserData(state => state.userInfo)

    useEffect(() => {
        isCommentsDialogOpened && fetchComments(commentType, commentId)
    }, [isCommentsDialogOpened]);

    const [comment, setcomment] = useState('');
    // var moment = require('moment');

    return (
        <Grid container p={4} alignItems="center" height='100%'>
            {loadingComments ? <Loading fullHeight={true} />
                :
                <>
                    <Grid item container md={12} height="6%">
                        <Grid item md={2}>
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'primary.light',
                                width: '40px'
                            }}>
                                <Description />
                            </Box>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="overline" fontWeight={700} textTransform='capitalize'>
                                {t('texts.comments')}
                            </Typography>
                            <Typography variant="subtitle2"
                                color="text.third">{numberOfComments}{" " + t('texts.commments_added')}</Typography>
                        </Grid>
                        <Grid item container md={2} justifyContent="flex-end" alignItems='center'>
                            <ArrowForwardIos sx={{ color: "text.third", height: '16px', cursor: 'pointer' }}
                                onClick={closeCommentsDialog} />
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} xs={12} mt={2} alignItems='center'>
                        <BasicTabs
                            key={selectedCommentGroupName}
                            tabs={commentsGroupsTabs}
                            initialValue={selectedCommentGroupName}
                            onChange={(commentGroupName: string) => changeDisplayedComment(commentGroupName)}
                        />
                    </Grid>
                    {isCommentsInSubGroups && <Grid container item lg={12} xs={12} py={1} alignItems='center'>
                        <TabsWithChips
                            tabs={commentsSubgroupsTabs}
                            initialValue={selectedCommentSubgroupName}
                            onChange={(commentSubgroupName: string) => changeSubgroupDisplayedComment(commentSubgroupName)}
                        />
                    </Grid>}
                    <MessagesStack displayedComments={displayedComments} isCommentsInSubGroups={isCommentsInSubGroups} />
                    <Grid item md={12} minHeight={90}>
                        {selectedCommentGroupName && !readOnly && <TextField
                            onChange={val => setcomment(val.target.value)}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    setcomment('')
                                    createComment(comment, userInfo)
                                }
                            }}
                            value={comment}
                            minRows={4}
                            maxRows={4}
                            variant="standard"
                            multiline
                            fullWidth
                            sx={{ minHeight: 90 }}
                            inputProps={{ maxLength: 500, }}
                            style={{ overflow: 'auto', minHeight: 90 }}
                            helperText={`${comment?.length ?? 0}/500`}
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    borderRadius: '8px',
                                    alignItems: "flex-start",
                                    py: 2,
                                    minHeight: 90,
                                    backgroundColor: '#F7F7F7'
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{
                                            width: '24px',
                                            overflow: 'hidden',
                                            height: '24px',
                                            borderRadius: '45%',
                                            alignSelf: 'flex-start',
                                            mx: 2
                                        }}
                                    >
                                        <img
                                            src={userInfo?.profile_picture_url ? userInfo?.profile_picture_url : '/assets/images/blank-profile.png'}
                                            width='100%' height='100%' />
                                    </InputAdornment>)
                            }}
                            placeholder={t(getInputsTranslationKey('placeholders.reply'))}
                        />}
                    </Grid>
                </>
            }
        </Grid>
    )
}

const MessagesStack: FC<{ isCommentsInSubGroups: boolean, displayedComments: Comment[] }> = ({ isCommentsInSubGroups, displayedComments }) => {
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [displayedComments])

    return (
        <Stack height={isCommentsInSubGroups ? '66%' : '74%'} width="100%">
            {displayedComments?.map(comment => (
                <Grid container p={1} py={2}>
                    <StyledComment key={comment.id} comment={comment} />
                </Grid>
            ))}
            <div ref={messagesEndRef} />
        </Stack>
    )
}