import React from 'react';
import { Grid, Typography } from '@mui/material';

const Title = ({ title }: { title: string }) => {
  return (
    <Typography fontWeight={400} color='#8B8D98' fontSize={'12px'}>
      {title}
    </Typography>
  );
};

export const RfqProductsTableHeader = () => {
  return (
    <Grid
      container
      px={2}
      py={1}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid item md={1.5}>
        <Title title='SEGMENT' />
      </Grid>
      <Grid item md={4.5}>
        <Title title='NAME' />
      </Grid>
      <Grid item container md={1.5} px={1}>
        <Title title='BRAND' />
      </Grid>
      <Grid item md={2}>
        <Title title='WARRANTY' />
      </Grid>
      <Grid container md={1.5} justifyContent={'center'}>
        <Title title='QUANTITY' />
      </Grid>
      <Grid item md={1}></Grid>
    </Grid>
  );
};
