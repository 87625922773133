import {
  Grid,
  Button,
  Card,
  CardMedia,
  Typography,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const HeroImageSection = ({ imageSrc }) => {
  return (
    <Grid item lg={5} xs={12}>
      <img src={imageSrc} alt='Hand shake' width='100%' height='100%' />

      {/* <Box
        component={motion.div}
        {...second}
        sx={{
          position: 'relative',
          height: smallScreens ? 320 : 550,
          overflow: 'hidden',
          borderRadius: t => t.spacing(0, 0, 0, 6),
        }}
      >
        <Image
          src='/assets/images/home/hand-shake.png'
          layout='fill'
          objectFit={smallScreens ? 'fill' : 'cover'}
          alt='Hand shake'
        />
      </Box> */}
    </Grid>
  );
};
