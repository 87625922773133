import { statusesTableData } from '@/config';
import { getUsersTranslationKey } from '@/hooks';
import { AuthorizeIcon, UnauthorizeIcon } from '@/icons';
import { useApproveUser, useAuthorizeUser, useCancelInvitation, useUnauthorizeUser } from '@/services';
import { useUserData, useUsersStore } from '@/zustand';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, ClickAwayListener, MenuItem, Paper, Popper } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { ConfirmPopup } from 'src/ui/components/shared';

export const UserTableMenu = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUserData = useUsersStore(state => state.changeUserData);

  const onSuccess = (newRow) => { props.table.options.meta?.updateData(props.index, props.row.id, newRow) }
  const authorize = useAuthorizeUser(onSuccess);
  const unauthorize = useUnauthorizeUser(onSuccess);
  const approve = useApproveUser(onSuccess);
  const onSuccessDelete = () => { props.table.options.meta?.deleteRow(props.row.id) }
  const cancel_Invitation = useCancelInvitation(onSuccessDelete);
  const menuIconStyle = { sx: { color: 'text.primary', width: '19px', height: '24px', mx: 0.5 } }
  const menuItemStyle = { fontSize: '14px !important', p: 1, my: 0 }
  const currentUserId = useUserData(state => state.userInfo.id);
  const unAutherizePopup = usePopup()


  const authorizeUser = () => {
    handleClose()
    authorize.mutate(props.row.id)
  }
  const unauthorizeUser = () => {
    handleClose()
    unAutherizePopup.handleOpen()
  }
  const handleUnautherizeAction = () => {
    unauthorize.mutate(props.row.id)
  }
  const approveUser = () => {
    handleClose()
    approve.mutate(props.row.id)
  }
  const cancelInvitation = () => {
    handleClose()
    cancel_Invitation.mutate(props.row.id)
  }
  return (
    <>
      {props.row.id == currentUserId && props.row.account_status !== statusesTableData.INVITED.text ? <></>
        :
        <>
          <Button sx={{ maxWidth: '5px', minWidth: '5px', borderColor: 'transparent' }} variant='outlined' onClick={openMenu}>
            <MoreVertIcon sx={{ fontSize: '20px !important' }} />
          </Button>
          <Popper open={open} anchorEl={anchorEl}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {props.row.account_status === statusesTableData.UNAUTHORIZED.text ? <MenuItem sx={{ ...menuItemStyle }} onClick={authorizeUser}>
                  <AuthorizeIcon />
                  {t(getUsersTranslationKey('menu_items.authorize'))}
                </MenuItem>
                  : props.row.account_status === statusesTableData.PENDING_APPROVAL.text ?
                    <MenuItem sx={{ ...menuItemStyle }} onClick={approveUser}>
                      <AuthorizeIcon />
                      {t(getUsersTranslationKey('menu_items.approve'))}
                    </MenuItem>
                    : props.row.account_status === statusesTableData.ACTIVE.text ? <MenuItem sx={{ ...menuItemStyle, color: 'red' }} onClick={unauthorizeUser}>
                      <UnauthorizeIcon />
                      {t(getUsersTranslationKey('menu_items.unauthorize'))}
                    </MenuItem>
                      : props.row.account_status === statusesTableData.INVITED.text ?
                        <MenuItem sx={{ ...menuItemStyle, color: 'red' }} onClick={cancelInvitation}>
                          <EventBusyIcon sx={{ fontSize: '20px', mr: 1 }} />
                          {t(getUsersTranslationKey('menu_items.cancel_invitation'))}
                        </MenuItem>
                        : <></>
                }
              </ClickAwayListener>
            </Paper>
          </Popper>
          <ConfirmPopup
            buttonTitleKey={t(getUsersTranslationKey('menu_items.unauthorize'))}
            handleClose={unAutherizePopup.handleClose}
            open={unAutherizePopup.isOpen}
            handleConfirm={handleUnautherizeAction}
            subTitleKey={t('popups.titles.unauthorize_user')}
            titleKey={t(getUsersTranslationKey('menu_items.unauthorize'))} />
        </>}
    </>
  )
}