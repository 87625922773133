const addProduct = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const removeProduct = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const changeQuantity = () => {
  return new Promise(resolve => {
    resolve({});
  });
};
const changePrice = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const changeAdditionalRequirements = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const EditPoFromScratchSavingServices = {
  addProduct,
  removeProduct,
  changeQuantity,
  changePrice,
  changeAdditionalRequirements,
};

export default EditPoFromScratchSavingServices;
