import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';
import { phoneRegExp } from 'src/logic/helpers/regex';

export const validationSchema = (props) => {
  return (
  yup.object({ mobile_number: yup.string()
    .required('This is required')
    .matches(phoneRegExp, "Invalid Phone Number")
    .test(
      'is-same',
      "mobile_same_as_old",
      (value) => value != props,
    )
  }))
}
