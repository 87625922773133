import { useAnimateOnView, useFullItemScrollInViewPort } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography } from '@mui/material';
import { motion, useTransform } from 'framer-motion';
import Image from 'next/image';

type Props = {};

export const LaptopBecomeASellerSection: React.FC<Props> = ({}) => {
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });

  const [ref1, node1] = useFullItemScrollInViewPort();
  const y1 = useTransform(node1, [0, 1], [80, -80]);

  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <Grid container sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          width: ['100%', '100%', '100%', '80%'],
          height: 'calc(100% + 50px)',
          right: 0,
          bottom: 0,
          zIndex: -1,
          bgcolor: 'background.primary.main',
          backgroundImage: 'url(/assets/images/shared/points.svg)',
          borderRadius: t => t.spacing(7, 0, 0, 0),
        }}
      />
      <Grid item lg={4} xs={12}>
        <motion.div {...first}>
          <Box
            component={motion.div}
            // animate={{ y: 10 }}
            // initial={{ y: -10 }}
            // transition={{
            //   repeat: Infinity,
            //   repeatType: 'reverse',
            //   duration: 2,
            // }}
            sx={{
              position: 'relative',
              height: 500,
              overflow: 'hidden',
              borderRadius: t => t.spacing(0, 7, 0, 0),
            }}
          >
            <Image
              src='/assets/images/become-a-seller/laptop.png'
              layout='fill'
              objectFit='cover'
              alt='Showing laptop'
            />
          </Box>
        </motion.div>
      </Grid>
      <Grid
        item
        component={ResponsiveContainer}
        lg={8}
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', py: 6 }}
      >
        <Box
          component={motion.div}
          ref={ref1}
          style={{ y: y1 } as any}
          sx={{ px: [0, 1, 2, 3, 4] }}
        >
          <Typography
            component={motion.div}
            {...second}
            variant='h2'
            fontWeight='700'
          >
            Est eget fusce dignissim et amet.
          </Typography>

          <Typography
            component={motion.div}
            {...third}
            color='text.third'
            mt={2}
            variant='body2'
            fontWeight= '400'
          >
            Neque tempor tempus ultricies consequat feugiat eget tortor. A
            tortor dui et fames sit elit, arcu. Sed placerat justo turpis a.
            Congue pellentesque pharetra sit odio ultrices aliquam. Est enim,
            enim congue cras consectetur ligula sagittis congue dictumst.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
