import { procurementStatus } from "@/config";
import {
    LinkType,
    Table,
    TableColumn,
    TableColumnMapper,
    TableGroup,
    TableMapper,
    TableRow,
    TableTab,
    TableType
} from "@/models";
import { convertStatusToApproverStatus } from "../procurement";

export const buildTableGroupModel = (
    jsonData: [] | null,
    tableMappers: Array<TableMapper>,
    columnMapper: Array<TableColumnMapper>,
    userId: number,
    userHasApproverRole: boolean = false,
    idAccessorKey: string = 'id',
    ownerIdAccessorKey: string = 'owner_id'
) => {
    const otherTable = []
    const myTable = []
    const myApprovalTable = []

    jsonData?.map(e => {
        const columns = convertToColumns(e, columnMapper)
        const ownerId = resolve(ownerIdAccessorKey, e)

        if (ownerId == userId) {
            const row = new TableRow(e[idAccessorKey], columns, e.status, '', e.has_new_changes, ownerId, e, null)
            myTable.push(row)
        } else {
            const approver = e.approvers && Array.isArray(e.approvers) && e.approvers.find(element => element.approver_id == userId)
            const shouldShowInApprovalSection = [procurementStatus.APPROVED, procurementStatus.REJECTED, procurementStatus.PENDING_APPROVAL].includes(e.status)
            if (approver != undefined && shouldShowInApprovalSection) {
                const status = convertStatusToApproverStatus(approver.status)
                const row = new TableRow(e[idAccessorKey], columns, status, '', e.has_new_changes, ownerId, e, null)
                myApprovalTable.push(row)
            }
            else {
                const row = new TableRow(e[idAccessorKey], columns, e.status, '', e.has_new_changes, ownerId, e, null)
                otherTable.push(row)
            }
        }
    })

    let tables = []

    let myTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.MINE)
    if (myTableMapper) {
        const myTableModel = buildTable(myTable, myTableMapper);
        tables.push(myTableModel)
    }

    if (userHasApproverRole || myApprovalTable.length > 0) {
        let myApprovalTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.MY_APPROVAL)
        if (myApprovalTableMapper) {
            const myApprovalTableModel = buildTable(myApprovalTable, myApprovalTableMapper);
            tables.push(myApprovalTableModel)
        }
    }

    let otherTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.OTHER)
    if (otherTableMapper) {
        const otherTableModel = buildTable(otherTable, otherTableMapper);
        tables.push(otherTableModel)
    }

    const key = tables.reduce((a, b) => a + ' - ' + b.key, '-')
    const tableGroup = new TableGroup(key, tables)

    return tableGroup
}

//deprecated
function buildTable(rows: Array<TableRow>, tableMapper: TableMapper) {
    let tabs = new Array<TableTab>()

    tableMapper.tabMappers.forEach((tabMapper) => {
        let tabRows
        if (tabMapper.statuses.includes(procurementStatus.ALL))
            tabRows = rows
        else
            tabRows = rows.filter((row) => tabMapper.statuses.includes(row.status))

        const tabKey = tableMapper.title + '-' + tabMapper.title + ' - ' + tabRows.length
        tabs.push(new TableTab(tabKey, tabMapper.title, tabRows, tabMapper.hasActions))
    })

    const key = tabs.reduce((a, b) => a + ' - ' + b.key, '-')
    return new Table(key, tableMapper.title, tableMapper.title, tableMapper.title, tabs, tableMapper.type, rows)
}

type BuildTableModelOptions = {
    jsonData: any[] | null;
    columnMapper?: TableColumnMapper[] | null;
    rowChildrenAccessorKey?: string | null;
    idAccessorKey?: string;
    showSingleChildAsMainRow?: boolean;
    isTheTableRequisition?: boolean;
}
export const buildTableModel = ({
    jsonData = [],
    columnMapper,
    rowChildrenAccessorKey = null,
    idAccessorKey = 'id',
    showSingleChildAsMainRow = true,
    isTheTableRequisition = false
}: BuildTableModelOptions) => {
    // const otherTable = []
    // const myTable = []
    // const myApprovalTable = []

    const tableRows = []

    const isGroupTable = rowChildrenAccessorKey != null


    jsonData?.map(e => {

        let isGroupRow = false
        let isMainRowFirstChild = false
        let mainRowData = e
        let childrenData = null
        if (isGroupTable) {
            let children = resolve(rowChildrenAccessorKey, e)

            if (showSingleChildAsMainRow) { isGroupRow = children?.length > 1 }
            if (!showSingleChildAsMainRow) { isGroupRow = children?.length > 0 }

            childrenData = isGroupRow ? children : null
            if (children?.length == 1 && showSingleChildAsMainRow) {
                isMainRowFirstChild = true
                mainRowData = children[0]
            }
        }

        const mainRowColumns = convertToColumns(mainRowData, columnMapper)
        let childrenRows = null
        if (isGroupRow && childrenData) {
            childrenRows = []
            childrenData.forEach(childData => {
                let childColumns = convertToChildColumns(childData, columnMapper)
                childrenRows.push(
                    new TableRow(childData[idAccessorKey], childColumns, childData.status, childData.form_type, childData.has_new_changes, null, childData, null, e.id)
                )
            })
        }
        let ownerId = (isTheTableRequisition ? mainRowData?.owner_id : mainRowData?.owner?.id) ?? null;
        const row = new TableRow(mainRowData[idAccessorKey], mainRowColumns, mainRowData.status, mainRowData.form_type, mainRowData.has_new_changes, ownerId, mainRowData, childrenRows, e.id)

        tableRows.push(row)

        // const ownerId = resolve(ownerIdAccessorKey, e)
        //
        // if (ownerId == userId) {
        //     const row = new TableRow(e[idAccessorKey], columns, e.status, e.has_new_changes, ownerId, e)
        //     myTable.push(row)
        // } else {
        //     const approver = e.approvers && Array.isArray(e.approvers) && e.approvers.find(element => element.approver_id == userId)
        //     const shouldShowInApprovalSection = [procurementStatus.APPROVED, procurementStatus.REJECTED, procurementStatus.PENDING_APPROVAL].includes(e.status)
        //     if (approver != undefined && shouldShowInApprovalSection) {
        //         const status = convertStatusToApproverStatus(approver.status)
        //         const row = new TableRow(e[idAccessorKey], columns, status, e.has_new_changes, ownerId, e)
        //         myApprovalTable.push(row)
        //     }
        //     else {
        //         const row = new TableRow(e[idAccessorKey], columns, e.status, e.has_new_changes, ownerId, e)
        //         otherTable.push(row)
        //     }
        // }
    })

    return new Table('key', 'id', 'name', 'title', [], '', tableRows)

    // let tables = []
    //
    // let myTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.MINE)
    // if (myTableMapper) {
    //     const myTableModel = buildTable(myTable, myTableMapper);
    //     tables.push(myTableModel)
    // }
    //
    // if (userHasApproverRole || myApprovalTable.length > 0) {
    //     let myApprovalTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.MY_APPROVAL)
    //     if (myApprovalTableMapper) {
    //         const myApprovalTableModel = buildTable(myApprovalTable, myApprovalTableMapper);
    //         tables.push(myApprovalTableModel)
    //     }
    // }
    //
    // let otherTableMapper = tableMappers.find((tableMapper) => tableMapper.type == TableType.OTHER)
    // if (otherTableMapper) {
    //     const otherTableModel = buildTable(otherTable, otherTableMapper);
    //     tables.push(otherTableModel)
    // }
    //
    // const key = tables.reduce((a, b) => a + ' - ' + b.key, '-')
    // const tableGroup = new TableGroup(key, tables)
    //
    // return tableGroup
}

function convertToChildColumns(data, columnsMapper: Array<TableColumnMapper>) {
    let columns = [];
    const firstVisableColumnIndex = columnsMapper.findIndex((e) => !e.hidden)

    columnsMapper.forEach((columnMapper, index) => {
        if (!columnMapper.childMapper) {
            let column = new TableColumn(
                columnMapper.accessorKey,
                columnMapper.title,
                columnMapper.type,
                columnMapper.format,
                '',//resolve(columnMapper.accessorKey, data),
                '',
                true
            )
            columns.push(column)
            return
        }

        let secondaryValue = null
        // if (columnMapper.secondaryMapper) {
        //     secondaryValue = resolve(columnMapper.secondaryMapper.accessorKey, data)
        // }
        let value = null
        if (columnMapper.childMapper.computeFunction) {
            value = columnMapper.childMapper.computeFunction(data)
        } else {
            value = resolve(columnMapper.childMapper.accessorKey, data)
        }

        const column = new TableColumn(
            columnMapper.childMapper.accessorKey,
            columnMapper.childMapper.title,
            columnMapper.childMapper.type,
            columnMapper.childMapper.format,
            value,//resolve(columnMapper.accessorKey, data),
            secondaryValue,
            columnMapper.childMapper.hidden
        )
        column.hasNewChanges = data.has_new_changes && index == firstVisableColumnIndex
        column.columnSort = columnMapper.childMapper.columnSort

        columns.push(column)
    })

    return columns
}

function convertToColumns(data, columnsMapper: Array<TableColumnMapper>) {
    let columns = [];
    const firstVisableColumnIndex = columnsMapper.findIndex((e) => !e.hidden)

    columnsMapper.forEach((columnMapper, index) => {
        let secondaryValue = null
        if (columnMapper.secondaryMapper) {
            if (columnMapper.secondaryMapper.computeFunction) {
                // console.log("compute")
                secondaryValue = columnMapper.secondaryMapper.computeFunction(data)
            }
            else secondaryValue = resolve(columnMapper.secondaryMapper.accessorKey, data)
        }
        let value = null
        if (columnMapper.computeFunction) {
            value = columnMapper.computeFunction(data)
        } else {
            value = resolve(columnMapper.accessorKey, data)
        }

        const column = new TableColumn(
            columnMapper.accessorKey,
            columnMapper.title,
            columnMapper.type,
            columnMapper.format,
            value,//resolve(columnMapper.accessorKey, data),
            secondaryValue,
            columnMapper.hidden
        )
        column.hasNewChanges = data.has_new_changes && index == firstVisableColumnIndex
        column.columnSort = columnMapper.columnSort

        columns.push(column)
    })

    return columns
}

//resolve("document.body.style.width")
// // or
// resolve("style.width", document.body)
// // or even use array indexes
// // (someObject has been defined in the question)
// resolve("part.0.size", someObject)
// // returns null when intermediate properties are not defined:
// resolve('properties.that.do.not.exist', {hello:'world'})
function resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
}