import { Divider, Grid, Typography } from '@mui/material';
import { DeleteIcon } from 'src/ui/icons/delete';
import { FieldsLinkOrCreateSeller } from './fields-link-or-create-seller';
import AlreadyExistsLink from './already-seller-link';

type SellerItemProps = {
  index: number;
  onRemove: () => void;
  productId: number | null;
  existingSellerId?: string;
};

export const SellerItem = ({ index, onRemove, productId, existingSellerId }: SellerItemProps) => {
  return (
    <>
      <Grid item md={12} py={1}>
        <Divider sx={{ opacity: 0.7, my: 2 }} />
      </Grid>{' '}
      <Grid container md={12} alignItems={'start'}>
        <Grid item container md={4}>
          <Typography fontSize={'13px'} fontWeight={600} color={'text.disabled'} mr={1}>
            New Private Seller
          </Typography>
          <DeleteIcon onClick={onRemove} fontSize='sx' style={{ cursor: 'pointer', marginTop: 2 }} />
          <Grid container alignItems={'center'}>
            {existingSellerId && existingSellerId !== '' && (
              <Grid item>
                <AlreadyExistsLink text='seller' sellerId={existingSellerId} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md={8} justifyContent={'start'}>
          <FieldsLinkOrCreateSeller product_id={productId} withoutSellersSuggestion={true} index={index} />
        </Grid>
      </Grid>
    </>
  );
};
