import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { Button } from "@mui/material"
import { FC, useState } from "react"
import { ConfirmPopup, StyledLoadingButton } from "src/ui/components/shared"
import { BundleRFQ, BundleRFQDetails } from '@/models';
import { ProcurementButtons } from '@/config';

type CancelApprovalProps = { bundleRFQDetails: BundleRFQDetails | null }

export const CancelApproval: FC<CancelApprovalProps> = ({ bundleRFQDetails }) => {
    const { t } = useTranslation();
    const cancelSendForApprovals = useProcurementStore(state => state.bundleRFQDetailsSliceActions.cancelSendForApprovals)
    const { customLoadingButton } = useProcurementStore(state => state.bundleRFQDetailsSliceProperties)

    const [showCancelApprovePopup, setshowCancelApprovePopup] = useState(false);
    const handleOpenCancelApprovePopup = () => setshowCancelApprovePopup(true);
    const handleCloseCancelApprovePopup = () => setshowCancelApprovePopup(false);

    const cancelSentForApprovals = () => {
        cancelSendForApprovals(bundleRFQDetails?.bundleRFQModel?.id)
        handleCloseCancelApprovePopup()
    }
    return (
        <>
            {
                bundleRFQDetails?.canCancelSendingForApprovals &&
                <StyledLoadingButton customWidth variant="outlined" onClick={handleOpenCancelApprovePopup} translateKey="cancel_approval_request"
                    loading={customLoadingButton == ProcurementButtons.CANCEL_BUNDLE_RFQ_APPROVAL} color='error'
                />
            }

            <ConfirmPopup
                titleKey={t("buttons.cancel_approval")}
                subTitleKey={t("popups.subTitles.cancel_approval")}
                buttonTitleKey={t("buttons.yes_confirm")}
                handleClose={handleCloseCancelApprovePopup}
                handleConfirm={cancelSentForApprovals}
                open={showCancelApprovePopup}
                hideDeleteIcon
            />
        </>
    )
} 