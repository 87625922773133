import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { createFilterSlice, FilterSlice } from '../Filters';
import { AlternativeProductDetailsSlice, createAlternativeProductDetailsSlice, createProductDetailsSlice, ProductDetailsSlice } from '../product-details';
import { createProductAlternativesSlice, ProductAlternativesSlice } from '../productsAlternatives';
import { CatalogForSellerTableSlice, createCatalogTableForSellerSlice } from '../seller-catalog';
import { CatalogListDetailsSlice, createCatalogListDetailsSlice } from '../seller-catalog/createSellerCatalogListSlice';
import { createQuoteDetailsSlice, QuoteDetailsSlice } from './seller/seller-slices/createQuoteDetailsSlice';
import { createProductsTableSlice, ProductsTableSlice } from './shared/createProductsTableSlice';
import { BadgeSlice, BuyerQuoteDetailsSlice, createBadgeSlice, createBuyerQuoteDetailsSlice, EditPurchaseOrderFromScratch, createEditPurchaseOrderFromScratchSlice, EditBundlePurchaseOrderSlice, createEditBundlePurchaseOrderSlice } from './slices';
import { ComparisonTableDetailsSlice, createComparisonTableDetailsSlice } from './slices/comparisonTableDetailsSlice';
import { BundleRFQDetailsSlice, createBundleRFQDetailsSlice } from './slices/createBundleRFQdetailsSlice';
import { createEditBundleRFQDetailsSlice, EditBundleRFQDetailsSlice } from './slices/createEditBundleRFQdetailsSlice';
import { BuyerBundlePurchaseOrderDetailsSlice, createBuyerBundlePurchaseOrderDetailsSlice } from './slices/createBuyerBundlePurchaseOrder';
import { BuyerPurchaseOrderDetailsSlice, createBuyerPurchaseOrderDetailsSlice } from './slices/createBuyerPurchaseOrderSlice';
import {
    BuyerPurchaseOrderTableSlice,
    createBuyerPurchaseOrderTableSlice
} from "./slices/createBuyerPurchaseOrderTableSlice";
import { BuyerQuotesTableSlice, createBuyerQuotesTableSlice } from "./slices/createBuyerQuotesTableSlice";
import { BuyerRequestForQuoteDetailsSlice, createBuyerRequestForQuoteDetailsSlice } from './slices/createBuyerRequestForQuoteSlice';
import { ConvertRequisitionToRFQ, createConvertRequisitionToRFQ } from './slices/createConvertRequisitionToRFQ';
import { createCTTableSlice, CTTableSlice } from "./slices/createCTTableSlice";
import { createProcurementSlice, ProcurementSlice } from './slices/createProcurementSlice';
import { createProductFormManagerSlice, ProductFormManagerSlice } from './slices/createProductFormManagerSlice';
import { createProjectSlice, ProjectSlice } from './slices/createProjectSlice';
import { createRequestForQuotesTableSlice, RequestForQuotesTableSlice } from "./slices/createRequestForQuotesTableSlice";
import { createRequisitionsDetailsSlice, RequisitionsDetailsSlice } from './slices/createRequisitionDetailsSlice';
import { createRequisitionSlice, RequisitionSlice } from './slices/createRequisitionSlice';
import { createRequisitionsTableSlice, RequisitionsTableSlice } from './slices/createRequisitionsTableSlice';
import { createTableSlice, TableSlice } from './slices/createTableSlice';
import { createGetAllProjectsSlice, GetAllProjectsSliceSlice } from './shared/createGetAllProjects';

type StoreState = ProjectSlice & ProcurementSlice & RequisitionSlice & ProductDetailsSlice & ProductAlternativesSlice & BuyerRequestForQuoteDetailsSlice
    & FilterSlice & AlternativeProductDetailsSlice & BadgeSlice & RequisitionsTableSlice & RequisitionsDetailsSlice & BuyerPurchaseOrderDetailsSlice & CatalogForSellerTableSlice & CatalogListDetailsSlice
    & ConvertRequisitionToRFQ & BundleRFQDetailsSlice & EditBundleRFQDetailsSlice & RequestForQuotesTableSlice & BuyerQuotesTableSlice & BuyerQuoteDetailsSlice & ProductFormManagerSlice & ComparisonTableDetailsSlice & BuyerBundlePurchaseOrderDetailsSlice
    & CTTableSlice & BuyerPurchaseOrderTableSlice & TableSlice & QuoteDetailsSlice & ProductsTableSlice & GetAllProjectsSliceSlice & EditPurchaseOrderFromScratch & EditBundlePurchaseOrderSlice

export const useProcurementStore = create<StoreState>(devtools((...a) => ({
    ...createProcurementSlice(...a),
    ...createBadgeSlice(...a),
    ...createRequisitionSlice(...a),
    ...createProductDetailsSlice(...a),
    ...createProductAlternativesSlice(...a),
    ...createFilterSlice(...a),
    ...createAlternativeProductDetailsSlice(...a),
    ...createProjectSlice(...a),
    ...createRequisitionsTableSlice(...a),
    ...createRequisitionsDetailsSlice(...a),
    ...createConvertRequisitionToRFQ(...a),
    ...createBundleRFQDetailsSlice(...a),
    ...createEditBundleRFQDetailsSlice(...a),
    ...createRequestForQuotesTableSlice(...a),
    ...createBuyerQuotesTableSlice(...a),
    ...createBuyerQuoteDetailsSlice(...a),
    ...createComparisonTableDetailsSlice(...a),
    ...createBuyerPurchaseOrderDetailsSlice(...a),
    ...createEditPurchaseOrderFromScratchSlice(...a),
    ...createBuyerBundlePurchaseOrderDetailsSlice(...a),
    ...createCTTableSlice(...a),
    ...createBuyerPurchaseOrderTableSlice(...a),
    ...createEditBundlePurchaseOrderSlice(...a),
    ...createBuyerRequestForQuoteDetailsSlice(...a),
    ...createProductFormManagerSlice(...a),
    ...createCatalogTableForSellerSlice(...a),
    ...createCatalogListDetailsSlice(...a),
    ...createTableSlice(...a),
    ...createQuoteDetailsSlice(...a),
    ...createProductsTableSlice(...a),
    ...createGetAllProjectsSlice(...a)
})))
export * from './seller';

