import { Typography } from '@mui/material';

interface props {
  title: string;
  value: string | number;
}
export const TextWithValue: React.FC<props> = props => {
  return (
    <Typography
      fontWeight={400}
      variant='subtitle2'
      color={'text.third'}
      lineHeight={1.35}
    >
      {props.title}{' '}
      <span style={{ fontWeight: '600', color: '#3A3E50' }}>{props.value}</span>
    </Typography>
  );
};
