import {
  Grid,
  Button,
  Card,
  CardMedia,
  Typography,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
// import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DeleteIcon } from 'src/ui/icons/delete';
import { ConfirmPopup } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

export const ProductChip = ({
  mainProduct,
  setSelectedProductIndex,
  selectedProductIndex,
  index,
  removeProduct,
  requisition,
  tabId,
}) => {
  const [showDelPopup, setshowDelPopup] = useState(false);
  const handleCloseDelPopup = () => setshowDelPopup(false);
  const handleOpenDelPopup = () => {
    setshowDelPopup(true);
  };
  const handleDelete = (product, id, tabId) => {
    removeProduct(product, id, tabId);
  };
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        transform: 'rotateX(180deg)',
        width: '200px',
        p: 1,
        bgcolor: index === selectedProductIndex ? 'primary.light' : 'grey.50',
        height: '50px',
        borderColor: 'white',
        cursor: 'pointer',
        transition: t =>
          t.transitions.create('border', {
            duration: t.transitions.duration.shortest,
          }),
        '&:hover': {
          borderColor: 'primary.main',
        },
        flexDirection: 'row',
      }}
      onClick={() => setSelectedProductIndex(index)}
    >
      <Grid container>
        <CardMedia
          component='img'
          image={mainProduct.imageURL}
          sx={{
            height: '31px',
            width: '31px',
            objectFit: 'contain',
            backgroundColor: 'white',
          }}
        />
        <Typography
                variant='body2'
          fontWeight='500'
          sx={{
            width: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            alignSelf: 'center',
            marginLeft: 1,
            color: index === selectedProductIndex ? 'primary.main' : 'text.disabled',
          }}
        >
          {mainProduct.name}
        </Typography>
        <Button
          onClick={handleOpenDelPopup}
          sx={{
            maxWidth: '20px',
            maxHeight: '30px',
            minWidth: '20px',
            minHeight: '30px',
          }}
        >
          <DeleteIcon
            sx={{ color: 'text.third', fontSize: '23px !important' }}
          />
        </Button>
        <ConfirmPopup
          buttonTitleKey={t('buttons.confirm_delete')}
          titleKey={t('texts.confirm_delete',{ ProductName: mainProduct.name })}
          handleClose={handleCloseDelPopup}
          handleConfirm={() => handleDelete(mainProduct, requisition.id, tabId)}
          open={showDelPopup}
        />
      </Grid>
      {index === selectedProductIndex && (
        <Divider
          sx={{
            marginTop: 1.1,
            marginLeft: -1,
            borderBottomWidth: 7,
            borderColor: 'primary.main',
            width: '200px',
          }}
        />
      )}
    </Card>
  );
};
