import { BundleRFQDetails } from '@/models';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'next-i18next';
import React from 'react';

type Props = { bundleRFQ: BundleRFQDetails; number_of_Products: number; number_of_Services: number };

export const BundleRFQPDFDocument: React.VFC<Props> = ({ bundleRFQ, number_of_Products, number_of_Services }) => {
  const { t } = useTranslation();
  return (
    <Document>
      {bundleRFQ.requestForQuotes.map((rfqDetails, index) => {
        return (
          <Page size='A4' style={styles.page} key={rfqDetails.rfqModel?.id}>
            <View>
              <Text style={styles.title}>{bundleRFQ.bundleRFQModel?.name}</Text>
            </View>
            <View style={[styles.section, { marginTop: '12px' }]}>
              <Text style={styles.smallBlue}> {t('texts.date_created')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundleRFQ.bundleRFQModel?.created_at}</Text>
              <Text style={styles.smallBlue}>{t('texts.number_of_rfqs')} </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundleRFQ.requestForQuotes.length} </Text>
              <Text style={styles.smallBlue}>Internal Project: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundleRFQ.bundleRFQModel?.project?.name}</Text>
              <Text style={styles.smallBlue}>{t('texts.number_of_products')} </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{number_of_Products} </Text>
              <Text style={styles.smallBlue}>{t('texts.number_of_services')} </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{number_of_Services} </Text>
              <Text style={styles.smallBlue}>{t('texts.req')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqDetails?.rfqModel?.requisition?.name}</Text>
            </View>
            <View style={[styles.section, { marginTop: '15px', width: '85%' }]}>
              <View>
                <Text style={[styles.smallGrey, { marginBottom: '6px' }]}>
                  {t('texts.rfq_number', { rfq_number: index + 1, rfqs_length: bundleRFQ.requestForQuotes.length })}
                </Text>
                <Text style={styles.mediumTitle}>{rfqDetails.rfqModel?.name}</Text>
              </View>
            </View>
            <RFQDetails rfqModel={rfqDetails.rfqModel} />
            <View style={{ width: '90%', marginTop: '20px' }}>
              {rfqDetails.rfqModel?.products.map((product, index) => {
                return <ProductCard item={product} quantity={product.quantity} index={index} key={index} />;
              })}
            </View>
            <View style={{ height: '10%' }} fixed />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                // `Page ${pageNumber} of ${totalPages}`
                t('texts.page_number', { page_number: pageNumber, pages_length: totalPages })
              }
              fixed
            />
          </Page>
        );
      })}
    </Document>
  );
};
const RFQDetails = ({ rfqModel }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
      <View>
        <Text style={styles.bigBlue}>{rfqModel?.seller_details.name}</Text>
        {/* <View style={styles.spaceBetweenSection}>
                    <Text style={styles.smallBlue}> {t('texts.company_name')} </Text>
                    <Text style={[styles.smallGrey]}>
                        {rfqModel?.company_details.name}
                    </Text>
                </View> */}
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.fax')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.seller_details.fax_number ? rfqModel?.seller_details.fax_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.phone')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.seller_details.phone_number ? rfqModel?.seller_details.phone_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.address')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.seller_details.company_address ? rfqModel?.seller_details.company_address : t('texts.n_a')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={styles.bigBlue}>{t('popups.titles.general_terms')}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.warranty_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.terms_bundle.warranty_term.name ? rfqModel?.terms_bundle.warranty_term.name : 'N/A'}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.payment_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.terms_bundle.payment_term.name ? rfqModel?.terms_bundle.payment_term.name : 'N/A'}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.delivery_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {rfqModel?.terms_bundle.delivery_term.name ? rfqModel?.terms_bundle.delivery_term.name : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};
const ProductDetails = ({ title, value }) => {
  return (
    <View style={{ marginRight: '30px', marginHorizontal: '5px' }}>
      <Text style={[styles.smallGrey, { marginBottom: '2px' }]}>{title}</Text>
      <Text style={styles.smallBlack}>{value}</Text>
    </View>
  );
};

const ProductCard = ({ item, index, quantity }) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        index % 2 == 0 ? styles.section : styles.blueSection,
        { height: '53px', display: 'flex', alignItems: 'center' },
      ]}
    >
      <View>
        <Image
          style={styles.smallImage}
          src={item.product.image_url + '?noCache=' + Math.random().toString()}
          source={{
            header: {
              'Access-Control-Allow-Origin': '*'
            }
          }}
        />
      </View>
      <View style={styles.productDetails}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.smallTitle}>
            {item.product.name} {item?.product?.is_private_product ? '  (Private)' : ''}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: '10px' }}>
          <ProductDetails
            title={t('texts.segment')}
            value={item?.product?.category_kind == 1 ? 'product' : 'service'}
          />
          <ProductDetails
            title={t('texts.type')}
            value={item.product.category.name ? item.product.category.name : 'N/A'}
          />
          <ProductDetails title={t('texts.brand')} value={item.product.brand.name ? item.product.brand.name : 'N/A'} />
          <ProductDetails
            title={t('texts.warranty_term')}
            value={item.warranty_term?.name ? item.warranty_term?.name : 'N/A'}
          />
          <ProductDetails
            title={t('texts.part_number')}
            value={item.product.part_number ? item.product.part_number : 'N/A'}
          />
          {item.product.productAttributes.slice(0, 3).map(({ name, value }) => (
            <ProductDetails key={name} title={name} value={value} />
          ))}
        </View>
      </View>
      <View style={{ alignSelf: 'flex-start' }}>
        <Text style={{ fontSize: '8px', margin: '5px' }}>{quantity ? quantity : 0} x</Text>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    margin: '30px',
  },
  section: {
    flexDirection: 'row',
    width: '535px',
  },
  blueSection: {
    backgroundColor: '#EAEEF6',
    flexDirection: 'row',
    width: '535px',
  },
  spaceBetweenSection: {
    flexDirection: 'row',
    width: '240px',
    justifyContent: 'space-between',
  },
  bigImageSection: {
    width: '90px',
  },
  smallImageSection: {
    width: '61px',
  },
  productDetails: {
    width: '474px',
  },
  title: {
    color: '#00385E',
    fontSize: '18px',
    fontWeight: 800,
    width: '90%',
  },
  mediumTitle: {
    color: '#13628C',
    fontSize: '12px',
    fontWeight: 600,
  },
  smallTitle: {
    color: '#13628C',
    fontSize: '8px',
    fontWeight: 500,
    width: '294px',
  },
  smallBlue: {
    color: '#00385E',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigBlue: {
    color: '#00385E',
    fontSize: '9px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 2,
  },
  smallGrey: {
    color: '#8B8D98',
    fontSize: '6px',
    fontWeight: 400,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 50,
    marginBottom: 2,
    left: 0,
    right: 0,
    fontSize: '6px',
    fontWeight: 400,
    color: '#8B8D98',
  },
  smallBlack: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigImage: {
    width: '61px',
    height: '61px',
  },
  smallImage: {
    width: '35px',
    height: '35px',
    margin: '5px',
    marginRight: '10px',
  },
});
