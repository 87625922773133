import React from 'react';
import NotificationItem from 'src/logic/models/notification';
import { useMarkNotificationAsRead } from 'src/logic/services/notification';
import { MenuItem, Typography } from '@mui/material';
import { useFormTypeNavigator } from 'src/logic/services/form-types-navigation';

type Props = {
  item: NotificationItem;
  handleClose: () => void;
};


const NotificationMenuItem = ({ item, handleClose }: Props) => {
  const navigate = useFormTypeNavigator(item.meta.form_type, item.meta.form_id);
  const { mutate: markAsRead, isProcessing } = useMarkNotificationAsRead();

  const handleReadNotification = () => {
    if (!item.read && !isProcessing(item.id)) {
      markAsRead({ id: item.id });
    }
  };

  const handleClick = () => {
    handleReadNotification();
    navigate();
    handleClose();
  };

  const formatDate = (dateString: string) => {
    const [datePart, timePart] = dateString.split(' ');

    const [day, month, year] = datePart.split('-').map(Number);

    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes, seconds);

    let formattedHours = date.getHours();
    const ampm = formattedHours >= 12 ? 'PM' : 'AM';
    formattedHours = formattedHours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    const formattedDate = date.toLocaleDateString();

    return `${formattedDate} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedCreatedAt = formatDate(item.created_at);
  return (
    <MenuItem onClick={handleClick} style={{ paddingBlock: '.5rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          width: "100%"
        }}
      >
        <div>
          <Typography
            color='#3A3E50'
            variant='subtitle1'
            fontWeight={400}
            lineHeight={1.2}
            style={{ whiteSpace: 'pre-wrap', marginBottom: '3px' }}
          >
            {item.content}
          </Typography>
          <Typography
            color='#C5C6CB'
            variant='subtitle2'
            fontWeight={400}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {formattedCreatedAt}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!item.read && (
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '100%',
                backgroundColor: '#0AB87B',
              }}
            />
          )}
        </div>
      </div>
    </MenuItem>
  );
};

export default NotificationMenuItem;
