import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { CheckboxWithLabel, MultilineTextFieldForm, SelectWithPlaceholder } from '@/components';
import { GeneralObject } from '@/models';
import { Formik } from 'formik';
import initialValues from './initialValues';
import { ProcurementPopupStatus } from '@/config';
import { commentsValidationSchema, commentsWithReasonValidationSchema } from './validationSchema';

type ProcurementActionsPopupFormContentProps = {
  icon: ReactNode;
  popupTitle: string;
  openedPopupStatus: ProcurementPopupStatus | null;
  handlePopupAction: (values: any) => void;
  buttonTitle: string;
  status: string;
  popupSubTitle?: string;
  commentNote?: string | null;
  checkLabel?: string;
  getReasonsForPopup?: () => void;
  reasonsForPopup?: GeneralObject[];
  cancelStatus?: boolean;
  isButtonLoading?: boolean;
};

export const ProcurementActionsPopupFormContent: FC<ProcurementActionsPopupFormContentProps> = props => {
  const reasonsStatus = useMemo(
    () =>
      [
        ProcurementPopupStatus.REJECT,
        ProcurementPopupStatus.REJECT_ALL,
        ProcurementPopupStatus.RECALL,
        ProcurementPopupStatus.DECLINE,
      ].includes(props.openedPopupStatus),
    [props.openedPopupStatus]
  );

  const handleSubmit = (values, actions) => props.handlePopupAction(values);
  useEffect(() => {
    if (props.getReasonsForPopup) props.getReasonsForPopup();
  }, [props.openedPopupStatus]);
  return (
    <Grid container gap={2} width={300}>
      <Formik
        initialValues={initialValues}
        validationSchema={reasonsStatus ? commentsWithReasonValidationSchema : commentsValidationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <Grid item md={12} textAlign='center' mb={1}>
              {props.icon}

              <Grid item md={12} textAlign='center'>
                <Typography variant='overline' color='primary.main' fontWeight='700' color='text.disabled'>
                  {/* color={props.status == "error" ? "error.main" : props.status == "success" ? "success.main" : props.status == "primary" ? "primary" : "text.disabled"}> */}
                  {props.popupTitle}
                </Typography>
              </Grid>

              {props.popupSubTitle && (
                <Grid item md={12} textAlign='center'>
                  <Typography variant='body2' color='text.disabled' my={1} lineHeight={1.3}>
                    {props.popupSubTitle}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {props.reasonsForPopup && (
              <Grid item container md={12} width={300}>
                <SelectWithPlaceholder
                  translateKey='reason'
                  name='reasonId'
                  items={props.reasonsForPopup}
                  required
                  placeholder='select_reason'
                />
              </Grid>
            )}

            <Grid item container md={12}>
              <MultilineTextFieldForm translateKey='comment' name='comment' showLabel commentNote={props.commentNote} />
              {props.cancelStatus && <CheckboxWithLabel name='hideReason'>{props.checkLabel}</CheckboxWithLabel>}
              {/* <Button variant="contained" fullWidth type="submit" color={props.status}>{props.buttonTitle}</Button> */}
              <Button variant='contained' fullWidth type='submit' color={props.status} disabled={props.isButtonLoading}>
                {props.isButtonLoading ? <CircularProgress size={24} color='inherit' /> : props.buttonTitle}{' '}
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
};
