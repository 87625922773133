import { Box, Grid, Switch, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface props {
  label: string;
  value: boolean;
  handleChange: Function;
}
export const StyledSwitch: React.FC<props> = ({ label, value, handleChange }) => {
  return (
    <Grid container my={2} sx={{ justifyContent: 'space-between' }}>
      <Grid item xs={10}>
        <Typography variant='h6' color={value ? 'text.primary' : 'info.light'} fontWeight={700}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box sx={{ cursor: 'pointer', width: 72, height: 25, }} onClick={() => handleChange(!value)}>
          <Switch
            checked={value}
            sx={{
              width: 72,
              height: 25,
              padding: 0,
              '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 0.1,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(47px)',
                  color: 'secondary.contrastText',
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                  },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color: 'red'
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.3,
                },
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 23,
                height: 23,
              },
              '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: 'text.third',
                opacity: 1,
                transition: { duration: 500 },
                "&:after, &:before": {
                  color: "white",
                  fontSize: "12px",
                  position: "absolute",
                  top: "4px"
                },
                "&:after": {
                  content: value && "'YES'",
                  left: "13px"
                },
                "&:before": {
                  content: !value && "'NO'",
                  right: "16px"
                }
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
