import React from 'react';

export const mergeRefs = <T>(
  ...refs: (
    | React.MutableRefObject<T>
    | React.RefObject<T>
    | React.ForwardedRef<T>
  )[]
): React.Ref<T> => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        // @ts-ignore
        ref.current = inst;
      }
    }
  };
};
