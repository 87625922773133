import { HowItWorksCard } from '@/components';
import { useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Grid, Typography } from '@mui/material';
import {  LAYER } from '../../../../../../public/assets/icons';
import { motion } from 'framer-motion';

type Props = {};

export const WhatWeCanOfferCareersSection: React.FC<Props> = ({}) => {
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <>
      <ResponsiveContainer
        as={motion.div}
        {...first}
        variant='h2'
        sx={{ textAlign: 'center', fontWeight: '700' }}
      >
        What we can offer our partners
      </ResponsiveContainer>
      <ResponsiveContainer
        as={motion.div}
        {...second}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        <Typography
          color='text.third'
          fontWeight='400'
          variant='body2'
          textAlign='center'
          maxWidth={560}
        >
          Amet, id integer ultricies lorem. Eu volutpat risus hendrerit egestas
          placerat lectus nisi rhoncus ornare. Quis volutpat tincidunt sed
          interdum viverra. Quis dolor vitae enim, viverra sodales. Ultrices id
          viverra diam diam nunc sem commodo.
        </Typography>
      </ResponsiveContainer>
      <Grid
        component={ResponsiveContainer}
        container
        spacing={2}
        pt={6}
        justifyContent='center'
      >
        {Array(4)
          .fill(0)
          .map((_, i) => (
            <Grid item xl={3} lg={5} xs={12} key={i}>
              <motion.div {...third}>
                <HowItWorksCard
                  primaryTextKey='Sed habitant blandit sit est.'
                  secondaryTextKey='Nec nulla id eget dolor adipiscing ipsum et, dignissim. Tellus enim ultrices vitae sit aenean laoreet rutrum purus. In platea ut lorem tincidunt elit vulputate.'
                  icon={LAYER}
                />
              </motion.div>
            </Grid>
          ))}
      </Grid>
    </>
  );
};
