import { WebSocketMiddleware } from '../websocker-io';
import { mutationWrapper, queryWrapper } from '../react-query';

export const queryWithSocket = (query: Function, params: any, channel: string, onSuccess: (data?: any) => void, onError?: (data: any) => void) => {
    WebSocketMiddleware(channel, onSuccess)
    return queryWrapper(query, params, channel, onSuccess, onError);
}

export const mutationWithSocket = (mutation: Function, params: any, channel: string, onSuccess: (data?: any) => void, onError?: (data: any) => void) => {
    WebSocketMiddleware(channel, onSuccess)
    return mutationWrapper(mutation, params, channel, onSuccess, onError);
}