import { Grid } from '@mui/material';
import { useRequisition } from '@/zustand';
import { Requisition } from '@/models';
import { useState } from 'react';
import {
  GeneralTermsSelect,
  PopupWithTitle,
  ProductTab,
  RequisitionProductMobile,
} from '@/components';
import { Box } from '@mui/system';
import { AddProductsButton } from '..';

type Props = {
  requisition: Requisition;
  selectedProductIndex: number;
  setSelectedProductIndex: Function;
};

export const ProductsListSmallScreen: React.FC<Props> = ({
  requisition,
  selectedProductIndex,
  setSelectedProductIndex,
}) => {
  const [open, setOpen] = useState(false);
  const removeProduct = useRequisition(state => state.removeProduct);
  const handleClickOpen = id => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {requisition?.products?.map((reqProduct, index) => {
        return (
          <div key={reqProduct.tabId}>
            {selectedProductIndex == index && (
              <Grid container>
                <Grid item md={12} xs={12}>
                  <ProductTab
                    index={selectedProductIndex}
                    product={reqProduct.mainProduct}
                    selectedProductIndex={selectedProductIndex}
                    onClickCard={handleClickOpen}
                    removeProduct={removeProduct}
                    requisition={requisition}
                    tabId={reqProduct.tabId}
                    isPopupProducts={false}
                  />
                </Grid>
                <Grid item md={12} xs={12} mt={1}>
                  <RequisitionProductMobile
                    product={reqProduct.mainProduct}
                    selectedProductIndex={selectedProductIndex}
                    mainProduct={true}
                    requisition={requisition}
                    tabId={reqProduct.tabId}
                    removeProduct={removeProduct}
                    isWithDetails={false}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        );
      })}
      <PopupWithTitle title={'Product Tabs'} handleClose={handleClose} open={open}>
        <AddProductsButton isSmallScreen={true} />
        <Box sx={{ py: 1 }}>
          <GeneralTermsSelect requisition={requisition} isSmallScreen={true} />
        </Box>
        {requisition?.products?.map((reqProduct, index) => {
          return (
            <ProductTab
              key={reqProduct.tabId}
              index={index}
              product={reqProduct.mainProduct}
              selectedProductIndex={selectedProductIndex}
              onClickCard={index => {
                setSelectedProductIndex(index);
                handleClose();
              }}
              removeProduct={removeProduct}
              requisition={requisition}
              tabId={reqProduct.tabId}
              isPopupProducts={true}
            />
          );
        })}
      </PopupWithTitle>
    </>
  );
};
