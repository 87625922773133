import { apiRoutes } from '@/config';
import { request } from '@/helpers';
import { Filter, FilterItem, Filters, Filters_Res, FiltrationPurpose } from '@/models';
import { useSubFilters } from '@/zustand';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { NotifyError } from '../Notify-toast';

export const toggleSelectionOfFilterItem = (
  filterItem: FilterItem,
  filterParent: string,
  filtersData: Filters_Res[],
  isChecked: boolean,
) => {
  let updatedFilters = filtersData.map((filterElement: Filter) => {
    if (filterElement.type == filterParent) {
      filterElement.filter_items = filterElement.filter_items.map((filterItemElement: FilterItem) => {
        if (filterItemElement.id == filterItem.id)
          return {
            ...filterItemElement,
            is_selected: isChecked,
          };
        return filterItemElement;
      }
      );
      return filterElement;
    }
    return filterElement;
  })

  return updatedFilters;
};

export const useLoadFilters = async (typeId: number, isAddingToCatalog: boolean, filtration_purpose: FiltrationPurpose | null, sellerId?: number, quoteId?: number) => {
  let data = { type_id: typeId, is_adding_to_catalog: isAddingToCatalog ? 1 : 0, filtration_purpose: filtration_purpose }
  if (sellerId) data = { ...data, seller_id: sellerId }
  if (quoteId) data = { ...data, quote_id: quoteId }
  return request({
    url: apiRoutes.productsFilterURL,
    method: "GET",
    params: data
  })
}

export const buildFilterRequestFormDataData = (typeId: number, filters: Filters_Res[], pageNumber: number, isAddingToCatalog: boolean, filtration_purpose: FiltrationPurpose | null, sortId?: number, sellerId?: number, quoteId?: number) => {
  let formData = new FormData;
  let counter = 0;

  filters.map((el) => {
    let shouldContinue = false;
    el.filter_items.filter((el1) => el1.is_selected).map((el2) => {
      formData.append(`filters[${counter}][ids][]`, `${el2.id}`);
      shouldContinue = true;
    });
    if (shouldContinue) {
      formData.append(`filters[${counter}][type]`, el.type);
      counter++;
    }
  });

  formData.append('type_id', `${typeId}`);
  formData.append('page', `${pageNumber}`);
  formData.append('is_adding_to_catalog', `${isAddingToCatalog ? 1 : 0}`);
  if (filtration_purpose) formData.append('filtration_purpose', `${filtration_purpose}`);
  if (sellerId) formData.append('seller_id', `${sellerId}`);
  if (quoteId) formData.append('quote_id', `${quoteId}`);
  if (sortId) formData.append('sort_id', `${sortId}`);

  return formData;
}

export const useApplyFilters = (formData: FormData) => {
  return request({
    url: apiRoutes.productsApplyFiltersURL,
    method: "POST",
    data: formData,
  })
}

export const getFilters = async (data, setWaitFilters) => {
  setWaitFilters('loading');
  return request({
    url: apiRoutes.productsFilterURL,
    method: "GET",
    params: data
  })
}
export const useGetFilters = (params: {}) => {
  const setWaitFilters = useSubFilters(state => state.setWaitFilters);
  const setFilters = useSubFilters(state => state.setFilters);

  const { t } = useTranslation();
  const onSuccess = (data: Filters) => {
    setFilters(data)
  }
  const onError = () => { NotifyError(t('notifications.server_error')) }

  return useQuery(['filters'], () => getFilters(params, setWaitFilters), { onSuccess: (data) => onSuccess(data.data), onError: onError })
}

export const getFiltersOnClick = async (data) => {
  return request({
    url: apiRoutes.productsFilterURL,
    method: "GET",
    params: data
  })
}
export const useGetFiltersOnClick = (params: string, sellerId?: string) => {
  let data = { type_id: params }
  // if (sellerId) data = { ...data, filters: [{ type: 'SELLER', ids: [sellerId] }] }
  const setFilters = useSubFilters(state => state.setFilters);
  const { t } = useTranslation();

  const onSuccess = (data: Filters) => {
    setFilters(data)
  }
  const onError = () => { NotifyError(t('notifications.server_error')) }

  return useQuery(['filtersOnClick', false], () => getFiltersOnClick(data),
    { enabled: false, retry: false, onSuccess: (data) => onSuccess(data.data), onError: onError })
}

export const applyFilters = async (data) => {
  return request({
    url: apiRoutes.productsApplyFiltersURL,
    method: "POST",
    data: data,
  })
}

export const useApplyFiltersURL = (params) => {
  const { t } = useTranslation();
  const setProducts = useSubFilters(state => state.setProducts);
  const selectedFiltersItems = useSubFilters(state => state.selectedFiltersItems);
  const sortId = useSubFilters(state => state.sortId);
  const enabled = useSubFilters(state => state.enabled);
  const setEnabled = useSubFilters(state => state.setEnabled);
  const pageNumber = useSubFilters(state => state.pageNumber);

  let formData = new FormData;
  let counter = 0;

  selectedFiltersItems.map((el) => {
    let shouldContinue = false;
    el.filter_items.filter((el1) => el1.is_selected).map((el2) => {
      formData.append(`filters[${counter}][ids][]`, el2.id);
      shouldContinue = true;
    });
    if (shouldContinue) {
      formData.append(`filters[${counter}][type]`, el.type);
      counter++;
    }
  });

  formData.append('type_id', params);
  formData.append('page', pageNumber);
  if (sortId) formData.append('sort_id', sortId);

  const onSuccess = (data: Filters) => {
    setProducts(data.data.content)
    setEnabled(false)
  }
  const onError = () => { NotifyError(t('notifications.server_error')), setEnabled(false) }
  return useQuery(['applyFilters', selectedFiltersItems, sortId], () => applyFilters(formData),
    { enabled: enabled, onSuccess: (data) => onSuccess(data), onError: onError })
}