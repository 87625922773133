import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { RecallIcon } from '@/icons';
import { procurementStatus } from '@/config';
import { useTranslation } from 'next-i18next';

interface CustomPageProps {
    formName: string
    text: string
    status: procurementStatus | undefined
    children?: any
}

export const CustomPage: FC<CustomPageProps> = ({ formName, text, children, status }) => {
    const { t } = useTranslation();

    return (
        <Grid item container md={12} alignItems={'center'} justifyContent={'center'} gap={2} pt={10}>

            <Grid item container md={12} alignItems={'center'} justifyContent={'center'}>
                <RecallIcon sx={{ color: 'info.dark', fontSize: '35px' }} />
            </Grid>

            <Grid item container md={12} alignItems={'center'} justifyContent={'center'}>
                <Chip label={formName + " " + t('chip.' + status)} variant={'filled'} sx={{ minWidth: "144px", color: 'white', bgcolor: 'rgba(255, 77, 77, 1)', border: `1px solid rgba(255, 77, 77, 1)` }} />
            </Grid>

            <Grid item container md={12} alignItems={'center'} justifyContent={'center'} pt={1}>
                <Typography variant="subtitle2" color='text.third' fontWeight={400}>{text}</Typography>
            </Grid>

            {children}

        </Grid>
    )
}