import React from 'react';

const APP_NAME = 'Al Tuhoo';
const APP_DESCRIPTION = 'Al Tuhoo E-Commerce';

export const DocumentMeta: React.FC = () => (
  <>
    {/* link manifest.json */}
    <link rel='manifest' href='/manifest.json' />
    {/* this sets the color of url bar */}
    <meta name='theme-color' content='#d3bd73' />
    {/* this sets logo in Apple smatphones. */}
    <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
    {/* this sets the color of url bar in Apple smatphones */}
    <meta name='apple-mobile-web-app-status-bar' content='#d3bd73' />

    <link rel='shortcut icon' href='/favicon.ico' />

    <meta name='application-name' content={APP_NAME} />
    <meta name='apple-mobile-web-app-capable' content='yes' />
    <meta name='apple-mobile-web-app-status-bar-style' content='default' />
    <meta name='apple-mobile-web-app-title' content={APP_NAME} />
    <meta name='description' content={APP_DESCRIPTION} />
    <meta name='format-detection' content='telephone=no' />
    <meta name='mobile-web-app-capable' content='yes' />
    {/* TIP: set viewport head meta tag in _app.js, otherwise it will show a warning */}
    {/* <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' /> */}
  </>
);
