import { Carousel, DescPopup, StyledCTClickableCard, TextTooltip, TextWithValue } from '@/components';
import { ComparisonTablesProduct, ProductCategoryKind, ProductCategoryKindLabel, SellerDetails } from '@/models';
import { Box, Grid, Typography } from '@mui/material';
import React, { useRef } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';

type CTProductWithSellersCardProps = {
  ctProductDetails: ComparisonTablesProduct;
  selectSellersForCtProduct: (
    ctProduct: ComparisonTablesProduct,
    seller: SellerDetails,
    isProductAlternative: boolean
  ) => void;
  isProductAlternative: boolean;
  withDetailsPopup?: boolean;
};

const sliceDescription = (description: string) => {
  if (!description) return '';
  const MAX_LENGTH = 100;
  let sliced = description?.slice(0, MAX_LENGTH);
  if (description.length > MAX_LENGTH) {
    return `${sliced}...`;
  }
  return sliced;
};

export const CTProductWithSellersCard: React.VFC<CTProductWithSellersCardProps> = ({
  ctProductDetails,
  isProductAlternative,
  selectSellersForCtProduct,
  withDetailsPopup = false,
}) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const detailsPopup = usePopup();
  const description = ctProductDetails?.product?.description;

  if (!ctProductDetails?.product) {
    return null;
  }
  return (
    <>
      {ctProductDetails && (
        <>
          {withDetailsPopup && (
            <DescPopup
              big
              open={detailsPopup.isOpen}
              handleClose={detailsPopup.handleClose}
              paperSx={{ borderRadius: 0 }}
            >
              <ProductQuickView product={ctProductDetails.product} />
            </DescPopup>
          )}
          <Grid
            container
            style={{
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: 'white',
              borderRadius: '8px',
              minHeight: '85px',
              borderLeft: isProductAlternative ? '8px solid #FB7F0D' : '8px solid #13628C',
            }}
          >
            <Grid
              item
              md={1.15}
              style={{
                padding: '.25rem .5rem',
                marginBlock: 'auto',
              }}
            >
              <Typography
                sx={{
                  backgroundColor: '#fff',
                  color: 'text.disabled',
                  borderRadius: '16px',
                  border: '0.5px solid #3A3E50',
                  position: 'absolute',
                  top: '.5rem',
                  left: '.5rem',
                  zIndex: 1,
                  padding: '4px 12px',
                }}
                fontSize={11}
                fontWeight={500}
              >
                {ProductCategoryKindLabel[ctProductDetails?.product?.category_kind]}
              </Typography>
              {/* <span style={{ fontSize: '.85em', lineHeight: 1.5 }}>
              <img
                style={{
                  objectFit: 'contain',
                  height: '15px',
                  width: '40px',
                }}
                src={ctProductDetails?.product?.brand?.image_url}
                // alt={ctProductDetails?.product?.brand?.name}
                alt=''
              />
            </span>
            <br /> */}
              <img
                style={{
                  objectFit: 'contain',
                  height: '40px',
                  width: '80px',
                  transform: 'translateY(18px)',
                }}
                src={ctProductDetails?.product?.image_url}
                // alt={ctProductDetails?.product?.name}
                alt=''
              />
            </Grid>

            <Grid item container md={3.35} justifyContent='start' pl={1} pr={1} alignItems={'center'}>
              <Grid md={12}>
                <TextTooltip title={ctProductDetails.product?.name} textRef={textRef}>
                  <Typography
                    variant='body1'
                    fontWeight={700}
                    color='text.primary'
                    ref={textRef}
                    sx={{
                      maxWidth: '500px',
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    className={withDetailsPopup ? 'underline-on-hover cursor-pointer' : ''}
                    onClick={() => {
                      if (withDetailsPopup) detailsPopup.handleOpen();
                    }}
                  >
                    {ctProductDetails.product?.name}
                  </Typography>
                </TextTooltip>
                <Grid md={12}>
                  {ctProductDetails.product?.category_kind === ProductCategoryKind.SERVICE ? (
                    <Typography color='#8B8D98' fontWeight={400} fontSize={12}>
                      {description ? sliceDescription(description) : 'Description: N/A'}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: '1rem',
                        gridTemplateColumns: '1fr 1fr',
                      }}
                    >
                      {ctProductDetails.product.productAttributes.slice(0, 3).map(product => (
                        <TextWithValue key={product.name} title={product.name} value={product.value} />
                      ))}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              width='100%'
              item
              container
              md={7.5}
              onClick={event => event.stopPropagation()}
              alignItems='center'
              py={1}
            >
              <Carousel>
                {ctProductDetails.sellers?.map((sellerData, index) => {
                  const hasNext = index + 1 < ctProductDetails.sellers?.length!;

                  return (
                    <React.Fragment key={sellerData.id}>
                      <Box minWidth={130}>
                        <StyledCTClickableCard
                          handleClickCard={() =>
                            selectSellersForCtProduct(ctProductDetails, sellerData, isProductAlternative)
                          }
                          textProperties={sellerData}
                        />
                      </Box>
                      {hasNext && (
                        <Box
                          mx={1}
                          // my={2}
                          maxHeight={'50%'}
                          key={sellerData.id}
                          sx={{ borderInlineEnd: '1px solid #E8E8EA' }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Carousel>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
