import { SvgIconProps, SvgIcon } from '@mui/material';

export const DownloadIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21 16C21.5128 16 21.9355 16.386 21.9933 16.8834L22 17V19C22 20.5977 20.7511 21.9037 19.1763 21.9949L19 22H5C3.40232 22 2.09634 20.7511 2.00509 19.1763L2 19V17C2 16.4477 2.44772 16 3 16C3.51284 16 3.93551 16.386 3.99327 16.8834L4 17V19C4 19.5128 4.38604 19.9355 4.88338 19.9933L5 20H19C19.5128 20 19.9355 19.614 19.9933 19.1166L20 19V17C20 16.4477 20.4477 16 21 16ZM12 2C12.5523 2 13 2.44772 13 3V12.585L14.2929 11.2929C14.6534 10.9324 15.2206 10.9047 15.6129 11.2097L15.7071 11.2929C16.0676 11.6534 16.0953 12.2206 15.7903 12.6129L15.7071 12.7071L12.7071 15.7071L12.6631 15.7485L12.5953 15.8037L12.4841 15.8753L12.3713 15.9288L12.266 15.9642L12.1175 15.9932L12 16L11.9248 15.9972L11.7993 15.9798L11.6879 15.9503L11.5768 15.9063L11.4793 15.854L11.3833 15.7872C11.3515 15.7623 11.3214 15.7356 11.2929 15.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.65338 10.9324 9.22061 10.9047 9.6129 11.2097L9.70711 11.2929L11 12.585V3C11 2.44772 11.4477 2 12 2Z'
      fill='#13628C'
    />
  </SvgIcon>
);
