import React from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ProductCategoryKindLabel, ProductCategoryKindType } from '@/models';

import styles from './RequisitionAlternativesTable.module.css';
import { Typography } from '@mui/material';

export const DescriptionIndicator = ({
  description,
  category_kind,
}: {
  description: string;
  category_kind: ProductCategoryKindType;
}) => {
  return (
    <div className={styles.description_container}>
      <ListAltIcon sx={{ fontSize: 18, color: '#13628C', transform: 'translateY(3px)' }} />
      <div className={styles.description_popover}>
        <Typography color='#3A3E50' fontWeight={700} fontSize={17}>
          <ListAltIcon sx={{ fontSize: 18, color: '#3A3E50', transform: 'translateY(3px)' }} />{' '}
          {ProductCategoryKindLabel[category_kind]} Details
        </Typography>
        <Typography color='#3A3E50' fontWeight={400} fontSize={13}>
          {description || 'N/A'}
        </Typography>
      </div>
    </div>
  );
};
