import create from 'zustand';

export const useProfileEmailFlow = create((set, get) => ({
  changeEmailPopupVisible: false,
  changeEmailByEmailPopupSteps: 0,
  changeEmailByPhonePopupSteps: 0,
  changeEmailViaEmailToken: null,
  temporaryEmail: null,
  timer: 0,
  isTimer: false,

  setChangeEmailByEmailSteps: (step: number) => {
    set({ changeEmailByEmailPopupSteps: step });
  },
  toggleChangeEmailPopup: (open: boolean) => {
    set({ changeEmailPopupVisible: open });
  }, 
  setChangeEmailByPhoneSteps: (step: number) => {
    set({ changeEmailByPhonePopupSteps: step });
  }, 
  setChangeEmailViaEmailToken: (token: string) => {
    set({ changeEmailViaEmailToken: token });
  },
  setTemporaryEmail: (email: string) => {
    set({ temporaryEmail: email });
  },
  setIsTimer: (num: boolean) => {
    set({ isTimer: num });
  },
  setTimer: (num: number) => {
    set({ timer: num });
  },

}));
