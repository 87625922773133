import { SvgIconProps, SvgIcon } from '@mui/material';

export const FilterIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.99978 9.52739L1.43734 2.35784C1.15491 1.91402 1.47372 1.33325 1.99978 1.33325H13.9998C14.5258 1.33325 14.8446 1.91402 14.5622 2.35784L9.99978 9.52739V12.6666C9.99978 12.9191 9.85711 13.1499 9.63126 13.2629L6.96459 14.5962C6.52132 14.8178 5.99978 14.4955 5.99978 13.9999V9.52739ZM3.21428 2.66659L7.22894 8.97534C7.29701 9.08231 7.33316 9.20647 7.33316 9.33326V12.9212L8.6665 12.2546V9.33326C8.6665 9.20647 8.70265 9.08231 8.77072 8.97534L12.7854 2.66659H3.21428Z'
      fill='white'
    />
  </SvgIcon>
);
