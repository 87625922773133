import { getInputsTranslationKey } from '@/hooks';
import { GeneralObject } from '@/models';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';


interface StyledSelectProps {
    placeholder: string
    value: string
    handleChange: (value: string) => void
    items: GeneralObject[]
    disabled?: boolean
}

const selectTextInputStyle = {
    color: '#B2B2B2',
}
const inputStyle = {
    width: '100%',
};
const textInputStyle = {
    // bgColor: 'white',
    color: '#3A3E50',
    width: '100%',
};

export const StyledSelect: FC<StyledSelectProps> = (props: StyledSelectProps) => {
    const { t } = useTranslation()
    const handleChange = (event: SelectChangeEvent) => {
        props.handleChange(event.target.value as string);
    };
    return (
        <Select
            displayEmpty
            renderValue={(value) => {
                if (!value) {
                    return props.placeholder ? t(getInputsTranslationKey('placeholders.' + props.placeholder)) : ''
                }
                return props.items.find(op => op.id == value)?.name;
            }}
            disabled={props.disabled}
            // inputProps={{ sx: (props.value == '0' ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle) }}
            onChange={handleChange}
            value={props.value}
            defaultValue={'0'}
            sx={{
                height: 30,
                '.MuiSelect-select': (!props.value ? { ...textInputStyle, ...selectTextInputStyle, backgroundColor: props.bgColor ?? "#fff" } : { ...textInputStyle, backgroundColor: props.bgColor ?? "#fff" }),
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        maxHeight: '200px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                            height: 10,
                            width: 7,
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: `white`,
                            margin: 2,
                            marginRight: -2
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'primary.main',
                            outline: `1px solid transparent`,
                            borderRadius: 8,
                        },
                    }
                }
            }}
        >
            {/* <MenuItem key={'0'} value={'0'} disabled style={{ display: "none" }}>
                {props.placeholder}
            </MenuItem> */}
            {props.items.map(function (object, i) {
                return <MenuItem value={object.id} key={object.id} sx={{ mx: 1 }}>{object.name}</MenuItem>;
            })}
        </Select>
    );
};
