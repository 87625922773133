import { Product, ProductCategoryKind } from '@/models';
import React from 'react';

const useProductsAndServicesCount = (products: Product[]) => {
  return React.useMemo(() => {
    let numberOfProducts = 0;
    let numberOfServices = 0;
    products.forEach(product => {
      if (product.category_kind === ProductCategoryKind.PRODUCT) numberOfProducts++;
      if (product.category_kind === ProductCategoryKind.SERVICE) numberOfServices++;
    });

    return {
      numberOfProducts,
      numberOfServices,
    };
  }, [products]);
};

export default useProductsAndServicesCount;
