import { CircularProgress, Box } from '@mui/material';
import React from 'react';

interface LoadingProps {
  fullHeight: boolean
}

export const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: props.fullHeight ? 500 : '100%',
        display: 'grid',
        placeContent: 'center',
        placeItems: 'center',
      }}
    >
      <CircularProgress size='2rem' />
    </Box>
  );
};
