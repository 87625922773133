import * as React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

interface SendVerificationProps {
    paragraphTranslate: string,
    isDisabledEmailButton: boolean,
    isDisabledPhoneButton: boolean,
    handleClickEmail: () => void,
    handleClickPhone: () => void,
    timerButton?: boolean,
    timerValue?: number
}

export const SendVerification : FC<SendVerificationProps> = (props: SendVerificationProps) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                 {props.paragraphTranslate} 
            </Typography>
            <LoadingButton
                loading={props.timerButton}
                loadingIndicator={`Waiting…  ${props.timerValue}`}
                disabled={props.isDisabledEmailButton}
                variant='contained'
                color='secondary'
                fullWidth
                sx={{ mt: 3 }}
                onClick={props.handleClickEmail}
            >
                {t('buttons.send_verification_to_email')}
            </LoadingButton>
            
            <LoadingButton
                loading={props.timerButton}
                loadingIndicator={`Waiting…  ${props.timerValue}`}
                disabled={props.isDisabledPhoneButton}
                variant='contained'
                color='secondary'
                fullWidth
                sx={{ mt: 3 }}
                onClick={props.handleClickPhone}
            >
                {t('buttons.send_verification_to_phone')}
            </LoadingButton>

        </>
    )
}