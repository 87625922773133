import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const NewSearchIcon: React.FC<SvgIconProps> = ({ fill, ...props }) => (
  <SvgIcon
    viewBox='0 0 15 14' 
    {...props}
  >
    <path
      fill={fill} 
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1821 10.7458C9.16576 11.5361 7.88866 12.0067 6.50167 12.0067C3.18704 12.0067 0.5 9.31891 0.5 6.00335C0.5 2.68779 3.18704 0 6.50167 0C9.81631 0 12.5033 2.68779 12.5033 6.00335C12.5033 7.39059 12.033 8.66794 11.243 9.6845L14.2799 12.7186C14.5731 13.0115 14.5734 13.4867 14.2806 13.7799C13.9878 14.0731 13.5128 14.0734 13.2196 13.7805L10.1821 10.7458ZM11.0029 6.00335C11.0029 8.49002 8.98765 10.5059 6.50167 10.5059C4.0157 10.5059 2.00042 8.49002 2.00042 6.00335C2.00042 3.51668 4.0157 1.50084 6.50167 1.50084C8.98765 1.50084 11.0029 3.51668 11.0029 6.00335Z"
    />
  </SvgIcon>
);
