import { ConvertToRFQButton, FormMoreOptions, RequisitionPDFDocument, StyledBreadcrumbs } from "@/components";
import { AuthenticateStatus, ProcurementForms, RequisitionStatus } from "@/config";
import { useProcurementStore, useUserData } from "@/zustand";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useRouter } from "next/router";
import usePopup from "src/logic/hooks/use-popup";
import { sm } from "src/site-map";
// import LoginPopup from "../../../login/login-popup";
import useProductsAndServicesCount from "src/logic/hooks/smart/products-and-services/use-products-and-services-count";
import SignupRequiredPopup from "src/ui/components/dumb/shared/signup-required-popup";

export const RequisitionMainDetails = ({ currentRequisition, breadCrumbs, children }) => {

    // const showDeletionSuccessPopup = useProcurementStore(state => state.requisitionDetailsSliceState.showDeletionSuccessPopup)
    // const canEditAndRegenerate = useProcurementStore(state => state.canEditAndRegenerate)
    // const closeDeletionSuccessPopup = useProcurementStore(state => state.requisitionsDetailsSliceActions.closeDeletionSuccessPopup)
    // const handleRegenerateRFQ = (handleCloseEditAndRegeneratePopup: () => void) => editAndRegenerateRFQFromRequisition(() => handleRedirectToRequisition(handleCloseEditAndRegeneratePopup))
    // const handleViewRFQ = () => router.push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(currentRequisition?.bundle_rfq_id).url)
    // const editAndRegenerateRFQFromRequisition = useProcurementStore(state => state.requisitionsDetailsSliceActions.editAndRegenerateRFQFromRequisition)
    // const handleRedirectToRequisition = (handleCloseEditAndRegeneratePopup: () => void) => {
    //     router.push(sm.portals.buyer.procurement.requisitions.requisition(currentRequisition.id).url)
    //     handleCloseEditAndRegeneratePopup()
    // }
    const router = useRouter()
    const { canCreateRFQ } = useUserData()
    const userInfo = useUserData().userInfo

    const isOwner = userInfo?.id == currentRequisition?.owner_id

    const deleteRequisition = useProcurementStore(state => state.requisitionsDetailsSliceActions.deleteRequisition)
    const duplicateRequisition = useProcurementStore(state => state.requisitionsDetailsSliceActions.duplicateRequisition)
    const { numberOfProducts, numberOfServices } = useProductsAndServicesCount(currentRequisition.products.map(product => ({
        category_kind: product.main_product.category_kind,
      })));

    const numberOfCurrentMainProducts = useProcurementStore(state => state.numberOfCurrentMainProducts)
    const handleDeleteRequisition = () => deleteRequisition(currentRequisition?.id, () => router.push(sm.portals.buyer.procurement.requisitions.index.url))


    const isGuest = useUserData().authenticationStatus !== AuthenticateStatus.AUTHENTICATED;
    const loginPopup = usePopup();
    const convertToRFQHandler = () => {
        if (isGuest) {
            loginPopup.handleOpen();
            return;
        }
        router.query.sellers = 'select'
        router.push(router)
    }

    const handleDuplicateRequisition = (project) => {
        duplicateRequisition(currentRequisition?.id, project, (id: any) => router.push(sm.portals.buyer.procurement.requisitions.requisition(id).url))
    }

    const canConvertToRfq = (currentRequisition.status != RequisitionStatus.CONVERTED_TO_RFQ && canCreateRFQ && isOwner && numberOfCurrentMainProducts > 0)
    return (
        <>
            {/* <LoginPopup loginPopup={loginPopup} onLogin={() => router.push(sm.home.url)} /> */}
            <SignupRequiredPopup open={loginPopup.isOpen} handleClose={loginPopup.handleClose} signupUrl={sm.register.asBuyer.index.url} />

            <Grid item md={12} my={1}><StyledBreadcrumbs alias={breadCrumbs} dynammicAlias={[currentRequisition?.name]} showBackButton /></Grid>

            {/* <PageDetailsHeader> */}

            <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px' }}>
                <Grid container md={12} px={2}>
                    <Grid item container md={5} alignItems="center" gap={1} >
                        {/* <Typography variant="h6" fontWeight={400} ml={2}>{t('texts.requisition')}</Typography> */}
                        <Typography variant="h5" fontWeight={600}>{currentRequisition?.name}</Typography>
                        {/* <Chip label={t('requisition_status.' + RequisitionStatus[currentRequisition.status])} variant="outlined" sx={{ minWidth: "144px", height: '28px', ml: 3 }} /> */}
                    </Grid>


                    <Grid item container gap={1} md={7} alignItems="center" justifyContent={'end'} px={2} py={1}>
                        <Grid container md={12} alignItems="center" justifyContent={'end'}>
                            {/* 
                            <Button sx={{ mr: 1 }} variant='contained' onClick={redirectToProductPage}>{t('buttons.add_products')}</Button>
                         */}

                            {canConvertToRfq && <ConvertToRFQButton redirectHandler={convertToRFQHandler} primary />}
                            {isGuest && numberOfCurrentMainProducts !== 0 && <Button type='button' variant='contained' onClick={convertToRFQHandler}>Convert To RFQ</Button>}

                            {/* <Divider variant='middle' orientation='vertical' sx={{ width: 2, height: 30, opacity: 1, mx: 1 }} /> */}
                            <FormMoreOptions
                                shareURL={currentRequisition.share_url}
                                pdfDocument={<RequisitionPDFDocument requisition={currentRequisition} number_of_Products={numberOfProducts} number_of_Services={numberOfServices}/>}
                                actionsDisabled={!numberOfCurrentMainProducts}
                                handleDeleteAction={handleDeleteRequisition}
                                formType={ProcurementForms.REQUISITION}
                                formName={currentRequisition?.name}
                                deleteAction={useUserData.getState().userInfo?.id === currentRequisition.owner_id && currentRequisition?.deletable}
                                duplicateAction={!isGuest}
                                disableDownload={!currentRequisition?.products?.length}
                                disableDuplicate={!currentRequisition?.products?.length}
                                handleDuplicateAction={(data) => handleDuplicateRequisition(data)}
                                currentProject={currentRequisition?.project?.id}
                            />
                            {/* <ExitForm exitFormHandler={() => router.push(sm.portals.buyer.procurement.requisitions.index.url)} /> */}
                        </Grid>
                    </Grid>

                    {children}
                </Grid>
            </Box>
            {/* </PageDetailsHeader> */}
        </>
    )
}