import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, Theme } from '@mui/material';
import { sm } from 'src/site-map';
import { Link } from 'src/ui/components/shared';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

interface CatalogueHoverPopperProps {
  sectionName: string;
  sectionContent: any;
}

const CatalogueHoverPopper: React.FC<CatalogueHoverPopperProps> = ({ sectionName, sectionContent }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton aria-label={sectionName} onClick={handlePopoverToggle}>
        <InfoOutlinedIcon
          sx={{ color: '#89B1C6', fontSize: 'medium', transform: 'translateY(-3px)', cursor: 'pointer' }}
        />
      </IconButton>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        sx={{
          '& .MuiPaper-root': {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            minWidth: '200px',
            padding: 2,
          },
        }}
      >
        <div>
          <Typography variant='body2' fontWeight={600} color='text.disabled'>
            {sectionName}
          </Typography>
          <Divider sx={{ my: 1, borderColor: '#D0E0E8' }} />
          <div style={{ maxHeight: '350px', overflow: 'auto', padding: 3 }} className='styled-scrollbar'>
            {sectionContent?.map((item, index) => (
              <React.Fragment key={index}>
                {sectionName === 'Sellers' && (
                  <Link href={sm.catalogue.sellers.sellerId(`${item.seller.id}`).url} underline='hover'>
                    <Typography variant='subtitle2' fontWeight={400} color='text.disabled' my={1}>
                      {item.seller.name}
                    </Typography>
                  </Link>
                )}
                {sectionName === 'Brands' && (
                  <Link href={sm.catalogue.brands.brandId(`${item.id}`).url} underline='hover'>
                    <Typography variant='subtitle2' fontWeight={400} color='text.disabled' my={1}>
                      {item.name}
                    </Typography>
                  </Link>
                )}
                {sectionName === 'Groups' && (
                  <Link href={sm.catalogue.products.groupId(`${item.id}`).url} underline='hover'>
                    <Typography variant='subtitle2' fontWeight={400} color='text.disabled' my={1}>
                      {item.name}
                    </Typography>
                  </Link>
                )}
                {sectionName === 'Users' && (
                  <Typography variant='subtitle2' fontWeight={400} color='text.disabled' my={1}>
                    {item.full_name}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CatalogueHoverPopper;
