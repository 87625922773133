export enum RequisitionPopupStatus {
    selectRequisition = "SELECT_REQUISITION",
    createRequisitionWithSelect = "CREATE_WITH_SELECT",
    createRequisition = "CREATE",
    loading = "LOADING",
};

export enum RequisitionProductType {
    main_product = "MAIN_PRODUCT",
    alternative_products = "ALTERNATIVE_PRODUCT"
}

export enum RequisitionStatus {
    DRAFT = "DRAFT",
    CONVERTED_TO_RFQ = "CONVERTED_TO_RFQ"
}
