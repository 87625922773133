import { phoneRegExp } from 'src/logic/helpers/regex';
import * as Yup from 'yup';

const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const initialValuesForLinkOrCreateSeller = {
  new_sellers: [],
};

export const validationSchemaForLinkOrCreateSeller = Yup.object().shape({
  new_sellers: Yup.array().of(
    Yup.object().shape({
      seller_id: Yup.string(),
      company_name: Yup.string().when('seller_id', {
        is: val => !val,
        then: Yup.string().required('Company Name is required'),
        otherwise: Yup.string(),
      }),
      country_id: Yup.number().when('seller_id', {
        is: val => !val,
        then: Yup.number().required('Country is required'),
        otherwise: Yup.number(),
      }),
      city_id: Yup.number().when('seller_id', {
        is: val => !val,
        then: Yup.number().required('City is required'),
        otherwise: Yup.number(),
      }),
      full_name: Yup.string().when('seller_id', {
        is: val => !val,
        then: Yup.string().required('Full Name is required'),
        otherwise: Yup.string(),
      }),
      email: Yup.string()
        .email('Invalid email')
        .matches(emailRegExp, 'Invalid email format')
        .when('seller_id', {
          is: val => !val,
          then: Yup.string().required('Email is required'),
          otherwise: Yup.string(),
        }),
      phone_number: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number')
        .when('seller_id', {
          is: val => !val,
          then: Yup.string().required('Phone number is required'),
          otherwise: Yup.string(),
        }),
    })
  ),
});
