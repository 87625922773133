import create from 'zustand';
import { Filters, Product, FilterItem, sortType, Filters_Res, Filter, SelectedFilters } from '@/models';

export const useSubFilters = create((set, get) => ({
  products: [] as Product[],
  filterData: [] as Filters_Res,
  sortData: [] as sortType,
  sortId: null as string,
  waitProducts: 'loading' as string,
  filtersNumber: 0 as number,
  waitFilters: 'loading' as string,
  searchText: '' as string,
  selectedFiltersItems: [] as SelectedFilters[],
  enabled:false,
  pageNumber: "1" as string,

  setEnabled: (data: boolean) => {
    set({ enabled: data });
  },
  setProducts: (data) => {
    set({ products: data, waitProducts: 'success', pageNumber:'1'  });
  },
  setFiltersNumber: (num: Function) => {
    set({ filtersNumber: num });
  },
  setWaitFilters: (status: string) => {
    set({ waitFilters: status });
  },
  setWaitProducts: (status: string) => {
    set({ waitProducts: status });
  },
  setFilters: (data: Filters) => {
    set({
      products: data.data,
      filterData: data.filter_data,
      sortData: data.sort_data,
      waitFilters: 'success',
      waitProducts: 'success'
    });
  },

  onItemChecked: async (filterItem: FilterItem, filterParent: string) => {
    set({ waitProducts: 'loading' });
    let tree = [];
    tree = onFilterItemChecked(filterItem, filterParent, get().filterData);
    set({ filterData: tree });
    let newFilters = onFilterItemChange(tree);
    set({ selectedFiltersItems: newFilters , enabled:true })
  },

  onItemUnchecked: async (filterItem: FilterItem, filterParent: string) => {
    set({ waitProducts: 'loading' });
    let tree = [];
    tree = onFilterItemUnchecked(filterItem, filterParent, get().filterData);
    set({ filterData: tree });
    let newFilters = onFilterItemChange(tree);
    set({ selectedFiltersItems: newFilters , enabled:true })
  },

  onSortTypeChange: (sortItemId: number) => {
    set({ waitProducts: 'loading', sortId: sortItemId, enabled: true })
  },

  onPageNumberChange: (pageNumber: number) => {
    set({ waitProducts: 'loading', pageNumber: pageNumber, enabled: true })
  },

  onResetFilters: async (getFiltersOnClick: any) => {
    set({ waitProducts: 'loading' })
    let tree = onFiltersReset(get().filterData);
    set({ filterData: tree });
    getFiltersOnClick.refetch()
  },
}));

export const onFilterItemChecked = (filterItem: FilterItem, filterParent: string, filtersData: Filters_Res) => {
  let newTree = toggleSelectionOfFilterItem(true, filterItem, filterParent, filtersData);
  return newTree;
};

export const onFilterItemUnchecked = (filterItem: FilterItem, filterParent: string, filtersData: Filters_Res) => {
  let newTree = toggleSelectionOfFilterItem(false, filterItem, filterParent, filtersData);
  return newTree;
};

export const toggleSelectionOfFilterItem = (
  isChecked: boolean,
  filterItem: FilterItem,
  filterParent: string,
  filtersData: Filters_Res
) => {
  let filterTree = filtersData.map((filterElement: Filter) => {
    if (filterElement.type == filterParent) {
      filterElement.filter_items = filterElement.filter_items.map((filterItemElement: FilterItem) => {
        if (filterItemElement.id == filterItem.id)
          return {
            ...filterItemElement,
            is_selected: isChecked,
          };
        return filterItemElement;
      }
      );
      return filterElement;
    }
    return filterElement;
  })
  // filterTree.map((el: Filter) => {
  //   el.filter_items.sort((currentItem: FilterItem, nextItem: FilterItem) => currentItem.name.localeCompare(nextItem.name))
  // })
  // filterTree.map((el: Filter) => {
  //   el.filter_items.sort((currentItem: FilterItem, nextItem: FilterItem) => Number(nextItem.is_selected) - Number(currentItem.is_selected));
  // })
  return filterTree;
};

export const onFiltersReset = (filtersData: Filters_Res) => {
  let newTree = toggleResetOfFilters(filtersData);
  return newTree;
};
export const toggleResetOfFilters = (filtersData: Filters_Res) => {
  let filterTree = filtersData.map((filterElement: Filter) => {
    filterElement.filter_items = filterElement.filter_items.map(
      (filterItemElement: FilterItem) => {
        return {
          ...filterItemElement,
          is_selected: false,
        };
      }
    );
    return filterElement;
  });
  return filterTree;
};

export const onFilterItemChange = (filtersData: Filters_Res) => {
  return filtersData;
};
