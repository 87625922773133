import { SvgIconProps, SvgIcon } from '@mui/material';

export const PdfIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.85 3.44L8.57 0.14L8.22 0H0.5L0 0.5V6H1V1H7V4.5L7.5 5H11V6H12V3.8L11.85 3.44ZM8 4V1L11 4H8ZM0.5 7L0 7.5V13.5L0.5 14H11.5L12 13.5V7.5L11.5 7H0.5ZM11 12V13H1V8H11V12ZM3 11H2.68V12H2V9H3.06C3.81 9 4.19 9.36 4.19 10C4.19194 10.136 4.16433 10.2708 4.10908 10.3951C4.05383 10.5195 3.97226 10.6303 3.87 10.72C3.62218 10.9132 3.31397 11.0124 3 11ZM2.94 9.55H2.68V10.48H2.94C3.3 10.48 3.48 10.32 3.48 10.01C3.48 9.7 3.3 9.55 2.94 9.55ZM7 11.58C7.14769 11.4345 7.26317 11.2597 7.33897 11.0667C7.41476 10.8738 7.44918 10.6671 7.44 10.46C7.44 9.46 6.91 9 5.84 9H4.78V12H5.84C6.05153 12.0102 6.26298 11.9784 6.46211 11.9063C6.66123 11.8342 6.84406 11.7233 7 11.58ZM5.45 11.45V9.55H5.78C5.90755 9.5419 6.0354 9.55989 6.15576 9.60287C6.27612 9.64586 6.38644 9.71293 6.48 9.8C6.56476 9.88901 6.63061 9.99426 6.67357 10.1094C6.71654 10.2246 6.73573 10.3472 6.73 10.47C6.74498 10.7336 6.65511 10.9924 6.48 11.19C6.38845 11.2776 6.28013 11.3458 6.16156 11.3905C6.04298 11.4352 5.9166 11.4554 5.79 11.45H5.45ZM9.9 10.84H8.93V12H8.25V9H9.99V9.55H8.93V10.29H9.9V10.84Z" fill="#13628C"/>
  </SvgIcon>
);
