import { ProductManagerPopupStatus } from '@/config';
import { getAddProductToQuoteData } from '@/services';
import { useProcurementStore } from '@/zustand';
import { Box, Typography } from '@mui/material';
import React from 'react';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import { DescPopup, Loading } from 'src/ui/components/shared';
import AddToExistingQuote, { QuoteOptionData } from './add-to-existing-quote';
import CreateQuoteWithProduct, { BuyerCompany } from './create-quote-with-product';

type PopupContentType = 'CREATE_NEW' | 'ADD_TO_EXISTING';

export const QuoteEnvironmentPopupManager = () => {
  const [popupContent, setPopupContent] = React.useState<PopupContentType>('CREATE_NEW');
  const handleTogglePopupContent = React.useCallback(() => {
    setPopupContent(prev => (prev === 'CREATE_NEW' ? 'ADD_TO_EXISTING' : 'CREATE_NEW'));
  }, []);

  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const isOpen = data.productManagerPopupStatus === ProductManagerPopupStatus.CREATE_QUOTE;

  const [fetchQueryStatus, setFetchQueryStatus] = React.useState<QueryHandlerModel>(QueryHandler.getStartStatus());
  const [buyersCompanies, setBuyersCompanies] = React.useState<BuyerCompany[]>([]);
  const [quotesOptions, setQuotesOptions] = React.useState<QuoteOptionData[]>([]);

  const fetchDetails = React.useCallback(product_id => {
    setFetchQueryStatus(QueryHandler.getStartStatus());
    getAddProductToQuoteData(product_id)
      .then(data => {
        setBuyersCompanies(data.data.companies);
        setQuotesOptions(data.data.quotes.map((item: any) => ({ ...item, ...item.quote })));
        setFetchQueryStatus(QueryHandler.getSuccessStatus());
      })
      .catch(err => {
        setFetchQueryStatus(QueryHandler.getErrorStatus(err));
      });
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      setPopupContent('CREATE_NEW');
    }
  }, [isOpen]);

  const closePopup = () => {
    actions.setProductManagerPopupStatus(null);
    actions.setCandidateSellerRelatedToForm(null);
    actions.setCandidateProductToAdd(null);
  };

  const product_id = data.candidateProductToAdd?.id;
  React.useEffect(() => {
    if (product_id) {
      fetchDetails(product_id);
    }
  }, [product_id]);

  return (
    <DescPopup open={isOpen} handleClose={closePopup}>
      <Box width={'100%'} px={1}>
        {fetchQueryStatus.isLoading && <Loading fullHeight />}
        {fetchQueryStatus.isError && (
          <Box minHeight={500} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography fontSize={18} color='red' fontWeight={700}>
              An error occured, please try again
            </Typography>
          </Box>
        )}
        {fetchQueryStatus.isSuccess && (
          <>
            {popupContent === 'CREATE_NEW' && (
              <CreateQuoteWithProduct
                onPopupSwitch={handleTogglePopupContent}
                onPopupClose={closePopup}
                buyersCompanies={buyersCompanies}
              />
            )}
            {popupContent === 'ADD_TO_EXISTING' && (
              <AddToExistingQuote
                onPopupSwitch={handleTogglePopupContent}
                onPopupClose={closePopup}
                quotesOptions={quotesOptions}
              />
            )}
          </>
        )}
      </Box>
    </DescPopup>
  );
};
export default QuoteEnvironmentPopupManager;
