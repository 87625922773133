import { ProcurementForms, ProcurementPopupStatus, procurementStatus } from "@/config";
import { BundleProcurementDetails } from "@/models";
import { ProcurementFormPermission } from "@/services";
import { useProcurementStore, useUserData } from "@/zustand";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useState } from "react";
import { sm } from "src/site-map";
import { BundlePoPDFDocument, EditAndRegenerateButton, EditResponseButton, FormMoreOptions } from "src/ui/components/dumb";

interface BundlePurchaseOrderHeaderActionsProps {
    bundlePO: BundleProcurementDetails | null
}
export const BundlePurchaseOrderHeaderActions: FC<BundlePurchaseOrderHeaderActionsProps> = ({ bundlePO }) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const { setPopupStatus, deleteBundlePO } = useProcurementStore(state => state.bundlePurchaseOrderBuyerDetailsSliceActions)

    const handleSendForApproval = () => setPopupStatus(ProcurementPopupStatus.SEND_FOR_APPROVAL)
    const handleApprove = () => setPopupStatus(ProcurementPopupStatus.APPROVE_ALL)
    const handleReject = () => setPopupStatus(ProcurementPopupStatus.REJECT_ALL)
    const handleCancel = () => setPopupStatus(ProcurementPopupStatus.CANCEL_APPROVAL)
    let canEditResponse = bundlePO?.actions[ProcurementFormPermission.EDIT_RESPONSE]

    const isOwner = useUserData().userInfo?.id == bundlePO?.model?.owner?.id;
    const canEditBundle = isOwner && (bundlePO?.actions[ProcurementFormPermission.EDIT] || [ProcurementFormPermission.EditAsMainAction]) && bundlePO?.model?.form_action_permissions?.editable && !bundlePO?.model?.form_action_permissions?.updatable_directly;
    const redirectToComparisonTable = () => {
        if (bundlePO?.model?.comparison_table) {
            push(sm.portals.buyer.procurement.purchaseOrders.editBundlePurchaseOrderId(bundlePO.model.id).url)
        } 
        else push({
            pathname: sm.portals.buyer.procurement.quotes.quoteId(bundlePO?.model?.quote?.id).url,
            query: { type: ProcurementForms.BUNDLE_PO, id: bundlePO?.model?.id, name: bundlePO?.model?.name }
        })
    }

    const handleSendBundlePOToSeller = () => setPopupStatus(ProcurementPopupStatus.SENT_TO_SELLERS)
    const handleRecallRequest = () => setPopupStatus(ProcurementPopupStatus.RECALL_Bundle)
    const handleDeleteBundlePO = () => deleteBundlePO(bundlePO?.model?.id, () => push(sm.portals.buyer.procurement.purchaseOrders.index.url))
    const handleCancelBundleAction = () => setPopupStatus(ProcurementPopupStatus.CANCEL_BUNDLE)


    const [hasApprovedPO, setHasApprovedPO] = useState(false);
    const [isAllPOsApproved, setIsAllPOsApproved] = useState(false);

    useEffect(() => {
        if (bundlePO) {
            const allPOs = bundlePO?.model?.purchaseOrders;
            if (allPOs && allPOs.length > 0) {
                const allApproved = allPOs.every(
                    po => po.status === procurementStatus.APPROVED || po.status === procurementStatus.RECALLED
                );
                const someApproved = allPOs.some(
                    po => po.status === procurementStatus.APPROVED
                );
                if (allApproved) {
                    setIsAllPOsApproved(true)
                } else if (!allApproved && someApproved) {
                    setHasApprovedPO(true);
                } else {
                    setHasApprovedPO(false);
                }
            }
        }
    }, [bundlePO]);

    const isAllPOsDraft = useMemo(() => {
        return bundlePO?.model?.purchaseOrders?.reduce((acc, po) => acc && po?.status === procurementStatus.DRAFT, true)
    }, [bundlePO])

    return (
        <>
            {canEditBundle && <EditAndRegenerateButton editAndRegenerateHandler={redirectToComparisonTable} hidePopup />}
            {bundlePO?.actions[ProcurementFormPermission.SEND_TO_SELLERS] && <Button variant="contained" sx={{ px: 4, mr: 1 }} onClick={handleSendBundlePOToSeller}> {hasApprovedPO ? t("buttons.send_approved_pos_to_sellers") : (isAllPOsDraft && bundlePO?.model?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_POs_to_sellers') : isAllPOsApproved ? t("buttons.send_all_pos_to_sellers") : t('buttons.send_POs_to_sellers')} </Button>}
            {bundlePO?.actions[ProcurementFormPermission.SEND_FOR_APPROVAL] && <Button variant='contained' onClick={handleSendForApproval}>
                {t('buttons.send_for_approval')}
            </Button>}
            {bundlePO?.actions[ProcurementFormPermission.CANCEL_APPROVAL] && <Button variant='outlined' color='error' onClick={handleCancel}>
                {t('buttons.cancel_approval_request')}
            </Button>}
            {bundlePO?.actions[ProcurementFormPermission.REJECT] && !canEditResponse && <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={handleReject}> {t("buttons.reject_all")} </Button>}
            {bundlePO?.actions[ProcurementFormPermission.APPROVE] && !canEditResponse && <Button variant="contained" color="success" sx={{ mr: 1 }} onClick={handleApprove}> {t("buttons.approve_all")} </Button>}
            {canEditResponse && <EditResponseButton>
                {bundlePO?.actions[ProcurementFormPermission.APPROVE] && <Button startIcon={<CheckCircleOutline />} sx={{ color: "success.dark" }} onClick={handleApprove}>
                    {t('buttons.approve_all')}
                </Button>}

                {bundlePO?.actions[ProcurementFormPermission.REJECT] && <Button startIcon={<HighlightOff />} color="error" onClick={handleReject}>
                    {t("buttons.reject_all")}
                </Button>}
            </EditResponseButton>}

            <FormMoreOptions
                shareURL={bundlePO?.model?.share_url}
                deleteAction={bundlePO?.actions[ProcurementFormPermission.DELETE]}
                pdfDocument={<BundlePoPDFDocument bundlePO={bundlePO} />}
                disableDownload={!bundlePO?.model?.purchaseOrders?.length}
                handleDeleteAction={handleDeleteBundlePO}
                formType={ProcurementForms.BUNDLE_PO}
                formName={bundlePO?.model?.name}
                duplicateAction={false}
                recallAction={bundlePO?.actions[ProcurementFormPermission.RECALL_Bundle]}
                handleRecallAction={handleRecallRequest}
                cancelAction={bundlePO?.actions[ProcurementFormPermission.CANCEL_FORM]}
                handleCancelAction={handleCancelBundleAction}
            />
        </>
    )
}