import React from 'react';

const useIsOffline = (): boolean => {
  const [isOffline, setIsOffline] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onOnline = () => setIsOffline(false);
    const onOffline = () => setIsOffline(true);

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  return isOffline;
};

export default useIsOffline;
