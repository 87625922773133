import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import ErrorIcon from '@mui/icons-material/Error';
import usePopup from 'src/logic/hooks/use-popup';
import { DescPopup } from '@/components';

type ErrorPageProps = {
  error?: null | AxiosResponse;
  debug?: boolean;
};

const ErrorPage = ({ error, debug = false }: ErrorPageProps) => {
  const debugPopup = usePopup();

  const items = React.useMemo(() => {
    if (error) {
      return [
        { label: 'Status Code', value: error.status },
        { label: 'Status Text', value: error.statusText },
        { label: 'URL', value: error?.config?.url },
        { label: 'Method', value: error?.config?.method },
      ];
    }
    return [];
  }, [error]);

  return (
    <>
      <div
        style={{
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'sans-serif',
        }}
      >
        <ErrorIcon color='error' sx={{ fontSize: 48 }} />
        <Typography
          textAlign={'center'}
          fontSize={18}
          fontWeight={700}
          color='error'
          lineHeight={1.1}
          maxWidth={'35ch'}
          mt={2}
        >
          Something went wrong, please refresh the page & try again
        </Typography>

        {debug && error && (
          <>
            <Button
              color='primary'
              type='button'
              variant='contained'
              sx={{ mt: 4 }}
              onClick={debugPopup.handleOpen}
            >
              Debug
            </Button>
            <DescPopup
              open={debugPopup.isOpen}
              handleClose={debugPopup.handleClose}
              medium
            >
              <div style={{ width: '100%', paddingInline: '.5rem' }}>
                {items.map(item => (
                  <Grid container key={item.label} sx={{ py: 0 }}>
                    <Grid item md={2.5}>
                      <Typography fontSize={12} sx={{ marginTop: '2px' }}>
                        {item.label}:
                      </Typography>
                    </Grid>
                    <Grid item md={9.5}>
                      <Typography>{item.value}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {error?.data && (
                  <div
                    style={{
                      marginTop: '1rem',
                      maxHeight: '12.5rem',
                      overflow: 'auto',
                      padding: '.5rem',
                      backgroundColor: '#EAEEF6',
                    }}
                  >
                    <Typography fontSize={12} maxWidth={'100%'}>
                      {JSON.stringify(error.data, null, 3)}
                    </Typography>
                  </div>
                )}
              </div>
            </DescPopup>
          </>
        )}
      </div>
    </>
  );
};

export default ErrorPage;
