import { SvgIconProps, SvgIcon } from '@mui/material';

export const UsersIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_7961_77895)' fill={'#13628C'}>
      <path
        d='M22.6654 28V25.3333C22.6654 23.9188 22.1035 22.5623 21.1033 21.5621C20.1031 20.5619 18.7465 20 17.332 20H6.66536C5.25088 20 3.89432 20.5619 2.89413 21.5621C1.89393 22.5623 1.33203 23.9188 1.33203 25.3333V28'
        stroke={props.color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={props.fill}
      />
      <path
        d='M12.0013 14.6667C14.9468 14.6667 17.3346 12.2789 17.3346 9.33333C17.3346 6.38781 14.9468 4 12.0013 4C9.05578 4 6.66797 6.38781 6.66797 9.33333C6.66797 12.2789 9.05578 14.6667 12.0013 14.6667Z'
        stroke={props.color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={props.fill}
      />
      <path
        d='M30.668 28V25.3333C30.6671 24.1516 30.2738 23.0037 29.5498 22.0698C28.8258 21.1358 27.8121 20.4688 26.668 20.1733'
        stroke={props.color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={props.fill}
      />
      <path
        d='M21.332 4.17334C22.4793 4.46707 23.4961 5.13427 24.2222 6.06975C24.9484 7.00523 25.3425 8.15578 25.3425 9.34001C25.3425 10.5242 24.9484 11.6748 24.2222 12.6103C23.4961 13.5457 22.4793 14.2129 21.332 14.5067'
        stroke={props.color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_7961_77895'>
        <rect width='32' height='32' fill={'#13628C'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
