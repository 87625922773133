import React, { memo, useEffect, useMemo, useState } from 'react';

import { BreadcrumbsConfig } from '@/config';
import { ArrowBack, ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, BreadcrumbsProps, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Link } from '..';

export type StyledBreadcrumbsProps = {
  alias: string;
  dynammicAlias?: string[];
  dynammicIds?: string[];
  showBackButton?: boolean;
  path?: string;
  maxItems?: number;
} & BreadcrumbsProps;
export const StyledBreadcrumbs: React.VFC<StyledBreadcrumbsProps> = memo(
  ({ alias, dynammicAlias, dynammicIds, showBackButton, path, maxItems = 2 }) => {
    const { asPath, query, push, back } = useRouter();
    const { t } = useTranslation();
    const currentBreadCrumbsConfigs = BreadcrumbsConfig[alias](dynammicAlias ? dynammicAlias : '');
    const urlConfigs = BreadcrumbsConfig[alias](dynammicIds ? dynammicIds : '');
    const des = useMemo<string>(() => {
      return asPath
        .split('/')
        .slice(0, asPath.split('/').length - 1)
        .join('/');
    }, [asPath]);

    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {
      if (window.history.length > 1) {
        setCanGoBack(true);
      }
    }, []);

    const handleGoBack = () => {
      if (canGoBack) {
        back();
      } else {
        push(des || '/');
      }
    };

    return (
      <Grid container pt={2}>
        {showBackButton && (
          <Button
            // onClick={() => push({
            //   pathname: path ?? des,
            // })}
            onClick={handleGoBack}
            startIcon={<ArrowBack sx={{ fontSize: '19px', mr: '-1px' }} />}
            sx={{ color: 'text.primary', mr: 1, alignItems: 'center', height: '20px' }}
          >
            <Typography variant='subtitle2'>{t('buttons.go_back')}</Typography>
          </Button>
        )}
        <Breadcrumbs
          maxItems={maxItems}
          separator={<ChevronRight sx={{ mx: -1, fontSize: '20px !important', pt: -1 }} />}
        >
          {currentBreadCrumbsConfigs?.map((el, i) => {
            return (
              <>
                {i != currentBreadCrumbsConfigs.length - 1 ? (
                  <Link
                    key={el.title}
                    href={{
                      pathname: urlConfigs ? urlConfigs[i].url : el.url,
                      query: { ...query },
                    }}
                    underline='hover'
                  >
                    <Typography color='text.third' variant='subtitle2' textTransform='capitalize'>
                      {dynammicAlias?.includes(el.title) ? el.title : t('pages_names.' + el.title)}
                    </Typography>
                  </Link>
                ) : (
                  <Typography color='primary.main' variant='subtitle2' textTransform='capitalize'>
                    {dynammicAlias?.includes(el.title) ? el.title : t('pages_names.' + el.title)}
                  </Typography>
                )}
              </>
            );
          })}
        </Breadcrumbs>
      </Grid>
    );
  }
);
