import { MoreVert } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { StyledPopper } from "src/ui/components/shared";

interface props {
    children: ReactNode
}
export const MoreOptions: FC<props> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClosePopper = () => setAnchorEl(null)
    return (
        <>
            <IconButton color='primary' sx={{ ml: 1, p: 2.2 }} onClick={handleOpenPopper}><MoreVert /></IconButton>
            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
                <Grid item container direction={'column'} p={1} mt={2} gap={1} alignItems='start' justifyContent={'start'} sx={{ borderRadius: '8px' , textTransform:'start' }} onClick={handleClosePopper}>
                    {children}
                </Grid>
            </StyledPopper>
        </>
    )
}