import React from 'react';
import { QueryHandlerModel } from './QueryHandler';
import { Loading } from '@/components';
import NotFoundPage, { NotFoundPageProps } from 'src/ui/components/dumb/app/NotFoundPage';
import ErrorPage from 'src/ui/components/dumb/app/ErrorPage';
import { SkeletonForm } from './skeleton-form.tsx/skeleton-form';

export enum SkeletonFormType {
  FULL_FORM = 'FULL_FORM',
  TABLE = 'TABLE',
}

type IProps = {
  queryHandler: QueryHandlerModel;
  children: React.ReactNode | null;

  notFoundPageProps?: NotFoundPageProps;
  type?: SkeletonFormType | undefined;
  LoadingFullHeight?: boolean;
};

const QueryStatusComponent = ({
  queryHandler,
  children,

  notFoundPageProps,
  type,
  LoadingFullHeight = true,
}: IProps) => {
  if (queryHandler.isLoading) {
    return <>{type ? <SkeletonForm type={type} /> : <Loading fullHeight={LoadingFullHeight} />}</>;
  }
  if (queryHandler.notFound) {
    return <NotFoundPage {...notFoundPageProps} />;
  }
  if (queryHandler.isError) {
    return <ErrorPage debug error={queryHandler.error} />;
  }
  return <>{children}</>;
};

export default QueryStatusComponent;
