import create from 'zustand';
import { AuthenticateStatus } from '@/config';
import axios from 'axios';
import { apiURLs } from '@/config';

export const useUsersStore = create((set, get) => ({
    users_info: null,
    user_info: null,
    company_settings: null,
    presentable_roles: null,
    presentable_permissions: null,
    account_types: null,
    invitePopupVisible: false,

    setUsersData: (data) => {
        set({ users_info: data });
    },
    setUserInfo: (data) => {
        if(data)
        set({ 
            user_info: data.user_info, 
            account_types: data.account_types.map(ob => ob.name), 
            presentable_roles: data.presentable_roles, 
            presentable_permissions: data.presentable_permissions 
        })
        else
            set({ user_info: null })
    },
    setRoles: (value: string, id: string) => {
        let roles = get().presentable_roles?.map(role => {
            if (role.data.id == id) return { ...role, is_selected: value};
            return role;
        });
        set({ presentable_roles: roles });
    },
    setPermisisons: (value: string, id: string) => {
        let permissions = get().presentable_permissions?.map(permission => {
            if (permission.data.id == id) return { ...permission, is_selected: value };
            return permission;
        });
        set({ presentable_permissions: permissions });
    },
    setCompanyDocuments: documents => {
        set({
            company_settings: {
                ...get().company_settings,
                company_documents: documents,
            },
        });
    },
    toggleInvitePopup: (open: boolean) => {
        set({ invitePopupVisible: open });
    },
    changeUserData: columnId => {
        set({ users_info: get().users_info.map(user => { return user.id == columnId ? { ...user, status: ['ACTIVE'] } : user }) });
    },
}));
