import React from 'react';
import useIsOffline from 'src/logic/hooks/use-is-offline';

import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { toast } from 'react-toastify';

const ConnectionStatusIndicatorToast = () => {
  const lostConnectionBefore = React.useRef<boolean>(false);
  const isOffline = useIsOffline();

  React.useEffect(() => {
    if (isOffline) {
      lostConnectionBefore.current = true;
      toast.error('Please check your internet connection', {
        toastId: 'OFFLINE',
        position: 'bottom-center',
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        bodyClassName: 'text-no-wrap',
        icon: <WifiOffIcon />,
      });
      toast.dismiss('ONLINE');
    } else {
      if (lostConnectionBefore.current) {
        toast.success('Your internet connection was restored', {
          toastId: 'ONLINE',
          position: 'bottom-center',
          autoClose: 5000,
          closeButton: false,
          bodyClassName: 'text-no-wrap',
          icon: <WifiIcon />,
        });
        toast.dismiss('OFFLINE');
      }
    }
  }, [isOffline]);

  return null;
};

export default ConnectionStatusIndicatorToast;
