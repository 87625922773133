import { Grid, Typography, Divider } from '@mui/material';
import { useTranslation } from 'next-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { CommentsNumber, QuoteSellerDetails, TextWithValue } from '@/components';
import { ReceivedQuote, ReceivedQuoteDetails } from '@/models';

type CTReceivedQuotesProps = {
    receivedQuotes: ReceivedQuote[] | null
    selectedQuote: ReceivedQuoteDetails | null
    selectedQuoteId: number
    setSelectedQuote: (quoteId: number) => void
}

type CTReceivedQuoteProps = {
    ctQuote: ReceivedQuote
    selectedQuote: ReceivedQuoteDetails | null
    setSelectedQuote: (quoteId: number) => void
}

export const CTReceivedQuotes: React.FC<CTReceivedQuotesProps> = ({ receivedQuotes, selectedQuote, selectedQuoteId, setSelectedQuote }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(true);
    const toggleCollapse = () => setIsOpen(!isOpen)
    const receivedQuote = receivedQuotes?.find(receivedQuote => receivedQuote.quoteDetails.quoteModel.id === selectedQuoteId);
    return (
        <Grid item md={12} my={8}>
            <Divider />

            {receivedQuotes?.length != 0 && <>
                <Grid container alignItems={'start'} md={12} mt={5} mb={3}>
                    <Grid item md={2}>
                        <Typography fontWeight={700} variant="overline" mr={5}>{t('texts.my_received_Quotes')}</Typography>
                        <Typography variant='body2' fontWeight={400} color='text.third'>{receivedQuote?.numberOfProducts} {" " + t('texts.products_added')}</Typography>
                    </Grid>
                    {isOpen ? <Grid item container md={9.5} alignItems={'center'} justifyContent={"start"} sx={{ cursor: 'pointer' }}><Typography onClick={toggleCollapse} fontWeight={500} variant="h6" color="primary.main">{t('buttons.collapse')}  </Typography><ExpandLessIcon onClick={toggleCollapse} sx={{ color: "primary.main" }} /></Grid> : <Grid item container md={9.5} alignItems={'center'} justifyContent={"start"} onClick={toggleCollapse} sx={{ cursor: 'pointer' }}> <Typography onClick={toggleCollapse} fontWeight={500} variant="h6" color="primary.main">{t('buttons.expand')} </Typography><ExpandMoreIcon onClick={toggleCollapse} sx={{ color: "primary.main" }} /></Grid>}
                </Grid>

                <Grid container md={12} alignItems='center' mb={3} gap={3}>
                    {isOpen && receivedQuotes?.map((quote: ReceivedQuote) => <QuoteCard ctQuote={quote} selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote} />)}
                </Grid>

                <Grid container md={12}>
                    {
                        selectedQuote && isOpen && <>
                            <Grid item container md={12} justifyContent='flex-end'>
                                <CommentsNumber title={t('texts.quote')} commentType={selectedQuote.commentable_type ?? ''} commentId={selectedQuote.id ?? ''} formDetails={selectedQuote} />
                            </Grid>
                            <QuoteSellerDetails productsTableRows={receivedQuote?.quoteDetails.productsTableRows} quote={receivedQuote?.quoteDetails} />
                        </>
                    }
                </Grid>
            </>}

        </Grid>
    )
}


const QuoteCard: React.FC<CTReceivedQuoteProps> = ({ ctQuote, selectedQuote, setSelectedQuote }) => {
    const { t } = useTranslation();
    const isSelectedQuote = ctQuote.quoteDetails.quoteModel.id == selectedQuote?.id
    return (
        <Grid item alignItems={'center'} md={3.5} sx={{ bgcolor: 'white', borderRadius: '8px', px: 3, py: 2, border: isSelectedQuote ? '3px solid #13628C' : '0.1px solid #E9F0F4', cursor: 'pointer' }}
            onClick={() => setSelectedQuote(ctQuote.quoteDetails.quoteModel.id)}>
            <Grid item><Typography fontWeight={600} variant="h6" color={isSelectedQuote ? 'primary.main' : 'text.disabled'}>{ctQuote.quoteDetails.quoteModel.seller_details.name}</Typography>
                <Grid item sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <TextWithValue title={t('texts.products')} value={ctQuote.numberOfProducts} />
                    <TextWithValue title={t('texts.quote')} value={ctQuote.quoteDetails.quoteModel.total_value} />
                </Grid>
            </Grid>
        </Grid>
    )
} 