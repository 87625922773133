import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { ProcurementButtons, ProcurementPopupStatus } from '@/config';
import { ProcurementActionsPopupContent, DescPopup } from '@/components';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import initialValues from './initialValues';
import { commentsWithReasonValidationSchema } from './validationSchema';
import { useSellerProcurementStore } from 'src/logic/zustand/procurement/seller';
import { HighlightOff } from '@mui/icons-material';
import { RFQDetails } from '@/models';

interface props {
  requestForQuote: RFQDetails;
}

export const RFQSellerDeclineAction: React.VFC<props> = ({ requestForQuote }) => {
  const { t } = useTranslation();
  const { getReasonsForPopup, decline, setPopupStatus } = useSellerProcurementStore(
    state => state.requestForQuoteSliceActions
  );
  const { openedPopupStatus, reasonsForPopup, customLoadingButton } = useSellerProcurementStore(
    state => state.requestForQuoteSliceProperties
  );

  const handleSubmit = (values, actions) => decline(values.reasonId, values.comment);
  const handleClosePopup = () => setPopupStatus(null);
  const openPopup = () => setPopupStatus(ProcurementPopupStatus.DECLINE);
  useEffect(() => {
    if (openedPopupStatus === ProcurementPopupStatus.DECLINE) getReasonsForPopup();
  }, [openedPopupStatus]);

  return (
    <>
      {requestForQuote?.canDeclineRequestForQuote && (
        <Button color='error' variant='outlined' onClick={openPopup}>
          {t('buttons.decline_rfq')}
        </Button>
      )}
      <DescPopup handleClose={handleClosePopup} open={openedPopupStatus === ProcurementPopupStatus.DECLINE}>
        <Grid container gap={2} width={300}>
          <Formik
            initialValues={initialValues}
            validationSchema={commentsWithReasonValidationSchema}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <ProcurementActionsPopupContent
                  icon={<HighlightOff color='error' />}
                  buttonTitle={t('buttons.decline', { formName: requestForQuote?.rfqModel?.name })}
                  popupTitle={t('buttons.decline', { formName: requestForQuote?.rfqModel?.name })}
                  status={'error'}
                  popupSubTitle={t('popups.subTitles.decline_rfq')}
                  reasonsForPopup={reasonsForPopup}
                  isButtonLoading={customLoadingButton == ProcurementButtons.DECLINE_RFQ_BY_SELLER}
                  // checkLabel={t('texts.decline_rfq')}
                />
              </form>
            )}
          </Formik>
        </Grid>
      </DescPopup>
    </>
  );
};
