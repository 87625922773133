export const WARRANTY_TERMS = [
  '3 months Limited Warranty',
  '6 months Limited Warranty',
  '9 months Limited Warranty',
  '12 months Limited Warranty',
  '18 months Limited Warranty',
  '24 months Limited Warranty',
  '36 months Limited Warranty',
  '3 months Comprehensive Warranty',
  '6 months Comprehensive Warranty',
  '9 months Comprehensive Warranty',
  '12 months Comprehensive Warranty',
  '18 months Comprehensive Warranty',
  '24 months Comprehensive Warranty',
  '36 months Comprehensive Warranty',
  '3 months Unconditional Warranty',
  '6 months Unconditional Warranty',
  '9 months Unconditional Warranty',
  '12 months Unconditional Warranty',
  '18 months Unconditional Warranty',
  '24 months Unconditional Warranty',
  '36 months Unconditional Warranty',
];
export const PAYMENT_TERMS = [
  'Cash on Delivery',
  'Cash/CDC on Delivery',
  '30 Days PDC on Delivery',
  '60 Days PDC on Delivery',
  '90 Days PDC on Delivery',
  '120 Days PDC on Delivery',
  'EOM CDC',
  'EOM Bank Transfer',
  'EOM 30 Days Transfer',
  'EOM 60 Days Transfer',
  'EOM 90 Days Transfer',
  'EOM 30 Days PDC',
  'EOM 60 Days PDC',
  'EOM 90 Days PDC',
  '30 Days Open Credit',
  '60 Days Open Credit',
  '90 Days Open Credit',
  '120 Days Open Credit',
];
export const DELIVERY_TERMS = ['Pick up from Warehouse', 'Free Delivery'];
