import { AuthenticateStatus, ProductInCartType } from "@/config";
import { FilterItem, Filters_Res, PaginationResult, Product, Requisition } from "@/models";
import {
    buildAlternativeFilterRequestFormDataData,
    toggleSelectionOfFilterItem, useApplyAlternativesFilter,
    useGetAlternativesProducts
} from "@/services";
import produce from "immer";
import { StateCreator } from "zustand";
import { useUserData } from "../../user";

export interface ProductAlternativesSlice {
    isSimilar: boolean
    params: any
    productAlternativesLoading: boolean
    productAlternativesFilterResultLoading: boolean
    isLoadingProductAlternatives: boolean
    productAlternativesMainProduct: Product | null
    productAlternativesAlternatives: Product[]
    loadProductAlternatives: (productId: number, params: any, isSimilar?: boolean) => void
    productAlternativesFilters: Filters_Res[] | null
    alternativesSortData: FilterItem[] | null
    productAlternativesPaginationResult: PaginationResult | null
    copyOfFormProductAlternativesSlice: Requisition | null
    numberOfInCartAlternatives: number
    canPerformActiononAlternatives: boolean
    canPerformActiononAlternativesPO: boolean
    selectedSortItemInAlternativePage?: number
    changeProductAlternativesProductQuantity: (product: Product, quantity: number) => void
    changeProductAlternativesRequirements: (product: Product, additional_requirements: string) => void
    changeAlternativesDetailsPrice: (product: Product, price: string) => void
    onFilterItemCheckProductAlternativesSlice: (filterItem: FilterItem, type: string) => void,
    onFilterItemUnCheckProductAlternativesSlice: (filterItem: FilterItem, type: string) => void,
    onFilterItemToggleProductAlternativesSlice: (filterItem: FilterItem, type: string, checked: boolean) => void,
    applyFiltersProductAlternativesSlice: () => void
    selectedPageNumberProductAlternativesSlice: number,
    onPageNumberChangeProductAlternativesSlice: (pageNumber: number) => void,
    onSortItemSelectionInAlternativePage: (sortId: number) => void
}

export const createProductAlternativesSlice: StateCreator<ProductAlternativesSlice> = (set, get, api) => ({
    isSimilar: false,
    params: {},
    numberOfInCartAlternatives: 0,
    productAlternativesLoading: true,
    productAlternativesFilterResultLoading: true,
    isLoadingProductAlternatives: false,
    copyOfFormProductAlternativesSlice: null,
    productAlternativesMainProduct: null,
    productAlternativesAlternatives: [],
    canPerformActiononAlternatives: false,
    alternativesSortData: null,
    canPerformActiononAlternativesPO: false,
    loadProductAlternatives: async (productId: number, params, isSimilar = false) => {
        set(produce(draftState => { draftState.isSimilar = isSimilar }))
        set(produce(draftState => { draftState.isLoadingProductAlternatives = true }))
        useGetAlternativesProducts(productId, params).then(data => {
            const paginationResult = data.data.data
            const { isSeller, isAuthenticated } = useUserData.getState()
            set(produce(draftState => {
                draftState.params = params
                draftState.productAlternativesMainProduct = get().buildInitialProductState(data.data.product)
                if(isSimilar) {
                    draftState.productAlternativesAlternatives = paginationResult.data.map((product: Product) => {
                        return { ...product, quantity: 1, inCart: false, price: product.price ?? product.catalog_price ?? '' }
                    });
                } else {
                    draftState.productAlternativesAlternatives = paginationResult.data.map((alternativeProduct: Product) => {
                        return get().buildInitialProductAlternativeState(alternativeProduct, data.data.product.id)
                    })
                }
                
                draftState.isLoadingProductAlternatives = false
                draftState.copyOfFormProductAlternativesSlice = null
                draftState.productAlternativesFilters = data.data.filter_data
                draftState.productAlternativesLoading = false
                draftState.canPerformActiononAlternatives = !isSeller() || isAuthenticated === AuthenticateStatus.NOT_AUTHENTICATED
                draftState.canPerformActiononAlternativesPO = !isSeller() && isAuthenticated === AuthenticateStatus.AUTHENTICATED
                draftState.productAlternativesFilterResultLoading = false
                draftState.alternativesSortData = data.data.sort_data
                draftState.productAlternativesPaginationResult = { total: paginationResult.total, last_page: paginationResult.last_page, current_page: paginationResult.current_page }
            }))

        }).catch(e => { })
        api.subscribe(
            state => {
                const productSliceData = get().productFormManagerProperties.data
                const formDetails = state.productFormManagerProperties.data.formDetails
                const productSliceActions = get().productFormManagerProperties.actions
                if (JSON.stringify(formDetails) !== JSON.stringify(get().copyOfFormProductAlternativesSlice))
                    set(produce(draftState => {
                        draftState.productAlternativesMainProduct =
                            productSliceData.environment ? productSliceActions.transformProduct(get().productAlternativesMainProduct, formDetails)
                                : productSliceActions.buildInitialProductState(get().productAlternativesMainProduct)
                        draftState.productAlternativesAlternatives = get().productAlternativesAlternatives.map((element: Product) =>
                            productSliceData.environment ? productSliceActions.transformProduct(element, formDetails, !get().isSimilar)
                                : get().buildInitialProductAlternativeState(element, get().productAlternativesMainProduct.id)
                        )
                        draftState.copyOfFormProductAlternativesSlice = formDetails
                        draftState.numberOfInCartAlternatives = formDetails?.products.reduce((numberOfAlternatives, requisitionProduct) => {
                            return requisitionProduct?.alternatives?.length
                        }, 0)
                    }))
            },
        )
    },
    changeProductAlternativesProductQuantity: (product: Product, quantity: number) => {
        product = produce(product, (draftProduct) => { draftProduct.quantity = quantity })
        if(get().productAlternativesMainProduct?.id === product.id) {
            set(produce(draftState => {
                draftState.productAlternativesMainProduct = product;
            }))
        }

        if (product.inCartType === ProductInCartType.ALTERNATIVE) set(produce(draftState => {
            draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        else set(produce(draftState => {
            // draftState.productAlternativesMainProduct.quantity = quantity
            draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return {...product, quantity}
                else return filterProduct
            })
        }))
        if (product?.inCart)
            get().productFormManagerProperties.actions.changeQuantity(product, quantity)
    },
    changeProductAlternativesRequirements: (product: Product, additional_requirements: string) => {
        product = produce(product, (draftProduct) => { draftProduct.additional_requirements = additional_requirements })
        if(get().productAlternativesMainProduct?.id === product.id) {
            set(produce(draftState => {
                draftState.productAlternativesMainProduct = product;
            }))
        }
        set(produce(draftState => {
            draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return {...product, additional_requirements}
                else return filterProduct
            })
        }))
    },
    changeProductAlternativesProductPrice: (product: Product, price: string) => {
        product = produce(product, (draftProduct) => { draftProduct.price = price })
        set(produce(draftState => {
            draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return {...product, price}
                else return filterProduct
            })
        }))
        if (product?.inCart)
            get().productFormManagerProperties.actions.changePrice(product, price)
    },
    changeAlternativesDetailsPrice: (product: Product, price: string) => {
        product = produce(product, (draftProduct) => { draftProduct.price = price })
        if(get().productAlternativesMainProduct?.id === product.id) {
            set(produce(draftState => {
                draftState.productAlternativesMainProduct = product;
            }))
        }
        set(produce(draftState => {
            draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return {...product, price}
                else return filterProduct
            })
        }))
        if (product?.inCart)
            get().productFormManagerProperties.actions.changePrice(product, price)
        // product = produce(product, (draftProduct) => { draftProduct.price = price })
        // if (Number(price) > 0) {
        //     if (product.inCartType === ProductInCartType.ALTERNATIVE) set(produce(draftState => {
        //         draftState.productAlternativesAlternatives = draftState.productAlternativesAlternatives.map((filterProduct: Product) => {
        //             if (filterProduct.id == product.id) return product
        //             else return filterProduct
        //         })
        //     }))
        //     else set(produce(draftState => {
        //         draftState.productAlternativesMainProduct.price = price
        //     }))
        // }
    },
    onFilterItemCheckProductAlternativesSlice: (filterItem: FilterItem, type: string) => {
        get().onFilterItemToggleProductAlternativesSlice(filterItem, type, true)
    },
    onFilterItemUnCheckProductAlternativesSlice: (filterItem: FilterItem, type: string) => {
        get().onFilterItemToggleProductAlternativesSlice(filterItem, type, false)
    },
    onFilterItemToggleProductAlternativesSlice: (filterItem: FilterItem, type: string, checked: boolean) => {
        set(produce(draftState => {
            draftState.productAlternativesFilterResultLoading = true
            draftState.productAlternativesFilters = toggleSelectionOfFilterItem(filterItem, type, draftState.productAlternativesFilters, checked)
            draftState.selectedPageNumberProductAlternativesSlice = 1
        }))
        get().applyFiltersProductAlternativesSlice()
    },
    onSortItemSelectionInAlternativePage: (sortId: number) => {
        set(produce(draftState => { 
            draftState.selectedSortItemInAlternativePage = sortId
            draftState.productAlternativesFilterResultLoading = true
        }))
        get().applyFiltersProductAlternativesSlice()
    },
    applyFiltersProductAlternativesSlice: () => {
        const filterFormData = buildAlternativeFilterRequestFormDataData(get().productAlternativesMainProduct.id, get().productAlternativesFilters, get().selectedPageNumberProductAlternativesSlice,get().selectedSortItemInAlternativePage)
        
        const params = get().params;
        if (params.is_adding_to_catalog !== undefined) {
            filterFormData.append("is_adding_to_catalog", 1)
        }
        if (params.seller_id) {
            filterFormData.append("seller_id", params.seller_id)
        }
        if (params.filtration_purpose && params.quote_id) {
            filterFormData.append("filtration_purpose", params.filtration_purpose)
            filterFormData.append("quote_id", params.quote_id)
        }

        const isSimilar = get().isSimilar;
        useApplyAlternativesFilter(filterFormData)
            .then(data => {
                const paginationResult = data.data.content
                set(produce(draftState => {
                    draftState.productAlternativesAlternatives = data.data.content.data.map((product: Product) => {
                        //TODO: buildInitialProductState SHOULD CATER FOR CURRENT REQ
                        const oldFormDetails = get().copyOfFormProductAlternativesSlice;
                        if (oldFormDetails) {
                            if(isSimilar) {
                                const productInCart = get().productFormManagerProperties?.data?.products?.find(prod => prod.id === product.id);
                                console.log(get().productFormManagerProperties?.data?.products)
                                if(productInCart) return { ...product, ...productInCart, inCartType: 'main' };
                                return get().buildInitialProductState(product);
                            } 

                            const formDetails = {
                                ...oldFormDetails,
                                products: oldFormDetails?.products.map(prod => ({
                                    ...prod,
                                    main_product: prod,
                                    alternative_products: prod.alternatives ?? [],
                                }))
                            }
                            return get().transformProduct(get().buildInitialProductAlternativeState(product, draftState.productAlternativesMainProduct.id), formDetails)
                        } else {
                            if(isSimilar) {
                                return get().buildInitialProductState(product);
                            } else {
                                return get().buildInitialProductAlternativeState(product, draftState.productAlternativesMainProduct.id)
                            }
                        }
                    })
                    draftState.productAlternativesFilterResultLoading = false
                    draftState.productAlternativesPaginationResult = { total: paginationResult.total, last_page: paginationResult.last_page, current_page: paginationResult.current_page }
                }))
            })
            // .catch(e => {
            //     console.log(e)
            // })
    },
    selectedPageNumberProductAlternativesSlice: 1,
    onPageNumberChangeProductAlternativesSlice: (pageNumber: number) => {
        set(produce(draftState => {
            draftState.selectedPageNumberProductAlternativesSlice = pageNumber
            draftState.productAlternativesFilterResultLoading = true
        }))
        get().applyFiltersProductAlternativesSlice()
    },
    productAlternativesFilters: null,
    productAlternativesPaginationResult: null
})

