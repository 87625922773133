import { Close } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

type ExitFormProps = {
    exitFormHandler: () => void
}

export const ExitForm: FC<ExitFormProps> = ({ exitFormHandler }) => {
    const { t } = useTranslation();
    return (
        <>
            <Divider variant='middle' orientation='vertical' sx={{ width: 2, height: 30, opacity: 1, mx: 1 }} />
            <Button onClick={exitFormHandler} sx={{ opacity: 0.5 }} startIcon={<Close sx={{ color: "text.disabled", height: 17, mr: -0.5 }} />}>
                <Typography fontWeight={600} variant="body2" color="text.disabled">
                    {t('buttons.exit')}
                </Typography>
            </Button>
        </>

    )
}