import React from 'react';

export type PopupUtils = {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
}

type PopupOptions = {
  initialValue?: boolean;
  onCloseCallback?: () => void; // New option for onClose callback
}

const usePopup = ({ initialValue = false, onCloseCallback }: PopupOptions = {}): PopupUtils => {
  const [isOpen, setIsOpen] = React.useState(initialValue);

  const handleOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    // Call onCloseCallback if provided
    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  const handleToggle = React.useCallback(() => {
    setIsOpen(v => !v);
  }, []);

  const returnValue: PopupUtils = React.useMemo(
    () => ({
      isOpen,
      setIsOpen,
      handleOpen,
      handleClose,
      handleToggle,
    }),
    [isOpen, setIsOpen, handleOpen, handleClose, handleToggle]
  );

  return returnValue;
};

export default usePopup;
