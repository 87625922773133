import { SvgIconProps, SvgIcon } from '@mui/material';

export const ShareIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 2C20.2091 2 22 3.79086 22 6C22 8.20914 20.2091 10 18 10C16.8302 10 15.7777 9.49784 15.0462 8.69732L10 11.5803V12.4197L15.0462 15.3027C15.7777 14.5022 16.8302 14 18 14C20.2091 14 22 15.7909 22 18C22 20.2091 20.2091 22 18 22C15.7909 22 14 20.2091 14 18C14 17.6802 14.0375 17.3692 14.1084 17.0712L9.26914 14.3055C8.54488 15.3306 7.35061 16 6 16C3.79086 16 2 14.2091 2 12C2 9.79086 3.79086 8 6 8C7.35061 8 8.54488 8.66938 9.26914 9.69449L14.1084 6.92883C14.0375 6.63076 14 6.31976 14 6C14 3.79086 15.7909 2 18 2ZM18 16C16.8954 16 16 16.8954 16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16ZM6 10C4.89543 10 4 10.8954 4 12C4 13.1046 4.89543 14 6 14C7.10457 14 8 13.1046 8 12C8 10.8954 7.10457 10 6 10ZM18 4C16.8954 4 16 4.89543 16 6C16 7.10457 16.8954 8 18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4Z'
      fill='#13628C'
    />
  </SvgIcon>
);
