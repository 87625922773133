import { Grid, Button } from '@mui/material';
import { useRequisition } from '@/zustand';
import AddIcon from '@mui/icons-material/Add';
import { useRouter } from 'next/router';
import React from 'react';
import { Requisition } from '@/models';
import { GeneralTermsSelect } from '@/components';
import { useTranslation } from 'next-i18next';
import { getRequisitionTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';

type Props = { requisition: Requisition; isSmallScreen: boolean };
const smallBreakPoints = {
  lg: 6,
  xs: 12,
  md: 6,
} as const;

export const AddProductsSection: React.FC<Props> = ({
  requisition,
  isSmallScreen,
}) => {
  return (
    <Grid container>
      {((isSmallScreen && requisition.products?.length == 0) ||
        !isSmallScreen) && (
        <Grid item {...smallBreakPoints}>
          <AddProductsButton isSmallScreen={isSmallScreen} />
        </Grid>
      )}
      {!isSmallScreen && (
        <Grid
          item
          {...smallBreakPoints}
          {...(!isSmallScreen && {
            sx: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            },
          })}
          mt={isSmallScreen ? 1 : 0}
        >
          <GeneralTermsSelect
            requisition={requisition}
            isSmallScreen={isSmallScreen}
          />
        </Grid>
      )}
    </Grid>
  );
};

type AddProductsButtonProps = { isSmallScreen: boolean };

export const AddProductsButton: React.VFC<AddProductsButtonProps> = ({
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const toggleReqMainPro = useRequisition(state => state.toggleReqMainPro);
  return (
    <Button
      onClick={() => {
        router.push({
          pathname: sm.searchResults.index.url,
          query: {
            reqId: router.query.reqId,
            tabId: new Date().getTime(),
            updatedTitle: 'All Products',
          },
        });
        toggleReqMainPro({}, true, 'add');
      }}
      endIcon={<AddIcon />}
      variant='contained'
      sx={{
        width: isSmallScreen ? '100%' : '176px',
        '& .MuiButton-endIcon': { position: 'absolute', right: 15 },
      }}
    >
       {t(getRequisitionTranslationKey('titles.add_products'))}
    </Button>
  );
};
