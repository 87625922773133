import { request } from '@/helpers';
import { Requisition } from '@/models';
import { useQuery } from '@tanstack/react-query';

export const useGetEditableRequisitions = ({ enabled = true } = {}) => {
  return useQuery<Requisition[]>({
    queryKey: ['EDITABLE_REQUISITIONS'],
    queryFn: async () => {
      const response = await request({
        url: '/requisition/all-data',
        method: 'GET',
      });
      return response.data.requisitions;
    },
    retry: 0,
    enabled,
  });
};
