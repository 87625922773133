import { Button, Snackbar } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

export type SavedSnackbarProps = {
  openSnack?: boolean;
  handleCloseSnack: Function;
};

export const SavedSnackbar: React.VFC<SavedSnackbarProps> = ({
  openSnack,
  handleCloseSnack,
}) => {
  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={4000}
      onClose={handleCloseSnack}
    >
      <Button
        variant='contained'
        startIcon={<CheckIcon />}
        sx={{ width: '94%', }}
      >
        Saved
      </Button>
    </Snackbar>
  );
};
