import { useRouter } from 'next/router';
import React from 'react';
import { RouteProtectionRules } from '@/config';
import { useRouteProtected } from 'src/logic/hooks/auth/use-route-protected';
import { useRouteRedirect } from 'src/logic/hooks/auth/use-route-redirect';
import { sm } from "src/site-map";

export const ProtectedRoutesProvider: React.FC<{}> = (props) => {
    const router = useRouter();
    const currentRouteProtectionRules = RouteProtectionRules[router.pathname.replace(/(\[.+?\])|(\[.+\])/g, '*')]
    const isAllowed = useRouteProtected(currentRouteProtectionRules);
    const redirectURL = useRouteRedirect(currentRouteProtectionRules);
    if (!isAllowed) router.push(currentRouteProtectionRules.redirectUrl)
    return (
        <>{props.children}</>
    )
}