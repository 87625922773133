import { ProductManagerEnv } from '@/config';

import { Product } from '@/models';
import RequsitionSavingServices from '../requisitions';
import PurchaseOrderSavingServices from '../purchase-orders';
import QuoteSavingServices from '../quotes';
import CatalogSavingServices from '../catalog';
import EditBundleRfqSavingServices from '../edit_bundle_rfq';
import EditQuoteFromScratchSavingServices from '../edit_quote_from_scratch';
import EditPoFromScratchSavingServices from '../edit_po_from_scratch';

type EnvRemoveServiceMapperParams = {
  env: ProductManagerEnv;
  formId: number;
  product: Product;
  isAlternative?: boolean;
};

const EnvRemoveServiceMapper = ({ env, formId, product, isAlternative = false }: EnvRemoveServiceMapperParams) => {
  if (env === ProductManagerEnv.requisition) {
    if (isAlternative) {
      return () =>
        RequsitionSavingServices.removeAlternativeProductFromRequsition({
          requisition_id: formId,
          main_product_id: product.parentId,
          alternative_product_id: product.id,
        });
    } else {
      return () =>
        RequsitionSavingServices.removeProductFromRequisition({
          requisition_id: formId,
          product_id: product.id,
        });
    }
  } else if (env === ProductManagerEnv.purchase_order) {
    return () =>
      PurchaseOrderSavingServices.removeProductFromPurchaseOrder({
        purchase_order_id: formId,
        product_id: product.id,
      });
  } else if (env === ProductManagerEnv.quote) {
    return () =>
      QuoteSavingServices.removeProductFromQuote({
        quote_id: formId,
        product_id: product.id,
      });
  } else if (env === ProductManagerEnv.catalog) {
    return () =>
      CatalogSavingServices.removeProductFromCatalog({
        catalog_id: formId,
        product_id: product.id,
      });
  } else if (env === ProductManagerEnv.edit_bundle_rfq) {
    return () => EditBundleRfqSavingServices.removeProduct();
  } else if (env === ProductManagerEnv.edit_quote_from_scratch) {
    return () => EditQuoteFromScratchSavingServices.removeProduct();
  } else if (env === ProductManagerEnv.edit_po_from_scratch) {
    return () => EditPoFromScratchSavingServices.removeProduct();
  }
  return null;
};

export default EnvRemoveServiceMapper;
