import React, { FC, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface TextFieldCounterProps {
    value: number,
    changeQuantity: Function,
    disabled?: boolean
}

export const TextFieldCounter: FC<TextFieldCounterProps> = ({ value, changeQuantity, disabled }) => {
    const handleChangeValue = (value: number) => {
        if (value) changeQuantity(value)
    }

    const iconButtonStyle = { width: 20, height: 20, backgroundColor: 'action.disabledBackground', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'  };

    const iconStyle = { color: 'primary.main', fontSize: '20px', '&:hover': {bgcolor:'transparent' } };

    return (
        <TextField
            disabled={disabled}
            type="tel"
            value={value}
            onChange={(val) => handleChangeValue(Number(val.target.value))}
            InputProps={{

                inputProps: { style: { textAlign: 'center', fontSize: '14px', fontWeight: '500', color: '#3A3E50' } },

                style: { width: '100%', height: 32, justifyContent: 'center' },

                startAdornment: (
                    <IconButton disableRipple disableTouchRipple disableFocusRipple disabled={disabled} onClick={() => { handleChangeValue(value - 1) }} sx={iconButtonStyle}>
                        <RemoveIcon sx={iconStyle} />
                    </IconButton>
                ),
                endAdornment: (
                    <IconButton disableRipple disableTouchRipple disableFocusRipple disabled={disabled} onClick={() => { handleChangeValue(value + 1) }} sx={iconButtonStyle} >
                        <AddIcon sx={iconStyle} />
                    </IconButton>
                ),
            }}
        />
    );
};

