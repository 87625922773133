import React from 'react';
import { Button, Typography } from '@mui/material';
import Link from 'next/link';

type WithLinkButton = {
  linkText: string;
  linkUrl: string;
};

type WithoutLinkButton = {
  linkText?: undefined;
  linkUrl?: undefined;
};

export type NotFoundPageProps = WithLinkButton | WithoutLinkButton;

const NotFoundPage = (props: NotFoundPageProps) => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'sans-serif',
      }}
    >
      <Typography
        textAlign={'center'}
        fontSize={80}
        fontWeight={700}
        color='error'
        lineHeight={1.1}
      >
        404
      </Typography>
      <Typography
        textAlign={'center'}
        fontSize={32}
        fontWeight={700}
        color='error'
        lineHeight={1.1}
      >
        Not Found
      </Typography>
      {props.linkText !== undefined && (
        <Link href={props.linkUrl}>
          <Button
            type='button'
            color='primary'
            variant='contained'
            style={{ marginTop: '2rem' }}
          >
            {props.linkText}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default NotFoundPage;
