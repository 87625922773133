import { GeneralTermsPopup } from "@/components";
import { GeneralObject } from "@/models";
import { useProcurementStore } from "@/zustand";
import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from "react";
import * as yup from 'yup';

interface UpdateGeneralTermsProps {
    terms: any
    isTermsValid: boolean
    forceValidateTerms?: boolean
    warranty_term: GeneralObject | null | undefined
    payment_term: GeneralObject | null | undefined
    delivery_term: GeneralObject | null | undefined
    handleSelectGeneralTerms: (values: any) => void
}

export const UpdateGeneralTerms: FC<UpdateGeneralTermsProps> = ({ terms, isTermsValid, warranty_term, payment_term, delivery_term, handleSelectGeneralTerms, forceValidateTerms = false }) => {
    const initialValues = { payment_term_id: payment_term?.id ?? null, warranty_term_id: warranty_term?.id ?? null, delivery_term_id: delivery_term?.id ?? null }

    const validationSchema = yup.object({
        payment_term_id: yup.string().required('required').nullable(),
        warranty_term_id: yup.string().required('required').nullable(),
        delivery_term_id: yup.string().required('required').nullable()
    });
    const handleSubmit = (values) => { }

    return (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange={false} validateOnBlur={false}>
            {formikProps => (<SelectGeneralTerms formikProps={formikProps}
                terms={terms} isTermsValid={isTermsValid} forceValidateTerms={forceValidateTerms} handleSelectGeneralTerms={handleSelectGeneralTerms}
            />)}
        </Formik>
    )
}


interface SelectGeneralTermsProps {
    formikProps: any
    terms: any
    isTermsValid: boolean
    handleSelectGeneralTerms: (values: any) => void
    forceValidateTerms?: boolean
}

export const SelectGeneralTerms: FC<SelectGeneralTermsProps> = ({ formikProps, terms, isTermsValid, handleSelectGeneralTerms, forceValidateTerms = false }) => {
    const { t } = useTranslation();
    const { validateForm, isValid } = useFormikContext()

    const [selectPopup, setSelectPopup] = useState(false)
    const handleOpenSelectPopup = () => setSelectPopup(true)
    const handleCloseSelectPopup = () => setSelectPopup(false)
    const { checkIfTermsValid } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceProperties)
    const { setCheckIfTermsValid } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions)

    const handleSelectTerms = (values: any) => {
        validateForm().then(() => { isValid && handleSelectGeneralTerms(values) })
        handleCloseSelectPopup()
    }

    if (checkIfTermsValid) {
        handleSelectTerms({ payment_term_id: null, warranty_term_id: null, delivery_term_id: null })
        setCheckIfTermsValid(false)
    }
    
    React.useEffect(() => {
        if(forceValidateTerms) {
            validateForm();
        }
    }, [forceValidateTerms])

    
    return (
        <>
            <Button
                variant='outlined'
                onClick={handleOpenSelectPopup}
                sx={{ backgroundColor: 'inherit' }}
                color={!isValid ? 'error' : isTermsValid ? 'success' : 'primary'}
                startIcon={isTermsValid ? <CheckCircleOutline /> : isValid ? <></> : <InfoOutlined />}
            > {t('buttons.general_terms')}
            </Button>
            {!isValid && <Typography color='error' variant="subtitle2">{t('texts.general_terms_required')}</Typography>}

            <GeneralTermsPopup
                isPopupOpen={selectPopup}
                closePopup={handleCloseSelectPopup}
                terms={terms}
                fieldValidation={formikProps.validateField}
                handleSaveGeneralTerms={() => handleSelectTerms(formikProps.values)}
            />
        </>
    )
}