import { Grid } from '@mui/material';
import { FilterItem } from '@/models';
import React from 'react';
import { StyledChip } from 'src/ui/components/shared';

type FilterChipsProps = {
  items: FilterItem[];
  onFilterChecked: Function;
  onFilterUnchecked: Function;
  filterType: string;
  isPageAlternative?: boolean
};

export const FilterChips: React.VFC<FilterChipsProps> = ({
  items,
  onFilterChecked,
  onFilterUnchecked,
  filterType,
  isPageAlternative
}) => {
  return (
    <>
      {
        isPageAlternative || filterType != 'BRAND' ?
          <Grid
            container
            sx={{
              marginBottom: 0, maxHeight: 100, overflow: 'auto', scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': { width: 2, height: 2, },
              '&::-webkit-scrollbar-track': { boxShadow: `white` },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main', outline: `1px solid slategrey`, borderRadius: 3 },
            }}
          >
            {items?.map(el => (
              <StyledChip
                key={el.id}
                handleClickChip={() => {
                  !el.is_selected ? onFilterChecked(el, filterType) : onFilterUnchecked(el, filterType)
                }}
                title={el.name}
                isDark={el.is_selected}
              />
              // <Chip


              //   sx={{
              //     height: 30, margin: 0.3, maxWidth: 120, borderRadius: '4px',
              //     backgroundColor: el.is_selected ? 'info.contrastText' : 'transparent',
              //     border: el.is_selected ? 'none' : '1.5px solid #E8E8EA',
              //   }}
              //   label={
              //     <Typography
              //       variant='subtitle2'
              //       sx={{
              //         overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 400, color: el.is_selected ? 'primary.main' : 'text.third',
              //       }}
              //     >

              //     </Typography>
              //   }
              // />
            ))}
          </Grid>
          :
          <Grid container
            sx={{
              marginBottom: 0, maxHeight: 100, overflow: 'auto', scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': { width: 2, height: 2, },
              '&::-webkit-scrollbar-track': { boxShadow: `white` },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main', outline: `1px solid slategrey`, borderRadius: 3 },
            }}>
            {items?.map(el =>
              <Grid container item md={2} mr={2} my={0.5}>
                <img height='100%' width='100%' src={el.image_url} alt={el.name}
                  onClick={() => {
                    !el.is_selected ? onFilterChecked(el, filterType) : onFilterUnchecked(el, filterType)
                  }}
                  style={{
                    objectFit: 'contain', cursor: 'pointer',
                    opacity: el.is_selected ? '1' : '0.3',
                  }}
                />
              </Grid>
            )}
          </Grid>
      }
    </>
  );
};
