import { ResponsiveContainer } from '@/styles';
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import Image from 'next/image';
import React from 'react';
import { Link } from '@/components';
import { useTranslation } from 'next-i18next';
import { getHomeTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';


type Props = {};

export const WhatWeCanOfferTwoHomeSection: React.FC<Props> = ({ }) => {
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        bgcolor: smallScreens ? 'primary.main' : 'unset',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '75%',
          top: '15%',
          bottom: 0,
          zIndex: -1,
          bgcolor: 'primary.main',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '80%',
          height: '75%',
          left: '20%',
          top: '15%',
          zIndex: -1,
          backgroundImage: 'url(/assets/images/home/points.svg)',
          backgroundRepeat: 'repeat',
          opacity: 0.1,
          backgroundPosition: 'right center',
        }}
      />
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item lg={5} md={12} xs={12}>
          <Box
            sx={{
              position: 'relative',
              height: smallScreens ? 420 : 512,
              width: '100%',
              marginLeft: smallScreens ? 0 : '20%',
              maxWidth: smallScreens ? '100%' : 480,
              overflow: 'hidden',
              borderRadius: t => (smallScreens ? t.spacing(0) : t.spacing(6)),
            }}
          >
            <Image
              src='/assets/images/home/happy.png'
              layout='fill'
              objectFit='fill'
              alt='Hand shake'
            />
          </Box>
        </Grid>
        <Grid component={ResponsiveContainer} item lg={7} md={12} xs={12}>
          <Typography
            color='white'
            variant='h2'
            sx={{
              fontWeight: 700,
              lineHeight: 1.5,
              marginTop: 3,
            }}
          >
            {t(getHomeTranslationKey('titles.what_offer'))}
          </Typography>
          <Typography
            color='action.disabledBackground'
            width={smallScreens ? '100%' : '70%'}
            mt={1}
            variant='body2'
            sx={{ fontWeight: 400 }}
          >
            {t(getHomeTranslationKey('sub_titles.what_offer'))}
          </Typography>
          <Button
            component={Link}
            sx={{
              '&:hover': {
                backgroundColor: '#FF912D',
              },
              width: smallScreens ? '100%' : '185px',
              marginTop: 3,
              marginBottom: 4,
            }}
            href={sm.register.asSeller.index.url}
            variant='contained'
            color='secondary'
            endIcon={<ArrowForward />}
          >
            {t('buttons.become_a_seller')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
