import type { FC } from 'react';
import { Box, Checkbox, Typography } from "@mui/material";
import { useField } from 'formik'
import { at } from 'lodash';
import { useTranslation } from 'next-i18next';
import { getInputsTranslationKey } from '@/hooks';

interface CheckboxWithLabelProps {
    children: React.ReactNode,
    name: string
    translateKey?: string
    onChange?: () => void
    error?: boolean
}

export const CheckboxWithLabel: FC<CheckboxWithLabelProps> = (props: CheckboxWithLabelProps) => {
    const [field, meta, helpers] = useField(props);
    const { t } = useTranslation()

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
        }
    }
    return (
        <>
            <Box>
                <Checkbox
                    {...field}
                    {...(props.onChange ? { onClick: props.onChange } : { onChange: e => helpers.setValue(e.target.checked) })}
                    error={meta.touched && meta.error && true}
                    checked={field.value}
                    {...(props.error && {
                        sx: {
                            '&.Mui-checked': {
                                color: 'error.main',
                            },
                        }
                    })}
                />
                {props.children}
            </Box>
            <Typography color="error" variant='body2'>
                {_renderHelperText()}
            </Typography>
        </>
    );
};

