import { procurementStatus } from "@/config"
import { useTranslation } from 'next-i18next';
import { Typography, Grid, Box } from "@mui/material"
import React from "react"
import CircleIcon from '@mui/icons-material/Circle';

interface StatusTabsProps {
    status: procurementStatus
}

export const StatusTabs: React.VFC<StatusTabsProps> = ({ status }) => {
    const { t } = useTranslation();
    const mainStatus = [procurementStatus.MIXED, procurementStatus.PARTIALLY_QUOTES_RECEIVED, procurementStatus.QUOTES_RECEIVED, procurementStatus.EDITING, procurementStatus.QUOTE_RECEIVED, procurementStatus.PO_RECEIVED, procurementStatus.RFQ_RECEIVED, procurementStatus.DRAFT, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_APPROVED_BY_ME, procurementStatus.PENDING, procurementStatus.QUOTE_GENERATED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.READY]
    const dangerStatus = [procurementStatus.REVOKED, procurementStatus.DECLINED, procurementStatus.REJECTED, procurementStatus.REJECTED_BY_ME, procurementStatus.CANCELED, procurementStatus.RECALLED, procurementStatus.PARTIALLY_DECLINED]
    const warningStatus = [procurementStatus.PENDING_APPROVAL, procurementStatus.PENDING_MY_APPROVAL, procurementStatus.SENT_FOR_APPROVAL, procurementStatus.REQUESTED, "PENDING_QUOTES"]
    const successStatus = [procurementStatus.MULTI_POS_GENERATED, procurementStatus.PO_GENERATED, procurementStatus.PUBLISHED, procurementStatus.APPROVED, procurementStatus.CONFIRMED, procurementStatus.APPROVED_BY_ME, procurementStatus.SENT_TO_SELLERS, procurementStatus.SENT_TO_SELLER, procurementStatus.SENT_TO_BUYER, procurementStatus.CONVERTED_TO_RFQ, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.PARTIALLY_CONFIRMED]
    const statuses = {}
    mainStatus.forEach(key => statuses[key] = 'main');
    dangerStatus.forEach(key => statuses[key] = 'danger');
    warningStatus.forEach(key => statuses[key] = 'warning');
    successStatus.forEach(key => statuses[key] = 'success');

    return (
        <Grid item container sx={{ alignItems: 'center', color: statuses[status] == 'main' ? 'text.disabled' : statuses[status] == 'danger' ? 'info.dark' : statuses[status] == 'warning' ? 'secondary.main' : statuses[status] == 'success' ? 'text.success' : 'inherit' }}>
            <CircleIcon sx={{ fontSize: '10px', mr: 0.5 }} />
            <Typography variant="body2" fontWeight={500}>{t('chip.' + status)}</Typography>
        </Grid>
    )
}


