import { Typography } from '@mui/material';
import { LabelWithButton, user } from '@/components';
import { useProfilePhoneNumberFlow } from '@/zustand';
 
type Props = { userData: user };

export const ChangePhoneButton: React.FC<Props> = ({userData}) => { 
  
const toggleChangePhonePopup = useProfilePhoneNumberFlow(state => state.toggleChangePhonePopup);
const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
const handleClickChangePhone = () => {toggleChangePhonePopup(true); setChangePhoneByPhoneSteps(0) ;setChangePhoneByEmailSteps(0)}

return(
    <LabelWithButton labelTranslateKey="mobile_number" 
        btnColorFill={'primary'}
        buttonTranslateKey={'change_mobile_number'} 
        onClick={() => handleClickChangePhone()} 
        >
        <div>
            <Typography
                variant='body2'
                sx={{ display: 'inline', fontWeight: '500'}}
            >
                {userData.mobile_number.number} 
            </Typography>
        </div>
    </LabelWithButton>
)}