import type { FC } from 'react';
import { useMediaQuery, TextField, InputAdornment } from "@mui/material";
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { SE } from 'public/assets/icons';
import { getInputsTranslationKey } from '@/hooks';

interface SearchTextFieldProps {
  translateKey: string,
  value?: string,
  onChange: Function,
  startAdornment?: boolean
  onBlur?: Function
}

export const SearchTextField: FC<SearchTextFieldProps> = (props: SearchTextFieldProps) => {
  const { t } = useTranslation()

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <TextField
      placeholder={t(getInputsTranslationKey('labels.search.' + props.translateKey))}
      onChange={props.onChange}
      value={props.value}
      onBlur={handleBlur}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            sx={{ width: '19px', height: '19px', marginLeft: -1, marginRight: 0.5 }}
          >
            <Image src={SE} objectFit='contain' alt='Fancy' />
          </InputAdornment>
        ),
        sx: {
          bgcolor: 'secondary.contrastText',
          width: '100%',
          height: '100%',
          borderColor: 'divider',
          borderRadius: '6px'
        },
      }}
      sx={{
        flex: 1,
        height: '100%',
        width: '100%',
        textOverflow: 'ellipsis !important',
      }}
    />
  );
};

