import { useProfile,useProfilePasswordFlow } from '@/zustand';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { VerificationCodeFormByPhone } from '../shared-popups/send-verification-in-password-byPhone';
import { useCheckVerificationCode,useReRequestVerificationCodeAgain,useChangePasswordWithToken } from '@/services';
import { Success } from '../shared-popups/success';
import { ChangePasswordByPhone } from '../shared-popups/change-password-ByPhone';

type Props = {};


export const ProfilePasswordByPhonePopupContent: React.FC<Props> = () => {
    const { t } = useTranslation();

    const setChangePasswordByPhoneSteps = useProfilePasswordFlow(state => state.setChangePasswordByPhoneSteps);
    const PasswordPopupByPhoneSteps = useProfilePasswordFlow(state => state.PasswordPopupByPhoneSteps);
    const user_info = useProfile(state => state.user_info);
    const setChangePasswordSteps = useProfilePasswordFlow(state => state.setChangePasswordSteps);
    const togglePasswordByPhonePopup = useProfilePasswordFlow(state => state.togglePasswordByPhonePopup);
    const togglePasswordPopup = useProfilePasswordFlow(state => state.togglePasswordPopup);
    const setPasswordByCurrentFinish = useProfilePasswordFlow(state => state.setPasswordByCurrentFinish);

    const sendVerificationCodeByPhone = useCheckVerificationCode();
    const handleSubmitVerificationByPhone = (values, actions) => { sendVerificationCodeByPhone.mutate(values) }
    
    const getCodeByPhoneAgain = useReRequestVerificationCodeAgain();
    const handleGetCodeByPhoneAgain = () => { getCodeByPhoneAgain.mutate("PHONE") }
   
    const submitChangePasswordWithToken = useChangePasswordWithToken();
    const handleSubmitChangePasswordWithToken = (values, actions) => {{ submitChangePasswordWithToken.mutate(values) }}

    const changePassword = [
        <VerificationCodeFormByPhone
            isDisabledButton={sendVerificationCodeByPhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_phone'))}
            userInfo={user_info?.mobile_number.number}
            handleClickNext={handleSubmitVerificationByPhone}
            handleClickBack={()=> {togglePasswordByPhonePopup(false),togglePasswordPopup(true),setChangePasswordSteps(2),setPasswordByCurrentFinish(false)}}
            handleClickAgain={handleGetCodeByPhoneAgain}
        />,
        <ChangePasswordByPhone
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_password'))}
            isDisabledButton={submitChangePasswordWithToken.isLoading}
            handleClickNext={handleSubmitChangePasswordWithToken}
            handleClickBack={()=> setChangePasswordByPhoneSteps(PasswordPopupByPhoneSteps-1)}
        />,
        <Success 
            paragraphTranslate={t('notifications.change_password_success')}
            togglePopup={() => togglePasswordPopup(false)}
        />,
    ]
    return (
        <>
            {changePassword[PasswordPopupByPhoneSteps]}
        </>
    );
};