export const getOffset = (el: HTMLElement | null = null) => {
  let _x = 0,
    _y = 0,
    element: HTMLElement | null = el;
  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    _x += element.offsetLeft - element.scrollLeft;
    _y += element.offsetTop - element.scrollTop;
    element = element.offsetParent as HTMLElement;
  }
  return { top: _y, left: _x };
};
