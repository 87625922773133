import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styles from './QuantityRequestedMoreThanAvailableIndicator.module.css';
import { Grid, Typography } from '@mui/material';

type IProps = {
  availableQuantity: number;
  requestedQuantity: number;
};

const QuantityRequestedMoreThanAvailableIndicator = ({
  requestedQuantity,
  availableQuantity,
}: IProps) => {
  if (requestedQuantity > availableQuantity) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon
          sx={{ fontSize: '18px', marginBlock: 'auto' }}
          color='error'
        />
        <div className={styles.popover}>
          <Typography lineHeight={1.2} color='#3A3E50' variant='body1'>
            <strong>
              <ErrorOutlineIcon sx={{ fontSize: 'inherit' }} color='error' />{' '}
              Warning:{' '}
            </strong>
            Quantity requested might be more than the quantity available
          </Typography>

          <Grid container sx={{ marginTop: '1rem' }}>
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderInlineEnd: '1px solid #C5C6CB',
              }}
            >
              <Typography
                color='#8B8D98'
                fontWeight={700}
                variant='subtitle1'
                textAlign={'center'}
                textTransform={'uppercase'}
              >
                Requested
              </Typography>
              <Typography
                color='#3A3E50'
                fontWeight={700}
                variant='body1'
                textAlign={'center'}
              >
                {requestedQuantity}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                color='#8B8D98'
                fontWeight={700}
                variant='subtitle1'
                textAlign={'center'}
                textTransform={'uppercase'}
              >
                Quoted
              </Typography>
              <Typography
                color='#3A3E50'
                fontWeight={700}
                variant='body1'
                textAlign={'center'}
              >
                {availableQuantity}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </span>
    );
  }
  return null;
};

export default QuantityRequestedMoreThanAvailableIndicator;
