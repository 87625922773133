import { DeleteIcon } from "@/icons";
import { Requisition, RequisitionProduct } from "@/models";
import { useProcurementStore } from "@/zustand";
import { ArrowForwardIos, Description } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import React, { useState } from "react";
import { sm } from "src/site-map";
import { ConfirmPopup, SideDialog, TextFieldCounter } from "src/ui/components/shared";
import { AddProductsToRequisitionButton } from "../../../../requisition/add-products-to-requisition-button";

type RequisitionSummaryDialogProps = {
    SummaryDialogIsOpened: boolean
    closeSummaryDialog: () => void
    currentRequisition: Requisition
    canEditRequisition: boolean
}

export const RequisitionSummary: React.VFC<RequisitionSummaryDialogProps> = ({ SummaryDialogIsOpened, closeSummaryDialog, currentRequisition, canEditRequisition }) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const { deleteCurrentRequisition, numberOfCurrentMainProducts } = useProcurementStore();
    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    const removeProduct = () => {
        deleteCurrentRequisition()
        handleCloseDelPopup()
    }
    const redirectToProductPage = () => closeSummaryDialog()

    return (
        <SideDialog sideDialogIsOpened={SummaryDialogIsOpened} closeSideDialog={closeSummaryDialog}>
            <Grid container p={4} alignItems="center">
                <Grid item container md={12}>
                    <Grid item md={2}><Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: 'primary.light', width: '40px' }}><Description /></Box></Grid>
                    <Grid item md={8}>
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => push(sm.portals.buyer.procurement.requisitions.requisition(currentRequisition.id).url)}
                            variant="overline" fontWeight={700} textTransform='capitalize' >
                            {t('texts.requisition') + currentRequisition.name}
                        </Typography>
                        <Grid item container mt={-0.5}>
                            <Typography variant="subtitle2" color="text.third">{currentRequisition.products.length + " "}{t('texts.products_added')}</Typography>
                            <Typography variant="subtitle2" color="error" onClick={handleOpenDelPopup} sx={{ cursor: 'pointer', ml: 1 }}>
                                {t('buttons.delete')}
                            </Typography>
                            <ConfirmPopup
                                titleKey={t("popups.titles.delete_requisition")}
                                subTitleKey={t("popups.subTitles.delete_requisition")}
                                buttonTitleKey={t("buttons.delete")}
                                handleClose={handleCloseDelPopup}
                                handleConfirm={removeProduct}
                                open={showDelPopup}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container md={2} justifyContent="flex-end" alignItems='center'>
                        <ArrowForwardIos sx={{ color: "text.third", height: '16px', cursor: 'pointer' }} onClick={closeSummaryDialog} />
                    </Grid>
                </Grid>
                {numberOfCurrentMainProducts != 0 && <>
                    <Grid item md={12} mb={2} mt={3}>
                        {canEditRequisition && <AddProductsToRequisitionButton handleClick={redirectToProductPage} />}
                    </Grid>

                    {currentRequisition.products.map(product => (
                        <ProductRow product={product} currentRequisition={currentRequisition} canEditRequisition={canEditRequisition} />
                    ))}
                </>}
            </Grid>
            {numberOfCurrentMainProducts == 0 && <Grid height="100%" container alignItems="center">
                <Grid item md={12} textAlign="center">
                    <Typography variant="body2" color="text.third" py={1}>
                        {t('texts.empty_requisition')}
                    </Typography>
                    {canEditRequisition && <AddProductsToRequisitionButton handleClick={redirectToProductPage} />}
                </Grid>
            </Grid>
            }
        </SideDialog>
    )
}

type ProductRowType = {
    product: RequisitionProduct
    currentRequisition: Requisition
    canEditRequisition: boolean
}
const ProductRow: React.VFC<ProductRowType> = ({ product, currentRequisition, canEditRequisition }) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const mainProduct = product.main_product
    const { updateRequisitionProductQuantity, removeMainProductFromRequisition } = useProcurementStore();
    function changeQuantity(quantity: number) {
        updateRequisitionProductQuantity(mainProduct, quantity)
    }
    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    const removeProduct = () => {
        removeMainProductFromRequisition(mainProduct)
        handleCloseDelPopup()
    }
    const redirectToProductDetailsPage = () => {
        // return push({ pathname: sm.products.productId(mainProduct?.category.parent.parent.name, mainProduct?.category.parent.name, mainProduct?.category.name, mainProduct?.name).url })
        return push({ pathname: sm.catalogue.products.productId(mainProduct?.name).url })
    }

    return (
        <>
            <Grid container item md={12} gap={2}>
                <Grid item container md={12} alignItems="center">
                    <Grid item md={10}><Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} variant="h6" fontWeight="700" color="text.disabled" onClick={redirectToProductDetailsPage}>{mainProduct.name}</Typography></Grid>
                    {canEditRequisition && <Grid item md={2} container justifyContent="flex-end">
                        <Box onClick={handleOpenDelPopup} width='24px' sx={{ display: 'flex', height: '100%', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', backgroundColor: 'warning.contrastText' }}>
                            <DeleteIcon />
                        </Box>
                        <ConfirmPopup
                            titleKey={t("popups.titles.remove_product")}
                            subTitleKey={t('popups.subTitles.remove_product')}
                            buttonTitleKey={t("buttons.remove")}
                            handleClose={handleCloseDelPopup}
                            handleConfirm={removeProduct}
                            open={showDelPopup}
                        />
                    </Grid>}
                </Grid>
                <Grid item container md={12}>
                    <Grid item md={3}>
                        {canEditRequisition && <TextFieldCounter
                            value={product.quantity}
                            changeQuantity={changeQuantity}
                            product_id={mainProduct.id}
                            requisition_id={currentRequisition.id}
                            isAddedToRequisition={true}
                        />}
                    </Grid>
                    <Grid container justifyContent="flex-end" md={9}><Typography variant="subtitle2" color="text.third" textTransform={'lowercase'}>{product.alternative_products?.length + " "}{t('buttons.alternatives')}</Typography></Grid>
                </Grid>
                <Grid item md={12} pb={1}>
                    <Divider sx={{ borderColor: 'info.contrastText', opacity: 0.5 }} />
                </Grid>
            </Grid>
        </>
    )
}