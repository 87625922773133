import { ProductManagerEnv } from '@/config';

import { Product } from '@/models';
import PurchaseOrderSavingServices from '../purchase-orders';
import QuoteSavingServices from '../quotes';
import CatalogSavingServices from '../catalog';
import EditQuoteFromScratchSavingServices from '../edit_quote_from_scratch';
import EditPoFromScratchSavingServices from '../edit_po_from_scratch';

type EnvChangePriceServiceMapperParams = {
  env: ProductManagerEnv;
  formId: number;
  product: Product;
  newPrice: number;
};

const EnvChangePriceServiceMapper = ({ env, formId, product, newPrice }: EnvChangePriceServiceMapperParams) => {
  if (env === ProductManagerEnv.purchase_order) {
    return () =>
      PurchaseOrderSavingServices.changePrice({
        purchase_order_id: formId,
        product_id: product.id,
        price: newPrice,
      });
  } else if (env === ProductManagerEnv.quote) {
    return () =>
      QuoteSavingServices.changePrice({
        quote_id: formId,
        product_id: product.id,
        price: newPrice,
      });
  } else if (env === ProductManagerEnv.catalog) {
    return () =>
      CatalogSavingServices.changePrice({
        catalog_id: formId,
        product_id: product.id,
        price: newPrice,
      });
  } else if (env === ProductManagerEnv.edit_quote_from_scratch) {
    return () => EditQuoteFromScratchSavingServices.changePrice();
  } else if (env === ProductManagerEnv.edit_po_from_scratch) {
    return () => EditPoFromScratchSavingServices.changePrice();
  }
  return null;
};

export default EnvChangePriceServiceMapper;
