import { ProcurementForms, apiRoutes } from "@/config"
import { request } from "@/helpers"
import { ComparisonTablesData } from "@/models"

export enum CTStatus {
    DRAFT = 'DRAFT',
    READY = 'READY',
    PO_GENERATED = 'PO_GENERATED',
}

export enum CTFormPermission {
    GENERATE_PO = 'generate_PO',
    VIEW_PO = 'VIEW_PO',
}

export function getCTFormActions(ct: ComparisonTablesData, hasPermission: boolean): Record<CTFormPermission, boolean> {
    const formActions: Record<CTFormPermission, boolean> = {
        [CTFormPermission.GENERATE_PO]: hasPermission && ct?.can_generate_po,
        [CTFormPermission.VIEW_PO]: ct.status == CTStatus.PO_GENERATED
    };
    return formActions;
}

export const useGetCT = () => {
    return request({ url: apiRoutes.indexComparisonTables, method: "GET" })
}

export const useGetCTDetails = (ctId: number) => {
    return request({ url: apiRoutes.getCT + '/' + ctId, method: "GET" })
}

export const useGetShowPoCT = (poId: number | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.getEditCT + '/' + poId, method: "GET" })
}

export const useGetShowBundlePoCT = (bundlePoId: number | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.getEditCT + '/' + bundlePoId, method: "POST" })
}

export const useCreatePurchaseOrderFromCT = (comparisonTableId: number | undefined, comparisonProductSellerIds: (number | undefined)[] | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.createPurchaseOrderFromCT, method: "POST", data: { comparison_table_id: comparisonTableId, comparison_product_seller_ids: comparisonProductSellerIds } })
}

export const useUpdateCt = (ctId: number | undefined, relatedType: string, relatedId: number, selectedCtSellersIds: (number | undefined)[] | undefined) => {
    return request({ url: apiRoutes.buyerPurchaseOrder + apiRoutes.bundle + apiRoutes.update, method: "POST", data: { comparison_table_id: ctId, comparison_product_seller_ids: selectedCtSellersIds, related_id: relatedId, related_type: relatedType == ProcurementForms.BUNDLE_PO ? "PurchaseOrderBundle" : "PurchaseOrder" } })
}