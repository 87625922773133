import { useCommentsStore } from "@/zustand";
import { TextsmsOutlined } from "@mui/icons-material";
import { Badge, Box, Grid, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react";
import { SideDialog } from "src/ui/components/shared";
import { CommentsSideDialogContent } from "../comments-side-dialog-content";
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

type CommentsNumberProps = {
    title: string
    commentType: string
    commentId: string | number
    formDetails?: any
    custom?: boolean
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: `${theme.palette.success.dark}`,
        boxShadow: `0 0 0 1px ${theme.palette.success.dark}`
    },
}));


export const CommentsNumber: FC<CommentsNumberProps> = ({ title, commentId, commentType, formDetails, custom }) => {
    const { t } = useTranslation();
    const { numberOfComments, isSeenNewComments } = useCommentsStore(state => state.commentsStoreProperties)
    const { updateSeenNewComments, getCommentsNumberAndSeenValue } = useCommentsStore(state => state.commentsStoreActions)
    const [isCommentsDialogOpened, setIsCommentsDialogOpened] = useState<boolean>(false);
    const openCommentsDialog = () => { setIsCommentsDialogOpened(true), updateSeenNewComments(true) }
    const closeCommentsDialog = () => setIsCommentsDialogOpened(false)
    useEffect(() => { commentId && getCommentsNumberAndSeenValue(commentType, commentId) }, [formDetails])

    
    return (
        <>
            <Grid item md={custom ? 12 : 2} container justifyContent={custom ? 'center' : 'flex-end'} alignItems={custom ? 'center' : 'end'} pt={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={openCommentsDialog}>
                    <Typography variant="subtitle2" fontWeight={400} mr={0.5} color={custom ? 'primary.main' : 'text.third'} >
                        {!isSeenNewComments ? <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot">
                            <TextsmsOutlined sx={{ height: 20, color: custom ? 'primary.main' : 'text.third' }} />
                        </StyledBadge> : <TextsmsOutlined sx={{ height: 20, color: custom ? 'primary.main' : 'text.third' }} />
                        }
                    </Typography>
                    {/* <Typography variant="subtitle2" fontWeight={400} mr={1} color="text.third">{t('texts.comments')}:</Typography> */}
                    <Typography variant="subtitle2" fontWeight={custom ? 500 : 400} mr={1} sx={{ textTransform: 'lowercase', color: custom ? 'primary.main' : 'text.third' }}>{formDetails?.number_of_comments} {t('texts.comment')}</Typography>
                    {/* <Typography variant="subtitle2" fontWeight={custom ? 500 : 400} mr={1} sx={{ textTransform: 'lowercase', color: custom ? 'primary.main' : 'text.third' }}>{numberOfComments} {t('texts.comment')}</Typography> */}
                </Box>
            </Grid>
            <SideDialog sideDialogIsOpened={isCommentsDialogOpened} closeSideDialog={closeCommentsDialog}>
                <CommentsSideDialogContent isCommentsDialogOpened={isCommentsDialogOpened} closeCommentsDialog={closeCommentsDialog} title={title} commentType={commentType} commentId={commentId} />
            </SideDialog>
        </>
    )
}