import { procurementStatus } from "@/config";
import {
    ColumnFormat, ColumnSort,
    ColumnType, TableColumnMapper,
    TableGroup,
    TableMapper, TableRow, TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import { useIndexSellerPurchaseOrders } from "@/services";
import produce from "immer";
import { useUserData } from "src/logic/zustand/user";
import { StateCreator } from "zustand";
import { buildTableGroupModel } from "../../../../services/tables";

export interface PurchaseOrdersForSellerTableSlice {
    PurchaseOrderTableForSellerProperties: {
        isLoading: boolean,
        tableGroup: TableGroup | null,
    }
    PurchaseOrderTableForSellerActions: {
        loadData: () => void,
        onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
    }
}


export const createPurchaseOrderTableForSellerSlice: StateCreator<PurchaseOrdersForSellerTableSlice> = (set, get, api) => ({
    PurchaseOrderTableForSellerProperties: {
        isLoading: true,
        tableGroup: null,
    },
    PurchaseOrderTableForSellerActions: {
        onActionClicked: (row: TableRow, action: TableRowAction) => { },
        loadData: () => {
            const userInfo = useUserData.getState().userInfo
            set(produce(draftState => { draftState.isLoading = true }))
            useIndexSellerPurchaseOrders().then(data => {
                const purchaseOrders = data.data
                const tableGroup = buildTableGroupModel(purchaseOrders, getTableMapper(), getColumnMapper(), userInfo.id, false)
                set(produce(draftState => {
                    draftState.PurchaseOrderTableForSellerProperties.isLoading = false
                    draftState.PurchaseOrderTableForSellerProperties.tableGroup = tableGroup
                }))
            })
        },
    }
})

function getTableMapper() {
    return [
        new TableMapper(TableType.OTHER, 'PurchaseOrders', [
            new TabMapper('all', [procurementStatus.ALL], false),
            new TabMapper('PENDING', [procurementStatus.PO_RECEIVED], false),
            new TabMapper('CANCELED', [procurementStatus.CANCELED], false),
        ])
    ];
}

function getColumnMapper() {
    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        new TableColumnMapper('name', 'purchase_order', ColumnType.STRING, ColumnFormat.URL,
        ),
        new TableColumnMapper('buyer_details.name', 'buyer', ColumnType.STRING),
        new TableColumnMapper('project.name', 'project', ColumnType.STRING),
        dateColumn,
        new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}