import RequisitionProductAlternativesTable, {
  RequisitionProductAlternativesTableProps,
} from './RequisitionProductAlternativesTable';
import RequisitionServiceAlternativesTable, {
  RequisitionServiceAlternativesTableProps,
} from './RequisitionServiceAlternativesTable';

export {
  RequisitionProductAlternativesTable,
  RequisitionServiceAlternativesTable,
  type RequisitionProductAlternativesTableProps,
  type RequisitionServiceAlternativesTableProps,
};

// type RequisitionAlternativesTableProps =
//   | ({ category_kind: typeof ProductCategoryKind.PRODUCT } & RequisitionProductAlternativesTableProps)
//   | ({ category_kind: typeof ProductCategoryKind.SERVICE } & RequisitionServiceAlternativesTableProps);

// const RequisitionAlternativesTable = ({ category_kind, ...props }: RequisitionAlternativesTableProps) => {
//   if (category_kind === ProductCategoryKind.PRODUCT) {
//     return <RequisitionProductAlternativesTable {...(props as RequisitionProductAlternativesTableProps)} />;
//   }
//   if (category_kind === ProductCategoryKind.SERVICE) {
//     return <RequisitionServiceAlternativesTable {...(props as RequisitionServiceAlternativesTableProps)} />;
//   }
//   return null;
// };

// export default RequisitionAlternativesTable;
