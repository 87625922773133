import { useProfile,useProfilePasswordFlow } from '@/zustand';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { VerificationCodeFormByEmail } from '../shared-popups/send-verification-in-password-byEmail';
import { useCheckVerificationCode,useReRequestVerificationCodeAgain,useChangePasswordWithToken } from '@/services';
import { ChangePasswordByEmail } from '../shared-popups/change-password-ByEmail';
import { Success } from '../shared-popups/success';

type Props = {};


export const ProfilePasswordByEmailPopupContent: React.FC<Props> = () => {
    const { t } = useTranslation();

    const setChangePasswordByEmailSteps = useProfilePasswordFlow(state => state.setChangePasswordByEmailSteps);
    const PasswordPopupByEmailSteps = useProfilePasswordFlow(state => state.PasswordPopupByEmailSteps);
    const user_info = useProfile(state => state.user_info);
    const setChangePasswordSteps = useProfilePasswordFlow(state => state.setChangePasswordSteps);
    const togglePasswordByEmailPopup = useProfilePasswordFlow(state => state.togglePasswordByEmailPopup);
    const togglePasswordPopup = useProfilePasswordFlow(state => state.togglePasswordPopup);
    const setPasswordByCurrentFinish = useProfilePasswordFlow(state => state.setPasswordByCurrentFinish);
    
    const sendVerificationCodeByEmail = useCheckVerificationCode();
    const handleSubmitVerificationByEmail = (values, actions) => { sendVerificationCodeByEmail.mutate(values) }

    const getCodeByEmailAgain = useReRequestVerificationCodeAgain();
    const handleGetCodeByEmailAgain = () => { getCodeByEmailAgain.mutate("EMAIL") }
    
    const submitChangePasswordWithToken = useChangePasswordWithToken();
    const handleSubmitChangePasswordWithToken = (values, actions) => {{ submitChangePasswordWithToken.mutate(values) }}
    

    const changePassword = [
        <VerificationCodeFormByEmail 
            isDisabledButton={sendVerificationCodeByEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_email'))}
            userInfo={user_info?.email}
            handleClickNext={handleSubmitVerificationByEmail}
            handleClickBack={()=> {togglePasswordByEmailPopup(false),togglePasswordPopup(true),setChangePasswordSteps(2),setPasswordByCurrentFinish(false)}}
            handleClickAgain={handleGetCodeByEmailAgain}
        />,
        <ChangePasswordByEmail
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_password'))}
            isDisabledButton={submitChangePasswordWithToken.isLoading}
            handleClickNext={handleSubmitChangePasswordWithToken}
            handleClickBack={()=> setChangePasswordByEmailSteps(PasswordPopupByEmailSteps-1)}
        />,
        <Success 
            paragraphTranslate={t('notifications.change_password_success')}
            togglePopup={() => {togglePasswordPopup(false);togglePasswordByEmailPopup(false)}}
        />
    ]
    return (
        <>
            {changePassword[PasswordPopupByEmailSteps]}
        </>
    );
};