import { SvgIconProps, SvgIcon } from '@mui/material';

export const DashboardIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='4.39922'
      y='4.4002'
      width='8.8'
      height='5.6'
      stroke={props.color}
      strokeWidth='2.4'
      fill='none'
    />
    <rect
      x='26.8'
      y='26.8'
      width='8.8'
      height='5.6'
      transform='rotate(-180 26.8 26.8)'
      stroke={props.color}
      strokeWidth='2.4'
      fill='none'
    />
    <rect
      x='4.39922'
      y='14.8001'
      width='8.8'
      height='12'
      stroke={props.color}
      strokeWidth='2.4'
      fill='none'
    />
    <rect
      x='26.8'
      y='16.4001'
      width='8.8'
      height='12'
      transform='rotate(-180 26.8 16.4001)'
      stroke={props.color}
      strokeWidth='2.4'
      fill='none'
    />
  </SvgIcon>
);
