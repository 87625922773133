import { FilterFn, flexRender, SortingFn } from '@tanstack/react-table'

export const arrayFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const rowsToBeFiltered = row.getValue(columnId)
  return rowsToBeFiltered.includes(value);
}

export const sortArray: SortingFn = (rowA, rowB, id, desc) => {
  // if (!rowA.getValue(id)[0] ||  < )
  if(!rowA.getValue(id)[0]) return -1
  if(!rowB.getValue(id)[0]) return 1
  return rowA.getValue(id)[0].localeCompare(rowB.getValue(id)[0]);
  // if (!rowB.getValue(id)[0] || rowB.getValue(id)[0] < rowA.getValue(id)[0]) return 1;
  // return 0;
}

export const sortNumber: SortingFn = (rowA, rowB, id, desc) => {
  let firstValue = rowA.getValue(id).columns.find(column => column.name == id).description
  let secondValue = rowB.getValue(id).columns.find(column => column.name == id).description

  if(!desc)
    return firstValue - secondValue
  return secondValue - firstValue
}

export const sortString: SortingFn = (rowA, rowB, id, desc) => {
  let firstValue = rowA.getValue(id).columns.find(column => column.name == id).description.toLowerCase()
  let secondValue = rowB.getValue(id).columns.find(column => column.name == id).description.toLowerCase()
  if(!desc)
    return firstValue == secondValue ? 0 : firstValue < secondValue ? -1 : 1
  return firstValue == secondValue ? 0 : firstValue < secondValue ? 1 : -1
}

//this needs to be refactored ... assumption here is the format is dd-mm-yyyy
export const sortDate: SortingFn = (rowA, rowB, id, desc) => {
  let firstValue = rowA.getValue(id).columns.find(column => column.name == id).description
  let secondValue = rowB.getValue(id).columns.find(column => column.name == id).description

  const array1 = firstValue.split('-');
  firstValue = new Date(+array1[2], +array1[1] - 1, +array1[0]);

  const array2 = secondValue.split('-');
  secondValue = new Date(+array2[2], +array2[1] - 1, +array2[0]);

  if(!desc)
    return firstValue.getTime() - secondValue.getTime()
  return secondValue.getTime()  - firstValue.getTime()
}