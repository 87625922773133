import {
    ColumnFormat, ColumnSort,
    ColumnType,
    TableColumnMapper,
    TableGroup,
    TableMapper,
    TableRow,
    TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import { StateCreator } from "zustand";
import produce from "immer";
import { useGetCT } from "../../../services/procurement/comparison-table";
import { procurementStatus } from "@/config";
import { useUserData } from "@/zustand";
import { buildTableGroupModel } from "../../../services/tables";

export interface CTTableSlice {
    ctTableSliceProperties: {
        data: {
            tableGroup: TableGroup | null,
            isLoading: boolean,
        },
        actions: {
            loadData: () => void,
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
        }
    },
}


export const createCTTableSlice: StateCreator<CTTableSlice> = (set, get, api) => ({
    ctTableSliceProperties: {
        data: {
            tableGroup: null,
            isLoading: true,
        },
        actions: {
            loadData: () => {
                set(produce(draftState => {
                    draftState.ctTableSliceProperties.data.isLoading = true
                }))

                useGetCT().then(data => {
                    const userId = useUserData.getState().userInfo?.id
                    const tableGroup = buildTableGroupModel(
                        data.data, ctTableSectionsMapper(), ctTableMapper(), userId, false,
                    )
                    set(produce(draftState => {
                        draftState.ctTableSliceProperties.data.isLoading = false
                        draftState.ctTableSliceProperties.data.tableGroup = tableGroup
                    }))
                })
            },
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => { }
        }
    }
})

export const ctTableSectionsMapper = () => {
    return [
        new TableMapper(TableType.MINE, 'My Comparison Tables', [
            new TabMapper('all', [procurementStatus.ALL]),
        ]),
        new TableMapper(TableType.OTHER, 'Others Comparison Tables', [
            new TabMapper('all', [procurementStatus.ALL], true),
        ])
    ];
}

export const ctTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'comparison_table', ColumnType.STRING, ColumnFormat.URL,
        new TableColumnMapper('created_at', 'date', ColumnType.STRING)
    );

    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)
    dateColumn.hidden = true

    // const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER)

    const detailsColumn = new TableColumnMapper('requisition_name', 'details', ColumnType.STRING, null,
        new TableColumnMapper('project_name', 'details', ColumnType.STRING)
    );

    const ownerColumn = new TableColumnMapper('owner_name', 'owner', ColumnType.STRING)
    const receivedQuotesColumn = new TableColumnMapper('received_quotes_count', 'received', ColumnType.NUMBER)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        nameColumn,
        detailsColumn,
        ownerColumn,
        new TableColumnMapper('competitors', 'products', ColumnType.NUMBER),
        new TableColumnMapper('competitors', 'competitors', ColumnType.NUMBER),
        receivedQuotesColumn,
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}