import { StyledPopper } from '@/components';
import { Seller } from '@/models';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Divider, Grid, SxProps, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

type SellersListProps = {
  items: Seller[] | undefined;
  length: number | undefined;
  typographySx?: SxProps;
};

export const SellersList: React.VFC<SellersListProps> = ({ items, length, typographySx = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopper = () => setAnchorEl(null);
  return (
    <>
      <Typography
        fontSize={13}
        fontWeight={400}
        sx={{
          bgcolor: '#EAEEF6',
          borderRadius: '4px',
          maxWidth: 'fit-content',
          padding: '.3rem .7rem',
          ...typographySx,
        }}
        color={'#13628C'}
        onMouseOver={handleOpenPopper}
        onMouseOut={handleClosePopper}
      >
        {length} Seller{length !== 1 ? 's' : ''}
      </Typography>
      {items && (
        <SellersListPopup items={items} open={open} anchorEl={anchorEl} handleClosePopper={handleClosePopper} />
      )}
    </>
  );
};

export const SellersListPopup = ({
  items,
  open,
  anchorEl,
  handleClosePopper,
}: {
  items: Seller[];
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClosePopper: () => void;
}) => {
  return (
    <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
      <div style={{ maxWidth: '450px' }}>
        <Grid container md={12} p={3}>
          <BadgeIcon sx={{ fontSize: '22px !important', color: 'primary.main' }} />

          <Typography variant='h6' color='primary.main' fontWeight={700} pl={1} pb={2}>
            Sellers
          </Typography>

          {items?.map((item: Seller, index: number) => (
            <React.Fragment key={item.seller.id}>
              <Grid container md={12} alignItems={'center'} component={Typography} pt={1} spacing={1}>
                <Grid item container md={8}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'text.disabled',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.seller?.name}
                    </Typography>
                    {item.is_private && (
                      <Box sx={{ flexShrink: 0, ml: 1 }}>
                        <PrivacyButton
                          disableText
                          content={
                            'This seller profile is private because it was created and/or linked by your company.'
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item md={4} sx={{ textAlign: 'end' }}>
                  <Typography variant='body2' color='text.disabled' fontWeight={400}>
                    {item.price ? t('texts.aed') + item.price : 'No List Price'}
                  </Typography>
                </Grid>
              </Grid>

              {index !== items.length - 1 && (
                <Grid item md={12}>
                  <Divider sx={{ opacity: 0.7, pb: 0.5 }} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </StyledPopper>
  );
};
