import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { useProcurementStore } from '@/zustand';
import { sm } from 'src/site-map';
import { RedirectPopup } from 'src/ui/components/shared';


type Props = {
    title: string
    openPopup: boolean
    icon: ReactNode
    subTitle?: string
    colorTitle?: string
};


export const RequestForQuoteRedirectPopup: FC<Props> = ({ title, openPopup, icon, subTitle, colorTitle }) => {

    const { t } = useTranslation();
    const setSuccessPopupStatus = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceActions.setSuccessPopupStatus)
    const handleClosePopup = () => setSuccessPopupStatus(null)
    return (
        <RedirectPopup
            openPopup={openPopup}
            title={title}
            subTitle={subTitle}
            handleClosePopup={handleClosePopup}
            buttonTitle={t('buttons.view_rfq')}
            redirectButtonTitle={t('buttons.my_RFQs')}
            redirectURL={sm.portals.buyer.procurement.requestForQuotes.index.url}
            colorTitle={colorTitle}
            icon={icon}
        />

    );
};
