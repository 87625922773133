import { ProcurementFormModel, ProductTableRow } from '@/models';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { formatNumber } from 'src/logic/utils/numbers';

type Props = { po: ProcurementFormModel | null; companyDetails: any; productsTableRows: ProductTableRow[] };

const getFormatedNumber = (value: string | number | null | undefined) => {
  if (value === null || value === undefined || isNaN(value)) return "";
  if (typeof value === "number") return formatNumber(value);
  return formatNumber(parseFloat(value) ?? '');
}

export const SinglePODocument: React.VFC<Props> = ({ po, companyDetails, productsTableRows }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size='A4' style={styles.page} key={po?.model?.id}>
        <View>
          <Text style={styles.title}>{po?.model?.name}</Text>
        </View>
        <View style={[styles.section, { marginTop: '12px' }]}>
          <Text style={styles.smallBlue}> {t('texts.date_created')}: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{po?.model?.created_at}</Text>
          <Text style={styles.smallBlue}>{t('texts.number_of_products')} </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{po?.numberOfProducts} </Text>
          <Text style={styles.smallBlue}>Internal Project: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{po?.model?.sender_project?.name}</Text>
          {po?.model?.receiver_project?.name && (
            <>
              <Text style={styles.smallBlue}>Seller Project: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{po?.model?.receiver_project?.name}</Text>
            </>
          )}
          {po?.model?.rfq && (
            <>
              <Text style={styles.smallBlue}>{t('table_cells.rfq')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{po?.model?.rfq?.name}</Text>
            </>
          )}
        </View>
        <View style={[styles.section, { marginTop: '15px', width: '85%' }]}>
          <View>
            <Text style={styles.mediumTitle}>{po?.model?.name}</Text>
          </View>
        </View>
        <Product model={po?.model} companyDetails={companyDetails} />
        <View style={{ width: '90%', marginTop: '20px' }}>
          {productsTableRows?.map((product, index) => {
            return (
              <ProductCard
                product={product}
                index={index}
                key={index}
                warrantyTermGeneral={po?.model?.terms_bundle?.warranty_term?.name}
              />
            );
          })}
        </View>

        <View style={{ height: '3%' }} fixed />

        <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
          <View></View>
          <View>
            <Text style={styles.bigWithBold}>{t('texts.order_summary')}</Text>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallBlue}> {t('texts.sub_total')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(po?.model?.subtotal_value)}</Text>
            </View>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallBlue}> {t('texts.vat')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(po?.model?.vat_value)}</Text>
            </View>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallWithBold}>{t('texts.total')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(po?.model?.total_value)}</Text>
            </View>
          </View>
        </View>
        <View style={{ height: '10%' }} fixed />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            t('texts.page_number', { page_number: pageNumber, pages_length: totalPages })
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const Product = ({ model, companyDetails }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
      <View>
        <Text style={styles.bigBlue}>{companyDetails?.name}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.fax')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.fax_number ? companyDetails?.fax_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.phone')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.phone_number ? companyDetails?.phone_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.address')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.company_address ? companyDetails?.company_address : t('texts.n_a')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={styles.bigBlue}>{t('popups.titles.general_terms')}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.payment_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.payment_term.name ? model?.terms_bundle?.payment_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.warranty_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.warranty_term.name ? model?.terms_bundle?.warranty_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.delivery_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.delivery_term.name ? model?.terms_bundle?.delivery_term.name : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ProductCard = ({ product, index, warrantyTermGeneral }) => {
  const { t } = useTranslation();
  return (
    <>
      {product && (
        <View
          style={[
            index % 2 == 0 ? styles.section : styles.blueSection,
            { height: '53px', display: 'flex', alignItems: 'center' },
          ]}
        >
          <View>
            <Image
              style={styles.smallImage}
              src={product?.productDetails?.image_url + '?noCache=' + Math.random().toString()}
              source={{
                header: {
                  'Access-Control-Allow-Origin': '*'
                }
              }}
            />
          </View>
          <View style={styles.productDetails}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.smallTitle}>
                {product?.productDetails?.name} {product?.productDetails?.is_private_product ? '  (Private)' : ''}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: '10px' }}>
              <ProductDetails
                title={t('texts.segment')}
                value={product?.productDetails?.category_kind == 1 ? 'product' : 'service'}
              />
              <ProductDetails
                title={t('texts.brand')}
                value={product?.productDetails?.brand.name ? product?.productDetails?.brand.name : 'N/A'}
              />
              <ProductDetails
                title={t('table_cells.warranty')}
                value={product?.warrantyTerm?.name ? product?.warrantyTerm?.name : warrantyTermGeneral}
              />
              <ProductDetails
                title={t('texts.part_number')}
                value={product?.productDetails?.part_number ? product?.productDetails?.part_number : 'N/A'}
              />
              <ProductDetails
                title={t('table_cells.ordered_qty')}
                value={product?.requestedQuantity ? product?.requestedQuantity : 'N/A'}
              />
              <ProductDetails
                title={t('table_cells.unit_price')}
                value={product?.unitPrice ? product?.unitPrice : 'N/A'}
              />
              <ProductDetails title={t('table_cells.remarks')} value={product?.remark ? product?.remark : 'N/A'} />
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const ProductDetails = ({ title, value }) => {
  return (
    <View style={{ marginRight: '30px', marginHorizontal: '5px' }}>
      <Text style={[styles.smallGrey, { marginBottom: '2px' }]}>{title}</Text>
      <Text style={styles.smallBlack}>{value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    margin: '30px',
  },
  section: {
    flexDirection: 'row',
    width: '535px',
  },
  blueSection: {
    backgroundColor: '#EAEEF6',
    flexDirection: 'row',
    width: '535px',
  },
  spaceBetweenSection: {
    flexDirection: 'row',
    width: '240px',
    justifyContent: 'space-between',
  },
  bigImageSection: {
    width: '90px',
  },
  smallImageSection: {
    width: '61px',
  },
  productDetails: {
    width: '474px',
  },
  title: {
    color: '#00385E',
    fontSize: '18px',
    fontWeight: 800,
    width: '90%',
  },
  mediumTitle: {
    color: '#13628C',
    fontSize: '12px',
    fontWeight: 600,
  },
  smallTitle: {
    color: '#13628C',
    fontSize: '8px',
    fontWeight: 500,
    width: '294px',
  },
  smallBlue: {
    color: '#00385E',
    fontSize: '6px',
    fontWeight: 400,
  },
  smallWithBold: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 700,
  },
  bigBlue: {
    color: '#00385E',
    fontSize: '9px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 2,
  },
  bigWithBold: {
    color: '#3A3E50',
    fontSize: '9px',
    fontWeight: 700,
    marginTop: 4,
    marginBottom: 2,
  },
  smallGrey: {
    color: '#8B8D98',
    fontSize: '6px',
    fontWeight: 400,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 50,
    marginBottom: 2,
    left: 0,
    right: 0,
    fontSize: '6px',
    fontWeight: 400,
    color: '#8B8D98',
  },
  smallBlack: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigImage: {
    width: '61px',
    height: '61px',
  },
  smallImage: {
    width: '35px',
    height: '35px',
    margin: '5px',
    marginRight: '10px',
  },
});
