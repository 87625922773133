import { ProcurementFormModel, RFQDetails } from '@/models';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'next-i18next';
import React from 'react';

type Props = { rfq: ProcurementFormModel | RFQDetails; rfqModel: any };

export const SingleRfqPDFDocument: React.VFC<Props> = ({ rfq, rfqModel }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size='A4' style={styles.page} key={rfqModel?.id}>
        <View>
          <Text style={styles.title}>{rfqModel?.name}</Text>
        </View>
        <View style={[styles.section, { marginTop: '12px' }]}>
          <Text style={styles.smallBlue}> {t('texts.date_created')}: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.created_at}</Text>
          <Text style={styles.smallBlue}>Internal Project: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.sender_project?.name}</Text>

          {rfqModel?.receiver_project?.name && (
            <>
              <Text style={styles.smallBlue}>Seller Project: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.receiver_project?.name}</Text>
            </>
          )}
          <Text style={styles.smallBlue}>{t('texts.number_of_products')} </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.number_of_products} </Text>
          <Text style={styles.smallBlue}>{t('texts.number_of_services')} </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.number_of_services} </Text>
          {rfqModel?.bundle && (
            <>
              <Text style={styles.smallBlue}>{t('texts.bundleRFQ')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{rfqModel?.bundle?.name}</Text>
            </>
          )}
        </View>
        <View style={[styles.section, { marginTop: '15px', width: '85%' }]}>
          <View>
            <Text style={styles.mediumTitle}>{rfqModel?.name}</Text>
          </View>
        </View>
        <Product model={rfqModel} />
        <View style={{ width: '90%', marginTop: '20px' }}>
          {rfqModel?.products.map((product, index) => {
            return <ProductCard product={product} index={index} key={index} />;
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            t('texts.page_number', { page_number: pageNumber, pages_length: totalPages })
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const Product = ({ model }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
      <View>
        <Text style={styles.bigBlue}>{model?.seller_details?.name}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.fax')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.seller_details?.fax_number ? model?.seller_details?.fax_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.phone')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.seller_details?.phone_number ? model?.seller_details?.phone_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.address')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.seller_details?.company_address ? model?.seller_details?.company_address : t('texts.n_a')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={styles.bigBlue}>{t('popups.titles.general_terms')}</Text>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.payment_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.payment_term.name ? model?.terms_bundle?.payment_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.warranty_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.warranty_term.name ? model?.terms_bundle?.warranty_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.delivery_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle?.delivery_term.name ? model?.terms_bundle?.delivery_term.name : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ProductCard = ({ product, index }) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        index % 2 == 0 ? styles.section : styles.blueSection,
        { height: '53px', display: 'flex', alignItems: 'center' },
      ]}
    >
      <View>
        <Image
          style={styles.smallImage}
          src={product.image_url + '?noCache=' + Math.random().toString()}
          source={{
            header: {
              'Access-Control-Allow-Origin': '*'
            }
          }}
        />
      </View>
      <View style={styles.productDetails}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.smallTitle}>
            {product?.product?.name} {product?.product?.is_private_product ? '  (Private)' : ''}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: '10px' }}>
          <ProductDetails
            title={t('texts.segment')}
            value={product?.product?.category_kind == 1 ? 'product' : 'service'}
          />
          <ProductDetails
            title={t('texts.brand')}
            value={product?.product?.brand.name ? product?.product?.brand.name : 'N/A'}
          />
          <ProductDetails
            title={t('texts.part_number')}
            value={product?.product?.part_number ? product?.product?.part_number : 'N/A'}
          />
          <ProductDetails
            title={t('table_cells.warranty')}
            value={product?.warranty_term?.name ? product?.warranty_term?.name : 'N/A'}
          />
        </View>
      </View>
      <View style={{ alignSelf: 'flex-start' }}>
        <Text style={{ fontSize: '8px', margin: '5px' }}>{product?.quantity ? product?.quantity : 0} x</Text>
      </View>
    </View>
  );
};

const ProductDetails = ({ title, value }) => {
  return (
    <View style={stylesProductDetail.productDetail}>
      <Text style={[styles.smallGrey, { marginBottom: '2px' }]}>{title}</Text>
      <Text style={styles.smallBlack}>{value}</Text>
    </View>
  );
};

const stylesProductDetail = StyleSheet.create({
  productDetail: {
    marginRight: '30px',
    marginHorizontal: '5px',
    flex: 1,
    alignItems: 'flex-start',
  },
});

const styles = StyleSheet.create({
  page: {
    margin: '30px',
  },
  section: {
    flexDirection: 'row',
    width: '535px',
  },
  blueSection: {
    backgroundColor: '#EAEEF6',
    flexDirection: 'row',
    width: '535px',
  },
  spaceBetweenSection: {
    flexDirection: 'row',
    width: '240px',
    justifyContent: 'space-between',
  },
  bigImageSection: {
    width: '90px',
  },
  smallImageSection: {
    width: '61px',
  },
  productDetails: {
    width: '474px',
  },
  title: {
    color: '#00385E',
    fontSize: '18px',
    fontWeight: 800,
    width: '90%',
  },
  mediumTitle: {
    color: '#13628C',
    fontSize: '12px',
    fontWeight: 600,
  },
  smallTitle: {
    color: '#13628C',
    fontSize: '8px',
    fontWeight: 500,
    width: '294px',
  },
  smallBlue: {
    color: '#00385E',
    fontSize: '6px',
    fontWeight: 400,
  },
  smallWithBold: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 700,
  },
  bigBlue: {
    color: '#00385E',
    fontSize: '9px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 2,
  },
  bigWithBold: {
    color: '#3A3E50',
    fontSize: '9px',
    fontWeight: 700,
    marginTop: 4,
    marginBottom: 2,
  },
  smallGrey: {
    color: '#8B8D98',
    fontSize: '6px',
    fontWeight: 400,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 50,
    marginBottom: 2,
    left: 0,
    right: 0,
    fontSize: '6px',
    fontWeight: 400,
    color: '#8B8D98',
  },
  smallBlack: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigImage: {
    width: '61px',
    height: '61px',
  },
  smallImage: {
    width: '35px',
    height: '35px',
    margin: '5px',
    marginRight: '10px',
  },
});
