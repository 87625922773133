export enum OwnerBundleRFQStatus {
    DRAFT = "DRAFT",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
    PARTIALLY_SENT_TO_SELLERS = "PARTIALLY_SENT_TO_SELLERS"
}
export enum ApproverBundleRFQStatus {
    PENDING_MY_APPROVAL = "PENDING_MY_APPROVAL",
    APPROVED_BY_ME = "APPROVED_BY_ME",
    REJECTED_BY_ME = "REJECTED_BY_ME",
    PARTIALLY_APPROVED_BY_ME = "PARTIALLY_APPROVED_BY_ME",
    CANCELED = "CANCELED"
}

export enum PopupStatus {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    APPROVE_ALL = "APPROVE_ALL",
    REJECT_ALL = "REJECT_ALL",
    SENT_TO_SELLERS = "SENT_TO_SELLERS",
    SENT_TO_SELLER = "SENT_TO_SELLER",
    CANCEL_BUNDLE = 'CANCEL_BUNDLE',
    CANCEL_RFQ = 'CANCEL_RFQ'
}

export enum CommentsGroupNames {
    Internal = "Internal",
    External = "External"
}

export enum DonePopupStatus {
    CANCEL_BUNDLE = 'CANCEL_BUNDLE',
    CANCEL_RFQ = 'CANCEL_RFQ',
    CANCEL_QUOTE = 'CANCEL_QUOTE',
    APPROVE_RFQ = 'APPROVE_RFQ',
    REJECT_RFQ = 'REJECT_RFQ'
}