import { ProcurementButtons, QuotePopupStatus } from "@/config";
import { QuoteDetails } from "@/models";
import { NotifyError } from "@/services";
import { useSellerProcurementStore } from "@/zustand";
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import { Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { FC } from "react";
import { DescPopup, SendToOtherCompany } from "src/ui/components/shared";

type SendQuoteToBuyerProps = {
    quote: QuoteDetails
    OpenSendToBuyerPopup: (values: any) => void
    showAfterSendToBuyerPopup: boolean
    openedPopupStatus: QuotePopupStatus | null
    onRequested?: () => void
}

export const SendQuoteToBuyer: FC<SendQuoteToBuyerProps> = ({ OpenSendToBuyerPopup, openedPopupStatus, quote, onRequested }) => {
    const { t } = useTranslation();
    // const { push } = useRouter()
    const { customLoadingButton, productsTableRows } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)
    const { sendQuoteToBuyer, setPopupStatus } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const quoteModel = quote?.quoteModel

    const handleClosePopup = () => setPopupStatus(null)
    const handleSendToBuyer = (values: any) => sendQuoteToBuyer(quoteModel?.id, values.validity_date, values.comment)
    // const closeAfterSendToBuyerPopup = () => { setShowAfterSendToBuyerPopup(false) };
    // const redirectToQuotesTable = () => {
    //     closeAfterSendToBuyerPopup()
    //     push({ pathname: sm.portals.seller.procurement.quotes.index.url })
    // }

    const sendToBuyerHandler = () => {
        onRequested?.();
        
        if(quote?.quoteModel?.terms_bundle == null) {
            NotifyError(t('texts.general_terms_required'))
            return;
        }

        const isMissingPrice = quoteModel?.quote_products.some(el => !el.unit_price);
        if(isMissingPrice) {
            NotifyError(t('notifications.missing_field', { fieldName: 'price' }))
            return;
        }

        OpenSendToBuyerPopup();
    }

    return (
        <>
            {quote?.canSendQuoteToBuyer && <Button onClick={sendToBuyerHandler} variant="contained" sx={{ mr: 1 }} color={'primary'}>{t('buttons.send_quote_to_buyer')}</Button>}

            {/* send to buyer popup */}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == QuotePopupStatus.SENT_TO_BUYER}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary.main' />}
                    popupTitle={t('buttons.send_to_buyer')}
                    validTitle={t('texts.quote') + " " + t('texts.valid_until')}
                    buttonTitle={t('buttons.send_to_buyer')}
                    handlePopupAction={handleSendToBuyer}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_QUOTE_TO_BUYER}
                />
            </DescPopup>

            {/* send to buyer popup done */}
            {/* <DescPopup open={showAfterSendToBuyerPopup} handleClose={closeAfterSendToBuyerPopup}>
                <Grid container md={12} gap={2} width={300} p={2}>
                    <Grid item md={12} textAlign='center'>
                        <CheckCircleOutline sx={{ color: 'success.dark', fontSize: '40' }} />
                        <Grid item md={12} textAlign='center'>
                            <Typography variant='overline' color='success.dark' fontWeight={700} my={1}>
                                {t('popups.titles.quote_sent_to_buyer')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Button variant="outlined" sx={{ color: 'text.disabled', fontWeight: 500 }} fullWidth onClick={closeAfterSendToBuyerPopup}>{t('buttons.back_to_quote')}</Button>
                    </Grid>
                    <Grid item md={5}>
                        <Button variant="contained" fullWidth onClick={redirectToQuotesTable}>{t('buttons.my_quotes')}</Button>
                    </Grid>
                </Grid>
            </DescPopup> */}
        </>
    )
} 