export * from './home';
export * from './product';
export * from './become-a-seller';
export * from './career';
export * from './contact-us';
export * from './login';
export * from './register';
export * from './header';
export * from './footer';
export * from './inputs';
export * from './requisition';
export * from './account';
export * from './users';
export * from './procurement'