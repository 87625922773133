import { Loading, MainProductCardButtons, ProductCard, StyledPagination } from '@/components';
import { RequisitionProductType } from '@/config';
import { getProductTranslationKey, useAnimateOnView } from '@/hooks';
import { FilterItem, PaginationResult, Product } from '@/models';
import { ResponsiveContainer } from '@/styles';
import { useRequisition } from '@/zustand';
import { Box, Grid, MenuItem, Stack, Typography, useMediaQuery, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { RequisitionPopup } from 'src/ui/components/dumb/requisition/requisition-popup';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef } from 'react';

const smallBreakPoints = {
  lg: 12,
  xs: 12,
} as const;

type PropsFilterPathDetails = {
  FilterPathDetails: {
    categoryTitle: string;
    classTitle: string;
    title: string;
    updatedTitle: string;
    type_id?: string;
    product_id?: string;
  };
};

type Props = {
  products: Product[];
  sortData: FilterItem[];
  paginationResult: PaginationResult;
  waitProducts: boolean;
  canPerformActionRequisition: boolean
  canPerformActionPO: boolean
  waitFilters: boolean;
  onSortItemSelected: Function;
  FilterPathDetails: PropsFilterPathDetails;
  onPageNumberChange: Function,

};
export const MainPage: React.FC<Props> = ({
  FilterPathDetails,
  children,
  products,
  sortData,
  waitFilters,
  waitProducts,
  onSortItemSelected,
  onPageNumberChange, paginationResult,
  canPerformActionRequisition,
  canPerformActionPO
}) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  const toggleReqMainPro = useRequisition(state => state.toggleReqMainPro);
  const router = useRouter();
  const newReqId = new Date().getTime();
  const newtabId = new Date().getTime();
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });

  const { t } = useTranslation();

  return (
    <Grid sx={{ paddingBottom: 10, bgcolor: 'background.primary.main' }}>
      {waitFilters ? (
        <Loading fullHeight={true} />
      ) : (
        <Grid component={ResponsiveContainer} {...smallBreakPoints}>
          <Grid
            item
            order={[1, 1, 1, 0]}
            mb={1}
            sx={{
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                maxHeight: 550,
                zIndex: -1,
              },
            }}
          >
            {children}
            <Box mt={isSmallScreen ? 2 : 3} mb={isSmallScreen ? 1 : 2}>
              <Typography
                component={motion.div}
                {...first}
                variant='h5'
                fontWeight='600'
                sx={{ textTransform: 'capitalize' }}
              >
                {t('pages_names.search_results')}
              </Typography>
            </Box>
            <Grid container lg={12} mt={-3}>
              <Grid item container lg={7} xs={12} order={[1, 1, 1, 0]} alignItems='center'>
                <Typography
                  component={motion.div}
                  {...second}
                  color='text.third'
                  fontWeight='400'
                  variant='body2'
                  mt={1}
                >
                  {paginationResult?.total}  {t(getProductTranslationKey('titles.products_found'))}
                </Typography>
              </Grid>
              <Grid container item lg={5} xs={12} justifyContent="flex-end">
                {!isSmallScreen && (
                  <SortSelect
                    onSortItemSelected={onSortItemSelected}
                    sortData={sortData}
                    paginationResult={paginationResult}
                    isMobile={false}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {waitProducts ? (<Loading fullHeight={true} />) : products?.map(product =>
            <ProductCard canPerformActionPO={canPerformActionPO} canPerformActionRequisition={canPerformActionRequisition} key={product.id} productDetails={product} productType={RequisitionProductType.main_product} ControlButtons={MainProductCardButtons} />)}
          <RequisitionPopup />
          {paginationResult?.last_page > 1 && (
            <Box>
              <Box
                sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}
              >
                <StyledPagination
                  count={paginationResult?.last_page}
                  page={paginationResult?.current_page}
                  onChange={(event, value) => {
                    onPageNumberChange(value);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

 
const SortSelect: React.VFC = ({ onSortItemSelected, sortData }) => {
  if (!sortData || sortData.length === 0) {
    return null;
  }
  const { t } = useTranslation();
  const selectRef = useRef(null);

  const defaultSortValue = sortData?.find((el) => el.is_selected)?.id;

  const handleIconClick = () => {
    if (selectRef.current) {
      const event = new Event('mousedown', { bubbles: true });
      selectRef.current.dispatchEvent(event);
    }
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-standard-label">
        <Typography variant="body2" color="info.light" fontWeight="400">
          {' '}
          {t('texts.sort_by')}
        </Typography>
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        defaultValue={defaultSortValue}
        onChange={(event) => {
          onSortItemSelected(event.target.value);
        }}
        sx={{
          '& .MuiSelect-select': {
            backgroundColor: 'transparent !important',
            fontSize: '14px !important',
            fontWeight: 500,
            color: '#3A3E50 !important',
          },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        disableUnderline
        IconComponent={(props) => (
          <ExpandMoreIcon
            {...props}
            onClick={() => {
              handleIconClick();
              onSortItemSelected(defaultSortValue); 
            }}
            sx={{ fontSize: '20px !important', color: '#3A3E50 !important', cursor: 'pointer' }}
          />
        )}
        ref={selectRef}
      >
        {sortData.map((sortItem: any) => (
          <MenuItem key={sortItem.id} value={sortItem.id}>
            {sortItem.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


{/* const SortSelect: React.VFC = ({ onSortItemSelected, paginationResult, sortData, isMobile }) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <Stack spacing={1} direction='row' justifyContent='flex-end' height={40} alignItems="center">
      {!isMobile && (
        <Typography color='text.third' fontWeight='400'>
          {t('texts.sort_by')}
        </Typography>
      )}
      {sortData && (
        <Select
          disabled={paginationResult?.total <= 1}
          onChange={event => {
            onSortItemSelected(event.target.value);
          }}
          defaultValue={
            sortData?.filter(el => el.is_selected)[0].id
          }
          size='small'
          MenuProps={{ disableScrollLock: true }}
          sx={{
            minWidth: isSmallScreen ? '98%' : 200,
            maxWidth: '98%',
            borderWidth: '0.08px',
          }}
        >
          {sortData.map(sortItem => {
            return (
              <MenuItem key={sortItem.id} value={sortItem.id}>
                {sortItem.title}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </Stack>
  );
}; */}