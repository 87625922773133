import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Header } from '../registration-header';
import { ResponsiveContainer } from '@/styles';
import { useTranslation } from 'next-i18next';
import { getFooterTranslationKey } from '@/hooks';

const stickeyHeaderStyle = {
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 5,
}

export const RegistrationLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box component='header' {...stickeyHeaderStyle}>
        <Header />
      </Box>
      <main>{children}</main>
      <Box component='footer' sx={{ position: 'fixed', bottom: 0, width: '100%', bgcolor: 'action.disabledBackground', py: .5 }}>
        <Grid container component={ResponsiveContainer}>
          <Typography variant='body2' color='info.main'>
            {new Date().getFullYear()}  {t(getFooterTranslationKey('titles.rights_reserved'))}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};
