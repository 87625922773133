import { VisibilityLockIcon } from '@/icons';
import { Grid, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledPopper } from '../../styled-popper-menu';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

type IProps = {
  disableText?: boolean;
  title?: string;
  content?: string;
  zIndex?: number;
  iconHeight?: string;
  buttonOnlyStyles?: React.CSSProperties;
};

const PrivacyButton = ({ disableText, title, content, zIndex, iconHeight, buttonOnlyStyles = {} }: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => {
    setIsHovered(true);
    setAnchorEl(event.currentTarget);
  };
  const handleHoverOut = () => {
    setIsHovered(false);
    setAnchorEl(null);
  };

  return (
    <>
      {disableText ? (
        <span
          onMouseEnter={event => handleHoverIn(event)}
          onMouseLeave={handleHoverOut}
          style={{
            borderRadius: '5px',
            cursor: 'default',
            backgroundColor: '#EAEEF6',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '1.5rem',
            height: iconHeight ? iconHeight : '1.6rem',
            marginInlineStart: '.5rem',
            ...buttonOnlyStyles,
          }}
        >
          <VisibilityLockIcon color='primary' sx={{ fontSize: '14px', fontWeight: 900 }} />
        </span>
      ) : (
        <Button
          onMouseEnter={event => handleHoverIn(event)}
          onMouseLeave={handleHoverOut}
          type='button'
          startIcon={<VisibilityLockIcon color='primary' fontSize='small' />}
          variant='containedInfo'
          lineHeight={1}
          sx={{ borderRadius: '5px', cursor: 'default' }}
          disableRipple
        >
          <Typography variant='subtitle2' fontWeight={500} pt={0.5}>
            Me (Only)
          </Typography>
        </Button>
      )}
      <StyledPopper open={isHovered} anchorEl={anchorEl} handleClose={handleHoverOut} zIndex={zIndex}>
        <Grid container md={12} p={2} width={300}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: '22px !important', color: 'text.disabled' }} />
          <Typography variant='h6' color='text.disabled' fontWeight={700} pl={1}>
            {title ? title : 'Only Visible to Your Company'}
          </Typography>
          <Grid container md={12} py={1}>
            <Typography variant='subtitle2' fontWeight={400}>
              {content
                ? content
                : 'This item is private to you and the assigned seller(s) because it was created and/or linked by your company.'}
            </Typography>
          </Grid>
        </Grid>
      </StyledPopper>
    </>
  );
};

export default PrivacyButton;
