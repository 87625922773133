import { StyledTextField } from '@/components';
import { Grid, Button } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { LoginFormHeader } from '../..';
import { getLoginTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';
import { useEffect } from 'react';

interface EmailFormProps {
  disabledButton: boolean;
  validate: Function;
}

export const EmailForm: React.FC<EmailFormProps> = (props: EmailFormProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [field, meta] = useField({ name: 'email' });
  useEffect(() => {
    props.validate()
  }, []);

  return (
    <>
      <LoginFormHeader
        titleTranslate={t(getLoginTranslationKey('headers.recovery'))}
        subTitleTranslate={t(getLoginTranslationKey('paragraphs.email'))}
      />
      <Grid item xs={12}>
        <StyledTextField autofocus={true} translateKey='user.email' name='email' showLabel={true}/>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        {meta.error === 'email_not_found' ? (
          <Button
            variant='contained'
            size='large'
            color='secondary'
            fullWidth
            onClick={() => router.push(sm.register.index.url)}
          >
            {t('buttons.registration')}
          </Button>
        ) : (
          <Button
            variant='contained'
            size='large'
            color='secondary'
            fullWidth
            type='submit'
            disabled={props.disabledButton}
          >
            {t('buttons.next')}
          </Button>
        )}
      </Grid>
    </>
  );
};
