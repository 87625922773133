import { useDebounce } from '@/hooks';
import { VisibilityLockIcon } from '@/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Image from 'next/image';
import { SE } from 'public/assets/icons';
import React, { useEffect, useRef } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { StyledPopper } from 'src/ui/components/shared';
import { dropdownContainerStyles } from './styles';

type Option = { id: number; name: string; is_private: boolean };

type OptionItemProps = {
  option: Option;
  handleCheckSeller: (sellerId: number) => void;
  handleUncheckSeller: (sellerId: number) => void;
  selectedSellers: number[];
};

const OptionItem = ({ option, handleCheckSeller, handleUncheckSeller, selectedSellers }: OptionItemProps) => {
  const option_id = option.id;
  const isChecked = React.useMemo(() => {
    return selectedSellers.includes(option_id);
  }, [option_id, selectedSellers]);

  const handleClick = React.useCallback(() => {
    if (isChecked) {
      handleUncheckSeller(option_id);
    } else {
      handleCheckSeller(option_id);
    }
  }, [isChecked, option_id, handleCheckSeller, handleUncheckSeller]);

  return (
    <FormControlLabel
      key={option.id}
      label={
        <Grid container alignItems='end' justifyContent='center'>
          <Grid item>
            <Typography fontSize={14} color='#13628C' fontWeight={400} maxWidth={'40ch'} lineHeight={1.2} my={0.7}>
              {option.name}{' '}
            </Typography>
          </Grid>
          <Grid item>
            {option.is_private && (
              <span
                style={{
                  borderRadius: '5px',
                  cursor: 'default',
                  backgroundColor: '#EAEEF6',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '1.3rem',
                  height: '1.3rem',
                  marginInlineStart: '.5rem',
                }}
              >
                <VisibilityLockIcon color='primary' sx={{ fontSize: '14px' }} />
              </span>
            )}
          </Grid>
        </Grid>
      }
      control={
        <Checkbox
          sx={{ p: 0, mx: 1 }}
          checked={isChecked}
          onChange={handleClick}
          inputProps={{ 'aria-label': 'controlled', style: { color: 'red' } }}
          size='small'
          color='primary'
        />
      }
    />
  );
};

type IProps = {
  onSearchChange: (newValue: string) => void;
  options: Option[];
  handleCheckSeller: (sellerId: number) => void;
  handleUncheckSeller: (sellerId: number) => void;
  dropdownTitle: string;
  selectedSellers: number[];
  onCreateOption: (value: string) => void
};

const AddSellerDropDown = ({
  onSearchChange,
  options,
  dropdownTitle,
  selectedSellers,
  handleCheckSeller,
  handleUncheckSeller,
  onCreateOption
}: IProps) => {
  const [searchText, setSearchText] = React.useState<string>('');
  useDebounce(() => onSearchChange(searchText), 700, [searchText]);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const selectPopup = usePopup({ onCloseCallback: () => setSearchText('') });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (anchorRef.current && popperRef.current && !anchorRef.current.contains(event.target as Node) && !popperRef.current.contains(event.target as Node)) {
        selectPopup.handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectPopup]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <Typography color='#8B8D98' fontSize={13} fontWeight={400}>
          Sellers
        </Typography>
        <div onClick={selectPopup.handleOpen} ref={anchorRef} style={dropdownContainerStyles}>
          <Typography variant='subtitle2' color={selectedSellers.length === 0 ? '#8B8D98' : '#3A3E50'}>
            {dropdownTitle}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              color: selectedSellers.length === 0 ? '#8B8D98' : '#3A3E50',
            }}
          />
        </div>
      </div>
      <StyledPopper
        open={selectPopup.isOpen}
        anchorEl={anchorRef.current}
        handleClose={selectPopup.handleClose}
        place='bottom-start'
        zIndex={1300}
      >
        <div
          ref={popperRef}
          style={{
            padding: '1rem',
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: '0px 1px 3px 0px #EBEBEB',
            width: anchorRef.current?.clientWidth,
          }}
        >
          <Box width={'100%'} mb={1.5}>
            <TextField
              fullWidth
              placeholder='Search'
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ width: '19px', height: '19px' }}>
                    <Image src={SE} objectFit='contain' alt='' />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Typography variant='body2' sx={{ fontWeight: 500, color: '#13628C', cursor: 'pointer' }} 
          onClick={() => { onCreateOption(searchText); selectPopup.handleClose() }}>+ Create New {searchText && `"${searchText}"`}</Typography>
          <div
            className='styled-scrollbar'
            style={{
              maxHeight: '12rem',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 1
            }}
          >
            {options.map(opt => (
              <OptionItem
                key={opt.id}
                option={opt}
                handleCheckSeller={handleCheckSeller}
                handleUncheckSeller={handleUncheckSeller}
                selectedSellers={selectedSellers}
              />
            ))}
          </div>
        </div>
      </StyledPopper>
    </>
  );
};

export default AddSellerDropDown;
