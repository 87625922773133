import { StateCreator } from "zustand";
import produce from "immer";
import { useUserData } from "../../user";
import { useGetAllProjects } from "@/services";
import { apiRoutes } from "@/config";

export interface GetAllProjectsSliceSlice {
    allProjects: any | null,
    loadAllProjects: () => void
}

export const createGetAllProjectsSlice: StateCreator<GetAllProjectsSliceSlice> = (set, get, api) => ({
    allProjects: null,
    loadAllProjects: () => {
        const isSeller = useUserData.getState().isSeller();
        const baseURL = isSeller ? apiRoutes.indexProjectsSeller : apiRoutes.indexProjectsBuyer;
        useGetAllProjects(baseURL).then(data => {
            set(produce(draftState => { draftState.allProjects = data.data.data.data }))
        })
    }
})