import { Divider, Grid, Box, Typography } from "@mui/material"
import { useTranslation } from 'next-i18next';

export const AlternativeProductSuppliers = () => {
  const { t } = useTranslation();
    const suppliers = [
      { supplierName: 'Supplier 1', price: 'AED 666' },
      { supplierName: 'Supplier 2', price: 'AED 666' },
    ]
    return (
      <Grid container sx={{ bgcolor: 'secondary.contrastText', justifyContent: 'space-between', alignItems: 'center' }}>
        {suppliers.map((el, index) => (
          <>
            {index != 0 && <Box width={"100%"} px={2}>
              <Divider sx={{ borderColor: 'info.contrastText' }} /></Box>
            }
            <Grid item container md={12} p={2} px={4}>
              <Typography variant="body2" sx={{ color: "text.disabled", fontWeight: 600, mr: 8 }}>{el.supplierName}</Typography>
              <Typography variant="body2" sx={{ color: "text.third", fontWeight: 500, mr: 2 }}>{t('texts.unit_price')} </Typography>
              <Typography variant="body2" sx={{ color: "text.disabled", fontWeight: 700 }}>{el.price}</Typography>
            </Grid>
          </>
        ))
        }
      </Grid >
    )
  }