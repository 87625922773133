import React, { useRef } from "react";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableChipCell } from "../table-chip-cell";


export const ChipCells = ({ cell, data }) => {
  const [showAtt, setshowAtt] = React.useState(false);
  const arrowStyle = { sx: { cursor: 'pointer', color: 'text.third', width: 18 } }


  return (
    <Grid container sx={{ maxWidth: 270 }}>
      <Grid item container md={cell.getValue().length <= 2 ? 12 : 11}>
        {(cell.getValue().length == 0 )||(cell.getValue().length == 1 && !cell.getValue()[0])? (
          <TableChipCell data={data} cell={cell} el='none' />
        ) : cell.getValue().length == 1 ? (
          <TableChipCell data={data} cell={cell} el={cell.getValue()[0]} />
        ) : cell.getValue().length == 2 ? <Stack flexWrap={'wrap'} flexDirection={'row'} width={250}>
          {cell.getValue().sort((a, b) => { return a.localeCompare(b) }).map(el => (
            <TableChipCell data={data} cell={cell} key={el} el={el} />
          ))}
        </Stack>
          : !showAtt ? <Stack flexWrap={'wrap'} flexDirection={'row'} width={240}>
            {cell.getValue().sort((a, b) => { return a.localeCompare(b) }).slice(0, 2).map(el => (
              <TableChipCell data={data} cell={cell} key={el} el={el} />
            ))}</Stack>
            : <Stack flexWrap={'wrap'} flexDirection={'row'} width={240}>
              {cell.getValue().sort((a, b) => { return a.localeCompare(b) }).map(el => (
                <TableChipCell data={data} cell={cell} key={el} el={el} />
              ))}
            </Stack>
        }
      </Grid>
      {cell.getValue().length > 2 && (
        <Grid item container md={1} justifyContent='flex-end'>
          <Stack>
            {!showAtt ? <KeyboardArrowDownIcon {...arrowStyle} onClick={() => setshowAtt(true)} />
              : <KeyboardArrowUpIcon {...arrowStyle} onClick={() => setshowAtt(false)} />}
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}
