import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import Image from 'next/image';

type Props = {
  icon: SvgIconComponent;
  primaryTextKey: string;
  secondaryTextKey: string;
} & React.ComponentProps<typeof Card>;

export const HowItWorksCard: React.VFC<Props> = ({
  icon,
  primaryText,
  secondaryText,
  ...props
}) => {


  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: 2,
        borderColor: 'warning.light',
        backgroundColor: 'warning.dark',
      }}
      variant='outlined'
      {...props}
    >
      <CardContent>
        <Box height='72px' sx={{ display: 'flex', alignItems: 'center', p: [0, 1, 2] }} >
          <Box sx={{ height: 50, width: 50 }}>
            <Image
              src={icon}
              alt='Fancy'
            />
          </Box>
          <Typography
            variant='h6'
            fontWeight='700'
            ml={4}
            sx={{ lineHeight: 1.3 }}
          >
            {primaryText}
          </Typography>
        </Box>
        <Typography color='text.third' mt={2} variant='body2' fontWeight='400'>
          {secondaryText}
        </Typography>
      </CardContent>
    </Card>
  );
};
