import React from 'react';
import { useProcurementStore, useSellerProcurementStore } from '@/zustand';
import { Button, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';

const SaveCancelEditQuoteActions = () => {
  const router = useRouter();
  const { data } = useProcurementStore(
    state => state.productFormManagerProperties
  );
  const { updateProductsFromEnvironment } = useSellerProcurementStore(
    state => state.editQuoteFromScratchSliceActions
  );
  const { products: originalProducts } = useSellerProcurementStore(
    state => state.editQuoteFromScratchSliceProperties
  );

  const onSaveClicked = () => {
    updateProductsFromEnvironment(data.products);
    router.push(
      sm.portals.seller.procurement.quotes.edit(data.formDetails.id).url
      );
    };
    
  const onCancelClicked = () => {
    updateProductsFromEnvironment(originalProducts);
    router.push(
      sm.portals.seller.procurement.quotes.edit(data.formDetails.id).url
    );
  };

  return (
    <Grid container mb={3} spacing={1}>
      <Grid item md={6}>
        <Button
          type='button'
          color='success'
          variant='contained'
          fullWidth
          onClick={onSaveClicked}
        >
          Update
        </Button>
      </Grid>
      <Grid item md={6}>
        <Button
          type='button'
          color='error'
          variant='outlined'
          fullWidth
          onClick={onCancelClicked}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default SaveCancelEditQuoteActions;
