import { apiRoutes } from "@/config"
import { request } from "@/helpers"

export const useGetSellerPurchaseOrderDetails = (POId: number) => {
    return request({ url: apiRoutes.sellerPurchaseOrder + apiRoutes.show + POId, method: "GET" })
}

export const useDeclinePurchaseOrder = (poId: number, reasonId: string, comment?: string) => {
    return request({ url: apiRoutes.sellerPurchaseOrder + '/' + poId + apiRoutes.decline, method: "PUT", data: { reason_id: reasonId, comment: comment ?? null } })
}


export const useFetchDeclinePurchaseOrderReasons = () => {
    return request({ url: apiRoutes.sellerPurchaseOrder + apiRoutes.declineReasons, method: "GET" })
}

export const useConfirmPurchaseOrder = (poId: number, comment?: string) => {
    return request({ url: apiRoutes.sellerPurchaseOrder + '/' + poId + apiRoutes.confirm, method: "PUT", data: { comment: comment ?? null } })
}

export const useIndexSellerPurchaseOrders = () => {
    return request({ url: apiRoutes.sellerPurchaseOrder + apiRoutes.index, method: "GET" })
}