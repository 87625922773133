import type { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from 'react';
import { useTranslation } from 'next-i18next';

interface StyledToggleProps {
    handleChange: () => void,
    options: string[],
    selectedValue: string,
    translateKey: string,
    disabled?: boolean
}
const buttonStyle = {
    textTransform: 'capitalize', width: '164px',
    fontSize: '12px !important',
}
export const StyledToggle: FC<StyledToggleProps> = (props: StyledToggleProps) => {
    const { t } = useTranslation();

    return (
        <ToggleButtonGroup
            value={props.selectedValue}
            exclusive
            onChange={props.handleChange}
            disabled={props.disabled}
            sx={{
                bgcolor: 'primary.light',
                borderRadius: '100px !important',
                color: 'white !important',
                height: 32,
                width: '100%',
                '.MuiToggleButton-root': {
                    borderColor: 'primary.light',
                    color: 'text.disabled',
                    borderRadius: '100px !important',
                    width: '50%'
                },
                '.Mui-selected': {
                    borderRadius: '100px !important',
                    backgroundColor: '#0AB87B !important',
                    color: 'white !important',
                },
            }}
        >
            {props.options.map(option =>
                <ToggleButton value={option} sx={buttonStyle} >{t(props.translateKey + "." + option)}</ToggleButton>
            )}
        </ToggleButtonGroup>
    );
};

