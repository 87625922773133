import { Roles } from '../Roles';
import { Permissions } from '../Permissions';

export const Rules = {
  [Roles.ADMIN_BUYER]: {
    [Permissions.EDIT_USERS]: true,
    [Permissions.EDIT_COMPANY]: true,
  },
  [Roles.ADMIN_SELLER]: {
    [Permissions.EDIT_USERS]: true,
    [Permissions.EDIT_COMPANY]: true,
  },
  [Roles.REGULAR_BUYER]: {
    [Permissions.EDIT_USERS]: false,
    [Permissions.EDIT_COMPANY]: false,
  },
  [Roles.REGULAR_SELLER]: {
    [Permissions.EDIT_USERS]: false,
    [Permissions.EDIT_COMPANY]: false,
  }
  // [Roles.Admin]: ({}, user) =>
  //   user && (user.roles.includes(Roles.ADMIN_SELLER)||user.roles.includes(Roles.ADMIN_BUYER)),
  // [Permissions.EDIT_USERS]: ({}, user) =>
  //   user && user.permissions.includes(Permissions.EDIT_USERS),
  // [Permissions.EDIT_COMPANY]: ({}, user) =>
  //   user && user.permissions.includes(Permissions.EDIT_COMPANY),
  // [Roles.ADMIN_BUYER]: {
  //     [Permissions.EDIT_COMPANY]: true,
  //     [Permissions.EDIT_USERS]: true,
  // },
  // [Roles.ADMIN_SELLER]: {
  //     [Permissions.EDIT_COMPANY]: true,
  //     [Permissions.EDIT_USERS]: true,
  // },
  // [Roles.REGULAR_BUYER]: {
  //     [Permissions.EDIT_COMPANY]: false,
  //     [Permissions.EDIT_USERS]: false,
  // },
  // [Roles.REGULAR_SELLER]: {
  //     [Permissions.EDIT_COMPANY]: false,
  //     [Permissions.EDIT_USERS]: false,
  // },
};
