import React, { FC } from "react"
import { Grid, Typography, TextField, Button } from "@mui/material"
import { useTranslation } from 'next-i18next';


interface ShowGeneralTermsProps {
    warranty_term: string | undefined
    payment_term: string | undefined
    delivery_term: string | undefined
    handleCloseGeneralTermsPopup: () => void
}

const textInputStyle = {
    color: '#000000',
    '& ::placeholder': {
        color: '#000000',
        fontWeight: '400',
    },
};
export const ShowGeneralTerms: FC<ShowGeneralTermsProps> = ({ warranty_term, payment_term, delivery_term, handleCloseGeneralTermsPopup }) => {
    const { t } = useTranslation();

    return (
        <Grid container md={12} gap={2} width={300} p={2}>
            <Grid item md={12} textAlign='center'>
                <Typography variant='overline' color='primary.main' fontWeight={700} my={1}>
                    {t('buttons.general_terms')}
                </Typography>
            </Grid>
            <Grid item md={12}>

                <Typography color='text.third' fontWeight='400' variant='subtitle2' pt={1}>{t('texts.paymentText')}</Typography>
                <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2' sx={{ display: 'block' }} >
                    <TextField InputProps={{ sx: textInputStyle }} sx={{ width: '100%' }} disabled
                        value={payment_term}
                    />
                </Typography>

                <Typography color='text.third' fontWeight='400' variant='subtitle2' pt={1}>{t('texts.warrantyText')}</Typography>
                <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2' sx={{ display: 'block' }} >
                    <TextField InputProps={{ sx: textInputStyle }} sx={{ width: '100%' }} disabled
                        value={warranty_term}
                    />
                </Typography>

                <Typography color='text.third' fontWeight='400' variant='subtitle2' pt={1}>{t('texts.deliveryText')}</Typography>
                <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2' sx={{ display: 'block' }} >
                    <TextField InputProps={{ sx: textInputStyle }} sx={{ width: '100%' }} disabled
                        value={delivery_term}
                    />
                </Typography>
            </Grid>
            <Grid item md={12} textAlign='center' mt={1}>
                <Button fullWidth variant="contained" onClick={handleCloseGeneralTermsPopup}>{t('buttons.close')}</Button>
            </Grid>
        </Grid>
    )
}