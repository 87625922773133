import { AxiosResponse } from 'axios';

export type QueryHandlerModel = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  notFound: boolean;
  error: null | AxiosResponse;
};

const getInitialStatus = (): QueryHandlerModel => {
  return {
    isSuccess: false,
    isLoading: false,
    isError: false,
    notFound: false,
    error: null,
  };
};

const getStartStatus = (): QueryHandlerModel => {
  return {
    isSuccess: false,
    isLoading: true,
    isError: false,
    notFound: false,
    error: null,
  };
};

const getSuccessStatus = (): QueryHandlerModel => {
  return {
    isSuccess: true,
    isLoading: false,
    isError: false,
    notFound: false,
    error: null,
  };
};

const getErrorStatus = (response: AxiosResponse<any>): QueryHandlerModel => {
  return {
    isSuccess: false,
    isLoading: false,
    isError: true,
    notFound: response?.status === 404,
    error: response,
  };
};

const QueryHandler = {
  getInitialStatus,
  getStartStatus,
  getSuccessStatus,
  getErrorStatus,
};
export default QueryHandler;
