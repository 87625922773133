import {
    ColumnFormat, ColumnSort,
    ColumnType,
    TableColumnMapper,
    TableGroup,
    TableMapper,
    TableRow,
    TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import {StateCreator} from "zustand";
import produce from "immer";
import {useIndexBuyerQuotes} from "@/services";
import {useUserData} from "@/zustand";
import {buildTableGroupModel} from "../../../services/tables";
import {procurementStatus} from "@/config";

export interface BuyerQuotesTableSlice {
    buyerQuotesTableSliceProperties: {
        data: {
            tableGroup: TableGroup | null,
            isLoading: boolean,
        },
        actions: {
            loadData: () => void,
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
        }
    },
}

export const createBuyerQuotesTableSlice: StateCreator<BuyerQuotesTableSlice> = (set, get, api) => ({
    buyerQuotesTableSliceProperties: {
        data: {
            tableGroup: null,
            isLoading: true,
        },
        actions: {
            loadData: () => {
                set(produce(draftState => {
                    draftState.buyerQuotesTableSliceProperties.data.isLoading = true
                }))

                useIndexBuyerQuotes().then(data => {
                    const userId = useUserData.getState().userInfo?.id
                    const tableGroup = buildTableGroupModel(
                        data.data, tableMapper(), quotesTableMapper(), userId, false,
                    )

                    set(produce(draftState => {
                        draftState.buyerQuotesTableSliceProperties.data.isLoading = false
                        draftState.buyerQuotesTableSliceProperties.data.tableGroup = tableGroup
                    }))
                })
            },
            onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => {

            }
        }
    }
})

export const tableMapper = () => {
    return [
        new TableMapper(TableType.OTHER, 'Other Quotes', [
            new TabMapper('all', [procurementStatus.ALL]),
            new TabMapper('QUOTES_RECEIVED', [procurementStatus.QUOTE_RECEIVED]),
            new TabMapper('CANCELED', [procurementStatus.CANCELED]),
            new TabMapper('PURCHASE_ORDER_GENERATED', [procurementStatus.PURCHASE_ORDER_GENERATED]),
        ]),
        new TableMapper(TableType.MINE, 'My Quotes', [
            new TabMapper('all', [procurementStatus.ALL], true),
            new TabMapper('QUOTES_RECEIVED', [procurementStatus.QUOTE_RECEIVED], true),
            new TabMapper('CANCELED', [procurementStatus.CANCELED]),
            new TabMapper('PURCHASE_ORDER_GENERATED', [procurementStatus.PURCHASE_ORDER_GENERATED], true),
        ])
    ];
}

export const quotesTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'quote', ColumnType.STRING, ColumnFormat.URL,
        new TableColumnMapper('project.name', 'project', ColumnType.STRING)
    );

    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        nameColumn,
        new TableColumnMapper('company_details.name', 'seller', ColumnType.STRING),
        new TableColumnMapper('rfq_name', 'rfq', ColumnType.STRING),
        new TableColumnMapper('requisition_name', 'requisition', ColumnType.STRING),
        dateColumn,
        numberOfProductsColumn,
        new TableColumnMapper('number_of_competitors', 'competitors', ColumnType.NUMBER),
        new TableColumnMapper('total_value', 'value', ColumnType.STRING),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}
