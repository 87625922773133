import { apiRoutes, Permissions, procurementStatus } from "@/config"
import { request } from "@/helpers"
import { RFQ, ProductTableRow, LinkType } from "@/models"


export const useSendReminder = (bundleRFQId: number) => {
    return request({ url: apiRoutes.getBundleRFQ + bundleRFQId + apiRoutes.sendReminder, method: "PUT" })
}

export const useIndexRequestForQuotes = () => {
    return request({ url: apiRoutes.request_for_quotes_index, method: "GET" })
}

// export const useApplyRFQTableFilters = (pageNumber: number, searchText: string | null, requiresUserAction: boolean, filters: TableSelectedFilters[] | null) => {
//     let data = {
//         page_number: pageNumber,
//         search_text: searchText,
//         requires_user_action: requiresUserAction,
//         filters: filters
//     }
//     return request({ url: apiRoutes.applyRFQTableFilters, method: "POST", data: data })
// }

export const useGenerateQuote = (rfqId: number, rfqProducts: ProductTableRow[]) => {
    let products = [] as {
        request_for_quote_product_id: number
        quantity: number
        price: string
        remarks: string
    }[]
    products = rfqProducts.map(rfqProduct => {
        return {
            request_for_quote_product_id: rfqProduct.id,
            quantity: rfqProduct.availableQuantity,
            price: rfqProduct.unitPrice,
            remarks: rfqProduct.remark ?? ''
        }
    })
    return request({ url: apiRoutes.createQuote, method: "POST", data: { request_for_quote_id: rfqId, products: products } })

}

export const useUpdateQuoteFromRfq = (quoteId: number, rfqProducts: ProductTableRow[]) => {
    let products = [] as {
        request_for_quote_product_id: number
        quantity: number
        price: string
        remarks: string
    }[]
    products = rfqProducts.map(rfqProduct => {
        return {
            request_for_quote_product_id: rfqProduct.id,
            quantity: rfqProduct.availableQuantity,
            price: rfqProduct.unitPrice,
            remarks: rfqProduct.remark ?? ''
        }
    })
    return request({ url: `/seller/quote/${quoteId}/update`, method: "PUT", data: { products } })

}

export const useRequestForQuoteDetails = (rfqId: number) => {
    return request({ url: apiRoutes.requestForQuotesDetails + rfqId, method: "GET" })
}
export const useFetchDeclineRFQReasons = () => {
    return request({ url: apiRoutes.sellerRequestForQuote + apiRoutes.declineReasons, method: "GET" })
}
export const useDeclineRequestForQuote = (rfqId: number, reasonId: string, comment?: string) => {
    return request({ url: apiRoutes.sellerRequestForQuote + apiRoutes.decline + '/' + rfqId, method: "POST", data: { reason_id: reasonId, comment: comment ?? null } })
}

export function buildRFQDetails(rfq: RFQ, userInfo: any, isEditMode?: boolean) {
    const status = rfq.status

    const isOwner = userInfo?.id == rfq?.owner.id
    const isApprover = !!(rfq?.approvers?.find(approver => approver.approver_id == userInfo?.id));
    const isGuest = userInfo === null;

    return {
        // rfqModel: rfq,
        // numberOfProducts: rfq.products.length,
        // canDelete: isOwner && rfq?.deletable,
        // canAcceptRFQ: userCanApproveRFQ(isApprover, status),
        // canRejectRFQ: userCanRejectRFQ(isApprover, status),
        // canGenerateQuote: isGenerateQuoteAllowed(rfq, userInfo, status, isEditMode, rfq?.is_expired),
        // canSendToSeller: isOwner && [procurementStatus.APPROVED, procurementStatus.RECALLED].includes(status),
        // canViewQuote: userCanViewQuote(status, isEditMode),
        // can_edit_regenerate: isCanEditRegenerateAllowed(rfq, userInfo, status, isEditMode),
        // canCancelRFQ: isCanCancelRFQAllowed(rfq, userInfo, status),
        // canDeclineRequestForQuote: isUserHasGenerateQuotePermission(userInfo) && status == procurementStatus.RFQ_RECEIVED && !rfq?.is_expired,
        // canChangeResponse: isUserHasGenerateQuotePermission(userInfo) && status == procurementStatus.DECLINED && !isEditMode,
        // canRequestExtension: rfq?.is_expired && !rfq?.has_pending_extension_request && rfq?.extension_request_status !== procurementStatus.DECLINED && rfq?.extension_request_status !== procurementStatus.PENDING,
        // canDeclineRequestExtension: isOwner && rfq?.is_expired == true && rfq?.extension_request_status == procurementStatus.PENDING


        /* new Solution */
        rfqModel: rfq,
        numberOfProducts: rfq.products.length,
        canDelete: rfq?.form_action_permissions?.deletable && isOwner,
        canAcceptRFQ: rfq?.form_action_permissions?.can_approve_approval_request && isApprover,
        canRejectRFQ: rfq?.form_action_permissions?.can_reject_approval_request && isApprover,
        canGenerateQuote: isGenerateQuoteAllowed(rfq, userInfo, status, isEditMode, rfq?.is_expired),
        canSendToSeller: rfq?.form_action_permissions?.can_send_to_counter_party && isOwner,
        canViewQuote: userCanViewQuote(status, isEditMode),
        can_edit_regenerate: isCanEditRegenerateAllowed(rfq, userInfo, rfq?.form_action_permissions?.editable, isEditMode),
        canRecallRFQ: rfq?.form_action_permissions?.recallable && isOwner,
        canDeclineRequestForQuote: (isUserHasGenerateQuotePermission(userInfo) || isGuest) && rfq?.form_action_permissions?.can_receiver_decline,
        canChangeResponse: rfq?.form_action_permissions?.can_receiver_change_response && isUserHasGenerateQuotePermission(userInfo) && status == procurementStatus.DECLINED && !isEditMode,
        canEditResponse: rfq?.form_action_permissions?.can_edit_approval_decision && isApprover,
        canRequestExtension: rfq?.form_action_permissions?.can_request_extension && rfq?.form_action_permissions?.can_receiver_change_response === false ,
        canDeclineRequestExtension: rfq?.form_action_permissions?.can_decline_request_extension
    };
}

export const isGenerateQuoteAllowed = (requestForQuote: RFQ, userInfo: any, status: string, isEditMode?: boolean, isExpired?: boolean) => {
    let isGenerateQuote: boolean | undefined = false
    const hasGenerateQuotePermission = isUserHasGenerateQuotePermission(userInfo)
    let isOwner = isThisUserOwner(requestForQuote, userInfo)
    const isGuest = userInfo === null;
    isGenerateQuote = (requestForQuote.form_action_permissions?.can_receiver_accept || isEditMode) && !isExpired && (hasGenerateQuotePermission || isGuest) && (status == procurementStatus.RFQ_RECEIVED || isEditMode || isOwner || isGuest)
    return isGenerateQuote;
}

export const isCanEditRegenerateAllowed = (requestForQuote: RFQ, userInfo: any, editable: boolean | undefined, isEditMode?: boolean) => {
    let isCanEditRegenerate: boolean | undefined = false
    const hasGenerateQuotePermission = isUserHasGenerateQuotePermission(userInfo)
    let isOwner = isThisUserOwner(requestForQuote, userInfo)
    const isGuest = userInfo === null;
    isCanEditRegenerate = (hasGenerateQuotePermission || isGuest) && editable && (isOwner || isGuest) && !isEditMode
    return isCanEditRegenerate;
}

export const isThisUserOwner = (requestForQuote: RFQ, userInfo: any) => {
    let isOwner: boolean | undefined = false
    isOwner = requestForQuote?.owner.id == userInfo?.id
    return isOwner;
}

export const isUserHasGenerateQuotePermission = (userInfo: any) => {
    const hasGenerateQuotePermission = userInfo?.permissions?.includes(Permissions.generate_quote)
    return hasGenerateQuotePermission;
}

export const userCanViewQuote = (status: string, isEditMode?: boolean) => {
    let userCanViewQuote: boolean | undefined = false
    userCanViewQuote = status == procurementStatus.QUOTE_GENERATED && !isEditMode
    return userCanViewQuote;
}

// export const userCanDeleteRFQ = (isOwner: boolean, status: string, canEdit: boolean) => {
//     let userCanDeleteRFQ: boolean | undefined = false
//     userCanDeleteRFQ = isOwner && status == procurementStatus.DRAFT && canEdit
//     return userCanDeleteRFQ;
// }

// export const userCanApproveRFQ = (isApprover: boolean, status: string) => {
//     let userCanApproveRFQ: boolean | undefined = false
//     userCanApproveRFQ = isApprover && (status == procurementStatus.PENDING_MY_APPROVAL || status == procurementStatus.REJECTED_BY_ME)
//     return userCanApproveRFQ;
// }

// export const userCanRejectRFQ = (isApprover: boolean, status: string) => {
//     let userCanRejectRFQ: boolean | undefined = false
//     userCanRejectRFQ = isApprover && (status == procurementStatus.PENDING_MY_APPROVAL || status == procurementStatus.APPROVED_BY_ME)
//     return userCanRejectRFQ;
// }

// export const isCanCancelRFQAllowed = (requestForQuote: RFQ, userInfo: any, status: string) => {
//     let isCanCancelRFQ: boolean | undefined = false
//     let isOwner = isThisUserOwner(requestForQuote, userInfo)
//     isCanCancelRFQ = status == procurementStatus.SENT_TO_SELLER && isOwner
//     return isCanCancelRFQ;
// }

export const useDeleteRFQ = (rfqId: number) => {
    return request({ url: apiRoutes.requestForQuote + rfqId, method: "DELETE" })
}

export const useApproveRFQ = (rfqId: number, comment: string | null) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.approve, method: "PUT", data: { comment: comment ?? null } })
}
export const useRejectRFQ = (rfqId: number, comment: string | null, reasonId: string | number) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.reject, method: "PUT", data: { comment: comment ?? null, reason_id: reasonId } })
}

export const useIndexSellerRequestForQuotes = () => {
    return request({ url: apiRoutes.request_for_quotes_seller_index, method: "GET" })
}

export const useCancelRFQ = (rfqId: number, reasonId: string, hideReason: boolean, optionalComment?: string | null) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.cancel, method: "PUT", data: { reason_id: reasonId, is_external: !hideReason, comment: optionalComment ?? null } })
}

export const useFetchCancelRFQReasons = () => {
    return request({ url: apiRoutes.requestForQuote + apiRoutes.cancelReasons, method: 'GET' })
}

export const useDeleteProductFromRFQ = (productId: number, rfqId: number | undefined) => {
    return request({ url: apiRoutes.requestForQuote + rfqId + apiRoutes.rfqProduct + productId, method: 'DELETE' })
}

export const useDuplicateForm = async (formId: number | undefined, project: any, formType: LinkType) => {
    return request({ url: apiRoutes.requestForQuote + formId + apiRoutes.duplicate, method: "POST", data: { project_id: project.projectId, new_project_name: project.projectName ?? null, form_type: formType } })
}