import { Grid, Button, useMediaQuery, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { getAccountTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';
import { StyledBreadcrumbs } from 'src/ui/components/shared';

type Props = {};

export const ProfileHeader: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Grid item xs={12}>
        <StyledBreadcrumbs alias={sm.account.index.url} />
      </Grid> */}
      <Grid item xs={12} mt={3}>
        <Typography variant='h1' fontWeight={'bold'}>{t(getAccountTranslationKey('title'))}</Typography>
      </Grid>
    </>
  );
};
