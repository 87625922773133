import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { queryWrapper } from '@/middlwares'
import { useDrawerStore } from '@/zustand'
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters'

export const fetchCategories = async () => {
    return request({
        url: apiRoutes.categories,
        method: "GET"
    })
}

export function useCategories() {
    const setDrawerSubMenuContent = useDrawerStore(state => state.setDrawerSubMenuContent)
    return queryWrapper({
        requestName: 'category',
        request: fetchCategories,
        onSuccess: (data) => setDrawerSubMenuContent(data.data),
    })
}

export const useProductDetails = (product_id: number, params: EnvironmentFilters = {}) => {
    // let data = { seller_id: sellerId, ...params }

    return request({
        url: apiRoutes.productDetails + product_id,
        method: "GET",
        params
    })
}

export const useAlternativeProductDetails = (product_id: number, mainProductId: number) => {
    return request({
        url: apiRoutes.alternativeProductDetails + mainProductId + '/' + product_id,
        method: "GET"
    })
}
