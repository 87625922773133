import { request } from '@/helpers';

const addProductToPurchaseOrder = ({
  purchase_order_id,
  product_id,
  quantity,
  price,
  additional_requirements,
}: {
  purchase_order_id: string;
  product_id: number;
  quantity: number;
  price: number;
  additional_requirements?: string | null;
}) =>
  request({
    url: `/buyer/purchase_order/add-product`,
    method: 'POST',
    data: {
      purchase_order_id,
      product_id,
      quantity,
      price,
      additional_requirements,
    },
  });

const removeProductFromPurchaseOrder = ({
  purchase_order_id,
  product_id,
}: {
  purchase_order_id: string;
  product_id: number;
}) =>
  request({
    url: `/buyer/purchase_order/${purchase_order_id}/delete-product`,
    method: 'POST',
    data: {
      product_id,
    },
  });

const changeQuantity = ({
  purchase_order_id,
  product_id,
  quantity,
}: {
  purchase_order_id: string;
  product_id: number;
  quantity: number;
}) =>
  request({
    url: `/buyer/purchase_order/${purchase_order_id}/update-product-details`,
    method: 'POST',
    data: {
      product_id,
      quantity,
    },
  });

const changePrice = ({
  purchase_order_id,
  product_id,
  price,
}: {
  purchase_order_id: string;
  product_id: number;
  price: number;
}) =>
  request({
    url: `/buyer/purchase_order/${purchase_order_id}/update-product-details`,
    method: 'POST',
    data: {
      product_id,
      price,
    },
  });

const changeAdditionalRequirements = ({
  purchase_order_id,
  product_id,
  additional_requirements,
}: {
  purchase_order_id: string;
  product_id: number;
  additional_requirements?: string | null;
}) =>
  request({
    url: `/buyer/purchase_order/${purchase_order_id}/update-product-details`,
    method: 'POST',
    data: {
      product_id,
      additional_requirements,
    },
  });

const PurchaseOrderSavingServices = {
  addProductToPurchaseOrder,
  removeProductFromPurchaseOrder,
  changeQuantity,
  changePrice,
  changeAdditionalRequirements,
};

export default PurchaseOrderSavingServices;
