import { useTranslation } from 'next-i18next';
import { user, RoundedImageWithText } from '@/components';
import { getInputsTranslationKey, getAccountTranslationKey } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, useMediaQuery } from '@mui/material';
import { AllowedTo } from 'react-abac';
import { Permissions, Roles, statusesTableData } from '@/config';
import { useApproveUser } from '@/services';

type Props = { userData: user; userId: string, refetch: Function };

export const CompanyUserInfo: React.FC<Props> = ({
    userData,
    userId,
    refetch
}) => {
    const { t } = useTranslation();
    const onSuccess = () => {
        refetch()
    }
    const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
    const { mutate, isLoading } = useApproveUser(onSuccess);
    const userTypeConfig = statusesTableData[userData.account_status];
    return (
        <RoundedImageWithText
            title={userData.first_name + ' ' + userData.last_name}
            subtitle={userData.company_name}
            chipContent={t("account_status." + userTypeConfig.text)}
            chipColor={userTypeConfig.color}
            chipTextColor={userTypeConfig.textColor}
            isSmallScreen={isSmallScreen}
            editImage={true}
        >
            {userData.account_status === 'PENDING_APPROVAL' && (
                <AllowedTo perform={Permissions.EDIT_USERS}>
                    <Box sx={{ display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <LoadingButton
                            color="secondary"
                            variant="contained"
                            sx={{ width: "173px" }}
                            loading={isLoading}
                            onClick={() => mutate(userId)}
                        >
                            {t('buttons.approve_user')}
                        </LoadingButton>
                    </Box>
                </AllowedTo>
            )}
        </RoundedImageWithText>
    );
};
