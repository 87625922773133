import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Menu,
  IconButton,
  InputAdornment,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from '@mui/material/Slider';
import { useDebounce } from '@/hooks';
import { formatNumber } from 'src/logic/utils/numbers';

export type PriceRangeFilterType = {
  label: string;
  type: string;
  kind: string;
  min: number;
  max: number;
  onFilterChanged: (type: string, kind: string, value: any) => void;
  onClear: (kind: string) => void
};

export const PriceRangeFilter: React.FC<PriceRangeFilterType> = ({
  label,
  type,
  kind,
  min: minimumValue,
  max: maximumValue,
  onFilterChanged,
  onClear
}) => {
  const initialValue = React.useRef([minimumValue, maximumValue]);
  const [value, setValue] = React.useState(initialValue.current);
  const min = value[0];
  const max = value[1];

  useDebounce(
    () => {
      onFilterChanged(type, kind, {
        min: value[0],
        max: value[1],
      });
    },
    300,
    [value]
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    const newValueArr = newValue as number[];
    setValue(newValueArr as number[]);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClearSelection = () => {
    // setValue([minimumValue, maximumValue]);
    onClear(kind)
    handleCloseMenu();
  };

  return (
    <>
      <TextField
        variant='standard'
        sx={{ width: '100%' }}
        InputLabelProps={{ style: { fontSize: '12px' } }}
        label={label}
        onClick={handleOpenMenu}
        value={`AED ${formatNumber(min)} - AED ${formatNumber(max)}`}
        InputProps={{
          style: { cursor: 'pointer' },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <ExpandMoreIcon fontSize='small' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: '18%',
            zIndex: 9999,
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='text'
            color='error'
            type='button'
            onClick={handleClearSelection}
          >
            Clear
          </Button>
        </div>

        <div style={{ padding: '1rem' }}>
          <Typography
            sx={{
              border: '1px solid #E8E8EA',
              borderRadius: '1px',
              width: '100%',
              textAlign: 'center',
              padding: '.5rem',
            }}
            color='#13628C'
          >
            <span style={{ fontSize: 'smaller' }}>AED</span>{' '}
            <span style={{ fontWeight: 600 }}>{formatNumber(min)}</span> -{' '}
            <span style={{ fontSize: 'smaller' }}>AED</span>{' '}
            <span style={{ fontWeight: 600 }}>{formatNumber(max)}</span>
          </Typography>

          <div style={{ paddingInline: '.5rem', marginTop: '1rem' }}>
            <Slider
              min={minimumValue}
              max={maximumValue}
              value={value}
              onChange={handleChange}
              valueLabelDisplay='auto'
              color='primary'
              size='small'
            />
          </div>
        </div>
      </Menu>
    </>
  );
};
