import React from 'react';
import { Button } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PictureAsPdf } from '@mui/icons-material';

type Props = {
    title: string;
    document: React.ReactElement;
    handleClickEvent?: () => void;
    fileName?: string;
    disabled?: boolean;
};

export const DownloadAsPDFButton: React.VFC<Props> = ({
    title,
    document,
    handleClickEvent,
    fileName = 'PDF_File',
    disabled = false
}) => {
    return (
        <>
            {!disabled ? (
                <PDFDownloadLink
                    style={{ textDecoration: 'none' }}
                    document={document}
                    fileName={`${fileName}.pdf`}
                >
                    {({ loading }) =>
                        loading ? (
                            <Button startIcon={<PictureAsPdf />} disabled>
                                {title}
                            </Button>
                        ) : (
                            <Button
                                startIcon={<PictureAsPdf />}
                                onClick={handleClickEvent}
                            >
                                {title}
                            </Button>
                        )
                    }
                </PDFDownloadLink>
            ) : (
                <Button startIcon={<PictureAsPdf />} disabled>
                    {title}
                </Button>
            )}
        </>
    );
};
