import { ProductInCartType, ProductManagerEnv, ProductManagerEnvItem, productManagerEnv } from '@/config';
import { Product, ProductCategoryKind } from '@/models';
import { useProcurementStore, useUserData } from '@/zustand';
import { Link as LinkIcon } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { sm } from 'src/site-map';
import {
  AddProductToEnvironmentButton,
  AlternativeProductRequisitionButton,
  FormMoreOptions,
  HistoryType,
  MainProductRequisitionButton,
  PriceInputForEnvironmentProduct,
  ProductHistory,
  ProductsSellerMainActions,
} from 'src/ui/components/dumb';
import { MainEditRfqButton } from 'src/ui/components/dumb/requisition/product-actions/editing-rfq';
import { QuoteProductActions } from 'src/ui/components/dumb/requisition/product-actions/quote';
import { RequisitionPopup } from 'src/ui/components/dumb/requisition/requisition-popup';
import LoadingLayer from 'src/ui/components/dumb/shared/loading-layer';
import { TextFieldCounter } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import QuoteEnvironmentPopupManager from '../../procurement/buyer/quote/quote-env-popups';
import { iconStyles, productHistoryTextStyles } from './styles';

type ProductDetailsHeaderProps = {
  env: ProductManagerEnvItem;
  changeProductDetailsMainProductQuantity: (quantity: number) => void;
  product: Product;
  mainProduct?: Product;
  hasActions: boolean;
};

export const ProductDetailsHeader: React.FC<ProductDetailsHeaderProps> = ({
  env,
  product,
  mainProduct,
  changeProductDetailsMainProductQuantity,
  hasActions,
}) => {
  const isSeller = useUserData().isSeller();

  const { changeProductQuantity, changeProductDetailsMainProductRequirements, productSellers } = useProcurementStore();
  function changeQuantity(quantity: number) {
    changeProductDetailsMainProductQuantity(quantity);
    changeProductQuantity(quantity, product);
  }

  const purchaseHistoryItems = product?.purchaseHistoryItems;
  const quoteHistoryItems = product?.quote_history;
  const { push } = useRouter();

  const { data } = useProcurementStore(state => state.productFormManagerProperties);
  const formDetails = data?.formDetails;
  const loadingProductsIds = data.loadingProductsIds;
  const product_id = product.id;
  const isProductLoading = React.useMemo(
    () => loadingProductsIds.find(prod => prod === product_id) !== undefined,
    [product_id, loadingProductsIds]
  );

  const requirementsFieldError =
    product.category_kind === ProductCategoryKind.SERVICE &&
    ((env && !product.additional_requirements) ||
      (!env && data.productIdRequestedToBeAdded == product.id && !product.additional_requirements));

  return (
    <Box
      // container
      gap={2}
      // md={12}
      width={'100%'}
      height='100%'
      sx={{
        borderRadius: '8px',
        // bgcolor: 'secondary.contrastText',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        position: 'relative',
      }}
    >
      {isProductLoading &&
        [
          ProductManagerEnv.requisition,
          ProductManagerEnv.catalog,
          ProductManagerEnv.quote,
          ProductManagerEnv.edit_quote_from_scratch,
        ].includes(env?.type) && <LoadingLayer />}

      <Box gap={1} width='100%'>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <div style={{ width: '100%', display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {product.brand && (
              <img
                src={product?.brand.image_url}
                style={{
                  objectFit: 'contain',
                  maxWidth: '50px',
                  maxHeight: '50px',
                  display: 'block',
                }}
              />
            )}
            {product.part_number && (
              <Typography fontSize={12} sx={{ fontWeight: 400 }} color='#8B8D98'>
                Part Number: {product.part_number}
              </Typography>
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ flexShrink: 0 }}>{product?.is_private_product && <PrivacyButton />}</div>

            <FormMoreOptions
              editAction={product?.can_edit_product_details}
              handleEditAction={() => push(sm.catalogue.products.editProduct(product?.id).url)}
            />
          </div>
        </div>
        <Typography variant='overline' sx={{ fontWeight: 600 }}>
          {product.name}
        </Typography>
      </Box>

      <Grid container md={12} alignItems={'center'}>
        {!isSeller && (
          <Grid item md={6}>
            <div style={{ display: 'flex' }}>
              <div style={iconStyles}>
                <BadgeIcon color='primary' />
              </div>
              <div>
                <Typography variant='subtitle1' lineHeight={1.2} fontWeight={400} color='#C5C6CB'>
                  Sellers
                </Typography>
                <Typography variant='body2' fontWeight={700} color='#3A3E50'>
                  {productSellers?.length ?? 0} Sellers
                </Typography>
              </div>
            </div>
          </Grid>
        )}
        <Grid item md={6}>
          {purchaseHistoryItems && purchaseHistoryItems?.length != 0 && (
            <ProductHistory
              items={purchaseHistoryItems}
              title={t('texts.purchase_history')}
              type={HistoryType.purchase}
              iconColor='#13628C'
              iconText={<span style={productHistoryTextStyles}>View Purchase History</span>}
            />
          )}
          {quoteHistoryItems && quoteHistoryItems?.length != 0 && (
            <ProductHistory
              items={quoteHistoryItems}
              title={t('texts.quote_history')}
              type={HistoryType.sales}
              iconColor='#13628C'
              iconText={<span style={productHistoryTextStyles}>View Quote History</span>}
            />
          )}
        </Grid>
      </Grid>

      {product.category_kind === ProductCategoryKind.SERVICE && (
        <TextField
          fullWidth
          multiline
          rows={3}
          variant='outlined'
          placeholder='Insert Buyer Requirements'
          InputProps={{
            sx: { minHeight: 70, border: requirementsFieldError ? '1px solid red' : 'none' },
          }}
          value={product.additional_requirements}
          onChange={e => {
            changeProductDetailsMainProductRequirements(e.target.value);
          }}
        />
      )}

      <Grid item gap={1} sx={{ display: 'flex' }}>
        {env?.type === ProductManagerEnv.catalog && (
          <>
            {product.part_of_seller_catalog ? (
              <>
                <Button variant='outlined_background' color='success' fullWidth startIcon={<LinkIcon />}>
                  {t('buttons.published')}
                </Button>
              </>
            ) : (
              <>
                {product.part_of_draft_list && product.part_of_draft_list.id !== formDetails?.id ? (
                  <Link href={sm.portals.seller.products.sellerProductList(`${product.part_of_draft_list.id}`).url}>
                    <Button variant='outlined_background' color='primary' fullWidth sx={{ lineHeight: 1 }}>
                      Added To {product.part_of_draft_list.name}
                    </Button>
                  </Link>
                ) : (
                  <>
                    <PriceInputForEnvironmentProduct product={product} />
                    <AddProductToEnvironmentButton
                      product={product}
                      titleForAddedProduct='added_to_list'
                      environment={productManagerEnv.purchase_order}
                      titleForAddingProduct={t('buttons.add_to_list')}
                      isPageAlternative={false}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {env?.type !== ProductManagerEnv.purchase_order && hasActions && (
          <Grid container md={12}>
            {product.inCartType == ProductInCartType.MAIN ? (
              <>
                <Grid item md={6}>
                  <TextFieldCounter value={product?.quantity ?? 1} changeQuantity={changeQuantity} />
                </Grid>

                <Grid item md={6}>
                  {env?.type === ProductManagerEnv.edit_bundle_rfq && <MainEditRfqButton {...product} />}
                  {env?.type === ProductManagerEnv.requisition && <MainProductRequisitionButton {...product} />}
                  {!env && <MainProductRequisitionButton {...product} />}
                </Grid>
                <RequisitionPopup />
              </>
            ) : mainProduct?.inCart ? (
              <AlternativeProductRequisitionButton product={product} mainProductIsInCart={mainProduct?.inCart} />
            ) : (
              <></>
            )}
          </Grid>
        )}
        {isSeller && (
          <>
            {!env && <ProductsSellerMainActions product={product} hidePriceInput />}
            {(env?.type === ProductManagerEnv.quote || env?.type === ProductManagerEnv.edit_quote_from_scratch) && (
              <QuoteProductActions product={product} />
            )}

            <QuoteEnvironmentPopupManager />
          </>
        )}
      </Grid>
    </Box>
  );
};
