import {AuthenticateStatus, Rules} from '@/config';
import {useProcurementStore} from '@/zustand';
import React, {useEffect} from 'react';
import {AbacProvider} from 'react-abac';
import {useUserData} from 'src/logic/zustand/user';
import {ProtectedRoutesProvider} from '..';
import {useSellerProcurementStore} from "../../../../../logic/zustand/procurement/seller";

export const AuthProvider: React.FC<{}> = props => {
  const { initializeUser, authenticationStatus, userInfo, isBuyer, isSeller } = useUserData();
  const { getProcurementData, getBadgeNumber, subscribeToRequisitions } = useProcurementStore()
  const { getSellerProcurementData } = useSellerProcurementStore()

  useEffect(() => {
    initializeUser();
  }, []);

  useEffect(() => {
    if(isBuyer()) {
      getBadgeNumber()
      getProcurementData()
    } else if(isSeller()) {
      getSellerProcurementData()
    } else if(authenticationStatus != AuthenticateStatus.AUTHENTICATED) {
      subscribeToRequisitions()
    }
  }, [authenticationStatus]);

  return (
    <>
      {authenticationStatus != AuthenticateStatus.UNDEFINED ? (
        <ProtectedRoutesProvider>
          <AbacProvider
            rules={Rules}
            permissions={userInfo?.permissions}
            roles={userInfo?.roles}
            user={userInfo}
          >
            {props.children}
          </AbacProvider>
        </ProtectedRoutesProvider>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};
