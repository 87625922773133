import { request } from '@/helpers';

export const getExternalPurchaseOrderDetails = (poId: string) => {
  return request({ url: `/external-purchase_order/show/${poId}`, method: 'GET' });
};

export const useConfirmExternalPurchaseOrder = (poId: number, comment?: string) => {
  return request({
    url: `/external-purchase_order/${poId}/confirm`,
    method: 'PUT',
    data: { comment: comment ?? null },
  });
};

export const useDeclineExternalPurchaseOrder = (poId: number, reasonId: string, comment?: string) => {
  return request({
    url: `/external-purchase_order/${poId}/decline`,
    method: 'PUT',
    data: { reason_id: reasonId, comment: comment ?? null },
  });
};
