const initialValues = (productId, quantity) => {
    return {
        purchase_order_id: null,
        product_id: productId,
        quantity: quantity,
        price: '',
        seller_id: null,
        projectId: 0,
        projectName:'',
    }
}

export default initialValues 