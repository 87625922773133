import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import Image from 'next/image';
import { useEmblaCarousel } from 'embla-carousel/react';
import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAnimateOnView } from '@/hooks';

type Props = {};

export const WhatWeCanOfferTwoBecomeASellerSection: React.FC<Props> = ({}) => {
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  const [viewportRef, embla] = useEmblaCarousel({ loop: false });
  const [index, setIndex] = useState(0);

  const slideNext = useCallback(() => {
    embla && embla.scrollNext();
  }, [embla]);

  const slidePrev = useCallback(() => {
    embla && embla.scrollPrev();
  }, [embla]);

  const slideToIndex = useCallback(
    (ind: number) => {
      embla && embla.scrollTo(ind);
    },
    [embla]
  );

  useEffect(() => {
    if (!embla) return;
    embla.on('select', () => {
      setIndex(embla?.selectedScrollSnap());
    });
  }, [embla]);
  return (
    <>
      <ResponsiveContainer
        as={motion.div}
        {...first}
        sx={{ typography: 'h2', textAlign: 'center', fontWeight: '700' }}
      >
        What we can offer our partners
      </ResponsiveContainer>
      <Box display='flex' justifyContent='center' mt={2}>
        <Typography
          component={motion.div}
          {...second}
          color='text.third'
          textAlign='center'
          maxWidth={560}
          fontWeight='400'
          variant='body2'
        >
          Amet, id integer ultricies lorem. Eu volutpat risus hendrerit egestas
          placerat lectus nisi rhoncus ornare. Quis volutpat tincidunt sed
          interdum viverra. Quis dolor vitae enim, viverra sodales. Ultrices id
          viverra diam diam nunc sem commodo.
        </Typography>
      </Box>

      <ResponsiveContainer
        m
        as={motion.div}
        {...third}
        sx={{
          mt: 12,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: ['100%', '100%', '100%', '80%'],
            height: 'calc(100% + 20px)',
            right: 60,
            top: 0,
            transform: 'translateY(-20px)',
            zIndex: -1,
            bgcolor: 'background.primary.main',
            borderRadius:15,
            backgroundImage: 'url(/assets/images/shared/points.svg)',
          }}
        />
        <IconButton
          color='primary'
          size='large'
          onClick={slidePrev}
          disabled={!embla?.canScrollPrev()}
          sx={{
            height: 'min-content',
            mr: [1, 2, 3, 4],
            position: ['absolute', 'absolute', 'relative'],
            left: 0,
            zIndex: 2,
          }}
        >
          <ArrowBackIosNew fontSize='inherit' />
        </IconButton>
        <Box className='embla' ref={viewportRef} sx={{ flex: 1 }}>
          <div className='embla__container'>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <div className='embla__slide' key={i}>
                  <Grid container justifyContent='center' sx={{ py: 6 }}>
                    <Box
                      sx={{
                        position: 'relative',
                        height: 400,
                        overflow: 'hidden',
                        borderRadius: t => t.spacing(6,6,6,0),
                        flexBasis: 370,
                      }}
                    >
                      <Image
                        src='/assets/images/become-a-seller/worker.png'
                        layout='fill'
                        objectFit='cover'
                        alt='Hand shake'
                      />
                    </Box>
                    <ResponsiveContainer
                      m
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        flex: '1 0 300px',
                        py: 4,
                        pr: [0, 2, 4, 6, 8],
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: 70,
                          height: 70,
                          zIndex: -1,
                        }}
                      >
                        <Image
                          src='/assets/images/become-a-seller/back.svg'
                          layout='fill'
                          objectFit='contain'
                          alt='Hand shake'
                        />
                      </Box>
                      <Typography
                        variant='h6'
                        color='text.primary'
                        fontStyle='italic'
                        fontWeight='400'
                        py={2}
                      >
                        Malesuada sit donec non gravida augue ultrices congue
                        faucibus. Rhoncus blandit ac vel quisque morbi purus
                        semper. Facilisi scelerisque est est fermentum convallis
                        enim. Libero sed porta aliquet est interdum mattis
                        tellus amet. Et nec, et ut iaculis augue id nibh sit
                        posuere.
                      </Typography>
                      <Box
                        sx={{
                          position: 'relative',
                          width: 70,
                          height: 70,
                          alignSelf: 'flex-end',
                        }}
                      >
                        <Image
                          src='/assets/images/become-a-seller/front.svg'
                          layout='fill'
                          objectFit='contain'
                          alt='Hand shake'
                        />
                      </Box>
                    </ResponsiveContainer>
                  </Grid>
                </div>
              ))}
          </div>
        </Box>
        <IconButton
          color='primary'
          size='large'
          onClick={slideNext}
          disabled={!embla?.canScrollNext()}
          sx={{
            height: 'min-content',
            ml: [1, 2, 3, 4],
            position: ['absolute', 'absolute', 'relative'],
            right: 0,
            zIndex: 2,
          }}
        >
          <ArrowForwardIos fontSize='inherit' />
        </IconButton>
      </ResponsiveContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 4,
        }}
      >
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <Box
              onClick={() => slideToIndex(i)}
              sx={{
                p:  0.5,
                borderRadius: '50%',
                mx: 1,
                cursor: 'pointer',
                bgcolor:
                  index === i ? 'primary.main' : 'background.primary.active',
              }}
              key={i}
            />
          ))}
      </Box>
    </>
  );
};
