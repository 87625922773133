import { phoneRegExp } from 'src/logic/helpers/regex';
import * as yup from 'yup';

const validationSchema = yup.object({
  first_name: yup.string().required('required'),
  last_name: yup.string().required('required'),
  title: yup.string().required('required'),
  job_title: yup.string().required('required'),
  company_name: yup.string(),
  existed_company_id: yup.string().when('company_name', {
    is: value => value == null,
    then: yup.string().required('required'),
  }),
  company_industry_id: yup.number().when('existed_company_id', {
    is: value => value == null,
    then: yup.number().required('required'),
  }),
  company_country_id: yup.number().when('existed_company_id', {
    is: value => value == null,
    then: yup.number().required('required'),
  }),
  company_city_id: yup.number().when('existed_company_id', {
    is: value => value == null,
    then: yup.number().required('required'),
  }),
  company_address: yup.string().when('existed_company_id', {
    is: value => value == null,
    then: yup.string().required('required'),
  }),
  trn_number: yup.string().nullable(),
  work_number: yup.string().matches(phoneRegExp, 'Invalid Phone Number').nullable(),
  fax_number: yup.string().matches(phoneRegExp, 'Invalid Phone Number').nullable(),
});

export default validationSchema;
