import { Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { EditResponseButton, EditAndRegenerateButton, FormMoreOptions, StyledLoadingButton, SingleRfqPDFDocument, LinkedFormsIcon } from "@/components";
import { ProcurementFormModel } from "@/models"
import { useRouter } from 'next/router'
import { ProcurementFormPermission } from "@/services";
import { ProcurementButtons, ProcurementForms, ProcurementPopupStatus, procurementStatus } from "@/config";
import { useProcurementStore, useUserData } from "@/zustand";
import { sm } from "src/site-map";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import { RelatedFormsList } from "../../../list-linked-forms";


type RFQBuyerActionsProps = {
    requestForQuoteDetails: ProcurementFormModel
}
export const RFQBuyerActions: React.FC<RFQBuyerActionsProps> = ({ requestForQuoteDetails }) => {
    const { t } = useTranslation();
    const { push } = useRouter();
    let requestForQuoteModel = requestForQuoteDetails?.model
    const { canCreateRFQ } = useUserData()
    const { customLoadingButton } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceProperties)
    const { setPopupStatus, deleteSingleRFQ, handleDuplicateSingleRFQ, declineRequestExtension } = useProcurementStore(state => state.requestForQuoteBuyerDetailsSliceActions)

    const updatable = requestForQuoteModel?.updatable ?? false

    const handleSendForApproval = () => setPopupStatus(ProcurementPopupStatus.SEND_FOR_APPROVAL)
    const handleApprove = () => setPopupStatus(ProcurementPopupStatus.APPROVE)
    const handleSendToSeller = () => setPopupStatus(ProcurementPopupStatus.SENT_TO_SELLER)
    const handleReject = () => setPopupStatus(ProcurementPopupStatus.REJECT)
    const handleCancel = () => setPopupStatus(ProcurementPopupStatus.CANCEL)
    const handleCancelApproval = () => setPopupStatus(ProcurementPopupStatus.CANCEL_APPROVAL)
    let canEditResponse = requestForQuoteDetails?.actions[ProcurementFormPermission.EDIT_RESPONSE]
    const handleEdit = () => {
        // const reqId = requestForQuoteModel?.requisition?.id
        // push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(reqId).url, query: { sellers: 'select', type: RFQType.SINGLE_RFQ, id: requestForQuoteModel?.id, name: requestForQuoteModel?.name } })
        // push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(reqId).url, query: { type: 'Single_RFQ', id: requestForQuoteModel?.id, name: requestForQuoteModel?.name } })
        push(sm.portals.buyer.procurement.bundleRFQs.edit(requestForQuoteModel?.bundle?.id).url)
    }
    // const handleViewCT = () => console.log('view')

    const handleDeleteSingleRFQ = () => deleteSingleRFQ(requestForQuoteDetails?.model?.id, () => push(sm.portals.buyer.procurement.requestForQuotes.index.url))

    const handleDuplicate = (project: any) => {
        handleDuplicateSingleRFQ(requestForQuoteDetails?.model?.id, project, (id: any) => push(sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(id).url))
    }

    const handleDeclineRequestExtension = () => {
        declineRequestExtension(requestForQuoteDetails?.model?.id)
    }

    function handleTheRedirectToRelatedForm(id: number) {
        return sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(id).url
    }

    return (
        <>
            {requestForQuoteDetails?.actions[ProcurementFormPermission.DECLINE_REQUEST_EXTENSION] && <StyledLoadingButton customWidth variant="outlined" translateKey="decline_request" loading={customLoadingButton == ProcurementButtons.DECLINE_REQUEST_EXTENSION} color={'error'} onClick={handleDeclineRequestExtension} />
                // <Button variant='outlined' color='error' onClick={handleDeclineRequestExtension}>
                //     {t('buttons.decline_request')}
                // </Button>
            }
            {/* {requestForQuoteDetails?.actions[ProcurementFormPermission.EDIT] && <EditAndRegenerateButton hidePopup editAndRegenerateHandler={handleEdit} subtitle={t('popups.subTitles.edit_regenerate_single')} />} */}

            {requestForQuoteDetails?.actions[ProcurementFormPermission.EditAsMainAction] && <EditAndRegenerateButton hidePopup editAndRegenerateHandler={handleEdit} subtitle={t('popups.subTitles.edit_regenerate_single')} mainAction={true} />}

            {/* {requestForQuoteDetails?.actions[ProcurementFormPermission.VIEW_CT] && <Button variant='contained' onClick={handleViewCT}>
                {t('buttons.view_comparison_table')}
            </Button>} */}
            {requestForQuoteDetails?.actions[ProcurementFormPermission.SEND_FOR_APPROVAL] && <Button variant='contained' onClick={handleSendForApproval}>
                {t('buttons.send_for_approval')}
            </Button>}
            {/* {requestForQuoteDetails?.actions[ProcurementFormPermission.CANCEL] && <Button variant='contained' onClick={handleCancel}>
                {t('popups.titles.cancel_rfq')}
            </Button>} */}
            {requestForQuoteDetails?.actions[ProcurementFormPermission.CANCEL_APPROVAL] && <Button variant='outlined' color='error' onClick={handleCancelApproval}>
                {t('buttons.cancel_approval_request')}
            </Button>}
            {requestForQuoteDetails?.actions[ProcurementFormPermission.REJECT] && !canEditResponse && <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={handleReject}> {t("buttons.reject")} </Button>}
            {requestForQuoteDetails?.actions[ProcurementFormPermission.APPROVE] && !canEditResponse && <Button variant="contained" color="success" sx={{ mr: 1 }} onClick={handleApprove}> {t("buttons.approve")} </Button>}
            {requestForQuoteDetails?.actions[ProcurementFormPermission.SEND_TO_SELLER] && !canEditResponse && <Button variant="contained" sx={{ mr: 1 }} onClick={handleSendToSeller}> {t("buttons.send_rfq_to_seller")} </Button>}
            {canEditResponse && <EditResponseButton>
                {requestForQuoteDetails?.actions[ProcurementFormPermission.APPROVE] && <Button startIcon={<CheckCircleOutline />} sx={{ color: "success.dark" }} onClick={handleApprove}>
                    {t('buttons.approve')}
                </Button>}

                {requestForQuoteDetails?.actions[ProcurementFormPermission.REJECT] && <Button startIcon={<HighlightOff />} color="error" onClick={handleReject}>
                    {t("buttons.reject")}
                </Button>}
            </EditResponseButton>}

            {requestForQuoteDetails?.model?.linkedforms?.length != 0 && <LinkedFormsIcon><RelatedFormsList forms={requestForQuoteDetails?.model?.linkedforms} /></LinkedFormsIcon>}

            <FormMoreOptions
                shareURL={requestForQuoteDetails?.model.share_url}
                deleteAction={requestForQuoteDetails?.actions[ProcurementFormPermission.DELETE]}
                handleDeleteAction={handleDeleteSingleRFQ}
                pdfDocument={<SingleRfqPDFDocument rfq={requestForQuoteDetails} rfqModel={requestForQuoteDetails?.model} />}
                disableDownload={!requestForQuoteDetails?.model?.products?.length}
                formType={ProcurementForms.RFQ}
                formName={requestForQuoteDetails?.model?.name}
                duplicateAction={canCreateRFQ}
                handleDuplicateAction={(data) => handleDuplicate(data)}
                currentProject={requestForQuoteDetails?.model?.project?.id}
                handleRecallAction={handleCancel}
                recallAction={requestForQuoteDetails?.actions[ProcurementFormPermission.RECALL]}
                editAction={requestForQuoteDetails?.actions[ProcurementFormPermission.EDIT] && updatable}
                handleEditAction={handleEdit}
            />
        </>
    )
}
