import { AuthenticateStatus, ProcurementButtons, ProcurementPopupStatus } from '@/config';
import { DescPopup, ProcurementActionsPopupFormContent } from '@/components';
import { useTranslation } from 'next-i18next';
import { MarkChatReadOutlined } from '@mui/icons-material';
import { ProcurementFormModel } from '@/models';
import { useSellerProcurementStore, useUserData } from '@/zustand';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface props {
  purchaseOrder: ProcurementFormModel | null;
}

export const PurchaseOrderSellerPopups: React.VFC<props> = ({ purchaseOrder }) => {
  const { t } = useTranslation();
  const { replace } = useRouter();
  const { confirm, getReasonsForPopup, decline, setPopupStatus } = useSellerProcurementStore(
    state => state.purchaseOrderDetailsSliceActions
  );
  const { customLoadingButton, openedPopupStatus, reasonsForPopup } = useSellerProcurementStore(
    state => state.purchaseOrderDetailsSliceProperties
  );

  const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
  const handleChangesDone = () => {
    const prefix = isGuest ? sm.portals.external.seller : sm.portals.seller;
    replace(prefix.procurement.purchaseOrders.purchaseOrderId(purchaseOrder?.model.id).url, undefined, {
      shallow: false,
    });
  };
  const handleSubmit = values => decline(values.reasonId, handleChangesDone, values.comment);
  const handleConfirmPopup = values => confirm(handleChangesDone, values.comment);
  const handleClosePopup = () => setPopupStatus(null);

  return (
    <>
      <DescPopup open={openedPopupStatus == ProcurementPopupStatus.DECLINE} handleClose={handleClosePopup}>
        <ProcurementActionsPopupFormContent
          handlePopupAction={handleSubmit}
          openedPopupStatus={openedPopupStatus}
          icon={<HighlightOffIcon color='error' />}
          buttonTitle={t('buttons.decline', { formName: purchaseOrder?.model.name })}
          status='error'
          popupTitle={t('buttons.decline', { formName: purchaseOrder?.model.name })}
          getReasonsForPopup={getReasonsForPopup}
          reasonsForPopup={reasonsForPopup}
          popupSubTitle={t('popups.subTitles.decline_po')}
          isButtonLoading={customLoadingButton == ProcurementButtons.DECLINE_PO_BY_SELLER}
        />
      </DescPopup>

      {/* confirm popup */}
      <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CONFIRM} handleClose={handleClosePopup}>
        <ProcurementActionsPopupFormContent
          handlePopupAction={handleConfirmPopup}
          openedPopupStatus={openedPopupStatus}
          icon={<MarkChatReadOutlined color='success' />}
          buttonTitle={t('popups.titles.confirm_po')}
          status='success'
          popupTitle={t('popups.titles.confirm_po')}
          isButtonLoading={customLoadingButton == ProcurementButtons.CONFIRM_PO_BY_SELLER}
        />
      </DescPopup>
    </>
  );
};
