import create from 'zustand';

export const useProfilePhoneNumberFlow = create((set, get) => ({

  phoneVerificationPopupVisible: false,
  phoneVerificationPopupSteps: 0,
  changePhonePopupVisible: false,
  changePhoneByPhonePopupSteps: 0,
  changePhoneByEmailPopupSteps: 0,
  changePhoneViaPhoneToken: null,
  temporaryPhone: null,
  changeDirectPopupVisible: false,
  changeDirectPopupSteps: 0,
  timer: 0,
  isTimer: false,

  setPhoneVerificationSteps: (step: number) => {
    set({ phoneVerificationPopupSteps: step });
  },
  togglePhoneVerificationPopup: (open: boolean) => {
    set({ phoneVerificationPopupVisible: open });
  },
  
  setChangePhoneByPhoneSteps: (step: number) => {
    set({ changePhoneByPhonePopupSteps: step });
  },
  setChangePhoneByEmailSteps: (step: number) => {
    set({ changePhoneByEmailPopupSteps: step });
  },
  toggleChangePhonePopup: (open: boolean) => {
    set({ changePhonePopupVisible: open });
  },
  setChangePhoneViaPhoneToken: (token: string) => {
    set({ changePhoneViaPhoneToken: token });
  },
  setTemporaryPhone: (num: number) => {
    set({ temporaryPhone: num });
  },

  setChangeDirectSteps: (step: number) => {
    set({ changeDirectPopupSteps: step });
  },
  toggleChangeDirectPopup: (open: boolean) => {
    set({ changeDirectPopupVisible: open });
  },

  setIsTimer: (num: boolean) => {
    set({ isTimer: num });
  },
  setTimer: (num: number) => {
    set({ timer: num });
  },

}));
