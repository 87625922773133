import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import Image from 'next/image';

type Props = {
  icon: SvgIconComponent;
  primaryText: string;
  secondaryText: string;
};

export const WhatWeCanOfferCard: React.VFC<Props> = ({
  icon,
  primaryText,
  secondaryText,
}) => {
  return (
    <Card
      variant='outlined'
      sx={{
        height: '100%',
        borderColor: 'warning.light',
        backgroundColor: 'warning.dark',
        borderRadius: 2, 
      }}
    >
      <CardContent>
          <Image
            src={icon}
            height='40px'
            width='40px'
            objectFit='fill'
            alt='Fancy'
          />
        <Typography variant='h6' fontWeight='700' mt={2}>
          {primaryText}
        </Typography>
        <Typography variant='body2' fontWeight='400'  color='text.third' mt={2}>
          {secondaryText}
        </Typography>
      </CardContent>
    </Card>
  );
};
