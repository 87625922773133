import { CancelButton, ConfirmPopup } from "@/components";
import { ProductManagerEnv } from "@/config";
import { useProcurementStore } from "@/zustand";
import { Grid } from "@mui/material";
import { t } from "i18next";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { sm } from "src/site-map";

type CancelAddingItemsButtonProps = {
    popupTitleKey: string
}

export const CancelAddingItemsToForm: React.VFC<CancelAddingItemsButtonProps> = ({ popupTitleKey }) => {
    const { actions, data } = useProcurementStore(state => state.productFormManagerProperties)
    const { push } = useRouter()
    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);

    const handelCancelActions = () => {
        if (data.environment?.type === ProductManagerEnv.catalog) push(sm.portals.seller.products.sellerProductList(data.formDetails?.id).url)
        if (data.environment?.type === ProductManagerEnv.quote) push(sm.portals.seller.procurement.quotes.quoteId(data.formDetails?.id).url)
        actions.reset();
        handleCloseDelPopup()
    }
    return (
        <>
            <Grid>
                <CancelButton handleClick={handleOpenDelPopup} />
            </Grid>
            <ConfirmPopup
                titleKey={t("popups.titles.cancel")}
                subTitleKey={t('popups.subTitles.' + popupTitleKey)}
                buttonTitleKey={t('buttons.yes_confirm')}
                handleClose={handleCloseDelPopup}
                handleConfirm={handelCancelActions}
                open={showDelPopup}
            />
        </>
    )
}