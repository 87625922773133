import {
    ColumnFormat, ColumnSort,
    ColumnType,
    RFQ,
    RfqSellerTableTabs,
    RFQTableRowModel,
    TableColumnMapper,
    TableGroup,
    TableMapper, TableRow, TableRowAction,
    TableType,
    TabMapper
} from "@/models";
import { isGenerateQuoteAllowed, useIndexSellerRequestForQuotes } from "@/services";
import produce from "immer";
import { StateCreator } from "zustand";
import { procurementStatus, RFQTableListOfActions } from "@/config";
import { useUserData } from "src/logic/zustand/user";
import { buildTableGroupModel } from "../../../../services/tables";

export interface RequestForQuotesForSellerTableSlice {
    RFQtableForSellerProperties: {
        // allTableRows: RFQTableRowModel[] | null,
        // tableRowsWhenSelectedTab: RFQTableRowModel[] | null,
        // allTabs: RfqSellerTableTabs[] | null
        isLoading: boolean,
        // canGenerateQuote: boolean,
        // selectedTab: string | null,
        // searchValue?: string | null
        tableGroup: TableGroup | null,
    }
    RFQtableForSellerActions: {
        loadData: () => void,
        // changeTab: (name: string) => void,
        //changeSearchValue: (name: string) => void
        onActionClicked: (row: TableRow, action: TableRowAction, detailsFunctionURL: () => void) => void;
    }
}


export const createRequestForQuotesForSellerTableSlice: StateCreator<RequestForQuotesForSellerTableSlice> = (set, get, api) => ({
    RFQtableForSellerProperties: {
        // allTableRows: null,
        // tableRowsWhenSelectedTab: null,
        // allTabs: getInitialTabs(),
        isLoading: true,
        // canGenerateQuote: false,
        // selectedTab: 'all',
        // searchValue: null,
        tableGroup: null,
    },
    RFQtableForSellerActions: {
        onActionClicked: (row: TableRow, action: TableRowAction) => { },
        loadData: () => {
            //const canGenerateQuote = useUserData.getState().canGenerateQuote
            const userInfo = useUserData.getState().userInfo

            set(produce(draftState => { draftState.isLoading = true }))
            useIndexSellerRequestForQuotes().then(data => {
                const rfqs = data.data
                const tableGroup = buildTableGroupModel(rfqs, getTableMapper(), getColumnMapper(), userInfo.id, false)
                set(produce(draftState => {
                    draftState.RFQtableForSellerProperties.isLoading = false
                    draftState.RFQtableForSellerProperties.tableGroup = tableGroup
                }))
            })
        },
    }
})

function getTableMapper() {
    return [
        new TableMapper(TableType.OTHER, 'RFQs', [
            new TabMapper('all', [procurementStatus.ALL], false),
            new TabMapper('PENDING', [procurementStatus.RFQ_RECEIVED], false),
            new TabMapper('CANCELED', [procurementStatus.CANCELED], false),
            new TabMapper('QUOTE_GENERATED', [procurementStatus.QUOTE_GENERATED], false),
            new TabMapper('DECLINED', [procurementStatus.DECLINED], false),
        ])
    ];
}

function getColumnMapper() {
    const dateColumn = new TableColumnMapper('created_at', 'date', ColumnType.DATE)
    dateColumn.columnSort = new ColumnSort(true, true)

    return [
        new TableColumnMapper('id', 'id', ColumnType.NUMBER, null, null, true),
        new TableColumnMapper('name', 'rfq', ColumnType.STRING, ColumnFormat.URL,
            new TableColumnMapper('bundle_name', 'bundle_name', ColumnType.STRING)
        ),
        new TableColumnMapper('company_details.name', 'buyer', ColumnType.STRING),
        new TableColumnMapper('project.name', 'project', ColumnType.STRING),
        // new TableColumnMapper('rfq_name', 'rfq', ColumnType.STRING),
        dateColumn,
        new TableColumnMapper('number_of_products', 'products', ColumnType.NUMBER),
        new TableColumnMapper('number_of_competitors', 'competitors', ColumnType.NUMBER),
        new TableColumnMapper('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, null, true),
    ];
}