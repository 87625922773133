import {
  CommentsGroupNames,
  procurementStatus,
  UserPortalPermission,
} from '@/config';
import { ProcurementFormPermission } from '@/services';
import { Product } from '../filters';
import { Requisition } from '../requisition';
import { UserInfoType } from '../user';
import { Timeline } from './form-timeline';

export interface GeneralObject {
  id: number;
  name: string;
}

export interface ProcurementData {
  requisitions: Requisition[];
  projects: GeneralObject[];
  sellers: GeneralObject[];
}

export interface SellerProcurementData {}

export interface ProcurementModelDetails {
  id: number;
  code: string;
  name: string;
  created_at: string;
  seller_details: Company;
  buyer_details: Company;
  company_details: Company;
  comments: CommentGroup[];
  user_relation: UserPortalPermission;
  owner_name: string;
  can_edit: boolean;
  new_comments: Comment[];
  number_of_comments: number;
  status: procurementStatus;
  validity_date: string;
  has_new_changes: boolean;
  project: GeneralObject;
  owner_id: number;
  commentable_type: string;
  share_url?: string;
  //is_individual: boolean;
  approvers?: Approver[];
  products: Product[];
  terms_bundle: ProcurementTermsBundle;
  requisition: GeneralObject;
  rfq?: GeneralObject;
  quote?: GeneralObject;
  owner: UserInfoType;
  comparison_table?: GeneralObject;
  is_individual?: boolean;
  timeline?: Timeline;
  deletable?: boolean;
  has_pending_extension_request?: boolean;
  is_expired?: boolean;
  is_rfq_expired?: boolean;
  is_quote_expired?: boolean;
  form_type: LinkType;
  can_update_validity_date?: boolean;
  extension_request_status?: string;
  can_be_recalled?: boolean;
  form_action_permissions?: ProcurementFormActionsModel;
  // allow_add_products_directly?: boolean;
  receiver_project?: GeneralObject;
  sender_project?: GeneralObject;
  linkedforms?: any[];
  number_of_products: number;
  number_of_services: number;
}
export interface ProcurementFormActionsModel {
  deletable?: boolean;
  editable?: boolean;
  can_send_to_counter_party?: boolean;
  recallable?: boolean;
  can_send_for_approval?: boolean;
  can_cancel_approval_request?: boolean;
  can_approve_approval_request?: boolean;
  can_reject_approval_request?: boolean;
  can_edit_approval_decision?: boolean;
  can_update_validity_date?: boolean;
  can_update_project?: boolean;
  can_request_extension?: boolean;
  can_accept_request_extension?: boolean;
  can_decline_request_extension?: boolean;
  can_receiver_decline?: boolean;
  can_receiver_accept?: boolean;
  can_receiver_change_response?: boolean;
  updatable_directly?: boolean;
  can_revoke?: boolean;
}

export interface ProcurementFormModel {
  model: ProcurementModelDetails;
  numberOfProducts: number;
  actions: Record<ProcurementFormPermission, boolean>;
}

export interface BundleProcurementDetails {
  model: ProcurementModelDetails | null;
  numberOfProducts: number | null;
  children: ProcurementFormModel[];
  actions: Record<ProcurementFormPermission, boolean>;
}
export interface Approver {
  approver_id: number;
  status: procurementStatus;
  user_name: string;
}
export interface ProcurementFormProduct {
  id: number;
  productDetails: ProcurementFormProductDetails;
  brandImage: string;
  warrantyTerm: GeneralObject;
  requestedQuantity: number;
  availableQuantity: number;
  unitPrice: string;
  remark?: string;
}

export interface ProcurementFormProductDetails {
  name: string;
  partNumber: string;
  image_url: string;
  selected?: boolean;
}
export interface ProcurementFormProductDetailsApi {
  id: number;
  product: Product;
  quantity: string;
  requested_quantity: number;
  selected: boolean;
  warranty_term: GeneralObject;
  available_quantity: number;
  unit_price: string;
  remarks?: string;
}

export interface FormHeaderDetails {
  name: string;
  status: procurementStatus;
  actions: any;
}
export interface TermsBundle {
  payment_terms: GeneralObject;
  warranty_terms: GeneralObject;
  delivery_terms: GeneralObject;
}

export interface ProcurementTermsBundle {
  payment_term: GeneralObject;
  warranty_term: GeneralObject;
  delivery_term: GeneralObject;
}

export interface Company {
  id: number;
  name: string;
  city_id: number;
  trn_number: string;
  company_address: string;
  work_number: string;
  fax_number: string;
  industry_id: number;
  company_type: string;
  is_private: boolean;
}

export interface Comment {
  id: number;
  label: string;
  title: string;
  text: string;
  user: {
    profile_picture_url: string;
    name: string;
  };
  created_at: string;
}
export interface CommentGroup {
  id: number;
  name: CommentsGroupNames;
  active: boolean;
  commentable_id: number;
  commentable_type: string;
  is_external: boolean;
  comments?: Comment[];
  sub_groups?: CommentGroup[];
  read_only: boolean;
}

export enum ColumnFormat {
  CHIP = 'chip',
  CURRENCY = 'currency',
  URL = 'url',
  SUBTITLE = 'subtitle',
}

export enum ColumnType {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
  READ_TICK = 'read_tick',
  STATUS = 'status',
  LINK = 'link',
  LINK_DOUBLE = 'link_double',
  IMAGE = 'image',
  INPUT = 'input',
  SWITCH = 'switch',
  DELETE = 'delete',
  COUNTER = 'counter',
  BUTTON = 'button',
  SELECT = 'select',
  LONG = 'long',
  DOUBLE = 'double',
  REMARK = 'remark',
  Expiry_mark = 'expired',
  SEGMENT = 'segment',
  PRODUCT_DETAILS = 'product_details',
}

export enum LinkType {
  BUNDLE_RFQ = 'BUNDLE_RFQ',
  RFQ_SINGLE = 'RFQ_SINGLE',
  RFQ_OF_BUNDLE = 'RFQ_OF_BUNDLE',
  RFQ_SELLER = 'RFQ_SELLER',
  QUOTE_SELLER = 'QUOTE_SELLER',
  QUOTE_BUYER = 'QUOTE_BUYER',
  REQUISITION = 'REQUISITION',
  PROJECT_SELLER = 'PROJECT_SELLER',
  PROJECT_BUYER = 'PROJECT_BUYER',
  CATALOG_DRAFT = 'CATALOG_DRAFT',
  BUYER_CLIENT_COMPANY = 'BUYER_CLIENT_COMPANY',
  SELLER_CLIENT_COMPANY = 'SELLER_CLIENT_COMPANY',
  PO_SINGLE = 'PO_SINGLE',
  BUNDLE_PO = 'BUNDLE_PO',
  PO_OF_BUNDLE = 'PO_OF_BUNDLE',
  PO_SELLER = 'PO_SELLER',
  COMPARISON_TABLE = 'COMPARISON_TABLE',
}

export class ColumnSort {
  sortByDefault: boolean = false;
  descending: boolean = false;

  constructor(sortByDefault: boolean, descending: boolean) {
    this.sortByDefault = sortByDefault;
    this.descending = descending;
  }
}

// export
export class TableColumn {
  get hasNewChanges(): boolean {
    return this._hasNewChanges;
  }

  set hasNewChanges(value: boolean) {
    this._hasNewChanges = value;
  }

  name: string;
  title: string;
  type: ColumnType;
  format: null | ColumnFormat;
  description: any;
  secondary_description: any;
  hidden: boolean;
  private _hasNewChanges: boolean;
  private _columnSort: ColumnSort = new ColumnSort(false, false);

  get columnSort(): ColumnSort {
    return this._columnSort;
  }

  set columnSort(value: ColumnSort) {
    this._columnSort = value;
  }

  public getName() {
    console.log('TableColumn');
  }

  constructor(
    name: string,
    title: string,
    type: ColumnType,
    format: ColumnFormat | null,
    description: any,
    secondary_description: any,
    hidden: boolean
  ) {
    this.name = name;
    this.title = title;
    this.type = type;
    this.format = format;
    this.description = description;
    this.secondary_description = secondary_description;
    this.hidden = hidden;
  }
}

export enum TableRowAction {
  SEND_FOR_APPROVAL = 'send_for_approval',
  SEND_REMINDER = 'send_reminder',
  SEND_TO_BUYER = 'send_to_buyer',
  SEND_TO_SELLER = 'send_to_seller',
  EDIT = 'edit',
  VIEW = 'view',
  CONVERT_TO_RFQ = 'convert_to_RFQ',
  ADD_PRODUCTS = 'add_products',
  VIEW_RFQ = 'view_rfq',
}

export class TableRow {
  id: number;
  columns: TableColumn[];
  status: string;
  form_type: string;
  hasNewChanges: boolean;
  columnsAccessor: TableRow;
  ownerId: number;
  actions: TableRowAction[];
  referenceObject: any;
  children: TableRow[] | null;
  parent_id: number | null;

  constructor(
    id,
    columns,
    status,
    form_type,
    hasNewChanges,
    ownerId,
    referenceObject,
    children = null,
    parent_id = null
  ) {
    this.id = id;
    this.columns = columns;
    this.status = status;
    this.form_type = form_type;
    this.hasNewChanges = hasNewChanges;
    this.columnsAccessor = this;
    this.ownerId = ownerId;
    this.actions = [];
    this.referenceObject = referenceObject;
    this.children = children;
    this.parent_id = parent_id;
  }

  public getColumnByName(name: string) {
    return this.columns.find(e => e.name == name);
  }

  public setActions(actions: TableRowAction[]) {
    this.actions = actions;
    return this;
  }

  public getName() {
    console.log('TableRow');
  }
}

export class TableTab {
  title: string;
  name: string;
  rows: TableRow[];
  hasNewChanges: boolean;
  count: number;
  hasActions: boolean;
  hasStatus: boolean;
  key: string;

  constructor(key, title, rows, hasActions, hasStatus: boolean = true) {
    this.key = key;
    this.title = title;
    this.name = title;
    this.rows = rows;
    this.hasActions = hasActions;
    this.hasStatus = hasStatus;

    this.hasNewChanges = rows.find(e => e.hasNewChanges);
    this.count = rows.length;
  }

  public getName() {
    console.log('TableTab');
  }
}

export enum TableType {
  OTHER = 'OTHER',
  MINE = 'MINE',
  MY_APPROVAL = 'MY_APPROVAL',
}

export class Table {
  id: string;
  name: string;
  title: string;
  tabs: TableTab[];
  type: TableType;
  rows: TableRow[];
  key: string;
  hasNewChanges: boolean;

  constructor(key, id, name, title, tabs, type, rows) {
    this.key = key;
    this.id = name;
    this.name = name;
    this.title = title;
    this.tabs = tabs;
    this.type = type;
    this.rows = rows;
    this.hasNewChanges = tabs.find(e => e.hasNewChanges);
  }

  public getName() {
    console.log('Table');
  }
}

export class TableGroup {
  tables: Table[];
  key: string;

  constructor(key, tables) {
    this.key = key;
    this.tables = tables;
  }

  public getName() {
    console.log('TableGroup');
  }

  public shouldShowGroupTabs() {
    const should = this.tables.length > 1;
    return should;
  }
}

export class TableColumnMapper {
  accessorKey: string;
  title: string;
  type: ColumnType;
  hidden: boolean;
  format: ColumnFormat | null;
  secondaryMapper: TableColumnMapper | null;
  computeFunction: (object: any) => any | null;
  private _columnSort: ColumnSort = new ColumnSort(false, false);
  private _childMapper: TableColumnMapper | null = null;

  constructor(
    accessorKey: string,
    title: string,
    type: ColumnType,
    format: ColumnFormat | null = null,
    secondaryMapper: TableColumnMapper | null = null,
    hidden: boolean = false,
    computeFunction: (object: any) => any = null
  ) {
    this.accessorKey = accessorKey;
    this.title = title;
    this.type = type;
    this.hidden = hidden;
    this.format = format;
    this.secondaryMapper = secondaryMapper;
    this.computeFunction = computeFunction;
  }

  get childMapper(): TableColumnMapper | null {
    return this._childMapper;
  }

  set childMapper(value: TableColumnMapper | null) {
    this._childMapper = value;
  }

  get columnSort(): ColumnSort {
    return this._columnSort;
  }

  set columnSort(value: ColumnSort) {
    this._columnSort = value;
  }
}

export class TabMapper {
  title: string;
  statuses: procurementStatus[];
  hasActions: boolean;

  constructor(
    title: string,
    statuses: procurementStatus[],
    hasActions: boolean = false
  ) {
    this.title = title;
    this.statuses = statuses;
    this.hasActions = hasActions;
  }
}

export class TableMapper {
  type: TableType;
  title: string;
  tabMappers: TabMapper[];

  constructor(type: TableType, title: string, tabMappers: TabMapper[]) {
    this.type = type;
    this.title = title;
    this.tabMappers = tabMappers;
  }
}

// export class Tab implements TabModel {
//   checked: boolean;
//   count: number;
//   disabled: boolean;
//   hasNewChanges: boolean;
//   id: number;
//   name: string;
// }
//
// export class TableTab {
//   tab: Tab;
//   rows: TableRow[]
// }

// export class TableResponse<Type> {
//   id: string;
//   name: string;
//
//   @Type(() => Type)
//   rows: TableRow<Type>[];
// }
//
// export class TableGroupResponse<Type> {
//   @Type(() => TableResponse)
//   tables: TableResponse<Type>[];
//
//   public getName() {
//     console.log('tabl grop - getname')
//   }
// }
export * from './purchase-order';
export * from './table';
