import { Box, Grid, InputAdornment, TextField, Typography, Stack, Button } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { StyledComment, BasicTabs, StyledChip, DescPopup, StyledLoadingButton } from '@/components'
import { MarkChatReadOutlined } from "@mui/icons-material";
import { Comment } from "@/models";
import { useTranslation } from 'next-i18next';


type NewCommentsPopupProps = {
    title: string
    buttonTitle: string
    newComments: Comment[]
    closeNewCommentsPopup: () => void
    canUpdateValidityDate?: boolean
    onDeclineRequestExtension?: () => void
    onEditValidityDate?: () => void
    isLoadingDeclineButton?: boolean
    isThereMultipleRequestExtension?: boolean
    onDeclineRequests?: () => void
    subHeader?: React.ReactNode
}

export const NewCommentsPopup: FC<NewCommentsPopupProps> = ({ title, buttonTitle, newComments, closeNewCommentsPopup, canUpdateValidityDate, onDeclineRequestExtension, onEditValidityDate, isLoadingDeclineButton, isThereMultipleRequestExtension, onDeclineRequests, subHeader }) => {
    const { t } = useTranslation();

    return (
        <>
            {newComments &&
                <DescPopup medium open={newComments} handleClose={closeNewCommentsPopup}>
                    <Grid container gap={2}>
                        <Grid item md={12} textAlign='center'>
                            <MarkChatReadOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
                            <Grid item md={12} textAlign='center'>
                                <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                        {subHeader && <Grid item md={12} px={3}>
                            {subHeader}
                        </Grid>}
                        <Grid container>
                            <Stack maxHeight='350px' px={2} mx={1} width="100%">
                                {newComments?.map(comment => (
                                    <Grid container p={1} py={2} my={1} sx={{ bgcolor: 'error.darker', borderRadius: '8px' }}>
                                        <StyledComment key={comment.id} comment={comment} />
                                    </Grid>
                                ))}
                            </Stack>
                            {canUpdateValidityDate ?
                                <Grid item container justifyContent={'center'} md={12} mt={2} gap={1}>
                                    <Grid item md={5}>
                                        {
                                            isThereMultipleRequestExtension ?
                                                <StyledLoadingButton variant="outlined" translateKey="decline_requests" loading={isLoadingDeclineButton} color={'error'} onClick={onDeclineRequests} />
                                                :
                                                <StyledLoadingButton variant="outlined" translateKey="decline_in_new_comments" loading={isLoadingDeclineButton} color={'error'} onClick={onDeclineRequestExtension} />
                                        }
                                        {/* <Button variant="outlined" fullWidth onClick={onDeclineRequestExtension} color='error'>{t('buttons.decline')}</Button> */}
                                    </Grid>
                                    <Grid item md={6}>
                                        <Button variant="contained" fullWidth onClick={onEditValidityDate} color='success'>{t('buttons.edit_expiry_date')}</Button>
                                    </Grid>
                                </Grid>
                                : <Grid item container justifyContent={'center'} md={12} mt={2}>
                                    <Grid item md={6}>
                                        <Button variant="contained" fullWidth onClick={closeNewCommentsPopup}>{buttonTitle}</Button>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </Grid>
                </DescPopup>
            }
        </>
    )
}