import { ExpandableListItemWithIcon } from '@/components';
import { useDrawerStore } from '@/zustand';
import { ListItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import useIsUserPendingApproval from 'src/logic/hooks/auth/use-is-user-pending-approval';

interface SideDrawerItemProps { drawerItemDetails: any, isDrawerOpen: boolean, isFooter?: boolean }

export const SideDrawerItem: React.FC<SideDrawerItemProps> = ({ drawerItemDetails, isDrawerOpen, isFooter }) => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
    const getDrawerSubMenuContent = drawerItemDetails.subMenuContent?.getData()
    const { toggleIsLoadingDrawerSubMenuContent, setDrawerSubMenuParent, openDrawerSubMenu, closeDrawerSubMenu, isDrawerSubMenuOpen, closeDrawer } = useDrawerStore()
    const router = useRouter()
    const currentRoute = router.asPath.split('/')[drawerItemDetails.routeIndex]
    const currentRouteUrl = currentRoute?.includes('?') ? currentRoute.slice(0, currentRoute.indexOf('?')) : currentRoute
    const drawerItemRoute = '/' + drawerItemDetails.url?.split('/')[drawerItemDetails.routeIndex]

    const handleDrawerItemClick = () => {
        if (drawerItemDetails.subMenuContent) {
            toggleIsLoadingDrawerSubMenuContent()
            getDrawerSubMenuContent()
            openDrawerSubMenu(isFooter ?? false)
            setDrawerSubMenuParent(drawerItemDetails)
        }
        else {
            if (isDrawerSubMenuOpen) closeDrawerSubMenu()
            if (drawerItemDetails.url) router.push(drawerItemDetails.url)
            closeDrawer()
        }
    }

    const isUserPendingApproval = useIsUserPendingApproval();

    if(isUserPendingApproval && drawerItemDetails?.onlyApprovedUser) {
        return null;
    }
    return (
        <ListItem disablePadding sx={{ height: 48, textTransform: 'uppercase', my: 1, width: isSmallScreen ? '100%' : '90%' }}>
            <ExpandableListItemWithIcon
                title={t("pages_names." + drawerItemDetails.title)}
                handleClickListItem={handleDrawerItemClick}
                isItemExpanded={isDrawerOpen}
                isItemContainsSubItem={drawerItemDetails.subMenuContent}
                icon={drawerItemDetails.pageIcon}
                selected={'/' + currentRouteUrl === drawerItemRoute}
                notificationNumber={drawerItemDetails.notificationNumber()}
            />
        </ListItem>
    )
}
