import axios from 'axios';
import create from 'zustand';
import { apiURLs } from '@/config';
import { Filters_Res, Product, Filter, FilterItem } from '@/models';

type Filters = {
  data: Product[];
  filter_data: Filters_Res;
};

export const useFilters = create((set, get) => ({
  filters: {} as Filters,
  waitProducts: '' as string,
  waitFilters: '' as string,
  searchText: '' as string,
  getFiltersURL: apiURLs.productsFilterURL as string,
  ApplyFiltersURL: apiURLs.productsApplyFiltersURL as string,
  getFilters: async data => {
    set({ waitFilters: 'loading' });
    const response = await axios.get(get().getFiltersURL, { params: data });
    let newTree = rebuildFiltersTree(response.data.filter_data);
    set({
      filters: {
        data: response.data.data,
        filter_data: newTree,
        sortData: response.data.sort_data,
      },
      waitFilters: 'success',
    });
  },
  setSearchText: async search => {
    set({ searchText: search });
  },
  onSortItemSelected: async sortItemId => {
    set({ waitProducts: 'loading' });
    let filters = get().filters.filter_data;
    let selectedFilterItems = onFilterItemChange(filters);
    const response = await axios.get(get().ApplyFiltersURL, {
      params: {
        'sort by': sortItemId,
        ...selectedFilterItems,
        search_text: get().searchText,
      },
    });
    set({
      filters: {
        data: response.data,
        filter_data: get().filters.filter_data,
        sortData: get().filters.sortData,
      },
      waitProducts: 'success',
    });
  },
  paginateProducts: async (selectedUrl, page) => {
    const response = await axios.get(`${selectedUrl}${page}`);
    set({
      filters: {
        data: response.data,
        filter_data: get().filters.filter_data,
        sortData: get().filters.sortData,
      },
    });
  },
  onResetFilters: async () => {
    set({ waitProducts: 'loading' });
    const filtersData = await axios.get(get().getFiltersURL, {
      params: { search_text: 'a' },
    });
    let newTree = rebuildFiltersTree(filtersData.data.filter_data);
    let tree = [];
    set({
      filters: {
        data: filtersData.data.data,
        filter_data: newTree,
        sortData: filtersData.data.sort_data,
      },
    });
    let filters = get().filters.filter_data;
    tree = onFiltersReset(filters);
    set({
      filters: {
        data: get().filters.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
    });
    let selectedFilterItems = onFilterItemChange(tree);
    const response = await axios.get(get().ApplyFiltersURL, {
      params: { ...selectedFilterItems, search_text: get().searchText },
    });
    set({
      filters: {
        data: response.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
      waitProducts: 'success',
    });
  },
  onItemChecked: async (filterItem, filterParent) => {
    set({ waitProducts: 'loading' });
    let tree = [];
    let filters = get().filters.filter_data;
    tree = onFilterItemChecked(filterItem, filterParent, filters);
    set({
      filters: {
        data: get().filters.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
    });
    let selectedFilterItems = onFilterItemChange(tree);
    const response = await axios.get(get().ApplyFiltersURL, {
      params: { ...selectedFilterItems, search_text: get().searchText },
    });
    set({
      filters: {
        data: response.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
      waitProducts: 'success',
    });
  },
  onItemUnchecked: async (filterItem, filterParent) => {
    set({ waitProducts: 'loading' });
    let tree = [];
    let filters = get().filters.filter_data;
    tree = onFilterItemUnchecked(filterItem, filterParent, filters);
    set({
      filters: {
        data: get().filters.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
    });
    let selectedFilterItems = onFilterItemChange(tree);
    const response = await axios.get(get().ApplyFiltersURL, {
      params: { ...selectedFilterItems, search_text: get().searchText },
    });
    set({
      filters: {
        data: response.data,
        filter_data: tree,
        sortData: get().filters.sortData,
      },
      waitProducts: 'success',
    });
  },
}));
export const onFilterItemChange = filters => {
  let selectedFilters = {};
  filters.map(el => {
    let newEl = el.filter_items
      .filter(el1 => el1.is_selected)
      .map(el2 => {
        return el2.form_id;
      });
    selectedFilters[el.form_name] = newEl;
  });
  return selectedFilters;
};
export const onFilterItemChecked = (filterItem, filter, filters) => {
  let newTree = toggleSelectionOfFilterItem(true, filterItem, filter, filters);
  let finalTree = rebuildFiltersTree(newTree);
  return finalTree;
};

export const onFilterItemUnchecked = (filterItem, filter, filters) => {
  let newTree = toggleSelectionOfFilterItem(false, filterItem, filter, filters);
  let finalTree = rebuildFiltersTree(newTree);
  return finalTree;
};
export const onFiltersReset = filters => {
  let newTree = toggleResetOfFilters(filters);
  let finalTree = rebuildFiltersTree(newTree);
  return finalTree;
};
export const toggleResetOfFilters = filters => {
  let filterTree = filters.map(filterElement => {
    filterElement.filter_items = filterElement.filter_items.map(
      filterItemElement => {
        return {
          ...filterItemElement,
          is_selected: false,
        };
      }
    );
    return filterElement;
  });
  return filterTree;
};
export const toggleSelectionOfFilterItem = (
  isChecked,
  filterItem,
  filter,
  filters
) => {
  let filterTree = filters.map(filterElement => {
    if (filterElement.id == filter.id) {
      filterElement.filter_items = filterElement.filter_items.map(
        filterItemElement => {
          if (filterItemElement.id == filterItem.id)
            return {
              ...filterItemElement,
              is_selected: isChecked,
            };
          return filterItemElement;
        }
      );
      return filterElement;
    }
    return filterElement;
  });
  return filterTree;
};

export const rebuildFiltersTree = filters => {
  let map = new Map<string, string[]>();
  filters.forEach(filterElement => {
    map.set(
      filterElement.id,
      filterElement.filter_items
        ?.filter(item => item.is_selected)
        .map(element => element.id)
    );
  });

  let newFilters = filters.map(filterElement => {
    let isRootFilter = filterElement.parent_id == null;
    let selectedParentFilterItemIds = isRootFilter
      ? []
      : map.get(filterElement.parent_id);
    let isEmpty = selectedParentFilterItemIds.length == 0;

    let newlySelectedFilterItemIds = [];
    let newFilter = {
      ...filterElement,
      filter_items: filterElement.filter_items.map(filterItemElement => {
        let isChildOfSelectedFilterItems = selectedParentFilterItemIds.some(
          ai => filterItemElement.parent_ids.includes(ai)
        );
        let isShown = isEmpty || isChildOfSelectedFilterItems || isRootFilter;
        let isActive = isChildOfSelectedFilterItems || isRootFilter;
        let isSelected = filterItemElement.is_selected && isActive && isShown;
        if (isSelected) newlySelectedFilterItemIds.push(filterItemElement.id);
        return {
          ...filterItemElement,
          hidden: !isShown,
          active: isActive,
          is_selected: isSelected,
        };
      }),
    };
    map.set(newFilter.id, newlySelectedFilterItemIds);
    return newFilter;
  });
  return newFilters;
};
export * from './slices'