import { Typography } from '@mui/material';
import { LabelWithButton, user } from '@/components';
import { useTranslation } from 'next-i18next';
import { useProfilePhoneNumberFlow } from '@/zustand'

type Props = { userData: user };

export const VerifyPhoneButton: React.FC<Props> = ({userData}) => { 
const { t } = useTranslation();
const togglePhoneVerificationPopup = useProfilePhoneNumberFlow(state => state.togglePhoneVerificationPopup);
const setPhoneVerificationSteps = useProfilePhoneNumberFlow(state => state.setPhoneVerificationSteps);
const handleClick = () => { togglePhoneVerificationPopup(true),setPhoneVerificationSteps(0) };

return(
    <LabelWithButton labelTranslateKey="mobile_number" 
        btnColorFill={'secondary'}
        buttonTranslateKey={'send_verification'} 
        onClick={() => handleClick()} 
        >
        <div>
            <Typography
                variant='body2'
                sx={{ display: 'inline', fontWeight: '500' , color: 'text.third'}}
            >
                {userData.mobile_number.number} 
            </Typography>
            <Typography
                variant='body2' color='secondary'
                sx={{ display: 'inline', fontWeight: '500', pl: 1 }}
            >{t('texts.not_verified')}</Typography>
        </div>
    </LabelWithButton>
)}