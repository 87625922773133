import { DescPopup } from '@/components';
import { TableRow, TableRowAction } from "@/models";
import { useProcurementStore } from '@/zustand';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import { sm } from "../../../../../site-map";
import { TableGroup } from "../../shared/table-groups";
import { productManagerEnv } from '@/config';

export const RequisitionsTablePage = () => {

    const { requisitionsTableSliceProperties, requisitionSliceActions } = useProcurementStore()
    const { actions } = useProcurementStore(state => state.productFormManagerProperties)

    const { t } = useTranslation();
    const { push } = useRouter()

    const createRequisition = () => {
        requisitionSliceActions.create({projectId: '', projectName: ''}, () => {})
    }

    const [showConvertToRFQPopup, setShowConvertToRFQPopup] = useState(false);
    const [selectedRequisitionId, setSelectedRequisitionId] = useState(0);
    const handleOpenConvertPopup = (requisitionId: number) => {
        setShowConvertToRFQPopup(true)
        setSelectedRequisitionId(requisitionId)
    }
    const handleCloseConvertPopup = () => {
        setShowConvertToRFQPopup(false)
        setSelectedRequisitionId(0)
    }

    const selectSellers = () => {
        const requisitionId = selectedRequisitionId

        setShowConvertToRFQPopup(false)
        setSelectedRequisitionId(0)

        push({ pathname: sm.portals.buyer.procurement.requisitions.requisition(requisitionId).url, query: { sellers: 'select' } })
    }

    useEffect(() => {
        requisitionsTableSliceProperties.actions.loadData()
    }, [])

    // const setCurrentRequisitionById = useProcurementStore(state => state.setCurrentRequisitionById)

    const onActionButtonClicked = (row: TableRow, action: TableRowAction) => {
        // if (action == TableRowAction.CONVERT_TO_RFQ)
        //     handleOpenConvertPopup(row.id)

        if (action == TableRowAction.ADD_PRODUCTS) {
            actions.setDetails({...row.referenceObject, products: requisitionSliceActions.getProductsArrayFromRequisition(row.referenceObject)}, productManagerEnv.requisition)
            push(sm.catalogue.index.url)
            // setCurrentRequisitionById(row.id)
        }

        // if (action == TableRowAction.VIEW_RFQ) {
        //     push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(row.referenceObject?.bundle_rfq_id).url)
        // }
    }

    return (
        <>
            {
                !requisitionsTableSliceProperties.data.isLoading && <TableGroup
                    key={requisitionsTableSliceProperties.data?.tableGroup?.key ?? ''}
                    title={t('texts.requisitions')}
                    tableGroup={requisitionsTableSliceProperties.data.tableGroup}
                    detailsURLFunction={sm.requisitions.requisition}
                    //onActionClickedFunction={requisitionsTableSliceProperties.actions.onActionClicked}
                    onActionClickedFunction={onActionButtonClicked}
                    onCreateClickedFunction={createRequisition}
                    canCreate={true}
                />
            }

            <DescPopup
                handleClose={handleCloseConvertPopup}
                open={showConvertToRFQPopup}>
                <Grid container gap={2} width={320}>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='overline' color='primary.main' fontWeight="700">
                            {t('popups.titles.convert_to_RFQ')}
                        </Typography>
                    </Grid>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='body2' color='text.disabled'>
                            {t('texts.convert_to_RFQ')}
                        </Typography>
                    </Grid>
                    <Grid item container md={12} justifyContent="center">
                        <Button variant="contained" onClick={selectSellers}>{t('buttons.continue')}</Button>
                    </Grid>
                </Grid>
            </DescPopup>

            {/* <DescPopup open={isCreatePopupOpened} handleClose={closeCreatePopup}>
                <Stack sx={{ alignItems: 'center', textAlign: 'center', m: 1 }}>
                    <Description color="primary" />
                    <CreateRequisitionPopup />
                </Stack>
            </DescPopup> */}
        </>
    )
}

