import {ColumnFormat, ColumnType, RFQTableRowModel, TableColumn, TableRow, TableRowAction, TableTab} from "@/models";
import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import {Link, sortArray, sortDate, sortNumber, sortString, StatusChip, StyledTable} from "@/components";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {makeStyles} from "@mui/styles";
import {ColumnDef} from "@tanstack/react-table";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import DoneAllIcon from "@mui/icons-material/DoneAll";

type GeneralTableProps = {
    key: string
    tab: TableTab
    detailsURLFunction: Function
    onActionClickedFunction: Function
    customURLBuilder: Function | undefined
}

const useStyles = makeStyles({
    cellHead: {},
    styleColumn: {}
});


export const GeneralTable: React.FC<GeneralTableProps> = ({ key, tab, detailsURLFunction, onActionClickedFunction, customURLBuilder= undefined}) => {
    const classes = useStyles()
    const columnObjects = [];
    if (tab.rows.length > 0) {
        const tableColumns = tab.rows[0].columns as TableColumn[]
        tableColumns.filter(e => !e.hidden).map(e => {
            if (e.type == 'string' || e.type == 'number' || e.type == 'date') {
                let sortFunction = null
                switch (e.type) {
                    case 'string':
                        sortFunction = sortString
                        break;
                    case 'number':
                        sortFunction = sortNumber
                        break;
                    case 'date':
                        sortFunction = sortDate
                        break;
                }


                columnObjects.push({
                    id: e.name,
                    accessorKey: 'columnsAccessor',
                    header: e.title,
                    cell: info => <TableText
                        row={info.getValue()}
                        customURLBuilder={customURLBuilder}
                        url={detailsURLFunction(info.getValue().id).url}
                        tableColumn={info.getValue().getColumnByName(e.name)}
                    />,
                    sortingFn: sortFunction,
                    meta: {
                        filterType: 'search',
                        columnIcon: UnfoldMoreIcon,
                        headerCellClass: classes.cellHead,
                        tableRow: {
                            cellClass: classes.styleColumn
                        },
                        sortByDefault: e.columnSort.sortByDefault,
                        sortDescending: e.columnSort.descending
                    },
                    enableColumnFilter: false,
                });
            }

            if (e.type == 'read_tick') {
                columnObjects.push({
                    id: e.name,
                    accessorKey: 'columnsAccessor',
                    header: e.title,
                    cell: info => <TableIsRead
                        value={info.getValue().getColumnByName(e.name).description}
                    />,
                    // sortingFn: sortFunction,
                    meta: {
                        filterType: 'search',
                        columnIcon: UnfoldMoreIcon,
                        headerCellClass: classes.cellHead,
                        tableRow: {
                            cellClass: classes.styleColumn
                        },
                        sortByDefault: e.columnSort.sortByDefault,
                        sortDescending: e.columnSort.descending
                    },
                    enableColumnFilter: false,
                });
            }
        })

        if (tab.hasStatus) {
            columnObjects.push({
                id: 'status',
                accessorKey: 'columnsAccessor',
                header: 'status',
                cell: info => <TableText
                    row={info.getValue()}
                    url={detailsURLFunction(info.getValue().id).url}
                    customURLBuilder={customURLBuilder}
                    tableColumn={new TableColumn('status', 'status', ColumnType.STRING, ColumnFormat.CHIP, info.getValue().status, null, false)}
                />,
                sortingFn: sortString,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.cellBody
                    },
                    sortByDefault: false,
                    sortDescending: false
                },
                enableColumnFilter: false,
            });
        }

        if (tab.hasActions) {
            columnObjects.push({
                accessorKey: 'columnsAccessor',
                header: 'actions',
                cell: info => <TableButton tableRow={info.getValue()} onActionClickedFunction={onActionClickedFunction} detailsFunctionURL={detailsURLFunction(info.getValue().id).url} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.cellBody
                    }
                },
                enableColumnFilter: false,
            });
        }
    }

    const columns = React.useMemo<ColumnDef<RFQTableRowModel>[]>(
        () => columnObjects,
        []
    )

    return (
        <>
            <StyledTable key={key} rows={tab?.rows} columns={columns} />
        </>
    )
}

const TableButton = ({ tableRow, onActionClickedFunction, detailsFunctionURL }) => {

    const { t } = useTranslation()
    const row = tableRow as TableRow
    const { push } = useRouter()

    const handleClickTableButton = (action: TableRowAction) => {
        // onActionClickedFunction(tableRow, action)
        // if(action == TableRowAction.SEND_REMINDER)
        //     onActionClickedFunction(tableRow, action)
        if (action == TableRowAction.VIEW || action == TableRowAction.EDIT || action == TableRowAction.SEND_FOR_APPROVAL || action == TableRowAction.SEND_TO_BUYER)
            push(detailsFunctionURL)
        else
            onActionClickedFunction(tableRow, action)
            //push(detailsFunctionURL)
    }

    return (
        <>
            {row.actions?.map((action) =>
                <Grid container gap={1}>
                    <Button><Typography variant="subtitle2" fontWeight={700} onClick={() => handleClickTableButton(action)}>
                        {t('buttons.' + action)}</Typography>
                    </Button>
                </Grid>
            )}
        </>
    )
}

const TableIsRead = ({ value }) => {
    if(value == 'PENDING')
        return (<></>)

    return (<DoneAllIcon sx={{ color: value == 'READ' ? 'success.dark' : 'text.third', fontSize: '16px' }} />)
}

const TableText = ({ row, tableColumn, url, customURLBuilder }) => {
    return (
        <Grid container width='100%'>
            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
                {tableColumn.hasNewChanges && <CircleIcon sx={{ color: 'success.dark', fontSize: '14px' }} />}
                {tableColumn.format == 'url' && customURLBuilder == undefined && <Link underline='hover' href={url}>
                    <Typography variant='body2' sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {tableColumn.description}
                    </Typography>
                </Link>}
                {tableColumn.format == 'url' && customURLBuilder && <Link key={row.id + '-' + tableColumn.description} underline='hover' href={customURLBuilder(row, tableColumn)}>
                    <Typography variant='body2' sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {tableColumn.description}
                    </Typography>
                </Link>}

                {(!tableColumn.format || tableColumn.format == 'currency') && <Typography variant='subtitle2' fontWeight={400}>{tableColumn.description}</Typography>}

                {tableColumn.format == 'chip' && <StatusChip status={tableColumn.description} />}

                {tableColumn.format == 'subtitle' && <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{tableColumn.description} </span>}

            </Grid>
            {tableColumn.secondary_description && <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{tableColumn.secondary_description} </span>}
        </Grid>
    )
}