import type { FC } from 'react';
import { Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'next-i18next';
import { useField } from 'formik'
import { at } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import { getInputsTranslationKey } from '@/hooks';


interface selectWithPlaceholderProps {
    translateKey: string
    name: string
    fieldValidation: Function
    disabled?: boolean
    required?: boolean
    items: Array<{
        id: number,
        name: string,
    }>
}

const selectTextInputStyle = {
    color: 'text.secondary',
    fontSize: '14px !important',
}
const inputStyle = {
    width: '100%',
};
const textInputStyle = {
    bgcolor: 'white',
    color: '#000000',
    width: '100%',
};

export const SelectWithPlaceholderWithValidation: FC<selectWithPlaceholderProps> = (props: selectWithPlaceholderProps) => {
    const { t } = useTranslation()
    const [field, meta, helpers] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (error) {
            return <Typography variant='subtitle2' >
                {t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
            </Typography>
            // return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
        }
    }

    return (
        <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
        >
            {t(getInputsTranslationKey('labels.' + props.translateKey))}
            <FormControl sx={inputStyle} fullWidth size="small" error={meta.error && true}>
                <Select
                    displayEmpty
                    disabled={props.disabled}
                    inputProps={{ sx: (field.value == 0 ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle) }}
                    error={meta.error && true}
                    {...field}
                    value={field.value}
                    onChange={(e) => helpers.setValue(e.target.value).then(() => props.fieldValidation(props.name))}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: '200px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                    height: 10,
                                    width: 7,
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: `white`,
                                    margin: 2,
                                    marginRight: -2
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'primary.main',
                                    outline: `1px solid transparent`,
                                    borderRadius: 8,
                                },
                            }
                        }
                    }}
                >
                    <MenuItem value={0} disabled style={{ display: "none" }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    <MenuItem value={null} disabled style={{ display: "none" }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    {
                        props.items.map(function (object, i) {
                            return <MenuItem value={object.id} key={object.id} sx={{ mx: 1 }}>{object.name}</MenuItem>;
                        })
                    }
                </Select>
                <FormHelperText>{_renderHelperText()}</FormHelperText>
            </FormControl>
        </Typography>
    );
};
