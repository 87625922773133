import { Loading } from "@/components"
import { Product } from "@/models"
import { Restore } from "@mui/icons-material"
import { Box, CardMedia, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { AlternativeProductRequisitionButton } from "src/ui/components/dumb"

type Props = {
    alternativeProductData: Product,
    waitAlternativesProductDetails: string
};

export const AlternativeProductDetailsHeader: React.VFC<Props> = ({ alternativeProductData, waitAlternativesProductDetails }) => {
    const { t } = useTranslation();
    return (
        <>
            {
                waitAlternativesProductDetails != 'Loading' ?
                    <>
                        <Grid container mb={2}>
                            <Grid container item md={8}>
                                <Typography variant='h5' fontWeight='400' sx={{ textTransform: 'capitalize', color: 'text.disabled' }}>
                                    {t('texts.alternatives_for')}
                                </Typography>
                                <Typography variant='h5' fontWeight='700' ml={1}
                                    sx={{ textTransform: 'capitalize', color: 'text.disabled' }}
                                >{alternativeProductData.name}
                                </Typography>
                            </Grid>
                            <Grid container item md={4} justifyContent="flex-end">
                                <Typography color='text.third' fontWeight='400' variant='body2' textTransform={'lowercase'} sx={{ justifyContent: 'end' }}>
                                    {t('buttons.alternatives')}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container sx={{
                            bgcolor: 'secondary.contrastText', alignItems: 'center', p: 2, borderRadius: '8px',
                        }}>
                            <Grid item container md={10} gap={3} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid item md={1}>
                                    <Box width='100%'>
                                        <CardMedia component='img' sx={{ objectFit: 'contain', height: '13px' }} image={alternativeProductData?.brand.image_url} alt={alternativeProductData?.brand.name} />
                                    </Box>
                                </Grid>
                                <Grid item md={10} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant="h5" sx={{ fontWeight: 700 }}>{alternativeProductData.name}</Typography>
                                    <Restore sx={{ color: 'text.disabled', fontWeight: 700, fontSize: '25px', ml: 3, mb: 1 }} />
                                </Grid>
                            </Grid>
                            <Grid item container md={2}>
                                <AlternativeProductRequisitionButton isAddedToRequisition={false} />
                            </Grid>
                        </Grid>

                    </> : <Loading fullHeight={true} />
            }
        </>
    )
}