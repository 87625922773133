import { Typography } from '@mui/material';
import { useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

interface PhoneInputFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const PhoneInputField = ({ name, label, disabled, required, ...props }: PhoneInputFieldProps) => {
  const [field, meta, helpers] = useField({ name });

  const { touched, error } = meta;
  const shouldShowErrorMessage = Boolean(touched) && Boolean(error);

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2'>
          {label} {required && '*'}
        </Typography>
      )}
      <PhoneInput
        country={'ae'}
        {...field}
        value={field.value}
        onChange={phone => {
          if (phone) {
            helpers.setValue(`+${phone}`);
          } else {
            helpers.setValue(phone);
          }
        }}
        containerStyle={{ width: '100%' }}
        dropdownStyle={{ maxHeight: '150px' }}
        inputStyle={{
          width: '100%',
          borderColor: shouldShowErrorMessage ? '#FF4D4D' : undefined,
          opacity: field.value ? 1 : undefined,
        }}
        inputClass='phone-input'
        buttonStyle={{ borderColor: shouldShowErrorMessage ? '#FF4D4D' : undefined }}
        onBlur={() => helpers.setTouched(true)}
        disabled={disabled}
        {...props}
      />
      {shouldShowErrorMessage && (
        <Typography variant='subtitle2' color='#FF4D4D'>
          {error}
        </Typography>
      )}
    </div>
  );
};
