import { ShoppingCart } from "@mui/icons-material";
import { ReactElement } from "react";

export enum ProductManagerEnv {
    requisition = "REQUISITION",
    purchase_order = "PURCHASE_ORDER",
    edit_po_from_scratch = 'EDIT_PO_FROM_SCRATCH',
    catalog = "CATALOG",
    quote = 'QUOTE',
    edit_bundle_rfq = 'EDIT_BUNDLE_RFQ',
    edit_quote_from_scratch = 'EDIT_QUOTE_FROM_SCRATCH',
}
export enum ProductInCartType {
    PO = 'po',
    MAIN = 'main',
    ALTERNATIVE = 'alternative',
    CATALOG = 'catalog',
    QUOTE = 'QUOTE'
}

export enum ProductManagerPopupStatus {
    CREATE_PO = "CREATE_PO",
    SELECT_PO = "SELECT_PO",
    ADD_SIMILAR_PRODUCT = "ADD_SIMILAR_PRODUCT",
    CREATE_REQUISITION = "CREATE_REQUISITION",
    SELECT_REQUISITION = "SELECT_REQUISITION",
    CREATE_REQUISITION_WITH_SELECT = "CREATE_REQUISITION_WITH_SELECT",
    CREATE_QUOTE = "CREATE_QUOTE"
}

export const productManagerEnv = {
    requisition: { type: ProductManagerEnv.requisition, actionsSliceName: 'requisitionSliceActions', productInCartType: ProductInCartType.MAIN, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: ProductManagerPopupStatus.SELECT_REQUISITION },
    edit_bundle_rfq: { type: ProductManagerEnv.edit_bundle_rfq, actionsSliceName: 'bundleRFQDetailsSliceActions', productInCartType: ProductInCartType.MAIN, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: null },
    edit_quote_from_scratch: { type: ProductManagerEnv.edit_quote_from_scratch, actionsSliceName: 'quoteDetailsSliceActions', productInCartType: ProductInCartType.MAIN, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: null },
    purchase_order: { type: ProductManagerEnv.purchase_order, actionsSliceName: 'purchaseOrderBuyerDetailsSliceActions', productInCartType: ProductInCartType.PO, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: ProductManagerPopupStatus.SELECT_PO },
    edit_po_from_scratch: { type: ProductManagerEnv.edit_po_from_scratch, actionsSliceName: 'purchaseOrderBuyerDetailsSliceActions', productInCartType: ProductInCartType.PO, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: ProductManagerPopupStatus.CREATE_PO },
    catalog: { type: ProductManagerEnv.catalog, actionsSliceName: 'catalogListSliceActions', productInCartType: ProductInCartType.CATALOG, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: null },
    quote: { type: ProductManagerEnv.quote, actionsSliceName: 'quoteDetailsSliceActions', productInCartType: ProductInCartType.QUOTE, mainIconComponent: <ShoppingCart />, productManagerPopupStatus: ProductManagerPopupStatus.CREATE_QUOTE },
}
export interface ProductManagerEnvItem {
    type: ProductManagerEnv;
    actionsSliceName: string;
    productInCartType: ProductInCartType
    mainIconComponent: ReactElement
    productManagerPopupStatus: ProductManagerPopupStatus
}
