import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';

export const validationSchema =
  yup.object({ verification_code: yup.string()
    .required('required')
    .matches(/^[0-9]+$/, "specify_number")
    .min(6, "must_6_characters")
    .max(6, "must_6_characters"), 
})
