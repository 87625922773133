import { BorderColorOutlined, ExpandMoreOutlined } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useTranslation } from 'next-i18next';
import { StyledPopper } from "src/ui/components/shared";

interface EditResponseButtonProps {
    children: any
}
export const EditResponseButton: FC<EditResponseButtonProps> = ({ children }) => {
    const { t } = useTranslation();
    const [anchorShowEditResponse, setAnchorShowEditResponse] = useState<null | HTMLElement>(null);
    const openShowEditResponse = Boolean(anchorShowEditResponse);
    const handleClickEditResponse = (currentTarget: any) => setAnchorShowEditResponse(currentTarget)
    return (
        <>

            <Button
                onClick={(event: React.MouseEvent<HTMLElement>) => handleClickEditResponse(event.currentTarget)}
                variant="contained"
                color="info"
                startIcon={<BorderColorOutlined sx={{ ml: 1 }} />}
                endIcon={<ExpandMoreOutlined sx={{ mr: 1 }} />}
            >
                <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.edit_response')}</Typography>
            </Button>

            <StyledPopper open={openShowEditResponse} anchorEl={anchorShowEditResponse} handleClose={() => setAnchorShowEditResponse(null)}>
                <Grid container sx={{ display: 'flex', flexDirection: 'column', minWidth: 185 }}>
                    {children}
                </Grid>
            </StyledPopper>
        </>
    )
}