const addProduct = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const removeProduct = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const changeQuantity = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const changeAdditionalRequirements = () => {
  return new Promise(resolve => {
    resolve({});
  });
};

const EditBundleRfqSavingServices = {
  addProduct,
  removeProduct,
  changeQuantity,
  changeAdditionalRequirements,
};

export default EditBundleRfqSavingServices;
