import { Close } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";

export const CancelButton: FC<{ handleClick: () => void }> = ({ handleClick }) => {
    return (
        <Button onClick={handleClick} sx={{ opacity: 0.5 }} startIcon={<Close sx={{ color: "error.main", height: 17, mr: -0.5 }} />}>
            <Typography fontWeight={600} variant="body2" color="error">
                {t('buttons.cancel')}
            </Typography>
        </Button>
    )
}