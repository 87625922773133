import { DescPopup, MultilineTextField, RemarkButton, SelectWithPlaceholder, StyledTextField, TableWithPagination, TextFieldCounterForm } from '@/components';
import { ProductTableRow } from "@/models";
import { useSellerProcurementStore } from '@/zustand';
import { Info, TextSnippetOutlined } from '@mui/icons-material';
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, Button, CardMedia, Grid, ThemeOptions, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { ColumnDef } from '@tanstack/react-table';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
import { CustomWarrantyPopper } from '../../poppers';


const useStyles = makeStyles((theme: ThemeOptions) => ({
    cellHead: {
        height: '20px', color: '#8B8D98', fontSize: 16, fontWeight: 400, paddingLeft: 60, textTransform: 'uppercase', width: '400px'
    },
    cellHeadPaddingLeft: {
        height: '20px', paddingLeft: 40
    },
    cellHeadpaddingRight: {
        height: '20px', paddingRight: 15
    },
    columnMarginTop: {
        height: '20px', marginTop: 5, fontSize: 18
    },
    columnNoPadding: {
        height: '20px', fontWeight: 400, padding: 0,
    },
    columnPaddingLeft: {
        height: '20px', paddingLeft: 40
    },
    columnPaddingRight: {
        height: '20px', paddingRight: 10
    },
    head: {
        backgroundColor: theme?.palette?.secondary.contrastText,
        '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 10 },
        '& th:last-child': { borderRadius: '0 6px 6px 0' },
    },
    rowBody: { borderRadius: '6px 6px 6px 6px' },
    rowBodyWhite: { backgroundColor: theme?.palette?.secondary.contrastText },
    rowBodyRedBorder: {
        backgroundColor: theme?.palette?.secondary.contrastText, borderBottom: '1px solid #ff0000', "& .MuiTableCell-root": {
            borderBottom: "1px solid red",
            borderTop: "1px solid red",
        },
        '& .MuiTableCell-root:first-child': {
            borderLeft: '1px solid red',
        },
        '& .MuiTableCell-root:last-child': {
            borderRight: '1px solid red',
        },
    },
}));



type IndividualProductsTableProps = {
    handleAdd: (obj: any) => void
    handleRemove: <T>(index: number) => T | undefined
}

const EMPTY_ARR = [] as any[];
export const SellerQuoteProductsTable: React.FC<IndividualProductsTableProps> = ({ }) => {
    const classes = useStyles()
    const { t } = useTranslation();

    const { productsUpdate } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const { terms, warranty_term, products } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)
    const { values, errors } = useFormikContext();
    const formikSlice = getIn(values, name) || EMPTY_ARR;
    const handlePriceChange = (original: any, price: string) => {
        const updatedProducts = products.map(product => { return product.id == original.id ? { ...product, price: price, unit_price: price } : { ...product } })
        productsUpdate(updatedProducts)
    }
    const handleQuantityChange = (original: any, quantity: number) => {
        const updatedProducts = products.map(product => { return product.id == original.id ? { ...product, quantity: quantity, available_quantity: quantity } : { ...product } })
        productsUpdate(updatedProducts)
    }
    const handleWarrantyTermChange = (original: any, warranty_term: number) => {
        const warrantyTerm = terms.warranty_terms.find(term => term.id == warranty_term)
        const updatedProducts = products.map(product => { return product.id == id ? { ...product, warranty_term: warrantyTerm } : { ...product } })
        productsUpdate(updatedProducts)
    }
    const [tableRows, setTableRows] = useState('');
    useEffect(() => {
        setTableRows(formikSlice);
    }, [formikSlice]);

    const columns = React.useMemo<ColumnDef<ProductTableRow>[]>(
        () => [
            {
                accessorKey: 'productDetails',
                id: 'productDetails',
                header: 'product_details',
                cell: info => <ProductDetails content={info.getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.rowBody
                    }
                },
                enableColumnFilter: false,
            },
            {
                accessorKey: 'brandImage',
                id: 'brandImage',
                header: 'brand',
                cell: (info) => <ImageTable imageUrl={info.getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.columnPaddingLeft,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'warrantyTerm',
                id: 'warrantyTerm',
                header: 'warranty',
                cell: ({ row: { index, original }, getValue }) => {
                    const { setFieldValue, values, errors } = useFormikContext();
                    return (
                        <Grid container alignItems={'start'} justifyContent={'start'} pt={0.5} >
                            <Box width='180px'>
                                <SelectWithPlaceholder
                                    name={`${name}[${index}].warrantyTerm.id`}
                                    items={terms?.warranty_terms ?? []}
                                    translateKey={t('warranty_term')}
                                    noShowLabel
                                    onChange={(value) => { handleWarrantyTermChange(original, value) }}
                                />
                            </Box>
                            {values[index]?.warrantyTerm?.id && values[index]?.warrantyTerm?.id !== warranty_term?.id ? (<CustomWarrantyPopper />) : null}
                        </Grid>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'availableQuantity',
                id: 'availableQuantity',
                header: 'available',
                cell: ({ row: { index, original } }) => {
                    return (
                        <Grid container alignItems={'center'} justifyContent={'center'} >
                            <TextFieldCounterForm onChange={(value) => value && handleQuantityChange(original, value)}
                                name={`${name}[${index}].availableQuantity`}
                            />
                        </Grid>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'unitPrice',
                id: 'unitPrice',
                header: 'unit_price',
                cell: ({ row: { index, original } }) => {
                    return (
                        <Grid container md={12} alignItems={'center'} justifyContent={'center'} >
                            <Box width='88px'>
                                <StyledTextField onChange={(e) => handlePriceChange(original, e.target.value)}
                                    name={`${name}[${index}].unitPrice`} translateKey="unit_price" outlined
                                />
                            </Box>
                        </Grid>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'remark',
                id: 'remark',
                header: 'remarks',
                cell: ({ row: { index, original } }) => (
                    <Grid container alignItems={'start'} justifyContent={'start'} pl={2} >
                        <AddRemark rowIndex={index} row={original} />
                    </Grid>
                ),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMoreIcon,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.columnPaddingLeft
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'empty',
                id: 'empty',
                header: 'empty',
                cell: ({ row: { index, original } }) => (
                    <RemoveAction rowIndex={index} row={original} />
                ),
                meta: {
                    // filterType: 'search',
                    columnIcon: '',
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false
            },
        ]
        ,
        [warranty_term]
    )

    const columnsWithErrors = React.useMemo<ColumnDef<ProductTableRow>[]>(
        () => [
            {
                accessorKey: 'productDetails',
                header: 'empty',
                cell: ({ row: { index, original } }) => {
                    const { setFieldValue, values, errors } = useFormikContext();
                    return (
                        <>
                            {errors[index] && <Info color='error' />}
                        </>
                    )
                },
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    tableRow: {
                        cellClass: classes.columnPaddingRight
                    }
                },
                enableColumnFilter: false
            }
        ]
        ,
        []
    )
    return (
        <>
            <TableWithPagination
                disablePagination
                columns={errors.length != 0 ? [...columns, ...columnsWithErrors] : columns}
                data={tableRows}
                key={tableRows}
                tableMeta={{
                    getRowStyle: (index) => {
                        return errors[index] ? classes.rowBodyRedBorder : classes.rowBodyWhite
                    },
                    headStyle: classes.head
                }}
            />
        </>
    )
}

const ProductDetails = ({ content }) => {
    const { t } = useTranslation();
    return (
        <Grid container item md={12}>
            <Grid item md={3}>
                <Box m={1}>
                    <CardMedia component="img" sx={{ objectFit: 'contain', height: '28px' }} image={content.image_url} alt={content.name} />
                </Box>
            </Grid>
            <Grid item md={9}>
                <Typography variant='body2' fontWeight={700} color={'text.disabled'}
                    sx={{ width: '100%', display: '-webkit-box', WebkitLineClamp: 1, lineBreak: 'loose', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}  >
                    {content.name}
                </Typography>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }} alignItems={'center'}>
                    <Typography variant='subtitle2' fontWeight={400} color={'text.third'} mr={1}>{t('texts.part_number')}</Typography>
                    <Typography variant='subtitle2' fontWeight={500} color={'text.disabled'} >{content.partNumber}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

const ImageTable = ({ imageUrl }) => {
    return (
        <Grid container pl={2}>
            <Box sx={{ height: '25px', width: '50px' }} m={1}>
                <CardMedia component="img" sx={{ objectFit: 'contain', height: '100%' }} image={imageUrl} />
            </Box>
        </Grid>
    )
}

const RemoveAction = (props: { row: ProductTableRow }) => {
    const { products } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)
    const { productsUpdate } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const handleDeleteProduct = (id: string) => productsUpdate(products.filter(product => product.id != row.id))

    return (
        <Grid container>
            <Button onClick={() => removeProduct(props.row.productId)}>
                {/* <Box width='27px' height='32px' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: 'warning.contrastText', borderRadius: '4px' }}> */}
                    {/* <DeleteSmallIcon /> */}
                    <Delete fontSize='small'/>
                {/* </Box> */}
            </Button>
        </Grid>
    )
}

const AddRemark = (props: { rowIndex: number, row: ProductTableRow }) => {
    const { t } = useTranslation();
    const { setFieldValue, values } = useFormikContext();
    const { productsUpdate } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const { products } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)
    const [showAddRemarkPopup, setShowAddRemarkPopup] = useState(false)
    const openAddRemarkPopup = () => setShowAddRemarkPopup(true)
    const closeAddRemarkPopup = () => setShowAddRemarkPopup(false)

    const handleChangeRemarkAction = (original: any, remark: string) => {
        const updatedProducts = products.map(product => { return product.id == original.id ? { ...product, remarks: remark } : { ...product } })
        productsUpdate(updatedProducts)
    }
    const updateRemarkOnProduct = (remark: string) => {
        setFieldValue(`${name}[${props.rowIndex}].remark`, remark)
        handleChangeRemarkAction(props.row, remark)
        // updateProductDetails(props.row)
        closeAddRemarkPopup()
    }
    const removeRemark = () => updateRemarkOnProduct('')
    const editRemark = (remark: string) => updateRemarkOnProduct(remark)
    return (
        <>
            {
                values[props.rowIndex]?.remark ? <RemarkButton remark={values[props.rowIndex].remark} contentDetails={values[props.rowIndex]} deleteRemarkHandler={removeRemark} handleRemarkChange={editRemark} />
                    : <>
                        <Button onClick={openAddRemarkPopup}>
                            <Typography variant='body2' fontWeight={400} color={'warning.active'} sx={{ textDecoration: 'underline' }}>{t('buttons.add_remark')}</Typography>
                        </Button>
                        <DescPopup open={showAddRemarkPopup} handleClose={closeAddRemarkPopup}>
                            <RemarkPopupContent updateRemarkOnProduct={updateRemarkOnProduct} />
                        </DescPopup>
                    </>
            }
        </>
    )
}
const RemarkPopupContent = ({ updateRemarkOnProduct }) => {
    const { t } = useTranslation();
    const [remark, setRemark] = useState('');


    return (
        <Grid container gap={2} width={300}>
            <Grid item md={12} textAlign='center'>
                <TextSnippetOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
                <Grid item md={12} textAlign='center'>
                    <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                        {t('buttons.add_remark')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container gap={1} >
                <Grid item container gap={1} md={12}>
                    <Typography variant='subtitle2' sx={{ color: 'text.third' }}>{t('buttons.add_remark')}</Typography>
                    <MultilineTextField value={remark} placeholder={t('input_fields.type_your_remark')} handleValueChange={val => setRemark(val)} />
                    <Button variant="contained" fullWidth onClick={() => updateRemarkOnProduct(remark)}>{t('buttons.add_remark')}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}