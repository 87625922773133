import { FC } from "react"
import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { ProcurementButtons, ProcurementPopupStatus } from "@/config"
import { MarkChatReadOutlined } from "@mui/icons-material"
import { PurchaseOrderRedirectPopup } from "../purchase-order-success-popup"
import { DescPopup, ProcurementActionsPopupFormContent, SendToOtherCompany } from "src/ui/components/shared"
import { GeneralObject } from "@/models";
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface PurchaseOrderPopupsProps {
    openedPopupStatus: ProcurementPopupStatus
    openedSuccessPopupsStatus: ProcurementPopupStatus
    handleClosePopup: () => void
    reasonsForPopup?: GeneralObject[]
    defaultValidityDate?: string | undefined;
    poId?: number | undefined
    purchaseOrderName?: string | undefined;
}
export const PurchaseOrderPopups: FC<PurchaseOrderPopupsProps> = ({ openedPopupStatus, handleClosePopup, openedSuccessPopupsStatus, reasonsForPopup, defaultValidityDate, poId, purchaseOrderName }) => {
    const { t } = useTranslation();
    const { sendForApproval, getReasonsForPopup, reject, approve, cancel, sendSinglePoToSeller, recallSinglePO, cancelSinglePO } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions)
    const { customLoadingButton } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceProperties)

    const handleGetReasonsForPopup = () => getReasonsForPopup()
    const handleSendForApprovalAction = (values: any) => sendForApproval(values.comment)
    const handleRejectPopup = (values: any) => reject(values.reasonId, values.comment)
    const handleApprovePopup = (values: any) => approve(values.comment)
    const handleCancelApproval = (values: any) => cancel(values.comment)
    const handleSendSinglePOToSeller = (values: any) => sendSinglePoToSeller(poId, values.validity_date, values.comment)
    const handleRecallSinglePO = (values: any) => recallSinglePO(values.comment, values.reasonId)
    const handleCancelSinglePO = (values: any) => cancelSinglePO(values.comment, values.reasonId)

    return (
        <>
            {/* Send for approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleSendForApprovalAction}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined />}
                    buttonTitle={t('popups.titles.send_for_approval')}
                    status="primary"
                    popupTitle={t('popups.titles.send_for_approval')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_SINGLE_PO_FOR_APPROVAL}
                />
            </DescPopup>
            {/* <PurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.sent_for_approval')}
                subTitle={t('popups.subTitles.purchase_order_sent_for_approval')}
                colorTitle='success.main'
            /> */}

            {/* Approve popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.APPROVE} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleApprovePopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                    buttonTitle={t('popups.titles.approve_purchase_order')}
                    status="success"
                    popupTitle={t('popups.titles.approve_purchase_order')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_SINGLE_PO}
                />
            </DescPopup>

            {/* Reject popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.REJECT} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRejectPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='error' />}
                    buttonTitle={t('buttons.reject_purchase_order')}
                    status="error"
                    popupTitle={t('popups.titles.reject_purchase_order')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_SINGLE_PO}
                />
            </DescPopup>

            {/* cancel approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelApproval}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='error' />}
                    buttonTitle={t('buttons.cancel_approval_request')}
                    status="error"
                    popupTitle={t('buttons.cancel_approval_request')}
                    popupSubTitle={t('popups.subTitles.cancel_approval_purchase_order')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_SINGLE_PO_APPROVAL}
                />
            </DescPopup>
            {/* <PurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.CANCEL_APPROVAL}
                icon={<MarkChatReadOutlined color='error' />}
                title={t('popups.titles.approval_canceled')}
                colorTitle='error.main'
            /> */}


            {/* Send To seller popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SENT_TO_SELLER} handleClose={handleClosePopup}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary' />}
                    popupTitle={t('popups.titles.send_single_po_to_seller')}
                    validTitle={t('texts.purchase_order') + " " + t('texts.valid_until')}
                    buttonTitle={t('popups.titles.send_single_po_to_seller')}
                    handlePopupAction={handleSendSinglePOToSeller}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_SINGLE_PO_TO_SELLER}
                />
            </DescPopup>
            {/* <PurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.SENT_TO_SELLER}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.success_send_single_po_to_seller')}
                colorTitle="success.main"
            /> */}

            {/*ReCall po - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.RECALL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRecallSinglePO}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.recall_po')}
                    status="error"
                    popupTitle={t('buttons.recall_po')}
                    popupSubTitle={t('popups.subTitles.recall_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_SINGLE_PO}
                />
            </DescPopup>

            {/*Cancel po - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelSinglePO}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.cancel_form', { formName: purchaseOrderName })}
                    status="error"
                    popupTitle={t('buttons.cancel_form', { formName: purchaseOrderName })}
                    popupSubTitle={t('popups.subTitles.cancel_single_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_SINGLE_PO}
                />
            </DescPopup>

        </>
    )
}