import React from 'react';
import Category from 'src/logic/models/catalogue/Category';

function useSearchFilterCategoryItems<T = Category>(items: T[] | null | undefined) {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const filteredItems = React.useMemo(() => {
    if (!searchValue) return items;
    return items?.filter(item => item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  }, [items, searchValue]);

  return {
    searchValue,
    setSearchValue,
    filteredItems,
  };
}

export default useSearchFilterCategoryItems;
