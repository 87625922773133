import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import ErrorIcon from "@mui/icons-material/Error"

import { useTranslation } from 'react-i18next';

type IProps = {
  height?: number | string;
  onRefetch?: Function;
  text?: string;
  textProps?: TypographyProps;
  btnProps?: ButtonProps;
  wrapperProps?:BoxProps 
} ;

const ErrorWithRefetchButton: React.FC<IProps> = ({
  height,
  text,
  onRefetch,
  ...props
}: IProps) => {
  const { t } = useTranslation();
  const sx = props.wrapperProps?.sx ?? {};

  return (
    <Box
      sx={{
        width: '100%',
        height: height ?? 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        ...sx,
      }}
      {...props.wrapperProps}
    >
      <ErrorIcon color="error" style={{ fontSize: "50px" }} />
      <Typography align='center' color='red' variant='h5' fontWeight={700} lineHeight={1.1} {...props.textProps}>
        {text ?? t('something_went_wrong')}
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button type='button' variant='contained' {...props.btnProps} onClick={(e) => {
            onRefetch?.();
            props.btnProps?.onClick?.(e)
        }}>
          {t('refetch')}
        </Button>
      </div>
    </Box>
  );
};
export default ErrorWithRefetchButton;
