import * as React from 'react';
import type { FC } from 'react';
import { Typography, Autocomplete, TextField, Paper, Stack, Box } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { FormikContext, useField, useFormikContext } from 'formik'
import { at } from 'lodash';
import { selectItem } from "@/components"
import { getLoginTranslationKey, getInputsTranslationKey } from '@/hooks'

const inputStyle = {
  width: '100%',
};
const textInputStyle = {
  bgcolor: 'white',
  height: '40px',
};

interface AutoCompleteProps {
  translateKey: string,
  name: string,
  freeSolo?: string,
  items: Array<selectItem>,
  onChange?: Function,
  onSearch?: Function,
  required?: boolean,
  defaultValue?: selectItem,
  autoFocus?: boolean
}

export const AutoComplete: FC<AutoCompleteProps> = (props: AutoCompleteProps) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField(props);
  const { values, setFieldValue } = useFormikContext()
  const [freeSoloField, freeSoloMeta, freeSoloHelpers] = useField({ name: props.freeSolo });

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
    }
  }

  return (
    <Typography
      component='label'
      color='text.third'
      fontWeight='400'
      variant='subtitle2'
      sx={{ display: 'block' }}
    >
      {t(getInputsTranslationKey('labels.' + props.translateKey))} {props.required ? ' *' : ''}
      <Autocomplete
        options={props.items}
        defaultValue={props.defaultValue}
        getOptionLabel={(option) => option?.name ?? values[props.freeSolo]}
        onChange={(e, v) => {
          if (props.onChange)
            props.onChange(e, v)
          if (props.freeSolo) {
            if (v.id) setFieldValue(props.freeSolo, '')
            else setFieldValue(props.freeSolo, values[props.freeSolo])
          }

          helpers.setValue(v ? v.id : '')
        }}
        ListboxProps={{
          sx: {
            maxHeight: 140,
            overflow: 'auto',
            mx: 1,
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: 2,
              height: 2,
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: `white`,
              mt: 1,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#13628C',
              outline: `1px solid slategrey`,
              borderRadius: 3,
            },
          }
        }}
        freeSolo={props.freeSolo}
        renderInput={(params) =>
          <TextField
            sx={inputStyle}
            placeholder={t(getInputsTranslationKey('placeholders.' + props.translateKey))}
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            {...params}
            // {...field.value ? { ...params } : { ...params, inputProps: { ...params.inputProps, value: '' } }}
            {...field}
            InputProps={{
              ref: params.InputProps.ref,
              sx: textInputStyle,
              autoFocus: props.autoFocus ?? false
            }}
            onChange={(e, v) => {
              if (props.onSearch)
                props.onSearch(e, v)
              if (props.freeSolo)
                setFieldValue(props.freeSolo, e.target.value)
            }}
          />
        }
      />
    </Typography>
  );
}