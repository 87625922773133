import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { useProfilePasswordFlow } from '@/zustand'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next';
import { NotifyError, NotifySuccess } from '../Notify-toast'

/* change password */

const changePassword = async (data) => {
  return request({
    url: apiRoutes.changePassword,
    method: "POST",
    data: data
  })
}
export const useChangePassword = () => {
  const setChangePasswordSteps = useProfilePasswordFlow(state => state.setChangePasswordSteps);
  const PasswordPopupSteps = useProfilePasswordFlow(state => state.PasswordPopupSteps);
  const { t } = useTranslation();
  const onSuccess = () => { setChangePasswordSteps(PasswordPopupSteps + 1) }
  const wrongPasswordHandler = (data, variables) => {
    if(data.data.error.code == "AUTH0006")
      variables.actions.setFieldError('current_password', 'wrong_password')
    NotifyError(t('notifications.' + data.data.error.message)) ;
  }
  return useMutation((formData) => changePassword(formData.values), { onSuccess: () => onSuccess() , onError: (data, variables) => wrongPasswordHandler(data, variables) })
}

const requestVerificationCode = async (data) => {
  return request({
    url: apiRoutes.requestVerificationCode,
    method: "POST",
    data:data
  })
}
export const useRequestVerificationCodeEMAIL = () => {
  const { t } = useTranslation();
  const setChangePasswordByEmailSteps = useProfilePasswordFlow(state => state.setChangePasswordByEmailSteps);
  const togglePasswordByEmailPopup = useProfilePasswordFlow(state => state.togglePasswordByEmailPopup);
  const setPasswordByCurrentFinish = useProfilePasswordFlow(state => state.setPasswordByCurrentFinish);
  const setIsTimer = useProfilePasswordFlow(state => state.setIsTimer);
  const setTimer = useProfilePasswordFlow(state => state.setTimer);
  
  const onSuccessEMAIL = () => { setPasswordByCurrentFinish(true), togglePasswordByEmailPopup(true),setChangePasswordByEmailSteps(0),NotifySuccess(t("notifications.verification_code"))}
  const showError = (data) => { setIsTimer(true); setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }

  return useMutation((data) => requestVerificationCode(({send_to: data})), { onSuccess: () =>  onSuccessEMAIL() , onError: (data) => showError(data) })
}

export const useRequestVerificationCodePHONE = () => {
  const { t } = useTranslation();
  const setChangePasswordByPhoneSteps = useProfilePasswordFlow(state => state.setChangePasswordByPhoneSteps);
  const togglePasswordByPhonePopup = useProfilePasswordFlow(state => state.togglePasswordByPhonePopup);
  const setPasswordByCurrentFinish = useProfilePasswordFlow(state => state.setPasswordByCurrentFinish);
  const setIsTimer = useProfilePasswordFlow(state => state.setIsTimer);
  const setTimer = useProfilePasswordFlow(state => state.setTimer);
    
  const onSuccessPHONE = () => { setPasswordByCurrentFinish(true), togglePasswordByPhonePopup(true),setChangePasswordByPhoneSteps(0) ,NotifySuccess(t("notifications.verification_code_phone"))}
  const showError = (data) => {  setIsTimer(true); setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  
  return useMutation((data) => requestVerificationCode(({send_to: data})), { onSuccess: () =>  onSuccessPHONE() , onError: (data) => showError(data) })
}

const checkVerificationCode = async (data) => {
  return request({
    url: apiRoutes.checkVerificationCode,
    method: "POST",
    data: data
  })
}
export const useCheckVerificationCode = () => {
  const { t } = useTranslation();
  const setChangePasswordByPhoneSteps = useProfilePasswordFlow(state => state.setChangePasswordByPhoneSteps);
  const PasswordPopupByPhoneSteps = useProfilePasswordFlow(state => state.PasswordPopupByPhoneSteps);
  const setChangePasswordByEmailSteps = useProfilePasswordFlow(state => state.setChangePasswordByEmailSteps);
  const PasswordPopupByEmailSteps = useProfilePasswordFlow(state => state.PasswordPopupByEmailSteps);

  const setChangePasswordToken = useProfilePasswordFlow(state => state.setChangePasswordToken);

  const onSuccess = (data) => { setChangePasswordByPhoneSteps(PasswordPopupByPhoneSteps+1),setChangePasswordByEmailSteps(PasswordPopupByEmailSteps+1), setChangePasswordToken(data.data.token) }
  const showError = (data) => {data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))}

  return useMutation((data) => checkVerificationCode(data), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data) })
}

const changePasswordWithToken = async (data) => {
  return request({
    url: apiRoutes.changePasswordWithToken,
    method: "POST",
    data: data
  })
}
export const useChangePasswordWithToken = () => {
  const setChangePasswordByPhoneSteps = useProfilePasswordFlow(state => state.setChangePasswordByPhoneSteps);
  const PasswordPopupByPhoneSteps = useProfilePasswordFlow(state => state.PasswordPopupByPhoneSteps);
  const setChangePasswordByEmailSteps = useProfilePasswordFlow(state => state.setChangePasswordByEmailSteps);
  const PasswordPopupByEmailSteps = useProfilePasswordFlow(state => state.PasswordPopupByEmailSteps);
  const changePasswordToken = useProfilePasswordFlow(state => state.changePasswordToken);
  const { t } = useTranslation();

  const onSuccess = () => { setChangePasswordByPhoneSteps(PasswordPopupByPhoneSteps+1),setChangePasswordByEmailSteps(PasswordPopupByEmailSteps+1) }
  const showError = (data) => {data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))}

  return useMutation((data) => changePasswordWithToken({ ...data, token: changePasswordToken }), { onSuccess: () => onSuccess() , onError: (data) => showError(data) })
}

const requestVerificationCodeAgain = async (data) => {
  return request({
    url: apiRoutes.reRequestVerificationCode,
    method: "POST",
    data:data
  })
}
export const useReRequestVerificationCodeAgain = () => {
  const { t } = useTranslation();

  const onSuccess = () => {NotifySuccess(t("notifications.re_verification_code_sent"))}
  const showError = (data) => {data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))}

  return useMutation((data) => requestVerificationCodeAgain(({send_to: data})), { onSuccess: () =>  onSuccess() , onError: (data) => showError(data) })
}
