import { Grid, Typography, ToggleButtonGroup, ToggleButton, Chip } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { getAccountTranslationKey } from '@/hooks';
import { UseRenderState } from 'framer-motion/types/render/types';
import { StyledToggle, Loading } from '@/components';
import { useCompanyUserInfo, useUpdateUserType } from '@/services';
import { useUsersStore } from '@/zustand';

type Props = { userData: UseRenderState, userId?: string, refetch?: Function, disabled: boolean };

export const UserTypeSection: React.FC<Props> = ({
    userData,
    userId,
    refetch,
    disabled
}) => {

    const { t } = useTranslation();
    const [alignment, setAlignment] = React.useState(userData.user_role);
    const onSuccess = () => refetch()
    const { mutate, isLoading } = useUpdateUserType(onSuccess);
    const account_types = useUsersStore(state => state.account_types);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        mutate({ account_type: newAlignment, id: userId })
        setAlignment(newAlignment);
    };
    return (
        <Grid container>
            <Grid item container xs={7} alignItems='center'>
                <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>
                    {disabled ? t(getAccountTranslationKey('sections_titles.type')) : t(getAccountTranslationKey('sections_titles.set_type'))}
                </Typography>
            </Grid>
            <Grid item container xs={5}alignItems='center' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {disabled ?
                    <Chip
                        sx={{ height: 32, width: '164px', backgroundColor: 'success.dark', minWidth: '80px' }}
                        label={
                            <Typography variant='subtitle2' style={{ color: 'white', textTransform: 'capitalize' }}>
                                {t("user_role." + userData.user_role)}
                            </Typography>
                        }
                    />
                    : <StyledToggle disabled={isLoading || disabled} handleChange={handleChange} options={account_types} translateKey='user_role' selectedValue={alignment} />}
            </Grid>
        </Grid>
    );
};