import { FC, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from 'next-i18next';
import { DescPopup } from "@/components";

type ConvertToRFQButtonProps = { redirectHandler: () => void, primary?: boolean }

export const ConvertToRFQButton: FC<ConvertToRFQButtonProps> = ({ primary, redirectHandler }) => {
    const router = useRouter()
    const { t } = useTranslation();
    //convert to rfq popup
    const [showConvertToRFQPopup, setShowConvertToRFQPopup] = useState(false);
    const handleOpenConvertPopup = () => setShowConvertToRFQPopup(true)
    const handleCloseConvertPopup = () => setShowConvertToRFQPopup(false);
    const selectSuppliers = () => {
        handleCloseConvertPopup()
        redirectHandler()
    }
    return (
        <>
            <Button {...(primary && { variant: "contained" })} onClick={handleOpenConvertPopup} >
                {!primary ? <Typography variant='subtitle2' fontWeight={700}>{t('buttons.convert_to_RFQ')} </Typography> : t("buttons.convert_to_RFQ")}
            </Button>

            <DescPopup
                handleClose={handleCloseConvertPopup}
                open={showConvertToRFQPopup}
            >
                <Grid container gap={2} width={320}>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='overline' color='primary.main' fontWeight="700">
                            {t('popups.titles.convert_to_RFQ')}
                        </Typography>
                    </Grid>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='body2' color='text.disabled'>
                            {t('texts.convert_to_RFQ')}
                        </Typography>
                    </Grid>
                    <Grid item container md={12} justifyContent="center">
                        <Button variant="contained" onClick={selectSuppliers}>{t('buttons.continue')}</Button>
                    </Grid>
                </Grid>
            </DescPopup>
        </>
    )
}