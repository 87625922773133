import React, { useCallback } from 'react';
import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useTranslation } from 'next-i18next';
 
export const MapComponent: React.FC<GoogleMapProps> = props => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAZQ5HMfDEtuD4NRh_7JJTRc_e5OnJ9DXg',
  });
  const { t } = useTranslation();

  const renderMap = useCallback(() => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    // const onLoad = useCallback(mapInstance => {
    //   // do something with map Instance
    // });
    return (
      <GoogleMap
        {...props}
        center={{
          lat: -3.745,
          lng: -38.523,
        }}
        zoom={3}
        defaultCenter={{ lat: -25.363882, lng: 131.044922 }}
        containerElement={<div style={{width: 200, height: 200}} />}
      />
    );
  }, [props]);

  if (loadError) return <div>{t('texts.map_not_loaded')}</div>;

  return isLoaded ? renderMap() : <>{t('texts.loading')}</>;
};
