import { Badge, } from '@mui/material';
import React, { ReactNode } from 'react';

export const StyledBadge: React.VFC<{ content?: number, children?: ReactNode }> = ({ children, content }) => {

    return (
        <Badge
            color='success'
            badgeContent={content}
            sx={{
                '& span': {
                    color: 'white',
                    marginTop: '26%',
                    marginRight: '18%',
                    height: 19,
                    width: 12,
                    borderRadius: '50%',
                    border: 2,
                    borderColor: 'white',
                    fontWeight: 500,
                    fontSize: '10px',
                    alignItems:'center',
                    textAlign:'center',
                    justifyContent:'center'
                },
            }}
        >
            {children}
        </Badge>
    );
};
