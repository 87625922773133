import { useProcurementStore } from '@/zustand';
import usePopup from 'src/logic/hooks/use-popup';
import * as yup from 'yup';

const validationSchema = yup.object({
  payment_term_id: yup.string().required('required').nullable(),
  warranty_term_id: yup.string().required('required').nullable(),
  delivery_term_id: yup.string().required('required').nullable(),
});

const useGeneralTerms = () => {
  const generalTermsPopup = usePopup();

  const { warranty_term, payment_term, delivery_term, select_sellers_data } =
    useProcurementStore(state => state.editBundleRFQDetailsSliceProperties);

  const initialValues = {
    payment_term_id: payment_term?.id,
    warranty_term_id: warranty_term?.id,
    delivery_term_id: delivery_term?.id,
  };

  const termsData = select_sellers_data?.terms ?? {
    payment_terms: [],
    warranty_terms: [],
    delivery_terms: [],
  };

  return {
    initialValues,
    validationSchema,
    generalTermsPopup,
    termsData,
  };
};

export default useGeneralTerms;
