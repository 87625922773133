import { StateCreator } from 'zustand';
import produce from 'immer';
import {
  ComparisonTablesData,
  ComparisonTablesProduct,
  ComparisonTablesProducts,
  GeneralObject,
  ReceivedQuote,
  ReceivedQuoteDetails,
  SellerDetails,
  TabModel,
} from '@/models';
import {
  getCTFormActions,
  useCreatePurchaseOrderFromCT,
  useGetCTDetails,
  useGetShowBundlePoCT,
  useGetShowPoCT,
} from 'src/logic/services/procurement/comparison-table';
import {
  buildBuyerQuoteDetails,
  buildQuoteProductsTable,
  NotifyError,
  NotifySuccess,
  useChangeProject,
  useRequestExtension,
  useUpdateBundlePurchaseOrder,
} from '@/services';
import { ProcurementButtons, ProcurementForms } from '@/config';
import { t } from 'i18next';
import { useUserData } from '../../user';
import { sm } from 'src/site-map';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';

export enum ctQuotesStatus {
  PENDING = 'PENDING',
  QUOTED = 'QUOTED',
  NOT_QUOTED = 'NOT_QUOTED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  EXPIRED_EXTENSION_REQUESTED = 'EXPIRED_EXTENSION_REQUESTED',
}

export interface ComparisonTableDetailsSlice {
  fetchComparisonTableDetailsQueryStatus: QueryHandlerModel;
  // loadingComparisonTableDetails: boolean
  comparisonTableData: ComparisonTablesData | null;
  comparisonTableProducts: ComparisonTablesProducts[] | null;
  originalComparisonTableProducts: ComparisonTablesProducts[] | null;
  selectedMainProductId: number;
  selectedMainProduct: ComparisonTablesProduct | null;
  selectedAlternativeProducts: ComparisonTablesProduct[];
  ctTabs: TabModel[] | null;
  isBundlePO: boolean;
  purchaseOrderId: string;
  // popupDoneGenerated: boolean,
  relatedType: string;
  relatedId: number;
  isShowSpecificQuotes: boolean;
  getComparisonTableDetails: (ctId: number) => void;
  buildModelData: (ctModel: any) => void;
  setSelectedMainProduct: (mainProductId: number) => void;
  setCtTabs: (products: ComparisonTablesProducts[] | undefined | null) => void;
  selectSellersForCtProduct: (
    ctProduct: ComparisonTablesProduct,
    seller: SellerDetails,
    isProductAlternative: boolean
  ) => void;
  updateSellerCTProduct: (ctProduct: ComparisonTablesProduct, seller: SellerDetails) => ComparisonTablesProduct;
  numSellerForSelectedCTProduct: (ctProduct: ComparisonTablesProducts) => number;
  clearItems: (selectedMainProductId: number) => void;
  // receivedQuotes: ReceivedQuote[] | null
  // selectedQuote: ReceivedQuoteDetails | null
  // selectedQuoteId: number
  // setSelectedQuote: (quoteId: number) => void
  // updateQuoteSelect: (ctQuote: ReceivedQuoteDetails | undefined) => ReceivedQuoteDetails | undefined
  generatePOFromCT: (redirect?: (link: string) => void) => void;
  // setPopupDoneGenerated: (isOpen: boolean) => void
  getShowPoCT: (id: number | undefined, type: ProcurementForms | undefined) => void;
  getShowBundlePoCT: (id: number | undefined, type: ProcurementForms | undefined) => void;
  EditCT: (redirect?: (link: string) => void) => void;
  resetCT: (buildCTModel: any, type: string, id: number) => void;
  updateIsShowSpecificQuotes: (value: boolean) => void;
  customLoadingButton: ProcurementButtons | null;
  setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void;
  changeProjectName: (project: GeneralObject) => void;
  requestExtensionForCTQuote: (quoteId: number, stopLoading: () => void) => void;
}

export const createComparisonTableDetailsSlice: StateCreator<ComparisonTableDetailsSlice> = (set, get, api) => ({
  fetchComparisonTableDetailsQueryStatus: QueryHandler.getInitialStatus(),
  // loadingComparisonTableDetails: true,
  comparisonTableData: null,
  comparisonTableProducts: [],
  originalComparisonTableProducts: [],
  // receivedQuotes: [],
  selectedMainProductId: 0,
  selectedMainProduct: null,
  selectedAlternativeProducts: [],
  ctTabs: [],
  isBundlePO: false,
  purchaseOrderId: '0',
  // selectedQuote: null,
  // selectedQuoteId: 0,
  // popupDoneGenerated: false,
  relatedType: '',
  relatedId: 0,
  isShowSpecificQuotes: true,
  customLoadingButton: null,
  getComparisonTableDetails: (ctId: number) => {
    // set(produce(draftState => { draftState.loadingComparisonTableDetails = true }))
    set(
      produce(draftState => {
        draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getStartStatus();
      })
    );
    useGetCTDetails(ctId)
      .then(data => {
        const ctModel = data.data;
        get().buildModelData(ctModel);
      })
      .catch(response => {
        set(
          produce(draftState => {
            draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getErrorStatus(response);
          })
        );
      });
  },
  buildModelData: ctModel => {
    const buildCTModel = buildInitialCT(ctModel);
    const firstQuote: ReceivedQuote | null = buildCTModel?.quotes?.length ? buildCTModel.quotes[0] : null;

    set(
      produce(draftState => {
        draftState.isBundlePO = false;
        // draftState.loadingComparisonTableDetails = false
        draftState.comparisonTableData = buildCTModel;
        draftState.comparisonTableProducts = buildCTModel?.products;
        draftState.originalComparisonTableProducts = buildCTModel?.products;
        // draftState.receivedQuotes = buildCTModel?.quotes
        draftState.selectedMainProductId = buildCTModel?.products[0].main_product.product.id;
        draftState.selectedMainProduct = buildCTModel?.products[0].main_product;
        draftState.selectedAlternativeProducts = buildCTModel?.products[0].alternative_products;
        // draftState.selectedQuoteId = firstQuote?.quoteDetails?.quoteModel.id
        // draftState.selectedQuote = firstQuote?.quoteDetails?.quoteModel
        // draftState.popupDoneGenerated = false
        draftState.relatedType = '';
        draftState.relatedId = 0;
        draftState.isShowSpecificQuotes = true;
        draftState.customLoadingButton = null;
      })
    );
    get().setCtTabs(buildCTModel.products);
    get().setTimeline(ctModel.timeline);
    set(produce(draftState => {
      draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getSuccessStatus();
    }))
  },
  setCtTabs: (tabsArray: ComparisonTablesProducts[] | undefined | null) => {
    let updatedTabs: TabModel[] = [];
    tabsArray?.map(element => {
      updatedTabs.push({
        id: element.main_product.product.id,
        name: element.main_product.product.name,
        count: element.alternative_products.length,
        detail: element.numberOfSelectedSellers,
        isPrivate: element.main_product.product.is_private_product,
      });
    });
    set(
      produce(draftState => {
        draftState.ctTabs = updatedTabs;
      })
    );
  },
  setSelectedMainProduct: (mainProductId: number) => {
    let selectedProduct = get().comparisonTableProducts?.find(item => item.main_product.product.id == mainProductId);
    // let selectedProductWithSortedSeller = getProductSort(selectedProduct)
    let ctProducts = get().comparisonTableProducts?.map(comparisonTableProduct => {
      if (comparisonTableProduct.main_product.product.id == selectedProduct?.main_product.product.id)
        return selectedProduct;
      else return comparisonTableProduct;
    });
    set(
      produce(draftState => {
        draftState.convertToRFQProducts = ctProducts;
        draftState.selectedMainProduct = selectedProduct?.main_product;
        draftState.selectedAlternativeProducts = selectedProduct?.alternative_products;
        draftState.selectedMainProductId = mainProductId;
      })
    );
    get().setCtTabs(get().comparisonTableProducts);
  },
  selectSellersForCtProduct: (
    ctProduct: ComparisonTablesProduct,
    seller: SellerDetails,
    isProductAlternative: boolean
  ) => {
    const draftComparisonTableProducts = get().comparisonTableProducts?.map(comparisonTableProduct => {
      if (!isProductAlternative && comparisonTableProduct.main_product.product.id == ctProduct.product.id) {
        const updatedMainProduct = get().updateSellerCTProduct(comparisonTableProduct.main_product, seller);
        return produce(comparisonTableProduct, draftComparisonTableProduct => {
          draftComparisonTableProduct.main_product = updatedMainProduct;
          draftComparisonTableProduct.numberOfSelectedSellers =
            get().numSellerForSelectedCTProduct(draftComparisonTableProduct);
        });
      } else if (isProductAlternative) {
        return produce(comparisonTableProduct, draftComparisonTableProduct => {
          draftComparisonTableProduct.alternative_products = comparisonTableProduct.alternative_products.map(
            alternativesCTProduct => {
              if (alternativesCTProduct.product.id == ctProduct.product.id)
                return get().updateSellerCTProduct(alternativesCTProduct, seller);
              else return alternativesCTProduct;
            }
          );
          draftComparisonTableProduct.numberOfSelectedSellers =
            get().numSellerForSelectedCTProduct(draftComparisonTableProduct);
        });
      } else return comparisonTableProduct;
    });
    const draftSelectedMainProduct = draftComparisonTableProducts?.find(
      draftCTProducts => draftCTProducts.main_product.product.id == get().selectedMainProductId
    );

    set(
      produce(draftState => {
        draftState.comparisonTableProducts = draftComparisonTableProducts;
        draftState.originalComparisonTableProducts = draftComparisonTableProducts;
        draftState.selectedMainProduct = draftSelectedMainProduct?.main_product;
        draftState.selectedAlternativeProducts = draftSelectedMainProduct?.alternative_products;
      })
    );
    get().setCtTabs(get().comparisonTableProducts);
  },
  updateSellerCTProduct: (ctProduct: ComparisonTablesProduct, seller: SellerDetails) => {
    return produce(ctProduct, draftCTProduct => {
      draftCTProduct.sellers = ctProduct.sellers?.map(ctSeller => {
        return ctSeller.id == seller.id
          ? produce(ctSeller, draftCtSeller => {
              draftCtSeller.is_selected = !ctSeller.is_selected;
            })
          : ctSeller;
      });
    });
  },
  numSellerForSelectedCTProduct: (ctProduct: ComparisonTablesProducts) => {
    let num = 0;
    ctProduct.main_product.sellers?.forEach(seller => {
      if (seller.is_selected) {
        num++;
      }
    });
    ctProduct.alternative_products?.forEach(alternative => {
      alternative.sellers?.forEach(seller => {
        if (seller.is_selected) {
          num++;
        }
      });
    });
    return num;
  },
  clearItems: (selectedMainProductId: number) => {
    let selectedProduct = get().comparisonTableProducts?.find(
      ctProduct => ctProduct.main_product.product.id == selectedMainProductId
    );
    let mainProduct = selectedProduct?.main_product;
    let updatedAlternativeProducts = selectedProduct?.alternative_products?.map(alt => clearSelectedItems(alt));
    let ctProduct = produce(selectedProduct, draftSelectedProduct => {
      draftSelectedProduct.main_product = clearSelectedItems(mainProduct);
      draftSelectedProduct.alternative_products = updatedAlternativeProducts;
      draftSelectedProduct.numberOfSelectedSellers = 0;
    });
    let updatedComparisonTableProducts = get().comparisonTableProducts?.map(comparisonTableProduct => {
      if (comparisonTableProduct.main_product.product.id == ctProduct?.main_product.product.id) return ctProduct;
      else return comparisonTableProduct;
    });
    set(
      produce(draftState => {
        draftState.selectedMainProduct = ctProduct?.main_product;
        draftState.selectedAlternativeProducts = ctProduct?.alternative_products;
        draftState.comparisonTableProducts = updatedComparisonTableProducts;
        draftState.originalComparisonTableProducts = updatedComparisonTableProducts;
      })
    );
    get().setCtTabs(updatedComparisonTableProducts);
  },
  // setSelectedQuote: (quoteId: number) => {
  //     const selectedCTQuote = get().receivedQuotes?.find(item => item.quoteDetails.quoteModel.id == quoteId)
  //     let updatedQuoteSelected = get().updateQuoteSelect(selectedCTQuote?.quoteDetails.quoteModel)
  //     let updateReceivedQuotes = get().receivedQuotes?.map(ctQuote => {
  //         if (quoteId == ctQuote.quoteDetails.quoteModel.id) return produce(ctQuote, draftCtQuote => { draftCtQuote.quoteModel = updatedQuoteSelected })
  //         else return ctQuote
  //     })
  //     set(produce(draftState => {
  //         draftState.receivedQuotes = updateReceivedQuotes
  //         draftState.selectedQuote = updatedQuoteSelected
  //         draftState.selectedQuoteId = updatedQuoteSelected?.id
  //     }))
  // },
  // updateQuoteSelect: (ctQuote: ReceivedQuoteDetails | undefined) => {
  //     let updateCTQuote = ctQuote
  //     return produce(ctQuote, draftCtQuote => { draftCtQuote.is_selected = !updateCTQuote?.is_selected })
  // },
  generatePOFromCT: (redirect?: (link: string) => void) => {
    const selectedSellerIds = getSelectedIDs(get().comparisonTableProducts);
    if (selectedSellerIds?.length != 0) {
      get().setCustomLoadingButton(ProcurementButtons.GENERATE_PO_FROM_CT);
      useCreatePurchaseOrderFromCT(get().comparisonTableData?.id, selectedSellerIds)
        .then(data => {
          const response = data.data;
          const buildCTModel = buildInitialCT(response.comparison_table);
          get().resetCT(buildCTModel, response.purchase_order.type, response.purchase_order.id);
          NotifySuccess(t('popups.titles.PO_Generated'));

          if (response.purchase_order.type === 'BUNDLE') {
            redirect?.(
              sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(response.purchase_order.id).url
            );
          } else {
            redirect?.(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(response.purchase_order.id).url);
          }
        })
        .catch(() => get().setCustomLoadingButton(null));
    } else NotifyError('No items selected');
  },
  // setPopupDoneGenerated: (isOpen: boolean) => {
  //     set(produce(draftState => { draftState.popupDoneGenerated = isOpen }))
  // },
  getShowPoCT: (poId: number | undefined, type: ProcurementForms | undefined) => {
    // set(produce(draftState => { draftState.loadingComparisonTableDetails = true }))
    set(
      produce(draftState => {
        draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getStartStatus();
      })
    );
    useGetShowPoCT(poId)
      .then(data => {
        const ctModel = data.data;
        get().buildModelData(ctModel);
        set(
          produce(draftState => {
            draftState.relatedType = type;
            draftState.relatedId = poId;
          })
        );
      })
      .catch(response => {
        set(
          produce(draftState => {
            draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getErrorStatus(response);
          })
        );
      });
  },
  getShowBundlePoCT: (bundlePoId: number | undefined, type: ProcurementForms | undefined) => {
    // set(produce(draftState => { draftState.loadingComparisonTableDetails = true }))
    set(
      produce(draftState => {
        draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getStartStatus();
      })
    );
    useGetShowBundlePoCT(bundlePoId)
      .then(data => {
        const ctModel = data.data;
        get().buildModelData(ctModel);
        set(
          produce(draftState => {
            draftState.relatedType = type;
            draftState.relatedId = bundlePoId;
          })
        );
      })
      .catch(response => {
        set(
          produce(draftState => {
            draftState.fetchComparisonTableDetailsQueryStatus = QueryHandler.getErrorStatus(response);
          })
        );
      });
  },
  EditCT: (redirect?: (link: string) => void) => {
    const selectedSellerIds = getSelectedIDs(get().comparisonTableProducts)?.map(id => ({
      comparison_table_product_seller_id: id,
    }));

    if (selectedSellerIds?.length !== 0) {
      get().setCustomLoadingButton(ProcurementButtons.GENERATE_PO_FROM_CT);

      return useUpdateBundlePurchaseOrder(get().relatedId, get().comparisonTableData?.id, selectedSellerIds)
        .then(data => {
          const response = data.data;
          // const buildCTModel = buildInitialCT(response.comparison_table);
          // get().resetCT(buildCTModel, response.purchase_order.type, response.purchase_order.id);

          if (response.purchase_order.type === 'BUNDLE') {
            redirect?.(
              sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(response.purchase_order.id).url
            );
          } else {
            redirect?.(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(response.purchase_order.id).url);
          }
        })
        .catch(() => {
          get().setCustomLoadingButton(null);
          return null;
        });
    } else {
      NotifyError('No items selected');
      return null;
    }
  },
  resetCT: (buildCTModel: any, type: string, id: number) => {
    set(
      produce(draftState => {
        draftState.comparisonTableData = buildCTModel;
        draftState.comparisonTableProducts = buildCTModel.products;
        draftState.originalComparisonTableProducts = buildCTModel.products;
        // draftState.receivedQuotes = buildCTModel.quotes
        draftState.selectedMainProductId = buildCTModel.products[0].main_product.product.id;
        draftState.selectedMainProduct = buildCTModel.products[0].main_product;
        draftState.selectedAlternativeProducts = buildCTModel.products[0].alternative_products;
        // draftState.selectedQuoteId = buildCTModel.quotes[0]?.id
        // draftState.selectedQuote = buildCTModel.quotes[0]
        draftState.isBundlePO = type == 'BUNDLE';
        draftState.purchaseOrderId = id;
        // draftState.popupDoneGenerated = true
        draftState.customLoadingButton = null;
      })
    );
    get().setCtTabs(buildCTModel?.products);
  },
  updateIsShowSpecificQuotes: (value: boolean) => {
    const comparisonTableProducts: any = get().comparisonTableProducts;
    let updatedComparisonTableProducts: any = get().comparisonTableProducts;
    if (!value) {
      updatedComparisonTableProducts = comparisonTableProducts?.map(item => {
        const mainProduct = getProductWithReceivedQuotesOnly(item.main_product);
        const alternativeProducts = item.alternative_products?.map(alternative =>
          getProductWithReceivedQuotesOnly(alternative)
        );
        return { ...item, main_product: mainProduct, alternative_products: alternativeProducts };
      });
    }
    const selectedProduct = updatedComparisonTableProducts?.find(
      (item: any) => item.main_product.product.id === get().selectedMainProductId
    );
    const originalSelectedProduct = get().originalComparisonTableProducts?.find(
      (item: any) => item.main_product.product.id === get().selectedMainProductId
    );

    set(
      produce(draftState => {
        draftState.isShowSpecificQuotes = value;
        draftState.comparisonTableProducts = value
          ? get().originalComparisonTableProducts
          : updatedComparisonTableProducts;
        draftState.selectedMainProductId = get().selectedMainProductId;
        draftState.selectedMainProduct = value ? originalSelectedProduct?.main_product : selectedProduct?.main_product;
        draftState.selectedAlternativeProducts = value
          ? originalSelectedProduct?.alternative_products
          : selectedProduct?.alternative_products;
      })
    );
  },
  changeProjectName: (project: GeneralObject) => {
    const modelAfterUpdateProject = useChangeProject(project, get().comparisonTableData, false, true);
    set(
      produce(draftState => {
        draftState.comparisonTableData = modelAfterUpdateProject;
      })
    );
  },
  setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
    set(
      produce(draftState => {
        draftState.customLoadingButton = customButton;
        if (!customButton) {
          draftState.customLoadingButton = null;
        }
      })
    );
  },
  requestExtensionForCTQuote: (quoteId: number, stopLoading: () => void) => {
    // get().setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
    let mainProductId = get().selectedMainProductId;

    useRequestExtension(quoteId, 'Quote')
      .then(data => {
        // set(produce(draftState => { draftState.customLoadingButton = null }))
        let updatedCt = findSellerByQuoteIdAndUpdateStatus(
          get().comparisonTableData,
          quoteId,
          'EXPIRED_EXTENSION_REQUESTED'
        );
        // window.location.reload();
        stopLoading();
        set(
          produce(draftState => {
            draftState.comparisonTableData = updatedCt;
            draftState.comparisonTableProducts = updatedCt?.products;
            draftState.originalComparisonTableProducts = updatedCt;
            draftState.selectedMainProductId = updatedCt?.products[0].main_product.product.id;
            draftState.selectedMainProduct = updatedCt?.products[0].main_product;
            draftState.selectedAlternativeProducts = updatedCt?.products[0].alternative_products;
          })
        );
        get().setSelectedMainProduct(mainProductId);

        get().setCtTabs(updatedCt?.products);
        NotifySuccess(t('notifications.extension_requested'));
      })
      .catch(() => {
        // get().setCustomLoadingButton(null),
        stopLoading();
      });
  },
});

function getProductWithReceivedQuotesOnly(product: ComparisonTablesProduct) {
  let newProduct = product?.sellers?.filter((seller: any) => seller?.status === 'QUOTED');
  return { ...product, sellers: newProduct };
}

function buildInitialCT(ctModel: any) {
  let updatedProducts = ctModel.products?.map((product: any) => buildCTProduct(product));
  let updatedQuotes = ctModel.quotes?.map((quote: any) => buildCTQuote(quote));
  let hasPermission = useUserData.getState().canCreatePO;
  // let isThereProductHasQuote = updatedProducts?.some((product: ComparisonTablesProducts) => product?.hasQuoteReceived == true);
  // let numberOfProducts = ctModel.products.filter((item: any) => item.product.category_kind === 1).length;
  // let numberOfServices = ctModel.products.filter((item: any) => item.product.category_kind === 2).length;

  return {
    ...ctModel,
    products: updatedProducts,
    quotes: updatedQuotes,
    numberOfProducts: ctModel.number_of_products ?? 0,
    numberOfServices: ctModel.number_of_services ?? 0,
    actions: getCTFormActions(ctModel, hasPermission),
  };
}

function getNumberOfSelectedSellers(sellers: any[]) {
  return sellers.reduce((acc, curr) => {
    if (curr.is_selected) return acc + 1;
    return acc;
  }, 0);
}

function buildCTProduct(product: any) {
  const numberOfSelectedSellers =
    getNumberOfSelectedSellers(product.sellers) +
    product.alternatives.reduce((acc, curr) => acc + getNumberOfSelectedSellers(curr.sellers), 0);

  let updatedSellersInAlternatives = product.alternatives?.map((alternative: any) => {
    let sortedSellers = SortIfHasQuote(alternative);
    let updatedSellers = sortedSellers.sellers?.map((item: any) => ({
      ...item,
      isLess: product.quantity > item.quantity,
      is_selected: item.is_selected,
      isPrivate: item.is_private,
    }));
    return { ...alternative, sellers: updatedSellers };
  });
  let updateSellersInMainProduct = product.sellers?.map((item: any) => {
    return {
      ...item,
      isLess: product.quantity > item.quantity,
      is_selected: item.is_selected,
      isPrivate: item.is_private,
    };
  });
  let newProduct = { ...product, sellers: updateSellersInMainProduct };
  let sortedSellersInMainProduct = SortIfHasQuote(newProduct);
  let mainProduct = Object.assign({}, sortedSellersInMainProduct);
  delete mainProduct['alternatives'];

  let sortedSellersInAlternatives = sortAlternativesByStatus(updatedSellersInAlternatives);
  const IFProductHasQuote = CheckIfProductHasQuote(sortedSellersInMainProduct);

  return {
    main_product: mainProduct,
    alternative_products: sortedSellersInAlternatives,
    numberOfSelectedSellers,
    warranty_term: newProduct.warranty_term.name,
    hasQuoteReceived: IFProductHasQuote,
  };
}

// function buildStatusCt(status: procurementStatus): ctQuotesStatus {
//     if (status == procurementStatus.SENT_TO_BUYER || status == procurementStatus.PO_GENERATED) {
//         return ctQuotesStatus.QUOTE_RECEIVED;
//     } else if (status == procurementStatus.DECLINED) {
//         return ctQuotesStatus.DECLINED;
//     } else {
//         return ctQuotesStatus.PENDING;
//     }
// }

function CheckIfProductHasQuote(product) {
  const mainProductSellerWithQuote = product?.sellers?.find((seller: SellerDetails) => seller.has_quote);
  if (mainProductSellerWithQuote) {
    return true;
  }

  for (const alternative of product?.alternatives) {
    const alternativeSellerWithQuote = alternative?.sellers?.find((seller: SellerDetails) => seller.has_quote);
    if (alternativeSellerWithQuote) {
      return true;
    }
  }

  return false;
}

function SortIfHasQuote(product: any) {
  let sortedSellers = product.sellers.slice().sort((a: any, b: any) => b.has_quote - a.has_quote);
  let sortedProduct = { ...product, sellers: sortedSellers };
  return sortedProduct;
}

function buildCTQuote(quote: any) {
  let quoteBuild = buildBuyerQuoteDetails(quote);
  let buildProductsTableRows = buildQuoteProductsTable(quoteBuild);
  let buildQuote = { ...quoteBuild, productsTableRows: buildProductsTableRows };
  return { quoteDetails: buildQuote, numberOfProducts: quote.number_of_products };
}

const sortSellersBySelected = (sellers: any) => {
  return sellers?.slice().sort(function (x, y) {
    return Number(y.is_selected) - Number(x.is_selected);
  });
};

function clearSelectedItems(ctProduct: ComparisonTablesProduct | undefined) {
  let unSelectedSellers = ctProduct?.sellers?.map(seller => {
    return { ...seller, is_selected: false };
  });
  return produce(ctProduct, draftCtProduct => {
    draftCtProduct.sellers = unSelectedSellers;
  });
}

function getSelectedIDs(ctProducts: ComparisonTablesProducts[] | null) {
  const ids = ctProducts?.flatMap(product =>
    [product.main_product]
      .concat(product.alternative_products)
      .flatMap(altProd => altProd.sellers?.filter(seller => seller.is_selected).map(seller => seller.id))
  );
  return ids;
}

function sortAlternativesByStatus(updatedAlternatives: any) {
  let sortedByQuoteReceived = updatedAlternatives
    .sort((a: any, b: any) => {
      const hasStatusA = a.sellers.some((seller: SellerDetails) => seller.status === ctQuotesStatus.QUOTED);
      const hasStatusB = b.sellers.some((seller: SellerDetails) => seller.status === ctQuotesStatus.QUOTED);
      if (hasStatusA && !hasStatusB) return -1;
      else if (!hasStatusA && hasStatusB) return 1;
      else return a.sellers.length - b.sellers.length;
    })
    .sort((a: any, b: any) => {
      const hasStatusA = a.sellers.some((seller: SellerDetails) => seller.status === ctQuotesStatus.QUOTED);
      const hasStatusB = b.sellers.some((seller: SellerDetails) => seller.status === ctQuotesStatus.QUOTED);
      if (!hasStatusA && !hasStatusB) return 0;
      else if (!hasStatusA) return 1;
      else if (!hasStatusB) return -1;
      else return 0;
    });
  return sortedByQuoteReceived;
}

const findSellerByQuoteIdAndUpdateStatus = (
  comparisonTableData: ComparisonTablesData,
  quoteId: number,
  newStatus: string
) => {
  const updatedProducts = comparisonTableData.products?.map(product => {
    const updatedMainProduct = updateProductStatus(product.main_product, quoteId, newStatus);
    const updatedAlternativeProducts = product.alternative_products?.map(alternative =>
      updateProductStatus(alternative, quoteId, newStatus)
    );

    return {
      main_product: updatedMainProduct,
      alternative_products: updatedAlternativeProducts,
      numberOfSelectedSellers: product.numberOfSelectedSellers,
      warranty_term: product.warranty_term,
      hasQuoteReceived: product.hasQuoteReceived,
    };
  });

  return {
    ...comparisonTableData,
    products: updatedProducts,
  };
};

const updateProductStatus = (ctProduct: ComparisonTablesProduct, quoteId: number, newStatus: string) => {
  const updatedSellers = ctProduct.sellers?.map(seller => {
    if (seller.quote_id === quoteId) {
      const updatedSeller = { ...seller, status: newStatus };
      return updatedSeller;
    } else {
      return seller;
    }
  });

  return {
    ...ctProduct,
    sellers: updatedSellers,
  };
};
