import { ResponsiveContainer } from '@/styles';
import { Box, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import { ArrowForward } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useAnimateOnView } from '@/hooks';
import { Link } from '@/components';
import { useTranslation } from 'next-i18next';
import { getHomeTranslationKey } from '@/hooks';
import { sm } from 'src/site-map';

type Props = {};

export const WorkersHomeSection: React.FC<Props> = ({ }) => {
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const { t } = useTranslation();

  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <Grid container sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          width: smallScreens ? '100%' : '80%',
          height: 'calc(100% + 30px)',
          borderRadius: t => ({ lg: t.spacing(0, 6, 0, 0), xs: undefined }),
          left: 0,
          bottom: 0,
          zIndex: -1,
          bgcolor: 'background.primary.main',
          backgroundImage: 'url(/assets/images/home/points.svg)',
        }}
      />
      <Grid
        item
        order={[1, 1, 1, 0]}
        component={ResponsiveContainer}
        lg={7}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box>
          <Typography
            {...first}
            component={motion.div}
            mt={smallScreens ? 3 : 0}
            variant='h2'
            fontWeight='700'
          >
            {t(getHomeTranslationKey('titles.workers'))}
          </Typography>

          <Typography
            {...second}
            component={motion.div}
            color='text.third'
            mt={1}
            variant='body2'
            sx={{ fontWeight: '400' }}
          >
            {t(getHomeTranslationKey('sub_titles.workers'))}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex' }}>
            <Button
              endIcon={<ArrowForward />}
              variant='contained'
              component={Link}
              href={{ pathname: sm.register.index.url }}
              sx={{
                width: smallScreens ? '100%' : '176px',
                marginBottom: 4,
              }}
            >
              {t('buttons.registration')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        lg={5}
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 0,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            borderRadius: t =>
              smallScreens ? t.spacing(0) : t.spacing(6, 0, 0, 0),
          }}
        >
          <img
            src='/assets/images/home/workers.png'
            alt='Hand shake'
            width='100%'
            height='100%'
          />
        </Box>
        {/* <Box
          component={motion.div}
          {...second}
          sx={{
            position: 'relative',
            height: smallScreens ? 400 : '100%',
            width: smallScreens ? '100%' : 592,
            borderRadius: t =>
              smallScreens ? t.spacing(0) : t.spacing(6, 0, 0, 0),
            overflow: 'hidden',
          }}
        >
          <Image
            src='/assets/images/home/workers.png'
            layout='fill'
            objectFit='fill'
            alt='Hand shake'
          />
        </Box> */}
      </Grid>
    </Grid>
  );
};
