export const formatNumber = (num: number): string =>
  num.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

export const formatInteger = (num: number): string =>
  num.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });
