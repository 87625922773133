import type { FC } from 'react';
import { Box, Switch } from "@mui/material";
import { useField } from 'formik'

interface SwitchWithLabelProps{
    disabled?:boolean,
    name: string,
    withStyling: boolean,
    checked: boolean,
    onSwitch: Function,
    children: React.ReactNode
}

export const SwitchWithLabel: FC<SwitchWithLabelProps> = (props: SwitchWithLabelProps) => {
    const [field, meta, helpers] = useField(props);
    return (
        <Box 
            border={props.withStyling? '1px solid' : '0px'} 
            borderColor="rgba(0, 0, 0, 0.23)" 
            borderRadius={'4px'} 
            bgcolor={props.withStyling && props.checked? "success.light" : "initial"}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                p: props.withStyling? 1 : 0
            }}>
            {props.children}
            <Switch {...field} 
                disabled={props.disabled}
                checked={props.checked} onChange={(e, v) => {
                    props.onSwitch()
                    helpers.setValue(v == true ? 1 : 0)
                }}
            />
        </Box>
    );
};

