import { StyledTextField } from "@/components";
import { Grid, Button, Typography } from "@mui/material";
import { Formik } from 'formik';
import { useTranslation } from "next-i18next";
import { LoginFormHeader } from "../..";
import { getLoginTranslationKey } from '@/hooks';

interface AccountRecoverySuccessProps {}

export const AccountRecoverySuccess: React.FC<AccountRecoverySuccessProps> = (props: AccountRecoverySuccessProps) => {
    
    const { t } = useTranslation()
   
    return (
        <>
            <LoginFormHeader 
                titleTranslate={t(getLoginTranslationKey('headers.success'))} 
                subTitleTranslate={t(getLoginTranslationKey('paragraphs.success'))} 
            />
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    fullWidth
                    type='submit'
                >{t('buttons.enter_account')}
                </Button>
            </Grid>
        </>
    )
}