import React from 'react';
import { Typography } from '@mui/material';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import Description from './Description';
import { containerStyles, iconContainerStyles } from './styles';
import { ExpandedSearchIcon } from '../../../../shared';


type IndexPageHeaderProps = {
  title: string;
  onChange?: (newValue: string) => void;
  Icon?: React.ReactNode;
  badgeNumber?: number | null;
  description?: string | null;
  search?: boolean;
};

const IndexPageHeader: React.FC<IndexPageHeaderProps> = ({
  title,
  Icon,
  badgeNumber,
  description,
  onChange,
  search,
}) => {
  return (
    <div style={containerStyles}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {Icon && <div style={iconContainerStyles}>{Icon}</div>}
          <Typography color='#00385E' fontWeight={700} fontSize={21}>
            {title} {badgeNumber !== null && badgeNumber !== undefined && <PrimaryBadge>{badgeNumber}</PrimaryBadge>}
          </Typography>
        </div>
        {search && (
          <div>
            <ExpandedSearchIcon onChangeSearchText={onChange} />
          </div>
        )}
      </div>
      {description && <Description description={description} />}
    </div>
  );
};


export default IndexPageHeader;
