import { procurementStatus } from '@/config';
import { useOnClickOutside } from '@/hooks';
import { ctRequestForQuoteProps } from '@/models';
import BadgeIcon from '@mui/icons-material/Badge';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Link from 'next/link';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import { SellerListStatuses } from './status-seller-list';

type IProps = {
  sellers: ctRequestForQuoteProps[] | undefined;
};

const SellersList = ({ sellers }: IProps) => {
  const drawer = usePopup();
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(drawerRef, e => {
    const isButtonClicked = buttonRef.current && buttonRef.current.contains(e.target as Node);
    if (drawer.isOpen && !isButtonClicked) {
      drawer.handleToggle();
    }
  });
  return (
    <Typography variant='body1' fontWeight={600} color='#3A3E50' lineHeight={1.2} ref={buttonRef}>
      {sellers?.length > 0 && (
        <>
          <IconButton
            onClick={drawer.handleToggle}
            size='small'
            sx={{
              width: '1rem',
              height: '1rem',
              marginInlineStart: '.5em',
            }}
          >
            <ChevronRightIcon fontSize='small' sx={{ color: 'text.disabled' }} />
          </IconButton>

          <Drawer
            ref={drawerRef}
            anchor={'right'}
            open={drawer.isOpen}
            onClose={drawer.handleClose}
            sx={{
              width: '400px',
              flexShrink: 0,

              [`& .MuiDrawer-paper`]: {
                maxHeight: 'calc(100vh - 65px)',
                top: 'auto',
                bottom: 0,

                width: '400px',
                boxSizing: 'border-box',
              },
            }}
            elevation={2}
            hideBackdrop
          >
            <div style={{ padding: 15 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '.5rem',
                }}
              >
                <div style={{ display: 'flex', gap: '.75rem' }}>
                  <div
                    style={{
                      backgroundColor: '#D0E0E8',
                      height: 'fit-content',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '.5rem',
                      borderRadius: '4px',
                    }}
                  >
                    <BadgeIcon sx={{ color: 'primary.main' }} />
                  </div>
                  <div>
                    <Typography variant='subtitle2' fontWeight={700} color={'#8B8D98'} lineHeight={1.1}>
                      RFQ Sent to
                    </Typography>
                    <Typography color='#3A3E50' variant='h6' fontWeight={700}>
                      {sellers?.length} Sellers
                    </Typography>
                  </div>
                </div>
                <IconButton onClick={drawer.handleClose}>
                  <ChevronRightIcon />
                </IconButton>
              </div>

              <br />
              {sellers?.map((seller, index) => (
                <div key={seller.id}>
                  {index !== 0 && (
                    <Divider
                      sx={{
                        borderColor: 'info.contrastText',
                        opacity: 0.7,
                        my: 1.5,
                      }}
                    />
                  )}

                  <Grid
                    container
                    md={12}
                    justifyContent='space-between'
                    alignItems='center'
                    gap={1}
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Grid item style={{ flexBasis: 'auto', flexGrow: 1, minWidth: 0 }}>
                      <Typography
                        color='#3A3E50'
                        variant='body2'
                        fontWeight={700}
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {seller.seller_details.name}
                      </Typography>
                    </Grid>
                    <Grid item style={{ flexShrink: 0 }}>
                      <Typography color='#8B8D98' variant='subtitle2' fontWeight={400}>
                        Sent on {seller.sent_to_seller_date}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container justifyContent={'space-between'} gap={1} pt={0.5}>
                    <Typography variant='subtitle2' fontWeight={500}>
                      <SellerListStatuses status={seller.status} />
                    </Typography>

                    {seller.status == procurementStatus.QUOTE_RECEIVED && (
                      <Link href={sm.portals.buyer.procurement.quotes.quoteId(seller.quote.id).url}>
                        <Typography
                          color='primary.main'
                          variant='subtitle2'
                          fontWeight={700}
                          sx={{ cursor: 'pointer' }}
                        >
                          View Quote
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </div>
              ))}
            </div>
          </Drawer>
        </>
      )}
    </Typography>
  );
};

export default SellersList;
