import { EmailOutlined, ShareOutlined, WhatsApp } from "@mui/icons-material"
import { Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from 'next-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { useEffect, useState } from "react";

const ShareWhatsappButtonStyle = {
    marginTop: 1, width: '100%', height: 50, color: 'white', backgroundColor: '#0AB87B', borderRadius: 4
};

const ShareEmailButtonStyle = {
    marginTop: 1, width: '100%', height: 50, color: '#8B8D98', backgroundColor: 'white', border: '1px solid #d8d8d8', borderRadius: 4
}

export const SharePopupContent = ({ title, share_url }) => {
    const { t } = useTranslation();
    const [buttonText, setButtonText] = useState(t('buttons.copy_link'));
    useEffect(() => {
        setButtonText(t('buttons.copy_link'))
    }, []);

    return (
        <Grid container width='100%'>
            <Grid item md={12} textAlign='center'>
                <ShareOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
            </Grid>

            <Grid item md={12} textAlign='center'>
                <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                    {title}
                </Typography>
            </Grid>

            {share_url && <>

                <Grid item md={12} pt={3} px={1}>
                    <WhatsappShareButton url={share_url} style={ShareWhatsappButtonStyle}>
                        <Grid container lg={12} xs={12} alignItems='center'>
                            <Grid item container lg={10} xs={10} pl={2}>
                                <Typography variant="body2" fontWeight={600}>
                                    {t('buttons.share_via_whatsapp')}
                                </Typography>
                            </Grid>
                            <Grid item container lg={2} xs={2} justifyContent='center'>
                                <WhatsApp type="button" round={true} style={{ fontSize: 25 }} />
                            </Grid>
                        </Grid>
                    </WhatsappShareButton>
                </Grid>

                <Grid item md={12} my={1} px={1}>
                    <EmailShareButton url={share_url} style={ShareEmailButtonStyle}>
                        <Grid container lg={12} xs={12} alignItems='center'>
                            <Grid item container lg={10} xs={10} pl={2}>
                                <Typography variant="body2" fontWeight={600}>
                                    {t('buttons.share_via_email')}
                                </Typography>
                            </Grid>
                            <Grid item container lg={2} xs={2} justifyContent='center'>
                                <EmailOutlined type="button" round={true} style={{ color: 'text.third', fontSize: 25 }} />
                            </Grid>
                        </Grid>
                    </EmailShareButton>
                </Grid>

                <Grid item md={12} pb={2} px={1}>
                    <CopyToClipboard text={share_url} onCopy={() => setButtonText(t('buttons.copied'))}>
                        <TextField
                            disabled
                            value={share_url}
                            fullWidth
                            InputProps={{
                                endAdornment: <Typography variant='body2' fontWeight='700' width={'30%'} color='primary.main' onClick={() => setButtonText(t('buttons.copied'))} sx={{ cursor: 'pointer' }}>{buttonText}</Typography>,
                                inputProps: {
                                    style: { fontSize: '14px', fontWeight: 600, color: 'text.third' },
                                },
                                style: { width: '100%', height: 50, textAlign: 'end', borderRadius: 4, border: 'none' },
                            }}
                        />
                    </CopyToClipboard>
                </Grid>

            </>
            }
        </Grid>
    )
}