import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";


export const Carousel = (props) => {
    const productsRef = React.useRef();
    const [productsOverflow, setproductsOverflow] = React.useState(false);
    const scrollProducts = scrollOffset => productsRef.current.scrollLeft += scrollOffset;
    function isOverflowActive(event) { return event.clientWidth < event.scrollWidth }
    useEffect(() => {
        if (isOverflowActive(productsRef.current)) {
            setproductsOverflow(true);
            return;
        }
        setproductsOverflow(false);
    }, [isOverflowActive]);

    return (
        <Grid container item alignItems={'center'} md={12} spacing={1}>
            {productsOverflow && <Grid item md={0.2}> <ArrowBackIosNew onClick={() => scrollProducts(-200)} fontSize='inherit' sx={{ color: 'info.light', cursor: 'pointer' }} /></Grid>}
            <Grid
                item
                md={productsOverflow ? 11.6 : 12}
                ref={productsRef}
                container
                sx={{ overflowX: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'horizontal', width: 200 }}
                wrap='nowrap'
            >
                {props.children}
            </Grid>
            {productsOverflow && <Grid item container md={0.2} sx={{ justifyContent: 'flex-end' }}>
                <ArrowForwardIos onClick={() => scrollProducts(+200)} fontSize='inherit' sx={{ color: 'info.light', cursor: 'pointer' }} />
            </Grid>}
        </Grid>
    )
}