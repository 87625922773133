import { Box, Typography } from '@mui/material';
import PrivacyButton from '../../buttons/privacy-button';

export type StyledClickableBorderedTextProps = {
  isSelected: boolean;
  handleTextClick: () => void;
  textProperties: { name: string; id: number; is_private: boolean };
};

export const StyledClickableBorderedText: React.VFC<StyledClickableBorderedTextProps> = ({
  isSelected,
  handleTextClick,
  textProperties,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
        color: isSelected ? 'text.primary' : 'text.third',
        backgroundColor: isSelected ? 'rgb(208, 224, 232, 0.7)' : 'white',
        p: 1,
        borderColor: isSelected ? 'primary.light' : 'text.third',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '4px',
        opacity: isSelected ? 1 : 0.5,
        fontWeight: isSelected ? 700 : 400,
        maxWidth: 250,
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
      onClick={handleTextClick}
    >
      <Typography
        variant='subtitle2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {textProperties.name}
      </Typography>
      {textProperties.is_private && (
        <Box sx={{ flexShrink: 0, ml: 1 ,maxHeight:'8px' , display:'flex', alignItems:'center', justifyContent:'center'}}>
          <PrivacyButton
            disableText
            content={'This seller profile is private because it was created and/or linked by your company.'}
          />
        </Box>
      )}
    </Box>
  );
};
