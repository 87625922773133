import { Divider } from "@mui/material";
import { Grid, Typography, Button, Chip } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { FormMoreOptions, LinkedFormsIcon, RFQProductCard, SingleRfqPDFDocument } from "../../dumb";
import { ConfirmPopup, DescPopup, BundleRFQPopupContent, StyledPopper, SendToOtherCompany, FormHeaderCompanyDetails, StyledLoadingButton } from "@/components";
import { useState } from "react";
import { RFQDetails, RFQProduct } from "@/models";
import { useProcurementStore, useUserData } from "@/zustand";
import { CheckCircleOutline } from "@mui/icons-material";
import { PopupStatus, ProcurementButtons, ProcurementForms, procurementStatus } from "@/config";
import { sm } from "src/site-map";
import { useRouter } from 'next/router';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import usePopup, { PopupUtils } from "src/logic/hooks/use-popup";
import ListOfApprovers from "../procurement/form-header-company-details/list-of-approvers";
import { RfqProductsTableHeader } from "../../dumb/shared/rfq-products-table-header";
import PrimaryBadge from "../../dumb/shared/primary-badge";
import { RelatedFormsList } from "../procurement/list-linked-forms";

type RFQDetailsProps = {
    selectedRFQ: RFQDetails
    isLastRFQ: boolean
    bundleId: number | undefined
    editValidityPopper?: PopupUtils
}
export const RFQAllDetails: React.FC<RFQDetailsProps> = ({ selectedRFQ, isLastRFQ, bundleId, editValidityPopper }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const showEditResponse = Boolean(anchorEl);
    const [confirmCancelRFQPopup, setConfirmCancelRFQPopup] = useState(false)
    // const userInfo = useUserData(state => state.userInfo);
    // const textRef = useRef<HTMLSpanElement>(null);
    const { editExpiryDate, deleteIndividualRFQ, approvalProcess, setPopupStatus, setConfirmDeletePopup, declineRequestExtension
        , deleteBundleRFQ, cancelRFQ, deleteProductFromRFQ } = useProcurementStore(state => state.bundleRFQDetailsSliceActions)
    const { openedPopupStatus, customLoadingButton, productIdDeleting, tabs } = useProcurementStore(state => state.bundleRFQDetailsSliceProperties)

    const sendRFQToSeller = useProcurementStore(state => state.bundleRFQDetailsSliceActions.sendRFQToSeller)

    // const handleOpenDeletePopup = () => setShowDeletePopup(true);
    const handleCloseDeletePopup = () => setShowDeletePopup(false);

    const handleAcceptRFQ = (values: any) => approvalProcess(selectedRFQ.rfqModel?.id, procurementStatus.APPROVED_BY_ME, values.comment)
    const handleRejectRFQ = (values: any) => approvalProcess(selectedRFQ.rfqModel?.id, procurementStatus.REJECTED_BY_ME, values.comment, values.reasonId)
    const handleSendToSeller = (values: any) => sendRFQToSeller(selectedRFQ.rfqModel?.id, values.validity_date, values.comment)

    const OpenRejectRFQPopup = (values: any) => setPopupStatus(PopupStatus.REJECT)
    const OpenApproveRFQPopup = (values: any) => setPopupStatus(PopupStatus.APPROVE)
    const OpenSendToSellerPopup = () => setPopupStatus(PopupStatus.SENT_TO_SELLER)
    const handleClosePopup = () => setPopupStatus(null)


    const handleDeleteBundleRfq = () => {
        deleteBundleRFQ(bundleId, () => router.push(sm.portals.buyer.procurement.requestForQuotes.index.url))
        setConfirmDeletePopup(false)
        handleCloseDeletePopup()
    }

    const handleDeleteRFQ = () => {
        deleteIndividualRFQ(selectedRFQ.rfqModel?.id, selectedRFQ.rfqModel?.name)
        handleCloseDeletePopup();
    }

    // Cancel RFQ
    const [openRFQActionsPopper, setOpenRFQActionsPopper] = useState<null | HTMLElement>(null);
    const RFQActionsPopper = Boolean(openRFQActionsPopper);
    // const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => setOpenRFQActionsPopper(event.currentTarget)
    const handleClosePopper = () => setOpenRFQActionsPopper(null)

    const handleCloseConfirmCancelRFQPopup = () => setConfirmCancelRFQPopup(false)
    const handleOpenConfirmCancelRFQPopup = () => {
        setConfirmCancelRFQPopup(true)
        handleClosePopper()
    }
    const handleOpenCancelRfqPopup = () => {
        setConfirmCancelRFQPopup(false)
        setPopupStatus(PopupStatus.CANCEL_RFQ)
    }
    const cancelTheRFQ = (values: any) => {
        cancelRFQ(selectedRFQ.rfqModel?.id, values.reasonId, values.hideReason, values.comment)
    }
    // Close done popup
    // const handleCloseDonePopup = () => setDonePopupStatus(null)

    const [productIdToDelete, setProductIdToDelete] = useState<number>();
    const confirmDeleteLastProduct = usePopup();
    const isLastProduct = selectedRFQ?.numberOfProducts === 1;
    const handleDeleteProduct = (productId: number) => {
        deleteProductFromRFQ(productId, selectedRFQ.rfqModel?.id, () => router.push(sm.portals.buyer.procurement.requestForQuotes.index.url));
        if (confirmDeleteLastProduct.isOpen) {
            confirmDeleteLastProduct.handleClose();
        }

    }
    const onDeleteProductClick = (productId: number) => {
        setProductIdToDelete(productId);
        if (isLastProduct) {
            confirmDeleteLastProduct.handleOpen();
        } else {
            handleDeleteProduct(productId)
        }
    }

    const isLastRfqInBundle = tabs?.length === 1

    const handleDeclineRequestExtension = () => {
        declineRequestExtension(selectedRFQ.rfqModel?.id)
    }

    const userId = useUserData.getState().userInfo?.id
    const isOwner = userId == selectedRFQ.rfqModel?.owner.id
    const isProductDeletable = selectedRFQ.rfqModel?.deletable && selectedRFQ.canDelete && isOwner

    return (
        <>
            <ConfirmPopup
                titleKey={`Delete Product`}
                subTitleKey={isLastRfqInBundle ? t("popups.subTitles.delete_last_rfq") : t("popups.subTitles.delete_last_product_in_rfq")}
                buttonTitleKey={t("buttons.delete")}
                handleClose={confirmDeleteLastProduct.handleClose}
                handleConfirm={() => handleDeleteProduct(productIdToDelete)}
                open={confirmDeleteLastProduct.isOpen}
            />
            <Grid container md={12} sx={{ bgcolor: 'white', borderRadius: '8px' }} gap={2} p={2} pb={0}>
                <Grid item container md={12} p={1}>
                    <Grid item container md={6} alignItems="center" my={1}>
                        <Typography variant="h5" fontWeight={600} mr={2}>{selectedRFQ.rfqModel?.name}</Typography>
                        {/* <StatusChip status={selectedRFQ.rfqModel?.status} title={t('texts.approvers')} listOfApprovers={selectedRFQ.rfqModel?.approvers} /> */}
                        {
                            selectedRFQ.rfqModel?.extension_request_status ?
                                selectedRFQ.rfqModel?.is_expired && selectedRFQ.rfqModel?.extension_request_status === procurementStatus.DECLINED ? <Chip label={t('notifications.request_extension_declined')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                                    : selectedRFQ.rfqModel?.is_expired && selectedRFQ.rfqModel?.extension_request_status === procurementStatus.PENDING ? <Chip label={t('notifications.extension_requested')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(251, 127, 13, 1)', bgcolor: 'white', border: `1px solid rgba(251, 127, 13, 1)` }} />
                                        : null
                                : selectedRFQ.rfqModel?.is_expired ? <Chip label={t('chip.EXPIRED')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                                    : <></>
                        }
                    </Grid>
                    <Grid item container md={6} justifyContent="flex-end">
                        {/* {selectedRFQ.canDelete && <Button variant="LightError" sx={{ mx: 1 }} startIcon={<DeleteIcon />} color="error" onClick={handleOpenDeletePopup}>
                            <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.delete')}</Typography>
                        </Button>} */}
                        <Grid item>
                            {selectedRFQ.canDeclineRequestExtension && <StyledLoadingButton customWidth variant="outlined" translateKey="decline_request" loading={customLoadingButton == ProcurementButtons.DECLINE_REQUEST_EXTENSION} color={'error'} onClick={handleDeclineRequestExtension} />}
                            {selectedRFQ.canSendToSeller && <Button variant="contained" sx={{ mr: 1 }} color={'primary'} onClick={OpenSendToSellerPopup}>{t('buttons.send_rfq_to_seller')}</Button>}

                            {/* {(!selectedRFQ.canAcceptRFQ && selectedRFQ.canRejectRFQ) || (selectedRFQ.canAcceptRFQ && !selectedRFQ.canRejectRFQ) ? <Button onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                                variant="contained" color="info" startIcon={<BorderColorOutlinedIcon sx={{ ml: 1 }} />} endIcon={<ExpandMoreOutlinedIcon sx={{ mr: 1 }} />} >
                                <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.edit_response')}</Typography>
                            </Button> : <Grid container md={12} justifyContent="flex-end" gap={1}>
                                {selectedRFQ.canRejectRFQ && <Button startIcon={<ClearIcon />} color="error" onClick={OpenRejectRFQPopup}>
                                    {t('buttons.reject')}
                                </Button>}
                                {selectedRFQ.canAcceptRFQ && <Button startIcon={<CheckIcon />} sx={{ color: "success.dark" }} onClick={OpenApproveRFQPopup}>
                                    {t('buttons.approve')}
                                </Button>}
                            </Grid>
                            } */}

                            {
                                selectedRFQ?.canEditResponse ? <Button onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)}
                                    variant="contained" color="info" startIcon={<BorderColorOutlinedIcon sx={{ ml: 1 }} />} endIcon={<ExpandMoreOutlinedIcon sx={{ mr: 1 }} />} >
                                    <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.edit_response')}</Typography>
                                </Button> : <Grid container md={12} justifyContent="flex-end" gap={1}>
                                    {selectedRFQ.canRejectRFQ && <Button startIcon={<ClearIcon />} color="error" onClick={OpenRejectRFQPopup}>
                                        {t('buttons.reject')}
                                    </Button>}
                                    {selectedRFQ.canAcceptRFQ && <Button startIcon={<CheckIcon />} sx={{ color: "success.dark" }} onClick={OpenApproveRFQPopup}>
                                        {t('buttons.approve')}
                                    </Button>}
                                </Grid>
                            }

                        </Grid>
                        {/* {selectedRFQ.canRecallRFQ && <IconButton color='primary' sx={{ ml: 1 }} onClick={handleOpenPopper}><MoreVert /></IconButton>} */}

                        {selectedRFQ?.rfqModel?.linkedforms?.length != 0 && <LinkedFormsIcon><RelatedFormsList forms={selectedRFQ?.rfqModel?.linkedforms} /></LinkedFormsIcon>}

                        <Grid item>
                            <FormMoreOptions
                                deleteAction={selectedRFQ?.canDelete}
                                handleDeleteAction={!isLastRFQ ? handleDeleteRFQ : handleDeleteBundleRfq}
                                formType={ProcurementForms.RFQ}
                                pdfDocument={<SingleRfqPDFDocument rfq={selectedRFQ} rfqModel={selectedRFQ?.rfqModel} />}
                                disableDownload={!selectedRFQ?.rfqModel?.products?.length}
                                formName={selectedRFQ?.rfqModel?.name}
                                specificSubtitle={!isLastRFQ ? t('popups.subTitles.delete_rfq') : t('popups.subTitles.delete_last_rfq')}
                                recallAction={selectedRFQ?.canRecallRFQ}
                                handleRecallAction={handleOpenConfirmCancelRFQPopup}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Divider sx={{ opacity: 0.7, my: 1 }} />
                    </Grid>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                        <FormHeaderCompanyDetails formType="RFQ" title="Seller" companyDetails={selectedRFQ?.rfqModel?.seller_details} formModel={selectedRFQ?.rfqModel} customLoadingButton={customLoadingButton} onEditExpiryDate={(validityDate: string, handleClose: () => void) => editExpiryDate(validityDate, selectedRFQ?.rfqModel?.id, handleClose)} editValidityPopper={editValidityPopper} />
                        {
                            (selectedRFQ.rfqModel?.approvers?.length != 0 && selectedRFQ.rfqModel?.approvers != undefined) &&
                            <ListOfApprovers approvers={selectedRFQ.rfqModel?.approvers} />
                        }
                    </div>
                </Grid>

                <StyledPopper open={showEditResponse} anchorEl={anchorEl} handleClose={() => setAnchorEl(null)}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', minWidth: 185 }}>
                        {selectedRFQ.canAcceptRFQ && <>
                            <Button startIcon={<CheckCircleOutlineIcon />} sx={{ color: "success.dark" }} onClick={OpenApproveRFQPopup}>
                                {t('buttons.approve')}
                            </Button>
                            <Divider sx={{ opacity: 0.7, mx: 2 }} />
                        </>}
                        {selectedRFQ.canRejectRFQ && <><Button startIcon={<HighlightOffIcon />} color="error" onClick={OpenRejectRFQPopup}>
                            {t('buttons.reject')}
                        </Button>
                            <Divider sx={{ opacity: 0.7, mx: 2 }} />
                        </>}
                    </Grid>
                </StyledPopper>

                <StyledPopper open={RFQActionsPopper} anchorEl={openRFQActionsPopper} handleClose={() => setOpenRFQActionsPopper(null)}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', minWidth: 185 }}>
                        <Button startIcon={<HighlightOffIcon />} color="error" onClick={handleOpenConfirmCancelRFQPopup}>{t('popups.titles.cancel_rfq')}</Button>
                    </Grid>
                </StyledPopper>

                {/* accept popup */}
                <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.APPROVE}>
                    <BundleRFQPopupContent
                        icon={<CheckCircleOutline sx={{ color: 'success.dark' }} />}
                        popupTitle={t('popups.titles.approve_rfq', { RFQName: selectedRFQ.rfqModel?.name })}
                        buttonTitle={t('buttons.approve_rfq')}
                        handlePopupAction={handleAcceptRFQ}
                        status='success'
                        isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_INDIVIDUAL_RFQ}
                    />
                </DescPopup>
                {/* reject popup */}
                <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.REJECT}>
                    <BundleRFQPopupContent
                        icon={<HighlightOffIcon color='error' />}
                        popupTitle={t('popups.titles.reject_rfq', { RFQName: selectedRFQ.rfqModel?.name })}
                        buttonTitle={t('buttons.reject_rfq')}
                        popupSubTitle={t('popups.subTitles.reject_rfq')}
                        handlePopupAction={handleRejectRFQ}
                        status='error'
                        isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_INDIVIDUAL_RFQ}
                    />
                </DescPopup>

                <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.SENT_TO_SELLER}>
                    <SendToOtherCompany
                        icon={<MarkChatReadOutlinedIcon color='primary' />}
                        popupTitle={t('buttons.send_rfq_to_seller')}
                        validTitle={t('table_cells.rfq') + " " + t('texts.valid_until')}
                        buttonTitle={t('buttons.send_rfq_to_seller')}
                        handlePopupAction={handleSendToSeller}
                        isButtonLoading={customLoadingButton == ProcurementButtons.SEND_INDIVIDUAL_RFQ_TO_SELLER}
                    />
                </DescPopup>

            </Grid>

            <Grid item container md={12} gap={1} p={1} mt={2}>
                <Typography variant='overline' fontWeight={700} color="#00385E">Products / Services <PrimaryBadge>{selectedRFQ.numberOfProducts}</PrimaryBadge></Typography>
            </Grid>

            <Grid item container md={12} gap={1.5}>
                <RfqProductsTableHeader />
                {selectedRFQ.rfqModel?.products?.map((element: RFQProduct) => <RFQProductCard key={`${element.id}-${selectedRFQ?.rfqModel?.id}`} withDetailsPopup deletable={isProductDeletable} deleteProductLoading={productIdDeleting === element.id} rfqData={element} warrantyGeneralTerm={selectedRFQ.rfqModel?.terms_bundle.warranty_term} handleDeleteProduct={onDeleteProductClick} />)}
            </Grid>

            <ConfirmPopup
                titleKey={t('popups.titles.delete_rfq', { RFQName: selectedRFQ.rfqModel?.name })}
                subTitleKey={!isLastRFQ ? t('popups.subTitles.delete_rfq') : t('popups.subTitles.delete_last_rfq')}
                buttonTitleKey={t("buttons.delete")}
                handleClose={handleCloseDeletePopup}
                handleConfirm={!isLastRFQ ? handleDeleteRFQ : handleDeleteBundleRfq}
                open={showDeletePopup}
            />

            {/*cancel RFQ - confirm Popup*/}
            <ConfirmPopup
                titleKey={t('popups.titles.cancel_rfq')}
                subTitleKey={<span dangerouslySetInnerHTML={{ __html: t('popups.subTitles.cancel_rfq') }} />}
                buttonTitleKey={t("buttons.yes_confirm")}
                handleClose={handleCloseConfirmCancelRFQPopup}
                handleConfirm={handleOpenCancelRfqPopup}
                open={confirmCancelRFQPopup}
                icon={<HighlightOffIcon color='error' />}
            />

            {/*cancel RFQ - Popup*/}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.CANCEL_RFQ}>
                <BundleRFQPopupContent
                    icon={<HighlightOffIcon color='error' />}
                    popupTitle={t('popups.titles.cancel_rfq')}
                    buttonTitle={t('popups.titles.cancel_rfq')}
                    popupSubTitle={t('popups.subTitles.reason_cancel_rfq')}
                    handlePopupAction={cancelTheRFQ}
                    status='error'
                    titleColor='error'
                    checkLabel={t('texts.hide_reason_from_seller')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_INDIVIDUAL_RFQ}
                />
            </DescPopup>

        </>
    )
}