import { DescPopup } from "@/components"
import { ProductManagerPopupStatus } from "@/config"
import { useProcurementStore } from "@/zustand"
import { Description } from "@mui/icons-material"
import { Stack } from "@mui/material"
import { Loading } from '@/components';
import { SelectRequisitionPoppup } from "./select-requisition";
import { CreateRequisitionPopup, CreateRequisitionWithSelectPopup } from "./create-requisition";


const LoadingPopup = () => <Loading fullHeight />

export const RequisitionPopup = () => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const closePopup = () => {
        actions.setProductManagerPopupStatus(null)
        actions.setCandidateSellerRelatedToForm(null)
        actions.setCandidateProductToAdd(null)
    }
    const openPopupStatus = data.productManagerPopupStatus === ProductManagerPopupStatus.CREATE_REQUISITION
        || data.productManagerPopupStatus === ProductManagerPopupStatus.SELECT_REQUISITION
        || data.productManagerPopupStatus === ProductManagerPopupStatus.CREATE_REQUISITION_WITH_SELECT

    const requisitionPopupContent = {
        [ProductManagerPopupStatus.SELECT_REQUISITION]: <SelectRequisitionPoppup {...data.candidateProductToAdd} onClose={closePopup} />,
        [ProductManagerPopupStatus.CREATE_REQUISITION_WITH_SELECT]: <CreateRequisitionWithSelectPopup {...data.candidateProductToAdd} />,
        [ProductManagerPopupStatus.CREATE_REQUISITION]: <CreateRequisitionPopup {...data.candidateProductToAdd} />
    }

    return (
        <DescPopup open={openPopupStatus} handleClose={closePopup}>
            <Stack sx={{ alignItems: 'center', textAlign: 'center', m: 1 }}>
                <Description color="primary" />
                {requisitionPopupContent[data.productManagerPopupStatus]}
            </Stack>
        </DescPopup>
    )
}
export * from './create-requisition'