import { AuthenticateStatus } from "@/config";
import { GeneralObject, Product, ProductCategoryKind, Requisition, RequisitionProduct } from "@/models";
import { NotifySuccess, NotifySuccessWithDeleteIcon, getRquisitionDetails, useDeleteRequisition, useDuplicateRequisition } from "@/services";
import { useUserData } from "@/zustand";
import { t } from "i18next";
import produce from "immer";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";
import { StateCreator } from "zustand";
import { Timeline } from "../../../models/procurement/form-timeline";

export interface RequisitionsDetailsSlice {
    requisitionDetailsSliceState: {
        // loadingDraftRequistion: boolean
        fetchRequisitionDetailsQueryStatus: QueryHandlerModel,
        selectedMainProduct: Product | null
        selectedAlternativeProducts: RequisitionProduct[]
        selectedMainProductId: number
        numberSelectedProductOfAlternativeProducts: number
        copyOfReq: Requisition | null;
        // showDeletionSuccessPopup: boolean
        timeline: Timeline | null
    },
    requisitionsDetailsSliceActions: {
        updateRequisition: (requisition: Requisition) => void
        getRequisitionDetails: (requisitionId: number) => void
        deleteRequisition: (requisitionId: number | undefined, redirectToRequisitions: () => void) => void
        setSelectedProduct: (mainProductId: number) => void
        buildInitialProductState: (product: Product, quantity: number, additional_requirements?: string) => Product
        buildInitialProductAlternativeState: (product: Product, mainProductId: string) => Product
        removeMainProduct: (product: Product) => void
        editAndRegenerateRFQFromRequisition: (handleEditAndRegenerateRFQ: () => void) => void
        duplicateRequisition: (requisitionId: number | undefined, project: GeneralObject, redirectToDuplicatedRequisition: (id: number) => void) => void
        // closeDeletionSuccessPopup: () => void
    },
}

export const createRequisitionsDetailsSlice: StateCreator<RequisitionsDetailsSlice> = (set, get, api) => ({
    requisitionDetailsSliceState: {
        fetchRequisitionDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingDraftRequistion: false,
        selectedMainProduct: null,
        selectedAlternativeProducts: [],
        selectedMainProductId: 0,
        numberSelectedProductOfAlternativeProducts: 0,
        copyOfReq: null,
        // showDeletionSuccessPopup: false,
        timeline: null
    },
    requisitionsDetailsSliceActions: {
        updateRequisition: (requisition: Requisition) => {
            get().setTimeline(requisition.timeline);

            let selectedProduct = requisition?.products[0]
            selectedProduct && set(produce(draftState => {
                draftState.requisitionDetailsSliceState.selectedMainProduct = get().requisitionsDetailsSliceActions.buildInitialProductState(selectedProduct.main_product, selectedProduct.quantity, selectedProduct.additional_requirements)
                draftState.requisitionDetailsSliceState.selectedAlternativeProducts = selectedProduct.alternative_products.map(alternativeProduct => get().requisitionsDetailsSliceActions.buildInitialProductAlternativeState(alternativeProduct, selectedProduct.main_product.id))
                draftState.requisitionDetailsSliceState.selectedMainProductId = selectedProduct.main_product.id
                draftState.requisitionDetailsSliceState.copyOfReq = requisition
                draftState.requisitionDetailsSliceState.numberSelectedProductOfAlternativeProducts = draftState.requisitionDetailsSliceState.selectedAlternativeProducts.length
                draftState.requisitionDetailsSliceState.timeline = requisition.timeline//buildBuyerFormTimeline({title: requisition.name, id: requisition.id}, {title: 'RFQ', id: 1})
            }))
            // set(produce(draftState => {
            //     draftState.requisitionDetailsSliceState.loadingDraftRequistion = false
            // }))
            set(produce(draftState => {
                draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getSuccessStatus();
            }))
            get().setCurrentRequisition(requisition)
            api.subscribe(
                state => {
                    const currentRequisition = get().currentRequisition
                    if (currentRequisition) {
                        let selectedProduct = state.requisitionDetailsSliceState.selectedMainProductId ?
                            currentRequisition.products.find(product => product.main_product.id == state.requisitionDetailsSliceState.selectedMainProductId)
                            : currentRequisition.products[0]
                        if (JSON.stringify(currentRequisition) !== JSON.stringify(state.requisitionDetailsSliceState.copyOfReq))
                            set(produce(draftState => {
                                draftState.requisitionDetailsSliceState.selectedMainProductId = selectedProduct ? selectedProduct.main_product.id : 0
                                draftState.requisitionDetailsSliceState.selectedMainProduct =
                                    selectedProduct ? state.requisitionsDetailsSliceActions.buildInitialProductState(selectedProduct.main_product, selectedProduct.quantity, selectedProduct.additional_requirements) : null
                                draftState.requisitionDetailsSliceState.selectedAlternativeProducts =
                                    selectedProduct ? selectedProduct.alternative_products.map(alternativeProduct => get().requisitionsDetailsSliceActions.buildInitialProductAlternativeState(alternativeProduct, selectedProduct.main_product.id)) : []
                                draftState.requisitionDetailsSliceState.copyOfReq = currentRequisition
                                draftState.requisitionDetailsSliceState.timeline = currentRequisition.timeline//buildBuyerFormTimeline({title: requisition.name, id: requisition.id}, {title: 'RFQ', id: 1})
                            }))
                    }
                },
            )
        },
        deleteRequisition: (requisitionId: number | undefined, redirectToRequisitions: () => void) => {
            useDeleteRequisition(requisitionId).then(() => {
                redirectToRequisitions();
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: get().requisitionDetailsSliceState.copyOfReq?.name}));
            });
        },
        // closeDeletionSuccessPopup: () => set(produce(draftState => { draftState.requisitionDetailsSliceState.showDeletionSuccessPopup = false })),
        duplicateRequisition: (requisitionId: number | undefined, project: GeneralObject, redirectToDuplicatedRequisition: (id: number) => void) => {
            useDuplicateRequisition(requisitionId, project).then((data) => {
                redirectToDuplicatedRequisition(data.data.id);
                NotifySuccess(t('notifications.form_duplicated', { formName: get().requisitionDetailsSliceState.copyOfReq?.name }));
            });
        },
        getRequisitionDetails: (requisitionId: number) => {
            const authenticationStatus = useUserData.getState().authenticationStatus
            set(produce(draftState => {
                draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = true
            }))
            set(produce(draftState => {
                draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            if (authenticationStatus == AuthenticateStatus.AUTHENTICATED)
                getRquisitionDetails(requisitionId).then(data => {
                    get().requisitionsDetailsSliceActions.updateRequisition(data.data)
                }).catch((response) => {
                    set(produce(draftState => {
                        draftState.requisitionDetailsSliceState.fetchRequisitionDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                    }))
                })
            else
                get().requisitionsDetailsSliceActions.updateRequisition(get().requisitions.find(e => e.id == requisitionId))
        },
        removeMainProduct: (product: Product) => {
            let currentSelectedMainProductIndex = get().currentRequisition.products.findIndex(product =>
                product.main_product.id == get().requisitionDetailsSliceState.selectedMainProductId
            )
            const removeProductHandler = () => {
                if (product.inCartType == 'main') {
                    let updatedMainProductIndex = currentSelectedMainProductIndex ? currentSelectedMainProductIndex - 1 : currentSelectedMainProductIndex + 1
                    if (get().currentRequisition.products[updatedMainProductIndex])
                        set(produce(draftState => {
                            draftState.requisitionDetailsSliceState.selectedMainProductId = get().currentRequisition.products[updatedMainProductIndex].main_product.id
                        }))
                    else
                        set(produce(draftState => {
                            draftState.requisitionDetailsSliceState.selectedMainProductId = 0
                        }))
                }
            }
            get().removeProductFromRequisitionWithPersistence(product, removeProductHandler)
        },
        setSelectedProduct: (mainProductId: number) => {
            const selectedProduct = get().currentRequisition.products.find(product => product.main_product.id == mainProductId)
            set(produce(draftState => {
                draftState.requisitionDetailsSliceState.selectedMainProduct = get().requisitionsDetailsSliceActions.buildInitialProductState(selectedProduct.main_product, selectedProduct.quantity, selectedProduct.additional_requirements)
                draftState.requisitionDetailsSliceState.selectedAlternativeProducts = selectedProduct.alternative_products.map(alternativeProduct => get().requisitionsDetailsSliceActions.buildInitialProductAlternativeState(alternativeProduct, selectedProduct.main_product.id))
                draftState.requisitionDetailsSliceState.selectedMainProductId = mainProductId
                draftState.requisitionDetailsSliceState.numberSelectedProductOfAlternativeProducts = draftState.requisitionDetailsSliceState.selectedAlternativeProducts.length
            }))
        },
        buildInitialProductState: (product: Product, quantity: number, additional_requirements?: string) => {
            const requisitionProduct = produce(product, (draftProduct) => {
                draftProduct.inCartType = 'main'
                draftProduct.inCart = true
                draftProduct.quantity = quantity
                draftProduct.additional_requirements = additional_requirements
                draftProduct.parentId = null
                draftProduct.category = { id: product.category.parent?.parent.id, name: product.category.parent?.parent.name }
                draftProduct.categoryClass = { id: product.category.parent?.id, name: product.category.parent?.name }
                draftProduct.categoryType = { id: product.category.id, name: product.category.name }
            })
            return {
                ...requisitionProduct,
                category: {
                    ...product.category,
                    ...requisitionProduct.category
                }
            }
        },
        buildInitialProductAlternativeState: (product: Product, mainProductId: string) => {
            return produce(product, (draftProduct) => {
                draftProduct.inCartType = 'alternative'
                draftProduct.inCart = true
                draftProduct.parentId = mainProductId
                draftProduct.price = product?.price || product?.catalog_price || ""
            })
        },
        editAndRegenerateRFQFromRequisition: (handleEditAndRegenerateRFQ: () => void) => {
            const handleEditAndRegenerateRFQFromRequisition = (requisition: Requisition) => {
                get().requisitionsDetailsSliceActions.updateRequisition(requisition)
                handleEditAndRegenerateRFQ()
            }
            get().editAndRegenerateRFQ(get().currentRequisition.id, handleEditAndRegenerateRFQFromRequisition)
        }
    }
})