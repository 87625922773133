import { request } from '@/helpers';
import { GeneralObject } from '@/models';
import { useQuery } from '@tanstack/react-query';

export const useGetEditablePurchaseOrders = () => {
  return useQuery<GeneralObject[]>({
    queryKey: ['EDITABLE_PURCHASE_ORDERS'],
    queryFn: async () => {
      const response = await request({
        url: '/buyer/purchase_order/all-data',
        method: 'GET',
      });
      return response.data.purchase_orders;
    },
    retry: 0,
  });
};
