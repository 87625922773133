import { FC, ReactNode, useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { MoreOptions, DownloadAsPDFButton, ConfirmPopup, DuplicateForm } from "@/components";
import { HighlightOff } from "@mui/icons-material";
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
// import { EmailShareButton, WhatsappShareButton } from "react-share";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { useRouter } from "next/router";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import usePopup from "src/logic/hooks/use-popup";
import { GeneralObject } from "@/models";
import { ProcurementForms } from "@/config";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

type MoreOptionsProps = {
    shareURL: string | undefined
    formType: ProcurementForms
    formName: string | undefined
    deleteAction?: boolean
    handleDeleteAction?: () => void
    recallAction?: boolean | undefined
    editAction?: boolean | undefined
    pdfDocument?: any
    actionsDisabled?: boolean
    primaryActions?: ReactNode[]
    handleRecallAction?: () => void
    handleEditAction?: () => void
    duplicateAction?: boolean
    handleDuplicateAction?: (project: GeneralObject) => void
    currentProject?: number
    specificSubtitle?: string
    disableDuplicate?: boolean
    disableDownload?: boolean
    cancelAction?: boolean
    handleCancelAction?: () => void
}

// const ShareWhatsappButtonStyle = {
//     marginTop: 1, width: '100%', height: 50, color: 'white', backgroundColor: '#0AB87B', borderRadius: 4
// };

// const ShareEmailButtonStyle = {
//     marginTop: 1, width: '100%', height: 50, color: '#8B8D98', backgroundColor: 'white', border: '1px solid #d8d8d8', borderRadius: 4
// }

export const FormMoreOptions: FC<MoreOptionsProps> = ({ formType, formName, pdfDocument, handleDeleteAction, actionsDisabled, primaryActions, deleteAction, recallAction, handleRecallAction, editAction, handleEditAction, duplicateAction, handleDuplicateAction, currentProject, specificSubtitle, disableDuplicate = false, disableDownload, cancelAction, handleCancelAction }) => {
    const { t } = useTranslation();

    const [showDelPopup, setShowDelPopup] = useState(false);
    const handleOpenDelPopup = () => {
        setShowDelPopup(true);
    }
    const handleCloseDelPopup = () => setShowDelPopup(false)

    const handleConfirmDelete = () => {
        handleDeleteAction && handleDeleteAction()
        handleCloseDelPopup()
    }

    //share popup
    // const [showSharePopup, setShowSharePopup] = useState(false);
    // const [buttonText, setButtonText] = useState(t('buttons.copy_link'));
    // const handleOpenSharePopup = () => { setShowSharePopup(true), setButtonText(t('buttons.copy_link')) };
    // const handleCloseSharePopup = () => setShowSharePopup(false);

    //Duplicate
    const duplicatePopup = usePopup();

    return (
        <>
            {(primaryActions || editAction || pdfDocument || duplicateAction || recallAction || deleteAction) ? <MoreOptions>
                {primaryActions?.map(action => action)}
                {editAction && <Button fullWidth style={{ justifyContent: 'start' }} startIcon={<BorderColorIcon />} onClick={handleEditAction}> {t('buttons.edit')}</Button>}
                {/* <Button startIcon={<Share />} onClick={handleOpenSharePopup} disabled={actionsDisabled}>{t('buttons.share')}</Button> */}
                {pdfDocument && <DownloadAsPDFButton
                    title={t('buttons.download')}
                    // disabled={actionsDisabled}
                    disabled={disableDownload ? disableDownload : false}
                    document={pdfDocument}
                    handleClickEvent={() => console.log('downloading')}
                    fileName={t('pages_names.procurement')}
                />}
                {duplicateAction && <Button fullWidth style={{ justifyContent: 'start' }} disabled={disableDuplicate} startIcon={<ContentCopyIcon />} onClick={duplicatePopup.handleOpen}>{t('buttons.duplicate')}</Button>}
                {cancelAction && <Button fullWidth style={{ justifyContent: 'start' }} startIcon={<DoDisturbIcon />} color="error" onClick={handleCancelAction}> {t('buttons.cancel')}</Button>}
                {recallAction && <Button fullWidth style={{ justifyContent: 'start' }} startIcon={<HighlightOff />} color="error" onClick={handleRecallAction}> {t('buttons.recall_request')}</Button>}
                {deleteAction && <Button fullWidth style={{ justifyContent: 'start' }} startIcon={<Delete />} color="error" onClick={handleOpenDelPopup}>{t('buttons.delete')}</Button>}
            </MoreOptions> : null}

            {/* <DescPopup
                handleClose={handleCloseSharePopup}
                open={showSharePopup}
            >
                <Grid container width='350px'>
                    <Grid item md={12} textAlign='center'>
                        <ShareOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
                    </Grid>

                    <Grid item md={12} textAlign='center'>
                        <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                            {t('buttons.share')}
                        </Typography>
                    </Grid>

                    <Grid item md={12} pt={3}>
                        <WhatsappShareButton url={shareURL} style={ShareWhatsappButtonStyle}>
                            <Grid container lg={12} xs={12} alignItems='center'>
                                <Grid item container lg={10} xs={10} pl={2}>
                                    <Typography variant="body2" fontWeight={600}>
                                        {t('buttons.share_via_whatsapp')}
                                    </Typography>
                                </Grid>
                                <Grid item container lg={2} xs={2} justifyContent='center'>
                                    <WhatsApp type="button" round={true} style={{ fontSize: 25 }} />
                                </Grid>
                            </Grid>
                        </WhatsappShareButton>
                    </Grid>

                    <Grid item md={12} my={1}>
                        <EmailShareButton url={shareURL} style={ShareEmailButtonStyle}>
                            <Grid container lg={12} xs={12} alignItems='center'>
                                <Grid item container lg={10} xs={10} pl={2}>
                                    <Typography variant="body2" fontWeight={600}>
                                        {t('buttons.share_via_email')}
                                    </Typography>
                                </Grid>
                                <Grid item container lg={2} xs={2} justifyContent='center'>
                                    <EmailOutlined type="button" round={true} style={{ color: 'text.third', fontSize: 25 }} />
                                </Grid>
                            </Grid>
                        </EmailShareButton>
                    </Grid>

                    <Grid item md={12} pb={2}>
                        <CopyToClipboard text={shareURL} onCopy={() => setButtonText(t('buttons.copied'))}>
                            <TextField
                                disabled
                                value={shareURL}
                                fullWidth
                                InputProps={{
                                    endAdornment: <Typography variant='body2' fontWeight='700' width={'30%'} color='primary.main' onClick={() => setButtonText(t('buttons.copied'))} sx={{ cursor: 'pointer' }}>{buttonText}</Typography>,
                                    inputProps: {
                                        style: { fontSize: '14px', fontWeight: 600, color: 'text.third' },
                                    },
                                    style: { width: '100%', height: 50, textAlign: 'end', borderRadius: 4, border: 'none' },
                                }}
                            />
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </DescPopup> */}

            {
                deleteAction && <ConfirmPopup
                    titleKey={t('popups.titles.delete_form', { formName: formName }) ?? t("buttons.delete")}
                    subTitleKey={specificSubtitle ? specificSubtitle : t('popups.subTitles.delete_form', { formType: formType })}
                    buttonTitleKey={t("buttons.delete")}
                    handleClose={handleCloseDelPopup}
                    handleConfirm={handleConfirmDelete}
                    open={showDelPopup}
                // showSuccessAfterConfirm={showDeletionSuccessPopup}
                // successAfterConfirmTitle={successAfterConfirmTitle}
                // handleCloseAfterSuccess={handleCloseAfterSuccess}
                />
            }

            <DuplicateForm openPopup={duplicatePopup.isOpen} closePopup={duplicatePopup.handleClose} onDuplicate={(project) => handleDuplicateAction?.(project)} duplicateFormName={formType} currentProject={currentProject} />

        </>
    )
}