import { SvgIconProps, SvgIcon } from '@mui/material';

export const RequisitionFile: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='22'
    height='30'
    viewBox='0 0 22 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.4926 27.24H2.50801V11.76H11.739V2.75998H19.4926V27.24ZM9.52357 3.39431L3.64839 9.59997H9.52357V3.39431ZM9.15416 0.599976L0.292624 9.95998V29.4H21.708V0.599976H9.15416ZM17.2774 25.08H4.72357V22.92H17.2774V25.08ZM4.72357 20.76H17.2774V18.6H4.72357V20.76ZM17.2774 16.44H4.72357V14.28H17.2774V16.44Z'
      fill={props.color}
    />
  </SvgIcon>
);
