import {  useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { useVacancies } from '@/services';
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import moment from 'moment';
import { Link } from '@/components';
import { sm } from 'src/site-map';
import { ArrowForward } from '@mui/icons-material';
import { Loading } from '@/components';
import { getCareerTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';

type Props = {};
const smallBreakPoints = {
  lg: 6,
  xs: 12,
} as const;

export const VacanciesCareersSection: React.FC<Props> = ({}) => {
  const { t } = useTranslation();

  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });

  const { data, isLoading, isFetching } = useVacancies()


  return (
    <ResponsiveContainer>
      <Typography fontWeight='700' variant='h2' textAlign='center'>
        {t(getCareerTranslationKey('titles.vacancies'))}
      </Typography>
      <Box display='flex' justifyContent='center' mt={2}>
        <Typography
          color='text.third'
          fontWeight='400'
          variant='body2'
          textAlign='center'
          maxWidth={560}
        >
          {t(getCareerTranslationKey('sub_titles.vacancies'))}
          
        </Typography>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data && (
            <Grid
              direction='column'
              component={motion.div}
              container
              spacing={2}
              sx={{ mt: 2 }}
              {...third}
            >
              {data.data.map(el => (
                <Grid item lg={6} xs={12} key={el.job_id}>
                  <VacancyCard
                    title={el.title}
                    date={moment(el.date, 'DD-MM-YYYY').toISOString()}
                    id={el.job_id}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </ResponsiveContainer>
  );
};

type VacancyCardProps = {
  title: string;
  date: string;
  id: string | number;
};

const VacancyCard: React.VFC<VacancyCardProps> = ({ title, date, id }) => {
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        border: 1,
        borderRadius: 2,
        borderColor: 'warning.light',
        boxShadow: 'none',
        height: '100%',
        // height: '75px',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: smallScreens ? 'center' : 'flex-start',
          p: 2,
        }}
      >
        <Grid item {...smallBreakPoints}>
          <Typography color='text.third' variant='body2' fontWeight='400'>
            {moment(date).format('ll')}
          </Typography>
          <Typography
            sx={{ mt: 1, textTransform: 'capitalize' }}
            variant='h6'
            fontWeight='700'
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          {...smallBreakPoints}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            endIcon={<ArrowForward />}
            LinkComponent={Link}
            variant='contained'
            href={sm.careers.id(`${id}`).url}
            sx={{
              width: smallScreens ? '100%' : '176px',
              marginTop: smallScreens ? 2 : 0,
            }}
          >
            {t('buttons.more_details')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
