import { Button, Grid } from "@mui/material"
import { ReactNode } from "react";
import { useTranslation } from 'next-i18next';


type ButtonWithStartIconProps = {
    variant: any;
    icon: ReactNode;
    translateKey: string;
}

export const ButtonWithStartIcon: React.VFC<ButtonWithStartIconProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Button
            variant={props.variant}
            sx={{ justifyContent: 'flex-start' }}
            startIcon={props.icon}
        >
            <Grid container justifyContent={"center"}>{t("buttons." + props.translateKey)}</Grid>
        </Button>
    )
}