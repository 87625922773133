import { useTranslation } from 'next-i18next';
import { Approver, Company, TermsBundle } from "@/models";
import { Box, Grid, Typography } from "@mui/material"
import { FC, ReactNode } from "react";
import { TextViewWithValue } from "src/ui/components/shared"
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import BadgeIcon from '@mui/icons-material/Badge';
import CreateIcon from '@mui/icons-material/Create';

type Props = {
    companyDetails: Company | undefined
    formModel?: any
};

type FormProps = {
    companyDetails: Company | undefined
    formModel: any
};

export const FormDetails: FC<Props> = ({ companyDetails, formModel }) => {
    let relatedForm = false // just test for ui
    return (
        <>
            {relatedForm ? <FormWithRelated companyDetails={companyDetails} formModel={formModel} /> : <FormWithOutRelated companyDetails={companyDetails} formModel={formModel} />}
        </>
    )
}


export const FormWithRelated: FC<FormProps> = ({ formModel, companyDetails }) => {
    const { t } = useTranslation();

    return (
        <Grid container md={12} bgcolor='secondary.contrastText' sx={{ borderRadius: '8px' }}>
            {/* <Grid container item md={12} mb={1} alignItems="center">
            <Grid container item md={7} mb={1} alignItems="center">
                <Typography variant="h5" mr={3} fontWeight={700}>{genericInfo?.name}</Typography>
                {showStatus && <StatusChip status={showStatus} title={t('texts.approvers')} listOfApprovers={listOfApprovers} />}
            </Grid>
            <Grid container item md={5} mb={1} justifyContent="flex-end">
                {actions}
            </Grid>
        </Grid> */}
            {/* <Grid item container md={4} pr={2} mt={2}>
            {children}
        </Grid> */}

            <Grid item container md={4}>
                <Grid item mr={2}>
                    <Box width='40px' height='95px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'primary.light', borderRadius: '4px' }}>
                        <BadgeIcon sx={{ color: 'primary.main', my: 1 }} />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="body2" fontWeight={600}>{companyDetails?.name}</Typography>
                    <TextViewWithValue title={t('texts.phone')} value={companyDetails?.work_number ?? t('texts.n_a')} color={'text.third'} />
                    <TextViewWithValue title={t('texts.fax')} value={companyDetails?.fax_number ?? t('texts.n_a')} color={'text.third'} />
                    <TextViewWithValue title={t('texts.address')} value={companyDetails?.company_address ?? t('texts.n_a')} color={'text.third'} />
                </Grid>
            </Grid>

            <Grid item container md={5}>
                <Grid item mr={2}>
                    <Box width='40px' height='95px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'primary.light', borderRadius: '4px' }}>
                        <ArticleIcon sx={{ color: 'primary.main', my: 1 }} />
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Typography variant="h6" fontWeight={600} color='text.primary'>{t('popups.titles.general_terms')}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'start', cursor: 'pointer', ml: 1 }} onClick={() => console.log('handle edit general')}>
                            <CreateIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                            <Typography variant="body2" fontWeight={500} color="primary.main">{t('buttons.edit')} </Typography>
                        </Box>
                    </Grid>
                    <TextViewWithValue title={t('texts.warrantyText')} value={formModel?.terms_bundle.warranty_term.name} color={'text.third'} />
                    <TextViewWithValue title={t('texts.paymentText')} value={formModel?.terms_bundle.payment_term.name} color={'text.third'} />
                    <TextViewWithValue title={t('texts.deliveryText')} value={formModel?.terms_bundle.delivery_term.name} color={'text.third'} />
                </Grid>
            </Grid>

            <Grid item container md={3}>
                <Grid item mr={2}>
                    <Box width='40px' height='95px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'primary.light', borderRadius: '4px' }}>
                        <DescriptionIcon sx={{ color: 'primary.main', my: 1 }} />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="h6" fontWeight={600}>{t('texts.related_forms')}</Typography>
                    <TextViewWithValue title={t('texts.req')} value={formModel?.requisition_name} color={'text.third'} />
                    <TextViewWithValue title={t('po')} value={formModel?.requisition_name} color={'text.third'} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export const FormWithOutRelated: FC<FormProps> = ({ formModel, companyDetails }) => {
    const { t } = useTranslation();

    return (
        <Grid container md={12} bgcolor='secondary.contrastText' sx={{ borderRadius: '8px' }}>
            <Box width='40px' height='45px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'primary.light', borderRadius: '4px' }}>
                <BadgeIcon sx={{ color: 'primary.main', my: 1 }} />
            </Box>

            <Grid item container md={5} pl={2}>
                <Grid item md={6}>
                    <Typography variant="body2" fontWeight={600}>{companyDetails?.name}</Typography>
                    <TextViewWithValue title={t('texts.phone')} value={companyDetails?.work_number ?? t('texts.n_a')} color={'text.third'} />
                </Grid>
                <Grid item md={6}>
                    <TextViewWithValue title={t('texts.fax')} value={companyDetails?.fax_number ?? t('texts.n_a')} color={'text.third'} />
                    <TextViewWithValue title={t('texts.address')} value={companyDetails?.company_address ?? t('texts.n_a')} color={'text.third'} />
                </Grid>
            </Grid>

            <Box width='40px' height='45px' sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', bgcolor: 'primary.light', borderRadius: '4px' }}>
                <ArticleIcon sx={{ color: 'primary.main', my: 1 }} />
            </Box>

            <Grid item container md={6} pl={2}>
                <Grid item md={6}>
                    <Grid item container md={12}>
                        <Typography variant="h6" fontWeight={600}>{t('popups.titles.general_terms')}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'start', cursor: 'pointer', ml: 1 }} onClick={() => console.log('handle edit general')}>
                            <CreateIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                            <Typography variant="body2" fontWeight={500} color="primary.main">{t('buttons.edit')} </Typography>
                        </Box>
                    </Grid>
                    <TextViewWithValue title={t('texts.warrantyText')} value={formModel?.terms_bundle.warranty_term.name} />
                </Grid>
                <Grid item md={6}>
                    <TextViewWithValue title={t('texts.paymentText')} value={formModel?.terms_bundle.payment_term.name} />
                    <TextViewWithValue title={t('texts.deliveryText')} value={formModel?.terms_bundle.delivery_term.name} />
                </Grid>
            </Grid>
        </Grid>
    )
}