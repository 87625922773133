import axios from 'axios';
import create from 'zustand';
import { apiURLs } from '@/config';
import { CommentGroup, Filters_Res, Product, TabModel } from '@/models';
import { useCreateComment, useGetAllComments, useGetCommentsNumberAndSeen } from '@/services';
import produce from 'immer';


export interface CommentsStore {
    commentsStoreProperties: {
        loadingComments: boolean
        displayedComments: Comment[]
        comments: CommentGroup[]
        selectedCommentGroupName: string | null
        commentsGroupsTabs: CommentGroup[]
        commentsSubgroups: CommentGroup[]
        commentsSubgroupsTabs: CommentGroup[]
        selectedCommentSubgroupName: string
        isCommentsInSubGroups: boolean
        numberOfComments: number
        readOnly: boolean
        isSeenNewComments: boolean
        commentType: string
        commentId: string
    }
    commentsStoreActions: {
        fetchComments: (commentType: string, commentId: string) => void
        setCommentsGroups: (commentsGroups: CommentGroup[], defaultName: string) => void
        changeDisplayedComment: (commentGroup: string) => void
        changeSubgroupDisplayedComment: (commentsubGroupName: string) => void
        createComment: (comment: string, userInfo: any) => void
        updateSeenNewComments: (isSeenn: boolean) => void
        getCommentsNumberAndSeenValue: (commentType: string, commentId: string) => void
        // buildCommentsGroups: (comments: CommentGroup[], groupName: string, subGroupName: string) => void
    }
}


export const useCommentsStore = create<CommentsStore>((set, get) => ({
    commentsStoreProperties: {
        loadingComments: false,
        comments: [],
        displayedComments: [],
        commentsGroupsTabs: [],
        selectedCommentGroupName: '',
        commentsSubgroups: [],
        commentsSubgroupsTabs: [],
        selectedCommentSubgroupName: '',
        isCommentsInSubGroups: false,
        numberOfComments: 0,
        readOnly: false,
        isSeenNewComments: true,
        commentType: '',
        commentId: ''
    },
    commentsStoreActions: {
        fetchComments: (commentType: string, commentId: string) => {
            const props = get().commentsStoreProperties
            if (commentType != props.commentType || commentId != props.commentId)
                set(produce(draftState => {
                    draftState.commentsStoreProperties.loadingComments = true
                    draftState.commentsStoreProperties.commentType = commentType
                    draftState.commentsStoreProperties.commentId = commentId
                }))
            useGetAllComments(commentType, commentId).then(data => {
                let comments = data.data
                get().commentsStoreActions.setCommentsGroups(comments, comments[0].name)
                set(produce(draftState => { draftState.commentsStoreProperties.loadingComments = false }))
            })
        },
        setCommentsGroups: (commentsGroups: CommentGroup[], defaultName: string) => {
            let commentsGroupsArray: TabModel[] = []
            commentsGroups?.map((commentsGroup: CommentGroup) => commentsGroupsArray.push({ id: commentsGroup.id, name: commentsGroup.name, disabled: !commentsGroup.active, checked: false, count: getNumberOfCommentsInGroup(commentsGroup) }))
            set(produce(draftState => {
                draftState.commentsStoreProperties.commentsGroupsTabs = commentsGroupsArray
                draftState.commentsStoreProperties.comments = commentsGroups
                draftState.commentsStoreProperties.numberOfComments = commentsGroupsArray.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
            }))
            get().commentsStoreActions.changeDisplayedComment(defaultName)//defaultName
        },
        changeDisplayedComment: (commentGroup: string) => {
            const selectedCommentGroupName = get().commentsStoreProperties.comments.filter(currentCommentGroup => currentCommentGroup.name == commentGroup && currentCommentGroup.active)[0]
            const isCommentsInSubGroups = selectedCommentGroupName?.sub_groups && selectedCommentGroupName?.active ? true : false
            let commentsSubGroupsTabs: TabModel[] = []
            if (isCommentsInSubGroups) {
                selectedCommentGroupName.sub_groups?.map((commentsSubgroup: CommentGroup) => commentsSubGroupsTabs.push({ id: commentsSubgroup.id, name: commentsSubgroup.name, checked: false, count: getNumberOfCommentsInGroup(commentsSubgroup) }))
            }
            set(produce(draftState => {
                draftState.commentsStoreProperties.selectedCommentGroupName = selectedCommentGroupName?.name
                draftState.commentsStoreProperties.readOnly = isCommentsInSubGroups ? selectedCommentGroupName?.sub_groups[0]?.read_only ?? false : selectedCommentGroupName?.read_only ?? false
                draftState.commentsStoreProperties.displayedComments = isCommentsInSubGroups ? selectedCommentGroupName?.sub_groups[0]?.comments ?? [] : selectedCommentGroupName?.comments ?? []
                draftState.commentsStoreProperties.selectedCommentSubgroupName = isCommentsInSubGroups ? selectedCommentGroupName?.sub_groups[0].name : null
                draftState.commentsStoreProperties.commentsSubgroups = isCommentsInSubGroups ? selectedCommentGroupName?.sub_groups : []
                draftState.commentsStoreProperties.commentsSubgroupsTabs = isCommentsInSubGroups ? commentsSubGroupsTabs : []
                draftState.commentsStoreProperties.isCommentsInSubGroups = isCommentsInSubGroups
            }))
        },
        changeSubgroupDisplayedComment: (commentSubgroupName: string) => {
            const selectedCommentSubgroupName = get().commentsStoreProperties.commentsSubgroups.filter(commentSubgroup => commentSubgroup.name == commentSubgroupName && commentSubgroup.active)[0]
            set(produce(draftState => {
                draftState.commentsStoreProperties.selectedCommentSubgroupName = selectedCommentSubgroupName?.name
                draftState.commentsStoreProperties.readOnly = selectedCommentSubgroupName?.read_only
                draftState.commentsStoreProperties.displayedComments = selectedCommentSubgroupName?.comments
            }))
        },
        createComment: (comment: string, userInfo: any) => {
            const commentProperties = get().commentsStoreProperties
            const selectedCommentGroup = commentProperties.isCommentsInSubGroups ?
                commentProperties.commentsSubgroups.filter(commentSubgroup => commentSubgroup.name == commentProperties.selectedCommentSubgroupName && commentSubgroup.active)[0]
                : commentProperties.comments.filter(commentGroup => commentGroup.name == commentProperties.selectedCommentGroupName && commentGroup.active)[0]
            const newComment = { user: { name: userInfo.full_name, profile_picture_url: userInfo.profile_picture_url }, text: comment, label: null, title: null, created_at: new Date() }
            const commentType = selectedCommentGroup.commentable_type
            const commentId = selectedCommentGroup.commentable_id
            const isExternal = selectedCommentGroup.is_external
            let commentsBeforeAddingComment = commentProperties.comments
            let comments = commentProperties.comments.map(commentGroup => {
                if (commentGroup.sub_groups) return produce(commentGroup, draftcommentGroup => {
                    draftcommentGroup.sub_groups = commentGroup.sub_groups?.map(subGroup => {
                        if (subGroup.commentable_id == commentId && subGroup.commentable_type == commentType && subGroup.is_external == isExternal)
                            return produce(subGroup, draftSubGroup => {
                                draftSubGroup.comments = [...subGroup?.comments, newComment]
                            })
                        return subGroup
                    })
                })
                else if (commentGroup.commentable_id == commentId && commentGroup.commentable_type == commentType && commentGroup.is_external == isExternal)
                    return produce(commentGroup, draftSubGroup => {
                        draftSubGroup.comments = [...commentGroup?.comments, newComment]
                    })
                return commentGroup
            })
            get().commentsStoreActions.setCommentsGroups(comments, commentProperties.selectedCommentGroupName)
            useCreateComment(comment, commentType, commentId, isExternal).catch(() => {
                get().commentsStoreActions.setCommentsGroups(commentsBeforeAddingComment, commentProperties.selectedCommentGroupName)
            })
        },
        updateSeenNewComments: (isSeenn: boolean) => set(produce(draftState => { draftState.commentsStoreProperties.isSeenNewComments = isSeenn })),
        getCommentsNumberAndSeenValue: (commentType: string, commentId: string) => {
            useGetCommentsNumberAndSeen(commentType, commentId).then(data => {
                let commentDetails = data.data
                set(produce(draftState => {
                    draftState.commentsStoreProperties.numberOfComments = commentDetails?.count
                    draftState.commentsStoreProperties.isSeenNewComments = !commentDetails?.has_new
                }))
            })
        }
    }
}));

const getNumberOfCommentsInGroup = (commentsGroup: CommentGroup) => {
    if (commentsGroup.sub_groups) return commentsGroup.sub_groups.reduce((accumulator, currentValue) => accumulator + currentValue?.comments?.length, 0);
    else if (commentsGroup.comments) return commentsGroup.comments.length
    else return 0
}