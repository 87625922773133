import { procurementStatus } from "@/config";
import { Approver } from "@/models";
import { useUserData } from "@/zustand";


export const useGetListOfApprovals = (ownerId: number, listOfApprovers: Approver[]) => {
    const userId = useUserData.getState().userInfo?.id
    if (userId == ownerId) return listOfApprovers
    else return listOfApprovers.map(approver => {
        return userId == approver.approver_id ? { ...approver, status: convertStatusToApproverStatus(approver.status) } : { ...approver }
    })
}
export const convertStatusToApproverStatus = (status: procurementStatus) => {
    switch (status) {
        case procurementStatus.PENDING_APPROVAL:
            return procurementStatus.PENDING_MY_APPROVAL;
        case procurementStatus.APPROVED:
            return procurementStatus.APPROVED_BY_ME;
        case procurementStatus.REJECTED:
            return procurementStatus.REJECTED_BY_ME;
        default:
            return status;
    }
}