import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import {
  EmailForm,
  VerificationCodeForm,
  NewPasswordForm,
  AccountRecoverySuccess,
} from '..';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { apiURLs } from '@/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getLoginTranslationKey } from '@/hooks';
import { NotifyError } from '@/services';

interface LoginProps { recoverySteps: number; setRecoverySteps: Function; }

export const AccountRecovery: React.FC<LoginProps> = (props: LoginProps) => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  const handleSubmit = (values, actions) => {
    switch (props.recoverySteps) {
      case 1:
        axios
          .post(apiURLs.forgetPassword, { email: values.email }, { withCredentials: true })
          .then(response => {
            props.setRecoverySteps(props.recoverySteps + 1);
            actions.setSubmitting(false);
            actions.setTouched({});
          })
          .catch(e => {
            if (e.response?.status == 422) {
              actions.setFieldError('email', e.response.data.error.key);
            }
          });
        break;
      case 2:
        axios
          .post(apiURLs.forgetPasswordVerify, {
            email: values.email,
            verification_code: values.verificationCode,
          })
          .then(response => {
            props.setRecoverySteps(props.recoverySteps + 1);
            setToken(response.data.token);
            actions.setTouched({});
            actions.setSubmitting(false);
          })
          .catch(e => NotifyError(t('notifications.server_error')));
        break;
      case 3:
        axios
          .post(apiURLs.resetPassword, {
            email: values.email,
            token: token,
            password: values.password,
            password_confirmation: values.password_confirmation,
          })
          .then(response => {
            props.setRecoverySteps(props.recoverySteps + 1);
          })
          .catch(e => NotifyError(t('notifications.server_error')));
        break;
      default:
        props.setRecoverySteps(0);
    }
  };
  const goBack = () => props.setRecoverySteps(props.recoverySteps - 1);
  const currentValidationSchema = validationSchema[props.recoverySteps - 1];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
      isolatedFields={true}
    >
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          {props.recoverySteps === 1 ? (
            <EmailForm
              disabledButton={!(formikProps.isValid && formikProps.dirty)}
              validate={()=>formikProps.validateForm()}
            />
          ) : props.recoverySteps === 2 ? (
            <VerificationCodeForm
              onBack={goBack}
              disabledButton={!(formikProps.isValid && formikProps.dirty)}
              validate={()=>formikProps.validateForm()}
            />
          ) : props.recoverySteps === 3 ? (
            <NewPasswordForm
              onBack={goBack}
              disabledButton={!(formikProps.isValid && formikProps.dirty)}
            />
          ) : (
            <AccountRecoverySuccess />
          )}
        </form>
      )}
    </Formik>
  );
};
