import { AuthenticateStatus, ProcurementButtons, ProductInCartType, ProductManagerEnv, ProductManagerEnvItem, ProductManagerPopupStatus } from "@/config";
import { GeneralObject, ProcurementModelDetails, Product, Seller } from "@/models";
import { compareTwoArrayOfObjects, NotifyError } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { toast } from "react-toastify";
import { StateCreator } from "zustand";

import EnvAddServiceMapper from "src/logic/services/products-saving/mappers/env-add-service-mapper";
import EnvRemoveServiceMapper from "src/logic/services/products-saving/mappers/env-remove-service-mapper";
import EnvChangeQuantityServiceMapper from "src/logic/services/products-saving/mappers/env-change-quantity-service-mapper";
import EnvChangePriceServiceMapper from "src/logic/services/products-saving/mappers/env-change-price-service-mapper";
import { useUserData } from "../../user";
import EnvChangeRequirementsServiceMapper from "src/logic/services/products-saving/mappers/env-change-requirements-service-mapper";

type SavingStatusType = "success" | "error" | "loading"
export interface ProductFormManagerSlice {
    productFormManagerProperties: {
        data: {
            savingStatus: SavingStatusType
            loadingProductsIds: number[]
            environment: ProductManagerEnvItem | null
            environmentDetails: Record<string, GeneralObject> | null
            formsArray: ProcurementModelDetails[]
            formDetails: ProcurementModelDetails | null
            FormHasChangesToSave: boolean
            products: Product[]
            persistentProducts: Product[]
            candidateProductToAdd: Product | null
            candidateSellerRelatedToPo: Seller | null
            productManagerPopupStatus: ProductManagerPopupStatus | null
            customLoadingButton: ProcurementButtons | null
            summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
            quantityTimer: {} // Maps Product quantity to a timer
            requirementsTimer: {} // Maps Product Requirements to a timer
            priceTimer: {} // Maps Product Price to a timer
            productIdRequestedToBeAdded: number | undefined;
        },
        actions: {
            addProductToLoading: (product_id: number) => void;
            removeProductFromLoading: (product_id: number, firstMatchOnly?: boolean) => void;
            setSavingStatus: (newStatus: SavingStatusType) => void;
            setEnvironment: (environment: ProductManagerEnvItem) => void
            setFormsArray: (formsArray: ProcurementModelDetails[]) => void
            setFormDetails: (formDetails: ProcurementModelDetails | null) => void
            setFormHasChangesToSave: (FormHasChangesToSave: boolean) => void
            setProductsArray: (products: Product[]) => void
            setPersistentProductsArray: (products: Product[]) => void
            setProductManagerPopupStatus: (productManagerPopupStatus: ProductManagerPopupStatus | null) => void
            setCandidateProductToAdd: (product: Product | null) => void
            setCandidateSellerRelatedToForm: (seller: Seller | null) => void
            markFormHasNewChanges: () => void
            addProduct: (product: Product, isAlternative?: boolean) => void
            removeProduct: (product: Product, isAlternative?: boolean) => void
            changePrice: (product: Product, price: string) => void
            changeQuantity: (product: Product, quantity: number) => void
            changeRequirements: (product: Product, requirements: string) => void
            setQuantity: (product: Product, quantity: number) => void
            transformProduct: (product: Product, form: ProcurementModelDetails, isAlternative?: boolean) => Product
            toggleForm: (formId: string, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => void
            buildInitialProductState: (product: Product) => Product
            selectForm: (values: any, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => void
            createForm: (values: any, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => void
            setDetails: (form: ProcurementModelDetails | null, environment: ProductManagerEnvItem) => void
            compareForms: () => boolean
            reset: () => void
            save: (props?: {onSuccess?: () => void, productSaving?: number}) => void
            setSummaryDetails: (products: Product[]) => void
            setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
            setRequirementTimer: (productId: number, timer: NodeJS.Timeout | null) => void
            setQuantityTimer: (productId: number, timer: NodeJS.Timeout | null) => void
            setPriceTimer: (productId: number, timer: NodeJS.Timeout | null) => void
            setProductIdRequestedToBeAdded: (productId: undefined | number) => void
        }
    },
}


export const createProductFormManagerSlice: StateCreator<ProductFormManagerSlice> = (set, get, api) => ({
    productFormManagerProperties: {
        data: {
            loadingProductsIds: [],
            savingStatus: "success",
            environment: null,
            environmentDetails: null,
            formsArray: [],
            formDetails: null,
            FormHasChangesToSave: false,
            products: [],
            persistentProducts: [],
            candidateProductToAdd: null,
            candidateSellerRelatedToPo: null,
            productManagerPopupStatus: null,
            customLoadingButton: null,
            summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
            productIdRequestedToBeAdded: undefined,
        },
        actions: {
            addProductToLoading: (product_id: number) => {
                const loadingProductsIds = get().productFormManagerProperties.data.loadingProductsIds;
                set(produce(draftState => {
                    draftState.productFormManagerProperties.data.loadingProductsIds = [...loadingProductsIds, product_id];
                }))
            },
            removeProductFromLoading: (product_id: number, firstMatchOnly: boolean = true) => {
                const oldLoadingProductsIds = get().productFormManagerProperties.data.loadingProductsIds;
                let newLoadingProductsIds: number[] = [];
                if(firstMatchOnly) {
                    const firstMatchIndex = oldLoadingProductsIds.indexOf(product_id);
                    newLoadingProductsIds = oldLoadingProductsIds.filter((_, index) => index !== firstMatchIndex);
                } else {
                    newLoadingProductsIds = oldLoadingProductsIds.filter(i => i !== product_id)
                }
                
                set(produce(draftState => {
                    draftState.productFormManagerProperties.data.loadingProductsIds = newLoadingProductsIds;
                }))
            },
            setSavingStatus: (newStatus: SavingStatusType) => set(produce(draftState => { draftState.productFormManagerProperties.data.savingStatus = newStatus })),
            setEnvironment: (environment: ProductManagerEnvItem) => set(produce(draftState => { draftState.productFormManagerProperties.data.environment = environment })),
            setFormsArray: (formsArray: ProcurementModelDetails[]) => set(produce(draftState => { draftState.productFormManagerProperties.data.formsArray = formsArray })),
            setFormDetails: (formDetails: ProcurementModelDetails | null) => set(produce(draftState => { draftState.productFormManagerProperties.data.formDetails = formDetails })),
            setFormHasChangesToSave: (FormHasChangesToSave: boolean) => set(produce(draftState => { draftState.productFormManagerProperties.data.FormHasChangesToSave = FormHasChangesToSave })),
            setProductsArray: (products: Product[]) => {
                const props = get().productFormManagerProperties
                set(produce(draftState => { draftState.productFormManagerProperties.data.products = products }))
                if ([ProductManagerEnv.purchase_order, ProductManagerEnv.quote, ProductManagerEnv.edit_quote_from_scratch, ProductManagerEnv.edit_po_from_scratch].includes(props.data.environment?.type)) {
                    props.actions.setSummaryDetails(products)
                }

                const authenticationStatus = useUserData.getState().authenticationStatus;
                const isGuestUser = authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
                if(get().productFormManagerProperties.data.environment?.type === ProductManagerEnv.requisition && isGuestUser) {
                    get()["requisitionSliceActions"].setProducts(get().productFormManagerProperties.data.formDetails?.id, products)
                }
            },
            setPersistentProductsArray: (products: Product[]) => set(produce(draftState => { draftState.productFormManagerProperties.data.persistentProducts = products })),
            setProductManagerPopupStatus: (productManagerPopupStatus: ProductManagerPopupStatus | null) => {
                set(produce(draftState => { draftState.productFormManagerProperties.data.productManagerPopupStatus = productManagerPopupStatus }))
                if (productManagerPopupStatus === null) set(produce(draftState => { draftState.productFormManagerProperties.data.candidateSellerRelatedToPo = null }))
            },
            setCandidateProductToAdd: (product: Product | null) => set(produce(draftState => { draftState.productFormManagerProperties.data.candidateProductToAdd = product })),
            setCandidateSellerRelatedToForm: (seller: Seller | null) => set(produce(draftState => { draftState.productFormManagerProperties.data.candidateSellerRelatedToPo = seller })),
            addProduct: (product: Product, isAlternative?: boolean) => {
                let actions = get().productFormManagerProperties.actions;
                let data = get().productFormManagerProperties.data;
                // if (
                // !product.price &&
                // data.environment?.type === ProductManagerEnv.purchase_order
                // ) {
                //     toast.error(t('notifications.no_price_added'), {
                //         toastId: 'no_price_added',
                //     });
                //     return;
                // }

                //TODO - Remove when all API's are implemented
                const defaultSaving = () => new Promise(() => {
                    get().productFormManagerProperties.actions.save({ productSaving: product.id });
                })
                const serviceToExecute = EnvAddServiceMapper({
                    env: data.environment?.type,
                    formId: data.formDetails?.id,
                    product,
                    isAlternative
                }) ?? defaultSaving
                get().productFormManagerProperties.actions.addProductToLoading(product.id);
                serviceToExecute().then(() => {
                    data = get().productFormManagerProperties.data;
                    let products = data.products;
                    const newProduct = produce(product, draftProduct => {
                        draftProduct.inCartType = isAlternative
                            ? ProductInCartType.ALTERNATIVE
                            : data.environment?.productInCartType;
                        draftProduct.inCart = true;
                        draftProduct.quantity = product.quantity;
                        draftProduct.price = product.price;
                        draftProduct.additional_requirements = product.additional_requirements;
                        draftProduct.alternatives = [];
                    });

                    if (isAlternative && newProduct.parentId) {
                        products = products.map(element => {
                        return element.id == newProduct.parentId
                            ? {
                                ...element,
                                alternatives: element.alternatives
                                ? [...element.alternatives, newProduct]
                                : [newProduct],
                            }
                            : { ...element };
                        });
                    } else {
                        products = [...products, newProduct];
                    }
                    actions.setProductsArray(products);

                    const newForm = produce(data.formDetails, draftState => {
                        draftState.products = products;
                    });
                    actions.setFormDetails(newForm);
                    // actions.setFormHasChangesToSave(!actions.compareForms());
                }).catch(() => {
                    NotifyError(`Failed to add ${product.name} to ${data.environment?.type}`)                    
                }).finally(() => {
                    get().productFormManagerProperties.actions.removeProductFromLoading(product.id)
                })
                
                
            },
            removeProduct: (product: Product, isAlternative?: boolean) => {
                get().productFormManagerProperties.actions.addProductToLoading(product.id);

                //TODO - Remove when all API's are implemented
                const defaultSaving = () => new Promise(() => {
                    get().productFormManagerProperties.actions.save({ productSaving: product.id });
                })

                const serviceToExecute = EnvRemoveServiceMapper({
                    env: get().productFormManagerProperties.data.environment?.type,
                    formId: get().productFormManagerProperties.data.formDetails?.id,
                    product,
                    isAlternative
                }) ?? defaultSaving

                let data = get().productFormManagerProperties.data
                serviceToExecute().then(() => {
                    let actions = get().productFormManagerProperties.actions
                    data = get().productFormManagerProperties.data
                    let newProductsArray = [...data.products]
                    const env = get().productFormManagerProperties.data.environment?.type;
                    if (isAlternative && (env === ProductManagerEnv.requisition || env === ProductManagerEnv.edit_bundle_rfq)) {
                        newProductsArray = newProductsArray.map(mainProduct => {
                            return mainProduct.id == product.parentId ? { ...mainProduct, alternatives: mainProduct.alternatives.filter(alternative => alternative.id != product.id) }
                                : { ...mainProduct }
                        })
                    }
                    else newProductsArray = newProductsArray.filter(formProducts => formProducts.id != product.id)
                    actions.setProductsArray(newProductsArray)
                    let newForm = produce(data.formDetails, draftState => {
                        draftState.products = newProductsArray
                    })
                    actions.setFormDetails(newForm)
                    // actions.setFormHasChangesToSave(!actions.compareForms())
                }).catch(() => {
                    NotifyError(`Failed to remove ${product.name} from ${data.environment?.type}`)                    
                }).finally(() => {
                    get().productFormManagerProperties.actions.removeProductFromLoading(product.id)
                })
            },
            markFormHasNewChanges: () => {
                const actions = get().productFormManagerProperties.actions
                actions.setFormHasChangesToSave(!actions.compareForms())
            },
            changePrice: (product: Product, price: string) => {
                const actions = get().productFormManagerProperties.actions
                
                //TODO - Remove when all API's are implemented
                const defaultSaving = () => new Promise(() => {
                    get().productFormManagerProperties.actions.save({ productSaving: product.id });
                })
                
                const data = get().productFormManagerProperties.data
                let newProductsArray = changeProductValueInArray(data.products, product, 'price', price) ?? data.products
                actions.setProductsArray(newProductsArray)
                let newForm = produce(data.formDetails, draftState => {
                    draftState.products = newProductsArray
                })
                actions.setFormDetails(newForm)

                // if(!price) return; //Expiremental

                if(data.priceTimer && data.priceTimer[product.id]) {
                    clearTimeout(data.priceTimer?.[product.id]);
                }
                const newTimer = setTimeout(() => {
                    actions.setPriceTimer(product.id, null)
                    const serviceToExecute = EnvChangePriceServiceMapper({
                        env: get().productFormManagerProperties.data.environment?.type,
                        formId: get().productFormManagerProperties.data.formDetails?.id,
                        product,
                        newPrice: price
                    }) ?? defaultSaving
                    
                    actions.addProductToLoading(product.id);
                    serviceToExecute().then(() => {
                        
                    }).catch(() => {
                        NotifyError(`Failed to change price for ${product.name}`)                    
                    }).finally(() => {
                        get().productFormManagerProperties.actions.removeProductFromLoading(product.id)
                    })
                }, 500);
                actions.setPriceTimer(product.id, newTimer)
            },
            changeQuantity: (product: Product, quantity: number) => {
                const actions = get().productFormManagerProperties.actions
                
                //TODO - Remove when all API's are implemented
                const defaultSaving = () => new Promise(() => {
                    get().productFormManagerProperties.actions.save({ productSaving: product.id });
                })
                
                const data = get().productFormManagerProperties.data
                let newProductsArray = changeProductValueInArray(data.products, product, 'quantity', quantity) ?? data.products
                actions.setProductsArray(newProductsArray)
                let newForm = produce(data.formDetails, draftState => {
                    draftState.products = newProductsArray
                })
                actions.setFormDetails(newForm)

                if(data.quantityTimer && data.quantityTimer[product.id]) {
                    clearTimeout(data.quantityTimer?.[product.id]);
                }
                const newTimer = setTimeout(() => {
                    actions.setQuantityTimer(product.id, null)
                    const serviceToExecute = EnvChangeQuantityServiceMapper({
                        env: get().productFormManagerProperties.data.environment?.type,
                        formId: get().productFormManagerProperties.data.formDetails?.id,
                        product,
                        newQuantity: quantity
                    }) ?? defaultSaving
                    
                    actions.addProductToLoading(product.id);
                    serviceToExecute().then(() => {
                        
                    }).catch(() => {
                        NotifyError(`Failed to update quantity for ${product.name}`)                    
                    }).finally(() => {
                        get().productFormManagerProperties.actions.removeProductFromLoading(product.id)
                    })
                }, 500);
                actions.setQuantityTimer(product.id, newTimer)
            },
            changeRequirements: (product: Product, requirements: string) => {
                const actions = get().productFormManagerProperties.actions
                
                //TODO - Remove when all API's are implemented
                const defaultSaving = () => new Promise(() => {
                    get().productFormManagerProperties.actions.save({ productSaving: product.id });
                })
                
                const data = get().productFormManagerProperties.data
                const newProductsArray = data.products?.map(prod => {
                    if(prod.id === product.id) return {
                        ...prod,
                        additional_requirements: requirements
                    }
                    return prod
                }) ?? null

                actions.setProductsArray(newProductsArray)
                let newForm = produce(data.formDetails, draftState => {
                    draftState.products = newProductsArray
                })
                actions.setFormDetails(newForm)

                if(data.requirementsTimer && data.requirementsTimer[product.id]) {
                    clearTimeout(data.requirementsTimer?.[product.id]);
                }
                const newTimer = setTimeout(() => {
                    if(requirements) {
                        actions.setRequirementTimer(product.id, null)
                        const serviceToExecute = EnvChangeRequirementsServiceMapper({
                            env: get().productFormManagerProperties.data.environment?.type,
                            formId: get().productFormManagerProperties.data.formDetails?.id,
                            product,
                            newRequirements: requirements
                        }) ?? defaultSaving
                        
                        actions.addProductToLoading(product.id);
                        serviceToExecute().then(() => {
                            
                        }).catch(() => {
                            NotifyError(`Failed to update requirements for ${product.name}`)                    
                        }).finally(() => {
                            get().productFormManagerProperties.actions.removeProductFromLoading(product.id)
                        })
                    }
                }, 3000);
                actions.setRequirementTimer(product.id, newTimer)
            },
            setQuantity: (product: Product, quantity: number) => {
                const data = get().productFormManagerProperties.data
                const actions = get().productFormManagerProperties.actions
                const newProductsArray = data.products.map(prod => prod.id === product.id ? ({ ...prod, quantity }) : prod)
                actions.setProductsArray(newProductsArray)
                // actions.setFormHasChangesToSave(!actions.compareForms())
            },
            transformProduct: (product: Product, form: ProcurementModelDetails, isAlternative?: boolean) => {
                const environment = get().productFormManagerProperties.data.environment
                const inCartType = isAlternative ? ProductInCartType.ALTERNATIVE : environment?.productInCartType
                if (form) {
                    if (isAlternative) {
                        const parentId = product?.parentId
                        const findMainProduct = form.products.find(element => element.id === (parentId ?? product.id))
                        if (findMainProduct) {
                            const findAlternativeProduct = findMainProduct?.alternatives?.find((element: Product) => element.id === product.id)
                            if (findAlternativeProduct)
                                return produce(product, (draftProduct) => {
                                    draftProduct.inCart = true
                                    draftProduct.inCartType = inCartType
                                    draftProduct.inCart = true
                                })
                        }
                    }
                    else if (form.products.find(element => element.id === product?.id)) {
                        const productInCart = form.products.find(element => element.id === product.id)
                        return produce(product, (draftProduct) => {
                            draftProduct.inCart = true
                            draftProduct.inCartType = inCartType
                            draftProduct.quantity = productInCart?.quantity
                            draftProduct.price = productInCart?.price
                            draftProduct.additional_requirements = productInCart?.additional_requirements
                            draftProduct.alternatives = productInCart?.alternatives ?? []
                        })
                    }
                }
                return produce(product, (draftProduct) => {
                    draftProduct.inCart = false
                    draftProduct.inCartType = inCartType
                    draftProduct.quantity = 1
                    draftProduct.price = product?.price ?? product.catalog_price ?? ''
                    draftProduct.additional_requirements = ''
                })
            },
            buildInitialProductState: (product: Product) => {
                return produce(product, (draftProduct) => {
                    draftProduct.inCart = false
                    draftProduct.quantity = 1
                    draftProduct.price = product.price ?? ''
                    draftProduct.additional_requirements = product.additional_requirements ?? ''
                })
            },
            compareForms: () => {
                const data = get().productFormManagerProperties.data
                return compareTwoArrayOfObjects(data?.products, data?.persistentProducts)
            },
            selectForm: (values: any, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => {
                const actions = get().productFormManagerProperties.actions
                actions.setFormsArray(forms)
                const handlerAfterFetch = (data: ProcurementModelDetails) => {
                    actions.setDetails(data, environment)
                    actions.setProductManagerPopupStatus(null)
                    if (applyFilters) applyFilters(data)
                    if (!data.products.find(product => product.id == values.product.id)) {
                        actions.addProduct(values.product)
                    }
                }
                get()[sliceName].handleSelectForm(values, handlerAfterFetch)
            },
            createForm: (values: any, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => {
                const actions = get().productFormManagerProperties.actions
                actions.setFormsArray(forms)
                const handlerAfterCreate = (data: ProcurementModelDetails) => {
                    actions.setDetails(data, environment)
                    actions.setProductManagerPopupStatus(null)
                    if (applyFilters) applyFilters(data)
                    if (!data.products.find(product => product.id == values.product.id)) {
                        actions.addProduct(values.product)
                    }
                }
                get()[sliceName].create(values, handlerAfterCreate)
            },
            toggleForm: (formId: string, sliceName: string, environment: ProductManagerEnvItem, forms: ProcurementModelDetails[], applyFilters?: (data: ProcurementModelDetails) => void) => {
                const actions = get().productFormManagerProperties.actions
                actions.setFormsArray(forms)
                const handlerAfterFetch = (data: ProcurementModelDetails) => {
                    actions.setDetails(data, environment)
                    actions.setProductManagerPopupStatus(null)
                    if (applyFilters) applyFilters(data)
                }
                get()[sliceName].fetchDetails(formId, handlerAfterFetch)
            },
            setDetails: (form: ProcurementModelDetails | null, environment: ProductManagerEnvItem) => {
                const actions = get().productFormManagerProperties.actions
                const data = get().productFormManagerProperties.data
                actions.setEnvironment(environment)
                const productsArray = form?.products.map(product => { return actions.transformProduct(product, form) })
                actions.setFormDetails(form)
                actions.setProductsArray(productsArray ?? [])
                actions.setPersistentProductsArray(productsArray ?? [])
                if (data.persistentProducts.length === 0) actions.setFormHasChangesToSave(false)
                actions.setFormHasChangesToSave(!actions.compareForms())
                set(produce(draftState => { draftState.productFormManagerProperties.data.customLoadingButton = null }))
            },
            reset: () => {
                const actions = get().productFormManagerProperties.actions
                actions.setEnvironment(null)
                actions.setFormDetails(null)
                actions.setProductsArray([])
                actions.setCandidateProductToAdd(null)
                actions.setCandidateSellerRelatedToForm(null)
                actions.setFormsArray([])
                actions.setFormHasChangesToSave(false)
                actions.setPersistentProductsArray([])
            },
            save: ({ onSuccess, productSaving }: { onSuccess?: () => void, productSaving?: number } = {}) => {
                let environment = get().productFormManagerProperties.data.environment
                get().productFormManagerProperties.actions.setCustomLoadingButton(ProcurementButtons.SAVE_PO_UPDATES)
                const data = get().productFormManagerProperties.data
                
                get().productFormManagerProperties.actions.setSavingStatus("loading")
                if(productSaving) {
                    get().productFormManagerProperties.actions.addProductToLoading(productSaving)
                }
                const handleSavingSucess = (data: ProcurementModelDetails) => {
                    // get().productFormManagerProperties.actions.setDetails(data, environment)

                    get().productFormManagerProperties.actions.setSavingStatus("success")
                    if(productSaving) {
                        get().productFormManagerProperties.actions.removeProductFromLoading(productSaving)
                    }

                    // NotifySuccess(t('notifications.changes_saved'))
                    onSuccess?.();
                }
                const onError = () => {
                    get().productFormManagerProperties.actions.setSavingStatus("error")
                    if(productSaving) {
                        get().productFormManagerProperties.actions.removeProductFromLoading(productSaving)
                    }
                }
                if (environment) get()[environment.actionsSliceName].saveFormProducts(data.formDetails, data.products, handleSavingSucess, onError)
            },
            setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
                set(produce(draftState => {
                    draftState.productFormManagerProperties.data.customLoadingButton = customButton
                    if (!customButton) { draftState.productFormManagerProperties.data.customLoadingButton = null }
                }))
            },
            setSummaryDetails: (products: Product[]) => {
                let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product.price) * product.quantity), 0);
                let vat = (10 / 100) * subtotal
                set(produce(draftState => {
                    draftState.productFormManagerProperties.data.summaryDetails.SummarySubtotal = subtotal
                    draftState.productFormManagerProperties.data.summaryDetails.SummaryVat = vat.toFixed(2)
                    draftState.productFormManagerProperties.data.summaryDetails.SummaryTotal = subtotal + vat
                }))
            },
            setQuantityTimer: (productId: number, timer: NodeJS.Timeout | null) => {
                const newQuantityTimer = {
                    ...get().productFormManagerProperties.data.quantityTimer,
                    [productId]: timer
                }
                set(produce(draftState => { draftState.productFormManagerProperties.data.quantityTimer = newQuantityTimer }))
            },
            setRequirementTimer: (productId: number, timer: NodeJS.Timeout | null) => {
                const newRequirementTime = {
                    ...get().productFormManagerProperties.data.requirementsTimer,
                    [productId]: timer
                }
                set(produce(draftState => { draftState.productFormManagerProperties.data.requirementsTimer = newRequirementTime }))
            },
            setPriceTimer: (productId: number, timer: NodeJS.Timeout | null) => {
                const newPriceTimer = {
                    ...get().productFormManagerProperties.data.priceTimer,
                    [productId]: timer
                }
                set(produce(draftState => { draftState.productFormManagerProperties.data.priceTimer = newPriceTimer }))
            },
            setProductIdRequestedToBeAdded: (productId: undefined | number) => {
                set(produce(draftState => { draftState.productFormManagerProperties.data.productIdRequestedToBeAdded = productId }))
            },
        }
    }
})

const changeProductValueInArray = (products: Product[], product: Product, key: string, value: string) => {
    let productsArray = [...products]
    var index = productsArray.findIndex(formProduct => formProduct.id == product.id);
    // if (index != -1 && value * 1 > 0) {
    if (index != -1) {
        const newProduct = produce(productsArray[index], draftState => { draftState[key] = value })
        productsArray[index] = { ...newProduct }
        return [...productsArray]
    }
}
