import { ConvertToRFQData, GeneralTerms } from "@/models";
import { Formik } from "formik";
import { FC, useState } from "react";
import { EditButton } from "src/ui/components/shared";
import { GeneralTermsPopup } from "../procurement-popups";

interface EditGeneralTermsProps {
    terms: GeneralTerms
    formTerms: ConvertToRFQData
}
interface formType { payment_term_id: number; warranty_term_id: number; delivery_term_id: number }


export const EditGeneralTerms: FC<EditGeneralTermsProps> = ({ terms, formTerms }) => {
    const [popupOpened, setpopupOpened] = useState(false)
    const closePopup = () => setpopupOpened(false)
    const openPopup = () => setpopupOpened(true)
    const handleSaveGeneralTerms = (values: formType) => {
        closePopup()
    }
    const initialValues = { payment_term_id: formTerms.payment_term?.id, warranty_term_id: formTerms.warranty_term?.id, delivery_term_id: formTerms.delivery_term?.id } as formType

    return (
        <>
            <EditButton handleClick={openPopup} />
            <Formik enableReinitialize initialValues={initialValues} onSubmit={() => { }}>
                {formikProps => (
                    <GeneralTermsPopup
                        isPopupOpen={popupOpened}
                        closePopup={closePopup}
                        terms={terms}
                        fieldValidation={formikProps.validateField}
                        handleSaveGeneralTerms={() => handleSaveGeneralTerms(formikProps.values)} />
                )}
            </Formik>
        </>
    )
}