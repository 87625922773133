import { useState, useEffect } from 'react';

const useImagePreview = (defaultValue: any) => {
  const [preview, setPreview] = useState(defaultValue || null);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const handleImageChange = (event: any) => {
    if (event.target.files[0]) {
      setPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  return {
    preview,
    handleImageChange,
    setPreview,
  };
};

export default useImagePreview;
