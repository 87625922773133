import { useDebounce } from '@/hooks';
import { Autocomplete, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { at } from 'lodash';
import type { FC } from 'react';
import React from 'react';
import { SellerCompany } from 'src/logic/models/catalogue/LinkOrCreateSellerProps';
import ClearIcon from '@mui/icons-material/Clear';

const inputStyle = {
  width: '100%',
};

const textInputStyle = {
  color: '#3A3E50',
  'input::placeholder': {
    fontWeight: '400',
  },
};

interface CompanyFieldProps {
  translateKey: string;
  name: string;
  field_seller_id: string;
  items: SellerCompany[] | never[];
  onChange?: Function;
  onSearch?: Function;
  isLoading?: boolean;
  required?: boolean;
  onClear?: () => void;
}

export const CompanySellerField: FC<CompanyFieldProps> = (props: CompanyFieldProps) => {
  const [field, meta, helpers] = useField({ name: props.name });
  const { values, setFieldValue } = useFormikContext();
  const [searchText, setSearchText] = React.useState('');

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  useDebounce(
    () => {
      if (props.onSearch) {
        props.onSearch(searchText);
      }
    },
    700,
    [searchText]
  );

  return (
    <Typography component='label' color='text.third' fontWeight='400' variant='subtitle2' sx={{ display: 'block' }}>
      {props.translateKey} {props.required ? ' *' : ''}
      <Autocomplete 
        options={props.items}
        getOptionLabel={option => option?.name}
        value={props.items.find(item => item.name === values[props.name]) || { name: searchText }}
        // onSelect
        onChange={(event, value) => {
          if (value) {
            const sellerId = value ? value.id : '';
            if (props.onChange) props.onChange(sellerId);
            setFieldValue(props.field_seller_id, sellerId);
            setFieldValue(props.name, value ? value.name : '');
          } else {
            return;
          }
        }}
        loading={props.isLoading}
        ListboxProps={{
          style: {
            maxHeight: 140,
            marginInline: 1,
            overflowY: 'auto',
          },
          className: 'styled-scrollbar',
        }}
        onBlur={() => {}}
        freeSolo={true}
        renderInput={params => (
          <TextField
            placeholder={`Insert Or Select ${props.translateKey}`}
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            {...params}
            {...field}
            sx={{
              ...inputStyle,
            }}
            onBlur={() => {}}
            value={searchText}
            InputProps={{
              ref: params.InputProps.ref,
              sx: textInputStyle,
              autoFocus: false,
              endAdornment: (
                <>
                  {(searchText || values[props.field_seller_id]) && (
                    <InputAdornment position='end' sx={{ margin: 0, padding: 0, transform: 'translateX(24px)' }}>
                      <IconButton
                        aria-label='clear'
                        onClick={() => {
                          setSearchText('');
                          setFieldValue(props.name, '');
                          setFieldValue(props.field_seller_id, '');
                          props.onClear?.();
                        }}
                        edge='end'
                      >
                        <ClearIcon sx={{ fontSize: '14px' }} />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            // onTextChange
            onChange={e => {
              setSearchText(e.target.value);
              setFieldValue(props.name, e.target.value);
            }}
          />
        )}
        filterOptions={(options, inputValue) => {
          const filtered = options.filter(option =>
            option.name.toLowerCase().includes(inputValue.inputValue.toLowerCase())
          );

          if (inputValue.inputValue !== '' && !props.isLoading) {
            filtered.push({
              id: '',
              name: `${inputValue.inputValue}`,
              isCustom: true,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.isCustom ? (
              <Typography variant='subtitle2' color='primary.main' style={{ fontWeight: 700 }}>
                + Create "{option.name}"
              </Typography>
            ) : (
              option.name
            )}
          </li>
        )}
      />
    </Typography>
  );
};
