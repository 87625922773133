import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { useMutation, useQuery } from '@tanstack/react-query'

export const fetchVacancies = async () => {
  return request({
    url: apiRoutes.vacanciesURL,
    method: "GET"
  })
}

export const useVacancies = () => {
  return useQuery(['vacancies'], () => fetchVacancies())
}

const postQuestion = async (formData) => {
  return request({
    url: apiRoutes.careerInquiry,
    method: "POST",
    data: formData
  })
}

export const useVacancyQuestionaire = ({ onSuccess, onError }) => {
  return useMutation((formData) => postQuestion(formData), { onSuccess: onSuccess, onError: onError })
}

const applyToVacancy = async (formData) => {
  return request({
    url: apiRoutes.applyVacancy,
    method: "POST",
    data: formData
  })
}

export const useVacancyApply = ({ onSuccess, onError }) => {
  return useMutation((formData) => applyToVacancy(formData), { onSuccess: onSuccess, onError: onError })
}