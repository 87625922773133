import { StateCreator } from "zustand";
import produce from "immer";
import { QuoteProductTableRow, ProductTableRow, TabModel, BundlePurchaseOrderAPIResponse, GeneralObject, BundleProcurementDetails, ProcurementFormModel, ProcurementModelDetails, LinkType } from "@/models";
import { getVATTotalValues } from "./createProcurementSlice";
import { buildBuyerBundlePurchaseOrderDetails, buildPurchaseOrderProductsTable, bulkApproval, ChildApprovalChange, getNumberofBundlePendingApprovalChild, NotifyMessage, NotifySuccess, NotifySuccessWithDeleteIcon, NotifySuccessWithCancelIcon, reBuildProcurementBundleFormDetails, useApproveBundlePurchaseOrder, useApprovePurchaseOrder, useBulkApproval, useCancelBundlePurchaseOrder, useChangeProject, useDeclineRequestExtension, useDeleteBundlePO, useDeleteIndividualPO, useDuplicateSinglePo, useEditExpiryDate, useFetchRejectPurchaseOrderReasons, useGetBuyerBundlePurchaseOrderDetails, useGetRecalledReasons, useRecallBundlePO, useRecallSinglePO, useRejectBundlePurchaseOrder, useRejectPurchaseOrder, useRejectSendBundlePurchaseOrder, useSendBundlePoToSeller, useSendBundlePurchaseOrderForApproval, useSendPoToSeller, useUpdateDetailsProduct, useRemoveProduct, useRequestExtension } from "@/services";
import { useUserData } from "../../user";
import { ProcurementButtons, ProcurementForms, ProcurementPopupStatus, procurementStatus } from "@/config";
import { t } from "i18next";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";


export interface BuyerBundlePurchaseOrderDetailsSlice {
    bundlePurchaseOrderBuyerDetailsSliceProperties: {
        bundleDetails: BundleProcurementDetails | null
        tabs: TabModel[] | null,
        selectedPO: ProcurementFormModel | null,
        fetchBundlePoDetailsQueryStatus: QueryHandlerModel,
        // loadingDetails: boolean
        productsTableRows: QuoteProductTableRow[]
        newComments: Comment[]
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        openedPopupStatus: ProcurementPopupStatus | null
        openedSuccessPopupsStatus: ProcurementPopupStatus | null
        reasonsForPopup: GeneralObject[]
        approvalChanges: ChildApprovalChange[]
        customLoadingButton: ProcurementButtons | null
    }
    bundlePurchaseOrderBuyerDetailsSliceActions: {
        fetchDetails: (bundlePOId: number, poId?: number) => void
        // setIsLoading: (isLoading: boolean) => void
        setDetails: (purchaseOrderResponse: BundlePurchaseOrderAPIResponse, poId?: number, disableSetSelectedPO?: boolean) => void
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => void
        closeNewCommentsPopup: () => void
        setSummaryDetails: (products: QuoteProductTableRow[]) => void
        setSelectedPO: (poId: number) => void
        getTabsDetails: (purchaseOrders: ProcurementFormModel[]) => TabModel[]
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => void
        getReasonsForPopup: () => void
        sendForApproval: (optionalComment: string) => void
        rejectAll: (reasonId: string, comment: string) => void
        approveAll: (comment: string) => void
        // reject: (reasonId: string, comment: string) => void
        // approve: (comment: string) => void
        cancel: (comment: string) => void
        rebuildDetails: (purchaseOrder: BundlePurchaseOrderAPIResponse | null, successPopupStatus?: ProcurementPopupStatus, poId?: number | undefined) => void
        approvalProcess: (approvalStatus: procurementStatus, comment: string, reasonId?: string) => void
        setApprovalChanges: (approvalChanges: ChildApprovalChange[]) => void
        changeProject: (project: GeneralObject) => void
        sendBundlePOToSeller: (bundlePOId: number | undefined, date: string, optionalComment: string | null) => void
        sendPOToSeller: (poId: number | undefined, date: string, optionalComment: string | null) => void
        RecallBundlePO: (comment: string, reasonId?: string) => void
        CancelBundlePO: (comment: string, reasonId?: string) => void
        recallPO: (poId: number | undefined, comment: string, reasonId?: string) => void
        CancelIndividualPO: (poId: number | undefined, comment: string, reasonId?: string) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        deleteBundlePO: (poId: number | undefined, redirectToPOs: () => void) => void
        deleteIndividualPO: (poId: number | undefined, poName: string) => void
        handleDuplicateIndividualPO: (poId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => void
        editExpiryDate: (validityDate: string, selectedPO: number, closePopup: () => void) => void
        declineAllRequestsExtension: (bundlePOId: number | undefined) => void
        declineRequestExtension: (poId: number | undefined) => void
        requestExtensionForExpiredQuote: (poId: number | undefined, quoteId: number | undefined) => void
        removeProduct: (productId: number, onEmptyState: Function) => void
        updateProductDetails: (product: ProductTableRow | undefined) => void
        changeOrderedQty: (product: ProductTableRow, quantity: number) => void
        changePrice: (product: ProductTableRow, price: string) => void
        changeAdditionalRequirements: (product: ProductTableRow, additional_requirements: string) => void
        changeWarrantyTerm: (product: ProductTableRow, warrantyId: string) => void
        addNewRemark: (product: ProductTableRow, remark: string) => void
    }
}

export const createBuyerBundlePurchaseOrderDetailsSlice: StateCreator<BuyerBundlePurchaseOrderDetailsSlice> = (set, get, api) => ({
    bundlePurchaseOrderBuyerDetailsSliceProperties: {
        bundleDetails: null,
        tabs: null,
        selectedPO: null,
        fetchBundlePoDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingDetails: false,
        productsTableRows: [],
        newComments: [],
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        openedPopupStatus: null,
        openedSuccessPopupsStatus: null,
        reasonsForPopup: [],
        approvalChanges: [],
        customLoadingButton: null
    },
    bundlePurchaseOrderBuyerDetailsSliceActions: {
        fetchDetails: (bundlePOId: number, poId?: number) => {
            // get().bundlePurchaseOrderBuyerDetailsSliceActions.setIsLoading(true)
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.fetchBundlePoDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useGetBuyerBundlePurchaseOrderDetails(bundlePOId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.setDetails(data.data, poId)
                // get().bundlePurchaseOrderBuyerDetailsSliceActions.setIsLoading(false)
                get().bundlePurchaseOrderBuyerDetailsSliceActions.setApprovalChanges([])
                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.fetchBundlePoDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
            }).catch(response => {
                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.fetchBundlePoDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model, false, true)
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setDetails(modelAfterUpdateProject, get().bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO?.model.id, true)
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.loadingDetails = isLoading })),
        setDetails: (purchaseOrderResponse: BundlePurchaseOrderAPIResponse, poId?: number, disableSetSelectedPO?: boolean) => {
            get().setTimeline(purchaseOrderResponse.timeline)

            const userId = useUserData.getState().userInfo?.id
            const bundle = { ...purchaseOrderResponse }
            const bundleDetails = buildBuyerBundlePurchaseOrderDetails(bundle, userId)
            const tabsArray = get().bundlePurchaseOrderBuyerDetailsSliceActions.getTabsDetails(bundleDetails.children)
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.tabs = tabsArray
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails = bundleDetails
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.newComments = purchaseOrderResponse.new_comments?.length == 0 ? null : purchaseOrderResponse.new_comments
            }))

            if (!disableSetSelectedPO) {
                if (poId && tabsArray.find(item => item.id == poId)) {
                    get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(poId);
                    return;
                }
                const selectedPoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO?.model.id;
                if (selectedPoId && tabsArray.find(item => item.id == selectedPoId)) {
                    get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(selectedPoId);
                    return;
                }
                get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(purchaseOrderResponse.purchaseOrders[0]?.id);
            }

        },
        setProductsTableRows: (purchaseOrder: ProcurementFormModel | null) => {
            const ProductsArray = buildPurchaseOrderProductsTable(purchaseOrder)
            set(produce(draftState => { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows = ProductsArray }))
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setSummaryDetails(ProductsArray)
        },
        closeNewCommentsPopup: () => set(produce(draftState => {
            const bundleModel = draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails.model;
            if (bundleModel) {
                bundleModel.new_comments = null;
            }
            draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.newComments = null
            draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails = { ...draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails, new_comments: [] }
        })),
        setSummaryDetails: (products: ProductTableRow[]) => {
            const summary = getVATTotalValues(products, 'requestedQuantity')
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        setSelectedPO: (poId: number) => {
            let selectedPO = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.children.find((element: ProcurementFormModel) => element.model?.id == poId)
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO = selectedPO
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.tabs = draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.tabs.map((element: TabModel) => {
                    return { ...element, checked: element.id === poId }
                })
            }))
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setProductsTableRows(selectedPO)
        },
        getTabsDetails: (purchaseOrders: ProcurementFormModel[]) => {
            let tabsArray: TabModel[] = []
            purchaseOrders?.map((po: ProcurementFormModel) => tabsArray.push({
                id: po.model.id,
                name: po.model?.seller_details.name,
                checked: false,
                count: po.numberOfProducts,
                status: po.model?.status,
                isExpired: po.model?.is_expired,
                isPrivate: po.model?.seller_details?.is_private
            }))
            return tabsArray;
        },
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.openedPopupStatus = status
        })),
        setSuccessPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.openedSuccessPopupsStatus = status
        })),
        sendForApproval: (optionalComment: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_BUNDLE_PO_FOR_APPROVAL)
            const bundlePoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.id
            useSendBundlePurchaseOrderForApproval(bundlePoId, optionalComment).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SEND_FOR_APPROVAL)
                NotifySuccess(t("popups.titles.sent_for_approval"))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        rejectAll: (reasonId: string, comment: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_ALL_POs)
            const bundlePoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.id
            useRejectBundlePurchaseOrder(bundlePoId, reasonId, comment).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.form_rejected', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.name }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        approveAll: (comment: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_ALL_POs)
            const bundlePoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.id
            useApproveBundlePurchaseOrder(bundlePoId, comment).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifySuccess(t('notifications.form_approved', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.name }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        setApprovalChanges: (approvalChanges: ChildApprovalChange[]) => set(produce(draftState => { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.approvalChanges = approvalChanges })),
        approvalProcess: (approvalStatus: procurementStatus, comment: string, reasonId?: string) => {
            approvalStatus == procurementStatus.APPROVED_BY_ME ? get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_INDIVIDUAL_RFQ) : get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_INDIVIDUAL_RFQ)
            const sliceProps = get().bundlePurchaseOrderBuyerDetailsSliceProperties
            const sliceActions = get().bundlePurchaseOrderBuyerDetailsSliceActions
            if (getNumberofBundlePendingApprovalChild(sliceProps.bundleDetails?.model, 'purchaseOrders')) {
                const changesAfterApproval = bulkApproval(sliceProps.bundleDetails, 'purchaseOrders', sliceProps.selectedPO?.model?.id ?? 0, approvalStatus, comment, sliceProps.approvalChanges, reasonId)
                sliceActions.rebuildDetails(changesAfterApproval.formDetailsModel, undefined, sliceProps.selectedPO?.model.id)
                sliceActions.setApprovalChanges(changesAfterApproval.changes)
                if (!getNumberofBundlePendingApprovalChild(changesAfterApproval.formDetailsModel, 'purchaseOrders'))
                    useBulkApproval(sliceProps.bundleDetails?.model?.id ?? 0, changesAfterApproval.changes).then(data => {
                        sliceActions.rebuildDetails(data.data, undefined, sliceProps.selectedPO?.model.id)
                        sliceActions.setApprovalChanges([])
                    })
            } else if (approvalStatus === procurementStatus.REJECTED_BY_ME) {
                useRejectPurchaseOrder(sliceProps.selectedPO?.model?.id ?? 0, reasonId, comment).then(data => {
                    get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, undefined, sliceProps.selectedPO?.model.id)
                    NotifySuccessWithCancelIcon(t("notifications.form_rejected", { formName: sliceProps.selectedPO?.model.name }))
                }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
            } else {
                useApprovePurchaseOrder(sliceProps.selectedPO?.model.id ?? 0, comment).then(data => {
                    sliceActions.rebuildDetails(data.data, undefined, sliceProps.selectedPO?.model.id)
                    NotifySuccess(t("notifications.form_approved", { formName: sliceProps.selectedPO?.model.name }))
                }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
            }

        },
        cancel: (comment: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_BUNDLE_PO_APPROVAL)
            const bundlePoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.id
            useCancelBundlePurchaseOrder(bundlePoId, comment).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.CANCEL_APPROVAL)
                NotifyMessage(t("popups.titles.approval_canceled"))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        rebuildDetails: (purchaseOrder: BundlePurchaseOrderAPIResponse | null, successPopupStatus?: ProcurementPopupStatus, poId?: number | undefined) => {
            const actions = get().bundlePurchaseOrderBuyerDetailsSliceActions
            actions.setPopupStatus(null)
            if (successPopupStatus) actions.setSuccessPopupStatus(successPopupStatus)
            actions.setDetails(purchaseOrder, poId)
        },
        getReasonsForPopup: () => {
            const status = get().bundlePurchaseOrderBuyerDetailsSliceProperties.openedPopupStatus
            if (status == ProcurementPopupStatus.REJECT || status == ProcurementPopupStatus.REJECT_ALL)
                useFetchRejectPurchaseOrderReasons().then(data => { set(produce(draftState => { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
            if (status == ProcurementPopupStatus.RECALL_Bundle || status == ProcurementPopupStatus.RECALL || status == ProcurementPopupStatus.CANCEL || status == ProcurementPopupStatus.CANCEL_BUNDLE)
                useGetRecalledReasons().then(data => { set(produce(draftState => { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        sendBundlePOToSeller: (bundlePOId: number | undefined, date: string, optionalComment: string | null) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_BUNDLE_PO_TO_SELLER)
            useSendBundlePoToSeller(bundlePOId, date, optionalComment ?? null).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SENT_TO_SELLERS)
                NotifySuccess(t('notifications.form_sent_to_seller', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.name }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        sendPOToSeller: (poId: number | undefined, date: string, optionalComment: string | null) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_INDIVIDUAL_PO_TO_SELLER)
            useSendPoToSeller(poId, date, optionalComment ?? null).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, ProcurementPopupStatus.SENT_TO_SELLER, poId)
                NotifySuccess(t("popups.titles.success_send_single_po_to_seller"))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        RecallBundlePO: (comment: string, reasonId?: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_BUNDLE_PO)
            const bundlePOId = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.bundleDetails?.model?.id
            useRecallBundlePO(bundlePOId, comment, reasonId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifyMessage(t('notifications.form_recalled', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model?.name }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        CancelBundlePO: (comment: string, reasonId?: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_BUNDLE_PO)
            const bundlePOId = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.bundleDetails?.model?.id
            useRecallBundlePO(bundlePOId, comment, reasonId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifyMessage(t('notifications.form_canceled', { formType: get().bundlePurchaseOrderBuyerDetailsSliceProperties?.bundleDetails?.model?.name }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        CancelIndividualPO: (poId: number | undefined, comment: string, reasonId?: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_INDIVIDUAL_PO)
            const po = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.selectedPO?.model
            const poName = po?.name
            useRecallSinglePO(poId, comment, reasonId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, undefined, poId)
                NotifyMessage(t('notifications.form_canceled', { formType: poName }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        recallPO: (poId: number | undefined, comment: string, reasonId?: string) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_INDIVIDUAL_PO)
            const po = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.selectedPO?.model
            const poName = po?.name
            useRecallSinglePO(poId, comment, reasonId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, undefined, poId)
                NotifyMessage(t('notifications.po_recalled', { poName: poName }))
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        deleteBundlePO: (bundlePoId: number | undefined, redirectToPOs: () => void) => {
            useDeleteBundlePO(bundlePoId).then(() => {
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties?.bundleDetails?.model?.name}));
                redirectToPOs()
                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails = null
                }))
            })
        },
        deleteIndividualPO: (poId: number | undefined, poName: string) => {
            useDeleteIndividualPO(poId).then(data => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: poName }));
            })
        },
        handleDuplicateIndividualPO: (poId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => {
            useDuplicateSinglePo(poId, project).then((data) => {
                NotifySuccess(t('notifications.form_duplicated', { formName: get().bundlePurchaseOrderBuyerDetailsSliceProperties?.selectedPO?.model?.name }));
                redirectToDuplicatedForm(data.data.id);
            });
        },
        editExpiryDate: (validityDate: string, selectedPO: number, closePopup: () => void) => {
            const poId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO?.model?.id
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(poId, LinkType.PO_OF_BUNDLE, validityDate).then((data) => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data, undefined, poId)
                closePopup()
                get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(selectedPO);
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineRequestExtension: (poId: number | undefined) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(poId, 'PurchaseOrder').then((data) => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                get().bundlePurchaseOrderBuyerDetailsSliceActions.closeNewCommentsPopup()
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
                get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(poId);
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineAllRequestsExtension: (bundlePOId: number | undefined) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(bundlePOId, 'BUNDLE_PO').then((data) => {
                get().bundlePurchaseOrderBuyerDetailsSliceActions.rebuildDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
                get().bundlePurchaseOrderBuyerDetailsSliceActions.closeNewCommentsPopup()
            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        },
        changeOrderedQty: (product: ProductTableRow, quantity: number) => {
            const selectedPoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO?.model?.id;
            const bundleDetails = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails;
            if(selectedPoId && bundleDetails) {
                const updatedChildren = bundleDetails.children.map(poChild => {
                    if(poChild.model.id !== selectedPoId) return poChild;
                    const updatedPoChild = produce<ProcurementFormModel>(poChild, draftState => {
                        draftState.model.products = poChild.model.products.map(poProduct => {
                            if(poProduct.id !== product.id) return poProduct;
                            return { ...poProduct, requested_quantity: quantity }
                        })
                    })
                    return updatedPoChild;
                })
                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails.children = updatedChildren
                }))
            }

            let productRows = get().bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                    draftProductRow.requestedQuantity = quantity
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
            }))
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
        },
        changePrice: (product: ProductTableRow, price: string) => {

        },
        changeWarrantyTerm: (product: ProductTableRow, warrantyId: string) => {

        },
        changeAdditionalRequirements: (product: ProductTableRow, additional_requirements: string) => {

        },
        addNewRemark: (product: ProductTableRow, remark: string) => {
            const selectedPoId = get().bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO?.model?.id;
            const bundleDetails = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails;
            if(selectedPoId && bundleDetails) {
                const updatedChildren = bundleDetails.children.map(poChild => {
                    if(poChild.model.id !== selectedPoId) return poChild;
                    const updatedPoChild = produce<ProcurementFormModel>(poChild, draftState => {
                        draftState.model.products = poChild.model.products.map(poProduct => {
                            if(poProduct.id !== product.id) return poProduct;
                            return { ...poProduct, remarks: remark }
                        })
                    })
                    return updatedPoChild;
                })
                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails.children = updatedChildren
                }))
            }

            let productRows = get().bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows = productRows.map((productRow: ProductTableRow) => {
                    if (productRow.id == product.id) return produce(productRow, draftProductRow => {
                        draftProductRow.remark = remark
                    })
                    else return productRow
                })
            }))
        },
        removeProduct: (productId: number, onEmptyState: Function) => {
            const poId = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.selectedPO?.model?.id
            useRemoveProduct(poId, productId).then(data => {
                const productRows = get().bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows
                const changedProductRows = productRows.filter((productRow: ProductTableRow) => productRow.productId != productId);

                const tabs = get().bundlePurchaseOrderBuyerDetailsSliceProperties.tabs;
                const newTabs = tabs?.map(tab => tab.id == poId ? { ...tab, count: changedProductRows.length } : tab)?.filter(tab => tab.count != 0)

                const children = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.children;
                const newChildren = children?.map(child => child.model.id == poId ? {
                    ...child,
                    numberOfProducts: changedProductRows.length,
                    model: { ...child.model, products: child.model.products.filter(prod => prod.product.id != productId) }
                } : child)?.filter(child => child.numberOfProducts != 0)

                set(produce(draftState => {
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows = changedProductRows
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.tabs = newTabs
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails.children = newChildren
                    draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.selectedPO.numberOfProducts = changedProductRows.length
                }))

                if (tabs?.length !== newTabs?.length && newTabs?.length != 0) {
                    get().bundlePurchaseOrderBuyerDetailsSliceActions.setSelectedPO(newTabs?.[0]?.id)
                }

                if (newTabs?.length == 0) {
                    onEmptyState?.()
                }

                get().bundlePurchaseOrderBuyerDetailsSliceActions.setSummaryDetails(changedProductRows)
            })
        },
        updateProductDetails: (product: ProductTableRow | undefined) => {
            let updatedProductRow: any = {}
            updatedProductRow = get().bundlePurchaseOrderBuyerDetailsSliceProperties.productsTableRows?.find((updatedProductRow: ProductTableRow) => product?.id == updatedProductRow.id)
            let priceInteger = updatedProductRow?.unitPrice ? parseInt(updatedProductRow?.unitPrice) : 0

            const poId = get().bundlePurchaseOrderBuyerDetailsSliceProperties?.selectedPO?.model?.id
            useUpdateDetailsProduct(poId, updatedProductRow?.productId, updatedProductRow?.requestedQuantity, priceInteger, updatedProductRow?.warrantyTerm?.id || "", updatedProductRow?.remark)
        },
        setCheckIfTermsValid: (value: boolean) => {
            set(produce(draftState => {
                draftState.bundlePurchaseOrderBuyerDetailsSliceProperties.checkIfTermsValid = value
            }))
        },
        requestExtensionForExpiredQuote: (poId: number | undefined, quoteId: number | undefined) => {
            get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(quoteId, 'Quote').then((data) => {
                set(produce(draftState => { draftState.purchaseOrderBuyerDetailsSliceProperties.customLoadingButton = null }))
                let model = get().bundlePurchaseOrderBuyerDetailsSliceProperties.bundleDetails?.model;
                const poIndex = model.purchaseOrders?.findIndex(po => po.id === poId);

                if (poIndex !== -1) {
                    const updatedModel = {
                        ...model,
                        purchaseOrders: [...model.purchaseOrders]
                    };

                    updatedModel.purchaseOrders[poIndex] = {
                        ...updatedModel.purchaseOrders[poIndex],
                        quote: {
                            ...updatedModel.purchaseOrders[poIndex].quote,
                            form_action_permissions: {
                                ...updatedModel.purchaseOrders[poIndex].quote?.form_action_permissions,
                                can_request_extension: false
                            }
                        }
                    };
                    get().bundlePurchaseOrderBuyerDetailsSliceActions.setDetails(updatedModel, poId)
                    NotifySuccess(t('notifications.extension_requested'))
                }

            }).catch(() => get().bundlePurchaseOrderBuyerDetailsSliceActions.setCustomLoadingButton(null))
        }
    }
})
