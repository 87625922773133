import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { LinkOrCreateSellerProps } from 'src/logic/models/catalogue/LinkOrCreateSellerProps';
import { useLinkOrCreateSellersSlice } from 'src/logic/zustand/catalouge/link-create-sellers/LinkOrCreateSellersSlice';
import { PhoneInputField, SelectWithPlaceholder, StyledTextField } from 'src/ui/components/shared';
import { CompanySellerField } from './company-field';
import { useCountriesSelectionsAsOptions } from './locations-utils';
import useLinkOrCreateSellerFormUtils from './use-form-utils';

type FieldsLinkOrCreateSellerProps = {
  product_id: number | null;
  withoutSellersSuggestion?: boolean;
  index: number;
};

export const FieldsLinkOrCreateSeller: React.FC<FieldsLinkOrCreateSellerProps> = ({
  product_id,
  withoutSellersSuggestion,
  index,
}) => {
  const { sellerCompaniesData, fetchSellersQueryStatus } = useLinkOrCreateSellersSlice(state => state.properties);
  const { onSellerCompanyChange, onSelectSellerCompany, onClear } = useLinkOrCreateSellerFormUtils(product_id);
  const formik = useFormikContext<LinkOrCreateSellerProps>();

  const selectedCountry = formik.values.new_sellers?.[index]?.country_id || '';
  const selectedSellerCompany = formik.values.new_sellers?.[index]?.seller_id || '';
  const dropdownOptions = useCountriesSelectionsAsOptions(selectedCountry);
  return (
    <Grid container md={12} alignItems='start' spacing={2} py={2}>
      <Grid item md={6}>
        {withoutSellersSuggestion ? (
          <StyledTextField
            showLabel
            disableTranslation
            translateKey='Company Name'
            name={`new_sellers.${index}.company_name`}
            required
            fullWidth
          />
        ) : (
          <CompanySellerField
            translateKey='Company Name'
            name={`new_sellers.${index}.company_name`}
            field_seller_id='seller_id'
            required
            items={sellerCompaniesData || []}
            onSearch={(value: string) => onSellerCompanyChange(value)}
            onChange={(seller_id: number) => onSelectSellerCompany(seller_id)}
            onClear={onClear}
            isLoading={fetchSellersQueryStatus.isLoading}
          />
        )}
      </Grid>

      <Grid item md={6}>
        <StyledTextField
          showLabel
          disableTranslation
          translateKey='Seller Full Name'
          name={`new_sellers.${index}.full_name`}
          required
          disabled={selectedSellerCompany != ''}
          fullWidth
        />
      </Grid>

      <Grid item md={6}>
        <SelectWithPlaceholder
          disableTranslation
          translateKey='country'
          placeholder='Select Country'
          name={`new_sellers.${index}.country_id`}
          items={dropdownOptions.countries || []}
          required
          disabled={selectedSellerCompany != ''}
        />
      </Grid>
      <Grid item md={6}>
        <SelectWithPlaceholder
          disableTranslation
          translateKey='city'
          placeholder={selectedCountry === '' ? 'must select country' : 'Select City'}
          name={`new_sellers.${index}.city_id`}
          items={dropdownOptions.cities || []}
          required
          disabled={selectedSellerCompany != '' || selectedCountry === ''}
        />
      </Grid>
      <Grid item md={6}>
        <StyledTextField
          showLabel
          disableTranslation
          translateKey='Email Address'
          name={`new_sellers.${index}.email`}
          required
          fullWidth
          disabled={selectedSellerCompany != ''}
        />
      </Grid>

      <Grid item md={6} sx={{ paddingTop: '9px !important' }}>
        <PhoneInputField
          name={`new_sellers.${index}.phone_number`}
          label='Phone Number'
          required
          disabled={selectedSellerCompany != ''}
        />
      </Grid>
    </Grid>
  );
};
