import { apiRoutes } from '@/config';
import { request } from '@/helpers';
import { queryWrapper } from '@/middlwares';
import { Alternative_Res, Filters_Res } from '@/models';
import { alternativesProducts } from '@/zustand';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useTranslation } from 'next-i18next';
import { NotifyError } from '../Notify-toast';


export const getAlternativesProducts = async (data) => {
  return request({
    url: apiRoutes.alternativesProduct,
    method: "GET",
    params: data
  })
}

export const useGetAlternativesProducts = (productId: number, params: any = {}) => {
  return request({
    url: apiRoutes.alternativesProduct,
    method: "GET",
    params: { product_id: productId, ...params }
  })
}

export const fetchAlternativeProductDetails = async (alternative_id) => {
  return request({
    url: apiRoutes.productDetails + alternative_id,
    method: "GET"
  })
}

export function useAlternativeProductDetails(alternative_id) {
  const setAlternativeProductDetails = alternativesProducts(state => state.setAlternativeProductDetails);

  const onSuccess = (data: Alternative_Res) => { setAlternativeProductDetails(data.data) }
  const onError = () => { NotifyError(t('notifications.server_error')) }
  return queryWrapper({
    requestName: 'alternative-product-details',
    request: () => fetchAlternativeProductDetails(alternative_id),
    onSuccess: (data) => onSuccess(data),
    onError: onError,
  })
}


export const buildAlternativeFilterRequestFormDataData = (productId: number, filters: Filters_Res[], pageNumber: number, sortId?: number) => {
  let formData = new FormData;
  let counter = 0;

  filters.map((el) => {
    let shouldContinue = false;
    el.filter_items.filter((el1) => el1.is_selected).map((el2) => {
      formData.append(`filters[${counter}][ids][]`, `${el2.id}`);
      shouldContinue = true;
    });
    if (shouldContinue) {
      formData.append(`filters[${counter}][type]`, el.type);
      counter++;
    }
  });

  formData.append('product_id', `${productId}`);
  formData.append('page_number', `${pageNumber}`);
  if (sortId) formData.append('sort_id', `${sortId}`);

  return formData;
}

export const useApplyAlternativesFilter = (formData: FormData) => {
  return request({
    url: apiRoutes.applyAlternativesProduct,
    method: "POST",
    data: formData,
  })
}

export const applyAlternativesFilter = async (data) => {
  return request({
    url: apiRoutes.applyAlternativesProduct,
    method: "POST",
    data: data,
  })
}

export const useApplyAlternativesFilters = (params) => {
  const { t } = useTranslation();
  const setAlternatives = alternativesProducts(state => state.setAlternatives);
  const selectedFiltersItems = alternativesProducts(state => state.selectedFiltersItems);
  const enabled = alternativesProducts(state => state.enabled);
  const setEnabled = alternativesProducts(state => state.setEnabled);
  const pageNumber = alternativesProducts(state => state.pageNumber);

  let formData = new FormData;
  let counter = 0;
  selectedFiltersItems.map((el) => {
    let shouldContinue = false;
    el.filter_items.filter((el1) => el1.is_selected).map((el2) => {
      formData.append(`filters[${counter}][ids][]`, el2.id);
      shouldContinue = true;
    });
    if (shouldContinue) {
      formData.append(`filters[${counter}][type]`, el.type);
      counter++;
    }
  });

  formData.append('product_id', params);
  formData.append('page', pageNumber);

  const onSuccess = (data) => {
    setAlternatives(data.data.content)
    setEnabled(false)
  }
  const onError = () => { NotifyError(t('notifications.server_error')), setEnabled(false) }
  return useQuery(['applyAlternativesFilter', selectedFiltersItems], () => applyAlternativesFilter(formData),
    { enabled: enabled, onSuccess: (data) => onSuccess(data), onError: onError })
}