import * as React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { StyledTextField } from '@/components';
import { validationSchema } from './validationSchema'
import { initialValues } from './initialValues'
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

interface VerificationCodeFormByPhoneProps {
    isDisabledButton: boolean,
    paragraphTranslate: string,
    userInfo?: string,
    handleClickNext: (values:any,actions: any) => void,
    handleClickBack: () => void,
    handleClickAgain:() => void
}

export const VerificationCodeFormByPhone : FC<VerificationCodeFormByPhoneProps> = (props: VerificationCodeFormByPhoneProps) => {
    const { t } = useTranslation();

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.handleClickNext}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}> 
                    <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                        {props.paragraphTranslate}
                        <Typography sx={{ fontWeight: '900', fontSize: '14px' }}>{props.userInfo}</Typography> 
                    </Typography>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField showLabel required translateKey='user.verificationCode' name='verification_code' />
                    </Grid>
                    <Button variant="text" sx={{ fontWeight: 400, fontSize: '14px' }} 
                        onClick={props.handleClickAgain}>
                        <u>{t('buttons.get_code_again')}</u>
                    </Button>
                    <Button
                        disabled={props.isDisabledButton}
                        variant='contained'
                        color='secondary'
                        type='submit'
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        {t('buttons.next')}
                    </Button>
                    <Button
                        variant='outlined'
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={props.handleClickBack}
                    >
                        {t('buttons.back')}
                    </Button>
                </form>
            )}
        </Formik>
    )
}