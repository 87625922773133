import { PhoneInputField } from '@/components';
import { useProfile } from '@/zustand';
import { Button, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

interface ChangePhoneFormProps {
  isDisabledButton: boolean;
  paragraphTranslate: string;
  handleClickNext: (values: any, actions: any) => void;
  handleClickBack: () => void;
}

export const ChangePhoneForm: FC<ChangePhoneFormProps> = (props: ChangePhoneFormProps) => {
  const { t } = useTranslation();
  const user_info = useProfile(state => state.user_info);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(user_info?.mobile_number.number)}
      onSubmit={props.handleClickNext}
    >
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <Typography variant='body2' sx={{ fontWeight: '400' }}>
            {props.paragraphTranslate}
          </Typography>
          <Grid item xs={12} mt={2}>
            <PhoneInputField name='mobile_number' label='Mobile Number' required />
          </Grid>
          <Button
            disabled={props.isDisabledButton}
            variant='contained'
            color='secondary'
            type='submit'
            fullWidth
            sx={{ mt: 3 }}
          >
            {t('buttons.next')}
          </Button>
          <Button variant='outlined' fullWidth sx={{ mt: 2 }} onClick={props.handleClickBack}>
            {t('buttons.back')}
          </Button>
        </form>
      )}
    </Formik>
  );
};
