import { StyledTextField } from '@/components';
import { getAccountTranslationKey } from '@/hooks';
import { NotifySuccess, useDeleteAccount } from '@/services';
import { Button, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { sm } from 'src/site-map';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

export const DeleteAccountForm = (props) => {
    const { t } = useTranslation();
    const onSuccess = () => {
        NotifySuccess('Account Deleted Successfully');
        window.location.replace(sm.home.url);
    }
    const deleteAccount = useDeleteAccount(onSuccess)
    const handleSubmit = (values, actions) => { deleteAccount.mutate(values) }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}  >
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid item xs={12} mt={2}>
                        <Typography variant='body2' fontWeight={400}>{t(getAccountTranslationKey('paragraphs.delete_account'))}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField required type="password" translateKey='user.password' name='password' showLabel/>
                    </Grid>
                    <Button
                        variant='contained'
                        color='error'
                        type='submit'
                        fullWidth
                        disabled={!(formikProps.isValid && formikProps.dirty) || deleteAccount.isLoading}
                        sx={{ mt: 3 }}
                    >
                        {t('buttons.delete')}
                    </Button>
                    <Button
                        variant='outlined'
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={() => { props.setdeleteAccountPopup(false) }}
                    >
                        {t('buttons.cancel')}
                    </Button>
                </form>
            )}
        </Formik>
    )
}