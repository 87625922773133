import { getBecomeSellerTranslationKey, useAnimateOnView } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { Box, Grid, useMediaQuery, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { ArrowForward } from '@mui/icons-material';
import Image from 'next/image';
import { Link } from '@/components';
import { useTranslation } from 'next-i18next';
import { sm } from 'src/site-map';

const smallBreakPoints = {
  lg: 6,
  xs: 12,
} as const;

type Props = {};

export const WorkersBecomeASellerSection: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();

  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <Grid container sx={{ position: 'relative' }}>
      {!smallScreens && (
        <Box
          sx={{
            position: 'absolute',
            width: ['100%', '100%', '100%', '70%'],
            height: 'calc(100% + 30px)',
            left: 0,
            bottom: 0,
            zIndex: -1,
            bgcolor: 'background.primary.main',
            borderRadius: t => t.spacing(0, 7, 0, 0),
            backgroundImage: 'url(/assets/images/shared/points.svg)',
          }}
        />
      )}
      <Grid
        item
        component={ResponsiveContainer}
        order={[1, 1, 1, 0]}
        {...smallBreakPoints}
        sx={{ display: 'flex', alignItems: 'center', py: 6 }}
      >
        <Box>
          <Typography
            component={motion.div}
            {...first}
            variant='h2'
            fontWeight='700'
          >
            {t(getBecomeSellerTranslationKey('titles.workers'))}
          </Typography>

          <Typography
            component={motion.div}
            {...second}
            color='text.third'
            variant='body2'
            fontWeight='400'
            mt={2}
            width={smallScreens ? '100%' : '80%'}
          >
            {t(getBecomeSellerTranslationKey('sub_titles.workers'))}
          </Typography>
          <Typography
            component={motion.div}
            {...second}
            color='text.third'
            variant='body2'
            fontWeight='400'
          >
            {t('texts.register_now')}
          </Typography>
          <Box
            component={motion.div}
            {...third}
            sx={{ mt: 3, display: 'flex' }}
          >
            <Button
              component={Link}
              href={{ pathname: sm.register.index.url }}
              endIcon={<ArrowForward />}
              variant='contained'
              sx={{
                width: smallScreens ? '100%' : '176px',
              }}
            >
              {t('buttons.registration')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item {...smallBreakPoints}>
        <Box
          component={motion.div}
          {...second}
          sx={{
            position: 'relative',
            height: smallScreens ? '400px' : '550px',
            overflow: 'hidden',
            borderRadius: t =>
              smallScreens ? t.spacing(0) : t.spacing(6, 0, 0, 0),
          }}
        >
          <Image
            src='/assets/images/become-a-seller/workers.png'
            layout='fill'
            objectFit='cover'
            alt='Hand shake'
          />
        </Box>
      </Grid>
    </Grid>
  );
};
