import { CircularProgress, Box, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'next-i18next';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { getAccountTranslationKey } from '@/hooks';

const fileStatuses = {
    UNDER_REVIEW: <PendingOutlinedIcon sx={{ color: 'warning.main' }} />,
    APPROVED: <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.dark' }} />,
    REJECTED: <HighlightOffOutlinedIcon sx={{ color: 'info.dark' }} />,
}
type FileStatusIconProps = { status: string, comment: string }
export const FileStatusIcon: React.FC<FileStatusIconProps> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Tooltip title={(props.comment ? props.comment + " " : ' ') + t(getAccountTranslationKey('comments.' + props.status))}>
                {fileStatuses[props.status]}
            </Tooltip>
        </>
    );
};
