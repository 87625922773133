import create from 'zustand';
import { Filters_Res, Product, FilterItem, Alternative_Res, SelectedFilters } from '@/models';

export const alternativesProducts = create((set, get) => ({
  alternatives: [] as Product[],
  filterData: [] as Filters_Res,
  product: {} as Product,
  waitAlternativesProduct: 'loading' as string,
  selectedFiltersItems: [] as SelectedFilters[],

  alternativeProductDetails: {} as Product,
  alternativesForAlternativeProductDetails: [],
  waitAlternativesProductDetails: 'loading' as string,

  enabled:false,
  pageNumber: "1" as string,

  setAlternativesProducts: (data: Alternative_Res) => {
    set({
      alternatives: data.data,
      filterData: data.filter_data,
      product: data.product,
      waitAlternativesProduct: 'success',
    });
  },

  setAlternatives: (data) => {
    set({ alternatives: data, waitAlternativesProduct: 'success', pageNumber:'1' });
  },

  setAlternativeProductDetails:(data) => {
    set({
      alternativeProductDetails: data.product,
      alternativesForAlternativeProductDetails: data.alternatives,
      waitAlternativesProductDetails: 'success',
    })
  }, 

  onPageNumberChange: (pageNumber: number) => {
    set({ waitAlternativesProduct: 'loading', pageNumber: pageNumber, enabled: true })
  },

  setEnabled: (data: boolean) => {
    set({ enabled: data });
  },

  onAlternativeItemChecked: async (filterItem: FilterItem, filterParent: string) => {
    set({ waitAlternativesProduct: 'loading' });
    let tree = [];
    tree = onFilterItemChecked(filterItem, filterParent, get().filterData);
    set({ filterData: tree });
    let newFilters = onFilterItemChange(tree);
    set({ selectedFiltersItems: newFilters , enabled:true })
  },

  onAlternativeItemUnchecked: async (filterItem: FilterItem, filterParent: string) => {
    set({ waitAlternativesProduct: 'loading' });
    let tree = [];
    tree = onFilterItemUnchecked(filterItem, filterParent, get().filterData);
    set({ filterData: tree });
    let newFilters = onFilterItemChange(tree);
    set({ selectedFiltersItems: newFilters , enabled:true })
  },

}));


export const onFilterItemChecked = (filterItem: FilterItem, filterParent: string, filtersData: Filters_Res) => {
  let newTree = toggleSelectionOfFilterItem(true, filterItem, filterParent, filtersData);
  return newTree;
};

export const onFilterItemUnchecked = (filterItem: FilterItem, filterParent: string, filtersData: Filters_Res) => {
  let newTree = toggleSelectionOfFilterItem(false, filterItem, filterParent, filtersData);
  return newTree;
};

export const toggleSelectionOfFilterItem = (
  isChecked: boolean,
  filterItem: FilterItem,
  filterParent: string,
  filtersData: Filters_Res
) => {
  let filterTree = filtersData.map((filterElement: Filter) => {
    if (filterElement.type == filterParent) {
      filterElement.filter_items = filterElement.filter_items.map((filterItemElement: FilterItem) => {
        if (filterItemElement.id == filterItem.id)
          return {
            ...filterItemElement,
            is_selected: isChecked,
          };
        return filterItemElement;
      }
      );
      return filterElement;
    }
    return filterElement;
  })
  // filterTree.map((el: Filter) => {
  //   el.filter_items.sort((currentItem: FilterItem, nextItem: FilterItem) => currentItem.name.localeCompare(nextItem.name))
  // })
  // filterTree.map((el: Filter) => {
  //   el.filter_items.sort((currentItem: FilterItem, nextItem: FilterItem) => Number(nextItem.is_selected) - Number(currentItem.is_selected));
  // })
  return filterTree;
};

export const onFilterItemChange = (filtersData: Filters_Res) => {
  return filtersData;
};
