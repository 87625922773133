import {
  Typography,
  Box,
  IconButton,
  Menu,
  // MenuItem,
  // ListItemIcon,
  Grid,
  // Divider,
} from '@mui/material';
import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTranslation } from 'next-i18next';
import { StyledBadge } from 'src/ui/components/shared';
import NotificationMenuContent from './NotificationMenuContent';
// import RefreshIcon from '@mui/icons-material/Refresh';

import {
  // useGetNotifications,
  useNotificationCount,
} from 'src/logic/services/notification';

// const ITEM_HEIGHT = 50;

export const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  // const { isLoading, refetch } = useGetNotifications();
  const notificationCount = useNotificationCount();
  return (
    <Box
      sx={{
        bgcolor: open ? 'white' : 'inherit',
        height: '90%',
        alignContent: 'center',
        borderRadius: t => t.spacing(0.5, 0.5, 0, 0),
      }}
    >
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          height: 40,
          width: 49,
          bgcolor: open ? 'info.contrastText' : 'inherit',
          borderRadius: '4px',
        }}
      >
        <StyledBadge content={notificationCount}>
          <NotificationsIcon
            sx={{ color: 'primary.main', height: 31, width: 31 }}
          />
        </StyledBadge>
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        PaperProps={{
          style: {
            transform: 'translateX(-5px)',
            width: '40ch',
            marginTop: 10,
            boxShadow: '0px 0px 4px 4px #ECECEC',
          },
        }}
      >
        <Grid container px={2} mb={1} justifyContent={'space-between'}>
          <Typography fontSize='20px !important' fontWeight='600'>
            {t('texts.notifications')}
          </Typography>
          {/* <IconButton disabled={isLoading} onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton> */}
        </Grid>
        <div style={{ paddingInline: '0' }}>
          <NotificationMenuContent handleClose={handleClose} />
        </div>
      </Menu>
    </Box>
  );
};
