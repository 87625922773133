import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { Button } from "@mui/material"
import { FC, useState } from "react"
import { ConfirmPopup, DescPopup, TranslationWithStyles } from "src/ui/components/shared"
import { BundleRFQ } from '@/models';
import { HighlightOff } from '@mui/icons-material';
import { DonePopupStatus, PopupStatus } from '@/config';
import { BundleRFQPopupContent } from 'src/ui/components/dumb';
import { ViewBundleOrRFQs } from '../../view-rfq-or-bundle-popup';

type CancelBundleProps = { bundleModel: BundleRFQ | null, openedPopupStatus: PopupStatus | null, openedDonePopupStatus: DonePopupStatus | null }

export const CancelBundle: FC<CancelBundleProps> = ({ bundleModel, openedPopupStatus, openedDonePopupStatus }) => {
    const { t } = useTranslation();
    const [confirmCancelBundleRFQPopup, setConfirmCancelBundleRFQPopup] = useState(false)

    const { setPopupStatus, cancelBundleRFQ, setDonePopupStatus } = useProcurementStore(state => state.bundleRFQDetailsSliceActions)
    const handleClosePopup = () => setPopupStatus(null)

    // Cancel BundleRFQ
    const handleCloseConfirmCancelBundleRFQPopup = () => setConfirmCancelBundleRFQPopup(false)
    const handleOpenConfirmCancelBundleRFQPopup = () => {
        setConfirmCancelBundleRFQPopup(true)
    }
    const handleOpenCancelBundleRfqPopup = () => {
        setConfirmCancelBundleRFQPopup(false)
        setPopupStatus(PopupStatus.CANCEL_BUNDLE)
    }
    const cancelTheBundleRFQ = (values: any) => {
        cancelBundleRFQ(bundleModel?.id, values.reasonId, values.hideReason, values.comment)
    }
    // Done cancel BundleRFQ popup cancel
    const handleCloseDoneCancelBundlePopup = () => setDonePopupStatus(null)

    return (
        <>
            <Button startIcon={<HighlightOff />} color="error" onClick={handleOpenConfirmCancelBundleRFQPopup}>{t('popups.titles.cancel_bundle_rfq')}</Button>
            {/*cancel bundle RFQ - confirm Popup*/}
            <ConfirmPopup
                titleKey={t('popups.titles.cancel_bundle_rfq')}
                subTitleKey={<TranslationWithStyles title={t('popups.subTitles.cancel_bundle_rfq')} />}
                buttonTitleKey={t("buttons.yes_confirm")}
                handleClose={handleCloseConfirmCancelBundleRFQPopup}
                handleConfirm={handleOpenCancelBundleRfqPopup}
                open={confirmCancelBundleRFQPopup}
                icon={<HighlightOff color='error' />}
            />

            {/*cancel bundle RFQ - Popup*/}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.CANCEL_BUNDLE}>
                <BundleRFQPopupContent
                    icon={<HighlightOff color='error' />}
                    popupTitle={t('popups.titles.cancel_bundle_rfq')}
                    buttonTitle={t('popups.titles.cancel_bundle_rfq')}
                    popupSubTitle={t('popups.subTitles.reason_cancel_bundle')}
                    handlePopupAction={cancelTheBundleRFQ}
                    status='error'
                    titleColor='error'
                    checkLabel={t('texts.hide_reason_from_seller')}
                />
            </DescPopup>

            {/*cancel bundle RFQ - Done Popup*/}
            <ViewBundleOrRFQs
                icon={<HighlightOff color='error' />}
                title={t('popups.titles.bundle_rfq_canceled')}
                openPopup={openedDonePopupStatus == DonePopupStatus.CANCEL_BUNDLE}
                handleClosePopup={handleCloseDoneCancelBundlePopup}
                status={'error'}
            />
        </>
    )
} 