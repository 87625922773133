import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { TextTooltip } from '../text-tooltip';

interface props {
    value: string | number | undefined | null
    title?: string
    redirectLink?: string
    color?: string
}
export const TextViewWithValue: React.FC<props> = props => {
    const textRef = useRef();
    const { push } = useRouter()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {props.title && <Typography variant="subtitle2" fontWeight={400} mr={1} color="text.third">{props.title}:</Typography>}
            <TextTooltip title={props.value} textRef={textRef}>
                <Typography
                    ref={textRef}
                    variant="subtitle2"
                    fontWeight={400}
                    mr={2}
                    {...(props.redirectLink && { onClick: () => push(props.redirectLink) })}
                    sx={{ '&:hover': { textDecoration: props.redirectLink ? 'underline' : 'none' }, color: props.color ? props.color : 'primary', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', cursor: props.redirectLink ? 'pointer' : 'default' }}>
                    {props.value}
                </Typography>
            </TextTooltip>
        </Box>
    );
};
