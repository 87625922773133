import { Button, Grid, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { DescPopup } from "@/components";
import { useRouter } from "next/router";


type RedirectPopupProps = {
    icon: ReactNode
    title: string
    openPopup: boolean
    handleClosePopup: () => void
    redirectURL: string
    buttonTitle: string
    redirectButtonTitle: string
    subTitle?: string
    colorTitle?: string
}

export const RedirectPopup: React.FC<RedirectPopupProps> = ({ icon, title, subTitle, openPopup, handleClosePopup, redirectURL, buttonTitle, redirectButtonTitle, colorTitle }) => {
    const { push } = useRouter()

    const handleRedirectFromPopup = () => {
        push({ pathname: redirectURL })
        handleClosePopup()
    }

    return (
        <DescPopup handleClose={handleClosePopup} open={openPopup}>
            <Grid container md={12} gap={2} p={2}>
                <Grid item md={12} textAlign='center'>
                    {icon}
                    <Grid item md={12} textAlign='center'>
                        <Typography variant="overline" fontWeight="700" color={colorTitle ? colorTitle : 'primary'}>{title}</Typography>
                    </Grid>
                    {subTitle && <Grid item md={12} textAlign='center'>
                        <Typography variant="body2" color='text.disabled'>{subTitle}</Typography>
                    </Grid>}
                </Grid>
                <Grid item md={5}>
                    <Button variant="outlined" sx={{ color: 'text.disabled', fontWeight: 500 }} fullWidth onClick={handleClosePopup}>{buttonTitle}</Button>
                </Grid>
                <Grid item md={6}>
                    <Button variant="contained" fullWidth onClick={handleRedirectFromPopup}>{redirectButtonTitle}</Button>
                </Grid>
            </Grid>
        </DescPopup>
    )
}