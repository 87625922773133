import {
  AlternativeProductRequisitionButton,
  FilterChips,
  FixedStyledBreadcrumbs,
  Loading,
  MainProductCardButtons,
  ProductCard,
  StyledPagination,
} from '@/components';
import { ProductInCartType } from '@/config';
import { Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { sm } from 'src/site-map';
import { RequisitionPopup } from 'src/ui/components/dumb/requisition/requisition-popup';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import BackToCatalogueButton from '../../catalogue/shared/back-to-catalogue-button';

export const AlternativesMainPage = () => {
  const {
    productAlternativesLoading,
    productAlternativesMainProduct,
    productAlternativesAlternatives,
    onPageNumberChangeProductAlternativesSlice,
    productAlternativesPaginationResult,
    productAlternativesFilters,
    productAlternativesFilterResultLoading,
    canEditRequisition,
    canPerformActiononAlternatives,
    canPerformActiononAlternativesPO,
  } = useProcurementStore();
  const {
    onFilterItemCheckProductAlternativesSlice,
    onFilterItemUnCheckProductAlternativesSlice,
    onSortItemSelectionInAlternativePage,
    alternativesSortData,
  } = useProcurementStore();
  const { push } = useRouter();
  const handleAlternativeProductCard = (product: Product) =>
    productAlternativesMainProduct?.inCart ? null : push(sm.catalogue.products.alternatives(product?.id).url);

  const breadcrumbsItems = React.useMemo(() => {
    const product = productAlternativesMainProduct;
    if (!product) return [];
    const { group, cat: category, class: productClass, type } = product;

    return [
      { title: 'Products', url: sm.catalogue.products.index.url },
      { title: group?.name, url: sm.catalogue.products.groupId(`${group?.id}`).url },
      { title: category?.name, url: sm.catalogue.products.categoryId(`${group?.id}`, `${category?.id}`).url },
      {
        title: productClass?.name,
        url: sm.catalogue.products.classId(`${group?.id}`, `${category?.id}`, `${productClass?.id}`).url,
      },
      { title: type?.name, url: sm.catalogue.products.typeId(`${type?.id}`).url },
      { title: product?.name, url: sm.catalogue.products.productId(`${product?.id}`).url },
      { title: 'Alternatives', url: sm.catalogue.products.alternatives(`${product?.id}`).url },
    ];
  }, [productAlternativesMainProduct]);

  return (
    <>
      {productAlternativesLoading ? (
        <Loading fullHeight={true} />
      ) : (
        <Grid container sx={{ paddingBottom: 10, bgcolor: 'background.primary.main' }}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1.5}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FixedStyledBreadcrumbs maxItems={4} showBackButton items={breadcrumbsItems} />
            </div>
            <BackToCatalogueButton buttonSx={{ mt: 1 }} />
          </Grid>
          <ProductCard
            key={productAlternativesMainProduct?.id}
            isPageAlternative={true}
            canPerformActionRequisition={canPerformActiononAlternatives}
            canPerformActionPO={canPerformActiononAlternativesPO}
            productDetails={productAlternativesMainProduct}
            ControlButtons={MainProductCardButtons}
            canDispatchActions={canEditRequisition}
          />

          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Typography color='#00385E' fontWeight={700} fontSize={24}>
              Alternatives <PrimaryBadge>{productAlternativesPaginationResult?.total}</PrimaryBadge>
            </Typography>

            <SortSelect
              onSortItemSelected={onSortItemSelectionInAlternativePage}
              sortData={alternativesSortData}
              paginationResult={productAlternativesPaginationResult}
              isMobile={false}
            />
          </Grid>

          <Typography color='text.third' fontWeight='400' variant='body2' mt={1}>
            {productAlternativesFilters.map(el => (
              <FilterChips
                filterType={el.type}
                isPageAlternative={true}
                items={el.filter_items}
                onFilterChecked={onFilterItemCheckProductAlternativesSlice}
                onFilterUnchecked={onFilterItemUnCheckProductAlternativesSlice}
              />
            ))}
          </Typography>
          {!productAlternativesFilterResultLoading ? (
            <>
              <Grid item md={12}>
                {productAlternativesAlternatives?.map(el => (
                  <ProductCard
                    key={el.id}
                    canPerformAction={canPerformActiononAlternatives}
                    isPageAlternative={true}
                    productDetails={el}
                    ControlButtons={
                      el.inCartType === ProductInCartType.ALTERNATIVE
                        ? AlternativeProductRequisitionButton
                        : MainProductCardButtons
                    }
                    canDispatchActions={canEditRequisition}
                    mainProduct={productAlternativesMainProduct}
                    onClickAlternativeProduct={() => handleAlternativeProductCard(el)}
                  />
                ))}
              </Grid>
              {productAlternativesPaginationResult?.last_page > 1 && (
                <Box
                  sx={{
                    mt: 5,
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <StyledPagination
                    count={productAlternativesPaginationResult?.last_page}
                    page={productAlternativesPaginationResult?.current_page}
                    onChange={(event, value) => {
                      onPageNumberChangeProductAlternativesSlice(value);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  />
                </Box>
              )}
            </>
          ) : (
            <Loading fullHeight={true} />
          )}

          <RequisitionPopup />
        </Grid>
      )}
    </>
  );
};

const SortSelect: React.VFC = ({ onSortItemSelected, sortData }) => {
  if (!sortData || sortData.length === 0) {
    return null;
  }
  const { t } = useTranslation();
  const selectRef = useRef(null);

  const defaultSortValue = sortData?.find(el => el.is_selected)?.id;

  const handleIconClick = () => {
    if (selectRef.current) {
      const event = new Event('mousedown', { bubbles: true });
      selectRef.current.dispatchEvent(event);
    }
  };

  return (
    <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id='demo-simple-select-standard-label'>
        <Typography variant='body2' color='info.light' fontWeight='400'>
          {' '}
          {t('texts.sort_by')}
        </Typography>
      </InputLabel>
      <Select
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        defaultValue={defaultSortValue}
        onChange={event => {
          onSortItemSelected(event.target.value);
        }}
        sx={{
          '& .MuiSelect-select': {
            backgroundColor: 'transparent !important',
            fontSize: '14px !important',
            fontWeight: 500,
            color: '#3A3E50 !important',
          },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        disableUnderline
        IconComponent={props => (
          <ExpandMoreIcon
            {...props}
            onClick={() => {
              handleIconClick();
              onSortItemSelected(defaultSortValue);
            }}
            sx={{
              fontSize: '20px !important',
              color: '#3A3E50 !important',
              cursor: 'pointer',
            }}
          />
        )}
        ref={selectRef}
      >
        {sortData.map((sortItem: any) => (
          <MenuItem key={sortItem.id} value={sortItem.id}>
            {sortItem.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
