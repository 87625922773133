import { Button, Grid, Typography } from '@mui/material';
import { Link, FileStatusIcon, ConfirmPopup } from '@/components';
import { useDeleteDocument, useUploadDocument } from '@/services';
import { useTranslation } from 'next-i18next';
import { FormEvent, useRef, useState } from 'react';
import { getAccountTranslationKey } from '@/hooks';

type Props = {};

export const UploadedDocumentsSection: React.FC<Props> = ({
    companyData
}) => {
    const { t } = useTranslation();
    const [uploadType, setuploadType] = useState('');
    const [open, setOpen] = useState(null);
    const uploadFile = useUploadDocument();
    const deleteFile = useDeleteDocument();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const onUploadClick = (e, uploadType: string) => { inputFileRef.current.click(); setuploadType(uploadType) };

    const onFileChange = (e: FormEvent<HTMLInputElement>) => {
        var formData = new FormData()
        formData.append('file', e.currentTarget.files[0])
        formData.append('document_type', uploadType)
        uploadFile.mutate(formData);
    }
    // const handleDeleteFile = (uploadType: string) => {

    // }

    const handleClickOpen = id => setOpen(id);
    const handleClose = () => setOpen(null);
    const handleDelete = document => {
        deleteFile.mutate({ document: document });
        setOpen(null);
    };
    return (
        <>
            <Grid item container xs={6}>
                <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>{t(getAccountTranslationKey('sections_titles.uploaded_documents'))}</Typography>
            </Grid>
            <Grid item xs={12} >
                <hr />
            </Grid>
            {companyData.company_documents.map(document => {
                return (
                    <Grid key={document.upload_type} container mt={2}>
                        <Grid item container xs={6}>
                            {document.file.name ?
                                <Grid container>
                                    <Link href={document.file.url} underline='hover' mr={3}>
                                        <Typography variant='h6' fontWeight={500} color='text.primary'>
                                            {document.file.name}
                                        </Typography>
                                    </Link>
                                    <FileStatusIcon status={document.status.name} comment={document.status.comment} />
                                </Grid>
                                : <Typography variant='h6'>{t(getAccountTranslationKey('files.' + document.document_key))}</Typography>
                            }
                        </Grid>
                        <Grid item container xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {document.file.name  &&
                                <>
                                    <Button variant='outlined' sx={{ mr: 1 }} onClick={() => handleClickOpen(document.upload_type)}>{t('buttons.delete')} </Button>
                                    <ConfirmPopup
                                        buttonTitleKey={t('buttons.confirm_delete')}
                                        titleKey={t('texts.confirm_delete_file', { DocumentName: document.file.name })}
                                        handleClose={handleClose}
                                        handleConfirm={() => handleDelete(document)}
                                        open={open === document.upload_type}
                                    />
                                </>}
                            <Button variant='contained' onClick={(e) => onUploadClick(e, document.upload_type)}>
                                <input name="upload" onChangeCapture={onFileChange} ref={inputFileRef} type="file" style={{ "display": "none" }} />
                                {document.file ? t('buttons.upload_new') : t('buttons.upload')}
                            </Button>
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
};
