import { CancelAddingItemsToForm, MoreOptions, ProductFormManagerPopper, StyledBadge, StyledLoadingButton } from "@/components";
import { ProcurementButtons } from "@/config";
import { ResponsiveContainer } from "@/styles";
import { useProcurementStore, useUserData } from "@/zustand";
import { LogoutRounded, ShoppingCart } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useRouter } from "next/router";
import { FC } from "react";
import { sm } from "src/site-map";


interface props {
    redirectToProducts: () => void
    handleSaveAction: () => void
    openSummaryDialog: () => void
    canViewCatalog?: boolean
}
export const PurchaseOrderHeader: FC<props> = ({ canViewCatalog, redirectToProducts, handleSaveAction, openSummaryDialog }) => {
    const { push } = useRouter()
    const { data } = useProcurementStore(state => state.productFormManagerProperties)
    const { hasAccessToPortal } = useUserData()
    const goToDetails = () => {
        if (hasAccessToPortal)
            push(sm.portals.buyer.procurement.purchaseOrders.purchaseOrderId(data.formDetails?.id).url)
        else
            push(sm.home.url)
    }
    return (
        <>
            <Grid container component={ResponsiveContainer}>
                <Grid container sx={{ height: '60px', borderRadius: '8px', backgroundColor: 'secondary.contrastText', px: 2, alignItems: "center" }}>
                    <Grid item container md={6} alignItems="center" >
                        <StyledBadge content={data.products.length} >
                            <ShoppingCart color="primary" />
                        </StyledBadge>
                        <Typography variant="h6" fontWeight='400' ml={1}>{t('texts.adding_items_from') + " "}</Typography>
                        <Typography textAlign="start" variant="h6" fontWeight='700' color="text.primary" ml={1} sx={{ width: 'auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {data.formDetails?.seller_details.name}
                        </Typography>
                        <Typography variant="h6" fontWeight='400' ml={1}>{t('texts.to')}</Typography>
                        <ProductFormManagerPopper />
                    </Grid>
                    <Grid item container gap={1} md={6} justifyContent="flex-end" alignItems="center">
                        {canViewCatalog && <Button variant="contained" onClick={redirectToProducts} >{t('buttons.view_catalogue')}</Button>}
                        <StyledLoadingButton customWidth variant="contained" onClick={handleSaveAction} translateKey="save" disabled={!data.FormHasChangesToSave} loading={data.customLoadingButton == ProcurementButtons.SAVE_PO_UPDATES} color={'success'} />
                        <MoreOptions>
                            <Button onClick={goToDetails} startIcon={<ShoppingCart />}>{t('buttons.go_to_po')}</Button>
                            <Button onClick={openSummaryDialog} startIcon={<LogoutRounded />}>{t('buttons.show_summary')}</Button>
                        </MoreOptions>
                        <CancelAddingItemsToForm popupTitleKey="cancel_adding_to_po" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}