import React from 'react';
import { DescPopup } from 'src/ui/components/shared';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Button, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { sm } from 'src/site-map';

type IProps = {
  open: boolean;
  handleClose: () => void;
  signupUrl?: string;
};

const SignupRequiredPopup = ({ open, handleClose, signupUrl = sm.register.asSeller.index.url }: IProps) => {
  return (
    <DescPopup open={open} handleClose={handleClose}>
      <Grid container alignItems='center' justifyContent='center' p={2}>
        <Grid item md={12} textAlign='center'>
          <AccountCircleOutlinedIcon sx={{ color: 'primary.main', fontSize: 40 }} />
          <Grid item md={12} textAlign='center'>
            <Typography variant='overline' color='primary.main' fontWeight={700} my={1}>
              Sign Up Required
            </Typography>
            <Typography variant='subtitle1' color='#3A3E50' fontWeight={400} my={1}>
              This functionality is only for registered users.
            </Typography>
          </Grid>
          <Link href={signupUrl}>
            <Button type='button' fullWidth color='primary' variant='contained' sx={{ mt: 2 }}>
              Sign Up
            </Button>
          </Link>
          <Typography textAlign={'center'} color='#8B8D98' fontSize={14} fontWeight={400} mt={1}>
            Already have an account?{' '}
            <Link href={sm.login.url}>
              <span style={{ fontWeight: 700, color: '#13628C', textDecoration: 'none', cursor: 'pointer' }}>
                Log in instead
              </span>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </DescPopup>
  );
};

export default SignupRequiredPopup;
