import { Construction } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';

type IProps = {
  handleClick?: Function;
  text?: string;
};

export const AddProductsToRequisitionButton = ({
  handleClick,
  text,
}: IProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const redirectToProductPage = () => {
    push(sm.catalogue.index.url);
    handleClick && handleClick();
  };
  return (
    <Button
      variant='contained'
      startIcon={<Construction />}
      onClick={redirectToProductPage}
    >
      {text ?? t('buttons.add_products')}
    </Button>
  );
};
