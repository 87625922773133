import React from 'react';
import { Box, ListItemButton, ListItemIcon, Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StyledBadge } from '../notification-badge';
import { TextTooltip } from '../text-tooltip';
interface ExpandableListItemWithIconProps {
    title?: string,
    isItemContainsSubItem: boolean,
    isItemExpanded: boolean,
    handleClickListItem: () => void,
    notificationNumber?: number,
    icon?: React.ReactNode,
    selected?: boolean,
    disabled?: boolean
    isMainButton?: boolean
}

export const ExpandableListItemWithIcon: React.FC<ExpandableListItemWithIconProps> = (props) => {
    const textRef = React.useRef();
    return (
        <ListItemButton
            sx={{ height: '100%', width: '100%', borderRadius: '4px' }}
            onClick={props.handleClickListItem}
            selected={props.selected}
            disabled={props.disabled}
        >
            {props.icon && <>{props.notificationNumber && !props.isItemExpanded ?
                <StyledBadge content={props.notificationNumber} > <StyledListItemIcon isItemExpanded={props.isItemExpanded} icon={props.icon} /></StyledBadge>
                : <StyledListItemIcon isItemExpanded={props.isItemExpanded} icon={props.icon} />
            }</>}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: props.icon ? '80%' : '100%' }}>
                {props.title &&
                    <Box sx={{ width: props.isItemContainsSubItem ? '78%' : '95%' }}>
                        <TextTooltip title={props.title} textRef={textRef}>
                            <Typography
                                ref={textRef}
                                variant={props.isMainButton ? "h6" : "body2"}
                                color="primary.main"
                                sx={{
                                    opacity: props.isItemExpanded ? 1 : 0,
                                    display: '-webkit-box',
                                    whiteSpace: 'pre-line',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflowY: 'hidden',
                                    fontWeight: props.isMainButton || props.selected ? 700 : 400,
                                    textOverflow: 'ellipsis',
                                }}> {props.title}
                            </Typography>
                        </TextTooltip>
                    </Box>}
                {props.notificationNumber != 0 && props.isItemExpanded && <StyledBadge content={props.notificationNumber} />}
                {props.isItemContainsSubItem && props.isItemExpanded && <ArrowForwardIosIcon color="primary" sx={{ fontSize: 14, mr: -1 }} />}
            </Box>
        </ListItemButton>
    )
}

interface StyledListItemIconProps { isItemExpanded: boolean, icon?: React.ReactNode }

const StyledListItemIcon: React.FC<StyledListItemIconProps> = ({ isItemExpanded, icon }) => {
    return (
        <ListItemIcon sx={{ minWidth: 0, mr: isItemExpanded ? 1 : 'auto', justifyContent: 'center', }}            >
            {icon}
        </ListItemIcon>
    )
}