import { ProductManagerEnv, apiURLs } from '@/config';
import { useDebounce } from '@/hooks';
import { Product } from '@/models';
import { useFilters, useProcurementStore } from '@/zustand';
import { ChevronRight, Close } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, CardMedia, Grid, InputAdornment, Paper, TextField, Typography
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { sm } from 'src/site-map';
import { Loading } from '../../shared';
import useEnvironmentFilters from 'src/logic/zustand/Filters/hooks/use-environment-filters';
import { request } from '@/helpers';

const MAX_SEARCH_LENGTH = 200;

type Props = { mobile: boolean; backFun: Function };
export const SearchBar: React.VFC<Props> = ({ mobile, backFun }) => {
  const { t } = useTranslation();

  const [display, setDisplay] = useState(false);
  const [wait, setwait] = useState('loading');
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const router = useRouter();
  const [search, setSearch] = useState('');
  const wrapperRef = useRef(null);
  const setSearchText = useFilters(state => state.setSearchText);

  const handleClickOutside = (event: KeyboardEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const envFilters = useEnvironmentFilters()

  async function handleChange(search: string) {
    if(!search) return;

    // setSearch(search);
    !display && setDisplay(true);
    setwait(t('texts.loading'));
    var url = new URL(apiURLs.autocomplete);
    var params = { search_text: search, ...envFilters };
    url.search = new URLSearchParams(params).toString();
    
    // console.log(url)
    const data = await request({ url: apiURLs.autocomplete, params , method: "GET" })
    if (!data) {
      console.log('Not found');
    } else {
      setProducts(data.data.products);
      setCategories(data.data.categories);
    }
    setwait(t('texts.success'));
  }

  useDebounce(() => handleChange(search), 700, [search]);

  const redirectToProductDetailsPage = (product) => {
    // return router.push(sm.products.productId(product.category.parent.parent.id, product.category.parent.id, product.category.id, product.id).url)
    return router.push(sm.catalogue.products.productId(product.id).url)
  }
  const redirectToFilterPage = (type) => {
    // return router.push(sm.products.typeId(type.parent.parent.id, type.parent.id, type.id).url)
    return router.push(sm.catalogue.products.typeId(type.id).url)
  }

  return (
    <Box ref={wrapperRef}>
      <Grid item container display="flex" alignItems={"center"}>
        <TextField
          placeholder={t('texts.search')}
          onChange={e => e.target.value?.length <= MAX_SEARCH_LENGTH && setSearch(e.target.value)}
          value={search}
          onClick={() => search && setDisplay(true)}
          // onKeyPress={ev => {
          //   if (ev.key === 'Enter') {
          //     setSearch('');
          //     setDisplay(false);
          //     router.asPath
          //       .split('/')[1]
          //       ?.slice(0, router.asPath.split('/')[1].indexOf('?')) ==
          //       'requisitions'
          //       ? router.push({
          //         pathname: sm.searchResults.index.url,
          //         query: {
          //           title: search,
          //           product_id: null,
          //           type_id: null,
          //           updatedTitle: null,
          //         },
          //       })
          //       : router.push({
          //         pathname: sm.searchResults.index.url,
          //         query: {
          //           ...router.query,
          //           title: search,
          //           product_id: null,
          //           type_id: null,
          //           updatedTitle: null,
          //         },
          //       });
          //     setSearchText(search);
          //   }
          // }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position='start'
                sx={{ marginLeft: -1, marginRight: 0.5 }}
              >
                <SearchIcon className='addIcon' sx={{ color: "text.third", height: 20, width: 20 }} />
              </InputAdornment>
            ),
            sx: {
              bgcolor: 'secondary.contrastText',
              height: '40px',
              opacity: 0.6,
              border: '1px solid #E8E8EA',
              '& ::placeholder': {
                fontSize: '0.875rem',
              },
              width: mobile ? '100%' : 272,
              transition: "width 400ms",
              '&.Mui-focused': {
                border: '1.5px solid #13628C',
                width: mobile ? '100%' : 560, // default
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .addIcon': {
                  color: 'primary.main',
                },
              },
            },
          }}
          sx={{
            px: 1,
            flex: 1,
            textOverflow: 'ellipsis !important',
          }}
          fullWidth
        />
        {mobile && <Close sx={{ mr: 2 }} onClick={() => backFun()} />}
      </Grid>
      <Grid
        item
        pt={1}
        style={{ position: 'absolute', zIndex: 2, transform: 'translateX(8px)' }}
      >
        {display && search && (
          <Paper
            className='styled-scrollbar'
            sx={{
              backgroundColor: mobile ? 'background.primary.main' : 'white',
              width: mobile ? '100%' : '560px',
              marginTop: 0,
              maxHeight: '50vh',
              minHeight: '60px',
              overflow: 'auto',

            }}
          >
            {wait === 'loading' ? (
              <Box my={1}>
                <Loading />
              </Box>
            ) : (
              <>
                {products.length > 0 || categories.length > 0 ? (
                  <>
                    <Typography
                      color='text.third'
                      ml={2}
                      mt={1}
                      variant='subtitle2'
                      fontWeight='400'
                    >
                      Popular Goods by your search
                    </Typography>
                    {products.length > 0 ? (
                      products.map((el, i) => {
                        return (
                          <ProductCard
                            key={i}
                            name={el.name}
                            brandName={el.brand_name}
                            image={el.image_url}
                            href={sm.searchResults.index.url}
                            changeState={() => {
                              setSearch('');
                              setDisplay(false);
                              setSearchText('');
                              redirectToProductDetailsPage(el);
                            }}
                            mobile={mobile}
                            backFun={backFun}
                            productQuery={{
                              title: JSON.stringify(el.name),
                              product_id: el.part_number,
                              type_id: null,
                              updatedTitle: null,
                            }}
                          />
                        );
                      })
                    ) : (
                      <Typography
                        m={2}
                        color='text.third'
                        variant="subtitle2"
                        fontWeight={500}
                      >
                        No result matches your search
                      </Typography>
                    )}
                    <Typography
                      color='text.third'
                      ml={2}
                      mt={2}
                      variant="subtitle2"
                      fontWeight='400'
                    >
                      Types
                    </Typography>
                    {categories.length > 0 ? (
                      categories.map((el, i) => {
                        return (
                          <CategoryCard
                            key={i}
                            products={products}
                            name={el.name}
                            href={sm.searchResults.index.url}
                            search={search}
                            changeState={() => {
                              setSearch('');
                              setDisplay(false);
                              setSearchText('');
                              redirectToFilterPage(el)
                            }}
                            backFun={backFun}
                            mobile={mobile}
                            categoryQuery={{
                              title: JSON.stringify(el.name),
                              type_id: el.id,
                              product_id: null,
                              updatedTitle: null,
                            }}
                          />
                        );
                      })
                    ) : (
                      <Typography
                        m={2}
                        color='text.third'
                        variant="subtitle2"
                        fontWeight='400'
                      >
                        No result matches your search
                      </Typography>
                    )}
                    {/* {(products.length > 0 || categories.length > 0) && (
                      <Box
                        sx={{
                          borderTopColor: 'action.disabledBackground',
                          borderTopStyle: 'solid',
                          borderTopWidth: '0.1px',
                        }}
                      >
                        <CategoryCard
                          products={products}
                          name={'See all results'}
                          href={sm.searchResults.index.url}
                          search={search}
                          changeState={() => {
                            setSearch('');
                            setDisplay(false);
                            setSearchText(search);
                          }}
                          mobile={mobile}
                          backFun={backFun}
                          categoryQuery={{
                            title: JSON.stringify(search),
                            product_id: null,
                            type_id: null,
                            updatedTitle: null,
                          }}
                        />
                      </Box>
                    )} */}
                  </>
                ) : (
                  <Typography
                    pt={2}
                    ml={2}
                    pb={2}
                    color='text.third'
                    variant="subtitle1"
                    fontWeight='400'
                  >
                    No result matches your search
                  </Typography>
                )}
              </>
            )}
          </Paper>
        )}
      </Grid>
    </Box>
  );
};

type Query = {
  title: string;
  product_id?: string;
  type_id?: number;
};
type ProductProps = {
  name: string;
  brandName: string;
  image?: string;
  href: string;
  changeState: Function;
  mobile: boolean;
  backFun: Function;
  productQuery: Query;
};
type CategoryProps = {
  name: string;
  href: string;
  search: string;
  changeState: Function;
  products: Product[];
  mobile: boolean;
  backFun: Function;
  categoryQuery: Query;
};
const ProductCard: React.VFC<ProductProps> = ({
  name,
  brandName,
  image,
  href,
  mobile,
  backFun,
  productQuery,
  changeState,
}) => {
  const { asPath, query } = useRouter();
  return (
    // <Link
    //   href={
    //     asPath.split('/')[1]?.slice(0, asPath.split('/')[1].indexOf('?')) ==
    //       'requisitions'
    //       ? {
    //         pathname: href,
    //         query: {
    //           ...productQuery,
    //         },
    //       }
    //       : query.tabId
    //         ? {
    //           pathname: href,
    //           query: {
    //             ...query,
    //             ...productQuery,
    //             tabId: new Date().getTime(),
    //           },
    //         }
    //         : {
    //           pathname: href,
    //           query: {
    //             ...query,
    //             ...productQuery,
    //           },
    //         }
    //   }
    //   passHref={true}
    // >
    <Box
      onClick={() => {
        changeState(name);
        mobile && backFun();
      }}
      height='60px'
      sx={{
        '&:hover': {
          backgroundColor: 'primary.light',
          '& .addIcon': {
            color: 'primary.main',
          },
        },
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        p: [1, 1],
      }}
    >
      <Box
        sx={{
          borderRadius: 1,
          overflow: 'hidden',
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'center',
        }}
      >
        <CardMedia component="img"
          sx={{ objectFit: 'contain', height: '50px', width: '50px' }}
          image={image} alt={name} />
      </Box>
      <Box width='90%'>
        <Typography
          ml={1}
          color='text.third'
          variant="subtitle2"
          sx={{ fontWeight: '500' }}
        >
          {brandName}
        </Typography>
        <Typography
          ml={1}
          color='primary'
          variant='body2'
          sx={{
            width: '230px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: '400',
          }}
        >
          {name}
        </Typography>
      </Box>
      <ChevronRight
        className='addIcon'
        sx={{
          color: 'info.light',
          display: 'inline-block',
          alignSelf: 'center',
          float: 'right',
          fontSize: '30px !important',
        }}
      />
    </Box>
    // </Link>
  );
};

const CategoryCard: React.VFC<CategoryProps> = ({
  name,
  href,
  search,
  changeState,
  products,
  mobile,
  backFun,
  categoryQuery,
}) => {
  const { asPath, query } = useRouter();
  return (
    // <Link
    //   href={
    //     asPath.split('/')[1]?.slice(0, asPath.split('/')[1].indexOf('?')) ==
    //       'requisitions'
    //       ? {
    //         pathname: href,
    //         query: {
    //           ...categoryQuery,
    //         },
    //       }
    //       : query.tabId
    //         ? {
    //           pathname: href,
    //           query: {
    //             ...query,
    //             ...categoryQuery,
    //             tabId: new Date().getTime(),
    //           },
    //         }
    //         : {
    //           pathname: href,
    //           query: {
    //             ...query,
    //             ...categoryQuery,
    //           },
    //         }
    //   }
    //   passHref={true}
    // >
    <Box
      onClick={() => {
        changeState(name);
        mobile && backFun();
      }}
      height='50px'
      sx={{
        '&:hover': {
          backgroundColor: 'primary.light',
          '& .addIcon': {
            color: 'primary.main',
          },
        },
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        p: [1, 1],
      }}
    >
      <Box width='93%'>
        <Typography
          ml={1}
          color='primary'
          variant="body2"
          sx={{
            width: '230px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: '400',
          }}
        >
          {name}
        </Typography>
      </Box>
      <ChevronRight
        className='addIcon'
        sx={{
          color: 'info.light',
          display: 'inline-block',
          alignSelf: 'center',
          float: 'right',
          fontSize: '30px !important',
        }}
      />
    </Box>
    // </Link>
  );
};
