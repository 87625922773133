import BadgeIcon from '@mui/icons-material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Checkbox, Grid, MenuItem, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRef, useState } from 'react';
import useSearchFilterCategoryItems from 'src/logic/hooks/smart/catalogue/use-search-filter-category-items';
import { SellersIndex } from 'src/logic/models/catalogue/CatalogueIndex';
import { Seller } from 'src/logic/models/company-details/seller';
import QueryStatusComponent from 'src/logic/services/query-handlers/QueryStatusComponent';
import { getAvatarDisplayName } from 'src/logic/utils/avatar';
import { useSellersIndexSlice } from 'src/logic/zustand/sellers';
import { sm } from 'src/site-map';
import { StyledBreadcrumbs } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import BackToCatalogueButton from 'src/ui/components/smart/catalogue/shared/back-to-catalogue-button';
import IndexPageHeader from 'src/ui/components/smart/catalogue/shared/index-page-header';
import { sellersAvatarStyles } from '../../../company-details/shared/styles';
import PrimaryBadge from '../../../shared/primary-badge';

type IProps = {
    sellers: SellersIndex;
};

export const SellersIndexPageContent = ({ sellers }: IProps) => {
    const { t } = useTranslation();
    const [checkedValue, setCheckedValue] = useState(false);
    const [sortValue, setSortValue] = useState('');

    const { fetchQueryFilterStatus } = useSellersIndexSlice(state => state.sellersProperties);
    const { onFilterSellers } = useSellersIndexSlice(state => state.sellersActions);
    const { setSearchValue, filteredItems } = useSearchFilterCategoryItems<Seller>(sellers?.items.map(item => ({ ...item, ...item.seller })));

    const handleShowOnlyMySellers = (e) => {
        const isChecked = e.target.checked;
        setCheckedValue(isChecked);
        onFilterSellers(sortValue, isChecked);
    };

    const handleSortItemSelected = (id: string) => {
        setSortValue(id);
        onFilterSellers(id, checkedValue);
    };

    return (
        <Grid container md={12} gap={1}>
            <Grid item container md={12} justifyContent="space-between" alignItems={'center'}>
                <Grid item>
                    <StyledBreadcrumbs alias={sm.catalogue.sellers.index.url} showBackButton />
                </Grid>
                <Grid item pt={1}>
                    <BackToCatalogueButton />
                </Grid>
            </Grid>

            <IndexPageHeader title={t('pages_names.sellers')} Icon={<BadgeIcon />} badgeNumber={sellers.count} search={true} onChange={setSearchValue} />
            <br />
            <Grid item container md={12} justifyContent="space-between" alignItems={'center'}>
                <Grid item>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontSize={19} fontWeight={700} color={'#3A3E50'} mr={1}> {t('pages_names.all_sellers')} <PrimaryBadge>{sellers.count}</PrimaryBadge></Typography>
                        <Checkbox onChange={(e) => handleShowOnlyMySellers(e)} checked={checkedValue} size='small' />   <Typography fontSize={13} fontWeight={700} color={'primary.main'}> Show only my Sellers </Typography>
                    </div>
                </Grid>
                <Grid item>
                    <SortSelect onSortItemSelected={(id: string) => handleSortItemSelected(id)} sortData={sellers.sort} />
                </Grid>
            </Grid>

            <Grid container md={12} spacing={2}>
                <QueryStatusComponent queryHandler={fetchQueryFilterStatus}>
                    {filteredItems?.map((seller, index) => (<Grid item md={4}>
                        <Link href={sm.catalogue.sellers.sellerId(`${seller.id}`).url}>
                        <Box key={index} sx={{ cursor: 'pointer',width: '100%', backgroundColor: '#fff', borderRadius: '40px', boxShadow: '0px 8px 32px 0px #EBEBEB', "&:hover": { '& .avatar': { color: '#fff', backgroundColor: '#13628C' } } }} className='main-border-on-hover'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: 5 }}>
                                <Avatar sx={sellersAvatarStyles} className='avatar'>{getAvatarDisplayName(seller.name)}</Avatar>
                                <Typography color='#00385E' fontWeight={500} lineHeight={1.2}>
                                    {seller.name} {seller.is_private && <PrivacyButton disableText />}
                                </Typography>
                            </div>
                        </Box>
                        </Link>
                    </Grid>))}
                </QueryStatusComponent>
            </Grid>

        </Grid>
    );
};


const SortSelect = ({ onSortItemSelected, sortData }) => {
    if (!sortData || sortData.length === 0) {
        return null;
    }
    const { t } = useTranslation();
    const selectRef = useRef(null);

    // const defaultSortValue = sortData?.find((el) => el.is_selected)?.id;

    const handleIconClick = () => {
        if (selectRef.current) {
            const event = new Event('mousedown', { bubbles: true });
            selectRef.current.dispatchEvent(event);
        }
    };

    const defaultValue = sortData.some((sortItem) => sortItem.selected) ? undefined : sortData[0].id;

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
                <Typography variant="body2" color="info.light" fontWeight="400">
                    {' '}
                    {t('texts.sort_by')}
                </Typography>
            </InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={defaultValue}
                onChange={(event) => {
                    onSortItemSelected(event.target.value);
                }}
                sx={{
                    '& .MuiSelect-select': {
                        backgroundColor: 'transparent !important',
                        fontSize: '14px !important',
                        fontWeight: 500,
                        color: '#3A3E50 !important',
                    },
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                disableUnderline
                IconComponent={(props) => (
                    <ExpandMoreIcon
                        {...props}
                        onClick={() => {
                            handleIconClick();
                            // onSortItemSelected(sortData?.[0]?.id);
                        }}
                        sx={{ fontSize: '20px !important', color: '#3A3E50 !important', cursor: 'pointer' }}
                    />
                )}
                ref={selectRef}
            >
                {sortData.map((sortItem: any) => (
                    <MenuItem key={sortItem.id} value={sortItem.id} >
                        {sortItem.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
