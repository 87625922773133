import { StyledTextField } from '../text-field/styled-text-field/index';
import React from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { useDebounce } from '@/hooks';

type DebouncedStyledInputProps = Omit<TextFieldProps, 'onChange'> & {
  translateKey: string;
  name:string
  index: number;
  onChange: (price: number) => void;
};

export const DebouncedStyledInput = (props: DebouncedStyledInputProps) => {
  const [value, setValue] = React.useState<string>('');
  useDebounce(() => props.onChange(value), 500, [value]);
  
  return (
    <StyledTextField
      name={`${props.name}[${props.index}].unitPrice`}
      translateKey={props.translateKey}
      onChange={e => {
        setValue(e.target.value)
      }}
      outlined
    />
  );
};
