import BadgeIcon from '@mui/icons-material/Badge';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { sm } from 'src/site-map';

import { httpConfig, UserType } from '@/config';
import { LogoPicSVG } from '@/icons';
import { useCategories } from '@/services';
import { useDrawerStore, useProcurementStore } from '@/zustand';

const productsSubMenuContent = {
    getData: () => { return useCategories().refetch },
    //Url is used to navigate the user to one dynamic url
    url: (categoryId: string, classId: string, typeId: string) => {
        return sm.products.typeId(categoryId, classId, typeId).url
    },
    canSearch: true
}
const procurementSubMenuContentBuyer = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([
                { ...sm.portals.buyer.procurement.quotes.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'external' },
                { ...sm.portals.buyer.procurement.requisitions.index, notificationNumber: getNumberOfDraftRequisitions, routeIndex: 4, section: 'internal' },
                { ...sm.portals.buyer.procurement.requestForQuotes.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'internal' },
                { ...sm.portals.buyer.procurement.comparisonTables.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'internal' },
                { ...sm.portals.buyer.procurement.purchaseOrders.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'internal' },
            ])
        }
    },
    canSearch: false
}

const procurementSubMenuContentSeller = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([
                { ...sm.portals.seller.procurement.requestForQuotes.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'external' },
                { ...sm.portals.seller.procurement.purchaseOrders.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'external' },
                { ...sm.portals.seller.procurement.quotes.index, notificationNumber: getNumberOfNotification, routeIndex: 4, section: 'internal' },
            ])
        }
    },
    canSearch: false
}

const procurementSubMenuContentSellerBuyers = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([
                { ...sm.portals.seller.buyers.index, notificationNumber: getNumberOfNotification, routeIndex: 4  },
                { ...sm.portals.seller.buyers.allBuyers.index, notificationNumber: getNumberOfNotification, routeIndex: 4  },
            ])
        }
    },
    canSearch: false
}

const procurementSubMenuContentSellerProducts = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([
                { ...sm.portals.seller.products.index, notificationNumber: getNumberOfNotification, routeIndex: 3  },
                { ...sm.catalogue.index, notificationNumber: getNumberOfNotification, routeIndex: 3  },
            ])
        }
    },
    canSearch: false
}

const procurementSubMenuContentBuyerSellers = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([
                { ...sm.portals.buyer.sellers.index, notificationNumber: getNumberOfNotification, routeIndex: 4  },
                { ...sm.portals.buyer.sellers.allSellers.index, notificationNumber: getNumberOfNotification, routeIndex: 4  },
            ])
        }
    },
    canSearch: false
}

const procurementSubMenuContentGuest = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([{ ...sm.requisitions.index, notificationNumber: getNumberOfDraftRequisitions, routeIndex: 1 },
            ])
        }
    },
    canSearch: false
}

const getNumberOfDraftRequisitions = () => {
    const { requisitionBadgeNumber } = useProcurementStore.getState();
    return requisitionBadgeNumber
}
const getNumberOfProcurements = () => {
    const { procurementBadgeNumber } = useProcurementStore.getState();
    return procurementBadgeNumber
}


const getNumberOfNotification = () => 0

export const Drawer_PAGES_CONFIG = {
    [UserType.UNDEFIENED]: [
        { ...sm.portals.buyer.procurement.index, pageIcon: <DescriptionIcon color="primary" />, routeIndex: 1, notificationNumber: getNumberOfProcurements, subMenuContent: procurementSubMenuContentGuest },
        {
            // ...sm.products.index, pageIcon: <ConstructionIcon color="primary" />, routeIndex: 1, notificationNumber: getNumberOfNotification, subMenuContent: productsSubMenuContent, subMenuPathQuery: { 'category-id': null, 'class-id': null, 'type-id': null }
            ...sm.catalogue.index, pageIcon: <ConstructionIcon color="primary" />, routeIndex: 1, notificationNumber: getNumberOfNotification
        },
    ],
    [UserType.BUYER]: [
        { ...sm.portals.buyer.dashboard, pageIcon: <DashboardIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification },
        { ...sm.portals.buyer.procurement.index, pageIcon: <DescriptionIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfProcurements, subMenuContent: procurementSubMenuContentBuyer, onlyApprovedUser: true },
        {
            // ...sm.products.index, pageIcon: <ConstructionIcon color="primary" />, routeIndex: 1, notificationNumber: getNumberOfNotification, subMenuContent: productsSubMenuContent, subMenuPathQuery: { 'category-id': null, 'class-id': null, 'type-id': null }
            ...sm.catalogue.index, pageIcon: <ConstructionIcon color="primary" />, routeIndex: 1, notificationNumber: getNumberOfNotification, onlyApprovedUser: true
        },
        { ...sm.portals.buyer.projects.index, pageIcon: <MapsHomeWorkIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification, onlyApprovedUser: true },
        { ...sm.portals.buyer.sellers.index_Title, pageIcon: <BadgeIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification, subMenuContent: procurementSubMenuContentBuyerSellers, onlyApprovedUser: true },
    ],
    [UserType.SELLER]: [
        { ...sm.portals.seller.dashboard, pageIcon: <DashboardIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification },
        { ...sm.portals.seller.procurement.index, pageIcon: <DescriptionIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfProcurements, subMenuContent: procurementSubMenuContentSeller, onlyApprovedUser: true },
        { ...sm.catalogue.index, pageIcon: <ConstructionIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification, subMenuContent: procurementSubMenuContentSellerProducts, onlyApprovedUser: true },
        { ...sm.portals.seller.projects.index, pageIcon: <MapsHomeWorkIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification, onlyApprovedUser: true },
        { ...sm.portals.seller.buyers.index_Title, pageIcon: <BadgeIcon color="primary" />, routeIndex: 3, notificationNumber: getNumberOfNotification, subMenuContent: procurementSubMenuContentSellerBuyers, onlyApprovedUser: true },
    ],
}

const FooterSubMenuContentGuest = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([{ ...sm.careers.index, notificationNumber: getNumberOfNotification, routeIndex: 1 },
            { ...sm.contactUs, notificationNumber: getNumberOfNotification, routeIndex: 1 },
            { title: 'privacy_policy', link: httpConfig.domain + '/privacy_policy' },
            { title: 'terms_and_conditions', routeIndex: 1, link: httpConfig.domain + '/terms_conditions' }
            ])
        }
    },
    canSearch: false
}
const FooterSubMenuContentLoggedInUser = {
    getData: () => {
        const setDrawerSubMenuContent = useDrawerStore.getState().setDrawerSubMenuContent
        return () => {
            setDrawerSubMenuContent([{ ...sm.contactUs, notificationNumber: getNumberOfNotification, routeIndex: 1 },
            { title: 'privacy_policy', link: httpConfig.domain + '/privacy_policy' },
            { title: 'terms_and_conditions', link: httpConfig.domain + '/terms_conditions' }
            ])
        }
    },
    canSearch: false
}
export const Drawer_Footer_CONFIG = {
    [UserType.UNDEFIENED]: {
        ...sm.home,
        title: 'about_us',
        pageIcon: <LogoPicSVG />,
        routeIndex: 1,
        notificationNumber: getNumberOfNotification,
        subMenuContent: FooterSubMenuContentGuest
    },
    [UserType.BUYER]: {
        ...sm.home,
        title: 'about_us',
        pageIcon: <LogoPicSVG />,
        routeIndex: 1,
        notificationNumber: getNumberOfNotification,
        subMenuContent: FooterSubMenuContentLoggedInUser
    },
    [UserType.SELLER]: {
        ...sm.home,
        title: 'about_us',
        pageIcon: <LogoPicSVG />,
        routeIndex: 1,
        notificationNumber: getNumberOfNotification,
        subMenuContent: FooterSubMenuContentLoggedInUser
    },
}