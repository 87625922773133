import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { ProcurementButtons, ProcurementPopupStatus, procurementStatus } from "@/config"
import { MarkChatReadOutlined } from "@mui/icons-material"
import { DescPopup, ProcurementActionsPopupFormContent, SendToOtherCompany } from "src/ui/components/shared"
import { GeneralObject } from "@/models";
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface BundlePurchaseOrderPopupsProps {
    openedPopupStatus: ProcurementPopupStatus | null
    openedSuccessPopupsStatus: ProcurementPopupStatus
    handleClosePopup: () => void
    reasonsForPopup?: GeneralObject[]
    defaultValidityDate?: string | undefined;
    bundlePOId?: number | undefined
    poId?: number | undefined
}
export const BundlePurchaseOrderPopups: FC<BundlePurchaseOrderPopupsProps> = ({ openedPopupStatus, handleClosePopup, openedSuccessPopupsStatus, reasonsForPopup, defaultValidityDate, bundlePOId, poId }) => {
    const { t } = useTranslation();
    const { sendForApproval, getReasonsForPopup, approvalProcess, rejectAll, approveAll, cancel, sendBundlePOToSeller, sendPOToSeller, RecallBundlePO, recallPO, CancelBundlePO, CancelIndividualPO } = useProcurementStore(state => state.bundlePurchaseOrderBuyerDetailsSliceActions)
    const { customLoadingButton, bundleDetails, selectedPO } = useProcurementStore(state => state.bundlePurchaseOrderBuyerDetailsSliceProperties)

    const handleGetReasonsForPopup = () => getReasonsForPopup()
    const handleSendForApprovalAction = (values: any) => sendForApproval(values.comment)
    const handleRejectPopup = (values: any) => approvalProcess(procurementStatus.REJECTED_BY_ME, values.comment, values.reasonId)
    const handleApprovePopup = (values: any) => approvalProcess(procurementStatus.APPROVED_BY_ME, values.comment)
    const handleRejectAllPopup = (values: any) => rejectAll(values.reasonId, values.comment)
    const handleApproveAllPopup = (values: any) => approveAll(values.comment)
    const handleCancelApproval = (values: any) => cancel(values.comment)
    const handleSendBundlePOToSeller = (values: any) => sendBundlePOToSeller(bundlePOId, values.validity_date, values.comment)
    const handleSendPOToSeller = (values: any) => sendPOToSeller(poId, values.validity_date, values.comment)
    const handleRecallBundlePOPopup = (values: any) => RecallBundlePO(values.comment, values.reasonId)
    const handleRecallPO = (values: any) => recallPO(poId, values.comment, values.reasonId)
    const handleCancelBundlePOPopup = (values: any) => CancelBundlePO(values.comment, values.reasonId)
    const handleCancelIndividualPOPopup = (values: any) => CancelIndividualPO(poId, values.comment, values.reasonId)

    const [hasApprovedPO, setHasApprovedPO] = useState(false);
    const [isAllPOsApproved, setIsAllPOsApproved] = useState(false);

    useEffect(() => {
        if (bundleDetails) {
            const allPOs = bundleDetails?.model?.purchaseOrders;
            if (allPOs && allPOs.length > 0) {
                const allApproved = allPOs.every(
                    po => po.status === procurementStatus.APPROVED || po.status === procurementStatus.RECALLED
                );
                const someApproved = allPOs.some(
                    po => po.status === procurementStatus.APPROVED
                );

                if (allApproved) {
                    setIsAllPOsApproved(true)
                } else if (!allApproved && someApproved) {
                    setHasApprovedPO(true);
                } else {
                    setHasApprovedPO(false);
                }
            }
        }
    }, [bundleDetails]);

    const isAllPOsDraft = useMemo(() => {
        return bundleDetails?.model?.purchaseOrders?.reduce((acc, po) => acc && po?.status === procurementStatus.DRAFT, true)
    }, [bundleDetails])

    return (
        <>
            {/* Send for approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleSendForApprovalAction}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined />}
                    buttonTitle={t('popups.titles.send_for_approval')}
                    status="primary"
                    popupTitle={t('popups.titles.send_for_approval')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_BUNDLE_PO_FOR_APPROVAL}
                />
            </DescPopup>
            {/* <BundlePurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.SEND_FOR_APPROVAL}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.sent_for_approval')}
                subTitle={t('popups.subTitles.purchase_order_sent_for_approval')}
                colorTitle='success.main'
            /> */}

            {/* Approve popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.APPROVE} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleApprovePopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='success' />}
                    buttonTitle={t('popups.titles.approve_purchase_order')}
                    status="success"
                    popupTitle={t('popups.titles.approve_purchase_order')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_INDIVIDUAL_PO}
                />
            </DescPopup>

            {/* Reject popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.REJECT} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRejectPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='error' />}
                    buttonTitle={t('buttons.reject_purchase_order')}
                    status="error"
                    popupTitle={t('popups.titles.reject_purchase_order')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_INDIVIDUAL_PO}
                />
            </DescPopup>

            {/* Approve all popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.APPROVE_ALL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleApproveAllPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='success' />}
                    buttonTitle={t('popups.titles.approve_Bundle_P.O.')}
                    popupTitle={t('popups.titles.approve_Bundle_P.O.')}
                    status="success"
                    popupSubTitle={t('popups.subTitles.approve_Bundle_P.O.')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_ALL_POs}
                />
            </DescPopup>

            {/* Reject all popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.REJECT_ALL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRejectAllPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='error' />}
                    buttonTitle={t('popups.titles.reject_Bundle_P.O.')}
                    status="error"
                    popupTitle={t('popups.titles.reject_Bundle_P.O.')}
                    popupSubTitle={t('popups.subTitles.reject_Bundle_P.O.')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_ALL_POs}
                />
            </DescPopup>

            {/* cancel approval popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL_APPROVAL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelApproval}
                    openedPopupStatus={openedPopupStatus}
                    icon={<MarkChatReadOutlined color='error' />}
                    buttonTitle={t('buttons.cancel_approval_request')}
                    status="error"
                    popupTitle={t('buttons.cancel_approval_request')}
                    popupSubTitle={t('popups.subTitles.cancel_approval')}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_BUNDLE_PO_APPROVAL}
                />
            </DescPopup>
            {/* <BundlePurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.CANCEL_APPROVAL}
                icon={<MarkChatReadOutlined color='error' />}
                title={t('popups.titles.approval_canceled')}
                colorTitle='error.main'
            /> */}

            {/* Send To All sellers popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SENT_TO_SELLERS} handleClose={handleClosePopup}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary' />}
                    popupTitle={hasApprovedPO ? t("buttons.send_approved_pos_to_sellers") : (isAllPOsDraft && bundleDetails?.model?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_POs_to_sellers') : isAllPOsApproved ? t("buttons.send_all_pos_to_sellers") : t('buttons.send_POs_to_sellers')}
                    validTitle={t('texts.purchase_order') + " " + t('texts.valid_until')}
                    note={t('texts.note_po_expiry_date')}
                    buttonTitle={hasApprovedPO ? t("buttons.send_approved_pos_to_sellers") : (isAllPOsDraft && bundleDetails?.model?.form_action_permissions?.can_send_to_counter_party) ? t('buttons.send_POs_to_sellers') : isAllPOsApproved ? t("buttons.send_all_pos_to_sellers") : t('buttons.send_POs_to_sellers')}
                    handlePopupAction={handleSendBundlePOToSeller}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_BUNDLE_PO_TO_SELLER}
                />
            </DescPopup>
            {/* <BundlePurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.SENT_TO_SELLERS}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.success_send_bundle_po_to_seller')}
                colorTitle='success.main'
            /> */}

            {/* Send To seller popup */}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.SENT_TO_SELLER} handleClose={handleClosePopup}>
                <SendToOtherCompany
                    icon={<MarkChatReadOutlinedIcon color='primary' />}
                    popupTitle={t('popups.titles.send_single_po_to_seller')}
                    validTitle={t('texts.purchase_order') + " " + t('texts.valid_until')}
                    buttonTitle={t('popups.titles.send_single_po_to_seller')}
                    handlePopupAction={handleSendPOToSeller}
                    isButtonLoading={customLoadingButton == ProcurementButtons.SEND_INDIVIDUAL_PO_TO_SELLER}
                />
            </DescPopup>
            {/* <BundlePurchaseOrderRedirectPopup
                openPopup={openedSuccessPopupsStatus == ProcurementPopupStatus.SENT_TO_SELLER}
                icon={<MarkChatReadOutlined sx={{ color: 'success.main' }} />}
                title={t('popups.titles.success_send_single_po_to_seller')}
                colorTitle='success.main'
            /> */}

            {/*Recall bundle - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.RECALL_Bundle} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRecallBundlePOPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.recall_bundle_po')}
                    status="error"
                    popupTitle={t('popups.titles.recall_bundle_po')}
                    popupSubTitle={t('popups.subTitles.recall_bundle_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_BUNDLE_PO}
                />
            </DescPopup>

            {/*ReCall po - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.RECALL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleRecallPO}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.recall_po')}
                    status="error"
                    popupTitle={t('buttons.recall_po')}
                    popupSubTitle={t('popups.subTitles.recall_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.RECALL_INDIVIDUAL_PO}
                />
            </DescPopup>

            {/*Cancel bundle po - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL_BUNDLE} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelBundlePOPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.cancel_form', { formName: bundleDetails?.model?.name })}
                    status="error"
                    popupTitle={t('buttons.cancel_form', { formName: bundleDetails?.model?.name })}
                    popupSubTitle={t('popups.subTitles.cancel_bundle_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_BUNDLE_PO}
                />
            </DescPopup>

            {/*Cancel individual po - Popup*/}
            <DescPopup open={openedPopupStatus == ProcurementPopupStatus.CANCEL} handleClose={handleClosePopup}>
                <ProcurementActionsPopupFormContent
                    handlePopupAction={handleCancelIndividualPOPopup}
                    openedPopupStatus={openedPopupStatus}
                    icon={<HighlightOffIcon color='error' />}
                    buttonTitle={t('buttons.cancel_form', { formName: selectedPO?.model?.name })}
                    status="error"
                    popupTitle={t('buttons.cancel_form', { formName: selectedPO?.model?.name })}
                    popupSubTitle={t('popups.subTitles.cancel_single_po')}
                    getReasonsForPopup={handleGetReasonsForPopup}
                    reasonsForPopup={reasonsForPopup}
                    isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_INDIVIDUAL_PO}
                />
            </DescPopup>

        </>
    )
}