import { request } from '@/helpers';
import { ProductTableRow } from '@/models';

export const getExternalRequestForQuoteDetails = (rfqId: string) => {
  return request({ url: `/external-request-for-quote/show/${rfqId}`, method: 'GET' });
};

export const useDeclineExternalRequestForQuote = (rfqId: number, reasonId: string, comment?: string) => {
  return request({
    url: `/external-request-for-quote/decline/${rfqId}`,
    method: 'POST',
    data: { reason_id: reasonId, comment: comment ?? null },
  });
};

export const generateExternalQuote = (rfqId: string, rfqProducts: ProductTableRow[]) => {
  let products = [] as {
    request_for_quote_product_id: number;
    quantity: number;
    price: string;
    remarks: string;
  }[];
  products = rfqProducts.map(rfqProduct => {
    return {
      request_for_quote_product_id: rfqProduct.id,
      quantity: rfqProduct.availableQuantity,
      price: rfqProduct.unitPrice,
      remarks: rfqProduct.remark ?? '',
    };
  });
  return request({
    url: `/external-quote/create`,
    method: 'POST',
    data: { request_for_quote_id: rfqId, products: products },
  });
};

export const useUpdateExternalQuoteFromRfq = (quoteId: number, rfqProducts: ProductTableRow[]) => {
  let products = [] as {
    request_for_quote_product_id: number;
    quantity: number;
    price: string;
    remarks: string;
  }[];
  products = rfqProducts.map(rfqProduct => {
    return {
      request_for_quote_product_id: rfqProduct.id,
      quantity: rfqProduct.availableQuantity,
      price: rfqProduct.unitPrice,
      remarks: rfqProduct.remark ?? '',
    };
  });
  return request({ url: `/external-quote/${quoteId}/update`, method: 'PUT', data: { products } });
};
