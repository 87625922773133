import { InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { FC, useState } from "react";
import { StyledPopper } from "src/ui/components/shared";

export const CustomWarrantyPopper: FC<{}> = ({ }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClosePopper = () => setAnchorEl(null)
    return (
        <Grid container>
            <Typography color='warning.active' variant='subtitle2' sx={{ display: 'flex', alignSelf: 'center' }} onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>
                <InfoOutlined sx={{ fontSize: '18px', mr: 0.5 }} />{t('texts.custom')}
            </Typography>
            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
                <Grid sx={{ mt: 2, width: 300, height: 100 }} >
                    <Grid md={12} p={2}>
                        <Typography color='primary.main' variant='h6' sx={{ display: 'flex', alignSelf: 'center' }} onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>
                            <InfoOutlined sx={{ fontSize: '20px', mr: 0.5 }} />{t('texts.custom_warranty')}
                        </Typography>
                        <Typography mt={1} color='text.disabled' variant='subtitle2' onMouseOver={handleOpenPopper} onMouseOut={handleClosePopper}>
                            {t('texts.custom_warranty_warning')}
                        </Typography>
                    </Grid>
                </Grid>
            </StyledPopper>
        </Grid>
    )
}