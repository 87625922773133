import { GeneralTerms } from "@/models";
import { Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import { DescPopup, SelectWithPlaceholderWithValidation } from "src/ui/components/shared";

interface GeneralTermsPopupProps {
    isPopupOpen: boolean
    closePopup: () => void
    handleSaveGeneralTerms: () => void
    terms: GeneralTerms
    fieldValidation?: any
}

export const GeneralTermsPopup: FC<GeneralTermsPopupProps> = ({ isPopupOpen, closePopup, handleSaveGeneralTerms, terms, fieldValidation }) => {
    return (
        <DescPopup
            handleClose={closePopup}
            open={isPopupOpen}
        >
            <Grid container gap={2} width={320}>
                <Grid item md={12} textAlign='center'>
                    <Typography variant='overline' color={'primary.main'} fontWeight="700">
                        {t('popups.titles.general_terms')}
                    </Typography>
                </Grid>
                <form onSubmit={() => { }}>
                    <SelectWithPlaceholderWithValidation {...(fieldValidation && { fieldValidation: fieldValidation })} items={terms.payment_terms} translateKey="payment_term" required name="payment_term_id" />
                    <SelectWithPlaceholderWithValidation {...(fieldValidation && { fieldValidation: fieldValidation })} items={terms.warranty_terms} translateKey="warranty_term" required name="warranty_term_id" />
                    <SelectWithPlaceholderWithValidation {...(fieldValidation && { fieldValidation: fieldValidation })} items={terms.delivery_terms} translateKey="delivery_term" required name="delivery_term_id" />
                    <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={handleSaveGeneralTerms}>{t('buttons.save')}</Button>
                </form>
            </Grid>
        </DescPopup>
    )
}