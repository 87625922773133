import { Button, Grid, Typography } from "@mui/material"
import { Formik } from "formik"
import { validationSchema } from './validationSchema'
import { initialValues } from './initialValues'
import { StyledTextField, StyledToggle } from '@/components'
import { useUsersStore } from "@/zustand"
import { useInviteUser } from "@/services"
import { useTranslation } from 'next-i18next';
import { getUsersTranslationKey } from "@/hooks"
import { AccountType } from "@/config"
import React from "react"

export const InviteUserPopupContent = () => {
    const toggleInvitePopup = useUsersStore(state => state.toggleInvitePopup);
    const { mutate } = useInviteUser();
    const { t } = useTranslation();
    const handleSubmit = (values, actions) => { mutate({...values, is_admin: (accountType == AccountType.ADMIN)}) }
    const [accountType, setaccountType] = React.useState(AccountType.ADMIN);
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newType: number,
    ) => {
        // mutate({ account_type: newType, id: userId })
        setaccountType(newType);
    };
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid item xs={12} mt={2}>
                        <Typography variant='body2'>{t(getUsersTranslationKey('text.invite_user_popup'))}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>  
                        <StyledTextField showLabel required translateKey='user.name' name='name' />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField showLabel required translateKey='user.email' name='email' />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <StyledToggle disabled={false} handleChange={handleChange} options={[AccountType.ADMIN, AccountType.REGULAR_USER]} translateKey='account_type' selectedValue={accountType}/>
                    </Grid>
                    <Button
                        variant='contained'
                        color='secondary'
                        type='submit'
                        fullWidth
                        disabled={!(formikProps.isValid && formikProps.dirty)}
                        sx={{ mt: 3 }}
                    >
                        {t('buttons.send_invite')}
                    </Button>
                    <Button
                        variant='outlined'
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={() => toggleInvitePopup(false)}
                    >
                        {t('buttons.cancel')}
                    </Button>
                </form>
            )}
        </Formik>
    )
}