import { Box, Grid, InputAdornment, TextField, Typography, Stack, Button } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { StyledComment, BasicTabs, StyledChip, DescPopup, EditExpiryDatePopup } from '@/components'
import { MarkChatReadOutlined } from "@mui/icons-material";
import { Comment } from "@/models";
import { useTranslation } from 'next-i18next';
import usePopup from "src/logic/hooks/use-popup";
import { ProcurementButtons } from "@/config";

type RequestExtensionPopupProps = {
    title: string | undefined
    newRequestExtension: boolean
    customLoadingButton: ProcurementButtons | null
    closeRequestExtensionPopup: () => void
    onEditExpiryDate: (validityDate: string) => void
}

export const RequestExtensionPopup: FC<RequestExtensionPopupProps> = ({
    title,
    newRequestExtension,
    customLoadingButton,
    closeRequestExtensionPopup,
    onEditExpiryDate
}) => {
    const { t } = useTranslation();
    const editExpiryDatePopup = usePopup()

    const handleActionEditExpiryDate = () => {
        closeRequestExtensionPopup()
        editExpiryDatePopup.handleOpen()
    }

    return (
        <>
            {
                newRequestExtension &&
                <DescPopup medium open={newRequestExtension} handleClose={closeRequestExtensionPopup}>
                    <Grid container gap={2}>
                        <Grid item md={12} textAlign='center'>
                            <MarkChatReadOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
                            <Grid item md={12} textAlign='center'>
                                <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            {/* <Stack maxHeight='350px' px={2} mx={1} width="100%">
                                {newRequestExtension?.map(comment => (
                                    <Grid container p={1} py={2} my={1} sx={{ bgcolor: 'error.darker', borderRadius: '8px' }}>
                                        <StyledComment key={comment.id} comment={comment} />
                                    </Grid>
                                ))}
                            </Stack> */}
                            <Grid item container justifyContent={'center'} md={12} mt={2} gap={1}>
                                {newRequestExtension ?
                                    <>
                                        <Grid item md={5}>
                                            <Button variant="containedWarning" fullWidth sx={{ fontWeight: 500 }}  >{t('buttons.dismiss_request')}</Button>
                                        </Grid>
                                        <Grid item md={5}>
                                            <Button variant="contained" fullWidth color='success'
                                                onClick={handleActionEditExpiryDate}
                                            >{t('buttons.edit_expiry_date')}</Button>
                                        </Grid>
                                    </>
                                    : <Grid item md={6}>
                                        <Button variant="contained" fullWidth  >{t('buttons.continue_to_form')}</Button>
                                    </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </DescPopup>
            }

            <DescPopup open={editExpiryDatePopup.isOpen} handleClose={editExpiryDatePopup.handleClose} >
                <EditExpiryDatePopup customLoadingButton={customLoadingButton} onEditExpiryDate={(validityDate: string) => onEditExpiryDate(validityDate)} />
            </DescPopup>
        </>
    )
}