import React from 'react';
import { Loading } from 'src/ui/components/shared';

const LoadingLayer = () => {
  return (
    <div
      style={{
        position: 'absolute',
        inset: 0,
        zIndex: 3,
        backgroundColor: 'rgba(255, 255, 255, .5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
      }}
    >
      <Loading fullHeight={false} />
    </div>
  );
};

export default LoadingLayer;
