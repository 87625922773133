import { Box, Button, Grid, Typography } from "@mui/material"
import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand";
import React, { useState } from "react";
import { ConfirmPopup, CancelButton } from "@/components";


type CancelAddingItemsButtonProps = {}

export const CancelAddingItemsButton: React.VFC<CancelAddingItemsButtonProps> = ({ }) => {
    const { t } = useTranslation();
    const { actions } = useProcurementStore(state => state.productFormManagerProperties)

    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleOpenDelPopup = () => setshowDelPopup(true);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    return (
        <>
            <Grid>
                <CancelButton handleClick={handleOpenDelPopup} />
            </Grid>
            <ConfirmPopup
                titleKey={t("popups.titles.cancel")}
                subTitleKey={t('popups.subTitles.cancel_adding_to_requisition')}
                buttonTitleKey={t('buttons.yes_confirm')}
                handleClose={handleCloseDelPopup}
                handleConfirm={() => { actions.reset(); handleCloseDelPopup() }}
                open={showDelPopup}
            />
        </>
    )
}