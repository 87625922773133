import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export type PopupWithTitleProps = {
  handleClose: () => void;
  open: boolean;
  children?: React.ReactNode;
  title?: string;
};

export const PopupWithTitle: React.VFC<PopupWithTitleProps> = ({
  children,
  title,
  handleClose,
  open,
}) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          ...(isSmallScreen ? {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            right: 0,
            left: 0,
            minWidth: '100%'
          } : { maxWidth: '400px' }),
        },
      }}
      sx={{ width: '100%', margin: 'auto' }}
      disableScrollLock
      fullWidth
      maxWidth='md'
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        <Typography variant='overline' sx={{ fontWeight: '700', textAlign: isSmallScreen ? 'start' : 'center' }}>
          {title}
        </Typography>
        {/* <Close
          onClick={handleClose}
          aria-label='add'
          sx={{
            color: '#747783',
            cursor: 'pointer',
            display: 'flex',
            height: 20,
            width: 20,
          }}
        /> */}
      </BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog >
  );
};

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, display: 'flex', flexDirection: 'row' }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};
