
export enum QuotePopupStatus {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    SENT_TO_BUYER = "SENT_TO_BUYER",
    CANCEL_QUOTE = "CANCEL_QUOTE"
}

export enum MyQuotesTableStatus {
    QUOTE_GENERATED = "QUOTE_GENERATED",
    SENT_FOR_APPROVAL = "SENT_FOR_APPROVAL",
    SENT_TO_Buyer = "SENT_TO_Buyer",
}

export enum QuoteStatus {
    QUOTE_GENERATED = "QUOTE_GENERATED",
}


export enum QuoteTableListOfActions {
    SEND_FOR_APPROVAL = "send_for_approval",
    SEND_REMINDER = "send_reminder",
    EDIT = "edit",
    GENERATE_QUOTE = "generate_quote",
    SEND_TO_BUYER = "send_to_buyer"
} 