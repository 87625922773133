import { useProfile, useProfileEmailFlow } from '@/zustand';
import { SendVerificationOrLost } from '../shared-popups/send-verification-or-lost';
import { VerificationCodeFormByEmail } from '../shared-popups/verification-code-form-byEmail'
import { ChangeEmailForm } from '../shared-popups/change-email-form';
import { Success } from '../shared-popups/success';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { useState, useEffect } from 'react';
import { useRequestVerificationOldEmail,useVerificationOldEmail,
useRequestChangeEmail,useVerificationNewEmail,useVerificationOldEmailAgain,
useVerificationNewEmailAgain } from '@/services';
import { ProfileChangeEmailByPhonePopupContent } from '../change-email-byPhone';


type Props = { };


export const ProfileChangeEmailPopupContent: React.FC<Props> = ({}) => {
   
    const { t } = useTranslation();
    const changeEmailByEmailPopupSteps = useProfileEmailFlow(state => state.changeEmailByEmailPopupSteps);
    const setChangeEmailByEmailSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps);
    const setChangeEmailByPhoneSteps = useProfileEmailFlow(state => state.setChangeEmailByPhoneSteps);
    const toggleChangeEmailPopup = useProfileEmailFlow(state => state.toggleChangeEmailPopup);
    const user_info = useProfile(state => state.user_info);
    const [enabled, setEnabled] = useState(false);
    const [isPhoneSteps, setIsPhoneSteps] = useState(false);
    const temporaryEmail = useProfileEmailFlow(state => state.temporaryEmail);

    const isTimer = useProfileEmailFlow(state => state.isTimer);
    const timer = useProfileEmailFlow(state => state.timer);
    const setIsTimer = useProfileEmailFlow(state => state.setIsTimer);
    const setTimer = useProfileEmailFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const getVerifyOldEmail = useRequestVerificationOldEmail(enabled); 
    const handleClickVerification = () => { getVerifyOldEmail.refetch() };

    const mutationRequestChangeEmailViaEmail = useVerificationOldEmail(); 
    const handleSubmitCode = (values, actions) => { mutationRequestChangeEmailViaEmail.mutate(values) } 
   
    const mutationChangeEmail = useRequestChangeEmail(); 
    const handleSubmitNewEmail = (values, actions) => { mutationChangeEmail.mutate(values)  } 

    const mutationVerificationNewEmail = useVerificationNewEmail(); 
    const handleSubmitCodeNew = (values, actions) => { mutationVerificationNewEmail.mutate(values) } 
   
    const ReRequestVerificationOldEmail = useVerificationOldEmailAgain(enabled); 
    const handleClickCodeAgainOld = () => { ReRequestVerificationOldEmail.refetch() };
    
    const { mutate } = useVerificationNewEmailAgain(temporaryEmail);
    const handleClickCodeAgainNew = () => { mutate() }

    const change_Email = [
        <SendVerificationOrLost 
            isDisabledButton={getVerifyOldEmail.isFetching}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verify_email'))}
            userInfo={user_info?.email} 
            verificationButtonTranslate={t('buttons.send_verification')} 
            lostButtonTranslate={t('buttons.lost_email')}  
            handleClickVerification={handleClickVerification}
            handleClickLost={ () => {setIsPhoneSteps(true), setChangeEmailByPhoneSteps(0)} }
            timerButton={isTimer}
            timerValue={timer}
        />,
        <VerificationCodeFormByEmail 
            isDisabledButton={mutationRequestChangeEmailViaEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_email' ))}
            handleClickNext={handleSubmitCode} 
            handleClickBack={() => {setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps-1)} }
            handleClickAgain={handleClickCodeAgainOld}
        />,
        <ChangeEmailForm
            isDisabledButton={mutationChangeEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_email'))}
            handleClickNext={handleSubmitNewEmail} 
            handleClickBack={() => {setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps-1) }}
        />,
        <VerificationCodeFormByEmail 
            isDisabledButton={mutationRequestChangeEmailViaEmail.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verification_phone_by_email' ))}
            userInfo={temporaryEmail} 
            handleClickNext={handleSubmitCodeNew} 
            handleClickBack={() => {setChangeEmailByEmailSteps(changeEmailByEmailPopupSteps-1) }}
            handleClickAgain={handleClickCodeAgainNew}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.change_email_success' ))}
            userInfo={user_info?.email} togglePopup={() => toggleChangeEmailPopup(false)}
        />
    ]

    return (
        <>
            { !isPhoneSteps ? change_Email[changeEmailByEmailPopupSteps] : <ProfileChangeEmailByPhonePopupContent/> }
        </>
    );
};