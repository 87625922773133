import { useProcurementStore } from '@/zustand';
import React from 'react';

const useTabs = () => {
  const { products, selectedMainProductId } = useProcurementStore(
    state => state.editBundleRFQDetailsSliceProperties
  );

  const tabs = React.useMemo(
    () =>
      products?.map(element => {
        const sellerMap = {};
        element.sellers?.forEach(sell => {
          if (sell.is_selected) sellerMap[sell.data.name] = true;
        });
        element.alternatives?.forEach(alt => {
          alt.sellers?.forEach(altSell => {
            if (altSell.is_selected) sellerMap[altSell.data.name] = true;
          });
        });
        const numberOfSelectedSellers = Object.keys(sellerMap).length;

        return {
          id: element.id,
          name: element.name,
          count: element.sellers?.length,
          detail: numberOfSelectedSellers,
        };
      }) ?? [],
    [products]
  );

  const allSellersCountAreZero = React.useMemo(() => {
    return tabs.reduce((prev, curr) => prev + curr.detail, 0) === 0;
  }, [tabs]);

  const selectedMainProduct = React.useMemo(() => {
    return products?.find(prod => prod.id === selectedMainProductId);
  }, [products, selectedMainProductId]);

  const numberOfSelectedSellersForMainProduct = React.useMemo(() => {
    return tabs.find(tab => tab.id === selectedMainProductId)?.detail;
  }, [tabs, selectedMainProductId]);

  return {
    tabs,
    selectedMainProduct,
    allSellersCountAreZero,
    numberOfSelectedSellersForMainProduct,
  };
};

export default useTabs;
