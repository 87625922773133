import * as yup from 'yup'

export const commentsWithReasonValidationSchema = yup.object({
    reasonId: yup.string().required('required').nullable(),
    comment: yup.string(),
    hideReason: yup.boolean()
});
export const commentsValidationSchema = yup.object({
    comment: yup.string()
}); 
