import { useState, useEffect } from 'react';
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import React from "react";
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
import { AlternativeProductRequisitionButton } from 'src/ui/components/dumb';

export const AlternativeProductAlternatives = (props) => {
    const { t } = useTranslation();
    const productsRef = React.useRef();
    const [productsOverflow, setproductsOverflow] = React.useState(false);
    const scrollProducts = scrollOffset => productsRef.current.scrollLeft += scrollOffset;
    function isOverflowActive(event) { return event.clientWidth < event.scrollWidth }
    const { push, query } = useRouter();

    useEffect(() => {
        if (isOverflowActive(productsRef.current)) {
            setproductsOverflow(true);
            return;
        }
        setproductsOverflow(false);
    }, [isOverflowActive]);
    return (
        <Grid container>
            <Grid item container md={12} sx={{ justifyContent: 'space-between', alignItems: 'center', py: 5, px: 15 }}>
                <Typography variant="overline" sx={{ fontWeight: 600 }}>{t('texts.view_alternatives')}</Typography>
                <Button sx={{ fontWeight: '600 !important' }} onClick={() => {
                    push({
                        pathname: sm.catalogue.products.alternatives(props.productName).url,
                        query: { ...query },
                    })
                }}
                >{t('buttons.view_all')}</Button>
            </Grid>
            <div>
                <div>{productsOverflow && <ArrowBackIosNew onClick={() => scrollProducts(-200)} fontSize='inherit' sx={{ color: 'text.third', cursor: 'pointer' }} />}</div>
                <div ref={productsRef}
                    sx={{ overflowX: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'horizontal' }} wrap='nowrap'>
                    {props.alternatives ? props.alternatives.map(el => (
                        <Box>
                            <h1>hi</h1>
                        </Box>
                    )) : ""}
                </div>
                <div>
                    {productsOverflow && <ArrowForwardIos onClick={() => scrollProducts(+200)} fontSize='inherit' sx={{ color: 'text.third', cursor: 'pointer' }} />}
                </div>
            </div>
        </Grid >
    )
}


{/* <Grid container item alignItems={'center'} md={12} px={12}>
<Grid item md={0.2}>{productsOverflow && <ArrowBackIosNew onClick={() => scrollProducts(-200)} fontSize='inherit' sx={{ color: 'text.third', cursor: 'pointer' }} />}</Grid>
<Grid
    item
    md={11.6}
    ref={productsRef}
    container
    gap={2}
    sx={{ overflowX: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'horizontal', }}
    wrap='nowrap'
>
    {props.alternatives ? props.alternatives.map(el => (
        <Grid item container md={3.7} p={1} sx={{ bgcolor: 'secondary.contrastText' }}>
            <Grid md={4} item height='94px'>
                <img src={el.image_url} height={'100%'} width={'100%'} />
            </Grid>
            <Grid md={8} item pl={2} py={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography>{el.brand.name}</Typography>
                <Typography>{el.name}</Typography>
            </Grid>
            <Grid md={12} item p={1} width="100%">
                <AlternativeProductRequisitionButton isAddedToRequisition={false}/>
            </Grid>
        </Grid>
    )) : ""}
</Grid>
<Grid item container md={0.2} sx={{ justifyContent: 'flex-end' }}>
    {productsOverflow && <ArrowForwardIos onClick={() => scrollProducts(+200)} fontSize='inherit' sx={{ color: 'text.third', cursor: 'pointer' }} />}
</Grid>
</Grid> */}

