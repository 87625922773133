import { Typography } from '@mui/material';
import React from 'react';

const MAX_DESCRIPTION_LENGTH = 470;

type IProps = {
  description: string;
};

const toggleButtonStyles: React.CSSProperties = {
  marginInlineStart: '.5rem',
  cursor: 'pointer',
  color: '#13628C',
};

const getDescriptionToDisplay = (description: string): string => {
  if (description.length <= MAX_DESCRIPTION_LENGTH) return description;
  return `${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
};

const Description = ({ description }: IProps) => {
  const shouldSlice = description.length > MAX_DESCRIPTION_LENGTH;
  const [showingMore, setShowingMore] = React.useState(false);
  const toggleShowingMore = () => setShowingMore(v => !v);
  const descriptionToDisplay = showingMore ? description : getDescriptionToDisplay(description);

  return (
    <Typography mt={1} fontSize={13} fontWeight={400} color='#8B8D98' lineHeight={1.25}>
      {descriptionToDisplay}{' '}
      {shouldSlice && (
        <>
          {showingMore && (
            <span style={toggleButtonStyles} onClick={toggleShowingMore}>
              Show Less
            </span>
          )}
          {!showingMore && (
            <span style={toggleButtonStyles} onClick={toggleShowingMore}>
              Show More
            </span>
          )}
        </>
      )}
    </Typography>
  );
};

export default Description;
