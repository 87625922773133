import { SvgIconProps, SvgIcon } from '@mui/material';

export const FacebookIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d="M15.4925 4.65292H17.5009V1.15492C17.1544 1.10725 15.9628 1 14.5749 1C11.6792 1 9.6955 2.82142 9.6955 6.16908V9.25H6.5V13.1605H9.6955V23H13.6133V13.1614H16.6796L17.1663 9.25092H13.6124V6.55683C13.6133 5.42658 13.9177 4.65292 15.4925 4.65292Z" fill="#3B5999"/>
  </SvgIcon>
);
