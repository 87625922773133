import { ProcurementForms } from '@/config';
import { useProcurementStore } from '@/zustand';
import { useRouter } from 'next/router';
import React from "react";
import { CTDetails } from '../ct-Details';
import { CTHeader } from '../ct-Header';

type CTPageProps = {
    relatedType?: ProcurementForms | undefined
    relatedId?: number | undefined
    relatedName?: string | undefined
}

export const CTPage: React.FC<CTPageProps> = ({ relatedType, relatedId, relatedName }) => {
    const { push } = useRouter();
    const { customLoadingButton, comparisonTableData, comparisonTableProducts, selectedMainProduct, selectedMainProductId, selectedAlternativeProducts,
        ctTabs, isBundlePO, purchaseOrderId, isShowSpecificQuotes, setSelectedMainProduct, selectSellersForCtProduct, clearItems, generatePOFromCT, EditCT,updateIsShowSpecificQuotes, changeProjectName } = useProcurementStore()

    return (
        <>
            <CTHeader comparisonTableData={comparisonTableData} customLoadingButton={customLoadingButton} generatePOFromCT={() => generatePOFromCT((link) => push(link))} EditCT={() => EditCT((link) => push(link))} isBundlePO={isBundlePO} purchaseOrderId={purchaseOrderId} relatedType={relatedType} relatedId={relatedId} relatedName={relatedName} isShowSpecificQuotes={isShowSpecificQuotes} updateIsShowSpecificQuotes={updateIsShowSpecificQuotes} changeProjectName={changeProjectName} />

            <CTDetails comparisonTableProducts={comparisonTableProducts} ctTabs={ctTabs} clearItems={clearItems} setSelectedMainProduct={setSelectedMainProduct} selectedMainProductId={selectedMainProductId} selectedMainProduct={selectedMainProduct} selectedAlternativeProducts={selectedAlternativeProducts} selectSellersForCtProduct={selectSellersForCtProduct} />

            {/* <CTReceivedQuotes receivedQuotes={receivedQuotes} selectedQuote={selectedQuote} selectedQuoteId={selectedQuoteId} setSelectedQuote={setSelectedQuote} /> */}
        </>
    )
}