import { useUsersStore } from '@/zustand'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  ColumnDef,
  flexRender, getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel, getSortedRowModel,
  SortingState, useReactTable
} from '@tanstack/react-table'
import { TableMeta } from "@tanstack/table-core"
import { useTranslation } from 'next-i18next'
import React from 'react'
import { StyledPagination } from '..'
import { arrayFilter, sortArray } from './table-functions'
import { Filter } from './TableFilters'



interface TableWithPaginationlProps {
  children: React.ReactNode,
  tableMeta?: TableMeta<TData>,
  disablePagination?: boolean
  style?: React.CSSProperties
}

export const TableWithPagination: FC<TableWithPaginationlProps> = ({ children, data, columns, Menu, tableMeta, disablePagination = false, style = {} }: TableWithPaginationlProps) => {
  return (
    <FilteredTable {...{ data, columns, children, Menu, tableMeta, disablePagination, style }} />
  )
}
function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  React.useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip] as const
}
const defaultColumn: Partial<ColumnDef<Person>> = {
  cell: ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue()
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    return (
      <input
        value={value as string}
        onChange={e => setValue(e.target.value)}
        onBlur={onBlur}
      />
    )
  },
}
function FilteredTable({
  data,
  columns,
  children,
  Menu,
  tableMeta,
  disablePagination,
  style,
}: {
  data,
  columns: ColumnDef<>[],
  children: React.ReactNode,
  disablePagination: boolean
  style: React.CSSProperties
}) {

  let sortByDefaultColumns = columns.filter(column => column != undefined && column.id && column.meta.sortByDefault === true)
  sortByDefaultColumns = (sortByDefaultColumns.length == 0) ? ([]) : sortByDefaultColumns
  let sortingStateArray = sortByDefaultColumns.map(column => {
    return { id: column.id, desc: column.meta.sortDescending }
  })
  const [tableData, settableData] = React.useState<SortingState>(data)
  const [sorting, setSorting] = React.useState<SortingState>(sortingStateArray)
  //const [sorting, setSorting] = React.useState<SortingState>([{id: 'number_of_products', desc: false}])
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
  const changeUserData = useUsersStore(state => state.changeUserData);
  const setUsersData = useUsersStore(state => state.setUsersData);
  const users_info = useUsersStore(state => state.users_info);
  setUsersData(tableData)

  const table = useReactTable({
    data: tableData,
    columns,
    defaultColumn,
    // Pipeline
    globalFilterFn: arrayFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(!disablePagination && {
      getPaginationRowModel: getPaginationRowModel(),
    }),
    autoResetPageIndex,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortingFns: {
      sortArray
    },
    // meta: tableMeta,
    meta: {
      ...tableMeta,
      updateData: (rowIndex, columnId, newRow) => {
        skipAutoResetPageIndex()
        settableData(old =>
          old.map((row, index) => {
            if (row.id === columnId) {
              return newRow;
            }
            return row
          })
        )
      },

      deleteRow: (deletedRow) => {
        skipAutoResetPageIndex()
        settableData(old => { return (old.filter(row => row.id !== deletedRow)) })
      }

    },
    debugTable: true,
  })
  const { t } = useTranslation();

  return (

    <div>
      <TableContainer style={style}>
        <Table style={{ borderCollapse: 'separate', borderSpacing: '0px 5px' }}>
          <TableHead className={table.options.meta.headStyle ?? null}>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} colSpan={header.colSpan} className={header.column.columnDef.meta.headerCellClass}>
                    {header.isPlaceholder ? null : (
                      <div>
                        <Grid container alignItems={'center'} justifyContent='flex-start'>
                          {header.column.columnDef.meta.headerRender && <>
                            {header.column.columnDef.meta.headerRender}
                          </>}
                          <TableSortLabel
                            IconComponent={header.column.columnDef.meta.columnIcon}
                            active={header.column.getCanSort()}
                            direction={header.column.getIsSorted() && header.column.getIsSorted() == 'desc' ? 'desc' : 'asc'}
                            onClick={header.column.getToggleSortingHandler()}
                          > <Typography component='label' variant='subtitle2' color='text.third' fontWeight={400}>
                              {t('table_cells.' + flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              ))}
                            </Typography>
                          </TableSortLabel>
                        </Grid>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => {
              return (
                <TableRow key={row.id}
                  className={table.options.meta.getRowStyle(index) ?? null}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <>
                        <TableCell key={index} className={cell.column.columnDef.meta.tableRow?.cellClass ?? null}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      </>)
                  })}
                  {Menu && <TableCell><Menu row={row.original} table={table} index={index} /></TableCell>}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && <>
        {
          Math.ceil(table.getPrePaginationRowModel().rows.length / 10) > 1 && < Box
          sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}
          >
            <StyledPagination
              count={Math.ceil(table.getPrePaginationRowModel().rows.length / 10)}
              page={table.getState().pagination.pageIndex + 1}
              onChange={(event, value) => {
                table.setPageIndex(value - 1);
              }}
              />
          </Box>
        }
      </>}
    </div>
  )
}
