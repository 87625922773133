import React, { FC, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import EditIcon from '@mui/icons-material/Edit';
import SelectDate from "src/ui/components/shared/text-field/date-textField";
import moment from 'moment';
import * as yup from 'yup';
import { ProcurementButtons } from "@/config";
import { StyledLoadingButton } from "src/ui/components/shared";


interface EditExpiryDatePopupProps {
    onEditExpiryDate: (validityDate: string) => void
    customLoadingButton?: ProcurementButtons | null
}

export const EditExpiryDatePopup: FC<EditExpiryDatePopupProps> = ({ onEditExpiryDate, customLoadingButton }) => {
    let defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 7);
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const handleDateChange = (date) => setSelectedDate(date)
    const formattedDate = moment(selectedDate).format("DD-MM-YYYY");

    const handleReset = () => {
        let resetResult = new Date();
        resetResult.setDate(resetResult.getDate() + 7);
        setSelectedDate(resetResult);
    }

    const handleSubmit = (values, actions) => {
        values.validity_date = formattedDate
        if (values.validity_date !== null && moment(values.validity_date, "DD-MM-YYYY").isValid()) {
            onEditExpiryDate(values.validity_date)
        }
    }
    const validityDateValidationSchema = yup.object({ validity_date: yup.date() });

    return (
        <Grid container gap={2} px={2}>
            <Grid item md={12} textAlign='center'>
                <EditIcon sx={{ color: 'primary.main', fontSize: 30 }} />
                <Grid item md={12} textAlign='center'>
                    <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>{t('buttons.edit_expiry_date')} </Typography>
                </Grid>
            </Grid>
            <Formik initialValues={{ validity_date: formattedDate }} validationSchema={validityDateValidationSchema} onSubmit={handleSubmit} validateOnBlur={true} validateOnChange={true}  >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Grid item container justifyContent={'center'} md={12} px={2}>
                            <Grid item md={12} textAlign='start'>
                                <Grid item container md={12} justifyContent={'space-between'}>
                                    <Typography variant='body2' fontWeight="400" color='text.third'> {t('texts.valid_until')}*  </Typography>
                                    <Typography variant='body2' fontWeight="400" color='primary.main' onClick={handleReset} sx={{ cursor: 'pointer' }}>
                                        {t("buttons.reset")}
                                    </Typography>
                                </Grid>
                                <SelectDate selectedDate={selectedDate} formattedDate={formattedDate} handleDateChange={handleDateChange} />
                            </Grid>
                            <Grid item container justifyContent={'center'} md={12} my={2}>
                                <StyledLoadingButton variant="contained" translateKey="save_expiry_date" disabled={!(formattedDate !== null && moment(formattedDate, "DD-MM-YYYY").isValid())} loading={customLoadingButton == ProcurementButtons.SAVE_EXPIRY_DATE} onClick={handleSubmit} />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Grid >
    )
}

