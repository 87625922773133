import { request } from '@/helpers';

export const getSellersCatalogueIndex = () => {
  return request({ url: `/product/sellers`, method: 'GET', params: { only_my_seller: false } });
};

export const getFilteredSellers = (id?: string, showMySellers?: boolean) => {
  const url = `/product/sellers`;
  return request({
    url,
    method: 'GET',
    params: {
      ...(showMySellers != undefined && { only_my_seller: showMySellers }),
      ...(id && { sort_by: id }),
    },
  });
};

export const getSellerDetails = (id: number) => {
  return request({ url: `/product/sellers/${id}`, method: 'GET' });
};
