import create from 'zustand';

export const useProfilePasswordFlow = create((set, get) => ({
  passwordPopupVisible: false,
  PasswordPopupSteps: 0,
  PasswordPopupByEmailSteps: 0,
  PasswordPopupByEmailVisible: 0,
  PasswordPopupByPhoneSteps: 0,
  PasswordPopupByPhoneVisible: 0,
  changePasswordToken: null,
  isEmailSteps: false,
  isPasswordByCurrentFinish: false,
  timer: 0,
  isTimer: false,
  
  setChangePasswordSteps: (step: number) => {
    set({ PasswordPopupSteps: step });
  },
  togglePasswordPopup: (open: boolean) => {
    set({ passwordPopupVisible: open });
  },
  setChangePasswordToken: (token: string) => {
    set({ changePasswordToken: token });
  },
  setChangePasswordByEmailSteps: (step: number) => {
    set({ PasswordPopupByEmailSteps: step });
  },
  togglePasswordByEmailPopup: (open: boolean) => {
    set({ PasswordPopupByEmailVisible: open });
  },
  setChangePasswordByPhoneSteps: (step: number) => {
    set({ PasswordPopupByPhoneSteps: step });
  },
  togglePasswordByPhonePopup: (open: boolean) => {
    set({ PasswordPopupByPhoneVisible: open });
  },
  setEmailSteps:(open: boolean) => {
    set({ passwordPopupVisible: open });
  },
  setPasswordByCurrentFinish:(open: boolean) => {
    set({ isPasswordByCurrentFinish: open });
  },

  setIsTimer: (num: boolean) => {
    set({ isTimer: num });
  },
  setTimer: (num: number) => {
    set({ timer: num });
  },

}));
