import { PhoneInputField } from '@/components';
import { Button, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';

interface ChangeDirectFormProps {
  isDisabledButton: boolean;
  paragraphTranslate: string;
  handleClickNext: (values: any, actions: any) => void;
  handleClickBack: Function;
}

export const ChangeDirectForm: FC<ChangeDirectFormProps> = (props: ChangeDirectFormProps) => {
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={props.handleClickNext}>
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <Typography variant='body2' sx={{ fontWeight: '400' }}>
            {props.paragraphTranslate}
          </Typography>
          <Grid item xs={12} mt={2}>
            <PhoneInputField name='direct_number' label='Direct Number' required />
          </Grid>
          <Button
            disabled={props.isDisabledButton}
            variant='contained'
            color='secondary'
            type='submit'
            fullWidth
            sx={{ mt: 3 }}
          >
            {t('buttons.next')}
          </Button>
          <Button
            variant='outlined'
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => {
              props.handleClickBack(false);
            }}
          >
            {t('buttons.back')}
          </Button>
        </form>
      )}
    </Formik>
  );
};
