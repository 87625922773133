import { DescriptionOutlined } from "@mui/icons-material"
import { Divider, Grid, Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { TextDataView, ButtonWithStartIcon, TextDataViewEnd } from '@/components'

export const AlternativeProductDetails = (props) => {
    const { t } = useTranslation();

    return (
        <Grid container sx={{ bgcolor: 'secondary.contrastText', p: 3 }}>
            <Grid item md={3} height='250px'>
                <Box height='100%' pt={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <img src={props.alternativeProductData.image_url} height={'185px'} width={'100%'} style={{ objectFit: 'contain' }} />
                    <ButtonWithStartIcon variant="outlined" icon={<DescriptionOutlined color="primary" />} translateKey="data_sheet" />
                </Box>
            </Grid>
            <Grid item md={9} pl={8} >
                <Box height='100%' sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="subtitle2" sx={{ color: "text.disabled", fontWeight: 400 }}>
                        {props.alternativeProductData.description}
                    </Typography>
                    <Divider sx={{ borderColor: 'info.contrastText', my: 4 }} />
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item md={6}>
                            <TextDataView textVariant='body1' title={t('texts.part_number')} value={props.alternativeProductData.part_number} />
                            <TextDataView textVariant='body1' title={t('texts.brand')} value={props.alternativeProductData.brand ? props.alternativeProductData.brand.name : ''} />
                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md={4}>
                            {props.alternativeProductData.productAttributes ? props.alternativeProductData.productAttributes.map(el => (
                                <TextDataViewEnd textVariant='body1' title={el.name} value={el.value} />
                            )) : ""}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}