import { productManagerEnv } from "@/config"
import { Product } from "@/models"
import { Grid } from "@mui/material"
import { t } from "i18next"
import { FC } from "react"
import { AddProductToEnvironmentButton, PriceInputForEnvironmentProduct, ProductQuantity } from "."

export const QuoteProductActions: FC<{ product: Product, isPageAlternative?: boolean }> = (props) => {
    return (
        <>
            <Grid item md={12} container alignItems={'center'}>
                <ProductQuantity product={props.product} isPageAlternative={props.isPageAlternative} />
            </Grid>
            <Grid item md={12}>
                <PriceInputForEnvironmentProduct placeholder={props.product.catalog_price} product={props.product} isPageAlternative={props.isPageAlternative} />
                {/* <ProductEditPrice placeholder={isInsideQuoteEnv ? props.product.catalog_price : null} handleChangePrice={handleChangePrice} value={props.product.price} showEndAdornment isPageAlternative={props.isPageAlternative} /> */}
            </Grid>
            <Grid item md={12}>
                <AddProductToEnvironmentButton product={props.product} titleForAddedProduct='added_to_Quote' environment={productManagerEnv.quote} titleForAddingProduct={t("buttons.add_to_quote")} isPageAlternative={props.isPageAlternative} />
            </Grid>
        </>
    )
}