import { DeleteIcon } from "@/icons";
import { ProcurementFormProduct, ProcurementModelDetails, Product } from "@/models";
import { UnfoldMore } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ColumnDef } from "@tanstack/react-table";
import { t } from "i18next";
import React, { FC } from "react";
import { BrandTableImage, ProductTableDetails, StyledTable } from "src/ui/components/dumb";
import { StyledTextField } from "src/ui/components/shared";


type FormProductsTableProps = {
    key: string
    rowsData: ProcurementFormProduct
    formDetails: ProcurementModelDetails
    products: Product[]
    canEdit?: boolean
    removeProduct: (product: Product) => void
    updateProductPrice: (product: Product, value: string) => void
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
    cellHead: {
        backgroundColor: theme?.palette?.secondary.contrastText,
        '& th:first-child': { borderRadius: '6px 0 0 6px', marginTop: 10 },
        '& th:last-child': { borderRadius: '0 6px 6px 0' },
        height: '20px', color: theme?.palette?.text.third, fontSize: 16, fontWeight: 400, paddingLeft: 24, textTransform: 'uppercase', width: '400px'
    },
    rowBody: { backgroundColor: theme?.palette?.secondary.contrastText, height: '20px', borderRadius: '8px', fontWeight: 400, padding: 0 },
    styleWithPaddingColumn: { backgroundColor: theme?.palette?.secondary.contrastText, borderRadius: '8px', height: '20px', paddingLeft: 40 },
    styleRemarkColumn: { backgroundColor: theme?.palette?.secondary.contrastText, borderRadius: '8px', height: '20px', paddingLeft: 20 },

}));


export const SellerCatalogProductsTable: React.FC<FormProductsTableProps> = ({ rowsData, canEdit, removeProduct, updateProductPrice }) => {
    const classes = useStyles()
    const PriceRendere: FC<{ value: string, row: any }> = ({ value, row }) => {
        return <Grid container pl={1}>
            {canEdit ? <Box width='88px'>
                <StyledTextField
                    onChange={(e) => updateProductPrice(row.original, e.target.value)}
                    name={`${name}[${row.index}].price`}
                    translateKey="unit_price" outlined
                />
            </Box>
                : <Typography variant='subtitle2'>{t('texts.aed') + ' '} {value ?? 0} </Typography>}
        </Grid>
    }
    const columns = React.useMemo<ColumnDef<ProcurementFormProduct>[]>(
        () => [
            {
                accessorKey: 'productDetails',
                id: 'productDetails',
                header: 'product_details',
                cell: info => {
                    const product = rowsData[info.row.index]
                    return <ProductTableDetails content={info.getValue()} product={product} />
                },
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.rowBody
                    }
                },
                enableColumnFilter: false,
            },
            {
                accessorKey: 'brandImage',
                id: 'brandImage',
                header: 'brand',
                cell: (info) => <BrandTableImage imageUrl={info.getValue()} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'category',
                id: 'category',
                header: 'category',
                cell: info => (<Typography maxWidth={100} variant='subtitle2'> {info.getValue().name} </Typography>),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'class',
                id: 'class',
                header: 'class',
                cell: info => (<Typography maxWidth={100} variant='subtitle2'> {info.getValue().name} </Typography>),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'type',
                id: 'type',
                header: 'type',
                cell: info => (<Typography maxWidth={100} variant='subtitle2'> {info.getValue().name} </Typography>),
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
            {
                accessorKey: 'price',
                id: 'price',
                header: 'unit_price',
                cell: ({ row, getValue }) => <PriceRendere key={getValue()} value={getValue()} row={row} canEdit={canEdit} updateProductPrice={updateProductPrice} />,
                meta: {
                    filterType: 'search',
                    columnIcon: UnfoldMore,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleRemarkColumn
                    }
                },
                enableColumnFilter: false
            },
        ]
        ,
        [canEdit]
    )
    const editColumns = React.useMemo<ColumnDef<ProcurementFormProduct>[]>(
        () => [
            {
                accessorKey: 'actions',
                id: 'actions',
                header: 'empty',
                cell: ({ row: { index, original } }) => (<Box onClick={() => removeProduct(original)} width='30px' sx={{ display: 'flex', height: '30px', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', backgroundColor: 'warning.contrastText' }}>
                    <DeleteIcon />
                </Box>),
                meta: {
                    filterType: 'search',
                    columnIcon: null,
                    headerCellClass: classes.cellHead,
                    tableRow: {
                        cellClass: classes.styleWithPaddingColumn
                    }
                },
                enableColumnFilter: false
            },
        ]
        ,
        []
    )
    return (<StyledTable style={{ overflow: 'visible' }} key={JSON.stringify({ canEdit: String(canEdit), length: rowsData.length })} rows={rowsData} columns={canEdit ? [...columns, ...editColumns] : columns} />)
}
