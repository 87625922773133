import {
  Box,
  Card,
  Grid,
  CardMedia,
  Typography,
  Badge,
  TextField,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { useRequisition } from '@/zustand';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DeleteIcon } from 'src/ui/icons/delete';
import { ConfirmPopup, CustomTermsSelect } from '@/components';
import { Product, Requisition } from '@/models';
import { useTranslation } from 'next-i18next';
import { getRequisitionTranslationKey } from '@/hooks';

interface RequisitionProductMobileProps {
  product: Product;
  selectedProductIndex: number;
  mainProduct: Product;
  requisition: Requisition;
  tabId: number;
  removeProduct: Function;
  isWithDetails: boolean;
}

export const RequisitionProductMobile: React.FC<RequisitionProductMobileProps> =
  ({
    product,
    selectedProductIndex,
    mainProduct,
    requisition,
    tabId,
    removeProduct,
    isWithDetails,
  }) => {
    const changeQuantity = useRequisition(state => state.changeQuantity);
    const [showSpec, setshowSpec] = useState(false);
    const [showDelPopup, setshowDelPopup] = useState(false);
    const handleCloseDelPopup = () => setshowDelPopup(false);
    const handleOpenDelPopup = () => {
      setAnchorEl(null);
      setshowDelPopup(true);
    };
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const changeProductQuantity = (q: number, v: number) => {
      changeQuantity(
        requisition.reqName,
        requisition.id,
        tabId,
        product,
        q + v
      );
    };
    const handleDelete = (product, id, tabId) => {
      removeProduct(product, id, tabId);
    };
    return (
      <Card
        variant='outlined'
        sx={{
          bgcolor: 'grey.50',
          // height: '408px',
          height: '100%',
          borderRadius: 2,
          borderColor: mainProduct
            ? 'secondary.main'
            : 'action.disabledBackground',
          borderWidth: mainProduct ? 2 : 1,
        }}
      >
        <Grid container sx={{ height: '50px' }}>
          <Grid item md={11} xs={11} p={1} sx={{ height: '30px' }}>
            <Box
              sx={{
                height: 30,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CardMedia
                component='img'
                image={product.brand.imageURL}
                sx={{
                  height: '30px',
                  width: 50,
                  objectFit: 'contain',
                  marginRight: 1,
                }}
              />
              <Typography
                variant='body2'
                fontWeight='800'
                sx={{ marginTop: 0.4 }}
              >
                {product.brandName}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={1} xs={1} pr={1} pt={1}>
            <Badge
              variant='dot'
              sx={{ '& .MuiBadge-badge': { backgroundColor: 'success.dark' } }}
              invisible={
                (mainProduct &&
                  !requisition?.products[selectedProductIndex]?.customTerms
                    .changed) ||
                !mainProduct
              }
            >
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={openMenu}
                sx={{
                  maxWidth: '20px',
                  maxHeight: '30px',
                  minWidth: '20px',
                  minHeight: '30px',
                }}
              >
                <MoreVertIcon
                  sx={{ color: 'text.third', fontSize: '23px !important' }}
                />
              </IconButton>
            </Badge>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                },
              }}
            >
              {mainProduct && (
                <CustomTermsSelect
                  requisition={requisition}
                  reqProduct={requisition?.products[selectedProductIndex]}
                  isSmallScreen={true}
                  closeMenu={handleClose}
                />
              )}
              <MenuItem onClick={handleOpenDelPopup}>
                <DeleteIcon sx={{ marginRight: 1 }} />
                {t('buttons.delete')}
              </MenuItem>
            </Menu>
            <ConfirmPopup
              buttonTitle={t('buttons.confirm_delete')}
              title={t('texts.confirm_delete', { ProductName: 'product.name' })}
              handleClose={handleCloseDelPopup}
              handleConfirm={() => handleDelete(product, requisition.id, tabId)}
              open={showDelPopup}
            />
          </Grid>
        </Grid>
        <Grid container pt={1} sx={{ height: '104px' }}>
          <Grid item md={4} xs={4} sx={{ height: '104px' }}>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
              }}
            >
              <CardMedia
                component='img'
                image={product.imageURL}
                sx={{ objectFit: 'contain' }}
              />
            </Box>
          </Grid>
          <Grid item md={7} xs={7} p={2}>
            <Typography
              variant='body2'
              fontWeight='500'
              color='textSecondary'
              sx={{
                whiteSpace: 'initial',
                lineHeight: 1.3,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflowY: 'hidden',
                textOverflow: 'ellipsis',
                // width: '118px',
              }}
            >
              {product.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 1, mt: 1, alignItems: 'center' }}>
          <Grid item md={4} xs={4}>
            <Typography variant='body2' fontWeight='500'>
              {t(getRequisitionTranslationKey('titles.quantity'))}
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={8}
            xs={8}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <TextField
              type='number'
              value={product.quantity}
              onChange={val =>
                changeQuantity(
                  requisition.reqName,
                  requisition.id,
                  tabId,
                  product,
                  val.target.value
                )
              }
              InputProps={{
                inputProps: {
                  style: { textAlign: 'center' },
                },
                style: { width: '100%', height: 40, justifyContent: 'center' },
                startAdornment: (
                  <IconButton
                    onClick={() => changeProductQuantity(product.quantity, -1)}
                  >
                    <RemoveIcon sx={{ color: 'text.primary' }} />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => changeProductQuantity(product.quantity, +1)}
                  >
                    <AddIcon sx={{ color: 'text.primary' }} />
                  </IconButton>
                ),
              }}
              FormHelperTextProps={{
                sx: {
                  textAlign: 'center',
                  justifyContent: 'center',
                },
              }}
              sx={{
                '& $div': {
                  justifyContent: 'center',
                  textAlign: 'center',
                },
                backgroundColor: 'white',
                height: 40,
                width: '100%',
                textAlign: 'center',
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <ProductAttributesList
            attributeName={t(getRequisitionTranslationKey('titles.type'))}
            attributeValue={product.type}
            color={1}
          />
          {/* <ProductAttributesList
            attributeName={'Brand'}
            attributeValue={product.brandName}
            color={0}
          /> */}
          <ProductAttributesList
            attributeName={t(getRequisitionTranslationKey('titles.part_number'))}
            attributeValue={product.partNumber}
            color={0}
          />
          {Object.entries(product.productAttributes).map(([k, v], index) => {
            return (
              ((index < 2 && !showSpec) || showSpec) && (
                <ProductAttributesList
                  key={k}
                  attributeName={k}
                  attributeValue={v}
                  color={index + 1}
                />
              )
            );
          })}
          {Object.entries(product.productAttributes).length > 1 && (
            <Box sx={{ my: 2, mx: 1 }}>
              <Button
                sx={{
                  width: '100%',
                  fontWeight: 500,
                  borderColor: 'primary.main',
                }}
                onClick={() => setshowSpec(!showSpec)}
                variant='outlined'
                endIcon={
                  showSpec ? (
                    <KeyboardArrowUpIcon sx={{ height: 24 }} />
                  ) : (
                    <KeyboardArrowDownIcon sx={{ height: 24 }} />
                  )
                }
              >
                {showSpec ? 'Show Less' : 'Show All'}
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    );
  };

const ProductAttributesList = ({ attributeName, attributeValue, color }) => {
  return (
    <Grid
      container
      sx={{
        bgcolor: color % 2 === 0 ? 'primary.light' : 'inherit',
        display: 'flex',
        height: 35,
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant='body2' color='textSecondary'>
          {attributeName}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          variant='body2'
          fontWeight='700'
          color='textPrimary'
          sx={{ wordWrap: 'break-word' }}
        >
          {attributeValue}
        </Typography>
      </Grid>
    </Grid>
  );
};
