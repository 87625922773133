import { Loading, RemarkButton, RemarkPopupContent } from '@/components';
import { getInputsTranslationKey } from '@/hooks';
import { ColumnType, GeneralObject, Product, ProductCategoryKind, ProductCategoryKindLabel } from '@/models';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { FC, useState } from 'react';
import { DescPopup, EditableTextField, StyledSelect, TextFieldCounter } from 'src/ui/components/shared';
import ErrorWithRefetchButton from 'src/ui/components/shared/error-with-refetch';
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import { DescriptionIndicator } from '../../requisition/requisition-alternatives-table/components';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';

export type TablesFormProps = {
    tableSliceProperties: any
    selectItems?: GeneralObject[]
    handleChangeInput?: (id: string, value: string) => void
    handleChangeSelect?: (id: string, value: string) => void
    handleChangeCounterValue?: (id: string, value: number) => void
    handleChangeAdditionalRequirements?: (id: string, value: string) => void
    handleSwitch?: (id: string, value: boolean) => void
    handleDelete?: (id: string) => void
    handleChangeRemark?: (id: string, value: string) => void
    disableSelect?: boolean
    fallbackSelectItem?: any
    isSendToBuyerRequested: boolean
    hideRequested?: boolean
    disableEditRequirements?: boolean
};


const COL_TYPE_SIZE = {
    [ColumnType.DELETE]: 1,
    [ColumnType.COUNTER]: 1.1,
    [ColumnType.REMARK]: 1,
    [ColumnType.SELECT]: 2,
    [ColumnType.INPUT]: 2,
    [ColumnType.SWITCH]: 1,
    [ColumnType.IMAGE]: 0.55,
    long: 2,
    string: 1,
    [ColumnType.NUMBER]: 0.75,
    [ColumnType.SEGMENT]: 0.6,
    [ColumnType.PRODUCT_DETAILS]: 2.5,
}
const spacing = 1;

const getRowPrice = (row: any) => {
    const col = row.columns.find(col => col.type === ColumnType.INPUT);
    return col?.description;
}

export const ShowProductsTableData: React.FC<TablesFormProps> = ({ tableSliceProperties, handleChangeInput, handleChangeSelect, handleSwitch, handleDelete, handleChangeRemark, handleChangeCounterValue, handleChangeAdditionalRequirements, selectItems, disableSelect = false, fallbackSelectItem, isSendToBuyerRequested, hideRequested, disableEditRequirements = false }) => {
    const [productToPreview, setProductToPreview] = React.useState<null | Product>(null)

    if (tableSliceProperties.data.isFilteredDataError) {
        return <ErrorWithRefetchButton
            text={tableSliceProperties.data.filteredDataErrorMessage}
            onRefetch={() => tableSliceProperties.actions.applyFilters()}
            height={"300px"}
        />
    }
    if (tableSliceProperties.data.isFilteredDataLoading) {
        return <Loading fullHeight={true} />
    }

    const columns = React.useMemo(() => {
        return tableSliceProperties?.data?.tableData?.rows?.[0]?.columns ?? [];
    }, [tableSliceProperties])
    return (
        <>
            <DescPopup big open={productToPreview !== null} handleClose={() => setProductToPreview(null)} paperSx={{ borderRadius: 0 }} >
                {productToPreview && <ProductQuickView product={productToPreview} />}
            </DescPopup>
            {
                columns.length > 0 && <Grid container md={12} sx={{ backgroundColor: '#fff', borderRadius: '8px', p: 1, my: 1.5 }}>
                    {columns.map((col: any) => {
                        const shouldHideColumn = (col.type === ColumnType.NUMBER && col.title === 'requested' && hideRequested)

                        if(shouldHideColumn) {
                            return null;
                        }
                        
                        let columnTitle = col.title;
                        if(columnTitle === 'available' && hideRequested) {
                            columnTitle = 'quantity'
                        }

                        return (<Grid key={`col-${col.name}-${col.type}`} item md={COL_TYPE_SIZE[col.type]} pr={spacing}>
                            {columnTitle ? 
                                <Typography fontWeight={400} color='#8B8D98' fontSize={12} textTransform={'uppercase'}>{t('texts.' + columnTitle)}</Typography>
                                : null
                            }
                        </Grid>)
                    })}
                </Grid>
            }
            {
                tableSliceProperties.data.tableData?.rows.length != 0 ? tableSliceProperties.data.tableData?.rows?.map((row: any, index: number) => {
                    const isPriceMissing = isSendToBuyerRequested && !getRowPrice(row);

                    return (
                        <Grid key={row.id} container md={12} p={1} mb={1} sx={{ position: 'relative', backgroundColor: '#fff', borderRadius: '8px', border: isPriceMissing ? '1px solid red' : 'none' }}>
                            {isPriceMissing && <ErrorOutlineIcon sx={{ position: 'absolute', top: '50%', right: '0', transform: 'translate(130%, -50%)' }} color='error' />}
                            <Grid container alignItems={'center'} md={12}>
                                {row?.columns?.map((col: any) =>
                                    <React.Fragment key={col.name}>
                                        {col.type === ColumnType.PRODUCT_DETAILS && (
                                            <Grid item md={COL_TYPE_SIZE[ColumnType.PRODUCT_DETAILS]} onClick={(event) => event.stopPropagation()} pr={spacing}>
                                                <div style={{ display: 'flex' }}>
                                                    <Typography
                                                        fontSize={14}
                                                        fontWeight='700'
                                                        color='#3A3E50'
                                                        noWrap
                                                        className={'underline-on-hover cursor-pointer'}
                                                        onClick={() => setProductToPreview(row.referenceObject.product)}
                                                    >
                                                        {row.referenceObject.product.name}
                                                    </Typography>
                                                    {row.referenceObject.product?.is_private_product && (
                                                        <PrivacyButton
                                                            disableText
                                                        />
                                                    )}
                                                </div>
                                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                                    <DescriptionIndicator
                                                        description={row.referenceObject.product.description}
                                                        category_kind={row.referenceObject.product.category_kind}
                                                    />
                                                    {row.referenceObject.product.category_kind === ProductCategoryKind.PRODUCT ? (
                                                        <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
                                                            {row.referenceObject.product.part_number}
                                                        </Typography>
                                                    ) : (
                                                        <BuyerRequirementsIndicator additional_requirements={row.referenceObject.additional_requirements} editable={!disableEditRequirements} onSave={(newRequirements, popupUtils) => { handleChangeAdditionalRequirements?.(row.id, newRequirements); popupUtils?.handleClose(); }} />
                                                    )}
                                                </div>
                                            </Grid>
                                        )}
                                        {col.type === ColumnType.SEGMENT && <Grid md={COL_TYPE_SIZE[ColumnType.SEGMENT]} item onClick={(event) => event.stopPropagation()} pr={spacing}>
                                            <Typography color="#3A3E50" fontSize={11} fontWeight={400} textTransform={'uppercase'}>{ProductCategoryKindLabel[col.description]}</Typography>    
                                        </Grid>}
                                        {col.type == ColumnType.DELETE && <Grid item container md={COL_TYPE_SIZE[ColumnType.DELETE]} justifyContent='end' onClick={(event) => event.stopPropagation()} pr={spacing}>
                                            <Box onClick={() => handleDelete(row.id)} p={0.5} sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', borderRadius: "4px" }}>
                                                {/* <DeleteIconSmall sx={{ width: "22px", height: "22px" }} /> */}
                                                <Delete fontSize="small"/>
                                            </Box>
                                        </Grid>}
                                        {col.type == ColumnType.COUNTER && <Grid item container md={COL_TYPE_SIZE[ColumnType.COUNTER]} pr={2} onClick={(event) => event.stopPropagation()}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }} width="100%">
                                                <TextFieldCounter
                                                    value={col.description}
                                                    changeQuantity={(value: string) => handleChangeCounterValue(row.id, value)}
                                                />
                                            </Box>
                                        </Grid>}
                                        {col.type == ColumnType.REMARK && <Grid container item md={COL_TYPE_SIZE[ColumnType.REMARK]} pr={spacing} alignItems='center' onClick={(event) => event.stopPropagation()} >
                                            <Remark row={row} col={col} handleChangeRemark={handleChangeRemark} />
                                        </Grid>}
                                        {col.type == ColumnType.SELECT && <Grid item md={COL_TYPE_SIZE[ColumnType.SELECT]} alignItems='center' onClick={(event) => event.stopPropagation()} pr={spacing}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', pb: 0.5, position: "relative" }} width="70%">
                                                {!disableSelect ? <StyledSelect
                                                    items={selectItems ?? []}
                                                    handleChange={(value: string) => handleChangeSelect(row.id, value)}
                                                    value={col.description ?? fallbackSelectItem}
                                                    placeholder={col.title}
                                                /> : <Typography variant='subtitle2' fontWeight={400}>{selectItems?.find(it => it.id == col.description)?.name}</Typography>}
                                                {col.secondary_description && <span style={{ color: '#91A7B6', fontSize: 13, fontWeight: 400, position: "absolute", bottom: 0, transform: "translateY(90%)", lineHeight: 1 }}><InfoOutlined sx={{ fontSize: 15, marginBottom: -0.35, marginRight: 0.5 }} />{col.secondary_description} </span>}
                                            </Box>
                                        </Grid>}
                                        {col.type == ColumnType.INPUT && <Grid container item md={COL_TYPE_SIZE[ColumnType.INPUT]} pr={spacing} gap={1} alignItems='center' onClick={(event) => event.stopPropagation()} >
                                            <Grid item md={5}>
                                                <EditableTextField
                                                    handleChange={(value: string) => handleChangeInput(row.id, value)}
                                                    value={col.description ?? ''}
                                                    placeholder={t(getInputsTranslationKey('placeholders.' + col.title))}
                                                    type='number'
                                                />
                                            </Grid>
                                            {col.title && <Grid item md={3}>
                                                <Typography variant='subtitle2'>{t(getInputsTranslationKey('labels.' + col.title))}</Typography>
                                            </Grid>}
                                        </Grid>}
                                        {col.type == ColumnType.SWITCH && <Grid item md={COL_TYPE_SIZE[ColumnType.SWITCH]} container onClick={(event) => event.stopPropagation()} pr={spacing}>
                                            <Switch
                                                color="success"
                                                onChange={() => handleSwitch(row.id, col.description)}
                                                checked={col.description} />
                                        </Grid>}
                                        {col.type == ColumnType.IMAGE && <Grid item md={COL_TYPE_SIZE[ColumnType.IMAGE]} container sx={{ alignItems: 'center' }} pr={spacing}>
                                            <Box height='50px' width='50px'>
                                                <img src={col.description} height={'50px'} width={'100%'} style={{ objectFit: 'contain' }} />
                                            </Box>
                                        </Grid>}
                                        {col.type == 'long' && <Grid item md={COL_TYPE_SIZE["long"]} pr={spacing}>
                                            <Typography variant='subtitle2' fontWeight={400}>  {col.description}  </Typography>
                                            {col.secondary_description && <span style={{ color: '#91A7B6', fontSize: 12, fontWeight: 400 }}>{col.secondary_description} </span>}
                                        </Grid>}
                                        {col.type === ColumnType.STRING && <Grid item md={COL_TYPE_SIZE[ColumnType.STRING]} pr={spacing}>
                                            <Typography variant='subtitle2' fontWeight={400}>
                                                {col.description} {col.name == "number_of_sellers" ? t('texts.seller') : null}
                                            </Typography>
                                        </Grid>}
                                        {col.type === ColumnType.NUMBER && col.title === 'requested' && !hideRequested && (
                                            <Grid item md={COL_TYPE_SIZE[ColumnType.NUMBER]} pr={spacing}>
                                                <Typography variant='subtitle2' fontWeight={400} textAlign={'center'}>
                                                    {col.description}
                                                </Typography>
                                            </Grid>
                                        )}
    
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid >
                    )
                }) : <Grid container md={12} justifyContent={'center'} alignItems={'center'} pt={15}>No result</Grid>
            }
        </>
    )
}

const Remark: FC<{ row: any, col: any, handleChangeRemark: (id: string, value: string) => void }> = ({ row, col, handleChangeRemark }) => {
    const [showAddRemarkPopup, setShowAddRemarkPopup] = useState(false)
    const openAddRemarkPopup = () => setShowAddRemarkPopup(true)
    const closeAddRemarkPopup = () => setShowAddRemarkPopup(false)

    const removeRemark = () => handleChangeRemark(row.id, '')
    const editRemark = (value: string) => handleChangeRemark(row.id, value)

    return (

        <>
            {
                col.description ? <RemarkButton remark={col.description} contentDetails={{ productDetails: { ...row.referenceObject } }} deleteRemarkHandler={removeRemark} handleRemarkChange={editRemark} />
                    : <>
                        <Button onClick={openAddRemarkPopup} >{t('buttons.add_remark')}</Button>
                        <DescPopup open={showAddRemarkPopup} handleClose={closeAddRemarkPopup}>
                            <RemarkPopupContent updateRemarkOnProduct={editRemark} />
                        </DescPopup>
                    </>
            }
        </>
    )
}