import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { company, TextDataView } from '@/components';
import { getAccountTranslationKey, getInputsTranslationKey } from '@/hooks';

type Props = { companyData: company };

export const CompanySettingsSection: React.FC<Props> = ({
  companyData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item container xs={12}>
        <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>{t(getAccountTranslationKey('sections_titles.company_settings'))}</Typography>
      </Grid>
      <Grid item xs={12} >
        <hr />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.name'))} value={companyData.name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.industry'))} value={companyData.industry.name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.country'))} value={companyData.country.name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.city'))} value={companyData.city.name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.address'))} value={companyData.company_address} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.trn'))} value={companyData.trn_number} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.fax'))} value={companyData.fax_number} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.company.phone'))} value={companyData.work_number} />
      </Grid>
    </>
  );
};
