import { GeneralObject, QuoteDetails, QuoteProductTableRow, TableMapperType } from '@/models';
import { useSellerProcurementStore } from '@/zustand';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import {
  BuyerQuoteProductsTable,
  FormProductsTable,
  ShowProductsTableData,
  TotalSection,
} from 'src/ui/components/dumb';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import GeneralTerms from 'src/ui/components/smart/general-terms/GeneralTerms';

type QuoteSellerDetailsProps = {
  quote: QuoteDetails;
  productsTableRows: QuoteProductTableRow[] | undefined;
  changeProject: (project: GeneralObject) => void;
  editing?: boolean;
  isAllProductsSelected: boolean;
  summaryDetails?: any;
  numberOfSelectedProducts?: number;
  onAddProducts?: () => void;
  isSendToBuyerRequested: boolean;
  isSendForApprovalRequested: boolean;
};

export const QuoteSellerDetails: FC<QuoteSellerDetailsProps> = ({
  quote,
  productsTableRows,
  editing,
  summaryDetails,
  changeProject,
  isAllProductsSelected,
  numberOfSelectedProducts,
  onAddProducts,
  isSendToBuyerRequested,
  isSendForApprovalRequested,
}) => {
  const { t } = useTranslation();
  const quoteModel = quote?.quoteModel;
  const { productsTableSliceProperties } = useSellerProcurementStore();
  const { quoteDetailsSliceActions, quoteDetailsSliceProperties } = useSellerProcurementStore();
  const handlePriceChange = (id: string, price: string) => {
    productsTableSliceProperties.actions.changePrice(quoteModel?.id, id, price);
  };
  const handleChangeAdditionalRequirements = (id: string, newRequirements: string) => {
    productsTableSliceProperties.actions.changeAdditionalRequirements(quoteModel?.id, id, newRequirements)
  }
  const handleQuantityChange = (id: string, quantity: number) => {
    productsTableSliceProperties.actions.changeQuantity(quoteModel?.id, id, quantity);
  };
  const handleWarrantyTermChange = (id: string, warranty_term: number) => {
    const warrantyTerm = quoteDetailsSliceProperties.terms.warranty_terms.find(term => term.id == warranty_term);
    productsTableSliceProperties.actions.changeWarrantyTerm(
      quoteModel?.id,
      id,
      warrantyTerm,
      warrantyTerm.id != quoteDetailsSliceProperties.warranty_term?.id
    );
  };
  const handleDeleteProduct = (id: string) => {
    productsTableSliceProperties.actions.deleteProduct(quoteModel?.id, id);
  };
  const handleSelectGeneralTerms = (values: any) => quoteDetailsSliceActions.selectGeneralTerms(values);
  const handleAddRemarkAction = (id: string, remark: string) => {
    productsTableSliceProperties.actions.changeRemakrs(quoteModel?.id, id, remark);
  };

  useEffect(() => {
    productsTableSliceProperties.actions.loadData(
      TableMapperType.quote_seller_products,
      quoteDetailsSliceProperties.products
    );
  }, []);

  const isCreatedFromScratch = quote.quoteModel?.created_from_scratch;

  return (
    <Grid container>
      {isCreatedFromScratch && quoteDetailsSliceProperties.terms && quote?.canEditProducts ? (
        <GeneralTerms
          mode='editable'
          terms={quoteDetailsSliceProperties.terms}
          isTermsValid={quoteDetailsSliceProperties.isTermsValid}
          forceValidateTerms={isSendToBuyerRequested || isSendForApprovalRequested}
          warranty_term={quoteModel?.terms_bundle?.warranty_term}
          payment_term={quoteModel?.terms_bundle?.payment_term}
          delivery_term={quoteModel?.terms_bundle?.delivery_term}
          handleSelectGeneralTerms={handleSelectGeneralTerms}
        />
      ) : (
        <GeneralTerms
          mode='view-only'
          warranty_term={quoteModel?.terms_bundle?.warranty_term}
          payment_term={quoteModel?.terms_bundle?.payment_term}
          delivery_term={quoteModel?.terms_bundle?.delivery_term}
        />
      )}

      <Grid container justifyContent={'space-between'} alignItems={'center'} mt={2} mb={1}>
        <Grid item>
          <Typography variant='overline' fontWeight={700} m={1}>
            Products / Services <PrimaryBadge>{quote?.numberOfProducts}</PrimaryBadge>
          </Typography>
        </Grid>
        {numberOfSelectedProducts != 0 && editing && (
          <Grid item>
            <Typography variant='body2' fontWeight={400} color={'text.third'}>
              {numberOfSelectedProducts == 1
                ? numberOfSelectedProducts + ' ' + 'Product' + ' ' + t('texts.selected')
                : numberOfSelectedProducts + ' ' + t('texts.products') + ' ' + t('texts.selected')}
            </Typography>
          </Grid>
        )}
        {quote?.canAddProducts && (
          <Grid item>
              <Button variant='contained' onClick={() => onAddProducts?.()}>
                Add Products / Services
              </Button>
          </Grid>
        )}
      </Grid>

      {quote?.numberOfProducts != 0 && (
        <Grid item container md={12}>
          <Box width={'100%'}>
            {editing ? (
              <>
                <FieldArray name='products' key={productsTableRows + numberOfSelectedProducts}>
                  {arrayHelpers => (
                    <BuyerQuoteProductsTable
                      name='products'
                      handleAdd={arrayHelpers.push}
                      handleRemove={arrayHelpers.remove}
                    />
                  )}
                </FieldArray>
                <Grid item container md={12} mt={3}>
                  <TotalSection
                    title={t('texts.order_summary')}
                    position={'end'}
                    subtotalValue={summaryDetails.SummarySubtotal}
                    vatValue={summaryDetails.SummaryVat}
                    totalValue={summaryDetails.SummaryTotal}
                  />
                </Grid>
              </>
            ) : quote?.canEditProducts ? (
              <>
                <ShowProductsTableData
                  isSendToBuyerRequested={isSendToBuyerRequested}
                  disableSelect={!isCreatedFromScratch}
                  handleChangeRemark={handleAddRemarkAction}
                  selectItems={quoteDetailsSliceProperties?.terms?.warranty_terms}
                  handleChangeSelect={handleWarrantyTermChange}
                  // fallbackSelectItem={quoteDetailsSliceProperties?.warranty_term?.id}
                  fallbackSelectItem={quoteModel?.terms_bundle?.warranty_term?.id || ''}
                  tableSliceProperties={productsTableSliceProperties}
                  handleChangeCounterValue={handleQuantityChange}
                  handleChangeAdditionalRequirements={handleChangeAdditionalRequirements}
                  handleChangeInput={handlePriceChange}
                  handleDelete={handleDeleteProduct}
                  hideRequested={isCreatedFromScratch}
                  disableEditRequirements={!isCreatedFromScratch}
                />

                <Grid item container md={12} mt={3}>
                  <TotalSection
                    title={t('texts.order_summary')}
                    position={'end'}
                    subtotalValue={productsTableSliceProperties.data.summaryDetails?.SummarySubtotal}
                    vatValue={productsTableSliceProperties.data.summaryDetails?.SummaryVat}
                    totalValue={productsTableSliceProperties.data.summaryDetails?.SummaryTotal}
                  />
                </Grid>
              </>
            ) : (
              <>
                <FormProductsTable
                  // hideAvailable={isCreatedFromScratch}
                  hideRequested={isCreatedFromScratch}
                  key={quoteModel?.name + productsTableRows?.length}
                  rowsData={productsTableRows}
                  formDetails={quoteModel}
                  products={quoteModel?.quote_products}
                />
                <Grid item container md={12} mt={3}>
                  <TotalSection
                    disableFormat
                    title={t('texts.order_summary')}
                    position={'end'}
                    subtotalValue={quoteModel?.subtotal_value}
                    vatValue={quoteModel?.vat_value}
                    totalValue={quoteModel?.total_value}
                  />
                </Grid>
              </>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
