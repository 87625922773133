import { ResponsiveContainer } from '@/styles';
import React from 'react';
import { Grid } from '@mui/material';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';
import { getFooterTranslationKey } from '@/hooks';

interface Props {}

export const BottomNavbar: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { publicRuntimeConfig } = getConfig();
  return (
    <ResponsiveContainer
      sx={{
        py: 1,
        typography: 'body1',
        color: 'info.main',
        fontSize: '14px',
        fontWeight: '400',
      }}
    >
      <Grid container>
        <Grid item lg={8} xs={8}>
          {new Date().getFullYear()}  {t(getFooterTranslationKey('titles.rights_reserved'))}
        </Grid>
        <Grid
          lg={4}
          xs={4}
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          V {publicRuntimeConfig}
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};
