// import React from 'react';
// import { useDebounce } from '@/hooks';
// import Add from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';

// type IProps = {
//   initialValue: number;
//   onChange: (newValue: number) => void;
// };

// const buttonStyles: React.CSSProperties = {
//   aspectRatio: '1',
//   borderRadius: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: '#E8E8EA',
//   border: 'none',
//   cursor: 'pointer',
// };

// const QuantityCounter = ({ initialValue, onChange }: IProps) => {
//   const [quantityValue, setQuantityValue] =
//     React.useState<number>(initialValue);
//   useDebounce(() => onChange(quantityValue), 700, [quantityValue]);

//   const handleDecrease = () => {
//     setQuantityValue(prev => Math.max(1, prev - 1));
//   };
//   const handleIncrease = () => {
//     setQuantityValue(prev => prev + 1);
//   };

//   return (
//     <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
//       <button onClick={handleDecrease} style={buttonStyles}>
//         <RemoveIcon fontSize='small' sx={{ fontSize: '12px' }} />
//       </button>
//       <span style={{ color: '#3A3E50', fontWeight: 400, fontSize: '13px' }}>
//         {quantityValue}
//       </span>
//       <button onClick={handleIncrease} style={buttonStyles}>
//         <Add fontSize='small' sx={{ fontSize: '12px' }} />
//       </button>
//     </div>
//   );
// };

// export default QuantityCounter;

import React from 'react';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type IProps = {
  initialValue: number;
  onChange: (newValue: number) => void;
};

const buttonStyles: React.CSSProperties = {
  aspectRatio: '1',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E8E8EA',
  border: 'none',
  cursor: 'pointer',
  padding: '4px',
};

const QuantityCounter = ({ initialValue, onChange }: IProps) => {
  const handleDecrease = () => {
    onChange(Math.max(1, initialValue - 1));
  };
  const handleIncrease = () => {
    onChange(initialValue + 1);
  };

  return (
    <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
      <button onClick={handleDecrease} style={buttonStyles}>
        <RemoveIcon
          fontSize='small'
          sx={{ fontSize: '12px', color: '#13628C' }}
        />
      </button>
      <span style={{ color: '#3A3E50', fontWeight: 400, fontSize: '13px' }}>
        {initialValue}
      </span>
      <button onClick={handleIncrease} style={buttonStyles}>
        <Add fontSize='small' sx={{ fontSize: '12px', color: '#13628C' }} />
      </button>
    </div>
  );
};

export default QuantityCounter;
