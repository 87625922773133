import React, { FC, useState } from "react";
import { Button, Grid, ThemeProvider, createTheme } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import moment from 'moment';
import * as yup from 'yup';
import { ProcurementButtons } from "@/config";
import { StyledLoadingButton } from "src/ui/components/shared";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from "@mui/lab";

interface EditValidityDatePopupProps {
    dateForm: string
    onEditExpiryDate: (validityDate: string) => void
    customLoadingButton?: ProcurementButtons | null
    isFormExpired?: boolean
}



export const EditValidityDatePopup: FC<EditValidityDatePopupProps> = ({ dateForm, onEditExpiryDate, customLoadingButton, isFormExpired }) => {
    const defaultDate = isFormExpired ? new Date() : moment(dateForm, "DD-MM-YYYY").toDate();

    if(isFormExpired) {
        defaultDate.setDate(defaultDate.getDate() + 7);
    } else {
        defaultDate.setDate(defaultDate.getDate());
    }

    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const handleDateChange = (date) => setSelectedDate(date)
    const formattedDate = moment(selectedDate).format("DD-MM-YYYY");

    const handleReset = () => {
        let resetResult = isFormExpired ? new Date() : moment(dateForm, "DD-MM-YYYY").toDate();
        resetResult.setDate(resetResult.getDate() + 7);
        setSelectedDate(resetResult);
    }

    const handleSubmit = (values, actions) => {
        values.validity_date = formattedDate
        if (values.validity_date !== null && moment(values.validity_date, "DD-MM-YYYY").isValid()) {
            onEditExpiryDate(values.validity_date)
        }
    }
    const validityDateValidationSchema = yup.object({ validity_date: yup.date() });

    return (
        <>
            {dateForm && <Grid item container justifyContent={'start'} md={12} px={2}>
                <Formik initialValues={{ validity_date: formattedDate }} validationSchema={validityDateValidationSchema} onSubmit={handleSubmit} validateOnBlur={true} validateOnChange={true}  >
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <Grid item md={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <div style={{ width: '100%' }}>
                                        <StaticDatePicker renderInput={() => <></>} value={selectedDate} onChange={(newValue) => handleDateChange(newValue)}
                                            showToolbar={false}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: [],
                                                },
                                            }}
                                            disablePast
                                        />
                                    </div>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item justifyContent={'start'} md={12} pb={1} mt={-5}>
                                <StyledLoadingButton variant="contained" translateKey="save" disabled={!(formattedDate !== null && moment(formattedDate, "DD-MM-YYYY").isValid())} loading={customLoadingButton == ProcurementButtons.SAVE_EXPIRY_DATE} onClick={handleSubmit} />
                                <Button sx={{ mt: 0.5 }} fullWidth variant='outlined' onClick={handleReset}>{t("buttons.reset_to_default")}</Button>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>}
        </>
    )
}

