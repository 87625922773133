import React from 'react';
import { Typography } from '@mui/material';

export const Label = ({ text }: { text: string }) => {
  return (
    <Typography fontSize={12} color={'#C5C6CB'} fontWeight={400}>
      {text}
    </Typography>
  );
};
export const Value = ({ text }: { text: string }) => {
  return (
    <Typography fontSize={15} color={'#3A3E50'} fontWeight={400}>
      {text}
    </Typography>
  );
};
