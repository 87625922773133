import { SvgIconProps, SvgIcon } from '@mui/material';

export const UserIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.33464 21.3333H22.668C26.3499 21.3333 29.3346 24.3181 29.3346 28C29.3346 28.7363 28.7377 29.3333 28.0013 29.3333C27.3175 29.3333 26.754 28.8186 26.6769 28.1555L26.6612 27.7649C26.544 25.743 24.925 24.1239 22.903 24.0067L22.668 24H9.33464C7.1255 24 5.33464 25.7908 5.33464 28C5.33464 28.7363 4.73768 29.3333 4.0013 29.3333C3.26492 29.3333 2.66797 28.7363 2.66797 28C2.66797 24.415 5.49771 21.4909 9.04545 21.3395L9.33464 21.3333H22.668H9.33464ZM16.0013 2.66663C20.4196 2.66663 24.0013 6.24835 24.0013 10.6666C24.0013 15.0849 20.4196 18.6666 16.0013 18.6666C11.583 18.6666 8.0013 15.0849 8.0013 10.6666C8.0013 6.24835 11.583 2.66663 16.0013 2.66663ZM16.0013 5.33329C13.0558 5.33329 10.668 7.72111 10.668 10.6666C10.668 13.6121 13.0558 16 16.0013 16C18.9468 16 21.3346 13.6121 21.3346 10.6666C21.3346 7.72111 18.9468 5.33329 16.0013 5.33329Z'
      fill={props.color}
    />
  </SvgIcon>
);
