import { AuthenticateStatus, RouteProtectionRule, UserType } from "@/config";
import { useUserData } from "src/logic/zustand/user";
import { sm } from "src/site-map";
export const useRouteRedirect = (rule: RouteProtectionRule) => {

    const companyInfo = useUserData(state => state.companyInfo);
    const authenticationStatus = useUserData(state => state.authenticationStatus);
    const getRedirectedUrl=()=>{
        if(rule.authenticationStatus == AuthenticateStatus.AUTHENTICATED && authenticationStatus==AuthenticateStatus.NOT_AUTHENTICATED)
            return sm.login.url;
        else if(authenticationStatus==AuthenticateStatus.AUTHENTICATED)
            if(companyInfo.companyType == UserType.SELLER)
                return sm.portals.seller.dashboard.url;
            else if(companyInfo.companyType == UserType.BUYER)
                return sm.portals.buyer.dashboard.url;
            else return sm.home.url
        else return sm.home.url
    }
    return getRedirectedUrl();
};
