import { LoadingButton } from '@mui/lab';
import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import usePopup, { PopupUtils } from 'src/logic/hooks/use-popup';
import { DescPopup } from 'src/ui/components/shared';
import styles from './BuyerRequirementsIndicator.module.css';

type ViewOnlyBuyerRequirementsIndicatorType = {
  additional_requirements: string | null | undefined;
  editable?: false;
  onSave?: undefined;
  loading?: undefined;
  textColor?: string;
};

type EditableBuyerRequirementsIndicatorType = {
  additional_requirements: string | null | undefined;
  editable: true;
  onSave: (newRequirements: string, popupUtils?: PopupUtils) => void;
  loading?: boolean;
  textColor?: string;
};

type BuyerRequirementsIndicatorType = ViewOnlyBuyerRequirementsIndicatorType | EditableBuyerRequirementsIndicatorType;

const EditRequirementsPopupContent = ({
  initialValue,
  onSave,
  loading,
}: {
  initialValue: string;
  loading?: boolean;
  onSave: (newRequirements: string) => void;
}) => {
  const [requirementsValue, setRequirementsValue] = React.useState<string>(initialValue);

  return (
    <div style={{ padding: '1rem', width: '100%' }}>
      <Typography textAlign={'center'} color='#13628C' fontSize={18} fontWeight={700} mb={2}>
        Edit Buyer Requirements
      </Typography>

      <Typography variant='subtitle2' component='label' fontWeight={400} sx={{ color: 'text.third' }}>
        Buyer Requirements
      </Typography>
      <TextField
        autoFocus
        rows={5}
        multiline
        variant='outlined'
        fullWidth
        placeholder={'Buyer Requirements'}
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        style={{ overflow: 'auto' }}
        InputProps={{
          sx: {
            minHeight: 120,
            fontSize: '14px',
            color: '#3A3E50',
            fontWeight: 400,
            '& ::placeholder': {
              fontSize: '12px',
            },
          },
        }}
        value={requirementsValue}
        onChange={e => setRequirementsValue(e.target.value)}
      />

      <LoadingButton
        type='button'
        disabled={!requirementsValue}
        variant='contained'
        color='success'
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => onSave(requirementsValue)}
        loading={loading}
      >
        Save
      </LoadingButton>
    </div>
  );
};

const BuyerRequirementsIndicator = ({
  additional_requirements,
  editable,
  onSave,
  loading,
  textColor,
}: BuyerRequirementsIndicatorType) => {
  const editRequirementsPopup = usePopup();

  return (
    <div className={styles.description_container}>
      <Typography fontSize={13} fontWeight={400} color={textColor ?? '#8B8D98'}>
        Buyer Requirements
      </Typography>
      <div className={styles.description_popover}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color={'#3A3E50'} fontWeight={700} fontSize={17}>
            Buyer Requirements
          </Typography>
          {editable && (
            <Button
              type='button'
              variant='text'
              onClick={editRequirementsPopup.handleOpen}
              sx={{ height: 'auto', minWidth: 'auto', px: 1, fontWeight: 700 }}
              color='primary'
            >
              Edit
            </Button>
          )}
        </div>
        <Typography color='#3A3E50' fontWeight={400} fontSize={13}>
          {additional_requirements || 'N/A'}
        </Typography>
      </div>
      {editable && (
        <DescPopup open={editRequirementsPopup.isOpen} handleClose={editRequirementsPopup.handleClose}>
          <EditRequirementsPopupContent
            initialValue={additional_requirements ?? ''}
            onSave={newRequirements => onSave(newRequirements, editRequirementsPopup)}
            loading={loading}
          />
        </DescPopup>
      )}
    </div>
  );
};

export default BuyerRequirementsIndicator;
