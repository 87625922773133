import * as yup from 'yup';

const validationSchema = [
    yup.object({
        purchase_order_id: yup.string().required('required')
    }),
    yup.object({
        price: yup.string().required('required')
    })
];

export default validationSchema