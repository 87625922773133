import React from 'react';

const containerStyles: React.CSSProperties = {
  borderRadius: '4px',
  backgroundColor: '#EAEEF6',
  color: '#13628C',
  fontWeight: 400,
  fontSize: '14px',

  minHeight: '24px',
  minWidth: '20px',
  paddingInline: '2px',

  marginBlock: 'auto',
  marginInlineStart: '1em',

  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const PrimaryBadge = (props: React.ComponentProps<'span'>) => {
  return (
    <span {...props} style={props.style ? { ...containerStyles, ...props.style } : containerStyles}>
      {props.children}
    </span>
  );
};

export default PrimaryBadge;
