import React from 'react';
import { useLinkOrCreateSellersSlice } from 'src/logic/zustand/catalouge/link-create-sellers/LinkOrCreateSellersSlice';

export const useCountriesSelectionsAsOptions = (selectedCountry: number | string | '') => {
  const { locations } = useLinkOrCreateSellersSlice(state => state.properties);
  return React.useMemo(() => {
    if (locations === undefined) return { countries: [], cities: [] };
    return {
      countries: locations?.map(({ id, name }) => ({ id, name })),
      cities: selectedCountry === '' ? [] : locations?.find(country => country.id === selectedCountry)?.cities,
    };
  }, [locations, selectedCountry]);
};
