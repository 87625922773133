import { apiRoutes } from '@/config';
import { request } from '@/helpers';
import { Quote } from '@/models';
import { useQuery } from '@tanstack/react-query';

export const useGetEditableQuotes = () => {
  return useQuery<Quote[]>({
    queryKey: ['EDITABLE_QUOTES'],
    queryFn: async () => {
      const response = await request({
        url: apiRoutes.indexEditableQuotes,
        method: 'GET',
      });
      return response.data;
    },
    retry: 0,
  });
};
