import {
  Box,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Snackbar,
  Button,
} from '@mui/material';
import { useRequisition } from '@/zustand';
import { Requisition } from '@/models';
import CheckIcon from '@mui/icons-material/Check';
import { DownloadButton } from '@/components';
import React, { useState, useEffect } from 'react';

type Props = { requisition: Requisition; isSmallScreen: boolean };

export const ReqHeader: React.FC<Props> = ({ requisition, isSmallScreen }) => {
  const changeReqName = useRequisition(state => state.changeReqName);
  const [search, setSearch] = useState(
    requisition.reqName ? requisition.reqName : ''
  );
  const [wait, setwait] = useState(false);
  const [waitEvent, setWaitEvent] =
    useState<React.ChangeEvent<HTMLInputElement>>(null);

  useEffect(() => {
    let timer = 0;
    if (waitEvent) {
      timer = setTimeout(() => {
        changeReqName(requisition.id, waitEvent.target.value), setwait(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [waitEvent]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setwait(true);
    event.persist();
    setWaitEvent(event);
    setSearch(event.target.value);
  };
  return (
    <Grid container>
      <Grid container item lg={7} md={7} xs={12} direction='row'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: isSmallScreen ? '100%' : '300px',
          }}
        >
          <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='body1'
            sx={{ display: 'block', width: '100%' }}
          >
            <Grid container>
              <Grid item xs={6} md={6}>
                Requisition Name
              </Grid>
              {isSmallScreen && (
                <Grid
                  item
                  xs={6}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                  }}
                >
                  <SavingIndicator wait={wait} isSmallScreen={isSmallScreen} />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <TextField
                placeholder='Enter Requisition Name'
                onChange={onChange}
                value={search}
                InputProps={{
                  sx: {
                    bgcolor: 'secondary.contrastText',
                    width: isSmallScreen ? '100%' : '300px',
                    height: '40px',
                    borderColor: 'divider',
                    color: '#000000',
                    '& ::placeholder': {
                      fontSize: '14px',
                      color: '#000000',
                      fontWeight: '500',
                    },
                  },
                }}
                sx={{
                  flex: 1,
                  height: '40px',
                  marginBottom: 1,
                  textOverflow: 'ellipsis !important',
                }}
              />
            </Grid>
          </Typography>
        </Box>
        {!isSmallScreen && (
          <Box
            sx={{
              alignItems: 'end',
              display: 'flex',
              height: '60px',
              flexDirection: 'row',
            }}
          >
            <SavingIndicator wait={wait} isSmallScreen={isSmallScreen} />
          </Box>
        )}
      </Grid>
      {!isSmallScreen && (
        <Grid
          item
          lg={5}
          md={5}
          xs={12}
          {...(!isSmallScreen && {
            sx: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: 2,
            },
          })}
        >
          <DownloadButton
            isSmallScreen={isSmallScreen}
            requisition={requisition}
          />
        </Grid>
      )}
    </Grid>
  );
};
type SavingIndicatorProps = { wait?: boolean; isSmallScreen: boolean };

const SavingIndicator: React.VFC<SavingIndicatorProps> = ({
  wait,
  isSmallScreen,
}) => {
  return (
    <>
      {!wait ? (
        <CheckIcon
          sx={{ color: '#5BBE5C', fontSize: isSmallScreen ? 25 : 30 }}
        />
      ) : (
        <CircularProgress
          size={isSmallScreen ? '25px' : '30px'}
          sx={{ color: 'text.third' }}
        />
      )}
      <Typography
        fontWeight={400}
        variant='body2'
        sx={{ color: 'text.third', marginLeft: 1 }}
      >
        {wait ? 'Saving...' : 'Saved'}
      </Typography>
    </>
  );
};
