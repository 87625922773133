import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import React, { ReactNode } from "react";
import { DescPopup } from "@/components";
import { useRouter } from "next/router";
import { sm } from "src/site-map";


type ViewBundleOrRFQsProps = {
    icon: ReactNode
    title: string
    openPopup: boolean
    handleClosePopup: () => void
    status: string
}

export const ViewBundleOrRFQs: React.FC<ViewBundleOrRFQsProps> = ({ icon, title, openPopup, handleClosePopup, status }) => {
    const { push } = useRouter()
    const { t } = useTranslation();

    const handleViewBundleRFQFromPopup = () => handleClosePopup()
    const handleViewMyRFQsFromPopup = () => {
        push({ pathname: sm.portals.buyer.procurement.requestForQuotes.index.url })
        handleClosePopup()
    }

    return (
        <DescPopup handleClose={handleClosePopup} open={openPopup}>
            <Grid container item md={12} mx={1} gap={1}>
                <Grid item container justifyContent={"center"} md={12}>
                    {icon}
                </Grid>
                <Grid item container justifyContent={"center"} md={12}>
                    <Typography variant="overline" fontWeight="700" color={status ?? 'primary'}>{title}</Typography>
                </Grid>
                <Grid item container md={12} mt={2}>
                    <Grid item md={6}>
                        <Button fullWidth variant="outlined" onClick={handleViewBundleRFQFromPopup}>{t('buttons.back_to_bundle')}</Button>
                    </Grid>
                    <Grid item md={6} pl={0.5}>
                        <Button fullWidth variant="contained" onClick={handleViewMyRFQsFromPopup}>{t('buttons.my_RFQs')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </DescPopup>
    )
}