import React from "react";
import { Grid, Box } from "@mui/material";
import { ResponsiveContainer } from "@/styles";
import { StyledBreadcrumbs } from "@/components";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ImageOnTheRightLayoutProps {
    noBreadcrumbs?: boolean,
    desktopImage: {
        src: string, 
        alt: string
    },
    breadcrumbsAlias:string
}

export const ImageOnTheRightLayout: React.FC<ImageOnTheRightLayoutProps> = (props) => {

    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    return (
        <Grid container sx={{display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row'}}>
            <Grid container xs={12} md={8} >
                <Grid item xs={12}>
                    {props.noBreadcrumbs? "" : 
                        <Grid item xs={12} component={ResponsiveContainer}>
                            <StyledBreadcrumbs alias={props.breadcrumbsAlias}/>
                        </Grid>
                    }
                    {props.children}
                </Grid>
            </Grid>
            {!isMobile ? 
            <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                    <img
                        src={props.desktopImage.src}
                        alt={props.desktopImage.alt}
                    />
                </Box>
            </Grid>
            :""}
        </Grid>
    )
}