import { apiRoutes } from "@/config"
import { request } from "@/helpers"


export const useGetAllComments = (commentType: string, commentId: string) => {
    return request({ url: apiRoutes.comment + "/" + commentType + "/" + commentId, method: 'GET' })
}


export const useCreateComment = (comment: string, commentType: string, commentId: number, isExternal: boolean) => {
    return request({ url: apiRoutes.createComment, method: 'POST', data: { comment: comment, commentable_type: commentType, commentable_id: commentId, is_external: isExternal } })
}

export const useGetCommentsNumberAndSeen = (commentType: string, commentId: string) => {
    return request({ url: apiRoutes.comment + "/" + commentType + "/" + commentId + apiRoutes.count, method: 'GET' })
}