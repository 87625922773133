import { getInputsTranslationKey } from '@/hooks';
import { IconVisibility, IconVisibilityOff } from '@/icons';
import { TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useField } from 'formik';
import { at } from 'lodash';
import { useTranslation } from 'next-i18next';
import type { ReactElement } from 'react';
import { FC, useState } from 'react';

interface StyledTextFieldProps {
  translateKey: string;
  name: string;
  showLabel?: boolean;
  type?: string;
  required?: boolean;
  iconEnd?: ReactElement;
  disabled?: boolean;
  autofocus?: boolean;
  outlined?: boolean;
  freeSolo?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlurAction?: () => void;
  disableErrorShow?: boolean;
  fullWidth?: boolean;
  disableTranslation?: boolean;
  disableHelperText?: boolean
}

const inputStyle = {
  width: '100%',
};
const textInputStyle = {
  color: '#3A3E50',
  'input::placeholder': {
    fontWeight: '400',
  },
};

const textDisabledInputStyle = {
  color: '#3A3E50',
  'input::placeholder': {
    fontWeight: '400',
  },
  opacity: 0.6,
};

export const StyledTextField: FC<StyledTextFieldProps> = (props: StyledTextFieldProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);

  function _renderHelperText() {
    if (props.disableErrorShow) return null;
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return (
        <Typography variant='subtitle2'>
          {props.disableTranslation
            ? error
            : t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
        </Typography>
      );
    }
  }

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleBlur = () => {
    if (props.onBlurAction) {
      props.onBlurAction(field.value);
    } else {
      helpers.setTouched(true);
    }
  };

  return (
    <Typography
      component='label'
      color='text.third'
      fontWeight='400'
      variant='subtitle2'
      sx={{ display: 'block', width: props.fullWidth ? '100%' : 'auto' }}
    >
      {props.showLabel &&
        (props.disableTranslation
          ? props.translateKey
          : t(getInputsTranslationKey('labels.' + props.translateKey)))}{' '}
      {props.required ? ' *' : ''}
      <TextField
        {...(props.onChange ? { onChange: props.onChange, value: field.value } : { ...field })}
        autoFocus={props.autofocus}
        {...(props.outlined && { variant: 'outlined' })}
        onBlur={handleBlur}
        InputProps={{
          sx: props.disabled ? textDisabledInputStyle : textInputStyle,
          fullWidth: props.fullWidth,
          endAdornment:
            props.type == 'password' ? (
              <>
                <InputAdornment position='end'>
                  {showPassword ? (
                    <IconVisibility
                      sx={{ fontSize: '18px', color: 'warning.active', '&:hover': { cursor: 'pointer' } }}
                      onClick={handleClickShowPassword}
                    />
                  ) : (
                    <IconVisibilityOff
                      sx={{ fontSize: '18px', color: 'warning.active', '&:hover': { cursor: 'pointer' } }}
                      onClick={handleClickShowPassword}
                    />
                  )}
                </InputAdornment>
              </>
            ) : null,
        }}
        disabled={props.disabled}
        sx={inputStyle}
        // onChange={props.onChange}
        placeholder={
          props.disableTranslation
            ? `Insert ${props.translateKey}`
            : t(getInputsTranslationKey('placeholders.' + props.translateKey))
        }
        error={meta.touched && meta.error && true}
        helperText={props.disableHelperText ? undefined : _renderHelperText()}
        type={props.type && !showPassword ? props.type : 'text'}
      />
    </Typography>
  );
};
