import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import { Badge, Tab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => {
    return ({
        tabs: {
            backgroundColor: theme.palette.background.primary.light,
            "&.MuiButtonBase-root.MuiTab-root": {
                color: theme.palette.text.third,
                fontSize: theme.typography.body2.fontSize,
                textTransform: 'capitalize',
                fontWeight: 600,
                opacity: 0.7,
            },
            "& .MuiTab-root.Mui-selected": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
                fontSize: theme.typography.body2.fontSize,
                borderRadius: '8px',
                textTransform: 'capitalize',
                fontWeight: 600,
                opacity: 0.9
            },
        }
    })
})

export const TabsWithChips = ({ tabs, initialValue, onChange }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(initialValue);

    const updateTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Tabs className={classes.tabs} value={value} onChange={(event, newValue) => { updateTab(event, newValue); onChange(newValue) }} variant='scrollable' TabIndicatorProps={{ sx: { display: 'none' } }} >
            {
                tabs.map((tab, index) =>
                    <Tab
                        disableFocusRipple
                        className={classes.tabs}
                        value={tab.name}
                        label={<TabContent tab={tab} value={value} />}
                    />
                )
            }
        </Tabs>
    );
}

const TabContent = ({ tab, value }) => {
    // const { t } = useTranslation();
    return (
        <span style={{
            cursor: 'pointer', lineHeight: 1, display: '-webkit-box', whiteSpace: 'pre-line',
            WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflowY: 'hidden', textOverflow: 'ellipsis', overflowX: 'hidden', maxWidth: 200,
        }}>{tab.name}</span>
    )
}