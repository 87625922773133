import create from 'zustand';
import axios from 'axios';
import { apiURLs } from '@/config';
import { country, company, industry } from '@/components';

export const useRegisterFormData = create((set, get) => ({
    countries: [],
    industries: [],
    companies: [],

    country: null,
    company: null,
    requestAdminRoles: true,
    upload: true,

    cities: () => {return get().country ? get().country.cities : []},
    toggleRequestAdminRoles: () => {set({requestAdminRoles: !get().requestAdminRoles})},
    toggleUpload: () => {set({upload: !get().upload})},
    setCountry: (country: country) => {set({country: country})},
    setCompany: (company: company) => {set({company: company})},
    initialize: (countries: Array<country>, industries: Array<industry>) => {set({countries: countries, industries: industries})},

    industry: () => {
      if(get().company == null) return ""
      const id = get().company.industry_id
      return get().industries.find(item => item.id  === id).name
    },
    searchCompanies: async (query: string, isBuyer: boolean) => {
      if(query != ''){
        const company_type = isBuyer? "BUYER" : "SUPPLIER"
        const response = await axios.get(apiURLs.companiesAutocomplete, {
          params: {
            query: query,
            company_type: company_type
          }
        });
        set({companies: response.data});
      }
      else
        set({companies: []})
    },
}));
