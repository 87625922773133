import { apiRoutes } from "@/config"
import { request } from "@/helpers"
import { ProcurementModelDetails, Product } from "@/models"

export const useIndexSellerCatalog = () => {
    return request({ url: apiRoutes.seller + apiRoutes.catalog + apiRoutes.index, method: "GET" })
}

export const useUpdateCatalogProduct = (productId: string, price: string, isActive: boolean) => {
    return request({ url: apiRoutes.seller + apiRoutes.catalog + apiRoutes.update + '/' + productId, method: "PUT", data: { price: price, active: isActive } })
}

export const useDeleteCatalogProduct = (productId: string) => {
    return request({ url: apiRoutes.seller + apiRoutes.catalog + apiRoutes.delete + '/' + productId, method: "DELETE" })
}


export const useGetSellerCatalogDetails = (id: string) => {
    return request({ url: apiRoutes.seller + apiRoutes.sellerDraft + apiRoutes.show + id, method: "GET" })
}



export const useCreateCatalougList = () => {
    return request({ url: apiRoutes.seller + apiRoutes.sellerDraft + apiRoutes.create, method: "POST" })
}

export const useSaveCatalougDetails = (form: ProcurementModelDetails, products: Product[]) => {
    const newProductsArray = products.map(({ id, price }) => ({ id, price }));

    return request({ url: apiRoutes.seller + apiRoutes.sellerDraft + apiRoutes.update + '/' + form.id, method: "PUT", data: { products: newProductsArray } })
}

export const usePublishSellerCatalog = (id: string) => {
    return request({ url: apiRoutes.seller + apiRoutes.sellerDraft + apiRoutes.publish + '/' + id, method: "POST" })
}


export const buildCatalogTable = (products: Product[] | null) => {
    let ProductsArray: ProcurementFormProduct[] = []
    products?.map((product: Product) => ProductsArray.push({
        ...product,
        id: product.id,
        productId: product.id,
        productDetails: {
            name: product.name,
            partNumber: product.part_number,
            image_url: product.image_url,
        },
        brandImage: product.brand.image_url,
        price: product.price,
        // category: product.category.parent.parent,
        // class: product.category.parent,
        // type: product.category,
        isActive: product.is_active ?? false
    }))
    return ProductsArray
}