import React, { useState } from 'react'
import { BasicTabs, SearchTextField } from 'src/ui/components/shared';
import { Table, TableGroup as TableGroupModel } from "@/models";
import { Badge, Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "next-i18next";
import { GeneralTable } from "./general-table";
import { styled } from "@mui/material/styles";

type TableGroupProps = {
    key: string
    title: string
    tableGroup: TableGroupModel
    detailsURLFunction: Function,
    onActionClickedFunction: Function,
    onCreateClickedFunction: Function,
    customURLBuilder: Function | undefined
    canCreate?: boolean
}

export const TableGroup: React.FC<TableGroupProps> = ({ key, title, tableGroup, detailsURLFunction, onActionClickedFunction, onCreateClickedFunction = () => { }, customURLBuilder = undefined, canCreate }) => {
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: `${theme.palette.success.dark}`,
            boxShadow: `0 0 0 1px ${theme.palette.success.dark}`
        },
    }));

    const styleButton = { fontWeight: 500, height: '32px' }
    const { t } = useTranslation();

    const [selectedTableGroup, setSelectedTableGroup] = useState(tableGroup.tables[0]);
    const [selectedTableTab, setSelectedTableTab] = useState(selectedTableGroup.tabs[0]);

    return (
        <Grid key={key} container lg={12} xs={12} p={3} width={'100%'} sx={{ bgcolor: 'white', borderRadius: '8px' }}>
            <Grid container item lg={12} xs={12} m={2} alignItems='center'>

                <Grid item lg={8.5} xs={8} >
                    <Typography variant='h2' fontWeight='700'> {title} </Typography>
                </Grid>

                <Grid item lg={2} xs={2} pr={3}>
                    <SearchTextField
                        onChange={(e) => { }}
                        //value={}
                        startAdornment
                        translateKey={"search_with_dots"}
                    />
                </Grid>

                <Grid item lg={1.5} xs={2}>
                    {canCreate && <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={onCreateClickedFunction}>  {t("Create")} </Button>}
                </Grid>

            </Grid>

            {tableGroup.shouldShowGroupTabs() && <Grid container item lg={12} xs={12} m={2} alignItems='center' justifyContent={'center'}>
                {
                    tableGroup.tables.map((element: Table) =>
                        <Button variant={element.id == selectedTableGroup.id ? 'contained' : 'outlined'}
                            style={styleButton}
                            sx={{ m: 0.1, color: element.id == selectedTableGroup.id ? 'white' : 'primary.main' }}
                            onClick={() => { setSelectedTableGroup(element); setSelectedTableTab(element.tabs[0]) }}
                        >
                            {element.title}
                            {element.hasNewChanges && <StyledBadge invisible={!element.hasNewChanges} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot" sx={{ pr: 1, pb: 1 }}></StyledBadge>}
                        </Button>
                    )
                }
            </Grid>}

            {selectedTableGroup.tabs.length > 1 && <Grid container item lg={12} xs={12} m={2} alignItems='center' justifyContent={'center'}>
                <BasicTabs
                    tabs={selectedTableGroup.tabs}
                    initialValue={selectedTableGroup.tabs[0].name}
                    onChange={(statusName) => { let tab = selectedTableGroup.tabs.filter(e => e.name == statusName)[0]; setSelectedTableTab(tab) }}
                    key={selectedTableGroup.id + '' + selectedTableGroup.tabs[0].name}
                />
            </Grid>}

            <Grid container item lg={12} xs={12}>
                <Box width={'100%'} minHeight={200}>
                    <GeneralTable
                        key={selectedTableTab.key}
                        tab={selectedTableTab}
                        detailsURLFunction={detailsURLFunction}
                        onActionClickedFunction={onActionClickedFunction}
                        customURLBuilder={customURLBuilder}
                    />
                </Box>

            </Grid>

        </Grid>
    )

}



