import type { FC } from 'react';
import { Checkbox } from "@mui/material";

interface StyledCheckboxProps {
    onChange: (value: boolean) => void
    value: boolean
    size?: 'small' | 'medium'
}

export const StyledCheckbox: FC<StyledCheckboxProps> = (props) => {

    return (
        <Checkbox
            onChange={(e) => props.onChange(e.target.checked)}
            checked={props.value}
            size={props.size ?? 'small'}
        />
    );
};

