import { SxProps } from '@mui/material';
import React from 'react';

export const companyAvatarStyles: React.CSSProperties = {
  backgroundColor: '#3A3E50',
  // backgroundColor: '#13628C',
  padding: '1.5em',
  fontWeight: 700,
};

export const containerStyles: React.CSSProperties = {
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0px 8px 32px 0px #EBEBEB',
  padding: '1.5rem',
  height: '100%',
  width: '100%',
};

export const companyNameContainerStyles: React.CSSProperties = {
  ...containerStyles,
  padding: '2rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
};

export const statsContainerStyles: React.CSSProperties = {
  ...containerStyles,
  display: 'grid',
};

export const companyTypeChip: React.CSSProperties = {
  paddingInline: '1rem',
  paddingBlock: '1em',
  backgroundColor: '#EAEEF6',
  color: '#13628C',
  fontWeight: 500,
};

export const sellersAvatarStyles:SxProps = {
  backgroundColor: '#D0E0E8',
  padding: '1.5em',
  fontWeight: 700,
  color:'#13628C' ,
  transition: 'background-color 200ms, color 200ms'
};
