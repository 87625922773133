import { useRef, useState, useLayoutEffect } from 'react';
import { useViewportScroll, useTransform, MotionValue } from 'framer-motion';
import { getOffset } from '@/utils';

type Result = [React.RefObject<HTMLDivElement>, MotionValue<number>] & {
  ref: React.RefObject<HTMLDivElement>;
  result: MotionValue<number>;
};

export const useTwoWayInViewPort = (): Result => {
  const { scrollY } = useViewportScroll();
  const [height, setHeight] = useState(0);
  const [top, setTop] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const result = useTransform(
    scrollY,
    [top - screenHeight, top + height - screenHeight, top, top + height],
    [0, 1, 1, 0]
  );

  useLayoutEffect(() => {
    const onResize = () => {
      if (!ref.current) return;
      setHeight(ref.current.offsetHeight ?? 0);
      setTop(getOffset(ref.current).top);
      setScreenHeight(window.innerHeight);
    };

    // const onScroll = () => {
    //   console.log({
    //     height,
    //     screenHeight,
    //     top,
    //     'result.get()': result.get(),
    //     'scrollY.get()': scrollY.get(),
    //   });
    // };

    onResize();
    window.addEventListener('resize', onResize);
    // window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('resize', onResize);
      // window.removeEventListener('scroll', onScroll);
    };
  }, []);

  let res: any = [ref, result] as const;
  res.ref = ref;
  res.result = result;
  return res;
};
