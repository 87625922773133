export enum Roles {
  ADMIN_BUYER = 'ADMIN_BUYER',
  ADMIN_SELLER = 'ADMIN_SELLER',
  REGULAR_BUYER = 'REGULAR_BUYER',
  REGULAR_SELLER = 'REGULAR_SELLER',
  Admin = 'Admin',
  QUOTE_APPROVER_ROLE = "QUOTE_APPROVER_ROLE",
  RFQ_APPROVER_ROLE = "rfq_approver_role",
  po_approver_role = "po_approver_role"
}
