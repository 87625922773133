
import { AutoComplete, SelectListWithChildren } from "@/components";
import { productManagerEnv, ProductManagerPopupStatus } from '@/config';
import { Product, Project, Requisition } from '@/models';
import { useProcurementStore, useUserData } from '@/zustand';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { t } from 'i18next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import { sm } from 'src/site-map';
import { createProjectSchema, createRequisitionSchema } from './validation-schema';

export const CreateRequisitionWithSelectPopup = (product: Product) => {
  const { draftRequisitions, projects, numberOfDraftRequisitions } = useProcurementStore()
  const { actions } = useProcurementStore(state => state.productFormManagerProperties)
  const handleCreate = (projectId: string, projectName: string) => actions.createForm({ projectId: projectId, projectName: projectName, product: product }, 'requisitionSliceActions', productManagerEnv.requisition, draftRequisitions)
  return (
    <Stack gap={2}>
      <Typography color="primary" variant="overline" fontWeight="700" mt={1}>{t("buttons.add_to_requisition")}</Typography>
      <Typography variant="body2" color="text.disabled">{t('texts.create_requisition')}</Typography>
      <CreateRequisitionFields createRequisition={handleCreate} projects={projects} product={product} />
      {numberOfDraftRequisitions ? <>
        {t("texts.or")}
        <Button variant="outlined" onClick={() => actions.setProductManagerPopupStatus(ProductManagerPopupStatus.SELECT_REQUISITION)}>
          {t("buttons.add_to_a_requisition")}
        </Button>
      </>
        : <></>}
    </Stack>
  )
}
export const CreateRequisitionPopup = () => {
  const { t } = useTranslation();
  const { createRequisition, projects } = useProcurementStore()
  return (
    <Stack gap={2}>
      <Typography color="primary" variant="overline" fontWeight="700" mt={1}>{t("buttons.create_requisition")}</Typography>
      <Typography variant="body2" color="text.disabled">{t('texts.create_requisition')}</Typography>
      <CreateRequisitionFields createRequisition={createRequisition} projects={projects} />
    </Stack>
  )
}


const CreateRequisitionFields: React.VFC<{ createRequisition: any, projects: Project[], product?: Product }> = ({
  createRequisition, projects, product
}) => {
  const { hasAccessToPortal } = useUserData()
  const [showCreateProject, setshowCreateProject] = useState(false);
  const { push } = useRouter()
  const defaultProjectId = projects[0].id
  const initialValues = { projectId: defaultProjectId, projectName: '' }
  const goToDraftRequisition = (requisition: Requisition) => {
    if (hasAccessToPortal)
      push(sm.portals.buyer.procurement.requisitions.requisition(requisition.id).url)
    else
      push(sm.requisitions.requisition(requisition.id).url)
  }
  const handleFailure = (error: any, actions: any) => {
    if (error.code == 'REQ0001') actions.setFieldError('name', error.message)
  }
  const handleCreateRequisition = (values: any, actions: any) => {
    createRequisition(values.projectId ?? '', values.projectName, (error: any) => handleFailure(error, actions), product, goToDraftRequisition)
  }

  return (
    <Stack sx={{ textAlign: 'start' }}>
      <Formik initialValues={initialValues} validationSchema={showCreateProject ? createProjectSchema : createRequisitionSchema} onSubmit={handleCreateRequisition} >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <ProjectCreateOrSelect projects={projects} showCreateProject={showCreateProject} setshowCreateProject={setshowCreateProject} />
            <Button sx={{ mt: 2 }} type="submit" fullWidth variant="contained">
              {t("buttons.create_new_requisition")}
            </Button>
          </form>
        )}
      </Formik>
    </Stack>
  )
}


export const ProjectCreateOrSelect: FC<{
  projects: Project[];
  setshowCreateProject: (value: boolean) => void;
  showCreateProject: boolean;
}> = ({ projects, setshowCreateProject, showCreateProject }) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const openCreateProjectField = () => {
    setFieldValue('projectId', '');
    setshowCreateProject(true);
  };
  return (
    <Grid container>
      <Grid md={12}>
        {showCreateProject ? (
          <AutoComplete
            freeSolo='projectName'
            translateKey='project'
            name='projectId'
            required
            items={projects ?? []}
            onSearch={event => {
              setFieldValue('projectId', '');
            }}
            onChange={() => setFieldValue('projectName', '')}
            autoFocus
          />
        ) : (
          <SelectListWithChildren
            translateKey='select_project'
            name='projectId'
            required
            items={projects ?? []}
          >
            <Box
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'secondary.contrastText',
              }}
            >
              <Button
                onClick={() => openCreateProjectField()}
                fullWidth
                sx={{ justifyContent: 'start' }}
              >
                <Typography variant='subtitle2' px={3} fontWeight={700}>
                  {' '}
                  + {' ' + t('buttons.create_project')}
                </Typography>
              </Button>
            </Box>
          </SelectListWithChildren>
        )}
      </Grid>
    </Grid>
  );
};