import type { FC } from 'react';
import { Box, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import PlusIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { useRef, useState } from "react"
import { useField } from 'formik'
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DeleteIcon } from 'src/ui/icons/delete';
import { getInputsTranslationKey } from '@/hooks'

interface UploadBoxProps {
    name: string,
    translateKey: string,
}

export const UploadBox: FC<UploadBoxProps> = (props: UploadBoxProps) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(props);

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [fileSelected, setFileSelected] = useState(false)

    const onBoxClick = (e) => {
        if (e.target.id == 'deleteIcon') {
            return;
        }
        if (inputFileRef.current !== null)
            inputFileRef.current.click();
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileSelected(true)
        helpers.setValue(e.currentTarget.files[0]);
    }

    const deleteFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileSelected(false)
        helpers.setValue(null);
    }

    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: fileSelected ? "success.light" : "background.secondary.main",
        position: 'relative',
        "&:hover": {
            cursor: "pointer"
        }
    }

    return (
        <Box
            onClick={onBoxClick}
            border="1px dashed"
            borderColor={fileSelected ? "success.dark" : "secondary.main"}
            borderRadius={'8px'}
            p="20px"
            sx={boxStyle}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", my: "2" }}>
                <input name={props.name} onChangeCapture={onFileChange} ref={inputFileRef} type="file" style={{ "display": "none" }} />
                <Box
                    p="5px"
                    mb="10px"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: fileSelected ? "success.dark" : "background.secondary.hover",
                        borderRadius: "8px"
                    }}>
                    {fileSelected ? <CheckIcon color='success' /> : <PlusIcon color='secondary' />}
                </Box>
                <Typography
                    component='label'
                    color={fileSelected ? "success.dark" : "secondary.main"}
                    fontWeight='600'
                    variant='body2'
                    sx={{ display: 'inline' }}
                > {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                </Typography>
            </Box>
        </Box>
    );
};

