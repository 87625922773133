import { AutoComplete, PhoneInputField, StyledTextField, UploadedDocumentsSection, user } from '@/components';
import { getAccountTranslationKey } from '@/hooks';
import { NotifySuccess, useUpdateProfile } from '@/services';
import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { sm } from 'src/site-map';
import { UserSettingsForm } from '..';
import validationSchema from './validationSchema';

type Props = { userData: user; isSmallScreen: boolean };

export const EditProfileSection: React.FC<Props> = ({ userData, companyData, predefinedData, isSmallScreen }) => {
  const { t } = useTranslation();

  const onSuccess = () => {
    NotifySuccess(t('notifications.update_profile'));
    router.push(sm.account.index.url);
  };
  const { mutate } = useUpdateProfile({ onSuccess });

  const handleSubmit = (values, actions) => {
    mutate({ values: values, actions: actions });
  };
  const router = useRouter();
  const cancel = () => {
    router.push(sm.account.index.url);
  };

  const initialValues = {
    first_name: userData.first_name,
    last_name: userData.last_name,
    title: userData.title,
    job_title: userData.job_title,
    company_industry_id: companyData.industryId,
    company_name: companyData.name,
    company_city_id: companyData.cityId,
    company_country_id: companyData.countryId,
    company_address: companyData.company_address,
    trn_number: companyData.trn_number,
    work_number: companyData.work_number,
    fax_number: companyData.fax_number,
  };
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <UserSettingsForm
              cancel={cancel}
              isSmallScreen={isSmallScreen}
              userData={userData}
              predefinedData={predefinedData}
              disabledButton={!(formikProps.isValid && formikProps.dirty)}
            />
            <CompanySettingsForm
              companyData={companyData}
              predefinedData={predefinedData}
              setFieldValue={formikProps.setFieldValue}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

type CompanySettingsFormProps = {};
export const CompanySettingsForm: React.FC<CompanySettingsFormProps> = ({
  companyData,
  predefinedData,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const [cities, setcities] = useState(predefinedData.countries.find(item => item.id === companyData.countryId).cities);
  const onCountryChange = (event, value) => {
    setcities(value.cities);
  };

  return (
    <Grid container>
      <Grid item container xs={6}>
        <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>
          {t(getAccountTranslationKey('sections_titles.company_settings'))}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <StyledTextField showLabel required translateKey='company.name' name='company_name' />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <AutoComplete
          required
          translateKey='company.industry'
          name='company_industry_id'
          defaultValue={{ name: companyData.industry.name, id: companyData.industryId }}
          items={predefinedData.industries}
        />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <AutoComplete
          required
          translateKey='company.country'
          name='company_country_id'
          defaultValue={{ id: companyData.countryId, name: companyData.country.name }}
          items={predefinedData.countries}
          onChange={onCountryChange}
        />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <AutoComplete
          required
          // freeSolo='company_city_id'
          translateKey='company.city'
          name='company_city_id'
          defaultValue={{ id: companyData.cityId, name: companyData.city.name }}
          items={cities}
        />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <StyledTextField showLabel required translateKey='company.address' name='company_address' />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <StyledTextField showLabel translateKey='company.trn' name='trn_number' />
      </Grid>
      <Grid item xs={12} md={6} mt={1} pr={2}>
        <PhoneInputField name='fax_number' label='Fax Number' />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <PhoneInputField name='work_number' label='Work Phone Number' />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item container xs={12} mb={5}>
        <UploadedDocumentsSection companyData={companyData} />
      </Grid>
    </Grid>
  );
};
