
export enum ApproverRFQStatus {
    PENDING_MY_APPROVAL = "PENDING_MY_APPROVAL",
    APPROVED_BY_ME = "APPROVED_BY_ME",
    REJECTED_BY_ME = "REJECTED_BY_ME",
    CANCELED = "CANCELED"
}
export enum SellerRFQStatus {
    PENDING = "PENDING",
    CANCELLED_RFQ = "CANCELLED_RFQ",
    QUOTE_GENERATED = "QUOTE_GENERATED",
}
export enum OwnerRFQStatus {
    DRAFT = "DRAFT",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    EDITING = "EDITING"
}
export enum MyRFQTableStatus {
    DRAFT = "DRAFT",
    SENT_FOR_APPROVAL = "SENT_FOR_APPROVAL",
    SENT_TO_SELLER = "SENT_TO_SELLER",
}

export enum RFQTableListOfActions {
    SEND_FOR_APPROVAL = "send_for_approval",
    SEND_REMINDER = "send_reminder",
    EDIT = "edit",
    SEND_TO_SELLERS = "send_to_sellers",
    GENERATE_QUOTE = "generate_quote"
}