import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { StyledTabs } from '@/components';
// import { useState } from 'react';
import { CTProductWithSellersCard } from '../ct-product-card';
import {
  ComparisonTablesProduct,
  ComparisonTablesProducts,
  ProductCategoryKind,
  SellerDetails,
  TabModel,
} from '@/models';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from '@mui/material';
import { useProcurementStore } from '@/zustand';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import BuyerRequirementsIndicator from '../../../products-table/BuyerRequirementsIndicator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type CTDetailsProps = {
  comparisonTableProducts: ComparisonTablesProducts[] | null;
  selectedMainProduct: ComparisonTablesProduct | null;
  selectedAlternativeProducts: ComparisonTablesProduct[];
  ctTabs: TabModel[] | null;
  selectedMainProductId: number;
  setSelectedMainProduct: (mainProductId: number) => void;
  selectSellersForCtProduct: (
    ctProduct: ComparisonTablesProduct,
    seller: SellerDetails,
    isProductAlternative: boolean
  ) => void;
  clearItems: (selectedMainProductId: number) => void;
};

export const CTDetails: React.FC<CTDetailsProps> = ({
  comparisonTableProducts,
  selectedMainProduct,
  selectedAlternativeProducts,
  ctTabs,
  setSelectedMainProduct,
  selectedMainProductId,
  selectSellersForCtProduct,
  clearItems,
}) => {
  const { comparisonTableData } = useProcurementStore();
  const { t } = useTranslation();
  // const [value, setValue] = useState(selectedMainProductId);
  // const updateTab = (event, newValue) => { setValue(newValue) };

  let selectedProduct = comparisonTableProducts?.find(item => item.main_product.product.id == selectedMainProductId);
  const quantityRequested = selectedProduct?.main_product.quantity;
  const additional_requirements = selectedProduct?.main_product?.additional_requirements;
  const category_kind = selectedProduct?.main_product?.product?.category_kind;

  const handleClearSelectedSeller = () => {
    clearItems(selectedMainProductId);
  };

  return (
    <Grid item md={12} my={3}>
      <Box sx={{ borderBottom: 1, mb: 2, borderColor: 'divider', display: 'grid' }}>
        <StyledTabs
          tabs={ctTabs}
          value={selectedMainProductId}
          secondText={' ' + t('buttons.alternatives')}
          thirdText={' ' + t('texts.selected')}
          updateTab={(_, newValue) => setSelectedMainProduct(newValue)}
          onChange={() => {}}
        />
      </Box>

      <Grid
        my={2}
        mt={3}
        item
        container
        md={12}
        alignItems='end'
        justifyContent={'space-between'}
        sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
      >
        {/* <Typography variant="body2" fontWeight={400} color='info.main' sx={{ textTransform: 'lowercase' }}>{selectedProduct?.numberOfSelectedSellers + " " + t('texts.items') + " " + t('texts.selected')}</Typography> */}
        <div style={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
          {quantityRequested !== undefined && (
            <Typography variant='body2' fontWeight={400} pt={0.5}>
              Quantity Requested: <span style={{ fontWeight: 700 }}>{quantityRequested}</span>
            </Typography>
          )}
          {comparisonTableData?.termsBundle?.warranty_term?.name !== undefined && (
            <Typography variant='body2' fontWeight={400} pt={0.5}>
              Custom Warranty:{' '}
              <span style={{ fontWeight: 700, color: 'primary.main' }}>
                {selectedMainProduct?.warranty_term?.name ? selectedMainProduct?.warranty_term?.name : 'N/A'}
              </span>
            </Typography>
          )}
          {category_kind === ProductCategoryKind.SERVICE && (
            <Box display={'flex'} alignItems={'center'} gap={0.5} pt={0.5}>
              <BuyerRequirementsIndicator textColor='#3A3E50' additional_requirements={additional_requirements} />
              <InfoOutlinedIcon sx={{ color: '#89B1C6', fontSize: '18px' }} />
            </Box>
          )}
        </div>
        <Grid
          item
          alignItems='center'
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            transition: 'opacity 300ms',
            opacity: selectedProduct?.numberOfSelectedSellers !== 0 ? 1 : 0,
          }}
          onClick={handleClearSelectedSeller}
        >
          <HighlightOffIcon sx={{ fontSize: '20px', pr: 0.5, ml: 2, mt: 0.5 }} />
          <Typography variant='body2' fontWeight={400} pt={0.5}>
            {t('buttons.clear_selection')}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        sx={{
          marginBottom: 0,
          maxHeight: 700,
          overflow: 'auto',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': { width: 2, height: 2 },
          '&::-webkit-scrollbar-track': { boxShadow: `white` },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'primary.main',
            outline: `1px solid slategrey`,
            borderRadius: 3,
          },
        }}
      >
        <Grid md={12}>
          {selectedMainProduct && (
            <Grid item md={12} my={1}>
              <CTProductWithSellersCard
                withDetailsPopup
                ctProductDetails={selectedMainProduct}
                selectSellersForCtProduct={selectSellersForCtProduct}
                isProductAlternative={false}
              />
            </Grid>
          )}

          {selectedAlternativeProducts && selectedAlternativeProducts.length != 0 && (
            <>
              <Grid item md={12} my={1.5}>
                <Typography variant='body2' color='text.disabled'>
                  {t('buttons.alternatives')}{' '}
                  <PrimaryBadge style={{ marginInlineStart: '.5em' }}>
                    {selectedAlternativeProducts.length}
                  </PrimaryBadge>
                </Typography>
              </Grid>

              {selectedAlternativeProducts?.map(alternativeProduct => (
                <Grid item md={12} key={alternativeProduct?.product?.id} my={1.5}>
                  <CTProductWithSellersCard
                    withDetailsPopup
                    ctProductDetails={alternativeProduct}
                    selectSellersForCtProduct={selectSellersForCtProduct}
                    isProductAlternative={true}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
