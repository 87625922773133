import { ProductManagerEnv } from '@/config';
import { Product, ProductCategoryKind } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';
import { ConfirmPopup, StyledLoadingButton, TwoButtonsWithHoverToggle } from 'src/ui/components/shared';
import { ProductQuantity } from '.';
import usePopup from 'src/logic/hooks/use-popup';
import { NotifyError } from '@/services';

type props = {
  product: Product;
  isPageAlternative?: boolean;
};

export const EditBundleRfqProductActions: React.FC<props> = props => {
  const { push, query } = useRouter();
  const { data } = useProcurementStore(state => state.productFormManagerProperties);

  const viewAlternatives = () => {
    push({
      pathname: sm.catalogue.products.alternatives(
        // props.product.category.parent.parent.id,
        // props.product.category.parent.id,
        // props.product.category.id,
        props.product.id
      ).url,
    });
  };
  return (
    <>
      <ProductQuantity product={props.product} isPageAlternative={props.isPageAlternative} />
      <MainEditRfqButton {...props.product} />
      {props.product.inCart &&
        !props.isPageAlternative &&
        data.environment?.type === ProductManagerEnv.edit_bundle_rfq && (
          <Button color='secondary' variant='contained' fullWidth onClick={viewAlternatives}>
            {t('buttons.add_alternatives')}
          </Button>
        )}
    </>
  );
};

export const MainEditRfqButton = (product: Product) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);

  const deletePopup = usePopup();

  const handleRemoveProduct = () => {
    actions.removeProduct(product);
    deletePopup.handleClose();
  };

  const handleAddProduct = () => {
    actions.setProductIdRequestedToBeAdded(product.id);
    if (product.category_kind === ProductCategoryKind.SERVICE && !product.additional_requirements) {
      NotifyError('Missing Field: Buyer Requirements');
      return;
    }

    if (data.environment?.type === ProductManagerEnv.edit_bundle_rfq) {
      actions.addProduct(product);
    }
  };

  return (
    <>
      {product.inCart && data.environment?.type === ProductManagerEnv.edit_bundle_rfq ? (
        <>
          <TwoButtonsWithHoverToggle
            handleClickHiddenButton={deletePopup.handleOpen}
            hiddenButtonTitleKey='remove'
            mainButtonTitleKey='added'
          />
          <ConfirmPopup
            titleKey={t('popups.titles.remove_product')}
            subTitleKey={t('popups.subTitles.remove_product', {
              formName: data.formDetails?.name,
            })}
            buttonTitleKey={t('buttons.remove')}
            handleClose={deletePopup.handleClose}
            handleConfirm={handleRemoveProduct}
            open={deletePopup.isOpen}
          />
        </>
      ) : (
        <StyledLoadingButton fullWidth variant='contained' onClick={handleAddProduct} translateKey='add_product' />
      )}
    </>
  );
};
