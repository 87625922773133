import React, { forwardRef } from 'react';
import { Box, Typography, TextField, TextFieldProps } from '@mui/material';

export type LabeledTextFieldProps = TextFieldProps;

export const LabeledTextField = forwardRef<
  HTMLDivElement,
  LabeledTextFieldProps
>(({ label, ...props }, ref) => {
  return (
    <Typography
      component='label'
      sx={{ display: 'block', color: 'text.third' }}
    >
      <Box sx={{ mb: 1 }}>{label}</Box>
      <TextField ref={ref} {...props} />
    </Typography>
  );
});
