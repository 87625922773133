export const enum i18nNameSpaces {
    DEFAULT = 'common',
    HOME = 'home',
    PRODUCT = 'product',
    BECOME_A_SELLER = 'become-a-seller',
    CAREER = 'career',
    CONTACT_US = 'contact-us',
    LOGIN = 'login',
    Register = 'register',
    HEADER = 'header',
    FOOTER = 'footer',
    INPUTS = 'inputs',
    REQUISITION = 'requisition',
    ACCOUNT = 'account',
    USERS = 'users',
    PROCUREMENT = "procurement"
} 
  