import { FC, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { DescPopup } from "@/components";

type EditAndRegenerateButtonProps = {
    editAndRegenerateHandler: (handleRedirectToRequisition: () => void) => void
    hidePopup?: boolean
    subtitle?: string
    mainAction?: boolean
    customName?: string
}

export const EditAndRegenerateButton: FC<EditAndRegenerateButtonProps> = ({ editAndRegenerateHandler, hidePopup, subtitle, mainAction, customName }) => {
    const { t } = useTranslation();
    //convert to rfq popup
    const [showEditAndRegeneratePopup, setShowEditAndRegeneratePopup] = useState(false);
    const handleOpenEditAndRegeneratePopup = () => setShowEditAndRegeneratePopup(true)
    const handleCloseEditAndRegeneratePopup = () => setShowEditAndRegeneratePopup(false);
    const editHandler = () => {
        editAndRegenerateHandler(handleCloseEditAndRegeneratePopup)
    }
    return (
        <>
            <Button variant={mainAction ? "contained" : "containedInfo"} sx={{ mr: 1, px: 8 }} onClick={hidePopup ? editHandler : handleOpenEditAndRegeneratePopup}>
                {customName ? customName : t("buttons.edit")}
            </Button>
            <DescPopup
                handleClose={handleCloseEditAndRegeneratePopup}
                open={showEditAndRegeneratePopup}
            >
                <Grid container gap={2} width={320}>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='overline' color='primary.main' fontWeight="700">
                            {t('buttons.edit_regenerate')}
                        </Typography>
                    </Grid>
                    <Grid item md={12} textAlign='center'>
                        <Typography variant='body2' color='text.disabled'>
                            {subtitle ? subtitle : t('popups.subTitles.edit_regenerate')}
                        </Typography>
                    </Grid>
                    <Grid item container md={12}>
                        <Grid item md={6} pr={0.5}>
                            <Button
                                onClick={handleCloseEditAndRegeneratePopup}
                                variant='contained'
                                fullWidth
                                color='warning'
                            >
                                {t('buttons.cancel')}
                            </Button>
                        </Grid>
                        <Grid item md={6} pl={0.5}>
                            <Button
                                onClick={editHandler}
                                variant='contained'
                                fullWidth
                            >
                                {t('buttons.yes_confirm')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DescPopup>
        </>
    )
}