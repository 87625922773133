import React from 'react';
import { Grid, Typography, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { PasswordRestrictions, PhoneInputField, SelectWithPlaceholder, StyledTextField } from '@/components';
import { useTranslation } from 'next-i18next';
import { sm } from 'src/site-map';
import { title } from "@/components"
import { getRegisterTranslationKey } from '@/hooks'
import { HiddenInput } from 'src/ui/components/shared/hidden-input';

const gridItemStyle = {
    pr: 2,
    pb: 2
}

interface UserDataFormProps{
    titles: Array<title>,
    disabledButton: boolean,
    validateField:Function,
    email_invitation?:string,
}
export const UserDataForm: React.FC<UserDataFormProps> = (props: UserDataFormProps) => {

    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const { t } = useTranslation()
    

    return (
        <Grid container xs={12} sx={{ pt: 2, display: 'flex' }}>
            <HiddenInput  name='invitation_uuid'/>
            <Grid item xs={12} sx={gridItemStyle}>
                <Typography variant="h5" sx={{fontWeight: 'bolder'}}> {t(getRegisterTranslationKey('headers.user_data'))} </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <StyledTextField showLabel translateKey='user.first_name' name='first_name' required/>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <StyledTextField showLabel translateKey='user.last_name' name='last_name' required/>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <SelectWithPlaceholder translateKey='user.title' placeholder='user.title' name="title" items={props.titles} required/>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <StyledTextField showLabel translateKey='user.job_title' name="job_title" required/>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <PhoneInputField name='mobile_number' label='Mobile Number' required />
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <PhoneInputField name='direct_number' label='Direct Number' />
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <StyledTextField showLabel translateKey='user.email' name="email" required 
                    disabled={props.email_invitation ? true : false}
                />
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <StyledTextField showLabel type="password" translateKey='user.password' name="password" required />
            </Grid>
            <Grid item xs={12} md={6} sx={{ ...gridItemStyle , pb:0 }}>
                <StyledTextField showLabel type="password" translateKey='user.password_confirm' name="password_confirmation" required/>
            </Grid>
            <Grid item xs={12} md={12} mb={3} sx={gridItemStyle}>
               <PasswordRestrictions />
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <Button
                    variant='outlined'
                    href={sm.register.index.url}
                    sx={{
                        float: 'left',
                        width:  isMobile? '110px' : '146px'
                    }}
                    startIcon={<ArrowBack/>}
                > {t('buttons.back')} </Button>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                <Button
                    variant='contained'
                    type="submit"
                    disabled={props.disabledButton}
                    sx={{
                        float: 'right',
                        width:  isMobile? '100%' : '230px'
                    }}
                    endIcon={<ArrowForward/>}
                >{t('buttons.next_step')}</Button>
            </Grid>
        </Grid>
    )

}