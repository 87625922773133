import * as yup from 'yup'
export const validationSchema = yup.object({
    sellerId: yup.string().required('required'),
    projectName: yup.string(),
    // projectId: yup.string().required('required')
});
export const createProjectSchema = yup.object({
    sellerId: yup.string().required('required'),
    projectName: yup.string().test('projectName', 'required', function (value) {
        const { projectId } = this.parent;
        return value || projectId;
    }),
    projectId: yup.string().test('projectId', 'required', function (value) {
        const { projectName } = this.parent;
        return value || projectName;
    }),
});
