import { Link, TextTooltip } from '@/components';
import { getProductTranslationKey } from '@/hooks';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  Box, Button, Card,
  CardMedia, Divider, Grid, Typography,
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { memo, useRef } from 'react';
import { sm } from 'src/site-map';

export type TypeCardProps = {
  id: number;
  imageURL: string;
  name: string;
  subs: string[];
  category: string;
  disabled: boolean;
};

export const TypeCard: React.VFC<TypeCardProps> = memo(
  ({ id, imageURL, name, subs, href, category, disabled }) => {
    const textRef = useRef();
    const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
    const router = useRouter();
    const { t } = useTranslation();

    return (
      <Card
        {...(subs.length == 0 && !disabled ? { onClick: () => router.push(href) } : {})}
        variant='outlined'
        sx={{
          marginRight: isSmallScreen ? 0 : 3,
          width: isSmallScreen ? 130 : 227,
          height: '100%',
          borderColor: 'background.primary.main',
          textDecoration: 'none',
          backgroundColor: 'secondary.contrastText',
          marginBottom: 2,
          cursor: disabled ? 'default' : 'pointer',
          borderRadius: '8px',
          transition: t =>
            t.transitions.create('border', {
              duration: t.transitions.duration.shortest,
            }),
          '&:hover': {
            borderColor: subs.length != 0 ? 'primary.main' : 'white',
            cursor: subs.length == 0 && !disabled ? 'pointer' : 'default',
          },
        }}
      >
        <Box
          key={id}
          sx={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            justifyContent: 'center',
            '&:hover': {
              transform: subs.length == 0 && !disabled ? 'scale(1.1)' : 'none',
            },
          }}
        >
          <CardMedia
            component='img'
            image={imageURL}
            sx={{
              height: 184,
              width: isSmallScreen ? 120 : 140,
              objectFit: 'contain',
            }}
          />
        </Box>
        <Grid container mt={1} direction='column' px={1}>
          <Box
            p={1}
            sx={{
              height: 56,
              marginBottom: 2,
              display: 'flex',
              width: '100%',
            }}
          >
            {!disabled ? (
              <TextTooltip title={name} textRef={textRef}>
                <Link
                  ref={textRef}
                  fontWeight='700'
                  underline={'hover'}
                  href={href}
                  sx={{
                    mt: 1,
                    height: '46px',
                    lineHeight: 1.2,
                    fontSize: '18px !important',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: subs.length === 0 && 'center',
                    width: '100%',
                  }}
                >{name}</Link>
              </TextTooltip>
            ) : (
              <TextTooltip title={name} textRef={textRef}>
                <Typography
                  ref={textRef}
                  fontWeight='700'
                  sx={{
                    mt: 1,
                    height: '46px',
                    lineHeight: 1.2,
                    fontSize: '18px !important',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'text.third',
                    textAlign: subs.length === 0 && 'center',
                    width: '100%',
                  }}
                >
                  {name}
                </Typography>
              </TextTooltip>
            )}
          </Box>
          {subs?.slice(0, 4).map(sub => {
            return (
              <Box key={sub.id} sx={{ height: 40, justifyContent: 'center' }}>
                <Link
                  sx={{
                    ml: 1,
                    width: isSmallScreen ? 120 : 140,
                    fontSize: '14px !important',
                    fontWeight: '400',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  underline='hover'
                  href={{
                    pathname: sm.products.typeId(category, id, sub.id).url,
                    query: { ...router.query },
                  }}
                >
                  {sub.title}
                </Link>
                <Divider sx={{ marginTop: 1 }} />
              </Box>
            );
          })}
          {subs?.length > 4 && (
            <Box sx={{ height: 60 }}>
              <Button
                component={Link}
                sx={{
                  borderColor: 'white',
                  textAlign: 'left',
                  paddingLeft: '4px',
                  width: isSmallScreen ? 143 : 155,
                  '&:hover': {
                    borderColor: 'white',
                  },
                  justifyContent: 'flex-start',
                }}
                href={href}
                variant='outlined'
                color='primary'
                endIcon={
                  <ArrowForwardIos sx={{ height: isSmallScreen ? 15 : 17 }} />
                }
              >
                {t('buttons.see_more')} {!category ? t(getProductTranslationKey('titles.classes')) : t(getProductTranslationKey('titles.types'))}
              </Button>
            </Box>
          )}
        </Grid>
      </Card>
    );
  }
);
