import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { ProductCategoryKind, ProductCategoryKindType } from '@/models';

type IProps = {
  selectedType: ProductCategoryKindType;
  onChange: (newValue: ProductCategoryKindType) => void;
  disabled?: boolean;
};

const ProductServiceButtonGroup = ({ selectedType, onChange, disabled }: IProps) => {
  return (
    <ButtonGroup color='primary'>
      <Button
        type='button'
        variant={selectedType === ProductCategoryKind.PRODUCT ? 'contained' : 'containedInfo'}
        onClick={() => {
          if (selectedType !== ProductCategoryKind.PRODUCT) {
            onChange(ProductCategoryKind.PRODUCT);
          }
        }}
        sx={{ borderRadius: '5px', width: '120px' }}
        disabled={disabled && selectedType !== ProductCategoryKind.PRODUCT}
      >
        Product
      </Button>
      <Button
        type='button'
        variant={selectedType === ProductCategoryKind.SERVICE ? 'contained' : 'containedInfo'}
        onClick={() => {
          if (selectedType !== ProductCategoryKind.SERVICE) {
            onChange(ProductCategoryKind.SERVICE);
          }
        }}
        sx={{ borderRadius: '5px', width: '120px' }}
        disabled={disabled && selectedType !== ProductCategoryKind.SERVICE}
      >
        Service
      </Button>
    </ButtonGroup>
  );
};

export default ProductServiceButtonGroup;
