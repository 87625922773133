import React from 'react';
import { ViewOnlyGeneralTermsProps } from '../types';
import { Grid, Typography } from '@mui/material';
import { containerStyles, gridItemStyles } from '../styles';

const ViewOnlyGeneralTerms = (props: ViewOnlyGeneralTermsProps) => {
  return (
    <Grid container style={containerStyles}>
      <Grid item md={2.25} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontWeight={700} fontSize={14}>
          General Terms
        </Typography>
      </Grid>
      <Grid item md={3.25} style={gridItemStyles}>
        <Typography color='#13628C' fontWeight={400} fontSize={12}>
          <span style={{ color: '#8B8D98' }}>Payment: </span>
          {props.payment_term?.name}
        </Typography>
      </Grid>
      <Grid
        item
        md={3.25}
        style={{ borderInline: '1px solid #EAEEF6', ...gridItemStyles }}
      >
        <Typography color='#13628C' fontWeight={400} fontSize={12}>
          <span style={{ color: '#8B8D98' }}>Warranty: </span>
          {props.warranty_term?.name}
        </Typography>
      </Grid>
      <Grid item md={3.25} style={gridItemStyles}>
        <Typography color='#13628C' fontWeight={400} fontSize={12}>
          <span style={{ color: '#8B8D98' }}>Delivery: </span>
          {props.delivery_term?.name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ViewOnlyGeneralTerms;
