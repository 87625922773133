import create from 'zustand';

export const useLogin = create((set, get) => ({
    needsVerification: false as boolean,
    email: '' as String,
    getNeedsVerification: () => {return get().needsVerification},
    getEmail: () => {return get().email},
    setNeedsVerification: (email: String) => {
        set({
            needsVerification: true, 
            email: email
        })
    },
    setVerified: () => {
        set({
            needsVerification: false, 
        })
    },
}));
