import { ApproverBundleRFQStatus, DonePopupStatus, OwnerBundleRFQStatus, PopupStatus, ProcurementButtons, ProcurementForms, procurementStatus } from "@/config";
import { BundleRFQ, BundleRFQDetails, GeneralObject, LinkType, RFQ, RFQDetails, TabModel } from "@/models";
import {
    buildRFQDetails, ChildApprovalChange,
    getNumberofBundlePendingApprovalChild, NotifyMessage, NotifySuccess, NotifySuccessWithDeleteIcon, NotifySuccessWithCancelIcon, useApproveAllRFQs, useApproveRFQ, useBundleRFQBulkApproval, useCancelBundleRFQ,
    useCancelRFQ, useCancelSentBundleRFQForApprovals, useChangeProject, useDeclineRequestExtension, useDeleteBundleRFQ, useDeleteProductFromRFQ, useDeleteRFQ, useDuplicateForm, useEditExpiryDate, useFetchCancelBundleReasons, useFetchCancelRFQReasons, useFetchRejectReasons, useGetBundleRFQDetails, useRejectAllRFQs, useRejectRFQ, useSendBundleToAllSellers, useSendForApproval, useSendRFQToSeller
} from "@/services";
import { t } from "i18next";
import produce from "immer";
import { StateCreator } from "zustand";
import { useUserData } from "../../user";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";


export interface BundleRFQDetailsSlice {
    bundleRFQDetailsSliceProperties: {
        bundleRFQDetails: BundleRFQDetails | null,
        fetchBundleRfqDetailsQueryStatus: QueryHandlerModel,
        // loadingBundleRFQ: boolean,
        tabs: TabModel[] | null,
        selectedRFQ: RFQDetails | null,
        isBundleRFQDeleted: boolean,
        numberOfComments: number,
        sendForApprovalPopup: boolean
        showAfterSendForApprovalPopup: boolean
        openedPopupStatus: PopupStatus | null
        popupComment: string | null
        popupReasonId: string
        reasonsForPopup: GeneralObject[]
        timePopup: boolean,
        confirmDeletePopup: boolean,
        hasNewComments: boolean,
        editResponse: boolean,
        sentDonePopup: boolean,
        sentDoneToAllSellersPopup: boolean,
        sentDoneToSellerPopup: boolean,
        newComments: Comment[],
        openedDonePopupStatus: DonePopupStatus | null
        approvalChanges: ChildApprovalChange[]
        customLoadingButton: ProcurementButtons | null
        productIdDeleting: number | null
    },
    bundleRFQDetailsSliceActions: {
        loadBundleRFQSlice: (bundleRFQId: number, RFQId?: number) => void
        setSelectedRFQ: (rfqId: number | undefined) => void
        editAndRegenerateRFQFromBundle: (handleRedirectToRequisition: () => void) => void
        // deleteRFQ: (rfqId: number) => void
        deleteIndividualRFQ: (rfqId: number, rfqName: string) => void
        // setBundleRFQDeleted: (isDeleted: boolean) => void
        sendForApproval: (bundleRfqId: number, optionalComment: string) => void
        setPopupStatus: (status: PopupStatus | null) => void
        setPopupReasonId: (reasonId: string) => void
        setSendForApprovalPopup: (isShow: boolean) => void
        setShowAfterSendForApprovalPopup: (isShow: boolean) => void
        cancelSendForApprovals: (bundleRfqId: number) => void
        setPopupComment: (comment: string) => void
        approveAllRFQs: (bundleRfqId: number, comment?: string) => void
        setApprovalChanges: (approvalChanges: ChildApprovalChange[]) => void
        approvalProcess: (rfqId: number | undefined, approvalStatus: procurementStatus, comment: string, reasonId?: string) => void
        rejectAllRFQs: (bundleRfqId: number, reasonId: string, comment?: string) => void
        resetBundleRFQDetails: (data: BundleRFQ) => void
        setSeennNewComments: () => void
        changeProject: (project: GeneralObject) => void
        setTimePopup: (isDeleted: boolean) => void,
        deleteBundleRFQ: (bundleRfqId: number, redirectToRFQs: () => void) => void,
        setConfirmDeletePopup: (isOpen: boolean) => void,
        onDestruction: () => void, //used to cancel any connection
        setSentDonePopup: (isOpen: boolean) => void,
        setSentDoneToAllSellersPopup: (isOpen: boolean) => void,
        setSentDoneToSellerPopup: (isOpen: boolean) => void,
        sendRFQToSeller: (RfqId: number | undefined, date: string, optionalComment?: string) => void,
        sendBundleToAllSellers: (bundleRfqId: number, date: string, optionalComment?: string) => void,
        cancelBundleRFQ: (bundleRfqId: number, reasonId: string, hideReason: boolean, optionalComment?: string) => void,
        setDonePopupStatus: (status: DonePopupStatus | null) => void,
        cancelRFQ: (rfqId: number, reasonId: string, hideReason: boolean, optionalComment?: string) => void
        getReasonsForPopup: () => void
        closeNewCommentsPopup: () => void
        setBundleTabs: (tabsArray: RFQ[]) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        handleDuplicateBundleRFQ: (bundleId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => void
        deleteProductFromRFQ: (productId: number, rfqId: number | undefined, redirectToRFQs: () => void) => void
        editExpiryDate: (validityDate: string, selectedRFQ: number | undefined, closePopup: () => void) => void
        declineRequestExtension: (rfqId: number | undefined) => void
        declineAllRequestsExtension: (bundleRfqId: number | undefined) => void
    }
}

export const createBundleRFQDetailsSlice: StateCreator<BundleRFQDetailsSlice> = (set, get, api) => ({
    bundleRFQDetailsSliceProperties: {
        bundleRFQDetails: null,
        fetchBundleRfqDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingBundleRFQ: false,
        tabs: null,
        selectedRFQ: null,
        isBundleRFQDeleted: false,
        sendForApprovalPopup: false,
        showAfterSendForApprovalPopup: false,
        openedPopupStatus: null,
        popupComment: null,
        popupReasonId: '0',
        reasonsForPopup: [],
        approvalChanges: [],
        numberOfComments: 0,
        timePopup: false,
        confirmDeletePopup: false,
        hasNewComments: false,
        isSeenNewComments: true,
        editResponse: false,
        sentDonePopup: false,
        sentDoneToAllSellersPopup: false,
        sentDoneToSellerPopup: false,
        newComments: [],
        openedDonePopupStatus: null,
        customLoadingButton: null,
        productIdDeleting: null
    },
    bundleRFQDetailsSliceActions: {
        onDestruction: () => {
            const bundleRFQId = get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.id
            window.Echo.private(`BUNDLE_RFQ_CHANNEL.${bundleRFQId}`).unsubscribe(() => {
                console.log('You are unsubscribed to bundle rfq private channel');
            })
            window.Echo.private(`BUNDLE_RFQ_CHANNEL.${bundleRFQId}`).stopListening('.RFQ.new_approval', () => {
                console.log('You are unsubscribed to bundle rfq private channel');
            })
        },
        loadBundleRFQSlice: (bundleRFQId: number, RFQId?: number) => {
            if (!window.Echo.private(`BUNDLE_RFQ_CHANNEL.${bundleRFQId}`).subscription.subscribed)
                window.Echo
                    .private(`BUNDLE_RFQ_CHANNEL.${bundleRFQId}`).subscribe()

            window.Echo
                .private(`BUNDLE_RFQ_CHANNEL.${bundleRFQId}`)
                .subscribed(() => {
                    console.log('You are subscribed to bundle rfq private channel');
                }).listen('.RFQ.new_approval', (data) => {
                    console.log('Listen to .RFQ.new_approval:')
                    useGetBundleRFQDetails(bundleRFQId).then(data => {
                        loadData(data, set, get, RFQId)
                        get().setTimeline(data.data.timeline);
                    }).catch((response) => {
                        set(produce(draftState => {
                            draftState.bundleRFQDetailsSliceProperties.fetchBundleRfqDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                        }))
                    })
                });

            // set(produce(draftState => {
            //     draftState.bundleRFQDetailsSliceProperties.loadingBundleRFQ = true
            // }))
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.fetchBundleRfqDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useGetBundleRFQDetails(bundleRFQId).then(data => {
                loadData(data, set, get, RFQId)
                get().bundleRFQDetailsSliceActions.setApprovalChanges([])
                get().setTimeline(data.data.timeline);
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.fetchBundleRfqDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel, false, true)
            get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(modelAfterUpdateProject)
        },
        setSeennNewComments: () => set(produce(draftState => {
            draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails.bundleRFQModel.new_comments = []
        })),
        setPopupStatus: (status: PopupStatus | null) => set(produce(draftState => {
            draftState.bundleRFQDetailsSliceProperties.openedPopupStatus = status
            if (!status) {
                draftState.bundleRFQDetailsSliceProperties.openedPopupStatus = null
                draftState.bundleRFQDetailsSliceProperties.popupComment = null
                draftState.bundleRFQDetailsSliceProperties.popupReasonId = '0'
            }
        })),
        setPopupReasonId: (reasonId: string) => set(produce(draftState => {
            draftState.bundleRFQDetailsSliceProperties.popupReasonId = reasonId
        })),
        setPopupComment: (comment: string) => set(produce(draftState => {
            draftState.bundleRFQDetailsSliceProperties.popupComment = comment
        })),
        setSelectedRFQ: (rfqId: number | undefined) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.selectedRFQ = get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.requestForQuotes.find((element: RFQDetails) => element.rfqModel?.id == rfqId)
                draftState.bundleRFQDetailsSliceProperties.tabs = draftState.bundleRFQDetailsSliceProperties.tabs.map((element: TabModel) => {
                    return { ...element, checked: element.id === rfqId }
                })
            }))
        },
        editAndRegenerateRFQFromBundle: (handleEditAndRegenerateRFQ: () => void) => {
            get().editAndRegenerateRFQ(get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel.requisition_id, handleEditAndRegenerateRFQ)
        },
        setBundleRFQDeleted: (isDeleted: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.isBundleRFQDeleted = isDeleted
            }))
        },
        sendForApproval: (bundleRfqId: number, optionalComment: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_BUNDLE_RFQ_FOR_APPROVAL)
            useSendForApproval(bundleRfqId, optionalComment).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.sendForApprovalPopup = false
                    draftState.bundleRFQDetailsSliceProperties.showAfterSendForApprovalPopup = true
                }))
                NotifySuccess(t("popups.titles.sent_for_approval"))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        setSendForApprovalPopup: (isShow: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.sendForApprovalPopup = isShow
            }))
        },
        setShowAfterSendForApprovalPopup: (isShow: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.showAfterSendForApprovalPopup = isShow
            }))
        },
        cancelSendForApprovals: (bundleRfqId: number) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.CANCEL_BUNDLE_RFQ_APPROVAL)
            useCancelSentBundleRFQForApprovals(bundleRfqId).then(data => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                NotifySuccess(t("popups.titles.approval_canceled"))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        setApprovalChanges: (approvalChanges: ChildApprovalChange[]) => set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.approvalChanges = approvalChanges })),
        approvalProcess: (rfqId: number | undefined, approvalStatus: procurementStatus, comment: string, reasonId?: string) => {
            approvalStatus == procurementStatus.APPROVED_BY_ME ? get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_INDIVIDUAL_RFQ) : get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_INDIVIDUAL_RFQ)
            const sliceProps = get().bundleRFQDetailsSliceProperties
            const sliceActions = get().bundleRFQDetailsSliceActions
            if (getNumberofBundlePendingApprovalChild(sliceProps.bundleRFQDetails?.bundleRFQModel, 'request_for_quotes')) {
                const changesAfterApproval = bulkApproval(sliceProps.bundleRFQDetails, 'request_for_quotes', sliceProps.selectedRFQ?.rfqModel?.id ?? 0, approvalStatus, comment, sliceProps.approvalChanges, reasonId)
                sliceActions.resetBundleRFQDetails(changesAfterApproval.formDetailsModel)
                sliceActions.setApprovalChanges(changesAfterApproval.changes)
                if (!getNumberofBundlePendingApprovalChild(changesAfterApproval.formDetailsModel, 'request_for_quotes'))
                    useBundleRFQBulkApproval(sliceProps.bundleRFQDetails?.bundleRFQModel?.id ?? 0, changesAfterApproval.changes).then(data => {
                        sliceActions.resetBundleRFQDetails(data.data)
                        sliceActions.setApprovalChanges([])
                    })
            } else if (approvalStatus === procurementStatus.REJECTED_BY_ME) {
                useRejectRFQ(sliceProps.selectedRFQ?.rfqModel?.id ?? 0, comment ?? null, reasonId).then(data => {
                    sliceActions.resetBundleRFQDetails(data.data)
                    NotifySuccessWithCancelIcon(t('notifications.form_rejected', { formName: sliceProps.selectedRFQ?.rfqModel?.name }))
                }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
            } else {
                useApproveRFQ(sliceProps.selectedRFQ?.rfqModel?.id ?? 0, comment ?? null).then(data => {
                    get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                    NotifySuccess(t('notifications.form_approved', { formName: sliceProps.selectedRFQ?.rfqModel?.name }))
                }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
            }
            get().bundleRFQDetailsSliceActions.setSelectedRFQ(rfqId)
        },
        approveAllRFQs: (bundleRfqId: number, comment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.APPROVE_ALL_RFQs)
            useApproveAllRFQs(bundleRfqId, comment ?? null).then(data => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                NotifySuccess(t('notifications.form_approved', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        rejectAllRFQs: (bundleRfqId: number, reasonId: string, comment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.REJECT_ALL_RFQs)
            useRejectAllRFQs(bundleRfqId, comment ?? null, reasonId).then(data => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.form_rejected', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        resetBundleRFQDetails: (data) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.popupReasonId = 0
                draftState.bundleRFQDetailsSliceProperties.openedPopupStatus = null
                draftState.bundleRFQDetailsSliceProperties.popupComment = null
                draftState.bundleRFQDetailsSliceProperties.openedDonePopupStatus = null
                const bundleRFQModel = data
                draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails = buildRFQBundleDetails(bundleRFQModel)
                draftState.bundleRFQDetailsSliceProperties.numberOfComments = data.number_of_comments
                draftState.bundleRFQDetailsSliceProperties.showAfterSendForApprovalPopup = false
                draftState.bundleRFQDetailsSliceProperties.customLoadingButton = null
            }))
            get().bundleRFQDetailsSliceActions.setSelectedRFQ(get().bundleRFQDetailsSliceProperties.selectedRFQ?.rfqModel?.id)
            // get().bundleRFQDetailsSliceActions.setSelectedRFQ(data?.request_for_quotes[0]?.id)
            get().bundleRFQDetailsSliceActions.setBundleTabs(data?.request_for_quotes)
            get().setTimeline(data.timeline)
        },
        setTimePopup: (isDeleted: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.timePopup = isDeleted
            }))
        },
        setConfirmDeletePopup: (isOpen: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.confirmDeletePopup = isOpen
            }))
        },
        sendBundleToAllSellers: (bundleRfqId: number, date: string, optionalComment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_BUNDLE_RFQ_TO_SELLER)
            useSendBundleToAllSellers(bundleRfqId, date, optionalComment ?? null).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.sentDoneToAllSellersPopup = true
                }))
                NotifySuccess(t('notifications.form_sent_to_seller', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        setSentDonePopup: (isOpen: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.sentDonePopup = isOpen
            }))
        },
        setSentDoneToAllSellersPopup: (isOpen: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.sentDoneToAllSellersPopup = isOpen
            }))
        },
        setSentDoneToSellerPopup: (isOpen: boolean) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.sentDoneToSellerPopup = isOpen
            }))
        },
        sendRFQToSeller: (RfqId: number | undefined, date: string, optionalComment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SEND_INDIVIDUAL_RFQ_TO_SELLER)
            useSendRFQToSeller(RfqId, date, optionalComment ?? null).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.sentDoneToSellerPopup = true
                }))
                get().bundleRFQDetailsSliceActions.setSelectedRFQ(RfqId)
                NotifySuccess(t("popups.titles.sent_to_seller_ct_created",{formName: get().bundleRFQDetailsSliceProperties.selectedRFQ?.rfqModel?.name}))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        setDonePopupStatus: (status: DonePopupStatus | null) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.openedDonePopupStatus = status
                if (!status) {
                    draftState.bundleRFQDetailsSliceProperties.openedDonePopupStatus = null
                }
            }))
        },
        cancelBundleRFQ: (bundleRfqId: number, reasonId: string, hideReason: boolean, optionalComment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_BUNDLE_RFQ)
            useCancelBundleRFQ(bundleRfqId, reasonId, hideReason, optionalComment ?? null).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                get().bundleRFQDetailsSliceActions.setDonePopupStatus(DonePopupStatus.CANCEL_BUNDLE)
                NotifyMessage(t('notifications.form_recalled', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        cancelRFQ: (rfqId: number, reasonId: string, hideReason: boolean, optionalComment?: string) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.RECALL_INDIVIDUAL_RFQ)
            useCancelRFQ(rfqId, reasonId, hideReason, optionalComment ?? null).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                get().bundleRFQDetailsSliceActions.setDonePopupStatus(DonePopupStatus.CANCEL_RFQ)
                NotifyMessage(t('notifications.form_recalled', { formName: get().bundleRFQDetailsSliceProperties.selectedRFQ?.rfqModel?.name }))
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        getReasonsForPopup: () => {
            if (get().bundleRFQDetailsSliceProperties.openedPopupStatus == PopupStatus.REJECT || get().bundleRFQDetailsSliceProperties.openedPopupStatus == PopupStatus.REJECT_ALL) useFetchRejectReasons().then(data => { set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.reasonsForPopup = data.data })) })
            else if (get().bundleRFQDetailsSliceProperties.openedPopupStatus == PopupStatus.CANCEL_BUNDLE) useFetchCancelBundleReasons().then(data => { set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.reasonsForPopup = data.data })) })
            else if (get().bundleRFQDetailsSliceProperties.openedPopupStatus == PopupStatus.CANCEL_RFQ) useFetchCancelRFQReasons().then(data => { set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.reasonsForPopup = data.data })) })
        },
        closeNewCommentsPopup: () => set(produce(draftState => {
            const bundleModel = draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails.bundleRFQModel;
            if (bundleModel) {
                bundleModel.new_comments = null;
            }
            draftState.bundleRFQDetailsSliceProperties.newComments = null
            draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails.bundleRFQModel.new_comments = []
        })),
        setBundleTabs: (tabsArray: RFQ[]) => {
            let updatedTabs: TabModel[] = []
            tabsArray?.map(element => { updatedTabs.push({ id: element.id, name: element.seller_details.name, count: element.products.length, status: element.status, isExpired: element.is_expired , isPrivate: element.seller_details?.is_private}) })
            set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.tabs = updatedTabs }))
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.bundleRFQDetailsSliceProperties.customLoadingButton = null }
            }))
        },
        deleteBundleRFQ: (bundleRfqId: number, redirectToRFQs: () => void) => {
            useDeleteBundleRFQ(bundleRfqId).then(() => {
                redirectToRFQs()
                set(produce(draftState => { draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails = null }))
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }));
                // get().requisitionSliceActions.refreshRequisition(data.data.requisition)
            })
        },
        deleteIndividualRFQ: (rfqId: number, rfqName: string) => {
            useDeleteRFQ(rfqId).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data);
                get().bundleRFQDetailsSliceActions.setSelectedRFQ(data?.data?.request_for_quotes[0]?.id);
                NotifySuccessWithDeleteIcon(t('notifications.form_deleted', { formName: rfqName }));
            })
        },
        handleDuplicateBundleRFQ: (bundleId: number | undefined, project: GeneralObject, redirectToDuplicatedForm: (id: number) => void) => {
            useDuplicateForm(bundleId, project, LinkType.BUNDLE_RFQ).then((data) => {
                const duplicatedFormId = data.data.id;
                redirectToDuplicatedForm(duplicatedFormId);
                NotifySuccess(t('notifications.form_duplicated', { formName: get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.bundleRFQModel?.name }));
            });
        },
        deleteProductFromRFQ: (productId: number, rfqId: number | undefined, redirectToRFQs: () => void) => {
            set(produce(draftState => {
                draftState.bundleRFQDetailsSliceProperties.productIdDeleting = productId;
            }))

            useDeleteProductFromRFQ(productId, rfqId).then(() => {
                // Update Request For Quotes
                const old_request_for_quotes = get().bundleRFQDetailsSliceProperties.bundleRFQDetails?.requestForQuotes;
                const new_request_for_quotes = old_request_for_quotes?.map(rfq => {
                    if (rfq.rfqModel?.id !== rfqId) return rfq;
                    const products = rfq.rfqModel?.products.filter(product => product.id !== productId)
                    return {
                        ...rfq,
                        numberOfProducts: products?.length,
                        rfqModel: {
                            ...rfq.rfqModel,
                            products
                        }
                    }
                });

                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.productIdDeleting = null;
                    draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails.requestForQuotes = new_request_for_quotes;
                    // draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails.bundleRFQModel.request_for_quotes = new_request_for_quotes
                }))

                // Update Selected RFQ
                const selectedRFQ = get().bundleRFQDetailsSliceProperties.selectedRFQ
                if (rfqId === selectedRFQ?.rfqModel?.id) {
                    const old_products = get().bundleRFQDetailsSliceProperties.selectedRFQ?.rfqModel?.products;
                    const new_products = old_products?.filter(product => product.id !== productId)
                    set(produce(draftState => {
                        draftState.bundleRFQDetailsSliceProperties.selectedRFQ.numberOfProducts = new_products?.length
                        draftState.bundleRFQDetailsSliceProperties.selectedRFQ.rfqModel.products = new_products;
                    }))
                }

                // Update Tabs
                const new_tabs = get().bundleRFQDetailsSliceProperties.tabs?.filter(tab => {
                    const targetRfq = new_request_for_quotes?.find(rfq => rfq.rfqModel?.id === tab.id)
                    if (targetRfq?.numberOfProducts === 0) return false
                    return true;
                }).map(tab => {
                    const targetRfq = new_request_for_quotes?.find(rfq => rfq.rfqModel?.id === tab.id)
                    return {
                        ...tab,
                        count: targetRfq?.numberOfProducts,
                    }
                })
                set(produce(draftState => {
                    draftState.bundleRFQDetailsSliceProperties.tabs = new_tabs
                }))

                if (new_tabs?.length === 0) {
                    redirectToRFQs();
                    return;
                }
                if (!new_tabs?.find(tab => tab.id === rfqId)) {
                    get().bundleRFQDetailsSliceActions.setSelectedRFQ(new_tabs?.[0].id)
                }
            });
        },
        editExpiryDate: (validityDate: string, selectedRFQ: number | undefined, closePopup: () => void) => {
            const rfqId = get().bundleRFQDetailsSliceProperties.selectedRFQ?.rfqModel?.id
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(rfqId, LinkType.RFQ_OF_BUNDLE, validityDate).then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                get().bundleRFQDetailsSliceActions.setSelectedRFQ(selectedRFQ);
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineRequestExtension: (rfqId: number | undefined) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(rfqId, 'RequestForQuote').then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                get().bundleRFQDetailsSliceActions.closeNewCommentsPopup()
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
                get().bundleRFQDetailsSliceActions.setSelectedRFQ(rfqId);
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        },
        declineAllRequestsExtension: (bundleRfqId: number | undefined) => {
            get().bundleRFQDetailsSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_REQUEST_EXTENSION)
            useDeclineRequestExtension(bundleRfqId, 'BUNDLE_RFQ').then((data) => {
                get().bundleRFQDetailsSliceActions.resetBundleRFQDetails(data.data)
                NotifySuccessWithCancelIcon(t('notifications.request_extension_declined'));
                get().bundleRFQDetailsSliceActions.closeNewCommentsPopup()
            }).catch(() => get().bundleRFQDetailsSliceActions.setCustomLoadingButton(null))
        }
    }
})

export function buildRFQBundleDetails(bundleRFQ: BundleRFQ) {
    const userInfo = useUserData.getState().userInfo
    const canCreateRFQ = useUserData.getState().canCreateRFQ
    let requestForQuotes = bundleRFQ?.request_for_quotes

    // let hasApprovedStatus = false;
    // hasApprovedStatus = requestForQuotes?.find((obj: RFQ) => obj.status === OwnerBundleRFQStatus.APPROVED) !== undefined;

    const rfqs = requestForQuotes?.map(e => buildRFQDetails(e, userInfo))
    const numberOfProducts = rfqs?.reduce((accumulator, currentValue) => accumulator + currentValue.numberOfProducts, 0);
    // let ifCanEditResponse = rfqs?.filter(ele => (!ele.canAcceptRFQ && ele.canRejectRFQ) || (ele.canAcceptRFQ && !ele.canRejectRFQ))?.length != 0
    let isOwner = userInfo?.id == bundleRFQ?.owner.id

    let isApprover = rfqs[0]?.rfqModel.approvers.filter(approver => approver.approver_id == userInfo?.id)[0]
    return {
        // bundleRFQModel: bundleRFQ,
        // numberOfProducts: numberOfProducts,
        // requestForQuotes: rfqs,
        // canCancelSendingForApprovals: isOwner && bundleRFQ.status == OwnerBundleRFQStatus.PENDING_APPROVAL && canCreateRFQ,
        // canSendForApprovals: isOwner && bundleRFQ.status == OwnerBundleRFQStatus.DRAFT && canCreateRFQ,
        // canEditAndRegeneratePrimary: isOwner && bundleRFQ.updatable,
        // canEditAndRegenerateSecondary: isOwner && (bundleRFQ.status == procurementStatus.APPROVED || hasApprovedStatus),
        // canAcceptAllRFQs: !isOwner && isApprover && (bundleRFQ.status == ApproverBundleRFQStatus.MIXED || bundleRFQ.status == ApproverBundleRFQStatus.PARTIALLY_APPROVED_BY_ME || bundleRFQ.status == ApproverBundleRFQStatus.PENDING_MY_APPROVAL || bundleRFQ.status == ApproverBundleRFQStatus.REJECTED_BY_ME),
        // canRejectAllRFQs: !isOwner && isApprover && (bundleRFQ.status == ApproverBundleRFQStatus.MIXED || bundleRFQ.status == ApproverBundleRFQStatus.PARTIALLY_APPROVED_BY_ME || bundleRFQ.status == ApproverBundleRFQStatus.PENDING_MY_APPROVAL || bundleRFQ.status == ApproverBundleRFQStatus.APPROVED_BY_ME),
        // canEditResponse: ifCanEditResponse,
        // canSendToSellers: isOwner && (hasApprovedStatus || bundleRFQ.status == procurementStatus.APPROVED),
        // canRecallBundle: isOwner && bundleRFQ.status == procurementStatus.SENT_TO_SELLERS,
        // canDelete: isOwner && bundleRFQ?.deletable,
        // canDuplicate: canCreateRFQ,

        /*new solution */
        bundleRFQModel: bundleRFQ,
        numberOfProducts: numberOfProducts,
        requestForQuotes: rfqs,
        canCancelSendingForApprovals: bundleRFQ?.form_action_permissions?.can_cancel_approval_request && isOwner && canCreateRFQ,
        canSendForApprovals: bundleRFQ?.form_action_permissions?.can_send_for_approval && isOwner && canCreateRFQ,
        canEditAndRegeneratePrimary: bundleRFQ?.form_action_permissions?.editable && (bundleRFQ.status == procurementStatus.DRAFT || bundleRFQ.status == procurementStatus.REJECTED || bundleRFQ.status == procurementStatus.DECLINED) && isOwner,
        canEditAndRegenerateSecondary: bundleRFQ?.form_action_permissions?.editable && bundleRFQ.status !== procurementStatus.DRAFT && bundleRFQ.status !== procurementStatus.REJECTED && bundleRFQ.status !== procurementStatus.DECLINED && isOwner,
        canAcceptAllRFQs: bundleRFQ?.form_action_permissions?.can_approve_approval_request && !isOwner && isApprover,
        canRejectAllRFQs: bundleRFQ?.form_action_permissions?.can_reject_approval_request && !isOwner && isApprover,
        canEditResponse: bundleRFQ?.form_action_permissions?.can_edit_approval_decision && !isOwner && isApprover,
        canSendToSellers: bundleRFQ?.form_action_permissions?.can_send_to_counter_party && isOwner,
        canRecallBundle: bundleRFQ?.form_action_permissions?.recallable && isOwner,
        canDelete: bundleRFQ?.form_action_permissions?.deletable && isOwner,
        canDuplicate: canCreateRFQ,
    };
}
function loadData(data, set, get, RFQId?: number | null) {

    let tabsArray: TabModel[] = []
    data.data?.request_for_quotes?.map((rfq: RFQ) => tabsArray.push({ id: rfq.id, name: rfq.seller_details.name, checked: false, count: rfq.products.length, status: rfq.status, isExpired: rfq.is_expired , isPrivate: rfq.seller_details?.is_private}))
    set(produce(draftState => {
        const bundleRFQModel = data.data
        draftState.bundleRFQDetailsSliceProperties.bundleRFQDetails = buildRFQBundleDetails(bundleRFQModel)
        // draftState.bundleRFQDetailsSliceProperties.loadingBundleRFQ = false
        draftState.bundleRFQDetailsSliceProperties.fetchBundleRfqDetailsQueryStatus = QueryHandler.getSuccessStatus();
        draftState.bundleRFQDetailsSliceProperties.tabs = tabsArray
        draftState.bundleRFQDetailsSliceProperties.isBundleRFQDeleted = false
        draftState.bundleRFQDetailsSliceProperties.timePopup = false
        draftState.bundleRFQDetailsSliceProperties.confirmDeletePopup = false
        draftState.bundleRFQDetailsSliceProperties.sentDonePopup = false
        draftState.bundleRFQDetailsSliceProperties.sentDoneToAllSellersPopup = false
        draftState.bundleRFQDetailsSliceProperties.sentDoneRoSellerPopup = false
        draftState.bundleRFQDetailsSliceProperties.openedDonePopupStatus = null
        draftState.bundleRFQDetailsSliceProperties.numberOfComments = data.data.number_of_comments
        draftState.bundleRFQDetailsSliceProperties.hasNewComments = data.data.new_comments.length != 0
        draftState.bundleRFQDetailsSliceProperties.newComments = data.data.new_comments?.length == 0 ? null : data.data.new_comments
        draftState.bundleRFQDetailsSliceProperties.showAfterSendForApprovalPopup = false
        draftState.bundleRFQDetailsSliceProperties.sendForApprovalPopup = false
        draftState.bundleRFQDetailsSliceProperties.customLoadingButton = null
    }))

    if (!RFQId || !tabsArray.find(item => item.id == RFQId)) {
        get().bundleRFQDetailsSliceActions.setSelectedRFQ(
            data.data?.request_for_quotes[0]?.id
        );
    } else {
        get().bundleRFQDetailsSliceActions.setSelectedRFQ(RFQId);
    }
}

export function bulkApproval(formDetails: BundleRFQDetails | null, bundleChildren: string, childId: number, status: procurementStatus, comment: string, changes: ChildApprovalChange[], reasonId?: string) {
    const userData = useUserData.getState()
    const childIndex = formDetails?.requestForQuotes.findIndex((child) => child.rfqModel?.id === childId);
    let child = formDetails?.requestForQuotes[childIndex ?? 0] as ProcurementFormModel
    let newBundleStatus = '' as procurementStatus
    child = {
        ...child, rfqModel: {
            ...child.rfqModel, status: status, approvers: child?.rfqModel?.approvers.map(approver => {
                if (approver.approver_id == userData.userInfo?.id) return { ...approver, status: status }
                return approver
            })
        }
    }
    const childStatuses = formDetails?.requestForQuotes.map((c) => c.rfqModel?.status);
    if (childStatuses?.every((s) => s === procurementStatus.APPROVED_BY_ME))
        newBundleStatus = procurementStatus.APPROVED_BY_ME
    else if (childStatuses?.every((s) => s === procurementStatus.REJECTED_BY_ME))
        newBundleStatus = procurementStatus.REJECTED_BY_ME
    else newBundleStatus = procurementStatus.MIXED
    changes = changes.filter((change) => change.id !== childId);
    const change: ChildApprovalChange = { id: childId, type: status === procurementStatus.APPROVED_BY_ME ? 'approve' : 'reject', comment: comment, reasonId: reasonId ?? null };
    changes.push(change);
    const formDetailsModel = formDetails?.bundleRFQModel ? produce(formDetails?.bundleRFQModel, draftFormDetailsModel => {
        draftFormDetailsModel.status = newBundleStatus
        draftFormDetailsModel.approvers = formDetails?.bundleRFQModel?.approvers.map(approver => {
            if (approver.user_name == child.rfqModel.seller_details.name) return { ...approver, status: status }
            return approver
        })
        draftFormDetailsModel[bundleChildren] = formDetails.bundleRFQModel[bundleChildren].map(formChild => {
            if (formChild.id == childId) return child.rfqModel
            return formChild
        })
    }) : null

    return {
        changes: changes,
        formDetailsModel: formDetailsModel,
    }
}