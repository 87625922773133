import React, { FC, useState } from "react"
import { Typography, Button } from "@mui/material"
import { useTranslation } from 'next-i18next';
import { DescPopup } from "../popups";
import { ShowGeneralTerms } from "../show-general-terms";
import { GeneralObject } from "@/models";


interface GeneralTermsButtonProps {
    warranty_term?: GeneralObject | undefined | null
    payment_term?: GeneralObject | undefined | null
    delivery_term?: GeneralObject | undefined | null
}

export const GeneralTermsButton: FC<GeneralTermsButtonProps> = ({ warranty_term, payment_term, delivery_term }) => {
    const { t } = useTranslation();
    const [ShowPopup, setShowPopup] = useState(false)
    const handleOpenShowPopup = () => setShowPopup(true)
    const handleCloseShowPopup = () => setShowPopup(false)

    return (
        <>
            <Button variant="outlined" sx={{ backgroundColor: 'inherit' }}>
                <Typography variant="body2" fontWeight={500} onClick={handleOpenShowPopup}>{t('buttons.general_terms')} </Typography>
            </Button>

            <DescPopup open={ShowPopup} handleClose={handleCloseShowPopup}>
                <ShowGeneralTerms warranty_term={warranty_term?.name} payment_term={payment_term?.name} delivery_term={delivery_term?.name} handleCloseGeneralTermsPopup={handleCloseShowPopup} />
            </DescPopup>
        </>
    )
}