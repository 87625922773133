import { apiRoutes } from '@/config'
import { request } from '@/helpers'
import { useProfile ,useProfilePhoneNumberFlow } from '@/zustand'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next';
import { NotifyError, NotifySuccess } from '../Notify-toast'

/* update Direct Number*/

const requestUpdateDirectNumber = async (data) => {
  return request({
    url: apiRoutes.updateDirectNumber,
    method: "PUT",
    data: data
  })
}
export const useRequestUpdateDirectNumber = () => {
  const { t } = useTranslation();
  const user_info = useProfile(state => state.user_info);
  const setUserInfo = useProfile(state => state.setUserInfo);
  const changeDirectPopupSteps = useProfilePhoneNumberFlow(state => state.changeDirectPopupSteps);
  const setChangeDirectSteps = useProfilePhoneNumberFlow(state => state.setChangeDirectSteps);
  const onSuccess = (data) => { 
    setChangeDirectSteps(changeDirectPopupSteps+1)
    setUserInfo({...user_info,direct_number:data.data.direct_number})
  }
  const showError = (data) => {
    data.status === 422 ? NotifyError(t('notifications.invalid_direct_number')):
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)):
    data.status === 429 ? NotifyError(t('notifications.too_many_requests')): 
    NotifyError(t('notifications.server_error'))
  }
  return useMutation((values) => requestUpdateDirectNumber(values), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}


/* verification Phone Number */

export const verificationPhoneNumber = async () => {
  return request({
    url: apiRoutes.phoneNumberVerification,
    method: "GET"
  })
}
export const useVerificationPhoneNumber = (enabled) => {
  const { t } = useTranslation();
  const phoneVerificationPopupSteps = useProfilePhoneNumberFlow(state => state.phoneVerificationPopupSteps);
  const setPhoneVerificationSteps = useProfilePhoneNumberFlow(state => state.setPhoneVerificationSteps);
  const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
  const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
  
  const onSuccess = (data) => {
    setPhoneVerificationSteps(phoneVerificationPopupSteps+1),setIsTimer(true),setTimer(60)
  }
  const showError = (data) => {
    setIsTimer(true);setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['verifyProfilePhoneNumber'], () => verificationPhoneNumber(), 
    {enabled: false ,retry: false,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

const requestVerificationPhoneCode = async (data) => {
  return request({
    url: apiRoutes.isPhoneNumberVerified,
    method: "POST",
    data: data,
    disableErrorMessage: (err) => {
      return err?.response?.status === 409
    }
  })
}
export const useRequestVerificationPhoneCode = () => {
  const { t } = useTranslation();
  const phoneVerificationPopupSteps = useProfilePhoneNumberFlow(state => state.phoneVerificationPopupSteps);
  const setUserInfo = useProfile(state => state.setUserInfo);
  const setPhoneVerificationSteps = useProfilePhoneNumberFlow(state => state.setPhoneVerificationSteps);
  const onSuccess = (data) => { 
    setPhoneVerificationSteps(phoneVerificationPopupSteps+1)
    setUserInfo(data.data)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((values) => requestVerificationPhoneCode(values), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

export const requestVerificationPhoneCodeAgain = async () => {
  return request({
    url: apiRoutes.phoneNumberVerificationAgain,
    method: "GET"
  })
}
export const useRequestVerificationPhoneCodeAgain = (enabled) => {
  const { t } = useTranslation()
  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code_phone"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['verifyProfilePhoneNumberAgain',enabled], () => requestVerificationPhoneCodeAgain(), 
  {enabled: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}


/* change Phone Number */

export const requestVerificationOldPhoneNumber = async () => {
  return request({
    url: apiRoutes.requestVerificationOldPhone,
    method: "GET"
  })
}
export const useRequestVerificationOldPhoneNumber = (enabled) => {
  const { t } = useTranslation();
  const changePhoneByPhonePopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByPhonePopupSteps);
  const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
  const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
  
  const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
  const onSuccess = (data) => {
    setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps+1)
    NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to }))
  }
  const showError = (data) => {
    setIsTimer(true);setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['requestVerificationOldPhone',enabled], () => requestVerificationOldPhoneNumber(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

const verificationOldPhoneNumber = async (data) => {
  return request({
    url: apiRoutes.verificationOldPhone,
    method: "POST",
    data: data
  })
}
export const useVerificationOldPhoneNumber = () => {
  const { t } = useTranslation();
  const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
  const setChangePhoneViaPhoneToken = useProfilePhoneNumberFlow(state => state.setChangePhoneViaPhoneToken);
  const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
  const changePhoneByEmailPopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByEmailPopupSteps);
  const changePhoneByPhonePopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByPhonePopupSteps);

  const onSuccess = (data) => { 
    setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps+1)
    setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps+1)
    setChangePhoneViaPhoneToken(data.data.token)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((values) => verificationOldPhoneNumber(values), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

const requestChangePhoneByPhone = async (data) => {
  return request({
    url: apiRoutes.requestChangePhoneViaPhone,
    method: "POST",
    data: data
  })
}
export const useRequestChangePhoneByPhone = () => {
  const { t } = useTranslation();
  const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
  const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
  const setChangePhoneViaPhoneToken = useProfilePhoneNumberFlow(state => state.setChangePhoneViaPhoneToken);
  const setTemporaryPhone = useProfilePhoneNumberFlow(state => state.setTemporaryPhone);
  const changePhoneViaPhoneToken = useProfilePhoneNumberFlow(state => state.changePhoneViaPhoneToken);
  const changePhoneByEmailPopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByEmailPopupSteps);
  const changePhoneByPhonePopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByPhonePopupSteps);

  const onSuccess = (data) => { 
    setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps+1)
    setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps+1)
    setChangePhoneViaPhoneToken(data.data.token)
    NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to }))
    setTemporaryPhone(data.data.sent_to)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => requestChangePhoneByPhone(({ ...data, token: changePhoneViaPhoneToken })), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

const verificationNewPhoneNumber = async (data) => {
  return request({
    url: apiRoutes.verificationNewPhone,
    method: "POST",
    data: data
  })
}
export const useVerificationNewPhoneNumber = () => {
  const { t } = useTranslation();
  const setChangePhoneByPhoneSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByPhoneSteps);
  const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
  const setUserInfo = useProfile(state => state.setUserInfo);
  const changePhoneViaPhoneToken = useProfilePhoneNumberFlow(state => state.changePhoneViaPhoneToken);
  const changePhoneByEmailPopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByEmailPopupSteps);
  const changePhoneByPhonePopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByPhonePopupSteps);

  const onSuccess = (data) => { 
    setChangePhoneByPhoneSteps(changePhoneByPhonePopupSteps+1)
    setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps+1),setUserInfo(data.data)
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => verificationNewPhoneNumber(({...data, token:changePhoneViaPhoneToken})), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

export const verificationOldPhoneNumberAgain = async () => {
  return request({
    url: apiRoutes.reRequestVerificationOldPhone,
    method: "GET"
  })
}
export const useVerificationOldPhoneNumberAgain = (enabled) => {
  const { t } = useTranslation();

  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code_phone"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['reRequestVerificationOldPhone',enabled], () => verificationOldPhoneNumberAgain(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

export const verificationNewPhoneNumberAgain = async (data) => {
  return request({
    url: apiRoutes.reRequestVerificationNewPhone,
    method: "POST",
    data: data
  })
}
export const useVerificationNewPhoneNumberAgain = () => {
  const { t } = useTranslation();
  const changePhoneViaPhoneToken = useProfilePhoneNumberFlow(state => state.changePhoneViaPhoneToken);

  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code_phone"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useMutation(() => verificationNewPhoneNumberAgain({token:changePhoneViaPhoneToken}), { onSuccess: (data) => onSuccess(data), onError: (data) => showError(data)})
}

export const requestVerifyOldPhoneByEmail = async () => {
  return request({
    url: apiRoutes.requestVerificationOldPhoneViaEmail,
    method: "GET"
  })
}
export const useRequestVerificationOldPhoneByEmail = (enabled) => {
  const { t } = useTranslation();

  const setChangePhoneByEmailSteps = useProfilePhoneNumberFlow(state => state.setChangePhoneByEmailSteps);
  const changePhoneByEmailPopupSteps = useProfilePhoneNumberFlow(state => state.changePhoneByEmailPopupSteps);
  const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
  const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
  
  const onSuccess = (data) => {
    setChangePhoneByEmailSteps(changePhoneByEmailPopupSteps+1)
    NotifySuccess(t('notifications.sent_to',{ PhoneNumber: data.data.sent_to }))
  }
  const showError = (data) => {
    setIsTimer(true);setTimer(60);
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['requestVerificationOldPhoneVaiEmail',enabled], () => requestVerifyOldPhoneByEmail(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}

export const verificationPhoneNumberByEmailAgain = async () => {
  return request({
    url: apiRoutes.reRequestVerificationOldPhoneViaEmail,
    method: "GET"
  })
}
export const useVerificationPhoneNumberByEmailAgain = (enabled) => {
  const { t } = useTranslation();
  const onSuccess = (data) => {
    NotifySuccess(t("notifications.verification_code"))
  }
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : data.status === 429 ? NotifyError(t('notifications.too_many_requests')) : NotifyError(t('notifications.server_error'))
  }
  return useQuery(['reRequestVerificationOldPhoneVaiEmail',enabled], () => verificationPhoneNumberByEmailAgain(), 
    {enabled: false ,retry: false ,onSuccess: (data) => onSuccess(data),onError: (data) => showError(data) })
}
