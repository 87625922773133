import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react';

type reactQueryWrapperType = { requestName: string, request: Promise, onSuccess: (data?: any) => void, enabled?: boolean, onError?: (data?: any) => void }

export const queryWrapper: React.FC<reactQueryWrapperType> = (props) => {
    return useQuery([props.requestName], props.request, { onSuccess: props.onSuccess, onError: props.onError, enabled: props.enabled });
} 

export const mutationWrapper: React.FC<reactQueryWrapperType> = (props) => {
    return useMutation([props.requestName], props.request, { onSuccess: props.onSuccess, onError: props.onError });
}
