import {
  MenuItem,
  Grid,
  Select,
  Button,
  Typography,
  Menu,
  Box,
  Divider,
  Popper,
  Paper,
  ClickAwayListener,
} from '@mui/material';
import { useRequisition } from '@/zustand';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DELIVERY_TERMS, PAYMENT_TERMS, WARRANTY_TERMS } from '@/constants';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { Product, Requisition } from '@/models';
import { PopupWithTitle, StyledPopper } from '@/components';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SavedSnackbar } from '../..';
import { useTranslation } from 'next-i18next';
import { getRequisitionTranslationKey } from '@/hooks';

type TermsSelectProps = {
  term: string;
  text: string;
  termName: string;
  setSelectedTerms: Function;
  values: Array<string>;
  selectedTerms: { warranty: string; payment: string; delivery: string };
  isSmallScreen: boolean;
};

const TermsSelect: React.VFC<TermsSelectProps> = ({
  term,
  text,
  termName,
  values,
  selectedTerms,
  setSelectedTerms,
  isSmallScreen,
}) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {isSmallScreen ? (
        <>
          <Button
            aria-haspopup='true'
            onClick={handleClickOpen}
            endIcon={<KeyboardArrowDownIcon />}
            variant='outlined'
            disableElevation
            sx={{
              width: '100%',
              borderColor: 'action.disabledBackground',
              justifyContent: 'start',
              '& .MuiButton-endIcon': { position: 'absolute', right: 15 },
              color: 'text.primary',
            }}
          >
            {values[selectedTerms[term]]
              ? values[selectedTerms[term]]
              : text
            }
          </Button>
          <PopupWithTitle
            title={termName}
            handleClose={handleClose}
            open={open}
          >
            {values.map((termValue, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => {
                    setSelectedTerms({ ...selectedTerms, [term]: index });
                    handleClose();
                  }}
                  endIcon={selectedTerms[term] === index && <CheckIcon />}
                  variant='outlined'
                  disableElevation
                  sx={{
                    width: '100%',
                    fontWeight: '400',
                    borderColor: 'secondary.contrastText',
                    justifyContent: 'start',
                    '& .MuiButton-endIcon': { position: 'absolute', right: 15 },
                    color: 'text.primary',
                  }}
                >
                  {termValue}
                </Button>
              );
            })}
          </PopupWithTitle>
        </>
      ) : (
        <Select
          defaultValue={selectedTerms[term] ? selectedTerms[term] : -1}
          onChange={event => {
            setSelectedTerms({ ...selectedTerms, [term]: event.target.value });
          }}
          size='small'
          MenuProps={{
            disableScrollLock: true,
            // disablePortal: true,
            style: { marginTop: "20px", width: "150px", height: 300 },
            // PaperProps: { sx: { maxHeight: 300 } },
          }}
          sx={{
            marginTop: 0.3,
            minWidth: '100%',
            maxWidth: '100%',
            maxHeight: '40px',
            borderWidth: '0.08px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.darker',
            },
            color: 'text.primary',
            borderColor: 'primary.main',
          }}
        >
          <MenuItem value={-1}>
            <Typography
              sx={{
                fontSize: '14px !important',
                fontWeight: '400',
                color: 'text.primary',
                textTransform: 'capitalize',
              }}
            >
              {text}
            </Typography>
          </MenuItem>
          {values.map((termValue, index) => {
            return (
              <MenuItem key={index} value={index}>
                {termValue}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </>
  );
};

type GeneralTermsSelectProps = {
  requisition: Requisition;
  isSmallScreen: boolean;
};

export const GeneralTermsSelect: React.VFC<GeneralTermsSelectProps> = ({
  requisition,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ChangeGeneralTerms = useRequisition(state => state.ChangeGeneralTerms);
  const [selectedTerms, setSelectedTerms] = React.useState({
    warranty: requisition.generalTerms.warranty,
    payment: requisition.generalTerms.payment,
    delivery: requisition.generalTerms.delivery,
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //this is for the snackbar
  const [openSnack, setopenSnack] = React.useState(false);

  const handleClickSnack = () => {
    setopenSnack(true);
  };

  const handleCloseSnack = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenSnack(false);
  };
  return (
    <>
      <Button
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={
          requisition.generalTerms.changed ? (
            <CheckIcon
              sx={{
                color: 'green',
                fontSize: '30px !important',
              }}
            />
          ) : (
            <>
              {isSmallScreen ? (
                <DashboardCustomizeOutlinedIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </>
          )
        }
        variant='outlined'
        disableElevation
        sx={{
          width: isSmallScreen ? '100%' : '192px',
          height: '40px',
          fontSize: '14px !important',
          fontWeight: '400',
          borderColor: 'primary.main',
          '& .MuiButton-endIcon': { position: 'absolute', right: 15 },
        }}
      >
        {t(getRequisitionTranslationKey('titles.general_terms'))}
      </Button>
      <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            height: '100%',
          }}
        >
          {isSmallScreen && (
            <Typography
              component={Button}
              color='text.third'
              fontWeight='400'
              variant='body2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
              onClick={handleClose}
            >
              <ArrowBackIosIcon sx={{ marginRight: 1 }} />
              {t(getRequisitionTranslationKey('titles.general_terms'))}
            </Typography>
          )}
          <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block', margin: 1 }}
          >
            <div>
              {t(getRequisitionTranslationKey('titles.warranty_terms'))}
            </div>
            <TermsSelect
              term='Warranty'
              text={t(getRequisitionTranslationKey('titles.select_warranty_terms'))}
              termName={t(getRequisitionTranslationKey('titles.warranty_terms'))}
              values={WARRANTY_TERMS}
              selectedTerms={selectedTerms}
              setSelectedTerms={setSelectedTerms}
              isSmallScreen={isSmallScreen}
            />
          </Typography>
          <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block', margin: 1 }}
          >
            <div>
              {t(getRequisitionTranslationKey('titles.payment_terms'))}
            </div>
            <TermsSelect
              term='Payment'
              text={t(getRequisitionTranslationKey('titles.select_payment_terms'))}
              termName={t(getRequisitionTranslationKey('titles.payment_terms'))}
              values={PAYMENT_TERMS}
              selectedTerms={selectedTerms}
              setSelectedTerms={setSelectedTerms}
              isSmallScreen={isSmallScreen}
            />
          </Typography>
          <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block', margin: 1 }}
          >
            <div>
              {t(getRequisitionTranslationKey('titles.delivery_terms'))}
            </div>
            <TermsSelect
              term='Delivery'
              text={t(getRequisitionTranslationKey('titles.select_delivery_terms'))}
              termName={t(getRequisitionTranslationKey('titles.delivery_terms'))}
              values={DELIVERY_TERMS}
              selectedTerms={selectedTerms}
              setSelectedTerms={setSelectedTerms}
              isSmallScreen={isSmallScreen}
            />
          </Typography>
          <Box
            {...(isSmallScreen && {
              sx: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              },
            })}
          >
            <Button
              onClick={() => {
                handleClose();
                ChangeGeneralTerms(requisition.id, selectedTerms);
                isSmallScreen && handleClickSnack();
              }}
              variant='contained'
              sx={{
                width: '94%',
                fontWeight: '400',
                margin: 1,
              }}
            >
              {t(getRequisitionTranslationKey('titles.set_general_terms'))}
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                width: '94%',
                borderColor: 'primary.main',
                margin: 1,
              }}
              variant='outlined'
            >
              {t('buttons.close')}
            </Button>
          </Box>
        </Grid>
      </StyledPopper>
      <SavedSnackbar
        handleCloseSnack={handleCloseSnack}
        openSnack={openSnack}
      />
    </>
  );
};
type CustomTermsSelecttProps = {
  requisition: Requisition;
  reqProduct: Product;
  isSmallScreen: boolean;
};

export const CustomTermsSelect: React.VFC<CustomTermsSelecttProps> = ({
  requisition,
  reqProduct,
  isSmallScreen,
  closeMenu,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ChangeCustomTerms = useRequisition(state => state.ChangeCustomTerms);
  const [selectedTerms, setSelectedTerms] = React.useState({
    warranty: reqProduct?.customTerms.warranty,
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setSelectedTerms({
      warranty: reqProduct?.customTerms.warranty,
    });
  }, [reqProduct?.customTerms.warranty]);
  //this is for the snackbar
  const [openSnack, setopenSnack] = React.useState(false);

  const handleClickSnack = () => {
    setopenSnack(true);
  };

  const handleCloseSnack = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setopenSnack(false);
  };
  return (
    <div>
      {isSmallScreen ? (
        <>
          <MenuItem onClick={handleClick}>
            {/* <IconButton onClick={handleClick}> */}
            <DashboardCustomizeOutlinedIcon sx={{ marginRight: 1 }} />
            {t(getRequisitionTranslationKey('titles.custom_terms'))}
          </MenuItem>
        </>
      ) : (
        <Button
          id='demo-positioned-button'
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={
            reqProduct?.customTerms.changed ? (
              <CheckIcon
                sx={{
                  color: 'green',
                  fontSize: '30px !important',
                }}
              />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
          disabled={!reqProduct}
          variant='outlined'
          disableElevation
          sx={{
            width: isSmallScreen ? '100%' : '192px',
            borderColor: 'primary.main',
          }}
        >
          {t(getRequisitionTranslationKey('titles.custom_terms'))}
        </Button>
      )}
      <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px', }}>
          {isSmallScreen && (
            <Typography
              component={Button}
              color='text.third'
              fontWeight='400'
              variant='body2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
              onClick={handleClose}
            >
              <ArrowBackIosIcon sx={{ marginRight: 1 }} />
              {t(getRequisitionTranslationKey('titles.custom_terms'))}
            </Typography>
          )}
          <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={{ display: 'block', margin: 1 }}
          >
            <div>
              {t(getRequisitionTranslationKey('titles.warranty_terms'))}
            </div>
            <TermsSelect
              term={'warranty'}
              text={t(getRequisitionTranslationKey('titles.select_warranty_terms'))}
              termName={t(getRequisitionTranslationKey('titles.warranty_terms'))}
              values={WARRANTY_TERMS}
              selectedTerms={selectedTerms}
              setSelectedTerms={setSelectedTerms}
              isSmallScreen={isSmallScreen}
            />
          </Typography>
          <Box
            {...(isSmallScreen && {
              sx: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              },
            })}
          >
            <Button
              onClick={() => {
                isSmallScreen && handleClickSnack();
                ChangeCustomTerms(
                  requisition.id,
                  reqProduct.tabId,
                  selectedTerms
                );
                closeMenu();
                handleClose();
              }}
              variant='contained'
              sx={{ width: '94%', margin: 1, }}
            >
              {t(getRequisitionTranslationKey('titles.set_custom_terms'))}
            </Button>
            <Button
              onClick={handleClose}
              sx={{ width: '94%', borderColor: 'primary.main', margin: 1, }}
              variant='outlined'
            >
              {t('buttons.close')}
            </Button>
          </Box>
        </Box>
      </StyledPopper>
      <SavedSnackbar
        handleCloseSnack={handleCloseSnack}
        openSnack={openSnack}
      />
    </div>
  );
};
