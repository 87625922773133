import { Product, Seller } from '@/models';
import { NotifySuccess } from '@/services';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { useAdvancedFiltersSlice } from 'src/logic/zustand/catalouge/advanced-filters';

type LinkSellerActionProps = {
  product: Product;
  seller: Seller;
};

const LinkSellerAction = ({ product, seller }: LinkSellerActionProps) => {
  const loadingState = useLoadingState();
  const { linkSellerToProduct } = useAdvancedFiltersSlice(state => state.actions);
  const handleClick = () => {
    loadingState.startLoading();
    linkSellerToProduct(product, seller, {
      onSuccess: () => {
        NotifySuccess('Seller Linked');
        loadingState.finishLoading();
      },
      onError: loadingState.finishLoading,
    });
  };

  return (
    <LoadingButton
      loading={loadingState.isLoading}
      variant='contained'
      color='success'
      type='button'
      fullWidth
      endIcon={<InfoOutlinedIcon />}
      onClick={handleClick}
    >
      Link Seller
    </LoadingButton>
  );
};

export default LinkSellerAction;
