import { useFormikContext } from 'formik';
import React from 'react';
import { LinkOrCreateSellerProps } from 'src/logic/models/catalogue/LinkOrCreateSellerProps';
import { useLinkOrCreateSellersSlice } from 'src/logic/zustand/catalouge/link-create-sellers/LinkOrCreateSellersSlice';

const useLinkOrCreateSellerFormUtils = product_id => {
  const { values, setValues, setFieldValue, resetForm } = useFormikContext<LinkOrCreateSellerProps>();
  const { sellerCompaniesData } = useLinkOrCreateSellersSlice(state => state.properties);
  const { getCompaniesData } = useLinkOrCreateSellersSlice(state => state.actions);

  const onSellerCompanyChange = React.useCallback(
    (name: string) => {
      if (values.seller_id != '') {
        setFieldValue('seller_id', '');
        getCompaniesData(product_id, name);
      } else {
        setFieldValue('seller_id', '');
        setFieldValue('company_name', name);
        getCompaniesData(product_id, name);
      }
    },
    [product_id]
  );

  const onSelectSellerCompany = React.useCallback(
    (seller_id: number) => {
      const sellerCompany = sellerCompaniesData?.find(item => item.id === seller_id);

      if (sellerCompany) {
        setValues({
          ...values,
          seller_id: seller_id,
          company_name: sellerCompany.name ? sellerCompany.name : '',
          country_id: sellerCompany.countryId ? sellerCompany.countryId : '',
          city_id: sellerCompany.cityId ? sellerCompany.cityId : '',
          full_name: sellerCompany.contact_full_name ? sellerCompany.contact_full_name : '',
          email: sellerCompany.email ? sellerCompany.email : '',
          phone_number: sellerCompany.phone_number ? sellerCompany.phone_number : '',
        });
      }
    },
    [sellerCompaniesData, values, setValues, product_id]
  );

  const onClear = React.useCallback(() => {
    // resetForm();
    setValues({
      ...values,
      seller_id: '',
      company_name: '',
      country_id: '',
      city_id: '',
      full_name: '',
      email: '',
      phone_number: '',
      identifier: '',
      existingSellerId: '',
    });
  }, [values, setValues]);

  return React.useMemo(
    () => ({
      onSellerCompanyChange,
      onSelectSellerCompany,
      onClear,
    }),
    [product_id, onSellerCompanyChange, onSelectSellerCompany, onClear]
  );
};

export default useLinkOrCreateSellerFormUtils;
