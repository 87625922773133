import { Grid, Typography, Button, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
import { LabelWithButton, user, TextDataView } from '@/components';
import { sm } from 'src/site-map';
import { Router, useRouter } from 'next/router';
import { UserSettingsWithPopupsSection } from '../user-settings-with-popups';
import { getAccountTranslationKey, getInputsTranslationKey } from '@/hooks';

type Props = { userData: user, anotherUser: boolean };

export const UserSettingsSection: React.FC<Props> = ({
  userData,
  anotherUser
}) => {

  const { t } = useTranslation();
  const router = useRouter()
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));

  return (
    <>
      <Grid item container xs={8}>
        <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left' }}>{t(getAccountTranslationKey('sections_titles.user_settings'))}</Typography>
      </Grid>
      <Grid item container xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!anotherUser && <Button
          onClick={() => router.push(sm.account.editProfile.url)}
          variant='outlined'
          sx={{ maxWidth: isSmallScreen ? '40px' : '174px', minWidth: '40px' }}
        >
          <BorderColorOutlinedIcon sx={{ fontSize: '20px', mr: isSmallScreen ? 0 : 1 }} />
          {isSmallScreen ? '' : t('buttons.edit_data')}
        </Button>}
      </Grid>
      <Grid item xs={12} >
        <hr />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.first_name'))} value={userData.first_name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.last_name'))} value={userData.last_name} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.title'))} value={userData.title} />
      </Grid>
      <Grid item container xs={12} md={6} mt={1}>
        <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.job_title'))} value={userData.job_title} />
      </Grid>
      {anotherUser && (
        <>
          <Grid item container xs={12} md={6} mt={1}>
            <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.phone_number'))} value={userData.mobile_number.number} />
          </Grid>
          <Grid item container xs={12} md={6} mt={1}>
            <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.direct_number'))} value={userData.direct_number} />
          </Grid>
          <Grid item container xs={12} md={6} mt={1}>
            <TextDataView textVariant='body2' title={t(getInputsTranslationKey('labels.user.email'))} value={userData.email} />
          </Grid>
        </>
      )}
      <Grid item xs={12} mt={3} />
      {!anotherUser && <UserSettingsWithPopupsSection userData={userData} />}
    </>
  );
};