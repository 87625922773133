import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';

export const validationSchema =
  yup.object({
    name: yup
      .string()
      .required('required'),
    email: yup
      .string()
      .required('required'),
  })
