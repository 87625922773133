import { getInputsTranslationKey } from '@/hooks';
import { Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import { at } from 'lodash';
import { useTranslation } from 'next-i18next';
import type { FC, ReactNode } from 'react';


interface selectWithPlaceholderProps {
    translateKey: string,
    name: string,
    disabled?: boolean,
    required?: boolean
    items: Array<{
        id: number,
        name: string,
    }>
    MenuItemComponent: ReactNode
    idObjKey: string
    placeholder?: string
}

const selectTextInputStyle = {
    color: 'text.secondary',
    fontSize: '14px !important',
}
const inputStyle = {
    width: '100%',
};
const textInputStyle = {
    bgcolor: 'white',
    color: '#000000',
    width: '100%',
};

export const StyledSelectWithStyledChildren: FC<selectWithPlaceholderProps> = (props: selectWithPlaceholderProps) => {
    const { t } = useTranslation()
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (error && touched) {
            return <Typography variant='subtitle2' >
                {t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
            </Typography>
            // return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
        }
    }

    return (
        <Typography
            component='label'
            color='text.third'
            fontWeight='400'
            variant='subtitle2'
            sx={inputStyle}
        >
            {props.noShowLabel ? null : t(getInputsTranslationKey('labels.' + props.translateKey))}
            <FormControl sx={inputStyle} fullWidth size="small" error={meta.error && meta.touched && true}>
                <Select
                    displayEmpty
                    disabled={props.disabled}
                    renderValue={(value) => {
                        if (!value) {
                            return props.placeholder ? t(getInputsTranslationKey('placeholders.' + props.placeholder)) : ''
                        }
                        return props.items.find(op => op[props.idObjKey].id == value)[props.idObjKey]?.name;
                    }}
                    inputProps={{ sx: (field.value == 0 ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle) }}
                    error={meta.error && meta.touched && true}
                    {...field}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: '160px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                    width: 2,
                                    height: 5,
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: `white`,
                                    margin: 2
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#13628C',
                                    outline: `1px solid slategrey`,
                                    borderRadius: 3,
                                    width: 10,
                                    justifyContent: 'center'
                                },
                            }
                        }
                    }}
                >
                    <MenuItem value={0} disabled style={{ display: "none" }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    <MenuItem value={null} disabled style={{ display: "none" }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    {
                        props.items.map((object) => (
                            <MenuItem value={object[props.idObjKey].id} key={object[props.idObjKey].id} >
                                <props.MenuItemComponent object={object} />
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{_renderHelperText()}</FormHelperText>
            </FormControl>
        </Typography >
    );
};
