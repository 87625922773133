// import React from 'react';
// import { ProductEditPrice } from '../../../../requisition';
// import { useDebounce } from '@/hooks';

// type IProps = {
//   initialValue: string;
//   onChange: (newValue: string) => void;
// };

// const DebouncedEditPrice = ({ initialValue, onChange }: IProps) => {
//   const [priceValue, setPriceValue] = React.useState<string>(initialValue);
//   useDebounce(() => onChange(priceValue), 700, [priceValue]);

//   return (
//     <ProductEditPrice
//       handleChangePrice={(newValue: string) => {
//         if (newValue !== '') {
//           setPriceValue(newValue);
//         }
//       }}
//       value={priceValue}
//       showEndAdornment={false}
//     />
//   );
// };

// export default DebouncedEditPrice;

import React from 'react';
import { ProductEditPrice } from '../../../../requisition';

type IProps = {
  initialValue: string;
  onChange: (newValue: string) => void;
};

const DebouncedEditPrice = ({ initialValue, onChange }: IProps) => {
  return (
    <ProductEditPrice
      handleChangePrice={onChange}
      value={initialValue}
      showEndAdornment={false}
    />
  );
};

export default DebouncedEditPrice;
