import { request } from '@/helpers';

const addProductToCatalog = ({
  catalog_id,
  product_id,
  price,
}: {
  catalog_id: string;
  product_id: number;
  price: number;
}) =>
  request({
    url: `/seller/catalog-draft/update/${catalog_id}`,
    method: 'PUT',
    data: {
      product_id,
      price,
    },
  });

const updateProduct = addProductToCatalog;

const removeProductFromCatalog = ({
  catalog_id,
  product_id,
}: {
  catalog_id: string;
  product_id: number;
}) =>
  request({
    url: `/seller/catalog-draft/delete-product/${catalog_id}`,
    method: 'DELETE',
    data: {
      product_id,
    },
  });

const changePrice = ({
  catalog_id,
  product_id,
  price,
}: {
  catalog_id: string;
  product_id: number;
  price: number;
}) =>
  request({
    url: `/seller/catalog-draft/update/${catalog_id}`,
    method: 'PUT',
    data: {
      product_id,
      price,
    },
  });

const CatalogSavingServices = {
  addProductToCatalog,
  removeProductFromCatalog,
  changePrice,
  updateProduct,
};

export default CatalogSavingServices;
