import { Product } from "@/models";
import { ProcurementFormPermission } from "@/services";
import { useProcurementStore } from "@/zustand";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { SellerCatalogProductsTable } from "../products-table";

interface props { }
export const SellerCatalogProductsList: FC<props> = ({ }) => {
    const { productsTableRows, catalogList } = useProcurementStore(state => state.catalogListSliceProperties)
    const { updateProductPriceFromList, removeProductFromList } = useProcurementStore(state => state.catalogListSliceActions)
    const handleUpdateProductPrice = (product: Product, value: string) => updateProductPriceFromList(product, value)
    const handleRemoveProduct = (product: Product) => removeProductFromList(product)

    const initialValues = productsTableRows
    const handleSubmit = (values, { setErrors, setFieldError }) => {
        console.log(values)
    } 

    return (
        <Grid item container md={12}>
            <Box width={'100%'}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false} validateOnChange={false} enableReinitialize >
                    {formikProps => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <SellerCatalogProductsTable removeProduct={handleRemoveProduct} updateProductPrice={handleUpdateProductPrice} rowsData={productsTableRows} formDetails={catalogList?.model} products={productsTableRows} canEdit={catalogList?.actions[ProcurementFormPermission.EDIT]} />
                        </form>
                    )}
                </Formik>
            </Box>
        </Grid>
    )
}
