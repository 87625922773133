import { AuthenticateStatus, ProcurementButtons, ProcurementPopupStatus, procurementStatus } from "@/config";
import { ProcurementFormModel } from "@/models";
import { ProcurementFormPermission } from "@/services";
import { useSellerProcurementStore, useUserData } from "@/zustand";
import { Button } from "@mui/material";
import { t } from "i18next";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { sm } from "src/site-map";
import { FormMoreOptions, LinkedFormsIcon, SinglePODocument } from "src/ui/components/dumb";
import { ExpiryPopupForChangeResponse, StyledLoadingButton } from "src/ui/components/shared";
import { RelatedFormsList } from "../../../list-linked-forms";
import SignupRequiredPopup from "src/ui/components/dumb/shared/signup-required-popup";
import usePopup from "src/logic/hooks/use-popup";

interface PurchaseOrderSellerHeaderActionsType {
    purchaseOrder: ProcurementFormModel | null
}
export const PurchaseOrderSellerHeaderActions: FC<PurchaseOrderSellerHeaderActionsType> = ({ purchaseOrder }) => {
    const { push, replace } = useRouter()
    const { customLoadingButton, productsTableRows } = useSellerProcurementStore(state => state.purchaseOrderDetailsSliceProperties)
    const { setPopupStatus, requestExtensionForPOExpiredBySeller } = useSellerProcurementStore(state => state.purchaseOrderDetailsSliceActions)
    const handleConfirmAction = () => setPopupStatus(ProcurementPopupStatus.CONFIRM)
    const handleDeclineAction = () => setPopupStatus(ProcurementPopupStatus.DECLINE)
    const [openExpiryPopup, setOpenExpiryPopup] = useState(false)
    const handleCloseExpiryPopup = () => setOpenExpiryPopup(false)
    
    const handleChangeResponse = () => {
        if (purchaseOrder?.model?.form_action_permissions?.can_request_extension !== true) {
            push({ pathname: sm.portals.seller.procurement.purchaseOrders.purchaseOrderId(purchaseOrder?.model.id).url, query: { 'change-response': true } })
        } else {
            setOpenExpiryPopup(true)
        }
    }
    
    const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
    const signupPopup = usePopup();
    const handleClickRequestExtension = () => {
        if(isGuest) {
            signupPopup.handleOpen();
            return;
        }
        requestExtensionForPOExpiredBySeller({ onSuccess: handleCloseExpiryPopup })
    }

    const handleCancelChangeResponse = () => replace(sm.portals.seller.procurement.purchaseOrders.purchaseOrderId(purchaseOrder?.model.id).url, undefined, { shallow: false })
    return (
        <>
            {purchaseOrder?.actions[ProcurementFormPermission.REQUEST_EXTENSION] && <StyledLoadingButton customWidth variant="contained" translateKey={purchaseOrder?.model?.has_pending_extension_request ? "extension_requested" : "request_extension"} loading={customLoadingButton == ProcurementButtons.REQUEST_EXTENSION} onClick={handleClickRequestExtension} />}

            {purchaseOrder?.actions[ProcurementFormPermission.CONFIRM] && <Button variant='contained' color='success' onClick={handleConfirmAction}>
                {t('buttons.confirm_po')}
            </Button>}
            {purchaseOrder?.actions[ProcurementFormPermission.DECLINE] && <Button variant='contained' color='error' onClick={handleDeclineAction}>
                {t('buttons.decline_po')}
            </Button>}
            {purchaseOrder?.actions[ProcurementFormPermission.CHANGE_RESPONSE] && <Button variant='contained' disabled={purchaseOrder?.model?.extension_request_status === procurementStatus.DECLINED || purchaseOrder?.model?.extension_request_status === procurementStatus.PENDING} onClick={handleChangeResponse}>
                {t('buttons.change_response')}
            </Button>}
            {purchaseOrder?.actions[ProcurementFormPermission.CANCEL] && <Button variant='outlined' color='error' onClick={handleCancelChangeResponse}>
                {t('buttons.cancel')}
            </Button>}

            {purchaseOrder?.model?.linkedforms?.length != 0 && <LinkedFormsIcon><RelatedFormsList forms={purchaseOrder?.model?.linkedforms} /></LinkedFormsIcon>}

            <FormMoreOptions
                shareURL={purchaseOrder?.model.share_url}
                pdfDocument={
                    <SinglePODocument
                      po={purchaseOrder}
                      companyDetails={purchaseOrder?.model?.buyer_details}
                      productsTableRows={productsTableRows}
                    />
                  }
            />

            <ExpiryPopupForChangeResponse open={openExpiryPopup} customLoadingButton={customLoadingButton}  handleClosePopup={handleCloseExpiryPopup} handleRequestExtension={handleClickRequestExtension} />
            <SignupRequiredPopup open={signupPopup.isOpen} handleClose={signupPopup.handleClose} />

        </>
    )
}