import { StyledTextField } from "@/components";
import { Grid, Box, Button } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { Formik } from 'formik';
import { initialValues } from './initialValues'
import { validationSchema } from './validationSchema'
// import { FacebookIcon, GoogleIcon } from "@/icons";
import { useUserData } from "src/logic/zustand/user";
import { useLogin } from "@/zustand";
import { getLoginTranslationKey } from "@/hooks";
import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { NotifyError } from "@/services";
import { useRouter } from "next/router";

interface LoginProps { recoverAccount: Function; custom?: boolean, refreshOnLogin?: boolean, onLogin?: Function }

export const LoginForm: React.FC<LoginProps> = (props: LoginProps) => {
    const router = useRouter();
    const { t } = useTranslation()
    const logUserIn = useUserData(state => state.logUserIn);
    const initializeUser = useUserData(state => state.initializeUser);
    const setNeedsVerification = useLogin(state => state.setNeedsVerification)
    const setLoadingLogin = useUserData(state => state.setLoadingLogin)
    const loadingLogin = useUserData(state => state.loadingLogin)

    useEffect(() => { setLoadingLogin(false) }, [])

    const handleSubmit = (values: { email: string; password: string }) => {
        let email = values['email']
        let password = values['password']
        let remember = false
        setLoadingLogin(true)
        logUserIn(email, password, remember)
            .then(() => {
                initializeUser();
                if(props.refreshOnLogin) {
                    router.reload();
                }
                props.onLogin?.();
            })
            .catch((e) => {
                if (e.response.data.status == 409 || e.response.data.status == 404) {
                    let errorCode = e.response.data.error.code;
                    if (errorCode == 'AUTH0002')
                        setNeedsVerification(email)
                    else
                    NotifyError(t(getLoginTranslationKey('notifications.' + e.response.data.error.message)))
                } else {
                    NotifyError(t('notifications.server_error'))
                }
                setLoadingLogin(false)
            })
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Grid item xs={12}>
                        <StyledTextField translateKey='user.email' name="email" showLabel />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <StyledTextField type="password" translateKey='user.password' name="password" showLabel />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="text" sx={{ fontWeight: 400 }} onClick={() => props.recoverAccount()}>
                                <u>{t('buttons.forget_password')}</u>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant='contained'
                            size='large'
                            color={props.custom ? 'primary' : 'secondary'}
                            fullWidth
                            sx={{ mt: 2 }}
                            loading={loadingLogin}
                            type="submit"
                        >
                            {t('buttons.login')}
                        </LoadingButton>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography
                            variant='subtitle2'
                            color='textSecondary'
                            sx={{ my: 2, textAlign: 'center' }}
                        >
                            Or
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} sx={{ mt: 2 }}>
                        <Grid item xs={6} md={6} sx={{ pr: 0.1 }}>
                            <Button
                                variant='outlined'
                                size='small'
                                startIcon={<GoogleIcon />}
                                fullWidth
                            >
                                <Typography variant='subtitle2' fontWeight='500'>Login with Google</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={6} sx={{ pl: 0.1 }}>
                            <Button
                                variant='outlined'
                                size='small'
                                startIcon={<FacebookIcon />}
                                fullWidth
                            >
                                <Typography variant='subtitle2' fontWeight='500'>Login with Facebook</Typography>
                            </Button>
                        </Grid>
                    </Grid> */}
                </form>
            )}
        </Formik>
    )
}