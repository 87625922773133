import { Button, Divider, Grid, Stack } from "@mui/material"
import { useTranslation } from 'next-i18next';
import { GeneralObject } from "@/models";
import React from "react";
import { StyledPopper } from "../../styled-popper-menu";
import { KeyboardArrowDown } from "@mui/icons-material";


type ButtonWithDropdownMenuProps = {
    translateKey: string;
    dropDownItems: GeneralObject[]
    onClickItem: (item: GeneralObject) => void
    translateKeySingleItem?: string
}

export const ButtonWithDropdownMenu: React.VFC<ButtonWithDropdownMenuProps> = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!isOneItem) setAnchorEl(event.currentTarget)
        else props.onClickItem(props.dropDownItems[0])
    }
    const handleCloseMenu = () => setAnchorEl(null)
    const open = Boolean(anchorEl);
    const isOneItem = props.dropDownItems?.length === 1
    return (
        <>
            <Button variant="containedInfo" onClick={handleOpenMenu} endIcon={!isOneItem ? <KeyboardArrowDown /> : null}>
                {isOneItem && props.translateKeySingleItem ? t("buttons." + props.translateKeySingleItem) : t("buttons." + props.translateKey)}
            </Button>
            <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleCloseMenu} place={'bottom-start'}>
                <Grid container width="250px" mt={3} p={2}>
                    <Stack maxHeight={200} width='100%'>
                        {props.dropDownItems.map((dropDownItem, i) =>
                            <Grid item md={12}>
                                <Button fullWidth sx={{ justifyContent: 'start' }} onClick={() => props.onClickItem(dropDownItem)}>{dropDownItem.name}</Button>
                                {i != props.dropDownItems.length - 1 && <Divider />}
                            </Grid>
                        )}
                    </Stack>
                </Grid>
            </StyledPopper>
        </>
    )
}