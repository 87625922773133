import React, { useState } from 'react';
import { Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import usePopup from 'src/logic/hooks/use-popup';
import { useAdvancedFiltersSlice } from 'src/logic/zustand/catalouge/advanced-filters';
import { useLinkOrCreateSellersSlice } from 'src/logic/zustand/catalouge/link-create-sellers/LinkOrCreateSellersSlice';
import { LinkOrCreateSellersPopup } from 'src/ui/components/smart/product/product-details-header/link-or-create-sellers-popup/link-sellers-popup';

type LinkMultipleSellerActionProps = {
  product: Product;
};

const LinkMultipleSellerAction = ({ product }: LinkMultipleSellerActionProps) => {
  const linkPopup = usePopup();
  const { linkMultipleSellerToProduct } = useAdvancedFiltersSlice(state => state.actions);
  const { fetchSellersQueryStatus, existingSellersInReq, getUnlinkedSellers } = useProcurementStore();
  const { getAllLocations } = useLinkOrCreateSellersSlice(state => state.actions);

  const [newSellers, setNewSellers] = useState<any[]>([]);

  const handleOpenPopup = () => {
    getUnlinkedSellers(product?.id);
    getAllLocations();
    linkPopup.handleOpen();
  };

  const handleSubmit = (sellers: any) => {
    setNewSellers(prevSellers => [...prevSellers, ...sellers]);
  };

  React.useEffect(() => {
    if (!linkPopup.isOpen && newSellers.length > 0) {
      linkMultipleSellerToProduct(product?.id, newSellers, {
        onSuccess: () => {
          setNewSellers([]);
        },
        onError: err => {
          console.log(err);
        },
      });
    }
  }, [newSellers]);

  const handleClosePopup = () => {
    linkPopup.handleClose();
  };

  return (
    <>
      <LoadingButton
        variant='contained'
        color='success'
        type='button'
        fullWidth
        endIcon={<InfoOutlinedIcon />}
        onClick={handleOpenPopup}
      >
        <span>
          Link Seller <span style={{ textTransform: 'lowercase' }}>(s)</span>
        </span>
      </LoadingButton>
      <LinkOrCreateSellersPopup
        service={'requisition'}
        open={linkPopup.isOpen}
        LoadingFetchStatus={fetchSellersQueryStatus}
        productId={product?.id}
        onSuccess={handleSubmit}
        existingSellers={existingSellersInReq}
        closePopup={handleClosePopup}
      />
    </>
  );
};

export default LinkMultipleSellerAction;
