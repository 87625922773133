import React from 'react';

import { useGetNotifications } from 'src/logic/services/notification';
import NotificationsQueryStatus from './NotificationsQueryStatus';
import NotificationMenuItem from './NotificationMenuItem';
import { Divider, Typography } from '@mui/material';
import useOnReachBottomScroll from 'src/logic/hooks/use-on-reach-bottom-scroll';

const NotificationMenuContent = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const {
    notifications,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useGetNotifications();

  const containerRef = React.useRef<HTMLDivElement>(null);
  useOnReachBottomScroll({
    ref: containerRef,
    offset: 10,
    onBottomReached: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const isEmpty = notifications.length === 0;

  if (isLoading || isError || isEmpty) {
    return (
      <NotificationsQueryStatus
        isLoading={isLoading}
        isError={isError}
        refetch={refetch}
        isEmpty={isEmpty}
      />
    );
  }
  return (
    <div
      style={{
        maxHeight: '400px',
        overflow: 'auto',
      }}
      className='styled-scrollbar'
      ref={containerRef}
    >
      {notifications.map((notification, index) => (
        <React.Fragment key={notification.id}>
          <NotificationMenuItem
            key={notification.id}
            item={notification}
            handleClose={handleClose}
          />
          {index < notifications.length - 1 && (
            <Divider
              color='#EAEEF6'
              style={{ marginInline: '1rem', marginBlock: 0 }}
            />
          )}
        </React.Fragment>
      ))}
      {hasNextPage && (
        <Typography
          color='#89B1C6'
          fontWeight={400}
          variant='subtitle1'
          textAlign={'center'}
          style={{ paddingTop: '5px', opacity: isFetchingNextPage ? 1 : 0 }}
        >
          Loading more...
        </Typography>
      )}
    </div>
  );
};

export default NotificationMenuContent;
