import React from 'react';

const useLoadingState = (initialState: boolean = false) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(initialState);

  const startLoading = React.useCallback(() => setIsLoading(true), []);
  const finishLoading = React.useCallback(() => setIsLoading(false), []);

  return React.useMemo(
    () => ({
      isLoading,
      startLoading,
      finishLoading,
    }),
    [isLoading, startLoading, finishLoading]
  );
};

export default useLoadingState;
