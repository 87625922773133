import React from 'react';
import { StyledTable } from '@/components';

type QuotesTablePageForBuyerProps = {
    key: string
    // rowsData: 
}


export const QuotesTablePageForBuyer: React.FC<QuotesTablePageForBuyerProps> = ({ }) => {

    return (
        <>
            {/* <StyledTable key={key} rows={rowsData.tableRows} columns={columns} /> */}
        </>
    )
}
