
import { AuthenticateStatus, ProcurementButtons, ProcurementForms, procurementStatus } from '@/config';
import { GeneralObject, ProductTableRow, RFQ, RFQDetails, RFQProduct } from '@/models';
import { useSellerProcurementStore, useUserData } from '@/zustand';
import { Close } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import { CommentsNumber, FormMoreOptions, LinkedFormsIcon, RFQProductCard, RFQProductsTable, StatusChip, TotalSection } from 'src/ui/components/dumb';
import { RFQSellerPDFDocument } from 'src/ui/components/dumb/pdf-documents/rfq-seller-document';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import { RfqProductsTableHeader } from 'src/ui/components/dumb/shared/rfq-products-table-header';
import { ConfirmPopup, ExpiryPopupForChangeResponse, StyledBreadcrumbs, StyledLoadingButton } from 'src/ui/components/shared';
import GeneralTerms from 'src/ui/components/smart/general-terms/GeneralTerms';
import { FormHeaderCompanyDetails } from '../../../form-header-company-details';
import { FormHeaderDetails } from '../../../form-header-details';
import { RelatedFormsList } from '../../../list-linked-forms';
import { CustomPage } from '../../../recalled-page';
import { RFQSellerDeclineAction } from '../rfq-popups';
import useProductsAndServicesCount from 'src/logic/hooks/smart/products-and-services/use-products-and-services-count';
import SignupRequiredPopup from 'src/ui/components/dumb/shared/signup-required-popup';


type RFQDetailsSectionProps = {
    requestForQuote: RFQDetails
    numberOfSelectedProducts: number
    getRFQData: () => void
    setRFQData: (rfq: RFQ, isEditMode: boolean) => void
    productsTableRows: ProductTableRow[]
    summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
    isEditMode: boolean
    isAllProductsSelected: boolean
    quoteId?: number
    quoteName?: string
    isGeneratingQuote: boolean;
    startGeneratingQuote: () => void;
    cancelGeneratingQuote: () => void;
    onSubmitRequested: () => void;
    hideComments?: boolean
    hideCancel?: boolean
    hideProject, hideBreadcrumbs?: boolean
    hideBreadcrumbs?: boolean
};
export const RFQDetailsSection: FC<RFQDetailsSectionProps> = ({ requestForQuote, numberOfSelectedProducts, isAllProductsSelected, getRFQData, productsTableRows, summaryDetails, isEditMode, setRFQData, quoteId, quoteName, isGeneratingQuote, startGeneratingQuote, cancelGeneratingQuote, onSubmitRequested, hideComments, hideCancel, hideProject, hideBreadcrumbs }) => {
    const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
    const { t } = useTranslation();
    const { push } = useRouter()
    const signupPopup = usePopup();

    const { customLoadingButton } = useSellerProcurementStore(state => state.requestForQuoteSliceProperties)
    const { editExpiryDate, requestExtensionForRFQExpiredBySeller, changeProject: _changeProject } = useSellerProcurementStore(state => state.requestForQuoteSliceActions)
    const changeProject = (project: GeneralObject) => {
        _changeProject(project, isEditMode);
    }
    const [openExpiryPopup, setOpenExpiryPopup] = useState(false)
    const handleCloseExpiryPopup = () => setOpenExpiryPopup(false)
    const changeResponse = () => {
        if (requestForQuote?.rfqModel?.form_action_permissions?.can_request_extension !== true) {
            setRFQData(requestForQuote.rfqModel, true)
        } else {
            setOpenExpiryPopup(true)
        }
    }
    const cancelEditing = () => setRFQData(requestForQuote.rfqModel, false)

    const handleClickRequestExtension = () => {
        if(isGuest) {
            signupPopup.handleOpen();
            return;
        }
        requestExtensionForRFQExpiredBySeller({ onSuccess: handleCloseExpiryPopup })
    }

    function IfShowStatusChip(status) {
        let ifShowStatus = isEditMode || (status == procurementStatus.RECALLED || status == procurementStatus.REJECTED_BY_ME || status == procurementStatus.APPROVED_BY_ME || status == procurementStatus.PENDING_MY_APPROVAL)
        if (status === procurementStatus.DECLINED) {
            ifShowStatus =
                (requestForQuote.rfqModel?.extension_request_status !== procurementStatus.DECLINED || requestForQuote.rfqModel?.extension_request_status === null) &&
                (requestForQuote.rfqModel?.extension_request_status !== procurementStatus.PENDING || requestForQuote.rfqModel?.extension_request_status === null);
        }
        return ifShowStatus
    }

    const handleCancelEdit = () => {
        if (quoteId) {
            if(isGuest) {
                push(sm.portals.external.seller.procurement.quotes.quoteId(quoteId).url)
            } else {
                push(sm.portals.seller.procurement.quotes.quoteId(quoteId).url)
            }
        } else {
            cancelEditing()
        }
    }

    const editValidityPopper = usePopup()
    const isChangingResponse = requestForQuote.rfqModel?.status === procurementStatus.DECLINED && isEditMode
    let customPage = requestForQuote?.rfqModel?.status == procurementStatus.RECALLED

    const canExternalChangeResponse = isGuest && requestForQuote.rfqModel?.status === procurementStatus.DECLINED;
    const onExternalChangeResponse = () => {
        signupPopup.handleOpen();
    }

    const cancelEditWarningPopup = usePopup(); //onConfirm: cancelGeneratingQuote

    const rawProducts = React.useMemo(() => {
        return requestForQuote?.rfqModel?.products?.map(prod => prod.product) ?? []
    }, [requestForQuote])
    const { numberOfProducts, numberOfServices } = useProductsAndServicesCount(rawProducts)

    return (
        <>
            {
                requestForQuote && <>
                    {!hideBreadcrumbs && <Grid item container md={12} my={1}>
                        <StyledBreadcrumbs path={sm.portals.seller.procurement.requestForQuotes.index.url} showBackButton alias={sm.portals.seller.procurement.requestForQuotes.requestForQuote('*').url} dynammicAlias={[requestForQuote?.rfqModel.name]} />
                    </Grid>}

                    {customPage ? <>
                        <>
                            <CustomPage formName={ProcurementForms.RFQ} status={requestForQuote?.rfqModel?.status} text={"This RFQ no longer exists because it was recalled by the buyer"}>
                               {!hideComments && <CommentsNumber formDetails={requestForQuote?.rfqModel} title={requestForQuote?.rfqModel?.name ?? ''} commentType={requestForQuote?.rfqModel?.commentable_type ?? ''} commentId={requestForQuote?.rfqModel?.id ?? ''} custom={true} />}
                            </CustomPage>
                        </>
                    </> : <>
                        {/* <PageDetailsHeader> */}
                        <Box sx={{ width: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px' }}>
                            <Grid container md={12} px={2}>
                                <Grid item container md={5} alignItems="center" gap={1} py={1} >
                                    {/* <Typography variant="h6" fontWeight={400} ml={2}>{t('table_cells.rfq')}: </Typography> */}
                                    <Typography variant="h5" fontWeight={600} mr={3}>{isEditMode ? (quoteName ?? requestForQuote?.rfqModel?.name) : requestForQuote?.rfqModel?.name}{isGeneratingQuote && <>: <strong style={{ fontSize: "1.1em" }}>{t('buttons.select_products')}</strong></>}</Typography>
                                    {IfShowStatusChip(requestForQuote?.rfqModel.status) && <StatusChip status={isEditMode ? procurementStatus.EDITING : requestForQuote?.rfqModel.status} />}
                                    {!IfShowStatusChip(requestForQuote?.rfqModel.status) ?
                                        requestForQuote?.rfqModel?.extension_request_status ?
                                            requestForQuote?.rfqModel?.is_expired && requestForQuote?.rfqModel?.extension_request_status === procurementStatus.DECLINED ? <Chip label={t('notifications.request_extension_declined')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                                                : requestForQuote?.rfqModel?.is_expired && requestForQuote?.rfqModel?.extension_request_status === procurementStatus.PENDING ? <Chip label={t('notifications.extension_requested')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(251, 127, 13, 1)', bgcolor: 'white', border: `1px solid rgba(251, 127, 13, 1)` }} />
                                                    : null
                                            : requestForQuote?.rfqModel?.is_expired ? <Chip label={t('chip.EXPIRED')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                                                : <></> : <></>
                                    }
                                </Grid>
                                <Grid item container gap={1} md={7} alignItems="center" justifyContent={'end'} >
                                    {requestForQuote?.canRequestExtension && <StyledLoadingButton type="button" customWidth variant="contained" translateKey={"request_extension"} loading={customLoadingButton == ProcurementButtons.REQUEST_EXTENSION} onClick={handleClickRequestExtension} />}

                                    {isEditMode &&
                                        <Grid container md={4} alignItems="center" justifyContent={'end'}>
                                            <StyledLoadingButton onClick={onSubmitRequested} customWidth variant="contained" translateKey={isChangingResponse ? "issue_quote" : "save_changes"} loading={customLoadingButton == ProcurementButtons.GENERATE_QUOTE} color={'success'} />

                                            {!hideCancel && <Button color='error' sx={{ mt: 0.5, ml: 2, backgroundColor: 'inherit', borderColor: 'error.main', '&:hover': { backgroundColor: 'inherit' } }}
                                                startIcon={<ClearIcon sx={{ mr: -1 }} />} onClick={handleCancelEdit} >
                                                {t("buttons.cancel")}
                                            </Button>}
                                            {/* <Button color='error' variant="outlined" sx={{ mr: 1, backgroundColor: 'inherit', borderColor: 'error.main' }} onClick={cancelEditing} >
                        {t("buttons.cancel")}
                    </Button> */}
                                        </Grid>
                                    }


                                    {requestForQuote?.canGenerateQuote && !isEditMode && !isGeneratingQuote && (
                                        <>
                                            <RFQSellerDeclineAction requestForQuote={requestForQuote} />
                                            <StyledLoadingButton onClick={startGeneratingQuote} type={'button'} customWidth variant="contained" translateKey="generate_quote" loading={false} color={'success'} />
                                        </>
                                    )}
                                    {requestForQuote?.canGenerateQuote && !isEditMode && isGeneratingQuote && (
                                        <>
                                            <StyledLoadingButton type={'submit'} onClick={onSubmitRequested} customWidth variant="contained" translateKey="issue_quote" loading={customLoadingButton == ProcurementButtons.GENERATE_QUOTE} color={'primary'} />
                                            {!hideCancel && <Button type="button" color="error" onClick={cancelEditWarningPopup.handleOpen} startIcon={<Close />}>{t("buttons.cancel")}</Button>}
                                        </>
                                    )}

                                    {requestForQuote?.canChangeResponse && <Button variant='contained' disabled={requestForQuote?.rfqModel?.extension_request_status === procurementStatus.DECLINED || requestForQuote?.rfqModel?.extension_request_status === procurementStatus.PENDING} onClick={changeResponse}>
                                        {t('buttons.change_response')}
                                    </Button>}

                                    {canExternalChangeResponse && <Button variant='contained' disabled={requestForQuote?.rfqModel?.extension_request_status === procurementStatus.DECLINED || requestForQuote?.rfqModel?.extension_request_status === procurementStatus.PENDING} onClick={onExternalChangeResponse}>
                                        {t('buttons.change_response')}
                                    </Button>}
                                    {/* {requestForQuote?.canViewQuote && <Button variant='contained' onClick={redirectToQuote}>
                                        {t('buttons.view_quote')}
                                    </Button>} */}

                                    {requestForQuote?.rfqModel?.linkedforms && requestForQuote.rfqModel.linkedforms.length != 0 && <LinkedFormsIcon><RelatedFormsList forms={requestForQuote?.rfqModel?.linkedforms} /></LinkedFormsIcon>}

                                    {!isEditMode && <>
                                        <FormMoreOptions
                                            shareURL={requestForQuote?.rfqModel?.share_url}
                                            pdfDocument={<RFQSellerPDFDocument rfq={requestForQuote?.rfqModel} productsTableRows={productsTableRows} companyDetails={requestForQuote?.rfqModel?.buyer_details} />}
                                        />
                                        {/* <ExitForm exitFormHandler={() => push(sm.portals.seller.procurement.requestForQuotes.index.url)} /> */}
                                    </>}
                                </Grid>
                                <Grid item container md={12}>
                                    <FormHeaderDetails numberOfProducts={numberOfProducts} numberOfServices={numberOfServices} hideProject={hideProject} hideComments={hideComments} formModel={requestForQuote?.rfqModel} updateProject={changeProject} projectDetailsUrl={sm.portals.seller.projects.projectId(requestForQuote?.rfqModel?.receiver_project?.id?.toString()).url} senderProject={requestForQuote?.rfqModel?.receiver_project?.name} senderProjectId={requestForQuote?.rfqModel?.receiver_project?.id} receiverProject={requestForQuote?.rfqModel?.sender_project?.name} />
                                </Grid>
                                <Grid item md={12}>
                                    <Divider sx={{ opacity: 0.7, my: 1 }} />
                                </Grid>
                                <Grid item container md={12}>
                                    <FormHeaderCompanyDetails showNumberOfCompetitors formType={'RFQ'} title="Buyer" companyDetails={requestForQuote?.rfqModel?.buyer_details} formModel={requestForQuote?.rfqModel} customLoadingButton={customLoadingButton} onEditExpiryDate={(validityDate: string, handleClose: () => void) => editExpiryDate(validityDate, handleClose)} editValidityPopper={editValidityPopper} />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* </PageDetailsHeader> */}

                        <Grid item container md={12} my={1} justifyContent="flex-end">
                            <GeneralTerms mode='view-only' warranty_term={requestForQuote?.rfqModel?.terms_bundle?.warranty_term} payment_term={requestForQuote?.rfqModel?.terms_bundle?.payment_term} delivery_term={requestForQuote?.rfqModel?.terms_bundle?.delivery_term} />
                        </Grid>

                        <Grid item container md={12} p={1} mt={1} justifyContent="space-between">
                            <Typography variant='overline' fontWeight={700}>Products / Services <PrimaryBadge>{requestForQuote.numberOfProducts}</PrimaryBadge></Typography>
                            {requestForQuote.canGenerateQuote && numberOfSelectedProducts != 0 && (isGeneratingQuote || isEditMode) && <Typography variant='body2' fontWeight={400} color='text.third'>
                                {numberOfSelectedProducts == 1 ? numberOfSelectedProducts + " " + 'Product' + " " + t('texts.selected') : numberOfSelectedProducts + " " + t('texts.products') + " " + t('texts.selected')}
                            </Typography>}
                        </Grid>

                        {(requestForQuote.canGenerateQuote && (isGeneratingQuote || isEditMode)) ?
                            <FieldArray name="products" key={productsTableRows + numberOfSelectedProducts}>
                                {arrayHelpers => (
                                    <RFQProductsTable
                                        name="products"
                                        handleAdd={arrayHelpers.push}
                                        handleRemove={arrayHelpers.remove} />
                                )}
                            </FieldArray>
                            : <Grid item container md={12} gap={1}>
                                <RfqProductsTableHeader />
                                {requestForQuote?.rfqModel?.products.map((element: RFQProduct) => <RFQProductCard withDetailsPopup rfqData={element} warrantyGeneralTerm={requestForQuote?.rfqModel?.terms_bundle.warranty_term} />)}
                            </Grid>
                        }
                        {(isGeneratingQuote || isEditMode) && <Grid item container md={12} mt={3}>
                            <TotalSection title={t('texts.order_summary')} position={'end'} subtotalValue={summaryDetails.SummarySubtotal} vatValue={summaryDetails.SummaryVat} totalValue={summaryDetails.SummaryTotal} />
                        </Grid>}


                        <ExpiryPopupForChangeResponse open={openExpiryPopup} customLoadingButton={customLoadingButton} handleClosePopup={handleCloseExpiryPopup} handleRequestExtension={handleClickRequestExtension} />

                    </>
                    }
                </>
            }

            <ConfirmPopup
                icon={<ErrorOutlineIcon color='error' fontSize='large' />}
                titleKey={t('popups.titles.cancel_select_products')}
                subTitleKey={t('popups.subTitles.cancel_select_products')}
                buttonTitleKey={"Yes, I'm sure"}
                handleClose={cancelEditWarningPopup.handleClose}
                handleConfirm={() => {
                    cancelGeneratingQuote();
                    cancelEditWarningPopup.handleClose();
                }}
                open={cancelEditWarningPopup.isOpen}
            />

            <SignupRequiredPopup open={signupPopup.isOpen} handleClose={signupPopup.handleClose} />
        </>
    )
}