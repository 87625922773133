import * as yup from 'yup'

export const validationSchema = yup.object({
    email: yup
        .string()
        .required('required')
        .email('email'),
    password: yup
        .string()
        .required('required')
});