import { SvgIconProps, SvgIcon } from '@mui/material';

export const IconVisibility: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width="14" 
    height="12" 
    viewBox="0 0 14 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
    
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9987 0.666992C9.3906 0.666992 11.5023 2.2886 13.3395 5.42933L13.4768 5.66774L13.6654 6.00033L13.3395 6.57132C11.5023 9.71205 9.3906 11.3337 6.9987 11.3337C4.6068 11.3337 2.49512 9.71205 0.65786 6.57132L0.52058 6.33291L0.332031 6.00033L0.52058 5.66774C2.38971 2.3707 4.547 0.666992 6.9987 0.666992ZM6.9987 2.00033C5.19664 2.00033 3.48422 3.27735 1.86891 6.00033C3.48422 8.7233 5.19664 10.0003 6.9987 10.0003C8.75182 10.0003 10.4179 8.79371 11.9901 6.23014L12.1285 6.00041L11.9898 5.77055C10.4153 3.20545 8.74928 2.00033 6.9987 2.00033ZM6.9987 4.00033C8.10327 4.00033 8.9987 4.89576 8.9987 6.00033C8.9987 7.1049 8.10327 8.00033 6.9987 8.00033C5.89413 8.00033 4.9987 7.1049 4.9987 6.00033C4.9987 4.89576 5.89413 4.00033 6.9987 4.00033Z" />
  
  </SvgIcon>
);