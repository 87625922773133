import PlusIcon from '@mui/icons-material/Add';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useRef } from 'react';
import useImagePreview from 'src/logic/hooks/use-image-preview';

interface UploadPhotoProps {
  name: string;
  preview_name: string;
  translateKey: string;
}

export const UploadPhoto: FC<UploadPhotoProps> = (props: UploadPhotoProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ name: props.name });

  const [previewField] = useField({ name: props.preview_name });
  const { preview, handleImageChange, setPreview } = useImagePreview(previewField.value);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const onBoxClick = e => {
    if (e.target.id == 'deleteIcon') {
      return;
    }
    if (inputFileRef.current !== null) inputFileRef.current.click();
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.currentTarget.files[0]);
    handleImageChange(e);
  };

  const deleteFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(null);
    setPreview(null);
  };

  const boxStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.primary.main',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <Box
      onClick={onBoxClick}
      border='1px dashed'
      borderColor={'primary.main'}
      borderRadius={'8px'}
      p='20px'
      sx={boxStyle}
      style={{
        backgroundImage: `url("${preview}")`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <input
          name={props.name}
          onChangeCapture={onFileChange}
          ref={inputFileRef}
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
        />
        {preview ? (
          <Button
            type='button'
            variant='containedInfo'
            sx={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              zIndex: 5,
              backgroundColor: '#EAEEF6',
              '&:hover': {
                backgroundColor: '#EAEEF6',
              },
            }}
            startIcon={<BorderColorOutlinedIcon fontSize='small' />}
          >
            Edit
          </Button>
        ) : (
          <>
            <Box
              p='5px'
              mb='10px'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.primary.hover',
                borderRadius: '8px',
              }}
            >
              <PlusIcon color='primary' />
            </Box>
            <Typography
              component='label'
              color={'primary.main'}
              fontWeight='600'
              variant='subtitle2'
              sx={{ display: 'inline' }}
            >
              {t('buttons.' + props.translateKey)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
