import { SvgIconProps, SvgIcon } from '@mui/material';

export const UnauthorizeIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    sx={{ width: '25px', height: '24px', p:0  }}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path d="M12.6666 14.0001L11.6666 15.3334H16L16.1175 15.3368C17.1285 15.3954 17.938 16.2049 17.9966 17.2159L18.0044 17.4112C18.043 17.7427 18.3247 18.0001 18.6666 18.0001C19.0348 18.0001 19.3333 17.7016 19.3333 17.3334C19.3333 15.4925 17.8409 14.0001 16 14.0001H12.6666Z" fill="#FF4D4D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.6666 8.66675C16.6666 6.45761 14.8758 4.66675 12.6666 4.66675C10.4575 4.66675 8.66663 6.45761 8.66663 8.66675C8.66663 9.78507 9.12556 10.7962 9.86537 11.5221C9.94757 11.5221 10.6666 11.2045 11.465 11.5221C12.2633 11.8396 12.3333 12.6667 12.6666 12.6667C14.8758 12.6667 16.6666 10.8759 16.6666 8.66675ZM9.99996 8.66675C9.99996 7.19399 11.1939 6.00008 12.6666 6.00008C14.1394 6.00008 15.3333 7.19399 15.3333 8.66675C15.3333 10.1395 14.1394 11.3334 12.6666 11.3334C11.1939 11.3334 9.99996 10.1395 9.99996 8.66675Z" fill="#FF4D4D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.94279 16.0002L11.1381 13.8049C11.3988 13.5442 11.3988 13.1229 11.1381 12.8622C10.8775 12.6016 10.4561 12.6016 10.1955 12.8622L8.00013 15.0576L5.80479 12.8622C5.54413 12.6016 5.12279 12.6016 4.86213 12.8622C4.60146 13.1229 4.60146 13.5442 4.86213 13.8049L7.05746 16.0002L4.86213 18.1956C4.60146 18.4562 4.60146 18.8776 4.86213 19.1382C4.99213 19.2682 5.16279 19.3336 5.33346 19.3336C5.50413 19.3336 5.67479 19.2682 5.80479 19.1382L8.00013 16.9429L10.1955 19.1382C10.3255 19.2682 10.4961 19.3336 10.6668 19.3336C10.8375 19.3336 11.0081 19.2682 11.1381 19.1382C11.3988 18.8776 11.3988 18.4562 11.1381 18.1956L8.94279 16.0002Z" fill="#FF4D4D" />
    </g>
    <defs>
      <filter id="filter0_d_8815_11860" x="0" y="0" width="24" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8815_11860" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8815_11860" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);
