import { Theme } from '@mui/material';
import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { PropsWithChildren } from 'react';

// container spacing
const cs = [2, 2, 2, 4, 6];

const func =
  ({ spacing }: Theme, margin = false) =>
  (num: number) =>
    margin
      ? {
          marginRight: spacing(num),
          marginLeft: spacing(num),
        }
      : {
          paddingRight: spacing(num),
          paddingLeft: spacing(num),
        };

export type ResponsiveContainerProps = {
  m?: boolean;
};

export const ResponsiveContainer = styled(
  (props: PropsWithChildren<ResponsiveContainerProps>) => <div {...props} />
)(({ theme, m }) => {
  const py = func(theme);
  const my = func(theme, true);
  return m
    ? {
        [theme.breakpoints.up('xl')]: my(cs[4]),
        [theme.breakpoints.down('xl')]: my(cs[3]),
        [theme.breakpoints.down('lg')]: my(cs[2]),
        [theme.breakpoints.down('md')]: my(cs[1]),
        [theme.breakpoints.down('sm')]: my(cs[0]),
      }
    : {
        [theme.breakpoints.up('xl')]: py(cs[4]),
        [theme.breakpoints.down('xl')]: py(cs[3]),
        [theme.breakpoints.down('lg')]: py(cs[2]),
        [theme.breakpoints.down('md')]: py(cs[1]),
        [theme.breakpoints.down('sm')]: py(cs[0]),
      };
});

const createRS = () => {
  return {
    p: {
      pl: cs,
      pr: cs,
    } as SxProps<Theme>,
    pl: {
      pl: cs,
    } as SxProps<Theme>,
    pr: {
      pr: cs,
    } as SxProps<Theme>,
    m: {
      ml: cs,
      mr: cs,
    } as SxProps<Theme>,
    ml: {
      ml: cs,
    } as SxProps<Theme>,
    mr: {
      ml: cs,
    } as SxProps<Theme>,
  };
};

export const responsiveContainer = createRS();
