import { useAnimateOnView, useFullItemScrollInViewPort } from '@/hooks';
import { ResponsiveContainer } from '@/styles';
import { mergeRefs } from '@/utils';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import Image from 'next/image';
import { motion, useTransform } from 'framer-motion';
import React from 'react';
import { RGB, LAYER, CM } from 'public/assets/icons';
import { useTranslation } from 'next-i18next';
import { getBecomeSellerTranslationKey } from '@/hooks';

type Props = {};

export const HowItWorksBecomeASellerSection: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();

  const [ref1, node1] = useFullItemScrollInViewPort();
  const x1 = useTransform(node1, [0, 1], [20, -20]);

  const [ref2, node2] = useFullItemScrollInViewPort();
  const x2 = useTransform(node2, [0, 1], [-20, 20]);

  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });

  return (
    <ResponsiveContainer
      sx={{
        position: 'relative',
        py: 8,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: 'calc(100% )',
          left: 0,
          bottom: 0,
          zIndex: -1,
          bgcolor: 'primary.main',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '80%',
          height: '100%',
          zIndex: -1,
          backgroundImage: 'url(/assets/images/home/points.svg)',
          opacity: 0.1,
          backgroundPosition: 'center',
        }}
      />
      <Typography
        component={motion.div}
        {...first}
        variant='h2'
        textAlign='center'
        fontWeight='700'
        color='white'
      >
        {t(getBecomeSellerTranslationKey('titles.how_it_works'))}
      </Typography>
      <Typography
        component={motion.div}
        {...second}
        // ref={mergeRefs(ref2, second.ref)}
        // style={{ x: x2 } as any}
        color='action.disabledBackground'
        textAlign='center'
        maxWidth='100%'
        variant='body2'
        fontWeight='400'
      >
        {t(getBecomeSellerTranslationKey('sub_titles.how_it_works'))}
      </Typography>
      <Grid container spacing={2} pt={6} justifyContent='center'>

        <HowItWorksBecomeASellerCard
          title={t(getBecomeSellerTranslationKey('titles.select_products'))}
          subTitle={t(getBecomeSellerTranslationKey('sub_titles.select_products'))}
          icon={LAYER}
        />
        <HowItWorksBecomeASellerCard
          title={t(getBecomeSellerTranslationKey('titles.receive_RFQ'))}
          subTitle={t(getBecomeSellerTranslationKey('sub_titles.receive_RFQ'))}
          icon={RGB}
        />
        <HowItWorksBecomeASellerCard
          title={t(getBecomeSellerTranslationKey('titles.receive_purchase'))}
          subTitle={t(getBecomeSellerTranslationKey('sub_titles.receive_purchase'))}
          icon={CM}
        />

      </Grid>
    </ResponsiveContainer>
  );
};


const HowItWorksBecomeASellerCard: React.FC<Props> = ({ title, subTitle, icon }) => {
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });
  return (
    <>
      <Grid
        component={motion.div}
        {...third}
        item
        xl={4}
        lg={6}
        xs={12}
      >
        <motion.div
          // initial={{ y: -5 }}
          // animate={{ y: 5 }}
          // transition={{
          //   repeat: Infinity,
          //   delay: i,
          //   duration: 1,
          //   repeatType: 'reverse',
          // }}
          style={{ height: '100%' }}
        >
          {' '}
          <Card
            sx={{
              height: '100%',
              borderRadius: 2,
              borderColor: '#3395C9',
              background: 'linear-gradient(to bottom, #13628C, #1671A1)',
            }}
            variant='outlined'
            key={title}
          >
            <CardContent>
              <Box
                height='72px'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '50px',
                    width: '15%',
                    display: 'flex', alignItems: 'center' 
                  }}
                >
                  <Image src={icon} alt='Fancy' />
                </Box>
                <Typography
                  color='white'
                  variant='h6'
                  fontWeight='700'
                  width='85%'
                  sx={{ lineHeight: 1.3 }}
                >
                  {title}
                </Typography>
              </Box>
              <Typography
                color='action.disabledBackground'
                mt={2}
                variant='body2'
                fontWeight='400'
              >
                {subTitle}
              </Typography>
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
    </>
  );
};