import { PasswordRestrictions, StyledTextField } from '@/components';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { LoginFormHeader } from '../..';
import { getLoginTranslationKey } from '@/hooks';


interface NewPasswordFormProps {
  disabledButton: boolean;
  onBack: Function;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = (
  props: NewPasswordFormProps
) => {
  const { t } = useTranslation();
  
  return (
    <>
      <LoginFormHeader
        titleTranslate={t(getLoginTranslationKey('headers.recovery'))}
        subTitleTranslate={t(getLoginTranslationKey('paragraphs.new_password'))}
      />
      <Grid item xs={12} mt={2}>
        <StyledTextField
          type="password"
          translateKey='user.password'
          showLabel
          name='password'
          required
         />
      </Grid>
      <Grid item xs={12} mt={2}>
        <StyledTextField
          type="password"
          translateKey='user.password_confirm'
          showLabel
          name='password_confirmation'
          required
        />
      </Grid>
      <Grid item xs={12} mt={3}>
        <PasswordRestrictions />
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Button
          variant='contained'
          size='large'
          color='secondary'
          fullWidth
          type='submit'
          disabled={props.disabledButton}
        >
          {t('buttons.change_password')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Button
          variant='outlined'
          fullWidth
          onClick={() => props.onBack()}
        >
          {t('buttons.back')}
        </Button>
      </Grid>
    </>
  );
};
