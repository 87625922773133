import { QuoteDetails, QuoteProductTableRow } from '@/models';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { formatNumber } from 'src/logic/utils/numbers';

type Props = {
  quote: QuoteDetails;
  productsTableRows: QuoteProductTableRow[];
  companyDetails: any;
  otherCompanyType: string;
  internalProject: string;
  otherProject: string;
};

const getFormatedNumber = (value: string | number | null | undefined) => {
  if(value === null || value === undefined || isNaN(value)) return "";
  if(typeof value === "number") return formatNumber(value);
  return formatNumber(parseFloat(value) ?? ''); 
}

export const QuotePDFDocument: React.VFC<Props> = ({
  quote,
  productsTableRows,
  companyDetails,
  otherCompanyType,
  internalProject,
  otherProject,
}) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size='A4' style={styles.page} key={quote.quoteModel?.id}>
        <View>
          <Text style={styles.title}>{quote.quoteModel?.name}</Text>
        </View>
        <View style={[styles.section, { marginTop: '12px' }]}>
          <Text style={styles.smallBlue}> {t('texts.date_created')}: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{quote.quoteModel?.created_at}</Text>
          <Text style={styles.smallBlue}>Internal Project: </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{internalProject}</Text>
          {otherProject && (
            <>
              <Text style={styles.smallBlue}>{otherCompanyType}</Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{otherProject}</Text>
            </>
          )}
          {quote.quoteModel?.rfq_name && (
            <>
              <Text style={styles.smallBlue}>{t('table_cells.rfq')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{quote.quoteModel?.rfq_name}</Text>
            </>
          )}
          <Text style={styles.smallBlue}>{t('texts.number_of_products')} </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{quote.quoteModel?.number_of_products} </Text>
          <Text style={styles.smallBlue}>{t('texts.number_of_services')} </Text>
          <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{quote.quoteModel?.number_of_services} </Text>
        </View>
        <View style={[styles.section, { marginTop: '15px', width: '85%' }]}>
          <View>
            <Text style={styles.mediumTitle}>{quote.quoteModel?.name}</Text>
          </View>
        </View>
        <Product quoteModel={quote.quoteModel} companyDetails={companyDetails} />
        <View style={{ width: '90%', marginTop: '20px' }}>
          {quote.quoteModel?.quote_products.map((product, index) => {
            return <ProductCard product={product} index={index} key={index} />;
          })}
        </View>

        <View style={{ height: '3%' }} fixed />

        <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
          <View></View>
          <View>
            <Text style={styles.bigWithBold}>{t('texts.order_summary')}</Text>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallBlue}> {t('texts.sub_total')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(quote.quoteModel?.subtotal_value)}</Text>
            </View>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallBlue}> {t('texts.vat')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(quote.quoteModel?.vat_value)}</Text>
            </View>
            <View style={styles.spaceBetweenSection}>
              <Text style={styles.smallWithBold}>{t('texts.total')} </Text>
              <Text style={[styles.smallGrey]}>{getFormatedNumber(quote.quoteModel?.total_value)}</Text>
            </View>
          </View>
        </View>
        <View style={{ height: '10%' }} fixed />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            t('texts.page_number', { page_number: pageNumber, pages_length: totalPages })
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const Product = ({ quoteModel, companyDetails }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
      <View>
        <Text style={styles.bigBlue}>{companyDetails?.name}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.fax')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.fax_number ? companyDetails?.fax_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.phone')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.phone_number ? companyDetails?.phone_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.address')} </Text>
          <Text style={[styles.smallGrey]}>
            {companyDetails?.company_address ? companyDetails?.company_address : t('texts.n_a')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={styles.bigBlue}>{t('popups.titles.general_terms')}</Text>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.payment_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {quoteModel?.terms_bundle?.payment_term.name ? quoteModel?.terms_bundle?.payment_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.warranty_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {quoteModel?.terms_bundle?.warranty_term.name ? quoteModel?.terms_bundle?.warranty_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.delivery_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {quoteModel?.terms_bundle?.delivery_term.name ? quoteModel?.terms_bundle?.delivery_term.name : 'N/A'}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ProductCard = ({ product, index }) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        index % 2 == 0 ? styles.section : styles.blueSection,
        { height: '53px', display: 'flex', alignItems: 'center' },
      ]}
    >
      <View>
        <Image
          style={styles.smallImage}
          src={product.image_url + '?noCache=' + Math.random().toString()}
          source={{
            header: {
              'Access-Control-Allow-Origin': '*'
            }
          }}
        />
      </View>
      <View style={styles.productDetails}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.smallTitle}>
            {product?.product?.name} {product?.product?.is_private_product ? '  (Private)' : ''}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: '10px' }}>
          <ProductDetails
            title={t('texts.segment')}
            value={product?.product?.category_kind == 1 ? 'product' : 'service'}
          />
          <ProductDetails
            title={t('texts.brand')}
            value={product?.product?.brand.name ? product?.product?.brand.name : 'N/A'}
          />
          <ProductDetails
            title={t('texts.part_number')}
            value={product?.product?.part_number ? product?.product?.part_number : 'N/A'}
          />
          <ProductDetails
            title={t('table_cells.warranty')}
            value={product?.warranty_term?.name ? product?.warranty_term?.name : 'N/A'}
          />
          <ProductDetails
            title={t('table_cells.available')}
            value={product?.available_quantity ? product?.available_quantity : 'N/A'}
          />
          {product?.requested_quantity && (
            <ProductDetails
              title={t('table_cells.requested')}
              value={product?.requested_quantity ? product?.requested_quantity : 'N/A'}
            />
          )}
          <ProductDetails
            title={t('table_cells.unit_price')}
            value={product?.unit_price ? product?.unit_price : 'N/A'}
          />
          <ProductDetails title={t('table_cells.remarks')} value={product?.remarks ? product?.remarks : 'N/A'} />
        </View>
      </View>
    </View>
  );
};

const ProductDetails = ({ title, value }) => {
  return (
    <View style={{ marginRight: '30px', marginHorizontal: '5px' }}>
      <Text style={[styles.smallGrey, { marginBottom: '2px' }]}>{title}</Text>
      <Text style={styles.smallBlack}>{value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    margin: '30px',
  },
  section: {
    flexDirection: 'row',
    width: '535px',
  },
  blueSection: {
    backgroundColor: '#EAEEF6',
    flexDirection: 'row',
    width: '535px',
  },
  spaceBetweenSection: {
    flexDirection: 'row',
    width: '240px',
    justifyContent: 'space-between',
  },
  bigImageSection: {
    width: '90px',
  },
  smallImageSection: {
    width: '61px',
  },
  productDetails: {
    width: '474px',
  },
  title: {
    color: '#00385E',
    fontSize: '18px',
    fontWeight: 800,
    width: '90%',
  },
  mediumTitle: {
    color: '#13628C',
    fontSize: '12px',
    fontWeight: 600,
  },
  smallTitle: {
    color: '#13628C',
    fontSize: '8px',
    fontWeight: 500,
    width: '294px',
  },
  smallBlue: {
    color: '#00385E',
    fontSize: '6px',
    fontWeight: 400,
  },
  smallWithBold: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 700,
  },
  bigBlue: {
    color: '#00385E',
    fontSize: '9px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 2,
  },
  bigWithBold: {
    color: '#3A3E50',
    fontSize: '9px',
    fontWeight: 700,
    marginTop: 4,
    marginBottom: 2,
  },
  smallGrey: {
    color: '#8B8D98',
    fontSize: '6px',
    fontWeight: 400,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 50,
    marginBottom: 2,
    left: 0,
    right: 0,
    fontSize: '6px',
    fontWeight: 400,
    color: '#8B8D98',
  },
  smallBlack: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigImage: {
    width: '61px',
    height: '61px',
  },
  smallImage: {
    width: '35px',
    height: '35px',
    margin: '5px',
    marginRight: '10px',
  },
});
