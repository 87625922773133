import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';
 
const emailRegExp =
/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationSchema = (props) => {
  return (
    yup.object({ email: yup.string()
    .required('required')
    .matches(emailRegExp, 'email')
    .test(
      'is-same',
      "email_same_as_old",
      (value) => value != props,
    )
  }))
}

