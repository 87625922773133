import { apiRoutes, AuthenticateStatus, RequisitionStatus } from '@/config';
import { request } from '@/helpers';
import { Product, Requisition } from '@/models';
import { useProductsRequisition } from '@/zustand';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';

export const useGetAllRequisitions = async () => {
  return request({ url: apiRoutes.getAllRequisitions, method: 'GET' });
};

export const getRquisitionDetails = (requisitionId: number) => {
  return request({ url: apiRoutes.requisitionDetails + requisitionId, method: 'Get' });
};

export const useCreateRequisition = async (data, product?: Product, userAuthStatus: AuthenticateStatus, numberOfPreviouslyCreatedRequisitions?: number = 0) => {
  if (userAuthStatus == AuthenticateStatus.AUTHENTICATED) {
    return request({ url: apiRoutes.createRequisition, method: 'POST', data: data });
  } else {
    let project;
    if (data.new_project_name) {
      project = {
        id: Date.now(),
        name: data.new_project_name,
      };
    } else {
      project = {
        id: data.project_id,
        name: data.project_name,
      };
    }

    let products = [];
    if (product) {
      products.push({
        quantity: product.quantity,
        main_product: product,
        alternative_products: [],
      });
    }
    // const requisitionId = Date.now();
    const requisitionId = 1000 + numberOfPreviouslyCreatedRequisitions;
    const newRequisition = {
      id: requisitionId,
      name: `Requisition ${requisitionId}`,
      products: products,
      status: RequisitionStatus.DRAFT,
      created_at: moment(new Date()).format('DD-MM-YYYY'),
      project: project,
      bundle_rfq_id: null,
      localDraft: true,
      can_edit: true,
      can_edit_regenerate: false,
      owner_name: 'You',

      timeline: {
        id: requisitionId,
        milestones: [
          {
            id: requisitionId,
            type: 'REQUISITION',
            title: `Requisition ${requisitionId}`,
            status: 'Selected',
            checkpoints: [
              {
                title: 'Draft',
                status: 'checked',
                next_active: false,
                pendingNext: false,
                reached: false,
                is_expired: false,
              },
            ],
            milestones: [],
            is_recalled: false,
            form_status: 'DRAFT',
            subtitle: '',
            is_expired: false,
          },
          {
            id: null,
            type: 'RFQ',
            title: 'RFQ',
            status: 'Pending',
            checkpoints: [
              {
                title: 'Pending',
                status: 'empty',
                next_active: false,
                pendingNext: false,
                reached: false,
                is_expired: false,
              },
            ],
            milestones: [],
            is_recalled: false,
            form_status: '',
            subtitle: '',
            is_expired: false,
          },
          {
            id: null,
            type: 'COMPARISON_TABLE',
            title: 'Comparison Table',
            status: 'Pending',
            checkpoints: [
              {
                title: 'Pending',
                status: 'empty',
                next_active: false,
                pendingNext: false,
                reached: false,
                is_expired: false,
              },
            ],
            milestones: [],
            is_recalled: false,
            form_status: '',
            subtitle: '',
            is_expired: false,
          },
          {
            id: null,
            type: 'QUOTE',
            title: 'Quote',
            status: 'Pending',
            checkpoints: [
              {
                title: 'Pending',
                status: 'empty',
                next_active: false,
                pendingNext: false,
                reached: false,
                is_expired: false,
              },
            ],
            milestones: [],
            is_recalled: false,
            form_status: '',
            subtitle: '',
            is_expired: false,
          },
          {
            id: null,
            type: 'PO',
            title: 'Purchase Order',
            status: 'Pending',
            checkpoints: [
              {
                title: 'Pending',
                status: 'empty',
                next_active: false,
                pendingNext: false,
                reached: false,
                is_expired: false,
              },
            ],
            milestones: [],
            is_recalled: false,
            form_status: '',
            subtitle: '',
            is_expired: false,
          },
        ],
        type: 'Buyer',
      },
    };

    return new Promise(resolve => {
      resolve({ data: newRequisition });
    });
  }
};

export const useAddProductToRequisition = (product: Product, requisition: Requisition) => {
  return new Promise(resolve => {
    const newProduct = {
      quantity: product.quantity,
      main_product: product,
      alternative_products: [],
    };
    resolve({ data: { ...requisition, products: [...requisition.products, newProduct] } });
  });
  // if (requisition.localDraft) {
  //     return new Promise(resolve => {
  //         const newProduct = {
  //             quantity: product.quantity,
  //             main_product: product,
  //             alternative_products: []
  //         }
  //         resolve({ data: { ...requisition, products: [...requisition.products, newProduct] } });
  //     });
  // }
  //
  // return request({
  //     url: apiRoutes.requisition + requisition?.id + apiRoutes.addProductToRequisition,
  //     method: "POST",
  //     data: { product_id: product.id, quantity: product.quantity }
  // })
};

export const useChangeProductRequirements = (product: Product, requisition: Requisition, persist: boolean = false) => {
  if (persist && !requisition.localDraft) {
    return request({
      url: apiRoutes.requisition + requisition.id + apiRoutes.changeProductRequirements,
      method: 'PUT',
      data: { product_id: product.id, additional_requirements: product.additional_requirements },
    });
  }
  return new Promise(resolve => {
    const updatedProducts = requisition.products.map(requisitionProduct => {
      if (requisitionProduct.main_product.id == product.id) {
        return { ...requisitionProduct, additional_requirements: product.additional_requirements };
      }
      return requisitionProduct;
    });
    resolve({ data: { ...requisition, products: updatedProducts } });
  });
};

export const useChangeProductQuantity = (product: Product, requisition: Requisition, persist: boolean = false) => {
  if (persist && !requisition.localDraft) {
    return request({
      url: apiRoutes.requisition + requisition.id + apiRoutes.changeProductQuantity,
      method: 'PUT',
      data: { product_id: product.id, quantity: product.quantity },
    });
  }
  return new Promise(resolve => {
    const updatedProducts = requisition.products.map(requisitionProduct => {
      if (requisitionProduct.main_product.id == product.id) {
        return { ...requisitionProduct, quantity: product.quantity };
      }
      return requisitionProduct;
    });
    resolve({ data: { ...requisition, products: updatedProducts } });
  });
  // if (requisition.localDraft) {
  //     return new Promise(resolve => {
  //         resolve(null);
  //     });
  // }
  //
  // return request({
  //     url: apiRoutes.requisition + requisition.id + apiRoutes.changeProductQuantity,
  //     method: "PUT",
  //     data: { product_id: product.id, quantity: product.quantity }
  // })
};

export const useRemoveProductFromRequisition = (
  product: Product,
  requisition: Requisition,
  persist: boolean = false
) => {
  if (persist && !requisition.localDraft) {
    return request({
      url: apiRoutes.requisition + requisition.id + apiRoutes.removeProductFromRequisition,
      method: 'POST',
      data: { product_id: product.id },
    });
  }
  return new Promise(resolve => {
    resolve({ data: { ...requisition, products: requisition.products.filter(e => e.main_product.id != product.id) } });
  });
  // if (requisition.localDraft) {
  //     return new Promise(resolve => {
  //         resolve({ data: { ...requisition, products: requisition.products.filter(e => e.main_product.id != product.id) } });
  //     });
  // }
  //
  // return request({
  //     url: apiRoutes.requisition + requisition.id + apiRoutes.removeProductFromRequisition,
  //     method: "POST",
  //     data: { product_id: product.id }
  // })
};

export const useAddAlternativeProductToRequisition = (product: Product, requisition: Requisition) => {
  return new Promise(resolve => {
    const products = requisition.products.map(e => {
      if (e.main_product.id != product.parentId) return e;
      // e.alternative_products = [...e.alternative_products, product]
      return { ...e, alternative_products: [...e.alternative_products, product] };
    });
    resolve({ data: { ...requisition, products: [...products] } });
  });
  // if (requisition.localDraft) {
  //     return new Promise(resolve => {
  //         const products = requisition.products.map(e => {
  //             if (e.main_product.id != product.parentId)
  //                 return e
  //             // e.alternative_products = [...e.alternative_products, product]
  //             return { ...e, alternative_products: [...e.alternative_products, product] }
  //         })
  //         resolve({ data: { ...requisition, products: [...products] } });
  //     });
  // }
  //
  // return request({
  //     url: apiRoutes.requisition + requisition.id + apiRoutes.addAlternativeProductToRequisition,
  //     method: "POST",
  //     data: { alternative_product_id: product.id, main_product_id: product.parentId }
  // })
};

export const useRemoveAlternativeProductFromRequisition = (
  product: Product,
  requisition: Requisition,
  persist: boolean = false
) => {
  if (persist && !requisition.localDraft) {
    return request({
      url: apiRoutes.requisition + requisition.id + apiRoutes.removeAlternativeProductFromRequisition,
      method: 'POST',
      data: { alternative_product_id: product.id, main_product_id: product.parentId },
    });
  }

  return new Promise(resolve => {
    const products = requisition.products.map(e => {
      if (e.main_product.id != product.parentId) return e;
      let newAlternativeProducts = e.alternative_products.filter(
        alternativeProduct => alternativeProduct.id != product.id
      );
      return { ...e, alternative_products: [...newAlternativeProducts] };
    });
    resolve({ data: { ...requisition, products: products } });
  });
  // if (requisition.localDraft) {
  //     return new Promise(resolve => {
  //         const products = requisition.products.map(e => {
  //             if (e.main_product.id != product.parentId)
  //                 return e
  //             let newAlternativeProducts = e.alternative_products.filter(alternativeProduct => alternativeProduct.id != product.id)
  //             return { ...e, alternative_products: [...newAlternativeProducts] }
  //         })
  //         resolve({ data: { ...requisition, products: products } });
  //     });
  // }
  //
  // return request({
  //     url: apiRoutes.requisition + requisition.id + apiRoutes.removeAlternativeProductFromRequisition,
  //     method: "POST",
  //     data: { alternative_product_id: product.id, main_product_id: product.parentId }
  // })
};

// export const useDeleteRequisition = async (requisition: Requisition) => {
//     if (requisition.localDraft) {
//         return new Promise(resolve => {
//             resolve(null);
//         });
//     }
//     return request({
//         url: apiRoutes.requisition + requisition.id,
//         method: "DELETE",
//     })
// }

export const useDeleteRequisition = async (requisitionId: number | undefined) => {
  return request({ url: apiRoutes.requisition + requisitionId, method: 'DELETE' });
};

export const useDuplicateRequisition = async (requisitionId: number | undefined, project: any) => {
  return request({
    url: apiRoutes.requisition + requisitionId + apiRoutes.duplicate,
    method: 'POST',
    data: { project_id: project.projectId, new_project_name: project.projectName ?? null },
  });
};

export const saveRequisition = async (requisition: Requisition) => {
  return request({
    url: apiRoutes.requisition + requisition.id + apiRoutes.updateRequisitionProducts,
    data: {
      products: requisition.products.map(function (product) {
        return {
          id: product.id,
          quantity: product.quantity,
          alternatives: product.alternatives?.map(product => product.id) ?? [],
        };
      }),
    },
    method: 'POST',
  });
};

export function useDeleteRequiisition(onSuccess: Function) {
  const setCurrentRequisition = useProductsRequisition(state => state.setCurrentRequisition);
  return useMutation((data: number) => deleteRequisition(data), {
    onSuccess: data => {
      setCurrentRequisition(null), onSuccess();
    },
  });
}

export const useRegenerateRFQFromRequisition = (requisitionId: number) => {
  return request({
    url: apiRoutes.requisition + requisitionId + apiRoutes.regenerateRFQFromRequisition,
    method: 'POST',
  });
};
