import { Seller } from '@/models';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Box, Typography } from '@mui/material';
import React from 'react';
import { SellersListPopup } from '../../shared/product-card/sellers-popup';

const boxSxProps = { width: '100%', backgroundColor: '#EAEEF6', borderRadius: '4px', padding: '8px 12px' };
const typographyProps = { color: '#13628C', fontSize: 13, fontWeight: 400 };

const SellersIndicatorBox = ({ sellers }: { sellers: Seller[] | undefined }) => {
  const length = sellers?.length ?? 0;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopper = () => setAnchorEl(null);

  return (
    <>
      <Box
        sx={{
          ...boxSxProps,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        mt={1}
        onMouseOver={handleOpenPopper}
        onMouseOut={handleClosePopper}
      >
        <Typography {...typographyProps}>
          {length} Seller{length !== 1 && 's'}
        </Typography>
        <InfoOutlinedIcon sx={{ color: '#13628C', fontSize: 16 }} />
      </Box>
      {sellers && (
        <SellersListPopup items={sellers} anchorEl={anchorEl} open={open} handleClosePopper={handleClosePopper} />
      )}
    </>
  );
};

export default SellersIndicatorBox;
