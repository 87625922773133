import { ResponsiveContainer } from "@/styles"
import { Box, Grid } from "@mui/material"
import { useTranslation } from 'next-i18next';
import React from "react";
import { useDrawerStore } from "@/zustand";


export const PageDetailsHeader = ({ children }) => {
    const { t } = useTranslation();
    const { isDrawerOpen, isDrawerSubMenuOpen } = useDrawerStore()

    return (
        <Box sx={{ height: '80px', width: '100%', backgroundColor: 'background.primary.main', paddingTop: 3 }}>
            <Box sx={{
                height: '80px', width: '100%', position: 'fixed', zIndex: 1,
                transform: 'scale(1)', transition: '0.30s all ease',
                paddingLeft: isDrawerOpen || isDrawerSubMenuOpen ? 33 : 8,
                right: 0
            }}>
                <Grid container component={ResponsiveContainer}>
                    <Grid container sx={{ height: '60px', borderRadius: '8px', backgroundColor: 'secondary.contrastText', px: 2, alignItems: "center" }}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}