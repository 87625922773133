import { useProfile, useProfilePhoneNumberFlow} from '@/zustand';
import { ChangeDirectForm } from '../shared-popups/change-direct-form';
import { Success } from '../shared-popups/success';
import { getAccountTranslationKey } from '@/hooks';
import { useTranslation } from 'next-i18next';
import { useRequestUpdateDirectNumber } from '@/services';

type Props = { };

export const ProfileChangeDirectPopupContent: React.FC<Props> = ({}) => {
    const { t } = useTranslation();
    const changeDirectPopupSteps = useProfilePhoneNumberFlow(state => state.changeDirectPopupSteps);
    const toggleChangeDirectPopup = useProfilePhoneNumberFlow(state => state.toggleChangeDirectPopup);
    const user_info = useProfile(state => state.user_info);
 
    const mutationUpdateDirectNumber = useRequestUpdateDirectNumber(); 
    const handleUpdateDirectNumber = (values, actions) => { mutationUpdateDirectNumber.mutate(values)   } 


    const change_Direct_Number = [
        <ChangeDirectForm
            isDisabledButton={mutationUpdateDirectNumber.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_new_direct'))}
            handleClickNext={handleUpdateDirectNumber} 
            handleClickBack={toggleChangeDirectPopup}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.change_direct_success' ))}
            userInfo={user_info?.direct_number} togglePopup={() => toggleChangeDirectPopup(false)}
        />
    ]

    return (
        <>
            { change_Direct_Number[changeDirectPopupSteps] }
        </>
    );
};