import React from 'react';
import { useProcurementStore } from '@/zustand';
import { Button, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';

const SaveCancelEditPoActions = () => {
  const router = useRouter();
  const { data } = useProcurementStore(
    state => state.productFormManagerProperties
  );
  const { updateProductsFromEnvironment } = useProcurementStore(
    state => state.editPurchaseOrderFromScratchSliceActions
  );
  const { products: originalProducts } = useProcurementStore(
    state => state.editPurchaseOrderFromScratchSliceProperties
  );

  const onSaveClicked = () => {
    updateProductsFromEnvironment(data.products);
    router.push(
      sm.portals.buyer.procurement.purchaseOrders.edit(data.formDetails.id).url
    );
  };

  const onCancelClicked = () => {
    updateProductsFromEnvironment(originalProducts);
    router.push(
      sm.portals.buyer.procurement.purchaseOrders.edit(data.formDetails.id).url
    );
  };

  return (
    <Grid container mb={3} spacing={1}>
      <Grid item md={6}>
        <Button
          type='button'
          color='success'
          variant='contained'
          fullWidth
          onClick={onSaveClicked}
        >
          Update
        </Button>
      </Grid>
      <Grid item md={6}>
        <Button
          type='button'
          color='error'
          variant='outlined'
          fullWidth
          onClick={onCancelClicked}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default SaveCancelEditPoActions;
