import { apiRoutes, Channels } from '@/config'
import { request } from '@/helpers'
import { useUsersStore } from '@/zustand'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next';
import { NotifyError, NotifyMessage, NotifySuccess } from '../Notify-toast'


const fetchUsersInfo = async () => {
  return request({
    url: apiRoutes.usersInfo,
    method: "GET"
  })
}

export const useUsersInfo = () => {
  const setUsersData = useUsersStore(state => state.setUsersData);
  const onSuccess = (data: any) => setUsersData(data.data)
  // return queryWithSocket(fetchUsersInfo, undefined, Channels.users, onSuccess)
  return useQuery(['users'], () => fetchUsersInfo(), { onSuccess: onSuccess })
}

const fetchCompanyUserInfo = async (id: string) => {
  return request({
    url: apiRoutes.user + '/' + id,
    method: "GET"
  })
}

export const useCompanyUserInfo = (id: string) => {
  const setUserInfo = useUsersStore(state => state.setUserInfo);
  const onSuccess = (data: any) => { setUserInfo(data.data) }
  // return queryWrapper(fetchCompanyUserInfo, id, 'company-user', onSuccess)
  // return useQuery(`company-user-${id}`, async () => await fetchCompanyUserInfo(id), { onSuccess: onSuccess })
  return useQuery({
    queryKey: ['company-user', id],
    queryFn: async () => await fetchCompanyUserInfo(id),
    onSuccess
  })
}

const modifyAccessControl = async (data) => {
  return request({
    url: apiRoutes.user + '/' + data.user_id + apiRoutes.modifyAccessControl,
    method: "PUT",
    data: data
  })
}
export const useUserAccessControl = () => {
  const setPermisisons = useUsersStore(state => state.setPermisisons);
  const setRoles = useUsersStore(state => state.setRoles);
  const { t } = useTranslation();
  const onAccessControlModified = (data) => {
    if (data.access_control_type == "PERMISSION") setPermisisons(data.status, data.id)
    if (data.access_control_type == "ROLE") setRoles(data.status, data.id)
    return modifyAccessControl(data)
  }
  const onAccessControlError = (data, variables) => {
    if (variables.access_control_type == "PERMISSION") setPermisisons(!variables.status, variables.id)
    if (variables.access_control_type == "ROLE") setRoles(!variables.status, variables.id)
    NotifyError(t('notifications.server_error'))
  }
  return useMutation((data) => onAccessControlModified(data), { onError: onAccessControlError })
}

const updateUserType = async (data) => {
  return request({
    url: apiRoutes.user + '/' + data.id + apiRoutes.accountType,
    method: "PUT",
    data: { account_type: data.account_type }
  })
}

export const useUpdateUserType = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation((data) => updateUserType(data), { onSuccess: onSuccess, onError: () => NotifyError(t('notifications.server_error')) })
}

const approveUser = async (userId) => {
  return request({
    url: apiRoutes.user + '/' + userId + apiRoutes.approve,
    method: "PUT",
  })
}

export const useApproveUser = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation((userId) => approveUser(userId), { onSuccess: (data) => onSuccess(data.data), onError: () => NotifyError(t('notifications.server_error')) })
}

const authorizeUser = async (userId) => {
  return request({
    url: apiRoutes.user + '/' + userId + apiRoutes.authorize,
    method: "PUT",
  })
}

export const useAuthorizeUser = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation((userId) => authorizeUser(userId), { onSuccess: (data) => onSuccess(data.data), onError: () => NotifyError(t('notifications.server_error')) })
}
const unauthorizeUser = async (userId) => {
  return request({
    url: apiRoutes.user + '/' + userId + apiRoutes.unauthorize,
    method: "PUT",
  })
}

export const useUnauthorizeUser = (onSuccess) => {
  const { t } = useTranslation();
  return useMutation((userId) => unauthorizeUser(userId), { onSuccess: (data) => onSuccess(data.data), onError: () => NotifyError(t('notifications.server_error')) })
}

export const fetchUsersAfterUpdate = () => {
  const setUsersData = useUsersStore(state => state.setUsersData);
  return useQuery(['users-update'], () => fetchUsersInfo(), { onSuccess: (data) => { setUsersData(data.data) }, enabled: false })
}

const inviteUser = async (data) => {
  return request({
    url: apiRoutes.invitation,
    method: "POST",
    data: data
  })
}

export const useInviteUser = () => {
  const { t } = useTranslation();
  const toggleInvitePopup = useUsersStore(state => state.toggleInvitePopup);
  const query = fetchUsersAfterUpdate()
  const onSuccess = () => {
    NotifySuccess(t("notifications.invite_user"))
    toggleInvitePopup(false)
    query.refetch()
  }
  const onError = (e) => {
    NotifyError(t('notifications.' + e.data.translation_key))
  }
  return useMutation((data) => inviteUser(data), { onSuccess: onSuccess, onError: onError })
}


const cancelInvitation = async (invitationId) => {
  return request({
    url: apiRoutes.invitation + '/' + invitationId,
    method: "DELETE",
  })
}
export const useCancelInvitation = (onSuccess) => {
  const { t } = useTranslation();
  const showError = (data) => {
    data.status === 409 ? NotifyError(t('notifications.' + data.data.error.message)) : NotifyError(t('notifications.server_error'))
  }
  return useMutation((invitationId) => cancelInvitation(invitationId), { onSuccess: (data) => {return (onSuccess(data.data),NotifyMessage(t("notifications.invite_canceled"))) }, onError: (data) => showError(data) })
}