import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, useMediaQuery, Fab, Grid, IconButton, Input, Typography, Chip, Stack } from '@mui/material';
import { useTranslation } from 'next-i18next';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { getInputsTranslationKey } from '@/hooks';
import { FormEvent, useRef, useState } from 'react';

type Props = { editImage: boolean, chipContent: string, title: string, subtitle: string, chipColor?: string, chipTextColor?: string, profilePictureUrl?: string, changePicture?: (data: File) => void, isSmallScreen: boolean }

export const RoundedImageWithText: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const gridStyle = {
        display: 'flex',
        justifyContent: props.isSmallScreen ? 'center' : 'space-between',
    };
    const inputFileRef = useRef<HTMLInputElement>(null);

    const onFileChange = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files[0]) {
            props.changePicture(e.currentTarget.files[0])
        }
    }


    return (
        <Stack direction={'row'} width='100%'>
            <Stack direction={props.isSmallScreen ? 'column' : 'row'} width={props.isSmallScreen ? '100%' : '50%'}>
                <Stack sx={gridStyle}>
                    <Box position='relative' sx={{ textAlign: props.isSmallScreen ? 'center' : 'initial' }}>
                        <Box position='relative' sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
                            <Box
                                sx={{
                                    height: 80,
                                    width: 80,
                                    overflow: 'hidden',
                                    borderRadius: '50%',
                                }}
                            >
                                {props.profilePictureUrl ?
                                    <img
                                        src={props.profilePictureUrl}
                                        alt='profile image'
                                        width='100%'
                                        height='100%'
                                    /> :
                                    <img
                                        src='/assets/images/blank-profile.png'
                                        alt='profile image'
                                        width='100%'
                                        height='100%'
                                    />
                                }
                            </Box>
                        </Box>
                        {props.isSmallScreen && props.editImage ? (
                            <Fab
                                size='small'
                                sx={{ position: 'abssolute', top: -40, right: -45 }}
                            >
                                <label htmlFor='icon-button-file'>
                                    <input accept='image/*' id='icon-button-file' type='file' style={{ "display": "none" }}
                                        onChange={onFileChange} ref={inputFileRef}
                                    />
                                    <IconButton
                                        color='primary'
                                        component='span'
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <PhotoCameraOutlinedIcon />
                                    </IconButton>
                                </label>
                            </Fab>
                        ) : props.editImage ? (
                            <label htmlFor='icon-button-file'>
                                <input accept='image/*' id='icon-button-file' type='file' style={{ "display": "none" }}
                                    onChange={onFileChange} ref={inputFileRef}
                                />
                                <Typography
                                    width={110}
                                    variant='body2'
                                    color='text.disabled'
                                    textAlign={'center'}
                                    sx={{ '&:hover': { cursor: 'pointer' } }}
                                >
                                    {t(getInputsTranslationKey('labels.user.edit_photo'))}
                                </Typography>
                            </label>
                        ) : <></>}
                    </Box>
                </Stack>
                <Stack sx={{ height: 100, ...gridStyle, mt: props.isSmallScreen && props.editImage ? -3 : 1, py: 1 }}  >
                    <Box
                        sx={{
                            textAlign: props.isSmallScreen ? 'center' : 'initial',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: props.isSmallScreen ? 'initial' : 'space-between',
                        }}
                    >
                        <Box>
                            <Chip
                                sx={{ height: 24, backgroundColor: props.chipColor, minWidth: '80px', py: 1 }}
                                label={
                                    <Typography variant='subtitle2' style={{ fontWeight: 500, color: props.chipTextColor ? props.chipTextColor : 'primary', textTransform: 'capitalize', }}>
                                        {props.chipContent}
                                    </Typography>
                                }
                            />
                        </Box>
                        <Typography variant='overline' color='text.primary' fontWeight={700}>
                            {props.title}
                        </Typography>
                        <Typography variant='body2' color='text.third' fontWeight={400}>
                            {props.subtitle}
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
            {
                !props.isSmallScreen && <Stack sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                    {props.children}
                </Stack>
            }
        </Stack >
    );
};
