import { SvgIconProps, SvgIcon } from '@mui/material';

export const FolderIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6381 8.00016H25.3346C27.5438 8.00016 29.3346 9.79102 29.3346 12.0002V22.6668C29.3346 24.876 27.5438 26.6668 25.3346 26.6668H6.66797C4.45883 26.6668 2.66797 24.876 2.66797 22.6668V9.3335C2.66797 7.12436 4.45883 5.3335 6.66797 5.3335H13.8462C14.2489 5.3335 14.6301 5.51552 14.8832 5.82875L16.6381 8.00016ZM13.2094 8.00016H6.66797C5.93159 8.00016 5.33464 8.59712 5.33464 9.3335V22.6668C5.33464 23.4032 5.93159 24.0002 6.66797 24.0002H25.3346C26.071 24.0002 26.668 23.4032 26.668 22.6668V12.0002C26.668 11.2638 26.071 10.6668 25.3346 10.6668H16.0013C15.5986 10.6668 15.2174 10.4848 14.9643 10.1716L13.2094 8.00016Z'
      fill={props.color}
    />
  </SvgIcon>
);
