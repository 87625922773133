import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';
import { getAccountTranslationKey } from '@/hooks';

interface props {
  labelTranslateKey: string;
  buttonTranslateKey: string;
  onClick: Function;
  btnColorFill?: string;
}
const flexBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const LabelWithButton: React.FC<props> = props => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  return (
    <Grid item xs={12} mt={1}>
      <Grid item sx={flexBox} onClick={() => isSmallScreen && props.onClick()} >
        <Grid item xs={6}>
          <Typography variant='h6' fontWeight={700}  {...(props.children && { sx: { pb: 2 } })}>
            {t(getAccountTranslationKey('labels.' + props.labelTranslateKey))}
          </Typography>
          {props.children}
        </Grid>
        <Grid
          container
          item
          xs={6}
          sx={{ disply: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        >
          <UserSettingsButton
            btnBgColorFill={props.btnColorFill}
            onClick={props.onClick}
            isSmallScreen={isSmallScreen}
            buttonTitle={t('buttons.' + props.buttonTranslateKey)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Divider />
      </Grid>
    </Grid >
  );
};

type UserSettingsButtonProps = {
  buttonTitle: string;
  onClick: Function;
  isSmallScreen: boolean;
  btnBgColorFill?: string
};

const UserSettingsButton: React.VFC<UserSettingsButtonProps> = ({
  buttonTitle,
  onClick,
  isSmallScreen,
  btnBgColorFill
}) => {
  return (
    <>
      {isSmallScreen ? (
        <ChevronRight color={btnBgColorFill} />
      ) : (
        <Button variant='contained' color={btnBgColorFill} onClick={() => onClick()}> {buttonTitle} </Button>
      )}
    </>
  );
};
