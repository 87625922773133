import React, { useEffect } from 'react';
import { GeneralObject } from '@/models';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { Backdrop, CircularProgress } from '@mui/material';

type DuplicateFormProps = {
  duplicateFormName: string | undefined;
  openPopup: boolean;
  closePopup: () => void;
  onDuplicate?: (project: GeneralObject) => void;
  currentProject?: number;
};

export const DuplicateForm: React.FC<DuplicateFormProps> = ({
  openPopup,
  closePopup,
  onDuplicate,
}) => {
  const duplicateLoading = useLoadingState();
  useEffect(() => {
    if (openPopup) {
      duplicateLoading.startLoading();
      onDuplicate?.({});
      closePopup();
    }
  }, [openPopup]);

  return (
    <Backdrop open={duplicateLoading.isLoading} style={{ zIndex: 1200 }}>
      <CircularProgress size='2rem' style={{ color: '#fff' }} />
    </Backdrop>
  );

  //   return (
  //     <>
  //       {allProjects && currentProject && (
  //         <DescPopup open={openPopup} handleClose={closePopup}>
  //           <Stack
  //             width='100%'
  //             sx={{ alignItems: 'center', textAlign: 'center', py: 3 }}
  //           >
  //             <ContentCopyIcon color='primary' />
  //             <Stack gap={2} width='100%' px={2}>
  //               <Typography fontWeight={700} variant='overline'>
  //                 {t('buttons.duplicate_form', { formName: duplicateFormName })}
  //               </Typography>
  //               <Typography
  //                 fontWeight={400}
  //                 variant='body2'
  //                 color='text.disabled'
  //               >
  //                 {t('popups.subTitles.edit_project')}
  //               </Typography>
  //               <Formik
  //                 initialValues={{
  //                   projectId: currentProject ? currentProject : '',
  //                   projectName: '',
  //                 }}
  //                 onSubmit={handleDuplicate}
  //                 validationSchema={
  //                   showCreateProject
  //                     ? createProjectSchema
  //                     : createRequisitionSchema
  //                 }
  //               >
  //                 {formikProps => (
  //                   <form onSubmit={formikProps.handleSubmit}>
  //                     <Stack sx={{ textAlign: 'start' }}>
  //                       <ProjectCreateOrSelect
  //                         projects={allProjects}
  //                         showCreateProject={showCreateProject}
  //                         setshowCreateProject={setShowCreateProject}
  //                       />
  //                     </Stack>
  //                     <Button
  //                       sx={{ mt: 2 }}
  //                       type='submit'
  //                       fullWidth
  //                       variant='contained'
  //                     >
  //                       {t('buttons.duplicate_form', {
  //                         formName: duplicateFormName,
  //                       })}
  //                     </Button>
  //                   </form>
  //                 )}
  //               </Formik>
  //             </Stack>
  //           </Stack>
  //         </DescPopup>
  //       )}
  //     </>
  //   );
};
