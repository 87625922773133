import { GeneralObject, ProductCategoryKind, ProductCategoryKindLabel, RFQProduct } from '@/models';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { DescPopup } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import BuyerRequirementsIndicator from 'src/ui/components/smart/procurement/products-table/BuyerRequirementsIndicator';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DeleteIcon as Delete } from 'src/ui/icons/delete';
import { CustomWarrantyText } from '../../procurement';
import { DescriptionIndicator } from '../../requisition/requisition-alternatives-table/components';

interface RFQProductCardProps {
  rfqData: RFQProduct;
  warrantyGeneralTerm: GeneralObject;
  deletable?: boolean;
  handleDeleteProduct?: (productId: number) => void;
  deleteProductLoading?: boolean;
  withDetailsPopup?: boolean;
}

export const RFQProductCard: FC<RFQProductCardProps> = ({
  rfqData,
  warrantyGeneralTerm,
  deletable,
  handleDeleteProduct,
  deleteProductLoading,
  withDetailsPopup = false,
}) => {
  const isWarranyTermCustom = warrantyGeneralTerm.id != rfqData.warranty_term.id;
  const detailsPopup = usePopup();

  return (
    <>
      {withDetailsPopup && (
        <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
          <ProductQuickView product={rfqData.product} />
        </DescPopup>
      )}
      <Grid
        container
        px={2}
        py={1}
        sx={{ backgroundColor: 'white', borderRadius: '8px', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item md={0.75}>
          <Typography color='#3A3E50' fontWeight={400} fontSize={12} textTransform={'uppercase'}>
            {ProductCategoryKindLabel[rfqData.product.category_kind]}
          </Typography>
        </Grid>
        <Grid item md={0.75} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box height='50px' width='50px'>
            <img height='100%' width='100%' style={{ objectFit: 'contain' }} src={rfqData.product.image_url} alt='' />
          </Box>
        </Grid>
        <Grid item md={4.5} pr={1.5}>
          <div style={{ display: 'flex' }}>
            <Typography
              fontSize={14}
              fontWeight='700'
              color='#3A3E50'
              noWrap
              className={'underline-on-hover cursor-pointer'}
              onClick={detailsPopup.handleOpen}
            >
              {rfqData.product.name}
            </Typography>
            {rfqData.product.is_private_product && (
              <PrivacyButton
                disableText
                content='This seller profile is private because it was created and/or linked by your company.'
              />
            )}
          </div>

          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <DescriptionIndicator
              description={rfqData.product.description}
              category_kind={rfqData.product.category_kind}
            />
            {rfqData.product.category_kind === ProductCategoryKind.PRODUCT ? (
              <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
                {rfqData.product.part_number}
              </Typography>
            ) : (
              <BuyerRequirementsIndicator additional_requirements={rfqData.additional_requirements} />
            )}
          </div>
        </Grid>

        <Grid item container md={1.5} px={1}>
          {rfqData.product.category_kind === ProductCategoryKind.PRODUCT && rfqData.product.brand ? (
            <>
              <img
                style={{ objectFit: 'contain', width: '50px', height: '50px' }}
                src={rfqData.product.brand.image_url}
                alt={rfqData.product.brand.name}
              />
            </>
          ) : (
            // <Typography color='#3A3E50' fontSize={11} fontWeight={400} pr={1}>
            <Typography color='#3A3E50' fontSize={20} fontWeight={400} pr={1}>
              -
            </Typography>
          )}
        </Grid>

        {/* <Grid item container md={3.5} px={1}>
          {rfqData.product.category_kind === ProductCategoryKind.PRODUCT ? (
            <>
              <Grid item container md={4} sx={{ alignItems: 'center' }}>
                <img
                  style={{ objectFit: 'contain', width: '50px', height: '50px' }}
                  src={rfqData.product.brand.image_url}
                  alt={rfqData.product.brand.name}
                />
              </Grid>
              {rfqData.product.productAttributes.slice(0, 2).map(attr => (
                <Grid key={attr.name} item container md={4} pr={1} alignItems={'center'}>
                  <Typography fontSize={13} fontWeight={500} color='#3A3E50' noWrap width={'100%'}>
                    {attr.value}
                  </Typography>
                </Grid>
              ))}
            </>
          ) : (
            <Typography color='#3A3E50' fontSize={11} fontWeight={400} pr={1}>
              {rfqData.additional_requirements}
            </Typography>
          )}
        </Grid> */}

        <Grid item md={2} sx={{ alignItems: 'center' }}>
          <CustomWarrantyText isWarranyTermCustom={isWarranyTermCustom} warrantyName={rfqData?.warranty_term?.name} />
        </Grid>

        <Grid container md={1.5} justifyContent='center'>
          <Typography variant='subtitle1' fontWeight='400' color='#00385E'>
            {rfqData.quantity}
          </Typography>
        </Grid>

        <Grid item md={1}>
          {deletable && (
            <Grid container alignItems='center' justifyContent='center' sx={{ gap: '.5rem' }}>
              <Box
                width='27px'
                onClick={() => rfqData.product?.id && !deleteProductLoading && handleDeleteProduct?.(rfqData.id)}
                height='32px'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  position: 'relative',
                }}
              >
                <Delete fontSize='small' />
              </Box>
              <CircularProgress size='1rem' style={{ opacity: deleteProductLoading ? 1 : 0 }} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
