import { Link, Logo } from '@/components';
import {
  Grid,
  Box,
  useMediaQuery,
  Button,
  Divider,
} from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { sm } from 'src/site-map';
import { SearchBar } from '../../dumb/header/SearchBar';
import { useTranslation } from 'next-i18next';
import { AuthenticateStatus } from '@/config';
import { NotificationMenu, UserNavMenu } from '@/components'
import { useUserData } from '@/zustand';
import useIsUserPendingApproval from 'src/logic/hooks/auth/use-is-user-pending-approval';

// const notifications = [
//   {
//     title: 'review_mode',
//     date: 'Apr 10:50',
//     from: 'requisition',
//   },
//   {
//     title: 'cameron_accepted',
//     date: '6 Apr 10:50',
//     from: 'users',
//   },
// ];

type Props = {
  authenticatedStatus?: number;
};

export const Header: React.VFC<Props> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const userInfo = useUserData(state => state.userInfo);
  const isSeller = useUserData.getState().isSeller();
  const isUserPendingApproval = useIsUserPendingApproval();

  return (
    <Box sx={{ display: 'flex', bgcolor: 'secondary.contrastText', alignItems: 'center', height: '60px', width: '100%' }}>
      <Grid container sx={{ bgcolor: 'secondary.contrastText', display: 'flex', alignItems: 'center', pl: 2, pr: 15 }} >
        <Grid item lg={2} sx={{ display: 'flex' }}>
          <Link href={props.authenticatedStatus === AuthenticateStatus.AUTHENTICATED ? isSeller ? sm.portals.seller.dashboard.url : sm.portals.buyer.dashboard.url : sm.home.url} underline='none' sx={{ alignItems: 'center', marginRight: 5 }} >
            <Logo />
          </Link>
        </Grid>
        <Grid item lg={6} sx={{ display: 'flex', alignItems: 'center' }}>
          {!isUserPendingApproval && <SearchBar mobile={false} backFun={() => { }} />}
        </Grid>
        <Grid item container lg={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {props.authenticatedStatus === AuthenticateStatus.AUTHENTICATED ? (
            <>
              <Divider variant='middle' orientation='vertical' sx={{ width: 2, height: 50, opacity: 0.5 }} />
              <Box height='100%' display='flex' alignItems='flex-end' margin={1}>
                <NotificationMenu />
              </Box>
              <Divider variant='middle' orientation='vertical' sx={{ width: 2, height: 50, opacity: 0.5 }} />
              <UserNavMenu imgURL={userInfo?.profile_picture_url} userData={userInfo} />
            </>
          ) : (
            <>
              <Button onClick={() => router.push(sm.register.index.url)} variant='contained' color='secondary' sx={{ width: '160px' }}  >
                {t('buttons.register')}
              </Button>
              <Button onClick={() => router.push(sm.login.url)} variant='contained' sx={{ marginLeft: 1, width: '160px' }}   >
                {t('buttons.login')}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
