import { SvgIconProps, SvgIcon } from '@mui/material';

export const DeleteIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon
    width="14.3" height="14.3" viewBox="0 0 14.3 14.3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}
  >
    <g clip-path="url(#clip0_12017_14094)">
      <path d="M5.88812 0.852966H8.24973V1.25034H9.10259V0.797338C9.1027 0.357703 8.74521 0 8.30579 0H5.83206C5.39264 0 5.03516 0.357703 5.03516 0.797338V1.25034H5.88812V0.852966Z" fill="#FF4D4D" />
      <path d="M11.0736 4.62964H3.06706C2.84768 4.62964 2.67497 4.81679 2.69265 5.03553L3.36202 13.3125C3.39933 13.7746 3.78473 14.1304 4.24776 14.1304H9.89284C10.3559 14.1304 10.7413 13.7746 10.7786 13.3124L11.448 5.03553C11.4657 4.81679 11.293 4.62964 11.0736 4.62964ZM5.00575 13.2476C4.9968 13.2481 4.98786 13.2485 4.97902 13.2485C4.75542 13.2485 4.56773 13.0744 4.55383 12.8482L4.13435 6.05323C4.1199 5.8181 4.29876 5.61575 4.53377 5.6013C4.76804 5.58707 4.97125 5.76549 4.9857 6.00072L5.40507 12.7957C5.41962 13.0308 5.24077 13.233 5.00575 13.2476ZM7.50158 12.822C7.50158 13.0574 7.31066 13.2484 7.0751 13.2484C6.83954 13.2484 6.64862 13.0574 6.64862 12.822V6.02692C6.64862 5.79136 6.83954 5.60044 7.0751 5.60044C7.31055 5.60044 7.50158 5.79136 7.50158 6.02692V12.822ZM10.0064 6.05204L9.60586 12.847C9.5926 13.0736 9.40459 13.2484 9.18056 13.2484C9.17216 13.2484 9.16364 13.2481 9.15512 13.2477C8.92 13.2338 8.74061 13.032 8.75451 12.7969L9.15491 6.0018C9.16871 5.76668 9.36987 5.58729 9.60565 5.60119C9.84077 5.61499 10.0202 5.81691 10.0064 6.05204Z" fill="#FF4D4D" />
      <path d="M12.5468 3.31357L12.2667 2.47397C12.1928 2.25264 11.9856 2.10333 11.7522 2.10333H2.38501C2.15171 2.10333 1.9444 2.25264 1.87066 2.47397L1.59058 3.31357C1.53657 3.47549 1.60686 3.64065 1.73806 3.72301C1.79153 3.75654 1.85481 3.7767 1.92435 3.7767H12.213C12.2825 3.7767 12.3459 3.75654 12.3993 3.72291C12.5305 3.64054 12.6008 3.47538 12.5468 3.31357Z" fill="#FF4D4D" />
    </g>
    <defs>
      <clipPath id="clip0_12017_14094">
        <rect width="14.1304" height="14.1304" fill="white" />
      </clipPath>
    </defs>

  </SvgIcon>
);
