import { Button, CircularProgress, Grid, Typography } from "@mui/material"
import { FC, ReactNode } from "react";
import { CheckboxWithLabel, MultilineTextFieldForm, SelectWithPlaceholder } from "@/components";
import { GeneralObject } from "@/models";


type ProcurementActionsPopupContentProps = {
    icon: ReactNode
    popupTitle: string
    buttonTitle: string
    status: string
    popupSubTitle?: string
    reasonsForPopup?: GeneralObject[]
    titleColor?: string
    commentNote?: string | null
    cancelStatus?: boolean
    checkLabel?: string
    isButtonLoading?: boolean
};


export const ProcurementActionsPopupContent: FC<ProcurementActionsPopupContentProps> = (props) => {
    return (
        <>
            <Grid item md={12} textAlign='center' mb={1}>
                {props.icon}

                <Grid item md={12} textAlign='center'>
                    <Typography variant='overline' color='primary.main' fontWeight="700" color={props.titleColor ? props.titleColor : props.status == 'primary' ? 'primary' : 'text.disabled'}>
                        {props.popupTitle}
                    </Typography>
                </Grid>

                {props.popupSubTitle && <Grid item md={12} textAlign='center'>
                    <Typography variant='body2' color='text.disabled' my={1}>
                        {props.popupSubTitle}
                    </Typography>
                </Grid>}

            </Grid>
            {props.reasonsForPopup && <Grid item container md={12} width={300}>
                <SelectWithPlaceholder translateKey='reason' name='reasonId' items={props.reasonsForPopup} required placeholder='select_reason' />
            </Grid>}

            <Grid item container md={12}>
                <MultilineTextFieldForm translateKey='comment' name='comment' showLabel commentNote={props.commentNote} />
                {props.cancelStatus && <CheckboxWithLabel name='hideReason' >{props.checkLabel}</CheckboxWithLabel>}
                <Button variant="contained" fullWidth type="submit" color={props.status} disabled={props.isButtonLoading}>
                    {props.isButtonLoading ? (<CircularProgress size={24} color="inherit" />) : props.buttonTitle} </Button>
            </Grid>
        </>
    )
}
export * from './general-terms'