import { apiURLs, AuthenticateStatus, Permissions, Roles } from '@/config';
import axios from 'axios';
import { ResponseHandlers } from 'src/logic/models/queries';
import create from 'zustand';

export const useUserData = create((set, get) => ({
  userInfo: null,
  companyInfo: null,
  authenticationStatus: AuthenticateStatus.UNDEFINED,
  isAuthenticated: AuthenticateStatus.UNDEFINED,
  isVerified: null,
  hasAccessToPortal: false,
  canCreateRFQ: false,
  canGenerateQuote: false,
  canCreatePO: false,
  canApproveQuote: false,
  canApprovePurchaseOrder: false,
  hasRFQApproverRole: false,
  loadingLogin: false,
  canUpdateCatalog: false,
  customLoginPopup: false,
  
  setUserInfo: data => {
    set({
      userInfo: {
        ...data,
        profile_picture_url:
          data.profile_picture_url == ''
            ? '/assets/images/blank-profile.png'
            : data.profile_picture_url,
      },
    });
  },

  isBuyer: () => {
    return get().companyInfo?.companyType == 'BUYER';
  },
  isSeller: () => {
    return get().companyInfo?.companyType == 'SELLER';
  },
  getUserInfo: () => {
    return get().userInfo;
  },
  getCompanyInfo: () => {
    return get().companyInfo;
  },
  getRoles: () => {
    return get().userInfo.roles;
  },
  getPermissions: () => {
    return get().userInfo.permissions;
  },
  getIsAuthenticated: () => {
    return get().isAuthenticated;
  },
  getUserType: () => {
    return get().companyInfo.companyType;
  },
  getIsVerified: () => {
    return get().isVerified;
  },
  setCompanyInfo: data => {
    set({ companyInfo: data });
  },
  getHasPermission: (permissionToFind: string) => {
    let found = false;
    get().userInfo?.permissions.map((permission: string) => {
      if (permission == permissionToFind) found = true;
    });
    return found;
  },
  getHasRole: (roleToFind: string) => {
    let found = false;
    get().userInfo?.roles.map((role: string) => {
      if (role == roleToFind) found = true;
    });
    return found;
  },
  getUser: () => {
    return {
      permissions: get().userInfo.permissions,
      roles: get().userInfo.roles,
    };
  },
  initializeUser: () => {
    axios.get(apiURLs.csrfCookieRoute).then(r => {
      axios
        .get(apiURLs.user)
        .then(response => {
          let userInfo = response.data.data.user_info;
          get().setUserInfo(userInfo);
          set({
            isAuthenticated: AuthenticateStatus.AUTHENTICATED,
            authenticationStatus: AuthenticateStatus.AUTHENTICATED,
            companyInfo: response.data.data.company_settings,
            hasAccessToPortal: userInfo.permissions.includes(
              Permissions.portal_access_permission
            ),
            canGenerateQuote: userInfo.permissions.includes(
              Permissions.generate_quote
            ),
            canUpdateCatalog: userInfo.permissions.includes(
              Permissions.update_catalog
            ),
            canCreatePO: userInfo.permissions.includes(Permissions.create_po),
            canApproveQuote: userInfo.roles.includes(Roles.QUOTE_APPROVER_ROLE),
            canApprovePurchaseOrder: userInfo.roles.includes(
              Roles.po_approver_role
            ),
            hasRFQApproverRole: userInfo.roles.includes(
              Roles.RFQ_APPROVER_ROLE
            ),
            canCreateRFQ: userInfo.permissions.includes(Permissions.create_rfq),
            customLoginPopup:false
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 401)
            set({
              isAuthenticated: AuthenticateStatus.NOT_AUTHENTICATED,
              authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED,
            });
          set({ userInfo: null });
          set({ companyInfo: null });
          set({ loadingLogin: false });
        });
    });
  },
  logUserOut: (responseHandlers?: ResponseHandlers) => {
    axios.post(apiURLs.logout, {}).then(response => {
      responseHandlers?.onSuccess?.(response.data);
      set({
        isAuthenticated: AuthenticateStatus.NOT_AUTHENTICATED,
        authenticationStatus: AuthenticateStatus.NOT_AUTHENTICATED,
      });
    }).catch((err) => {
      responseHandlers?.onError?.(err);
    });
  },
  logUserIn: (email: string, password: string, remember: boolean) => {
    return axios.post(apiURLs.login, { email, password, remember });
  },
  changeUserData: (first_name, last_name, title, job_title) => {
    return axios.put(apiURLs.changeUserData, {
      first_name,
      last_name,
      title,
      job_title,
    });
  },
  setLoadingLogin: (isLoading: boolean) => {
    set({ loadingLogin: isLoading });
  },
  setCustomLoginPopup: (open: boolean) => {
    set({ customLoginPopup: open });
  },
  setPermissionInUserDataStore: (value: string, id: string) => {
    if (id === Permissions.create_rfq) set({ canCreateRFQ: value });
    else if (id === Permissions.generate_quote) set({ canGenerateQuote: value });
    else if (id === Permissions.create_po) set({ canCreatePO: value });
    else {console.log(value, id)}
  },
}));
