import { Typography, Box } from '@mui/material';
import { Inbox } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'next-i18next';


export const Empty: React.VFC = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Inbox
        sx={{
          color: 'secondary.main',
          fontSize: '5rem',
        }}
      />
      <Typography color='textPrimary' variant='h2'>
         {t('texts.no_data')}
      </Typography>
    </Box>
  );
};
