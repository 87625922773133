import { AuthenticateStatus } from '@/config';
import { request } from '@/helpers';
import { useUserData } from 'src/logic/zustand/user';

const userIsGuest = () => useUserData.getState().authenticationStatus !== AuthenticateStatus.AUTHENTICATED;

const addProductToRequisition = ({
  requisition_id,
  product_id,
  quantity,
  additional_requirements,
}: {
  requisition_id: string;
  product_id: number;
  quantity: number;
  additional_requirements?: string | null;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/add-product`,
    method: 'POST',
    data: {
      product_id,
      quantity,
      additional_requirements,
    },
  });
};

const addProductToRequisitionAsAlternative = ({
  requisition_id,
  main_product_id,
  alternative_product_id,
}: {
  requisition_id: string;
  main_product_id: number;
  alternative_product_id: number;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/add-product-as-alternative`,
    method: 'POST',
    data: {
      main_product_id,
      alternative_product_id,
    },
  });
};

const removeProductFromRequisition = ({
  requisition_id,
  product_id,
}: {
  requisition_id: string;
  product_id: number;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/remove-product`,
    method: 'POST',
    data: {
      product_id,
    },
  });
};

const removeAlternativeProductFromRequsition = ({
  requisition_id,
  main_product_id,
  alternative_product_id,
}: {
  requisition_id: string;
  main_product_id: number;
  alternative_product_id: number;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/remove-alternative`,
    method: 'POST',
    data: {
      main_product_id,
      alternative_product_id,
    },
  });
};

const changeQuantity = ({
  requisition_id,
  product_id,
  quantity,
}: {
  requisition_id: string;
  product_id: number;
  quantity: number;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/change-product-quantity`,
    method: 'PUT',
    data: {
      product_id,
      quantity,
    },
  });
};

const changeAdditionalRequirements = ({
  requisition_id,
  product_id,
  additional_requirements,
}: {
  requisition_id: string;
  product_id: number;
  additional_requirements?: string | null;
}) => {
  const isGuest = userIsGuest();
  if (isGuest) {
    return new Promise(resolve => {
      resolve({});
    });
  }

  return request({
    url: `/requisition/${requisition_id}/change-product-requirements`,
    method: 'PUT',
    data: {
      product_id,
      additional_requirements,
    },
  });
};

const RequsitionSavingServices = {
  addProductToRequisition,
  addProductToRequisitionAsAlternative,
  removeProductFromRequisition,
  removeAlternativeProductFromRequsition,
  changeQuantity,
  changeAdditionalRequirements,
};

export default RequsitionSavingServices;
