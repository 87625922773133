import { apiRoutes } from "@/config"
import { request } from "@/helpers"
import { LinkType } from "@/models"

export const useGetConvertRequisitionToRFQData = (requisitionId: number) => {
    return request({ url: apiRoutes.requisition + requisitionId + apiRoutes.convertToRFQ, method: "GET" })
}

export const useGenerateRequestForQuotes = (requisitionId: number | undefined, paymentTermId: number | undefined, warranty_term_id: number | undefined, deliveryTermId: number | undefined, selectedProducts: any) => {
    return request({ url: apiRoutes.generateRFQ, method: "POST", data: { requisition_id: requisitionId, payment_term_id: paymentTermId, warranty_term_id: warranty_term_id, delivery_term_id: deliveryTermId, selected_products: selectedProducts } })
}

export const useUpdateGeneralTerms = (requisitionId: number, params: { payment_term_id: string, warranty_term_id: string, delivery_term_id: string }) => {
    return request({ url: apiRoutes.requisition + requisitionId + apiRoutes.updateTerms, method: "PUT", data: params })
}

export const useSendRFQToSeller = (RFQId: number | undefined, date: string, optionalComment: string | null) => {
    return request({ url: apiRoutes.requestForQuote + RFQId + apiRoutes.sendToAllSeller, method: "POST", data: { validity_date: date, comment: optionalComment ?? null } })
}

export const useEditProject = (formId: string, formType: LinkType, projectId: string, projectName: string) => {
    return request({ url: apiRoutes.procurement + apiRoutes.project + apiRoutes.changeProject, method: "POST", data: { form_id: formId, new_project_name: projectName ?? null, form_type: formType, project_id: projectId } })
}