import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { WhiteDeleteIcon } from '@/icons';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({ closeToast }) => (
    <i
        className="material-icons"
        onClick={closeToast}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '20px', color: 'white', borderLeft: '1px solid rgba(246, 248, 252, 0.3)', paddingInline: '1rem' }}>
        <CloseIcon />
    </i>
);


export const NotifyError = (message: string, options: ToastOptions  = {}) => {
    toast.error(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
        icon: <ErrorOutlineIcon />,
        closeButton: CloseButton,
        style: { width: '100%' },
        ...options
    });
}

export const NotifySuccess = (message: string, successIcon: React.ReactNode = <TaskAltIcon />) => {
    toast.success(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
        icon: successIcon,
        closeButton: CloseButton
    });
}

export const NotifySuccessWithCancelIcon = (message: string) => {
    toast.success(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
        icon: <HighlightOffIcon />,
        closeButton: CloseButton
    });
}

export const NotifySuccessWithDeleteIcon = (message: string) => {
    toast.success(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
        icon: <WhiteDeleteIcon />,
        closeButton: CloseButton
    });
}

export const NotifyMessage = (message: string) => {
    toast(message, {
        toastId: message,
        position: toast.POSITION.TOP_RIGHT,
        style: {
            backgroundColor: 'var(--toastify-color-success)',
            color: 'white'
        },
        closeButton: CloseButton
    });
}