import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { Button, Divider, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { DescPopup, StyledPopper } from "src/ui/components/shared"
import { BundleRFQDetails } from '@/models';
import { BorderColorOutlined, CheckCircleOutline, ExpandMoreOutlined, HighlightOff } from '@mui/icons-material';
import { PopupStatus, ProcurementButtons, procurementStatus } from '@/config';
import { BundleRFQPopupContent } from 'src/ui/components/dumb';

type ApproveRejectProps = { bundleRFQDetails: BundleRFQDetails, openedPopupStatus: PopupStatus | null }

export const ApproveReject: FC<ApproveRejectProps> = ({ bundleRFQDetails, openedPopupStatus }) => {
    const { t } = useTranslation();
    const [anchorShowEditResponse, setAnchorShowEditResponse] = useState<null | HTMLElement>(null);
    const openShowEditResponse = Boolean(anchorShowEditResponse);

    const { customLoadingButton } = useProcurementStore(state => state.bundleRFQDetailsSliceProperties)
    const { setPopupStatus, approveAllRFQs, rejectAllRFQs } = useProcurementStore(state => state.bundleRFQDetailsSliceActions)

    const bundleModel = bundleRFQDetails.bundleRFQModel
    const OpenRejectAllRFQPopup = () => setPopupStatus(PopupStatus.REJECT_ALL)
    const OpenApproveAllRFQPopup = () => setPopupStatus(PopupStatus.APPROVE_ALL)

    const handleRejectAllRFQs = (values) => rejectAllRFQs(bundleModel?.id, values.reasonId, values.comment)
    const handleApproveAllRFQs = (values) => approveAllRFQs(bundleModel?.id, values.comment)

    const handleClosePopup = () => setPopupStatus(null)

    return (
        <>

            {bundleRFQDetails?.canEditResponse ? <Button onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorShowEditResponse(event.currentTarget)}
                variant="contained" color="info" startIcon={<BorderColorOutlined sx={{ ml: 1 }} />} endIcon={<ExpandMoreOutlined sx={{ mr: 1 }} />} >
                <Typography variant="body2" fontWeight={500} mr={1}>{t('buttons.edit_response')}</Typography></Button>
                : <>
                    {bundleRFQDetails.canRejectAllRFQs && <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={OpenRejectAllRFQPopup}> {t("buttons.reject_all")}</Button>}
                    {bundleRFQDetails.canAcceptAllRFQs && <Button variant="contained" color="success" sx={{ mr: 1 }} onClick={OpenApproveAllRFQPopup}> {t("buttons.approve_all")} </Button>}
                </>}


            <StyledPopper open={openShowEditResponse} anchorEl={anchorShowEditResponse} handleClose={() => setAnchorShowEditResponse(null)}>
                <Grid container sx={{ display: 'flex', flexDirection: 'column', minWidth: 185 }}>
                    {bundleRFQDetails.canAcceptAllRFQs && <><Button startIcon={<CheckCircleOutline />} sx={{ color: "success.dark" }} onClick={OpenApproveAllRFQPopup}>
                        {t('buttons.approve_all')}
                    </Button><Divider sx={{ opacity: 0.7, mx: 2 }} /></>
                    }
                    {bundleRFQDetails.canRejectAllRFQs && <><Button startIcon={<HighlightOff />} color="error" onClick={OpenRejectAllRFQPopup}>
                        {t("buttons.reject_all")}
                    </Button> <Divider sx={{ opacity: 0.7, mx: 2 }} /></>
                    }
                </Grid>
            </StyledPopper>

            {/* accept all */}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.APPROVE_ALL}>
                <BundleRFQPopupContent
                    icon={<CheckCircleOutline sx={{ color: 'success.dark' }} />}
                    popupTitle={t('popups.titles.approve_all_rfq')}
                    popupSubTitle={t('popups.subTitles.approve_all_rfq')}
                    buttonTitle={t('buttons.approve_bundle')}
                    handlePopupAction={handleApproveAllRFQs}
                    status='success'
                    isButtonLoading={customLoadingButton == ProcurementButtons.APPROVE_ALL_RFQs}
                />
            </DescPopup>
            {/* reject all */}
            <DescPopup handleClose={handleClosePopup} open={openedPopupStatus == PopupStatus.REJECT_ALL}>
                <BundleRFQPopupContent
                    icon={<HighlightOff color='error' />}
                    popupTitle={t('popups.titles.reject_all_rfq')}
                    buttonTitle={t('buttons.reject_bundle')}
                    popupSubTitle={t('popups.subTitles.reject_bundle_rfq')}
                    handlePopupAction={handleRejectAllRFQs}
                    status='error'
                    isButtonLoading={customLoadingButton == ProcurementButtons.REJECT_ALL_RFQs}
                />
            </DescPopup>
        </>
    )
} 