import { RequisitionProduct } from "@/models"
import { Box, Typography } from "@mui/material"
import { Carousel } from "../carousel"
import { StyledClickableText } from "../typography";

type CarouselWithSelectedCardsProps = {
    carouselElements: { id: number, name: string }[];
    selectedElementId: number;
    setSelectedElementId: (id: number) => void
    elementJson?: string
}

export const CarouselWithSelectedCards: React.VFC<CarouselWithSelectedCardsProps> = ({ carouselElements, selectedElementId, setSelectedElementId, elementJson }) => {
    return (
        <Carousel>
            {carouselElements?.map((element: any) => {
                let currnetElement = elementJson ? element[elementJson] : element
                let isSelectedElement = currnetElement.id == selectedElementId
                return (<Box sx={{ borderBottomColor: 'primary.light', borderBottomStyle: 'solid', pr:1 }}>
                    <StyledClickableText handleTextClick={() => setSelectedElementId(currnetElement.id)} isSelected={isSelectedElement} textProperties={currnetElement} />
                </Box>
                )
            })}
        </Carousel>
    )
}