import { Button, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { Link } from '@/components';
import React, { memo, useMemo } from 'react';

export type BackLinkProps = {
  title?: string;
  path?: string;
};

export const BackLink: React.VFC<BackLinkProps> = memo(({ title, path, ...props }) => {
  const { asPath, query, push } = useRouter();
  const des = useMemo<string>(() => {
    return asPath.split('/').slice(0, asPath.split('/').length - 1).join('/')
  }, [asPath]);

  return (
    <Button
      onClick={() => push({
        pathname: path ?? des,
        query: { ...query }
      })}
      sx={{ color: 'text.primary' }}
      startIcon={<ArrowBack sx={{ fontSize: '19px' }} />}
    >
      {title}
    </Button>
  );
});
