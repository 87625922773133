import { useProfile, useProfilePhoneNumberFlow } from '@/zustand';
import { VerificationCodeFormByPhone } from '../shared-popups/verification-code-form-byPhone';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useVerificationPhoneNumber, useRequestVerificationPhoneCode,useRequestVerificationPhoneCodeAgain } from '@/services';
import { getAccountTranslationKey } from '@/hooks';
import { Success } from '../shared-popups/success';
import { SendVerificationOrLost } from '../shared-popups/send-verification-or-lost';

type Props = {};


export const ProfilePhoneVerificationPopupContent: React.FC<Props> = () => {
    const phoneVerificationPopupSteps = useProfilePhoneNumberFlow(state => state.phoneVerificationPopupSteps);
    const { t } = useTranslation();
    const togglePhoneVerificationPopup = useProfilePhoneNumberFlow(state => state.togglePhoneVerificationPopup);
    const setPhoneVerificationSteps = useProfilePhoneNumberFlow(state => state.setPhoneVerificationSteps);
    const [enabled, setEnabled] = useState(false);
    const user_info = useProfile(state => state.user_info);

    const isTimer = useProfilePhoneNumberFlow(state => state.isTimer);
    const timer = useProfilePhoneNumberFlow(state => state.timer);
    const setIsTimer = useProfilePhoneNumberFlow(state => state.setIsTimer);
    const setTimer = useProfilePhoneNumberFlow(state => state.setTimer);
    timer > 0 ?  (setTimeout(() => setTimer(timer - 1), 1000),setIsTimer(true)) : setIsTimer(false)

    const {isFetching , refetch } = useVerificationPhoneNumber(enabled) 
    const handleClick = () => {refetch()};

    const verificationPhone = useRequestVerificationPhoneCode();
    const handleSubmitCode = (values, actions) => { verificationPhone.mutate(values) }    

    const requestVerificationPhoneAgain = useRequestVerificationPhoneCodeAgain(enabled)
    const handleRequestVerificationPhoneAgain = () => { requestVerificationPhoneAgain.refetch() }

    const phone_Verification = [
        <SendVerificationOrLost 
            isDisabledButton={isFetching}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.verify_phone_number'))}
            userInfo={user_info?.mobile_number.number} 
            verificationButtonTranslate={t('buttons.send_verification')} 
            lostButtonTranslate={t('buttons.cancel')}  
            handleClickVerification={handleClick}
            handleClickLost={() => togglePhoneVerificationPopup(false)}
            timerButton={isTimer}
            timerValue={timer}
        />,
        <VerificationCodeFormByPhone
            isDisabledButton={verificationPhone.isLoading}
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.enter_verification_phone'))}
            handleClickNext={handleSubmitCode} 
            handleClickBack={() => {setPhoneVerificationSteps(phoneVerificationPopupSteps-1)} }
            handleClickAgain={handleRequestVerificationPhoneAgain}
        />,
        <Success 
            paragraphTranslate={t(getAccountTranslationKey('paragraphs.phone_verification_success'))}
            togglePopup={() => togglePhoneVerificationPopup(false)}
        />
    ]

    return (
        <>
            {phone_Verification[phoneVerificationPopupSteps]}
        </>
    );
};