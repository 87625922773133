import { useRequisition } from '@/zustand';
import {
  Grid,
  Button,
  Card,
  CardMedia,
  Typography,
  Box,
  IconButton,
  TextField,
} from '@mui/material';
import { DeleteIcon } from 'src/ui/icons/delete';
import { ConfirmPopup } from '@/components';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'next-i18next';
import { getRequisitionTranslationKey } from '@/hooks';

export const RequisitionProduct = ({
  product,
  mainProduct,
  requisition,
  tabId,
  removeProduct,
  isWithDetails,
}) => {
  const { t } = useTranslation();
  const changeQuantity = useRequisition(state => state.changeQuantity);
  const [showDelPopup, setshowDelPopup] = useState(false);
  const handleCloseDelPopup = () => setshowDelPopup(false);
  const handleOpenDelPopup = () => {
    setshowDelPopup(true);
  };
  const handleDelete = (product, id, tabId) => {
    removeProduct(product, id, tabId);
  };
  const changeProductQuantity = (q: number, v: number) => {
    changeQuantity(
      requisition.reqName,
      requisition.id,
      tabId,
      product,
      q + v
    );
  };
  const cardStyle = {
    sx: {
      transform: 'rotateX(180deg)',
      width: '214.4px',
      marginRight: 2,
      bgcolor: 'grey.50',
      height: '100%',
      borderRadius: 2,
      borderColor: mainProduct ? 'secondary.main' : 'white',
      borderWidth: mainProduct ? 2 : 1,
      transition: t =>
        t.transitions.create('border', {
          duration: t.transitions.duration.shortest,
        }),
      '&:hover': {
        borderColor: 'primary.main',
      },
    },
  };
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Card variant='outlined' {...cardStyle}>
        <Grid container sx={{ p: 1 }}>
          <Grid item lg={11} md={11}>
            <Grid
              container
              sx={{ alignItems: 'center', height: '30px', pb: 1 }}
            >
              <CardMedia
                component='img'
                image={product.brand.imageURL}
                sx={{
                  height: '30px',
                  width: 50,
                  objectFit: 'contain',
                  marginRight: 1,
                }}
              />
              <Typography
                variant='body2'
                fontWeight='800'
                sx={{ marginTop: 0.4 }}
              >
                {product.brandName}
              </Typography>
            </Grid>
            <Grid container sx={{ alignItems: 'center', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  height: '48px',
                  width: '48px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    width: '48px',
                  }}
                >
                  <CardMedia
                    component='img'
                    image={product.imageURL}
                    sx={{ objectFit: 'contain' }}
                  />
                </Box>
              </Box>
              <Typography
                fontSize={'12px !important'}
                fontWeight='600'
                color='textSecondary'
                sx={{
                  // height: '65px',
                  whiteSpace: 'initial',
                  lineHeight: 1.3,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflowY: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '118px',
                  marginLeft: 1,
                }}
              >
                {product.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1}>
            <Button
              onClick={handleOpenDelPopup}
              sx={{
                width: '20px',
                maxHeight: '30px',
                minWidth: '20px',
                minHeight: '30px',
              }}
            >
              <DeleteIcon
                sx={{ color: 'text.third', fontSize: '23px !important' }}
              />
            </Button>
            <ConfirmPopup
              buttonTitleKey={t('buttons.confirm_delete')}
              titleKey={t('texts.confirm_delete',{ ProductName: product.name })}
              handleClose={handleCloseDelPopup}
              handleConfirm={() => handleDelete(product, requisition.id, tabId)}
              open={showDelPopup}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            p: 1,
            mt: 2,
            bgcolor: 'primary.light',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 60,
            marginTop: 2,
          }}
        >
          <Typography
            variant='body2'
            lineHeight={1.1}
            fontWeight='400'
            maxWidth='120px'
            sx={{ wordWrap: 'break-word', marginRight: 0.5 }}
          >
            {t(getRequisitionTranslationKey('titles.quantity'))}
          </Typography>
          <TextField
              type="tel"
              value={product.quantity}
              onChange={val =>
                changeQuantity(
                  requisition.reqName,
                  requisition.id,
                  tabId,
                  product,
                  val.target.value
                )
              }
              InputProps={{
                inputProps: {
                  style: { textAlign: 'center'},
                },
                style: { width: '100%', height: 40, justifyContent: 'center'},
                startAdornment: (
                  <IconButton
                    onClick={() => changeProductQuantity(product.quantity, -1)}
                  >
                    <RemoveIcon sx={{ color: 'text.primary' , fontSize:'20px' }} />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => changeProductQuantity(product.quantity, +1)}
                  >
                    <AddIcon sx={{ color: 'text.primary' , fontSize:'20px'}} />
                  </IconButton>
                ),
              }}
              FormHelperTextProps={{
                sx: {
                  textAlign: 'center',
                  justifyContent: 'center',
                },
              }}
              sx={{
                '& $div': {
                  justifyContent: 'center',
                  textAlign: 'center',
                },
                backgroundColor: 'white',
                height: 40,
                width: '100%',
                textAlign: 'center',
              }}
            />
        </Box>
        <Box sx={{ mt: 2 }}>
          <ProductAttributesList
            mainProduct={mainProduct}
            attributeName={t(getRequisitionTranslationKey('titles.type'))}
            attributeValue={product.type}
            color={1}
          />
          {/* <ProductAttributesList
            mainProduct={mainProduct}
            attributeName={'Brand'}
            attributeValue={product.brandName}
            color={0}
          /> */}
          <ProductAttributesList
            mainProduct={mainProduct}
            attributeName={t(getRequisitionTranslationKey('titles.part_number'))}
            attributeValue={product.partNumber}
            color={0}
          />
          {Object.entries(product.productAttributes).map(([k, v], index) => (
            <ProductAttributesList
              mainProduct={mainProduct}
              key={k}
              attributeName={k}
              attributeValue={v}
              color={index + 1}
            />
          ))}
        </Box>
      </Card>
    </Box>
  );
};

const ProductAttributesList = ({
  attributeName,
  attributeValue,
  mainProduct,
  color,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 60,
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: color % 2 === 0 ? 'primary.light' : 'grey.50',
      }}
    >
      <Box sx={{ p: 1 }}>
        {/* <Divider /> */}
          <Typography variant='body2' color='textSecondary' fontWeight='400' lineHeight={1.1} mb={1}>
            {attributeName}
          </Typography>
        <Typography
          variant='body2'
          lineHeight={1.1}
          maxWidth='214px'
          fontWeight='700'
          sx={{ wordWrap: 'break-word' }}
        >
          {attributeValue}
        </Typography>
      </Box>
    </Box>
  );
};
