import { Divider, Grid, Typography, Chip } from "@mui/material";
import { useTranslation } from 'next-i18next';
import { FC, useState } from "react";
import { ApproversList } from '../approvers-list';
import { procurementStatus } from "@/config";
import { useUserData } from "@/zustand";
import { StatusChip } from "../status-chip";

type Props = {
    genericInfo?: any
    showStatus?: string
    actions?: any
};

export const FormSummary: FC<Props> = ({ genericInfo, showStatus, children, actions }) => {
    const { t } = useTranslation();
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const showListOfApprovers = Boolean(anchorElement);
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => {
        handleMouseEnter()
        setAnchorElement(event.currentTarget)
    }
    const handleHoverOut = () => {
        handleMouseLeave()
        setAnchorElement(null)
    }
    const userId = useUserData.getState().userInfo?.id
    let approver = genericInfo?.approvers?.find(approver => approver.approver_id == userId)

    return (
        <Grid container item md={12} p={3} alignItems="center" sx={{ bgcolor: 'white', borderRadius: '8px' }}>
            <Grid container item md={7} alignItems="center">
                <Typography variant="h5" mr={2} fontWeight={600}>{genericInfo?.name}</Typography>
                {/* {
                    (genericInfo?.approvers?.length != 0 && genericInfo?.approvers != undefined) &&
                    <span style={{ cursor: 'pointer', color: '#8B8D98' }} onMouseEnter={(event) => handleHoverIn(event)} onMouseLeave={handleHoverOut}>
                        {t('texts.approvers')}:  {genericInfo?.approvers?.length}
                        <ApproversList isHovered={isHovered && showListOfApprovers} Anchor={anchorElement} listOfApprovers={genericInfo?.approvers} handleClose={() => setAnchorElement(null)} />
                    </span>
                } */}
                {genericInfo?.is_quote_expired == true && !approver && <StatusChip status={(genericInfo?.is_quote_expired == true && !approver) ? procurementStatus.QUOTE_EXPIRED : null} />}

                {
                    !approver && (genericInfo?.extension_request_status ?
                        genericInfo?.is_expired && genericInfo?.extension_request_status === procurementStatus.DECLINED ? <Chip label={t('notifications.request_extension_declined')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                            : genericInfo?.is_expired && genericInfo?.extension_request_status === procurementStatus.PENDING ? <Chip label={t('notifications.extension_requested')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(251, 127, 13, 1)', bgcolor: 'white', border: `1px solid rgba(251, 127, 13, 1)` }} />
                                : null
                        : genericInfo?.is_expired ? <Chip label={t('chip.EXPIRED')} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(255, 77, 77, 1)', bgcolor: 'white', border: `1px solid rgba(255, 77, 77, 1)` }} />
                            : <></>)
                }
                {/* {showStatus && <StatusChip status={showStatus} title={t('texts.approvers')} listOfApprovers={genericInfo?.approvers} />} */}
            </Grid>
            <Grid container item md={5} justifyContent="flex-end">
                {actions}
            </Grid>
            <Divider sx={{ opacity: 0.7 }} />
            <Grid item container md={12}>
                {children}
            </Grid>
        </Grid>
    )
}
