import {
  RadioButtonGroup,
  SearchTextField,
  StyledTextField,
} from '@/components';
import {
  productManagerEnv,
  ProductManagerPopupStatus,
  RadioButtonStatus,
} from '@/config';
import { ProcurementModelDetails, Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import initialValues from './initialValues';
import validationSchema from './validationSchema';
import createPoInitialValues from '../create-with-product/initialValues';
import { NotifySuccess } from '@/services';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'next-i18next';

export const SelectPurchaseOrderPoppup: FC<{
  selectedSeller?: string;
  product: Product;
  popupState: number;
  changePopupState: (val: number) => void;
  sellerId?: string;
}> = ({ selectedSeller, product, popupState, changePopupState, sellerId }) => {
  const formik = useFormikContext();
  const router = useRouter();
  const { purchaseOrderRelatedData } = useProcurementStore();
  const { selectForm, addProduct } = useProcurementStore(
    state => state.productFormManagerProperties.actions
  );
  useEffect(() => {
    changePopupState(0);
  }, []);

  const currentValidationSchema = validationSchema[popupState];
  const getSellerFiltration = (form: ProcurementModelDetails) => {
    router.query['seller-id'] = form?.seller_details?.id;
    router.push(router);
  };
  const handleSelectPO = (values, actions) => {
    selectForm(
      { ...values, product: { ...product, price: values.price } },
      'purchaseOrderBuyerDetailsSliceActions',
      productManagerEnv.purchase_order,
      purchaseOrderRelatedData?.purchase_orders,
      (form: ProcurementModelDetails) => {
        // addProduct({ ...product, price: values.price });
        getSellerFiltration(form);
      }
    );
  };
  return (
    <Stack sx={{ alignItems: 'center', textAlign: 'center', m: 1 }}>
      <Stack>
        {product && (
          <Formik
            initialValues={initialValues(product.id, product.quantity)}
            validationSchema={currentValidationSchema}
            onSubmit={handleSelectPO}
            isolatedFields={true}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <Typography
                  color='primary'
                  variant='overline'
                  fontWeight='700'
                  mt={1}
                >
                  {t('buttons.add_to_PO')}
                </Typography>
                {popupState === 0 ? (
                  <SelectPo
                    selectedSeller={selectedSeller}
                    changePopupState={changePopupState}
                    disabledButton={!(formikProps.isValid && formikProps.dirty)}
                    product={product}
                  />
                ) : (
                  // : popupState === 1 ? (
                  //   <ChangeSellerPrice
                  //     product={product}
                  //     disabledButton={!(formikProps.isValid && formikProps.dirty)}
                  //   />
                  // )
                  <></>
                )}
              </form>
            )}
          </Formik>
        )}
      </Stack>
    </Stack>
  );
};
// const ChangeSellerPrice: FC<{ product: Product; disabledButton: boolean }> = ({
//   product,
//   disabledButton,
// }) => {
//   const { submitForm } = useFormikContext();
//   const handleSelectPurchaseOrder = () => submitForm();
//   return (
//     <Grid container gap={2} direction='column'>
//       <Typography variant='body2' color='text.disabled'>
//         {t('texts.confirm_details')}
//       </Typography>
//       <StyledTextField name='price' translateKey='unit_price' type='number' />
//       <Button
//         variant='contained'
//         onClick={handleSelectPurchaseOrder}
//         disabled={disabledButton}
//       >
//         {t('buttons.confirm')}
//       </Button>
//       {/* {product?.unit_price && <FakeStyledTextField text={product?.unit_price} hideLabel />} */}
//     </Grid>
//   );
// };
const SelectPo: FC<{
  changePopupState: (val: number) => void;
  disabledButton: boolean;
  selectedSeller?: string;
  product: Product;
}> = ({ selectedSeller, changePopupState, disabledButton, product }) => {
  const { submitForm } = useFormikContext();
  const handleSelectPurchaseOrder = () => submitForm();
  const { t } = useTranslation();

  const [searchText, setsearchText] = useState('');
  const { values, setFieldValue } = useFormikContext();
  const { data, actions } = useProcurementStore(
    state => state.productFormManagerProperties
  );
  const { purchaseOrderBuyerDetailsSliceActions } = useProcurementStore();
  const { individualForms } = useProcurementStore(
    state => state.purchaseOrderBuyerDetailsSliceProperties
  );
  const handleChangePurchaseOrder = (id: string) => {
    setFieldValue('purchase_order_id', id);
    const selectedPoObject = individualForms.find(
      form => form.purchase_order.id === parseInt(id)
    );
    setFieldValue('price', selectedPoObject?.product_price ?? "");
  };

  const isCreatingPo = useLoadingState();
  const handleCreatePO = () => {
    isCreatingPo.startLoading();
    const values = createPoInitialValues(
      product.id,
      product.quantity,
      selectedSeller
    );
    purchaseOrderBuyerDetailsSliceActions.create(
      {
        projectId: '',
        projectName: '',
        sellerId: values.seller_id,
      },
      newPurchaseOrder => {
        actions.setDetails(newPurchaseOrder, productManagerEnv.purchase_order);
        actions.addProduct(product);
        // NotifySuccess('Purchase Order Created');
        NotifySuccess(t('notifications.create_form', { formName: newPurchaseOrder.name}))
        actions.setProductManagerPopupStatus(null);
      }
    );
  };

  return (
    <Stack>
      <Typography variant='body2' color='text.disabled'>
        {t('texts.select_po')}
      </Typography>
      <Box my={2} height={40}>
        <SearchTextField
          translateKey='search_pos'
          value={searchText}
          onChange={e => setsearchText(e.target.value)}
        />
      </Box>
      <Stack mb={2} height='220px'>
        <Stack pl={1}>
          <RadioButtonGroup
            data={individualForms
              ?.map(form => {
                return { ...form, ...form.purchase_order };
              })
              .filter((contentItem: any) =>
                contentItem.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
                  ? true
                  : false
              )}
            handleChange={handleChangePurchaseOrder}
            value={values.purchase_order_id}
            getSubTitle={(purchaseOrder: any) => {
              return (
                purchaseOrder?.sender_project?.name +
                  ' ; ' +
                  purchaseOrder.seller_details.name ?? ''
              );
            }}
            isItemDisabled={(purchaseOrder: any) => {
              return (
                purchaseOrder.active_status === RadioButtonStatus.ALREADY_EXISTS
              );
            }}
            disableTypography={t('texts.' + RadioButtonStatus.ALREADY_EXISTS)}
          />
        </Stack>
      </Stack>
      <Button
        variant='contained'
        onClick={handleSelectPurchaseOrder}
        disabled={disabledButton || isCreatingPo.isLoading}
      >
        {t('buttons.select')}
      </Button>
      {t('texts.or')}
      <LoadingButton
        loading={isCreatingPo.isLoading}
        variant='outlined'
        onClick={() => {
          if (selectedSeller) {
            handleCreatePO();
          } else {
            actions.setProductManagerPopupStatus(
              ProductManagerPopupStatus.CREATE_PO
            );
          }
        }}
      >
        {t('popups.titles.create_new_purchase_order')}
      </LoadingButton>
    </Stack>
  );
};
