import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";

interface Props {
    value: string;
    handleChange: (value: string) => void;
    endAdornment?: React.ReactNode;
    placeholder: string;
    type?: string;
    onBlur?: () => void;
    autoFocus?: boolean;
}

const useStyles = makeStyles({
    root: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield', // Firefox
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none', // Webkit browsers
            margin: 0,
        },
        '& .custom-placeholder': {
            color: '#000000',
            fontWeight: '400',
            opacity: 0.6, // Adjust the opacity for placeholder
        },
    },
});

export const EditableTextField: FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <TextField
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
                e.stopPropagation();
                props.handleChange(e.target.value);
            }}
            value={props.value}
            fullWidth
            type={props.type ?? 'text'}
            placeholder={props.placeholder}
            InputProps={{
                endAdornment: props.endAdornment
            }}
            InputLabelProps={{
                className: 'custom-placeholder'  
            }}
            className={classes.root}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus}
        />
    );
};
