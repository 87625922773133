import { DescPopup, StatusChip, StyledPopper } from "@/components"
import { apiRoutes, procurementStatus } from "@/config"
import { request } from "@/helpers"
import { ColumnFormat, ColumnType, LinkType, TableColumnMapper, TableMapperType, TableSelectedFilters } from "@/models"
import { Box, Grid, Typography } from "@mui/material"
import HovarableListChip from "src/ui/components/dumb/company-index-table/HovarableListChip"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useState } from "react"
import { useUserData } from "@/zustand"
import { useTranslation } from 'next-i18next'
import { Chip } from "@mui/material"
import { sm } from "src/site-map"
import { useRouter } from 'next/router'
import usePopup from "src/logic/hooks/use-popup"
import { formatNumber } from "src/logic/utils/numbers"
import ProductQuickView from "src/ui/components/smart/product/product-quick-view"
import PrivacyButton from "src/ui/components/shared/buttons/privacy-button"
import Link from "next/link"

export const useApplyTableFilters = (tableType: TableMapperType | null, pageNumber: number, searchText: string | null, requiresUserAction: boolean, filters: TableSelectedFilters[] | null, params: undefined | any) => {
    let data = { page_number: pageNumber, search_text: searchText, requires_user_action: requiresUserAction, filters: filters }

    let URLBasedOnType: any
    if (tableType === TableMapperType.request_for_quotes_buyer) { URLBasedOnType = apiRoutes.applyRFQTableFilters }
    else if (tableType === TableMapperType.request_for_quotes_seller) { URLBasedOnType = apiRoutes.applyRFQSellerTableFilters }
    else if (tableType === TableMapperType.quotes_seller) { URLBasedOnType = apiRoutes.applyQuoteSellerTableFilters }
    else if (tableType === TableMapperType.catalog_draft) { URLBasedOnType = apiRoutes.applyCatalogSellerTableFilters }
    else if (tableType === TableMapperType.catalog) { URLBasedOnType = apiRoutes.applyCatalogTableFilters }
    else if (tableType === TableMapperType.quotes_buyer) { URLBasedOnType = apiRoutes.applyQuoteBuyerTableFilters }
    else if (tableType === TableMapperType.requisitions) { URLBasedOnType = apiRoutes.applyRequisitionsTableFilters }
    else if (tableType === TableMapperType.projects_seller) { URLBasedOnType = apiRoutes.applySellerProjectsTableFilters }
    else if (tableType === TableMapperType.projects_buyer) { URLBasedOnType = apiRoutes.applyBuyerProjectsTableFilters }
    else if (tableType === TableMapperType.seller_buyers) { URLBasedOnType = apiRoutes.filterSellerBuyers }
    else if (tableType === TableMapperType.seller_all_buyers) { URLBasedOnType = apiRoutes.filterSellerAllBuyers }
    else if (tableType === TableMapperType.buyer_sellers) { URLBasedOnType = apiRoutes.filterBuyerSellers }
    else if (tableType === TableMapperType.buyer_all_sellers) { URLBasedOnType = apiRoutes.filterBuyerAllSellers }
    else if (tableType === TableMapperType.buyer_comparison_table) { URLBasedOnType = apiRoutes.filterComparisonTables }
    else if (tableType === TableMapperType.buyer_purchase_order) { URLBasedOnType = apiRoutes.buyerFilterPurchaseOrder }
    else if (tableType === TableMapperType.seller_purchase_order) { URLBasedOnType = apiRoutes.sellerFilterPurchaseOrder }

    // As a buyer -- Seller Company Tabs
    else if (tableType === TableMapperType.seller_company_request_for_quotes) { URLBasedOnType = apiRoutes.applyRFQTableFilters }
    else if (tableType === TableMapperType.seller_company_comparison_tables) { URLBasedOnType = apiRoutes.filterComparisonTables }
    else if (tableType === TableMapperType.seller_company_quotes) { URLBasedOnType = apiRoutes.applyQuoteBuyerTableFilters }
    else if (tableType === TableMapperType.seller_company_purchase_order) { URLBasedOnType = apiRoutes.buyerFilterPurchaseOrder }

    // As a seller -- Buyer Company Tabs
    else if (tableType === TableMapperType.buyer_company_request_for_quotes) { URLBasedOnType = apiRoutes.applyRFQSellerTableFilters }
    else if (tableType === TableMapperType.buyer_company_quotes) { URLBasedOnType = apiRoutes.applyQuoteSellerTableFilters }
    else if (tableType === TableMapperType.buyer_company_purchase_order) { URLBasedOnType = apiRoutes.sellerFilterPurchaseOrder }

    // As a buyer -- Project Tabs
    else if (tableType === TableMapperType.project_tables_requisitions) { URLBasedOnType = apiRoutes.projectTablesRequisitionFilters }
    else if (tableType === TableMapperType.project_tables_request_for_quotes_buyer) { URLBasedOnType = apiRoutes.applyRFQTableFilters }
    else if (tableType === TableMapperType.project_tables_buyer_comparison_table) { URLBasedOnType = apiRoutes.filterComparisonTables }
    else if (tableType === TableMapperType.project_tables_quotes_buyer) { URLBasedOnType = apiRoutes.applyQuoteBuyerTableFilters }
    else if (tableType === TableMapperType.project_tables_buyer_purchase_order) { URLBasedOnType = apiRoutes.buyerFilterPurchaseOrder }

    // As a seller -- Project Tabs
    else if (tableType === TableMapperType.project_tables_request_for_quotes_seller) { URLBasedOnType = apiRoutes.applyRFQSellerTableFilters }
    else if (tableType === TableMapperType.project_tables_quotes_seller) { URLBasedOnType = apiRoutes.applyQuoteSellerTableFilters }
    else if (tableType === TableMapperType.project_tables_seller_purchase_order) { URLBasedOnType = apiRoutes.sellerFilterPurchaseOrder }

    if (URLBasedOnType) { return request({ url: URLBasedOnType, method: "POST", data: data, params }) }
    return null;
}

export const getTableMapper = (type: TableMapperType | null) => {
    switch (type) {
        case TableMapperType.quotes_seller:
            return QuoteSellerTableMapper();
        case TableMapperType.quotes_buyer:
            return QuoteBuyerTableMapper();
        case TableMapperType.requisitions:
            return RequisitionsTableMapper();
        case TableMapperType.request_for_quotes_buyer:
            return RFQBuyerTableMapper();
        case TableMapperType.request_for_quotes_seller:
            return RFQSellerTableMapper();
        case TableMapperType.catalog_draft:
            return catalogDraftTableMapper();
        case TableMapperType.catalog:
            return catalogTableMapper();
        case TableMapperType.projects_seller:
            return ProjectSellerTableMapper();
        case TableMapperType.projects_buyer:
            return ProjectBuyerTableMapper();
        case TableMapperType.seller_buyers:
            return SellerBuyersTableMapper();
        case TableMapperType.seller_all_buyers:
            return SellerAllBuyersTableMapper();
        case TableMapperType.buyer_sellers:
            return BuyerSellersTableMapper();
        case TableMapperType.buyer_all_sellers:
            return BuyerAllSellersTableMapper();
        case TableMapperType.buyer_comparison_table:
            return BuyerCTTableMapper();
        case TableMapperType.buyer_purchase_order:
            return POBuyerTableMapper();
        case TableMapperType.seller_purchase_order:
            return POSellerTableMapper();


        // As a buyer -- Seller Company Tabs
        case TableMapperType.seller_company_request_for_quotes:
            return SellerCompanyRFQBuyerTableMapper();
        case TableMapperType.seller_company_comparison_tables:
            return SellerCompanyBuyerCTTableMapper();
        case TableMapperType.seller_company_quotes:
            return SellerCompanyQuoteBuyerTableMapper();
        case TableMapperType.seller_company_purchase_order:
            return SellerCompanyPOBuyerTableMapper();

        // As a seller -- Buyer Company Tabs
        case TableMapperType.buyer_company_request_for_quotes:
            return BuyerCompanyRFQSellerTableMapper();
        case TableMapperType.buyer_company_quotes:
            return BuyerCompanyQuoteSellerTableMapper();
        case TableMapperType.buyer_company_purchase_order:
            return BuyerCompanyPOSellerTableMapper();

        // As a buyer -- Project Tabs
        case TableMapperType.project_tables_requisitions:
            return ProjectTablesRequisitionsTableMapper();
        case TableMapperType.project_tables_request_for_quotes_buyer:
            return ProjectTablesRFQsTableMapper();
        case TableMapperType.project_tables_buyer_comparison_table:
            return ProjectTablesCtBuyerTableMapper();
        case TableMapperType.project_tables_quotes_buyer:
            return ProjectTablesQuoteBuyerTableMapper();
        case TableMapperType.project_tables_buyer_purchase_order:
            return ProjectTablesPOBuyerTableMapper();

        // As a seller -- Project Tabs
        case TableMapperType.project_tables_request_for_quotes_seller:
            return ProjectTablesRFQSellerTableMapper();
        case TableMapperType.project_tables_quotes_seller:
            return ProjectTablesQuoteSellerTableMapper();
        case TableMapperType.project_tables_seller_purchase_order:
            return ProjectTablesPOSellerTableMapper();


        default:
            return null;
    }
};

export const getTableIndex = (tableType: TableMapperType | null, params: undefined | any) => {
    let URLBasedOnType: string | undefined;

    if (tableType === TableMapperType.request_for_quotes_buyer) { URLBasedOnType = apiRoutes.request_for_quotes_index }
    else if (tableType === TableMapperType.request_for_quotes_seller) { URLBasedOnType = apiRoutes.request_for_quotes_seller_index }
    else if (tableType === TableMapperType.quotes_seller) { URLBasedOnType = apiRoutes.quote.seller.index }
    else if (tableType === TableMapperType.quotes_buyer) { URLBasedOnType = apiRoutes.quote.buyer.index }
    else if (tableType === TableMapperType.catalog_draft) { URLBasedOnType = apiRoutes.catalogDraftIndex }
    else if (tableType === TableMapperType.catalog) { URLBasedOnType = apiRoutes.seller + apiRoutes.catalog + apiRoutes.index }
    else if (tableType === TableMapperType.requisitions) { URLBasedOnType = apiRoutes.requisitionTableIndex }
    else if (tableType === TableMapperType.projects_seller) { URLBasedOnType = apiRoutes.indexProjectsSeller }
    else if (tableType === TableMapperType.projects_buyer) { URLBasedOnType = apiRoutes.indexProjectsBuyer }
    else if (tableType === TableMapperType.seller_buyers) { URLBasedOnType = apiRoutes.indexSellerBuyers }
    else if (tableType === TableMapperType.seller_all_buyers) { URLBasedOnType = apiRoutes.indexSellerAllBuyers }
    else if (tableType === TableMapperType.buyer_sellers) { URLBasedOnType = apiRoutes.indexBuyerSellers }
    else if (tableType === TableMapperType.buyer_all_sellers) { URLBasedOnType = apiRoutes.indexBuyerAllSellers }
    else if (tableType === TableMapperType.buyer_comparison_table) { URLBasedOnType = apiRoutes.indexComparisonTables }
    else if (tableType === TableMapperType.buyer_purchase_order) { URLBasedOnType = apiRoutes.buyerPurchaseOrder + apiRoutes.index }
    else if (tableType === TableMapperType.seller_purchase_order) { URLBasedOnType = apiRoutes.sellerPurchaseOrder + apiRoutes.index }

    // As a buyer -- Seller Company Tabs
    else if (tableType === TableMapperType.seller_company_request_for_quotes) { URLBasedOnType = apiRoutes.request_for_quotes_index }
    else if (tableType === TableMapperType.seller_company_comparison_tables) { URLBasedOnType = apiRoutes.indexComparisonTables }
    else if (tableType === TableMapperType.seller_company_quotes) { URLBasedOnType = apiRoutes.quote.buyer.index }
    else if (tableType === TableMapperType.seller_company_purchase_order) { URLBasedOnType = apiRoutes.buyerPurchaseOrder + apiRoutes.index }

    // As a seller -- Buyer Company Tabs
    else if (tableType === TableMapperType.buyer_company_request_for_quotes) { URLBasedOnType = apiRoutes.request_for_quotes_seller_index }
    else if (tableType === TableMapperType.buyer_company_quotes) { URLBasedOnType = apiRoutes.quote.seller.index }
    else if (tableType === TableMapperType.buyer_company_purchase_order) { URLBasedOnType = apiRoutes.sellerPurchaseOrder + apiRoutes.index }

    // As a buyer -- Project Tabs
    else if (tableType === TableMapperType.project_tables_requisitions) { URLBasedOnType = apiRoutes.projectTablesRequisitionIndex }
    else if (tableType === TableMapperType.project_tables_request_for_quotes_buyer) { URLBasedOnType = apiRoutes.request_for_quotes_index }
    else if (tableType === TableMapperType.project_tables_buyer_comparison_table) { URLBasedOnType = apiRoutes.indexComparisonTables }
    else if (tableType === TableMapperType.project_tables_quotes_buyer) { URLBasedOnType = apiRoutes.quote.buyer.index }
    else if (tableType === TableMapperType.project_tables_buyer_purchase_order) { URLBasedOnType = apiRoutes.buyerPurchaseOrder + apiRoutes.index }

    // As a seller -- Project Tabs
    else if (tableType === TableMapperType.project_tables_request_for_quotes_seller) { URLBasedOnType = apiRoutes.request_for_quotes_seller_index }
    else if (tableType === TableMapperType.project_tables_quotes_seller) { URLBasedOnType = apiRoutes.quote.seller.index }
    else if (tableType === TableMapperType.project_tables_seller_purchase_order) { URLBasedOnType = apiRoutes.sellerPurchaseOrder + apiRoutes.index }

    if (URLBasedOnType) { return request({ url: URLBasedOnType, method: "GET", params }); }
    return null;
};


export const RFQBuyerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'rfq', ColumnType.LINK)
    nameColumn.childMapper = new TableColumnMapper('name', '', ColumnType.LINK)

    const projectColumn = new TableColumnMapper('sender_project', 'project', ColumnType.STRING, null, null, false, (data: any) => data?.project ? <ClickableLink id={data.project.id} name={data.project.name} link={sm.portals.buyer.projects.projectId(data.project.id).url} /> : data?.sender_project ? <ClickableLink id={data.sender_project.id} name={data.sender_project.name} link={sm.portals.buyer.projects.projectId(data.sender_project.id).url} /> : '-')

    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)

    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)

    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    validityDateColumn.childMapper = new TableColumnMapper('validity_date', '', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')

    const readStatusColumn = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)
    readStatusColumn.childMapper = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)

    const numberOfSellersColumn = new TableColumnMapper('number_of_sellers', 'sellers', ColumnType.DOUBLE, null, null, false, (data: any) => data.number_of_sellers ? data.number_of_sellers + " Sellers" : data.seller_details ? data.seller_details.name : "-")
    numberOfSellersColumn.childMapper = new TableColumnMapper('seller_details.name', '', ColumnType.DOUBLE, null, null)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    numberOfProductsColumn.childMapper = new TableColumnMapper('number_of_products', '', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)



    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)
    statusColumn.childMapper = new TableColumnMapper('status', '', ColumnType.STATUS)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        ownerColumn,
        validityDateColumn,
        readStatusColumn,
        numberOfSellersColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesRFQsTableMapper = () => RFQBuyerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const SellerCompanyRFQBuyerTableMapper = () => RFQBuyerTableMapper().filter((column) => column.accessorKey !== 'number_of_sellers');

export const QuoteSellerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'quote', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('sender_project', 'project', ColumnType.STRING, null, null, false, (data: any) => data?.sender_project ? <ClickableLink id={data.sender_project?.id} name={data.sender_project?.name} link={sm.portals.buyer.projects.projectId(data.sender_project.id).url} /> : '-')
    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const readStatusColumn = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)
    const buyerColumn = new TableColumnMapper('buyer_details.name', 'buyer', ColumnType.LONG)
    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    const valueColumn = new TableColumnMapper('total_value', 'value', ColumnType.STRING, null, null, false, (data) => <PriceCell price={data.total_value} />)
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)

    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)

    return [
        nameColumn,
        projectColumn,
        ownerColumn,
        dateColumn,
        readStatusColumn,
        buyerColumn,
        validityDateColumn,
        valueColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesQuoteSellerTableMapper = () => QuoteSellerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const BuyerCompanyQuoteSellerTableMapper = () => QuoteSellerTableMapper().filter((column) => column.accessorKey !== 'buyer_details.name');

export const QuoteBuyerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'quote', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('receiver_project', 'project', ColumnType.STRING, null, null, false, (data: any) => data?.receiver_project ? <ClickableLink id={data.receiver_project?.id} name={data.receiver_project?.name} link={sm.portals.buyer.projects.projectId(data.receiver_project.id).url} /> : '-')
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const sellerColumn = new TableColumnMapper('seller_details.name', 'sellers', ColumnType.LONG)
    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    const valueColumn = new TableColumnMapper('total_value', 'value', ColumnType.STRING, null, null, false, (data) => <PriceCell price={data.total_value} />)
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        sellerColumn,
        validityDateColumn,
        valueColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesQuoteBuyerTableMapper = () => QuoteBuyerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const SellerCompanyQuoteBuyerTableMapper = () => QuoteBuyerTableMapper().filter((column) => column.accessorKey !== 'seller_details.name');

export const ProjectSellerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'project', ColumnType.LINK)
    const projectTypeColumn = new TableColumnMapper('project_type.name', 'type', ColumnType.LONG, null, null, false, (data) => data.project_type ? <TypeChip type={data.project_type.name} /> : '-')
    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const numberOfRfqs = new TableColumnMapper('number_of_rfqs', 'rfq.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.request_for_quotes?.count} statisticName={'RFQ'} />)
    const numberOfQuotes = new TableColumnMapper('number_of_quotes', 'quotes', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.quotes?.count} statisticName={'Quote'} />)
    const numberOfPOs = new TableColumnMapper('number_of_purchase_orders', 'P.O.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.purchase_orders?.count} statisticName={'P.O.'} />)

    return [
        nameColumn,
        projectTypeColumn,
        ownerColumn,
        dateColumn,
        numberOfRfqs,
        numberOfQuotes,
        numberOfPOs
    ];
}

export const SellerBuyersTableMapper = () => {
    const privacyColumn = new TableColumnMapper('is_private', '', ColumnType.BUTTON, null, null, false, row => row.is_private ? <PrivacyButton disableText zIndex={1300} content={'This item is private to you and the assigned buyer(s) because it was created and/or linked by your company.'} /> : null)

    const nameColumn = new TableColumnMapper('name', 'company', ColumnType.STRING, null, null, false, row => (
        <>
            <Link href={sm.portals.seller.buyers.buyerId(row.id).url} style={{ textDecoration: 'none' }}>
                <span style={{ color: '#3A3E50', fontWeight: 400, fontSize: 13 }} className='underline-on-hover'>
                    {row.name}
                </span>
            </Link>
            <br />
            <span style={{ color: '#8B8D98', fontWeight: 400, fontSize: 12 }}>{row.country?.name}</span>
        </>
    ));
    nameColumn.childMapper = new TableColumnMapper('', '', ColumnType.STRING);

    const numberOfBuyersColumn = new TableColumnMapper('', 'buyers', ColumnType.STRING, null, null, false, (row) => `${row.users?.length ?? 0} Buyers`);
    numberOfBuyersColumn.childMapper = new TableColumnMapper('full_name', '', ColumnType.STRING)

    const workNumberColumn = new TableColumnMapper('phone_number', 'phone number', ColumnType.STRING, null, null, false, (data) => data.phone_number ? data.phone_number : '-');
    workNumberColumn.childMapper = new TableColumnMapper('mobile_number.number', '', ColumnType.STRING);

    const emailColumn = new TableColumnMapper('email', 'email address', ColumnType.STRING, null, null, false, (data) => data.email ? data.email : '-');
    emailColumn.childMapper = new TableColumnMapper('email', '', ColumnType.STRING);

    const rfqsColumn = new TableColumnMapper('rfqs', 'rfq.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.request_for_quotes.count} statisticName={'RFQ'} totalValue={data.statistics?.request_for_quotes.value} />)
    const quotesColumn = new TableColumnMapper('quotes', 'quote.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.quotes.count} statisticName={'Quote'} totalValue={data.statistics?.quotes.value} />)
    const posColumn = new TableColumnMapper('po.s', 'po.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.purchase_orders.count} statisticName={'P.O.'} totalValue={data.statistics?.purchase_orders.value} />)

    return [
        privacyColumn,
        nameColumn,
        numberOfBuyersColumn,
        workNumberColumn,
        emailColumn,
        rfqsColumn,
        quotesColumn,
        posColumn
    ];
}

export const SellerAllBuyersTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'company', ColumnType.LINK);
    nameColumn.childMapper = new TableColumnMapper('', '', ColumnType.STRING);

    const numberOfBuyersColumn = new TableColumnMapper('', 'buyers', ColumnType.STRING, null, null, false, (row) => `${row.users?.length ?? 0} Buyers`);
    numberOfBuyersColumn.childMapper = new TableColumnMapper('full_name', '', ColumnType.STRING)

    const workNumberColumn = new TableColumnMapper('phone_number', 'phone number', ColumnType.STRING, null, null, false, (data) => data.phone_number ? data.phone_number : '-');
    workNumberColumn.childMapper = new TableColumnMapper('mobile_number.number', '', ColumnType.STRING);

    const emailColumn = new TableColumnMapper('email', 'email address', ColumnType.STRING, null, null, false, (data) => data.email ? data.email : '-');
    emailColumn.childMapper = new TableColumnMapper('email', '', ColumnType.STRING);

    const countryColumn = new TableColumnMapper('country.name', 'country', ColumnType.STRING);

    return [
        nameColumn,
        numberOfBuyersColumn,
        workNumberColumn,
        emailColumn,
        countryColumn,
    ];
}

export const BuyerSellersTableMapper = () => {
    const privacyColumn = new TableColumnMapper('is_private', '', ColumnType.BUTTON, null, null, false, row => row.is_private ? <PrivacyButton disableText zIndex={1300} /> : null)

    const nameColumn = new TableColumnMapper('name', 'company', ColumnType.STRING, null, null, false, row => (
        <>
            <Link href={sm.portals.buyer.sellers.sellerId(row.id).url} style={{ textDecoration: 'none' }}>
                <span style={{ color: '#3A3E50', fontWeight: 400, fontSize: 13 }} className='underline-on-hover'>
                    {row.name}
                </span>
            </Link>
            <br />
            <span style={{ color: '#8B8D98', fontWeight: 400, fontSize: 12 }}>{row.country?.name}</span>
        </>
    ));
    nameColumn.childMapper = new TableColumnMapper('', '', ColumnType.STRING);

    const numberOfSellersColumn = new TableColumnMapper('', 'sellers', ColumnType.STRING, null, null, false, (row) => `${row.users?.length ?? 0} Sellers`);
    numberOfSellersColumn.childMapper = new TableColumnMapper('full_name', '', ColumnType.STRING)

    const contactColumn = new TableColumnMapper('contact', 'contact', ColumnType.STRING, null, null, false, row => (
        <>
            {row.phone_number && <div>{row.phone_number}</div>}
            {row.email && <div>{row.email}</div>}
        </>
    ));
    contactColumn.childMapper = new TableColumnMapper('mobile_number.number', '', ColumnType.STRING);

    const categoriesColumn = new TableColumnMapper('', 'categories', ColumnType.STRING, null, null, false, (row) => <HovarableListChip items={row.categories} type="Categories" />);
    categoriesColumn.childMapper = new TableColumnMapper('email', '', ColumnType.STRING);
    const brandsColumn = new TableColumnMapper('', 'brands', ColumnType.STRING, null, null, false, (row) => <HovarableListChip items={row.brands} type="Brands" />);

    const rfqsColumn = new TableColumnMapper('rfqs', 'rfq.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.request_for_quotes.count} statisticName={'RFQ'} totalValue={data.statistics?.request_for_quotes.value} />)
    const quotesColumn = new TableColumnMapper('quotes', 'quote.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.quotes.count} statisticName={'Quote'} totalValue={data.statistics?.quotes.value} />)
    const posColumn = new TableColumnMapper('po.s', 'po.s', ColumnType.STRING, null, null, false, (data: any) => <DoubleRowsInCell statisticCount={data.statistics?.purchase_orders.count} statisticName={'P.O.'} totalValue={data.statistics?.purchase_orders.value} />)

    return [
        privacyColumn,
        nameColumn,
        numberOfSellersColumn,
        contactColumn,
        categoriesColumn,
        brandsColumn,
        rfqsColumn,
        quotesColumn,
        posColumn
    ];
}

export const BuyerAllSellersTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'company', ColumnType.LINK);
    nameColumn.childMapper = new TableColumnMapper('', '', ColumnType.STRING);

    const numberOfSellersColumn = new TableColumnMapper('', 'sellers', ColumnType.STRING, null, null, false, (row) => `${row.users?.length ?? 0} Sellers`);
    numberOfSellersColumn.childMapper = new TableColumnMapper('full_name', '', ColumnType.STRING)

    const workNumberColumn = new TableColumnMapper('phone_number', 'phone number', ColumnType.STRING, null, null, false, (data) => data.phone_number ? data.phone_number : '-');

    const emailColumn = new TableColumnMapper('email', 'email address', ColumnType.STRING, null, null, false, (data) => data.email ? data.email : '-');
    emailColumn.childMapper = new TableColumnMapper('email', '', ColumnType.STRING);

    const countryColumn = new TableColumnMapper('country.name', 'country', ColumnType.STRING, null, null, false, (data) => data.country ? data.country.name : '-');

    const categoriesColumn = new TableColumnMapper('', 'categories', ColumnType.STRING, null, null, false, (row) => <HovarableListChip items={row.categories} type="Categories" />);
    const brandsColumn = new TableColumnMapper('', 'brands', ColumnType.STRING, null, null, false, (row) => <HovarableListChip items={row.brands} type="Brands" />);

    return [
        nameColumn,
        numberOfSellersColumn,
        workNumberColumn,
        emailColumn,
        countryColumn,
        categoriesColumn,
        brandsColumn
    ];
}

// export const BuyerAllSellersTableMapper = () => BuyerSellersTableMapper().filter((column) => !['rfqs', 'quotes', 'p.os'].includes(column.accessorKey));

export const ProjectBuyerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'project', ColumnType.LINK)
    const projectTypeColumn = new TableColumnMapper('project_type.name', 'type', ColumnType.LONG, null, null, false, (data) => data.project_type ? <TypeChip type={data.project_type.name} /> : '-')
    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const numberOfRequisitions = new TableColumnMapper('number_of_requisitions', 'req.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.requisitions?.count} statisticName={'REQ'} />)
    const numberOfRfqs = new TableColumnMapper('number_of_rfqs', 'rfq.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.request_for_quotes?.count} statisticName={'RFQ'} />)
    const numberOfCTs = new TableColumnMapper('number_of_comparison_tables', 'CT.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.comparison_table?.count} statisticName={'CT'} />)
    const numberOfQuotes = new TableColumnMapper('number_of_quotes', 'quotes', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.quotes?.count} statisticName={'Quote'} />)
    const numberOfPOs = new TableColumnMapper('number_of_purchase_orders', 'P.O.s', ColumnType.STRING, null, null, false, (data) => <DoubleRowsInCell statisticCount={data?.statistics?.purchase_orders?.count} statisticName={'P.O.'} />)

    return [
        nameColumn,
        projectTypeColumn,
        ownerColumn,
        dateColumn,
        numberOfRequisitions,
        numberOfRfqs,
        numberOfCTs,
        numberOfQuotes,
        numberOfPOs
    ];
}

export const RFQSellerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'rfq', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('receiver_project', 'project', ColumnType.LONG, null, null, false, (data: any) => data?.receiver_project ? <ClickableLink id={data.receiver_project?.id} name={data.receiver_project?.name} link={sm.portals.buyer.projects.projectId(data.receiver_project.id).url} /> : '-')
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE, null, null, false, (data) => data.created_at ? data.created_at : '-')
    const BuyerColumn = new TableColumnMapper('buyer_details.name', 'buyer', ColumnType.STRING)
    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    const statusColumn = new TableColumnMapper('table_status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        BuyerColumn,
        validityDateColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesRFQSellerTableMapper = () => RFQSellerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const BuyerCompanyRFQSellerTableMapper = () => RFQSellerTableMapper().filter((column) => column.accessorKey !== 'buyer_details.name');

export const RequisitionsTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'requisition', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('project.name', 'project', ColumnType.LONG)
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const ownerColumn = new TableColumnMapper('owner_name', 'owner', ColumnType.LINK_DOUBLE)
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    const statusColumn = new TableColumnMapper('table_status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        ownerColumn,
        numberOfProductsColumn,
        statusColumn,
    ];
}

export const ProjectTablesRequisitionsTableMapper = () => RequisitionsTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const catalogDraftTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'list name', ColumnType.LINK)
    nameColumn.childMapper = new TableColumnMapper('name', '', ColumnType.LINK)

    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)

    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LONG)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'products', ColumnType.STRING, null, null, false, (data: any) => data.number_of_products + ' Products')
    numberOfProductsColumn.childMapper = new TableColumnMapper('number_of_products', '', ColumnType.STRING)

    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)
    statusColumn.childMapper = new TableColumnMapper('status', '', ColumnType.STATUS,)

    return [
        nameColumn,
        dateColumn,
        ownerColumn,
        numberOfProductsColumn,
        statusColumn,
    ];
}

export const catalogTableMapper = () => {
    // const imageColumn = new TableColumnMapper('product.image_url', 'photo', ColumnType.IMAGE, null, null, false, data => data?.product?.image_url && <Box height='50px' paddingX={3}><img src={data?.product?.image_url} height={'50px'} width={'100%'} style={{ objectFit: 'contain' }} /></Box>)
    const imageColumn = new TableColumnMapper('product.image_url', 'photo', ColumnType.IMAGE)
    // const nameColumn = new TableColumnMapper('product.name', '', ColumnType.LONG)
    const nameColumn = new TableColumnMapper('product.name', 'product', ColumnType.STRING, null, null, false, (data: any) => <ClickableProductWithDetails product={data.product} />)

    const brandImageColumn = new TableColumnMapper('product.brand.image_url', 'brand', ColumnType.IMAGE)
    const groupColumn = new TableColumnMapper('product.group.name', 'group', ColumnType.STRING)
    const categoryColumn = new TableColumnMapper('product.cat.name', 'category', ColumnType.STRING)
    const classColumn = new TableColumnMapper('product.class.name', 'class', ColumnType.STRING)
    const typeColumn = new TableColumnMapper('product.type.name', 'type', ColumnType.STRING)
    const switchColumn = new TableColumnMapper('is_active', 'published', ColumnType.SWITCH)
    const inputColumn = new TableColumnMapper('price', 'price', ColumnType.INPUT)
    const deleteColumn = new TableColumnMapper('delete', '', ColumnType.DELETE, null, null, true)

    return [
        imageColumn,
        nameColumn,
        brandImageColumn,
        groupColumn,
        categoryColumn,
        classColumn,
        typeColumn,
        inputColumn,
        switchColumn,
        deleteColumn
    ];
}

export const BuyerCTTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'table', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('project_name', 'project', ColumnType.LONG)
    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)
    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)
    const competitorsColumn = new TableColumnMapper('competitors', 'sellers', ColumnType.STRING, null, null, false, (data: any) => data.competitors + ' Sellers')
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)

    const receivedQuotesColumn = new TableColumnMapper('received_quotes_count', 'quotes', ColumnType.STRING, null, null, false, (data: any) => data.received_quotes_count + ' Quotes')
    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STRING, null, null, false, (data) => <StatusChip status={data.status == procurementStatus.DRAFT ? "PENDING_QUOTES" : data.status} />);

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        ownerColumn,
        competitorsColumn,
        numberOfProductsColumn,
        receivedQuotesColumn,
        statusColumn
    ];
}

export const ProjectTablesCtBuyerTableMapper = () => BuyerCTTableMapper().filter((column) => column.accessorKey !== 'project_name');

export const SellerCompanyBuyerCTTableMapper = () => BuyerCTTableMapper().filter((column) => column.accessorKey !== 'competitors');

export const POBuyerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'p.o.', ColumnType.LINK)
    nameColumn.childMapper = new TableColumnMapper('name', '', ColumnType.LINK)

    const projectColumn = new TableColumnMapper('sender_project', 'project', ColumnType.DOUBLE, null, null, false, (data: any) => data?.project ? <ClickableLink id={data.project.id} name={data.project.name} link={sm.portals.buyer.projects.projectId(data.project.id).url} /> : data?.sender_project ? <ClickableLink id={data.sender_project.id} name={data.sender_project.name} link={sm.portals.buyer.projects.projectId(data.sender_project.id).url} /> : '-')

    const dateColumn = new TableColumnMapper('created_at', 'created on', ColumnType.DATE)

    const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.LINK_DOUBLE)

    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    validityDateColumn.childMapper = new TableColumnMapper('validity_date', '', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')

    const readStatusColumn = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)
    readStatusColumn.childMapper = new TableColumnMapper('read_status', '', ColumnType.READ_TICK)

    const numberOfSellersColumn = new TableColumnMapper('number_of_sellers', 'sellers', ColumnType.DOUBLE, null, null, false, (data: any) => data.purchaseOrders?.length > 0 ? data.purchaseOrders?.length + " Sellers" : data.seller_details?.name)
    numberOfSellersColumn.childMapper = new TableColumnMapper('seller_details.name', '', ColumnType.DOUBLE)

    const valueColumn = new TableColumnMapper('total_value', 'Value', ColumnType.STRING, null, null, false, (data) => <PriceCell price={data.total_value} />)
    valueColumn.childMapper = new TableColumnMapper('total_value', 'Value', ColumnType.STRING, null, null, false, (data) => <PriceCell price={data.total_value} />)

    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    numberOfProductsColumn.childMapper = new TableColumnMapper('number_of_products', '', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)

    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)
    statusColumn.childMapper = new TableColumnMapper('status', '', ColumnType.STATUS)

    return [
        nameColumn,
        projectColumn,
        dateColumn,
        ownerColumn,
        validityDateColumn,
        readStatusColumn,
        numberOfSellersColumn,
        valueColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesPOBuyerTableMapper = () => POBuyerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const SellerCompanyPOBuyerTableMapper = () => POBuyerTableMapper().filter((column) => column.accessorKey !== 'number_of_sellers');


export const POSellerTableMapper = () => {
    const nameColumn = new TableColumnMapper('name', 'po', ColumnType.LINK)
    const projectColumn = new TableColumnMapper('receiver_project', 'project', ColumnType.DOUBLE, null, null, false, (data: any) => data?.receiver_project ? <ClickableLink id={data.receiver_project?.id} name={data.receiver_project?.name} link={sm.portals.buyer.projects.projectId(data.receiver_project.id).url} /> : '-')
    const buyerColumn = new TableColumnMapper('buyer_details.name', 'buyer', ColumnType.DOUBLE)
    const dateColumn = new TableColumnMapper('created_at', 'received on', ColumnType.DATE)
    // const ownerColumn = new TableColumnMapper('owner.full_name', 'owner', ColumnType.DOUBLE)
    const validityDateColumn = new TableColumnMapper('validity_date', 'valid until', ColumnType.STRING, null, null, false, (data: any) => data.is_expired ? <ExpiryIcon formType={data.form_type} expiryDate={data.validity_date} /> : data.validity_date ? data.validity_date : '-')
    const valueColumn = new TableColumnMapper('total_value', 'value', ColumnType.STRING, null, null, false, (data) => <PriceCell price={data.total_value} />)
    const numberOfProductsColumn = new TableColumnMapper('number_of_products', 'P&S', ColumnType.STRING, null, null, false, ProductsAndServicesNumber)
    const statusColumn = new TableColumnMapper('status', 'status', ColumnType.STATUS, ColumnFormat.CHIP)

    return [
        nameColumn,
        projectColumn,
        buyerColumn,
        dateColumn,
        // ownerColumn,
        validityDateColumn,
        valueColumn,
        numberOfProductsColumn,
        statusColumn
    ];
}

export const ProjectTablesPOSellerTableMapper = () => POSellerTableMapper().filter((column) => column.accessorKey !== 'project.name');

export const BuyerCompanyPOSellerTableMapper = () => POSellerTableMapper().filter((column) => column.accessorKey !== 'buyer_details.name');


const ExpiryIcon = ({ formType, expiryDate }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { isSeller } = useUserData();
    const { t } = useTranslation();

    const [expiryNote, setExpiryNote] = useState(isSeller() ? t('popups.subTitles.buyer_expiry_popup') : t('popups.subTitles.seller_expiry_popup'));

    const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => {
        setIsHovered(true);
        setAnchorEl(event.currentTarget);
        if (formType === LinkType.RFQ_SELLER || formType === LinkType.QUOTE_BUYER || formType === LinkType.PO_SELLER) {
            const newExpiryNote = t('popups.subTitles.expiry_popup');
            setExpiryNote(newExpiryNote);
        }
    }
    const handleHoverOut = () => {
        setIsHovered(false)
        setAnchorEl(null)
    }
    return (
        <>
            <Grid container md={12} alignItems={'center'}>
                <Typography variant="subtitle2">{expiryDate}</Typography>
                <ErrorOutlineOutlinedIcon onMouseEnter={(event) => handleHoverIn(event)} onMouseLeave={handleHoverOut} sx={{ ml: 0.5, cursor: 'pointer', color: 'error.main', fontSize: '18px' }} />
            </Grid>
            <StyledPopper open={isHovered} anchorEl={anchorEl} handleClose={handleHoverOut}>
                <Grid container md={12} p={2} width={300}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: '22px !important', color: 'error.main' }} />
                    <Typography variant='h6' color='error.main' fontWeight={700} pl={1}>{t('popups.titles.expired')}</Typography>
                    <Grid container md={12} py={1}> <Typography variant='subtitle2' fontWeight={400}>   {expiryNote}  </Typography> </Grid>
                </Grid>
            </StyledPopper>
        </>
    )
}

const TypeChip = ({ type }) => {
    return (<Chip label={type} variant={'filled'} sx={{ minWidth: "144px", color: 'rgba(19, 98, 140, 1)', bgcolor: 'white', border: `1px solid rgba(19, 98, 140, 1)` }} />)
}

const ClickableLink = ({ id, name, link }) => {
    const { push } = useRouter()
    return (<Typography key={id} variant='subtitle2' fontWeight={400} onClick={(event) => { event.stopPropagation(); push(link) }} sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>  {name} </Typography>)
}

function ClickableProductWithDetails({ product }) {
    const detailsPopup = usePopup();

    return <>
        <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }} >
            <ProductQuickView product={product} />
        </DescPopup>
        <Typography variant='subtitle2' fontWeight={400} onClick={(event) => { event.stopPropagation(); detailsPopup.handleOpen(); }} sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}> {product.name}</Typography>
    </>
}


const DoubleRowsInCell = ({ statisticCount, statisticName, totalValue }) => {
    return (<>
        {totalValue != null && <Grid container>
            <Typography variant="subtitle2" color={'success.main'} fontWeight={500}> AED {formatNumber(totalValue)}</Typography>
        </Grid>}
        <Grid container>
            <Typography variant="subtitle2" color={'text.disabled'}> {statisticCount} {statisticCount > 1 ? statisticName + 's' : statisticName}</Typography>
        </Grid>
    </>)
}

const PriceCell = ({ price }: { price: number | null | undefined }) => price ? <Typography variant="subtitle2" color={'success.main'} fontWeight={500}> AED {formatNumber(price)}</Typography> : null;

const ProductsAndServicesNumber = (data: any) => <span>{data.number_of_products} Product{data.number_of_products !== 1 && 's'}<br />{data.number_of_services} Service{data.number_of_services !== 1 && 's'}</span>
