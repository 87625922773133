import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { Badge, Tab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'next-i18next';
import { getInputsTranslationKey } from '@/hooks';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: `${theme.palette.success.dark}`,
        boxShadow: `0 0 0 1px ${theme.palette.success.dark}`
    },
}));

const useStyles = makeStyles({
    root: {
        textTransform: "capitalize"
    }
})

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BasicTabs = ({ key, tabs, initialValue, onChange }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(initialValue);

    const updateTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: 'auto', alignItems: 'start' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs key={key} value={value} onChange={(event, newValue) => { updateTab(event, newValue); onChange(newValue) }} variant='scrollable'>
                    {
                        tabs?.map((tab, index) =>
                            <Tab
                                className={classes.root}
                                value={tab.name}
                                disabled={tab.disabled}
                                label={<TabContent tab={tab} />}
                                {...a11yProps(index)}
                            />
                        )
                    }
                </Tabs>
            </Box>
        </Box>
    );
}

const TabContent = ({ tab }) => {
    const { t } = useTranslation();
    return (
        <>
            <Grid container sx={{ display: 'flex', flexDirection: 'row', pr: 3 }}>
                <StyledBadge invisible={!tab.hasNewChanges} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant="dot" sx={{ pr: 1 }}>
                    <Typography variant='body2' fontWeight='400' color='primary.main'>
                        {t(getInputsTranslationKey('labels.tabs.' + tab.name))}
                    </Typography>
                </StyledBadge>
                <Typography sx={{ color: 'text.third', ml: 1 }}>{' ' + tab.count}</Typography>
            </Grid>
        </>
    )
}