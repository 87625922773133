import type { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { getInputsTranslationKey } from '@/hooks';

export const PasswordRestrictions: FC<{}> = () => {
  const { t } = useTranslation();

  const passwordRestrictions = ['min8', 'small_big', 'letter_digit'];
  return (
    <>
      {passwordRestrictions.map(el => {
        return (
          <Typography variant='subtitle2' color='info.main' key={el}>
            - {t(getInputsTranslationKey('error_messages.user.password.' + el))}
          </Typography>
        );
      })}
    </>
  );
};
