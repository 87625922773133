import { Grid, Typography } from '@mui/material';
import { AllowedTo, NotAllowedTo } from 'react-abac';
import { Permissions } from '@/config';
import { CheckedTextDataView, StyledSwitch } from '@/components';
import { useAccessControl, useUserAccessControl } from '@/services';
import { useTranslation } from 'next-i18next';
import { getAccountTranslationKey } from '@/hooks';

type Props = { type: string; titleKey: string, userId?: string, canEditPermission: Permissions};

export const SettingsWithCheck: React.FC<Props> = ({
    data,
    type,
    titleKey,
    userId,
    canEditPermission
}) => {
    const { t } = useTranslation();
    const mutateUser = useAccessControl();
    const mutateAnotherUser = useUserAccessControl()

    const handleChangeSwitch = (status: boolean, id: number, userId: string) => {
        !userId ? mutateUser.mutate({ status: status, access_control_type: type, id: id })
            : mutateAnotherUser.mutate({ status: status, access_control_type: type, id: id, user_id: userId })
    };

    return (
        <>
            <Grid item container xs={12}>
                <Typography variant='h5' fontWeight={'bold'} sx={{ float: 'left', textTransform: 'capitalize' }}>{t(getAccountTranslationKey('sections_titles.' + titleKey))}</Typography>
            </Grid>
            <Grid item xs={12} >
                <hr />
            </Grid>
            {data.map((item, index: number) => {
                return (
                    <Grid key={index} item container xs={12} mt={1}>
                        <AllowedTo perform={canEditPermission}>
                            <StyledSwitch label={t(titleKey + '.' + item.data.id)} value={item.is_selected} handleChange={(status: boolean) => handleChangeSwitch(status, item.data.id, userId)} />
                        </AllowedTo>
                        <NotAllowedTo perform={canEditPermission}>
                            <CheckedTextDataView label={t(titleKey + '.' + item.data.id)} value={item.is_selected} />
                        </NotAllowedTo>
                    </Grid>
                )
            })}
        </>
    );
};
