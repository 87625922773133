import { useTranslation } from 'next-i18next';
import { Button } from "@mui/material"
import { FC, useState } from "react"
import { QuoteDetails } from "@/models";
import { ConfirmPopup, TranslationWithStyles, RedirectPopup } from 'src/ui/components/shared';
import { HighlightOff } from '@mui/icons-material';
import { QuoteActionPopup } from 'src/ui/components/dumb';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ProcurementButtons, QuotePopupStatus } from '@/config';
import { useSellerProcurementStore } from '@/zustand';
import { sm } from 'src/site-map';

type CancelBuyerQuoteProps = {
    quote: QuoteDetails
    openedPopupStatus: QuotePopupStatus | null
}

export const CancelBuyerQuote: FC<CancelBuyerQuoteProps> = ({ quote, openedPopupStatus }) => {
    const { t } = useTranslation();
    const { setPopupStatus, cancelForBuyer } = useSellerProcurementStore(state => state.quoteDetailsSliceActions)
    const { customLoadingButton } = useSellerProcurementStore(state => state.quoteDetailsSliceProperties)

    //confirm popup
    const [cancelQuotePopup, setCancelQuotePopup] = useState(false);
    const handleOpenCancelQuotePopup = () => setCancelQuotePopup(true);
    const handleCloseCancelQuotePopup = () => setCancelQuotePopup(false);

    //redirect popup
    const [redirectPopup, setRedirectPopup] = useState(false);
    const handleOpenRedirectPopup = () => setRedirectPopup(true);
    const handleCloseRedirectPopup = () => setRedirectPopup(false);

    //cancel popup
    const OpenCancelQuotePopup = (values) => {
        setPopupStatus(QuotePopupStatus.CANCEL_QUOTE)
        handleCloseCancelQuotePopup()
    }
    const handleCancelDone = () => {
        handleOpenRedirectPopup()
        handleCloseCancelReasonPopup()
    }
    const handleCloseCancelReasonPopup = () => setPopupStatus(null)
    const handleCancelQuote = (values: any) => {
        cancelForBuyer(quote.quoteModel?.id, handleCancelDone, values.reasonId, values.hideReason, values.comment)
    }


    return (
        <>
            <Button startIcon={<HighlightOff />} color="error" onClick={handleOpenCancelQuotePopup}> {t("buttons.cancel_quote")}</Button>

            <ConfirmPopup
                titleKey={t("buttons.cancel_quote")}
                subTitleKey={<TranslationWithStyles title={t('popups.subTitles.cancel_quote')} />}
                buttonTitleKey={t("buttons.yes_confirm")}
                handleClose={handleCloseCancelQuotePopup}
                handleConfirm={OpenCancelQuotePopup}
                open={cancelQuotePopup}
                icon={<HighlightOffIcon color='error' sx={{ fontSize: 40 }} />}
            />
            <QuoteActionPopup
                handleCloseQuotePopup={handleCloseCancelReasonPopup}
                QuotePopupOpened={openedPopupStatus == QuotePopupStatus.CANCEL_QUOTE}
                icon={<HighlightOff color='error' />}
                popupTitle={t("buttons.cancel_quote")}
                buttonTitle={t("buttons.cancel_quote")}
                popupSubTitle={t('popups.subTitles.cancel_quote_reason')}
                handlePopupAction={handleCancelQuote}
                status='error'
                isButtonLoading={customLoadingButton == ProcurementButtons.CANCEL_SEND_QUOTE_TO_BUYER}
            />
            {/*ViewQuotes Popup*/}
            <RedirectPopup
                icon={<HighlightOffIcon color='error' />}
                title={t('popups.titles.quote_canceled')}
                openPopup={redirectPopup}
                handleClosePopup={handleCloseRedirectPopup}
                colorTitle={'error'}
                buttonTitle={t('buttons.view_quote')}
                redirectButtonTitle={t('buttons.my_quotes')}
                redirectURL={sm.portals.seller.procurement.quotes.index.url}
            />

        </>
    )
} 