import { Typography, Button, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { LabelWithButton, PopupWithTitle, user } from '@/components';
import { ProfilePasswordPopupContent } from './change-password-byCurrent';
import { ProfilePhoneVerificationPopupContent } from './phone-verification';
import { ProfileChangePhonePopupContent } from './change-phone-byPhone';
import { ProfileChangeDirectPopupContent } from './change-direct-number';
import { ProfileChangeEmailPopupContent } from './change-email-byEmail';
import { useProfile,useProfileEmailFlow,useProfilePhoneNumberFlow,useProfilePasswordFlow } from '@/zustand';
import { useTranslation } from 'next-i18next';
import { getAccountTranslationKey } from '@/hooks';
import { VerifyPhoneButton } from './phone-verification/verify-phone-button';
import { ChangePhoneButton} from './change-phone-byPhone/change-phone-button';

type Props = { userData: user };

export const UserSettingsWithPopupsSection: React.FC<Props> = ({
    userData,
}) => {
    const { t } = useTranslation();
    const [showEmail, setShowEmail] = useState(false);
    
    const togglePasswordPopup = useProfilePasswordFlow(state => state.togglePasswordPopup);
    const setChangePasswordSteps = useProfilePasswordFlow(state => state.setChangePasswordSteps);
    const passwordPopupVisible = useProfilePasswordFlow(state => state.passwordPopupVisible);
    const togglePasswordByEmailPopup = useProfilePasswordFlow(state => state.togglePasswordByEmailPopup);
    const togglePasswordByPhonePopup = useProfilePasswordFlow(state => state.togglePasswordByPhonePopup);
    const setPasswordByCurrentFinish = useProfilePasswordFlow(state => state.setPasswordByCurrentFinish);

    const togglePhoneVerificationPopup = useProfilePhoneNumberFlow(state => state.togglePhoneVerificationPopup);
    const phoneVerificationPopupVisible = useProfilePhoneNumberFlow(state => state.phoneVerificationPopupVisible);

    const toggleChangePhonePopup = useProfilePhoneNumberFlow(state => state.toggleChangePhonePopup);
    const changePhonePopupVisible = useProfilePhoneNumberFlow(state => state.changePhonePopupVisible);
    
    const toggleChangeDirectPopup = useProfilePhoneNumberFlow(state => state.toggleChangeDirectPopup);
    const changeDirectPopupVisible = useProfilePhoneNumberFlow(state => state.changeDirectPopupVisible);
    const setChangeDirectSteps = useProfilePhoneNumberFlow(state => state.setChangeDirectSteps);

    const toggleChangeEmailPopup = useProfileEmailFlow(state => state.toggleChangeEmailPopup);
    const changeEmailPopupVisible = useProfileEmailFlow(state => state.changeEmailPopupVisible);
    const setChangeEmailByEmailSteps = useProfileEmailFlow(state => state.setChangeEmailByEmailSteps)

    return (
        <>
            <LabelWithButton labelTranslateKey="password" buttonTranslateKey="change_password" onClick={() => { togglePasswordPopup(true); setChangePasswordSteps(0);togglePasswordByEmailPopup(false);togglePasswordByPhonePopup(false);setPasswordByCurrentFinish(false) }} />
            <PopupWithTitle title={t(getAccountTranslationKey('titles.change_password'))} handleClose={() => togglePasswordPopup(false)} open={passwordPopupVisible} >
                <ProfilePasswordPopupContent />
            </PopupWithTitle>

            <PopupWithTitle title={t(getAccountTranslationKey('titles.phone_verification'))} handleClose={() => togglePhoneVerificationPopup(false)} open={phoneVerificationPopupVisible}>
                <ProfilePhoneVerificationPopupContent />
            </PopupWithTitle>

            {
                !userData.mobile_number.verified  ? <VerifyPhoneButton userData={userData}/> : <ChangePhoneButton userData={userData}/>
            }
   
            <PopupWithTitle title={t(getAccountTranslationKey('titles.change_phone_number'))} handleClose={() => {toggleChangePhonePopup(false)}} open={changePhonePopupVisible}>
                <ProfileChangePhonePopupContent/>
            </PopupWithTitle>

            <LabelWithButton labelTranslateKey="direct_number" buttonTranslateKey="change_direct_number" onClick={() => { toggleChangeDirectPopup(true); setChangeDirectSteps(0) }}>
                <Typography
                    variant='body2'
                    sx={{ display: 'inline', fontWeight: '500' }}
                >
                    {userData.direct_number}
                </Typography>
            </LabelWithButton>
 
            <PopupWithTitle title={t(getAccountTranslationKey('titles.change_direct_number'))} handleClose={() => toggleChangeDirectPopup(false)} open={changeDirectPopupVisible}>
                <ProfileChangeDirectPopupContent/>
            </PopupWithTitle>

            <LabelWithButton labelTranslateKey="email" buttonTranslateKey="change_email" onClick={() => { toggleChangeEmailPopup(true); setChangeEmailByEmailSteps(0) }}>
                <div>
                    <Typography
                        variant='body2'
                        sx={{ display: 'inline', fontWeight: '500' }}
                    >
                        {!showEmail ? userData.email.substring(0, 2) + "**************"
                            : userData.email}
                    </Typography>
                    {!showEmail && (<Typography
                        variant='body2'
                        sx={{ display: 'inline', fontWeight: '500', pl: 1, '&:hover': { cursor: 'pointer' } }}
                        color={'secondary'}
                        onClick={() => setShowEmail(true)}
                    >
                        {t('buttons.show_email')}
                    </Typography>
                    )}
                </div>
            </LabelWithButton>

            <PopupWithTitle title={t(getAccountTranslationKey('titles.change_email'))} handleClose={() => {toggleChangeEmailPopup(false)}} open={changeEmailPopupVisible}>
                <ProfileChangeEmailPopupContent/>
            </PopupWithTitle>

        </>
    );
};