import { Product } from '@/models';
import { useProcurementStore } from '@/zustand';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';

const useDeleteUtils = () => {
  const confirmDeletePopup = usePopup();
  const [productToBeDeleted, setProductToBeDeleted] = React.useState<Product | null>(null);
  const removeProduct = useProcurementStore(state => state.requisitionsDetailsSliceActions.removeMainProduct);
  const requestProductToDelete = (product: Product) => {
    setProductToBeDeleted(product);
    confirmDeletePopup.handleOpen();
  };
  const cancelDeleteProduct = () => {
    setProductToBeDeleted(null);
    confirmDeletePopup.handleClose();
  };
  const confirmDeleteProduct = () => {
    if (productToBeDeleted) {
      removeProduct(productToBeDeleted);
    }
    confirmDeletePopup.handleClose();
  };

  return { requestProductToDelete, cancelDeleteProduct, confirmDeleteProduct, confirmDeletePopup };
};

export default useDeleteUtils;
