import { Button, Grid, Typography } from '@mui/material';
import { DescPopup } from '../popups';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ProcurementButtons } from '@/config';
import { StyledLoadingButton } from '../buttons';

type ExpiredExtensionPopupProps = {
  open: boolean;
  customLoadingButton: ProcurementButtons | null
  handleClosePopup: () => void;
  handleRequestExtension: () => void;
};

export const ExpiryPopupForChangeResponse: React.FC<ExpiredExtensionPopupProps> = ({
  open,
  customLoadingButton,
  handleClosePopup,
  handleRequestExtension,
}) => {
  return (
    <DescPopup open={open} handleClose={handleClosePopup}>
      <Grid item md={12} textAlign='center' p={1}>
        <CalendarMonthIcon sx={{ color: 'info.dark' }} />

        <Grid item md={12} textAlign='center'>
          <Typography variant='overline' color='info.dark' fontWeight='700'>
            Form Expired
          </Typography>
        </Grid>

        <Grid item md={12} textAlign='center'>
          <Typography variant='body2' color='text.disabled' my={1}>
            In order to change your response to this form, the owner needs to extend its validity date first.
          </Typography>
        </Grid>

        <Grid item container md={12} spacing={2} mt={1}>
          <Grid item md={6}>
            <Button fullWidth variant='contained' color='warning' onClick={handleClosePopup}>
              Cancel
            </Button>
          </Grid>
          <Grid item md={6}>
            <StyledLoadingButton variant="contained" color='error' translateKey={"request_extension"} loading={customLoadingButton == ProcurementButtons.REQUEST_EXTENSION} onClick={handleRequestExtension} />
          </Grid>
        </Grid>
      </Grid>
    </DescPopup>
  );
};
