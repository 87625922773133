import { ComparisonTablesData, ComparisonTablesProduct, ProcurementModelDetails, SellerDetails } from '@/models';
import { useGetBuyerBundlePurchaseOrderDetailsEditPreview, useGetComparisonTablePreviewForBundlePoEdit, useUpdateBundlePurchaseOrder } from '@/services';
import produce from 'immer';
import { StateCreator } from 'zustand';

type ResponseHandlers = {
  onSuccess?: (data: any) => void;
  onError?: () => void;
};
export interface EditBundlePurchaseOrderSlice {
  editBundlePurchaseOrderSliceProperties: {
    bundleDetails: ProcurementModelDetails | null
    originalBundleDetails: ProcurementModelDetails | null
    comparisonTableDetails: ComparisonTablesData | null
    loadingDetails: boolean
    selectedPoId: number
    selectedCtMainProductId: number
  };
  editBundlePurchaseOrderSliceActions: {
    fetchDetails: (bundlePOId: number, responseHandlers?: ResponseHandlers) => void
    fetchComparisonTablePreview: (responseHandlers?: ResponseHandlers) => void
    clearComparisonTableData: () => void
    setIsLoading: (isLoading: boolean) => void
    setSelectedPoId: (newPoId: number) => void
    setSelectedCtMainProductId: (newMainProductId: number) => void
    updatePurchaseOrders: (updatedPurchaseOrders: any) => void
    deleteIndividualPO: (poId: number) => void
    removeProductFormPO: (poId: number, poProductIdToDelete: number) => void
    changeProductQuantity: (poId: number, poProductId: number, newQuantity: number) => void
    changeProductRemarks: (poId: number, poProductId: number, remarks: string | null) => void
    selectSellersForCtProduct: (ctProduct: ComparisonTablesProduct, seller: SellerDetails, isProductAlternative: boolean) => void
    saveChanges: (responseHandlers?: ResponseHandlers) => void
    reset: () => void;
  };
}

export const createEditBundlePurchaseOrderSlice: StateCreator<
  EditBundlePurchaseOrderSlice
> = (set, get, api) => ({
  editBundlePurchaseOrderSliceProperties: {
    bundleDetails: null,
    originalBundleDetails: null,
    comparisonTableDetails: null,
    loadingDetails: false,
    selectedPoId: 0,
    selectedCtMainProductId: 0,
  },
  editBundlePurchaseOrderSliceActions: {
    reset: () => {
        set(produce(draftState => { 
            draftState.editBundlePurchaseOrderSliceProperties.bundleDetails = null
            draftState.editBundlePurchaseOrderSliceProperties.originalBundleDetails = null
            draftState.editBundlePurchaseOrderSliceProperties.comparisonTableDetails = null
            draftState.editBundlePurchaseOrderSliceProperties.loadingDetails = false
            draftState.editBundlePurchaseOrderSliceProperties.selectedPoId = 0
            draftState.editBundlePurchaseOrderSliceProperties.selectedCtMainProductId = 0
        }))
    },
    fetchDetails: (bundlePOId: number, responseHandlers?: ResponseHandlers) => {
        const currentBundlePo = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        const comparisonTableDetails = get().editBundlePurchaseOrderSliceProperties.comparisonTableDetails;

        let previewBundlePoData: any = null;
        if(comparisonTableDetails !== null) { // Get the preview data
            previewBundlePoData = [];
            const sellerCallback = (seller) => {
                if(seller.is_selected) {
                    previewBundlePoData.push({ comparison_table_product_seller_id: seller.id, quantity: seller.quantity, remarks: seller.remarks, additional_requirements: seller.additional_requirements })
                }
            }
            comparisonTableDetails.products.forEach(ctMainProduct => {
                ctMainProduct.sellers.forEach(sellerCallback)
                ctMainProduct.alternatives.forEach(ctAltProduct => {
                    ctAltProduct.sellers.forEach(sellerCallback)
                })
            })
        }

        if(comparisonTableDetails == null && currentBundlePo != null && currentBundlePo.id == bundlePOId) {
            return;
        }

        get().editBundlePurchaseOrderSliceActions.setIsLoading(true)
        useGetBuyerBundlePurchaseOrderDetailsEditPreview(bundlePOId, previewBundlePoData).then(data => {
            set(produce(draftState => { 
                draftState.editBundlePurchaseOrderSliceProperties.bundleDetails = data.data

                // Fetch for the first time only
                if(get().editBundlePurchaseOrderSliceProperties.originalBundleDetails == null) {
                    draftState.editBundlePurchaseOrderSliceProperties.originalBundleDetails = data.data
                }
            }))
            get().editBundlePurchaseOrderSliceActions.setSelectedPoId(data.data.purchaseOrders[0].id)
            get().editBundlePurchaseOrderSliceActions.setIsLoading(false)
            responseHandlers?.onSuccess?.(data.data);
            get().setTimeline(data.data.timeline)
        }).catch(() => {
            responseHandlers?.onError?.();
        })
    },
    fetchComparisonTablePreview: (responseHandlers?: ResponseHandlers) => {
        get().editBundlePurchaseOrderSliceActions.setIsLoading(true)

        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        const previewCtData = bundleDetails.purchaseOrders.reduce((acc, curr) => {
            const products = curr.products.map(prod => ({ comparison_table_product_seller_id: prod.comparison_table_product_seller_id, quantity: prod.requested_quantity, remarks: prod.remarks, additional_requirements: prod.additional_requirements }))
            return [...acc, ...products]
        }, []);

        useGetComparisonTablePreviewForBundlePoEdit(bundleDetails?.id!, previewCtData).then(data => {
            set(produce(draftState => { 
                draftState.editBundlePurchaseOrderSliceProperties.comparisonTableDetails = data.data
                draftState.editBundlePurchaseOrderSliceProperties.selectedCtMainProductId = data.data.products?.[0]?.product?.id
            }))
            get().editBundlePurchaseOrderSliceActions.setIsLoading(false)
            responseHandlers?.onSuccess?.(data.data);
            get().setTimeline(data.data.timeline);
        }).catch(() => {
            responseHandlers?.onError?.();
        })
    },
    clearComparisonTableData: () => {
        set(produce(draftState => { 
            draftState.editBundlePurchaseOrderSliceProperties.comparisonTableDetails = null
        }))
    },
    setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.editBundlePurchaseOrderSliceProperties.loadingDetails = isLoading })),
    setSelectedPoId: (newPoId: number) => {
        set(produce(draftState => { draftState.editBundlePurchaseOrderSliceProperties.selectedPoId = newPoId }))
    },
    setSelectedCtMainProductId: (newMainProductId: number) => {
        set(produce(draftState => { draftState.editBundlePurchaseOrderSliceProperties.selectedCtMainProductId = newMainProductId }))
    },
    updatePurchaseOrders: (updatedPurchaseOrders: any) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        set(produce(draftState => { 
            draftState.editBundlePurchaseOrderSliceProperties.bundleDetails = {
                ...bundleDetails,
                purchaseOrders: updatedPurchaseOrders
            }
         }))
    },
    deleteIndividualPO: (poIdToDelete: number) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        const selectedPoId = get().editBundlePurchaseOrderSliceProperties.selectedPoId;

        const purchaseOrders = bundleDetails?.purchaseOrders;
        const updatedPurchaseOrders = purchaseOrders?.filter(po => po.id !== poIdToDelete);

        get().editBundlePurchaseOrderSliceActions.updatePurchaseOrders(updatedPurchaseOrders);
        if(selectedPoId === poIdToDelete) {
            get().editBundlePurchaseOrderSliceActions.setSelectedPoId(updatedPurchaseOrders?.[0]?.id || 0)
        }
    },
    removeProductFormPO: (poId: number, poProductIdToDelete: number) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        const purchaseOrders = bundleDetails?.purchaseOrders;
        const updatedPurchaseOrders = purchaseOrders?.map(po => po.id !== poId ? po : {
            ...po,
            products: po.products.filter(poProduct => poProduct.id !== poProductIdToDelete)
        });
        get().editBundlePurchaseOrderSliceActions.updatePurchaseOrders(updatedPurchaseOrders);
    },
    changeProductQuantity: (poId: number, poProductId: number, newQuantity: number) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;

        const purchaseOrders = bundleDetails?.purchaseOrders;
        const updatedPurchaseOrders = purchaseOrders?.map(po => po.id !== poId ? po : {
            ...po,
            products: po.products.map(poProduct => poProduct.id !== poProductId ? poProduct : {
                ...poProduct,
                requested_quantity: newQuantity
            })
        });
        get().editBundlePurchaseOrderSliceActions.updatePurchaseOrders(updatedPurchaseOrders);
    },
    changeProductRemarks: (poId: number, poProductId: number, remarks: string | null) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;

        const purchaseOrders = bundleDetails?.purchaseOrders;
        const updatedPurchaseOrders = purchaseOrders?.map(po => po.id !== poId ? po : {
            ...po,
            products: po.products.map(poProduct => poProduct.id !== poProductId ? poProduct : {
                ...poProduct,
                remarks
            })
        });
        get().editBundlePurchaseOrderSliceActions.updatePurchaseOrders(updatedPurchaseOrders);
    },
    selectSellersForCtProduct: (ctProduct: ComparisonTablesProduct, seller: SellerDetails, isProductAlternative: boolean) => {
        const comparisonTableDetails = get().editBundlePurchaseOrderSliceProperties.comparisonTableDetails;
        const products = get().editBundlePurchaseOrderSliceProperties.comparisonTableDetails!.products;

        const updatedProducts = products.map(prod => ({
            ...prod,
            sellers: prod.sellers.map(el => el.id !== seller.id ? el : { ...el, is_selected: !el.is_selected }),
            alternatives: prod.alternatives.map(altProd => ({
                ...altProd,
                sellers: altProd.sellers.map(el => el.id !== seller.id ? el : { ...el, is_selected: !el.is_selected }),
            }))
        }))
       
        set(produce(draftState => { 
            draftState.editBundlePurchaseOrderSliceProperties.comparisonTableDetails = {
                ...comparisonTableDetails,
                products: updatedProducts
            }
         }))

    },
    saveChanges: (responseHandlers?: ResponseHandlers) => {
        const bundleDetails = get().editBundlePurchaseOrderSliceProperties.bundleDetails;
        if(!bundleDetails) return; 

        const productsToSave = bundleDetails.purchaseOrders.reduce((acc, curr) => {
            const products = curr.products.map(prod => ({ comparison_table_product_seller_id: prod.comparison_table_product_seller_id, quantity: prod.requested_quantity, remarks: prod.remarks }))
            return [...acc, ...products]
        }, []);

        useUpdateBundlePurchaseOrder(bundleDetails?.id, bundleDetails.comparison_table.id ,productsToSave).then(data => {
            responseHandlers?.onSuccess?.(data.data);
        }).catch(() => {
            responseHandlers?.onError?.();
        })
    },
  },
});
