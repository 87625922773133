import * as yup from 'yup';
import { apiURLs } from '@/config';
import axios from 'axios';
import { useRef } from 'react';
import { phoneRegExp } from 'src/logic/helpers/regex';

const emailSchema = yup.string().email('email').required();

export class UniqueEmailTester {

  static state() {
    return {
      lastState: {
        email: '',
        result: false
      }
    }
  }

  static async validator(email) {
    if (this.lastState.email === email) { return this.lastState.result; }

    this.lastState.email = email;
    emailSchema.validate(email).catch(err => {
      this.lastState.result = false;
      return false;
    });
    let x;
    await axios.post(apiURLs.validateEmail, { email: email }).then(res => { this.lastState.result = true; x = true }).catch(error => { this.lastState.result = false; x = false })
    return x;
  }
}
let emailValidatorState = UniqueEmailTester.state();

export const validationSchema = [
  yup.object({
    first_name: yup.string().required('required').min(2, 'min2').matches(/^[A-Za-z ]*$/, 'valid_name'),
    last_name: yup.string().required('required').min(2, 'min2').matches(/^[A-Za-z ]*$/, 'valid_name'),
    title: yup.string().required('required'),
    job_title: yup.string().required('required').min(2, 'min2').matches(/^[A-Za-z ]*$/, 'valid_name'),
    mobile_number: yup
      .string()
      .required('This is required')
      .matches(phoneRegExp, 'Invalid Phone Number'),
    direct_number: yup
      .string()
      .matches(phoneRegExp, 'Invalid Phone Number'),
    email: yup
      .string()
      .required('required')
      .test('checkEmailUnique', 'already_registered', UniqueEmailTester.validator.bind(emailValidatorState)),
    // .test(
    //   'Unique Email',
    //   'already_registered',
    //    emailUniqueTest.current
    //    ),
    // .test(
    //   'Unique Email',
    //   'already_registered', // <- key, message
    //   function (value) {
    //     const isValid = yup
    //       .string()
    //       .email('Enter a valid email')
    //       .required('Email is required')
    //       .validateSync(value);
    //     if (isValid) return new Promise((resolve, reject) => {
    //       axios
    //         .post(apiURLs.validateEmail, { email: value })
    //         .then(res => {
    //           resolve(true);
    //         })
    //         .catch(error => resolve(false));
    //     });
    //   }
    // ),
    password: yup
      .string()
      .required('required')
      .min(8, 'min8')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'small_big')
      .matches(/^(?=.*[0-9])/, 'letter_digit'),
    password_confirmation: yup
      .string()
      .required('required')
      .oneOf([yup.ref('password')], 'matches_password'),
  }),
  yup.object({
    company_name: yup.string(),
    existed_company_id: yup.string().when('company_name', {
      is: value => value == null,
      then: yup.string().required('required'),
    }),
    company_industry_id: yup.number().when('existed_company_id', {
      is: value => value == null,
      then: yup.number().required('required'),
    }),
    company_country_id: yup.number().when('existed_company_id', {
      is: value => value == null,
      then: yup.number().required('required'),
    }),
    company_city_id: yup.number().when('existed_company_id', {
      is: value => value == null,
      then: yup.number().required('required'),
    }),
    company_address: yup.string().when('existed_company_id', {
      is: value => value == null,
      then: yup.string().required('required'),
    }),
    trn_number: yup.string(),
    work_number: yup
      .string()
      .when('existed_company_id', {
        is: value => value == null,
        then: yup.string(),
      })
      .matches(phoneRegExp, 'Invalid Phone Number'),
    fax_number: yup.string().matches(phoneRegExp, 'Invalid Fax Number'),
    is_company_admin: yup.boolean().required('required'),
    terms: yup.boolean().required('required').oneOf([true], 'agreed'),
  }),
];
