import axios from 'axios';
import create from 'zustand';
import { apiURLs } from '@/config';
import { country, company, industry } from '@/components';

export const useRegisterData = create((set, get) => ({
  isLoading: false,
  isFetched: false,
  countries: [],
  industries: [],
  titles: [],
  companies: [],

  country: null,
  company: null,
  requestAdminRoles: true,
  upload: true,

  getCities: () => { return get().country ? get().country.cities : [] },
  toggleRequestAdminRoles: () => { set({ requestAdminRoles: !get().requestAdminRoles }) },
  toggleUpload: () => { set({ upload: !get().upload }) },
  setCountry: (country: country) => { set({ country: country }) },
  setCompany: (company: company) => { set({ company: company }) },
  
  getSelectedCompanyIndustry: () => {
    if (get().company == null) return ""
    const id = get().company.industry.id
    return get().industries.find(item => item.id === id).name
  },

  getSelectedCompanyCountry: () => {
    if (get().company == null) return ""
    const id = get().company.country.id
    return get().countries.find(item => item.id === id).name
  },

  getSelectedCompanyCity: () => {
    if (get().company == null) return ""
    const country = get().countries.find(item => item.id === get().company.country.id)
    const id = get().company.city.id
    return country.cities.find(item => item.id === id).name
  },

  searchCompanies: (query: string, isBuyer: boolean) => {
    if (query != '') {
      const company_type = isBuyer ? "BUYER" : "SELLER"
      axios.get(apiURLs.companiesAutocomplete, {
        params: {
          query: query,
          company_type: company_type
        }
      }).then(response => {
        set({ companies: response.data.data });
      });
    }
    else
      set({ companies: [] })
  },

  getRegisterData: () => {
    if(get().isFetched) { return; }
    set({ isLoading: true, isFetched: true });
    axios.get(apiURLs.registerData, {}).then((response) => {
      set({
        companies: [],
        countries: response.data.data.countries,
        industries: response.data.data.industries,
        titles: response.data.data.titles,
        isLoading: false
      });
    });
  },
}));

