import * as React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { StyledTextField } from '@/components';
import { validationSchema } from './validationSchema'
import { initialValues } from './initialValues'
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useProfile } from '@/zustand';

interface ChangeEmailFormProps {
    isDisabledButton: boolean,
    paragraphTranslate: string,
    handleClickNext: (values: any, actions: any) => void,
    handleClickBack: () => void,
}

export const ChangeEmailForm: FC<ChangeEmailFormProps> = (props: ChangeEmailFormProps) => {

    const { t } = useTranslation();
    const user_info = useProfile(state => state.user_info);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema(user_info?.email)} onSubmit={props.handleClickNext}>
            {formikProps => (
                <form onSubmit={formikProps.handleSubmit}>
                    <Typography variant='body2' sx={{ fontWeight: '400' }}>
                        {props.paragraphTranslate}
                    </Typography>
                    <Grid item xs={12} mt={2}>
                        <StyledTextField showLabel required translateKey='user.new_email' name='email' />
                    </Grid>
                    <Button
                        disabled={props.isDisabledButton}
                        variant='contained'
                        color='secondary'
                        type='submit'
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        {t('buttons.change_email')}
                    </Button>
                    <Button
                        variant='outlined'
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={props.handleClickBack}
                    >
                        {t('buttons.back')}
                    </Button>
                </form>
            )}
        </Formik>
    )
}