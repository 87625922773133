import { DescPopup, LoginForm, MainPage } from '@/components';
import { getRequisitionTranslationKey } from '@/hooks';
import { FilterItem, Filters_Res, PaginationResult, Product } from '@/models';
import { useGetFiltersOnClick } from '@/services';
import { ResponsiveContainer } from '@/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import { Grid, Typography, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'next-i18next';
import { FilterChips } from '../filter-chips';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { sm } from "src/site-map";
import { useUserData } from '@/zustand';
import { useState } from 'react';

type PropsFilterPathDetails = {
  FilterPathDetails: {
    categoryTitle: string;
    classTitle: string;
    title: string;
    updatedTitle: string;
    type_id?: number;
    product_id?: string;
  };
};

type Props = {
  typeId: number;
  products: Product[];
  paginationResult: PaginationResult;
  filterData: Filters_Res[];
  sortData: FilterItem[];
  onFilterChecked: Function;
  onFilterUnchecked: Function;
  onResetFilters: Function;
  numberOfSelectedFilters: number;
  waitFilters?: boolean;
  waitProducts?: boolean;
  onSortItemSelected: Function;
  FilterPathDetails: PropsFilterPathDetails;
  onPageNumberChange: Function;
  canPerformActionRequisition: boolean
  canPerformActionPO: boolean
  categoryData: any
};


export const FilterPage: React.FC<Props> = ({
  FilterPathDetails,
  children,
  typeId,
  products,
  filterData,
  sortData,
  onFilterChecked,
  onFilterUnchecked,
  numberOfSelectedFilters,
  onResetFilters,
  waitFilters,
  waitProducts,
  onSortItemSelected,
  onPageNumberChange,
  paginationResult,
  canPerformActionRequisition,
  canPerformActionPO,
  categoryData
}) => {
  const { t } = useTranslation();
  const getFiltersOnClick = useGetFiltersOnClick(typeId)
  const [recoverySteps, setRecoverySteps] = useState(0)
  const setCustomLoginPopup = useUserData(state => state.setCustomLoginPopup)
  const customLoginPopup = useUserData(state => state.customLoginPopup)

  return (
    <>
      <Grid sx={{ bgcolor: 'background.primary.main' }}>
        <Grid container md={12} component={ResponsiveContainer}>
          {children}
          <Grid container xs={12} md={12} mt={3}>
            <Grid item md={5}>
              <Typography
                variant='h1'
                fontWeight='600'
                sx={{ textTransform: 'capitalize' }}
              >
                {categoryData?.name}
              </Typography>
            </Grid>
            <Grid item container md={7} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'center' }}>
              <Typography
                color='text.third'
                fontWeight='400'
                variant='body2'
                sx={{ textTransform: 'lower', display: 'flex', alignItems: 'center' }} >
                {numberOfSelectedFilters + ' ' + t('buttons.filters_added')}
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: 'primary.main', cursor: 'pointer', textDecoration: 'underline', ml: 1, display: 'flex', alignItems: 'center' }}
                onClick={() => { onResetFilters(getFiltersOnClick); }}
              >
                <ReplayIcon sx={{ fontSize: '16px !important', mr: 0.5 }} />{t(getRequisitionTranslationKey('titles.reset_filters'))}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            md={12}
            my={1}
            sx={{ bgcolor: 'secondary.contrastText' }}
          >
            {filterData?.map((el, index) => {
              return <>
                <Grid item container xs={12} md={4}>
                  <Grid item md={9.5} m={2}>
                    <Typography variant='body2' color={'text.black'} fontWeight={400} sx={{ margin: 1, textTransform: 'capitalize' }}> {el.title}</Typography>
                    <FilterChips
                      items={el.filter_items} 
                      filterType={el.type}
                      onFilterChecked={onFilterChecked}
                      onFilterUnchecked={onFilterUnchecked}
                    />
                  </Grid>
                  {index != filterData.length - 1 && <Grid item md={1} my={2}>
                    <Divider orientation='vertical' sx={{ borderRight: '1px solid #E8E8EA' }} />
                  </Grid>}
                </Grid>
              </>
            }
            )}
          </Grid>
        </Grid>
        <MainPage
          FilterPathDetails={FilterPathDetails}
          products={products}
          paginationResult={paginationResult}
          sortData={sortData}
          waitFilters={waitFilters}
          waitProducts={waitProducts}
          onSortItemSelected={onSortItemSelected}
          onPageNumberChange={onPageNumberChange}
          canPerformActionRequisition={canPerformActionRequisition}
          canPerformActionPO={canPerformActionPO}
        ></MainPage>

        <DescPopup open={customLoginPopup} handleClose={() => setCustomLoginPopup(false)}>
          <Grid container alignItems='center' justifyContent='center'>
            <Grid item md={12} textAlign='center'>
              <AccountCircleOutlinedIcon sx={{ color: 'primary.main', fontSize: 40 }} />
              <Grid item md={12} textAlign='center'>
                <Typography variant='overline' color='primary.main' fontWeight={700} my={1}> {t('buttons.login')} </Typography>
              </Grid>
            </Grid>

            <Grid item md={7} textAlign='center'>
              <Typography variant='body2' color='text.disabled' fontWeight={400}> {t('texts.login_to_add_products_to_po')} </Typography>
            </Grid>

            <Grid container alignItems='center' justifyContent='center' py={1}>
              <LoginForm recoverAccount={() => setRecoverySteps(recoverySteps + 1)} custom={true} />
            </Grid>

            <Grid item md={12} textAlign='center'> <Typography variant='subtitle2' color='text.third' fontWeight={400} sx={{ textTransform: 'capitalize' }}> {t('texts.or')}  </Typography> </Grid>

            <Grid item md={9} textAlign='center' p={1}>
              <Button fullWidth variant='contained' href={sm.register.index.url} color='secondary' sx={{ cursor: 'pointer' }}> {t('buttons.register_new_account')} </Button>
            </Grid>
          </Grid>
        </DescPopup>

      </Grid>
    </>
  );
};
