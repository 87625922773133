import { CommentsNumber, EditProject } from "@/components";
import { GeneralObject } from '@/models';
import { useUserData } from '@/zustand';
import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { FC } from "react";
import { TextViewWithValue } from '../../../shared';
// import { sm } from "src/site-map";
// import { Link } from '@/components';

type FormHeaderDetailsProps = {
    formModel: any;
    updateProject?: (project: GeneralObject) => void;
    bundle?: boolean
    hideComments?: boolean
    projectDetailsUrl?: string | null | undefined
    hideProject?: boolean
    senderProject?: string
    receiverProject?: string
    senderProjectId?: number
    disableEditProject?: boolean
    numberOfProducts?: number
    numberOfServices?: number
};

export const FormHeaderDetails: FC<FormHeaderDetailsProps> = ({
    formModel,
    updateProject,
    bundle,
    hideComments,
    projectDetailsUrl,
    hideProject,
    senderProject,
    receiverProject,
    senderProjectId,
    disableEditProject = false,
    numberOfProducts,
    numberOfServices,
}) => {
    const { t } = useTranslation();
    const userInfo = useUserData(state => state.userInfo);
    const canEdit = formModel?.form_action_permissions?.can_update_project;
    // const canEdit = userInfo?.id === formModel?.owner?.id && formModel?.form_action_permissions?.can_update_project;

    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const showListOfApprovers = Boolean(anchorEl);
    // const [isHovered, setIsHovered] = useState(false);
    // const handleMouseEnter = () => setIsHovered(true);
    // const handleMouseLeave = () => setIsHovered(false);

    // const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => {
    //     handleMouseEnter()
    //     setAnchorEl(event.currentTarget)
    // }
    // const handleHoverOut = () => {
    //     handleMouseLeave()
    //     setAnchorEl(null)
    // }
    const { isSeller } = useUserData();

    return (
        <Grid container md={12} alignItems="center">
            <Grid item md={12}> <Divider sx={{ opacity: 0.7, my: 1 }} /> </Grid>

            <Grid item container md={10} alignItems="center" color='text.third' gap={2} sx={{ fontSize: '12px' }}>
                {/* <Grid item>
                    <Grid item container> */}
                {t('texts.created_on')} {formModel?.created_at} {t('texts.by')} {formModel?.owner?.full_name}
                {/* <Link underline='hover' href={{ pathname: isSeller() ? sm.portals.seller.usersAccount.url : sm.portals.buyer.usersAccount.url, query: { userId: formModel?.owner?.id } }} onClick={(event) => event.stopPropagation()}> */}
                {/* <Typography variant='body2' ml={1} fontWeight={400} color={'text.third'}>  {formModel?.owner?.full_name}</Typography> */}
                {/* </Link>
                    </Grid>
                </Grid> */}
                {/* {(!bundle && formModel?.approvers?.length != 0 && formModel?.approvers != undefined) &&
                    <span style={{ cursor: 'pointer' }} onMouseEnter={(event) => handleHoverIn(event)} onMouseLeave={handleHoverOut}>
                        {t('texts.approvers')}: {formModel?.approvers?.length}
                        <ApproversList isHovered={isHovered && showListOfApprovers} Anchor={anchorEl} listOfApprovers={formModel?.approvers} handleClose={() => setAnchorEl(null)} />
                    </span>
                } */}

                {numberOfProducts !==undefined && <Typography fontWeight={400} fontSize={13} color="#8B8D98">Products: {numberOfProducts}</Typography>}
                {numberOfServices !== undefined && <Typography fontWeight={400} fontSize={13} color="#8B8D98">Services: {numberOfServices}</Typography>}

                {formModel?.competitors &&
                    <TextViewWithValue title={t('texts.competitors')} value={formModel?.competitors} color='text.third' />
                }

                {formModel?.project && bundle && !hideProject && <TextViewWithValue title={"Internal Project"} value={formModel?.project?.name} redirectLink={projectDetailsUrl} color="primary.main" />}

                {/* {canEdit && formModel?.project && !hideProject && <Grid item md={1}><EditProject formModel={formModel} updateProject={updateProject} /></Grid>} */}

                {receiverProject && !hideProject && <TextViewWithValue title={isSeller() ? "Buyer Project" : "Seller Project"} value={receiverProject} />}
                {senderProject && !hideProject && <TextViewWithValue title={"Internal Project"} value={senderProject} redirectLink={projectDetailsUrl} color="primary.main" />}
                {canEdit && !disableEditProject && !hideProject && (formModel?.project || senderProjectId) && <Grid item md={0.1} ml={1.5}><EditProject formModel={formModel} updateProject={updateProject} projectId={formModel?.project ? formModel.project.id : senderProjectId} /></Grid>}

                {/* {canEdit && formModel?.project && !hideProject && <Grid item md={1}><EditProject formModel={formModel} updateProject={updateProject} /></Grid>} */}

                {/* {formModel?.validity_date && (
                    <Grid item>
                        <CircleIcon sx={{ color: 'success.dark', fontSize: '10px', mr: 0.5 }} />
                        {t('texts.valid_until')} {formModel?.validity_date}
                    </Grid>
                )} */}
            </Grid>

            {!hideComments && <CommentsNumber formDetails={formModel} title={formModel?.name ?? ''} commentType={formModel?.commentable_type ?? ''} commentId={formModel?.id ?? ''} />}

        </Grid>
    );
};
