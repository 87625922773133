import { HeroImageSection, HeroTextSection } from '@/components';
import { getCareerTranslationKey, useAnimateOnView } from '@/hooks';
import { Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { sm } from 'src/site-map';

type Props = {};

export const HeroCareersSection: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  const first = useAnimateOnView({
    transition: { delay: 0 },
  });
  const second = useAnimateOnView({
    transition: { delay: 0.3 },
  });
  const third = useAnimateOnView({
    transition: { delay: 0.6 },
  });

  return (
    <Grid container>
      <HeroTextSection
        isSmallScreen={smallScreens}
        ShowBreadcrumbs={true}
        breadCrumbsAlias={sm.careers.index.url}
        title={t(getCareerTranslationKey('titles.intro'))}
        subTitle={t(getCareerTranslationKey('sub_titles.intro'))}
        withBlueBackgroung={true}
      />
      <HeroImageSection imageSrc='/assets/images/careers/girl.png' />
    </Grid>
  );
};
