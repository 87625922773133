import { SxProps, TypographyProps } from '@mui/material';

export const TableHeaderStyles: SxProps = {
  backgroundColor: '#fff',
  pl: 4,
  pr: 2,
  py: 1,
  borderRadius: '8px',
};

export const ProductCardStyles: SxProps = {
  ...TableHeaderStyles,
  borderLeft: '8px solid #FB7F0D',
  mt: 1.5,
  pl: 3,
};

export const TableHeaderCellProps: TypographyProps = {
  fontSize: 12,
  color: '#8B8D98',
  textTransform: 'uppercase',
  fontWeight: 400,
};
