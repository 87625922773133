import { FC, useState } from 'react';
import { Divider, Stack, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'next-i18next';
import { useField } from 'formik'
import { at } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import { getInputsTranslationKey } from '@/hooks';


interface SelectListWithChildrenProps {
    translateKey: string,
    name: string,
    disabled?: boolean,
    required?: boolean
    items: Array<{
        id: number,
        name: string,
    }>
}

// const selectTextInputStyle = {
//     color: 'text.secondary',
//     fontWeight: 200
// }
// const inputStyle = {
//     minWidth: '100%',
//     fontWeight: 200,
// };
// const textInputStyle = {
//     bgcolor: 'white',
//     fontWeight: 200,
// };

const selectTextInputStyle = {
    color: '#B2B2B2',
}
const inputStyle = {
    width: '100%',
};
const textInputStyle = {
    bgcolor: 'white',
    color: '#3A3E50',
    width: '100%',
};

export const SelectListWithChildren: FC<SelectListWithChildrenProps> = (props: SelectListWithChildrenProps) => {
    const { t } = useTranslation()
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <Typography variant='subtitle2' >
                {t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error))}
            </Typography>
            // return t(getInputsTranslationKey('error_messages.' + props.translateKey + '.' + error));
        }
    }
    return (
        <Typography
            component='label'
            color='text.third'
            variant='subtitle2'
            sx={{ display: 'block', minWidth: '100%' }}
        >
            {t(getInputsTranslationKey('labels.' + props.translateKey))} {props.required ? ' *' : ''}
            <FormControl sx={inputStyle} fullWidth size="small" error={meta.touched && meta.error && true}>
                <Select
                    displayEmpty
                    disabled={props.disabled}
                    error={meta.touched && meta.error && true}
                    // inputProps={{ sx: (field.value == 0 ? { ...textInputStyle, ...selectTextInputStyle } : textInputStyle) }}
                    sx={{
                        '.MuiSelect-select': (!field.value ? { ...textInputStyle, ...selectTextInputStyle } : { ...textInputStyle })
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: '220px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                '&::-webkit-scrollbar': {
                                    height: 5,
                                    width: 7,
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: `white`,
                                    margin: 2,
                                    marginRight: -2
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'primary.main',
                                    outline: `1px solid transparent`,
                                    borderRadius: 8,
                                },
                            }
                        }
                    }}
                    error={meta.touched && meta.error && true}
                    {...field}
                >
                    <MenuItem value={0} disabled style={{ display: "none", fontWeight: 200 }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    <MenuItem value={''} disabled style={{ display: "none", fontWeight: 200 }}>
                        {t(getInputsTranslationKey('placeholders.' + props.translateKey))}
                    </MenuItem>
                    {props.items.map(function (object, i) {
                        return <MenuItem value={object.id} key={object.id} sx={{ borderBottom: '0.1px solid #f6f6f6', mx: 2 }}>{object.name}</MenuItem>;
                    })}
                    {props.children}
                </Select>
                <FormHelperText>{_renderHelperText()}</FormHelperText>
            </FormControl>
        </Typography >
    );
};
