import { SvgIconProps, SvgIcon } from '@mui/material';

export const IconVisibilityOff: React.FC<SvgIconProps> = props => (
  <SvgIcon
    {...props}
    width="14" 
    height="12" 
    viewBox="0 0 14 12" 
    fill="none" xmlns="http://www.w3.org/2000/svg"
    >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75752 1.71027L6.07165 5.02383C6.31173 4.79187 6.63919 4.64906 7.00016 4.64906C7.73654 4.64906 8.3335 5.24337 8.3335 5.9765C8.3335 6.33816 8.18822 6.66604 7.9526 6.90545L11.2428 10.1956C11.5032 10.4559 11.5032 10.878 11.2428 11.1384C10.9825 11.3987 10.5603 11.3987 10.3 11.1384L9.7 10.539C8.84341 11.0351 7.94358 11.2862 7.00016 11.2862C4.60976 11.2862 2.49923 9.67383 0.66277 6.55082L0.522045 6.30762L0.333496 5.9765L0.522045 5.64538C1.08298 4.66029 1.66987 3.81807 2.28288 3.12169L1.81471 2.65308C1.55436 2.39273 1.55436 1.97062 1.81471 1.71027C2.07506 1.44992 2.49717 1.44992 2.75752 1.71027ZM2.03064 5.71276L1.87038 5.9765L2.00907 6.20534C3.58361 8.75904 5.2496 9.95881 7.00016 9.95881C7.58205 9.95881 8.15435 9.82646 8.71682 9.55631L6.19775 7.03673C6.097 6.96104 6.00738 6.87144 5.93178 6.7708L3.22516 4.06276C2.82138 4.53034 2.42317 5.07954 2.03064 5.71276ZM7.00016 0.666748C9.39057 0.666748 11.5011 2.27917 13.3376 5.40217L13.4783 5.64538L13.6668 5.9765L13.341 6.54496C12.8285 7.41725 12.2946 8.17183 11.7392 8.80651L10.7979 7.86509C11.2491 7.33878 11.6932 6.71036 12.13 5.97658C10.5147 3.26559 8.80224 1.99419 7.00016 1.99419C6.42931 1.99419 5.86744 2.12177 5.31481 2.38228L4.32975 1.39703C5.17751 0.912191 6.06754 0.666748 7.00016 0.666748Z" />
  </SvgIcon>
);
