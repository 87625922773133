import type { FC } from 'react';
import { Box } from '@mui/material';
import { Logo } from '../logo';

export const SplashScreen: FC = () => {
  return (
    <div>
      <Box
        sx={{
          position: 'fixed',
          display: 'grid',
          placeContent: 'center',
          placeItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: 'background.paper',
          zIndex: 2000,
          width: '100%',
          height: '100%',
        }}
      >
        <Logo size={100} widthSize={5} width={'100%'} height={'100%'} />
      </Box>
    </div>
  );
};
