import { BundleProcurementDetails } from '@/models';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { formatNumber } from 'src/logic/utils/numbers';

type Props = { bundlePO: BundleProcurementDetails | null };

const getFormatedNumber = (value: string | number | null | undefined) => {
  if (value === null || value === undefined || isNaN(value)) return "";
  if (typeof value === "number") return formatNumber(value);
  return formatNumber(parseFloat(value) ?? '');
}

export const BundlePoPDFDocument: React.VFC<Props> = ({ bundlePO }) => {
  const { t } = useTranslation();
  return (
    <Document>
      {bundlePO?.model?.purchaseOrders?.map((poDetails, index) => {
        return (
          <Page size='A4' style={styles.page} key={poDetails?.id}>
            <View>
              <Text style={styles.title}>{bundlePO?.model?.name}</Text>
            </View>
            <View style={[styles.section, { marginTop: '12px' }]}>
              <Text style={styles.smallBlue}> {t('texts.date_created')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.created_at}</Text>
              <Text style={styles.smallBlue}> number of pos: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.purchaseOrders?.length} </Text>
              <Text style={styles.smallBlue}>{t('texts.project')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.project?.name}</Text>
              <Text style={styles.smallBlue}>{t('texts.number_of_products')} </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.number_of_products} </Text>
              <Text style={styles.smallBlue}>{t('texts.number_of_services')} </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.number_of_services} </Text>
              <Text style={styles.smallBlue}>{t('texts.req')}: </Text>
              <Text style={[styles.smallGrey, { marginRight: '5px' }]}>{bundlePO?.model?.requisition?.name}</Text>
            </View>
            <View style={[styles.section, { marginTop: '15px', width: '85%' }]}>
              <View>
                <Text style={[styles.smallGrey, { marginBottom: '6px' }]}>
                  {t('texts.po_number', { po_number: index + 1, pos_length: bundlePO?.model?.purchaseOrders?.length })}
                </Text>
                <Text style={styles.mediumTitle}>{poDetails?.name}</Text>
              </View>
            </View>
            <PoDetails model={poDetails} />
            <View style={{ width: '90%', marginTop: '20px' }}>
              {poDetails?.products.map((item, index) => {
                return <ProductCard item={item} quantity={item.requested_quantity} index={index} key={index} />;
              })}
            </View>

            <View style={{ height: '3%' }} fixed />
            <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
              <View></View>
              <View>
                <Text style={styles.bigWithBold}>{t('texts.order_summary')}</Text>
                <View style={styles.spaceBetweenSection}>
                  <Text style={styles.smallBlue}> {t('texts.sub_total')} </Text>
                  <Text style={[styles.smallGrey]}>{getFormatedNumber(poDetails?.subtotal_value)}</Text>
                </View>
                <View style={styles.spaceBetweenSection}>
                  <Text style={styles.smallBlue}> {t('texts.vat')} </Text>
                  <Text style={[styles.smallGrey]}>{getFormatedNumber(poDetails?.vat_value)}</Text>
                </View>
                <View style={styles.spaceBetweenSection}>
                  <Text style={styles.smallWithBold}>{t('texts.total')} </Text>
                  <Text style={[styles.smallGrey]}>{getFormatedNumber(poDetails?.total_value)}</Text>
                </View>
              </View>
            </View>
            <View style={{ height: '10%' }} fixed />

            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                // `Page ${pageNumber} of ${totalPages}`
                t('texts.page_number', { page_number: pageNumber, pages_length: totalPages })
              }
              fixed
            />
          </Page>
        );
      })}
    </Document>
  );
};

const PoDetails = ({ model }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.section, { margin: 1, justifyContent: 'space-between', width: '85%' }]}>
      <View>
        <Text style={styles.bigBlue}>{model?.buyer_details.name}</Text>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.fax')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.buyer_details.fax_number ? model?.buyer_details.fax_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.phone')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.buyer_details.phone_number ? model?.buyer_details.phone_number : t('texts.n_a')}
          </Text>
        </View>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.address')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.buyer_details.company_address ? model?.buyer_details.company_address : t('texts.n_a')}
          </Text>
        </View>
      </View>
      <View>
        <Text style={styles.bigBlue}>{t('popups.titles.general_terms')}</Text>
        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.payment_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle.payment_term.name ? model?.terms_bundle.payment_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.warranty_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle.warranty_term.name ? model?.terms_bundle.warranty_term.name : 'N/A'}
          </Text>
        </View>

        <View style={styles.spaceBetweenSection}>
          <Text style={styles.smallBlue}> {t('texts.delivery_term')} </Text>
          <Text style={[styles.smallGrey]}>
            {model?.terms_bundle.delivery_term.name ? model?.terms_bundle.delivery_term.name : 'N/A'}
          </Text>
        </View>

      </View>
    </View>
  );
};

const ProductDetails = ({ title, value }) => {
  return (
    <View style={{ marginRight: '30px', marginHorizontal: '5px' }}>
      <Text style={[styles.smallGrey, { marginBottom: '2px' }]}>{title}</Text>
      <Text style={styles.smallBlack}>{value}</Text>
    </View>
  );
};

const ProductCard = ({ item, index, quantity }) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        index % 2 == 0 ? styles.section : styles.blueSection,
        { height: '53px', display: 'flex', alignItems: 'center' },
      ]}
    >
      <View>
        <Image
          style={styles.smallImage}
          src={item.product.image_url + '?noCache=' + Math.random().toString()}
          source={{
            header: {
              'Access-Control-Allow-Origin': '*'
            }
          }}
        />
      </View>
      <View style={styles.productDetails}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.smallTitle}>
            {item.product.name} {item?.product?.is_private_product ? '  (Private)' : ''}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginTop: '10px' }}>
          <ProductDetails
            title={t('texts.segment')}
            value={item?.product?.category_kind == 1 ? 'product' : 'service'}
          />
          <ProductDetails
            title={t('texts.type')}
            value={item.product?.category.name ? item.product?.category.name : 'N/A'}
          />
          <ProductDetails title={t('texts.brand')} value={item.product.brand.name ? item.product?.brand.name : 'N/A'} />
          <ProductDetails
            title={t('texts.warranty_term')}
            value={item.warranty_term?.name ? item.warranty_term?.name : 'N/A'}
          />
          <ProductDetails
            title={t('texts.part_number')}
            value={item.product?.part_number ? item.product?.part_number : 'N/A'}
          />
          {item.product?.productAttributes.slice(0, 3).map(({ name, value }) => (
            <ProductDetails key={name} title={name} value={value} />
          ))}
        </View>
      </View>
      <View style={{ alignSelf: 'flex-start' }}>
        <Text style={{ fontSize: '8px', margin: '5px' }}>{quantity ?? 0} x</Text>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    margin: '30px',
  },
  section: {
    flexDirection: 'row',
    width: '535px',
  },
  blueSection: {
    backgroundColor: '#EAEEF6',
    flexDirection: 'row',
    width: '535px',
  },
  spaceBetweenSection: {
    flexDirection: 'row',
    width: '240px',
    justifyContent: 'space-between',
  },
  bigImageSection: {
    width: '90px',
  },
  smallImageSection: {
    width: '61px',
  },
  productDetails: {
    width: '474px',
  },
  title: {
    color: '#00385E',
    fontSize: '18px',
    fontWeight: 800,
    width: '90%',
  },
  mediumTitle: {
    color: '#13628C',
    fontSize: '12px',
    fontWeight: 600,
  },
  smallTitle: {
    color: '#13628C',
    fontSize: '8px',
    fontWeight: 500,
    width: '294px',
  },
  smallBlue: {
    color: '#00385E',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigBlue: {
    color: '#00385E',
    fontSize: '9px',
    fontWeight: 500,
    marginTop: 4,
    marginBottom: 2,
  },
  smallGrey: {
    color: '#8B8D98',
    fontSize: '6px',
    fontWeight: 400,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 50,
    marginBottom: 2,
    left: 0,
    right: 0,
    fontSize: '6px',
    fontWeight: 400,
    color: '#8B8D98',
  },
  smallBlack: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 400,
  },
  bigImage: {
    width: '61px',
    height: '61px',
  },
  smallImage: {
    width: '35px',
    height: '35px',
    margin: '5px',
    marginRight: '10px',
  },
  bigWithBold: {
    color: '#3A3E50',
    fontSize: '9px',
    fontWeight: 700,
    marginTop: 4,
    marginBottom: 2,
  },
  smallWithBold: {
    color: '#3A3E50',
    fontSize: '6px',
    fontWeight: 700,
  },
});
