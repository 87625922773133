import create from 'zustand';
import produce from 'immer';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import { SellersIndex, SellersDetails } from 'src/logic/models/catalogue/CatalogueIndex';
import { getFilteredSellers, getSellerDetails, getSellersCatalogueIndex } from 'src/logic/services/catalogue/sellers';

type ResponseHandlers<T = any> = {
  onSuccess?: (data: T) => void;
  onError?: () => void;
};

export interface SellersIndexSlice {
  sellersProperties: {
    sellers: null | SellersIndex;
    sellerDetails: null | SellersDetails;
    fetchQueryStatus: QueryHandlerModel;
    fetchQueryFilterStatus: QueryHandlerModel;
  };

  sellersActions: {
    loadData: () => void;
    onFilterSellers: (id?: string, showMySellers?: boolean) => void;
    loadSellerDetails: (id: number, responseHandlers?: ResponseHandlers<SellersDetails>) => void;
  };
}

export const useSellersIndexSlice = create<SellersIndexSlice>((set, get) => ({
  sellersProperties: {
    sellers: null,
    sellerDetails: null,
    fetchQueryStatus: QueryHandler.getInitialStatus(),
    fetchQueryFilterStatus: QueryHandler.getInitialStatus(),
  },

  sellersActions: {
    loadData: async () => {
      set(
        produce<SellersIndexSlice>(draftState => {
          draftState.sellersProperties.fetchQueryStatus = QueryHandler.getStartStatus();
        })
      );

      getSellersCatalogueIndex()
        .then(data => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.sellers = data.data;
              draftState.sellersProperties.fetchQueryStatus = QueryHandler.getSuccessStatus();
            })
          );
        })
        .catch(err => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.fetchQueryStatus = QueryHandler.getErrorStatus(err);
            })
          );
        });
    },
    onFilterSellers: (id?: string, showMySellers?: boolean) => {
      set(
        produce<SellersIndexSlice>(draftState => {
          draftState.sellersProperties.fetchQueryFilterStatus = QueryHandler.getStartStatus();
        })
      );

      getFilteredSellers(id, showMySellers)
        .then(data => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.sellers = data.data;
              draftState.sellersProperties.fetchQueryFilterStatus = QueryHandler.getSuccessStatus();
            })
          );
        })
        .catch(err => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.fetchQueryFilterStatus = QueryHandler.getErrorStatus(err);
            })
          );
        });
    },
    loadSellerDetails: (id: number, responseHandlers?: ResponseHandlers<SellersDetails>) => {
      set(
        produce<SellersIndexSlice>(draftState => {
          draftState.sellersProperties.fetchQueryStatus = QueryHandler.getStartStatus();
        })
      );

      getSellerDetails(id)
        .then(data => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.sellerDetails = data.data;
              draftState.sellersProperties.fetchQueryStatus = QueryHandler.getSuccessStatus();
            })
          );
          responseHandlers?.onSuccess?.(data.data);
        })
        .catch(err => {
          set(
            produce<SellersIndexSlice>(draftState => {
              draftState.sellersProperties.fetchQueryStatus = QueryHandler.getErrorStatus(err);
            })
          );
          responseHandlers?.onError?.();
        });
    },
  },
}));
