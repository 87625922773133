import { AuthenticateStatus, RouteProtectionRule, UserType } from "@/config";
import { useUserData } from "src/logic/zustand/user";
export const useRouteProtected = (rule: RouteProtectionRule) => {

    const authenticationStatus = useUserData(state => state.authenticationStatus);
    const companyInfo = useUserData(state => state.companyInfo);
    const getHasRole = useUserData(state => state.getHasRole);
    const getHasPermission = useUserData(state => state.getHasPermission);
    const isCorrectAuthenticationStatus = () => {
        return rule.authenticationStatus == AuthenticateStatus.UNDEFINED || rule.authenticationStatus == authenticationStatus;
    };
    const isCorrectUserType = () => {
        return rule.userType == UserType.UNDEFIENED || rule.userType == companyInfo.companyType;
    };
    const isCorrectRoles = () => {
        var allRolesFound = true;
        rule.roles.map((role) => {
            allRolesFound = allRolesFound && getHasRole(role)
        })
        return allRolesFound;
    };
    const isCorrectPermissions = () => {
        var allPermissionsFound = true;
        rule.permissions.map((permission) => {
            allPermissionsFound = allPermissionsFound && getHasPermission(permission)
        })
        return allPermissionsFound;
    };
    return isCorrectAuthenticationStatus() && isCorrectUserType() && (isCorrectRoles() && isCorrectPermissions());
};
