import React from 'react';
import { Loading } from 'src/ui/components/shared';
import ErrorWithRefetchButton from 'src/ui/components/shared/error-with-refetch';

type IProps = {
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  refetch: () => void;
};

const NotificationsQueryStatus = ({
  isEmpty,
  isError,
  isLoading,
  refetch,
}: IProps) => {
  if (isLoading) {
    return (
      <div style={{ height: '150px' }}>
        <Loading fullHeight={false} />
      </div>
    );
  }
  if (isError) {
    return (
      <ErrorWithRefetchButton
        height={'150px'}
        onRefetch={refetch}
        textProps={{ variant: 'body2' }}
        wrapperProps={{ px: 1 }}
      />
    );
  }
  if (isEmpty) {
    return (
      <div
        style={{
          height: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p style={{ textAlign: 'center' }}>No Notifications to display</p>
      </div>
    );
  }
  return null;
};

export default NotificationsQueryStatus;
