import React from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { SplashScreen } from '../../shared';
import { useUserData } from '@/zustand';
import { AuthenticateStatus, UserType } from '@/config';
import { MobileNavbar, MainFooter, BottomNavbar, Header, NotificationsHeader, SideDrawer } from '..';
import { MobileDrawer } from '../drawer-mobile';


export const MainLayout: React.FC = ({ children }) => {
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));
  const authenticationStatus = useUserData(state => state.authenticationStatus);
  const companyInfo = useUserData(state => state.companyInfo);
  // const userInfo = useUserData(state => state.userInfo);
  // const hasAccessToPortal = useUserData(state => state.hasAccessToPortal);

  return (
    <>
      {authenticationStatus != AuthenticateStatus.UNDEFINED ? (
        <Box>
          <Box sx={{ height: '60px' }}>
            <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 3, height: '60px' }} >
              {/* {authenticationStatus == AuthenticateStatus.AUTHENTICATED && <NotificationsHeader companyInfo={companyInfo} userInfo={userInfo} />} */}
              {isSmallScreen ? (
                <MobileNavbar authenticatedStatus={authenticationStatus} userType={authenticationStatus == AuthenticateStatus.AUTHENTICATED ? (companyInfo?.companyType) : UserType.UNDEFIENED}/>
              ) : (
                <>
                  <Header authenticatedStatus={authenticationStatus} />
                </>
              )}
            </Box>
          </Box>
          {!isSmallScreen ? (<SideDrawer userType={authenticationStatus == AuthenticateStatus.AUTHENTICATED ? (companyInfo?.companyType) : UserType.UNDEFIENED}>
              <main>{children}</main>
              {/* <Box component='footer'>
                <MainFooter />
                <Box my={0} />
                <BottomNavbar />
              </Box> */}
            </SideDrawer>)
            : (<main>{children}</main>)}
        </Box>
      ) : (
        <SplashScreen />
      )}
    </>
  );
};
